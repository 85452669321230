import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CourseData, LearningMaterialData, ModuleData } from '@app/api/models/learning-material-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ModuleApiService } from '@app/api/module/services/module-api.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.sass']
})
export class CourseFormComponent implements OnInit {

  @ViewChild('addModuleModal') addModuleModal!: TemplateRef<any>;

  @Input() courseData!: CourseData;
  @Input() maxDescriptionLength: number = 250;
  @Output() courseDataChange = new EventEmitter<CourseData>();
  @Output() editModule = new EventEmitter<ModuleData>();
  @Output() triggerSaveCourse = new EventEmitter();
  selectedModule: ModuleData | null = null;
  editingModule?: ModuleData;
  courseDescriptionControl!: FormControl;

  constructor(
    private modalService: NgbModal,
    private globalModal: GlobalModalService,
    private moduleService: ModuleApiService,
    private route: ActivatedRoute,
    private router: Router,
    private moduleApi: ModuleApiService
  ) {}

  triggerCourseDataChange() {
    this.courseDataChange.emit(this.courseData);
  }

  ngOnInit() {
    this.updateModuleOrder();
    this.courseDescriptionControl = new FormControl(this.courseData.description, [
      Validators.required,
      Validators.maxLength(250) 
    ]);
    this.courseDescriptionControl.valueChanges.subscribe(value => {
      this.courseData.description = value;
    });
    this.route.queryParams.subscribe((params) => {
      if (params['moduleId']) {
        this.moduleApi.getModuleById(params['moduleId']).subscribe({
          next: (lesson) => {
            this.selectedModule = lesson;
            this.addModule();
            this.router.navigate([], {
              queryParams: {
                moduleId: null,
              },
              queryParamsHandling: 'merge',
            });
          },
        });
      }
    });
  }

  onDescriptionInput() {
    this.courseDescriptionControl.markAsTouched();
    this.courseDescriptionControl.updateValueAndValidity();
  }

  isDescriptionLimitExceeded(): boolean {
    return this.courseDescriptionControl.value.length > this.maxDescriptionLength;
  } 

  onMoveItem(item: any) {
    moveItemInArray(this.courseData.modules, item.previousIndex, item.currentIndex);
    this.courseData.modules[item.currentIndex].order = item.currentIndex;
    this.updateModuleOrder();
    this.courseDataChange.emit(this.courseData);
  }

  updateModuleOrder() {
    this.courseData.modules.forEach((module, index) => {
      module.order = index;
    });
  }

  openAddModuleModal() {
    this.modalService.open(this.addModuleModal, {
      size: 'lg',
      scrollable: true,
    });
  }

  onSelectModule(data: ModuleData) {
    this.selectedModule = data;
  }

  get alreadySelectedFilter() {
    return (module: LearningMaterialData) => {
      return !this.courseData.modules.find(m => m.id === module.id);
    };
  }

  addModule() {
    if (!this.courseData.modules) {
      this.courseData.modules = [];
    }

    if (this.selectedModule && this.selectedModule.id) {
      this.moduleService.getModuleById(this.selectedModule.id).subscribe(module => {
        this.courseData.modules.push(module);
        this.triggerCourseDataChange();
        this.updateModuleOrder();
      });
    }
  }

  deleteModule(module: ModuleData) {

    this.globalModal.confirm('Are you sure you want to remove this module?', {
      title: 'Remove Module',
      okButtonText: 'Remove',
      cancelButtonText: 'Cancel',
      type: 'danger'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.courseData.modules = this.courseData.modules.filter(m => m.id !== module.id);
          this.triggerCourseDataChange();
          this.updateModuleOrder();
        }
      }
    });

  }

  openEditModulePage(module: ModuleData) {
    if (!module.id) {
      return;
    }

    this.editModule.emit(module);
  }

  get orderedModules() {
    return this.courseData.modules.sort((a, b) => a.order - b.order);
  }
  createModule() {
    this.triggerSaveCourse.emit();
  }

}
