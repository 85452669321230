import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  LessonData,
  ModuleData,
} from '@app/api/models/learning-material-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizData } from '@app/api/quiz/models/quiz.model';

@Component({
  selector: 'app-module-tree',
  templateUrl: './module-tree.component.html',
  styleUrls: ['./module-tree.component.sass'],
})
export class ModuleTreeComponent implements OnInit {
  @Input() moduleData?: ModuleData;
  @Input() indent = false;
  @ViewChild('previewModal') previewModal?: TemplateRef<any>;
  lessonsCollapse = false;

  lesson!: LessonData;
  quiz!: QuizData;

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {
    if (this.moduleData) {
      this.moduleData.lessons.sort((a, b) => a.order - b.order);
      this.moduleData.quizzes.sort((a, b) => a.order - b.order);
    }
  }

  openLessonPreview(content: LessonData) {
    this.lesson = content;
    this.modal.open(this.previewModal, {
      size: 'xl',
      scrollable: true,
    });
  }

  openQuizPreview(content: QuizData) {
    this.quiz = content;
    this.modal.open(this.previewModal, {
      size: 'xl',
      scrollable: true,
    });
  }
}
