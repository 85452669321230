import { Component } from '@angular/core';

@Component({
  selector: 'app-student-view-page',
  templateUrl: './student-view-page.component.html',
  styleUrls: ['./student-view-page.component.sass']
})
export class StudentViewPageComponent {

}
