import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';

interface MarkdownEditorConfig {
  showPreviewButton: boolean;
  useMarkdown?: boolean;
}

@Component({
  selector: 'app-text-field',
  template: `
    <ng-container *ngIf="control">
      <div class="form-floating" *ngIf="type !== 'markdown'; else markdownTemplate">
        <input *ngIf="lines === 1; else textareaTemplate"
               [type]="type"
               class="form-control"
               [id]="controlName"
               (focus)="controlFocus.emit()"
               [readonly] = "readonly"
               [placeholder]="label"
               [formControl]="control">
        <ng-template #textareaTemplate>
          <textarea class="form-control"
                    [id]="controlName"
                    (focus)="controlFocus.emit()"
                    [placeholder]="label"
                    [formControl]="control"
                    [style]="{ 'height': multilineHeight }"
                    [rows]="lines"></textarea>
        </ng-template>
        <label class="form-label" [for]="controlName">
          <span [ngClass]="{ 'required': control.hasValidator(Validators.required) }">{{label}}</span>
        </label>
      </div>
      <ng-template #markdownTemplate>
        <app-markdown-editor [formControl]="control"
                             [placeholder]="label"
                             (editorFocus)="controlFocus.emit()"
                             [label]="label"
                             [useMarkdown]="mdEditorConfig.useMarkdown"
                             [showPreviewButton]="mdEditorConfig.showPreviewButton"
                             [lines]="lines"></app-markdown-editor>
      </ng-template>
      <div *ngIf="showCharacterCount" class="d-flex justify-content-between align-items-center mt-1">
          <app-control-validation-message [control]="control"
                                    [customErrorMessages]="customErrorMessages"></app-control-validation-message>
          <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
              {{ descriptionLength }}/{{ maxLength }}
          </span>
      </div>
    </ng-container>
    <ng-container *ngIf="controlName !== 'description'">
        <app-control-validation-message [control]="control" 
                                  [customErrorMessages]="customErrorMessages"></app-control-validation-message>
    </ng-container>

  `
})
export class TextFieldComponent extends AbstractCoreFormControlComponent implements OnInit {

  @Input() lines = 1;
  @Input() type: 'text' | 'email' | 'password' | 'markdown' = 'text';
  @Input() markdownEditorConfig?: Partial<MarkdownEditorConfig>;
  @Input() readonly = false;
  @Input() maxLength: number = 250;
  @Input() showCharacterCount = false;
  descriptionLength: number = 0;
  get mdEditorConfig(): MarkdownEditorConfig {
    // Only override certain properties of the default config
    return {
      showPreviewButton: true,
      useMarkdown: true,
      ...this.markdownEditorConfig
    };
  }

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }

  get multilineHeight(): string {
    return `${this.lines * 2}em`;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.showCharacterCount) {
      this.updateCharacterCount();
    }
  }

  updateCharacterCount(): void {
    if (this.control) {
      this.descriptionLength = this.control.value?.length || 0;
      this.control.valueChanges.subscribe(() => {
        this.descriptionLength = this.control?.value?.length || 0;
        this.control!.markAsTouched();
      });
    }
  }

  isDescriptionLimitExceeded(): boolean {
    return this.descriptionLength > this.maxLength;
  }
}
