import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { UploadState } from '@app/api/video/interfaces/upload-state.interface';
import { map } from 'rxjs';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoUploadService {

  constructor(private http: HttpClient) { }

  uploadVideo(videoUploadFormData: FormData, uploadId: string) {
    return this.http.post<VideoMetaData>(`${environment.apiUrl}/videos/upload`, videoUploadFormData, {
      params: { uploadId },
      observe: 'events',
      reportProgress: true
    });
  }

  /**
   * Initiate the upload of a video
   * @param videoUploadFormData The form data to upload
   * @returns The upload ID
   */
  initiateUpload(videoUploadFormData: FormData) {
    const formData = new FormData();
    formData.append('fileName', videoUploadFormData.get('fileName') as string);
    formData.append('path', videoUploadFormData.get('path') as string);
    formData.append('title', videoUploadFormData.get('title') as string);
    formData.append('description', videoUploadFormData.get('description') as string);
    formData.append('overwrite', videoUploadFormData.get('overwrite') as string);
    formData.append('tags', videoUploadFormData.get('tags') as string);

    return this.http.post(`${environment.apiUrl}/videos/initiate-upload`, formData)
      .pipe(map((response: any) => response.uploadId));
  }

  getVideoUploadStatus(uploadId: string) {
    return this.http.get<UploadState>(`${environment.apiUrl}/videos/upload-status/${uploadId}`);
  }
}
