import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnologyTag } from '@app/api/categorization/models/technology-tag.model';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { DiscussionsService } from '@app/api/discussion/service/discussions.service';
import { Page } from '@app/api/models/page.model';
import { User } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';
import { AuthService } from '@app/auth/services/auth.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-all-discussions',
  templateUrl: './all-discussions.component.html',
  styleUrls: ['./all-discussions.component.sass']
})
export class AllDiscussionsComponent {

  allPosts: Discussion[] = [];
  totalPagesArray: number[] = [];
  visiblePageNumbers: number[] = [];
  loading: boolean = false;
  userId?: number | undefined;
  isThreadFlagged: boolean = false;
  isThreadAlert: boolean = false;
  localStorageKey: string = '';
  discussionFlags: { [postId: number]: boolean } = {};

  @Input() currentSortOption: string = 'Latest';
  @Input() selectedTags: TechnologyTag[] = [];
  @Input() filteredPosts: Discussion[] = [];
  @Input() searchTerm: string = '';

  @Input() selectedTag: number = 0;
  @Input() removedTag: number = 0;
  @Input() discussions: Discussion[] = [];

  allPostsTmp: Discussion[] = [];
  pagination = {
    page: 0,
    size: 10,
    currentPageSize: 0,
    total: 0,
    sortBy: 'id',
    direction: 'asc',
    totalPages: 0
  };

  pageSortDir: string = 'updatedDate,desc';
  loginUserRole: string = 'Admin';

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private allDiscussionsService: DiscussionsService,
    private globalModalService: GlobalModalService,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const sortOption = params['sort'] || 'Latest';
      this.currentSortOption = sortOption;
      this.loadDiscussions();
    });
    this.fetchUserId();
    this.fetchAllPosts();
    this.userService.getSelfRole().subscribe((role: string) => {
      console.log("user role => [" + role + "]");
      this.loginUserRole = role;
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error fetching User Role:', error);
    });
  }

  fetchUserId(): void {
    this.userService.getSelf().subscribe(
      (user: User) => {
        this.userId = user.id;
      },
      (error) => {
        console.error('Error fetching user ID:', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("All Discussion Component ngOnChanges called");
    if (changes['currentSortOption'] && !changes['currentSortOption'].firstChange) {
      this.loadDiscussions();
    }
    if (changes['selectedTag'] && !changes['selectedTag'].firstChange) {
      console.log("selectedTag changed");
      this.filterDiscussionsByTags();
    }
    if (changes['removedTag'] && !changes['removedTag'].firstChange) {
      console.log("removedTag changed");
      this.filterDiscussionsByTags();
    }
    if (changes['searchTerm'] && !changes['searchTerm'].firstChange) {
      if (this.searchTerm.trim() === '') {
        this.fetchAllPosts();
      } else {
        this.searchDiscussion();
      }
    }
    console.log('Input changes:', changes);
  }

  filterDiscussionsByTags(): void {
    if (this.selectedTags.length === 0) {
      this.filteredPosts = [...this.allPosts];
    } else {
      this.filteredPosts = [];
      this.allPosts.forEach((post) => {
        console.log("post.technologyTags => ", JSON.stringify(post.technologyTags));
        post.technologyTags?.forEach((pt) => {
          this.selectedTags.forEach((st) => {
            if (st.id === pt.id) {
              console.log("selectedTag found => ", pt.id);
              this.filteredPosts.push(post);
            } else {
              console.log("selectedTag NOT found => ", st.id);
            }
          });
        });
      });
      console.log("filteredPosts.length (aft filter) => ", this.filteredPosts.length);
    }
    console.log('Filtered posts:', this.filteredPosts);
  }

  loadDiscussions(): void {
    switch (this.currentSortOption) {
      case 'Latest':
        this.pagination.sortBy = 'updatedDate';
        this.pagination.direction = 'desc';
        this.loadLatestDiscussions();
        break;
      case 'TopRated':
        this.pagination.sortBy = 'upvoteCount';
        this.pagination.direction = 'desc';
        this.loadTopRatedDiscussions();
        break;
      case 'Oldest':
        this.pagination.sortBy = 'createdDate';
        this.pagination.direction = 'asc';
        this.loadOldestDiscussions();
        break;
      default:
        console.error('Invalid sort option');
    }
  }

  loadLatestDiscussions(): void {
    this.pageSortDir = 'updatedDate,desc';
    this.allDiscussionsService.getAllPosts({
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    }).subscribe(
      (response: Page<Discussion>) => {
        console.log('Latest Discussions : ', response.content);
        this.allPosts = response.content;
        this.populateTagsForPosts();
        this.fetchCommentCounts();
      },
      (error) => {
        console.error('Error fetching latest discussions:', error);
      }
    );
  }

  loadOldestDiscussions(): void {
    this.pageSortDir = 'createdDate,asc';
    this.allDiscussionsService.getAllPosts({
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    }).subscribe(
      (response: Page<Discussion>) => {
        console.log('Oldest Discussions : ', response.content);
        this.allPosts = response.content;
        this.populateTagsForPosts();
        this.fetchCommentCounts();
      },
      (error) => {
        console.error('Error fetching oldest discussions:', error);
      }
    );
  }

  loadTopRatedDiscussions(): void {
    this.pageSortDir = 'upvoteCount,desc';
    this.allDiscussionsService.getAllPosts({
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    }).subscribe(
      (response: Page<Discussion>) => {
        console.log('Top Rated Discussions : ', response.content);
        this.allPosts = response.content;
        this.populateTagsForPosts();
        this.fetchCommentCounts();
      },
      (error) => {
        console.error('Error fetching top rated discussions:', error);
      }
    );
  }

  fetchAllPosts(): void {
    this.allDiscussionsService.getAllPosts({
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    }).subscribe(
      (response: Page<Discussion>) => {
        this.allPosts = response.content;
        this.pagination.total = response.totalElements;
        this.pagination.totalPages = Math.ceil(response.totalElements / this.pagination.size);
        this.populateTagsForPosts();
        this.fetchCommentCounts();
        this.allPosts.forEach(post => {
          this.isThreadAlert = post.isAlert;
          this.isThreadFlagged = post.isFlagged;
        });
      },
      (error) => {
        console.error('Error fetching all posts:', error);
      }
    );
  }

  searchDiscussion(): void {
    this.loading = true;
    this.allDiscussionsService.searchDiscussions(this.searchTerm).subscribe(
      (searchedDiscussions: any) => {
        this.allPosts = searchedDiscussions.content.filter((discussion: Discussion) =>
          discussion.content.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          discussion.postTitle.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          (discussion.technologyTags && discussion.technologyTags.some(tag => tag.name.toLowerCase().includes(this.searchTerm.toLowerCase())))
        );
        this.pagination.totalPages = Math.ceil(this.allPosts.length / this.pagination.size);
        this.pagination.page = 0;
        this.populateTagsForPosts();
        this.fetchCommentCounts();
        this.filterDiscussionsByTags();
        console.log("searched discussion => ", JSON.stringify(this.filteredPosts));
        this.loading = false;
      },
      (error) => {
        console.error('Error searching discussions:', error);
        this.loading = false;
      }
    );
  }

  populateTagsForPosts(): void {
    const tagFetchingObservables = this.allPosts.map(post =>
      this.allDiscussionsService.getTagsForPost(post.id)
    );

    forkJoin(tagFetchingObservables).subscribe(
      (tagsArray: TechnologyTag[][]) => {
        tagsArray.forEach((tags, index) => {
          this.allPosts[index].technologyTags = tags;
        });
        this.filterDiscussionsByTags();
      },
      (error) => {
        console.error('Error fetching tags for posts:', error);
      }
    );
  }

  fetchPostById(id: number): void {
    this.allDiscussionsService.getPostById(id).subscribe(
      (post) => {
        console.log('Details for Post ID ' + id + ': ', post);
      },
      (error) => {
        console.error('Error fetching post details:', error);
      }
    );
  }

  nextPage(): void {
    if (this.pagination.page < this.pagination.totalPages - 1) {
      this.pagination.page++;
      this.loadDiscussions();
    }
  }

  previousPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.loadDiscussions();
    }
  }

  goToPage(pageNumber: number): void {
    if (pageNumber >= 0 && pageNumber < this.pagination.totalPages) {
      this.pagination.page = pageNumber;
      this.loadDiscussions();
    }
  }

  fetchCommentCounts(): void {
    const commentCountObservables = this.allPosts.map(post =>
      this.allDiscussionsService.getCommentCountForPost(post.id)
    );

    forkJoin(commentCountObservables).subscribe(
      (commentsArray: any[]) => {
        commentsArray.forEach((commentCount, index) => {
          this.allPosts[index].commentCount = commentCount;
        });
      },
      (error) => {
        console.error('Error fetching comment counts:', error);
      }
    );
  }

  onDeleteThread(postId: number): void {
    this.userService.getSelf().subscribe((user: User) => {
      this.userService.getSelfRole().subscribe((role: string) => {
        console.log("user role => [" + role + "]");
        if (role === 'Admin') {
          const message = 'Are you sure you want to delete this thread?';

          this.globalModalService.confirm(message).subscribe((result) => {
            if (result) {
              this.deleteThread(postId, this.userId!);
            }
          });
        } else {
          const errorMessage = 'Unauthorized: Only admins can delete threads.';
          this.globalModalService.alert({
            content: errorMessage,
            type: 'warning'
          });
          console.error('Unauthorized: Only admins can delete threads.');
        }
      }, (error) => {
        const errorMessage = 'Error: ' + error.error.message;
        this.globalModalService.alert({
          content: errorMessage,
          type: 'danger'
        });
        console.error('Error deleting thread:', error);
      });
    }, (error) => {
      console.error('Error fetching user ID:', error);
    });
  }

  private deleteThread(postId: number, userId: number): void {
    this.allDiscussionsService.deleteThread(postId, userId).subscribe(
      () => {
        console.log('Thread deleted successfully');
        this.loadDiscussions();
      },
      (error) => {
        const errorMessage = 'Error: ' + error.error.message;
        this.globalModalService.alert({
          content: errorMessage,
          type: 'danger'
        });
        console.error('Error deleting thread:', error);
      }
    );
  }

  flagThread(postId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }

    const discussion = this.allPosts.find(post => post.id === postId);
    if (!discussion) {
      console.error('Discussion not found.');
      return;
    }

    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.allDiscussionsService.flagThread(postId).subscribe(
          () => {
            console.log('Thread flagged successfully.');
            discussion.isFlagged = true;
            this.globalModalService.alert({
              content: 'Thread has been flagged successfully.',
              type: 'success'
            });
          },
          (error) => {
            console.error('Error flagging thread:', error);
            this.globalModalService.alert({
              content: 'Error flagging thread. Please try again later.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can flag threads.');
        this.globalModalService.alert({
          content: 'Only admins can flag threads.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

  setAlert(postId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }
    const discussion = this.allPosts.find(post => post.id === postId);
    if (!discussion) {
      console.error('Discussion not found.');
      return;
    }
    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.allDiscussionsService.alertThread(postId).subscribe(
          () => {
            console.log('Alert set successfully for post:', postId);
            discussion.isAlert = true;
            this.globalModalService.alert({
              content: 'Alert set successfully for post.',
              type: 'success'
            });
          },
          (error) => {
            console.error('Error setting alert:', error);
            this.globalModalService.alert({
              content: 'Error setting alert.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can set alerts.');
        this.globalModalService.alert({
          content: 'Only admins can set alerts.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

}
