import { Component } from '@angular/core';

@Component({
  selector: 'app-module-activity-page',
  templateUrl: './module-activity-page.component.html',
  styleUrls: ['./module-activity-page.component.sass']
})
export class ModuleActivityPageComponent {

}
