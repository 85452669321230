import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { FileRecord, FileTree } from '@core/components/file-explorer/file-record.model';
import { UploadService } from '@core/services/upload.service';

@Injectable({
  providedIn: 'root'
})
export class FilesApiService {

  constructor(private http: HttpClient, private uploadService: UploadService, private api: ApiService) { }

  getFileTree(path: string, publicAccess?: boolean) {
    return this.http.get<FileTree>(this.api.endpoint('file-records'),
      {
        params: {
          publicAccess: publicAccess ? publicAccess : '',
          path
        }
      });
  }

  uploadFile(id: number | 'self', file: File, path: string, overwrite: boolean, publicAccess: boolean, fileName?: string) {
    const formData = this.uploadService.buildFormData(file, path, overwrite, publicAccess, fileName);
    return this.http.post(this.api.endpoint(`users/${id}/files`), formData);
  }

  deleteFile(fileRecordId: number) {
    return this.http.delete(this.api.endpoint(`file-records/${fileRecordId}`));
  }

  getFileRecord(fileRecordId: number) {
    return this.http.get<FileRecord>(this.api.endpoint(`file-records/${fileRecordId}`));
  }
}
