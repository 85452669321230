<div class="my-2" [formGroup]="rubricForm">

  <div class="table-responsive" *ngIf="criteria.controls.length">
    <table class="table table-bordered">
      <thead class="table-light">
      <tr>
        <th><span class="visually-hidden">Criteria</span></th>
        <th>Excellent <span class="text-muted small">(5 points)</span></th>
        <th>Great <span class="text-muted small">(4 points)</span></th>
        <th>Good <span class="text-muted small">(3 points)</span></th>
        <th>Fair <span class="text-muted small">(2 points)</span></th>
        <th>Poor <span class="text-muted small">(1 points)</span></th>
      </tr>
      </thead>
      <tbody formArrayName="criteria">
      <tr *ngFor="let criterion of criteria.controls; let index = index" [formGroupName]="index">
        <td class="rubric-criteria-cell">
          <div class="rounded h-100 position-relative flex-column">
            <input type="text"
                   class="form-control rounded-bottom-0 fw-bold criterion-title"
                   formControlName="title"
                   placeholder="Criterion Title">
            <textarea id="criterionDescription"
                      formControlName="description"
                      class="form-control rounded-top-0 border-top-0 h-100 criterion-description textarea-no-resize"
                      [rows]="8"
                      placeholder="Describe the criterion being measured. This can be a feature or a concept that must be implemented."></textarea>
            <label for="criterionDescription" class="visually-hidden">
              Describe the criterion being measured. This can be a feature or a concept that must be implemented.
            </label>
            <div class="delete-row-btn">
              <button type="button" class="btn btn-danger w-100 my-2"
                      (click)="removeCriteria(index)">
                <span class="visually-hidden">Delete Criterion</span>
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
        </td>
        <td formArrayName="ratings" *ngFor="let _ of getRatings(criterion); let j = index">
          <div [formGroupName]="j">
            <textarea [id]="getRatingId(j)"
                      class="form-control rubric-textarea textarea-no-resize"
                      formControlName="description"
                      [placeholder]="getPlaceholder(j)"></textarea>
            <label [for]="getRatingId(j)" class="visually-hidden">
              {{getPlaceholder(j)}}
            </label>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="w-100">
    <button type="button" class="btn btn-primary w-100" (click)="addCriteria()">
      Add Criterion <span class="bi bi-plus-circle"></span>
    </button>
  </div>
</div>
