<div class="container">
    <div *ngIf="showTitle" class="my-2">
      <h2>My Quizzes</h2>
    </div>
    <div class="w-100 d-flex justify-content-end align-items-center flex-wrap gap-2">
      <div class="mx-1 d-flex align-items-start">
        <button (click)="openFilterModal()" class="btn btn-light border me-2" type="button">
          <span class="bi bi-filter"></span>
          Filters
        </button>
        <button (click)="resetFilter(); loadQuizzes()" *ngIf="hasFilter"
          class="btn btn-light border me-2 border text-muted align-self-end" type="button">
          <span class="bi bi-x-circle"></span>
          Clear Filters
        </button>
      </div>
      <div class="col-12 col-md-3">
        <app-search-bar-input (loadResults)="filterQuizzes()" [(search)]="searchQuery"></app-search-bar-input>
      </div>
    </div>
    <div *ngIf="showFilterModal" class="modal fade show" tabindex="-1" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filters</h5>
            <button type="button" class="btn-close" (click)="toggleFilterModal()"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="technologyFilter">Filter by Technology</label>
              <select id="technologyFilter" class="form-select" [(ngModel)]="selectedTechnology"
                (change)="filterQuizzes()">
                <option value="">All Technologies</option>
                <option *ngFor="let tech of technologies" [value]="tech">{{ tech }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="toggleFilterModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply Filters</button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3">
      <div *ngIf="!loading; else spinner" class="row row-cols-3 row-cols-md-4">
        <div *ngFor="let quiz of filteredQuiz" class="col my-3">
          <div tabindex="0" class="card card-body h-100 module-card" (click)="openEditQuizPage(quiz)">
            <div class="d-flex flex-row align-items-center h-100">
              <div class="bi bi-file-earmark-text text-primary display-6"></div>
              <div class="mx-2 flex-grow-1">
                <div class="fw-bold text-wrap">{{truncateText(quiz.title, 15) || 'Untitled Quiz'}}</div>
                <p class="text-muted">{{truncateText(quiz.description, 25) || 'No description'}}</p>
              </div>
              <div class="h-100 align-top" ngbDropdown placement="right-top" click-stop-propagation>
                <button ngbDropdownToggle class="btn btn-sm btn-link text-primary border-0 dropdown-no-caret" click-stop-propagation>
                  <span class="bi bi-three-dots-vertical"></span>
                </button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>
                    <div class="d-flex justify-content-between" (click)="openEditQuizPage(quiz)">
                      Edit <span class="bi bi-pencil-square"></span>
                    </div>
                  </button>
                  <button ngbDropdownItem >
                    <div class="d-flex justify-content-between" (click)="deleteQuiz(quiz.id!)">
                      Delete <span class="bi bi-trash text-danger"></span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top py-2 bg-white d-flex justify-content-center">
      <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="currentPage" [collectionSize]="totalItems"
        [pageSize]="pageSize" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
  </div>
  <ng-template #spinner>
    <div class="row row-cols-1">
      <div class="col d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>


