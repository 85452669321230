import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import * as CodeMirror from 'codemirror';
import { Editor } from 'codemirror';
import { SettingsService } from '@core/services/settings.service';
import { TextType, TextTypes } from '@core/components/markdown-editor/text-type.interface';
import { ToolbarButtonData } from '@core/components/markdown-editor/toolbar-button-data.interface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { FileRecord } from '@core/components/file-explorer/file-record.model';
import { ApiService } from '@core/services/api.service';
import { UserService } from '@app/api/user/services/user.service';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';


const imageExtensions = '.png,.jpg,.jpeg,.gif,.svg';
const pdfExtensions = '.pdf';

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownEditorComponent),
      multi: true
    }
  ]
})
export class MarkdownEditorComponent implements ControlValueAccessor, OnInit {

  @ViewChild('editor') editor?: CodemirrorComponent;
  @ViewChild('insertLinkModal') insertLinkModal?: TemplateRef<any>;
  @ViewChild('insertAlertModal') insertAlertModal?: TemplateRef<any>;
  @ViewChild('insertImageModal') insertImageModal?: TemplateRef<any>;
  @ViewChild('insertFileModal') insertFileModal?: TemplateRef<any>;
  @ViewChild('insertVideoModal') insertVideoModal?: TemplateRef<any>;
  @ViewChild('uploadVideoModal') uploadVideoModal?: TemplateRef<any>;
  @ViewChild('selectVideoModal') selectVideoModal?: TemplateRef<any>;
  @ViewChild('insertPdfModal') insertPdfModal?: TemplateRef<any>;

  @Input() set placeholder(placeholder: string) {
    this.defaultCodeMirrorOptions.placeholder = placeholder;
  }

  get placeholder() {
    return this.defaultCodeMirrorOptions?.placeholder || '';
  }

  @Input() label = 'Markdown Editor';
  @Input() lines = 4;
  @Input() useMarkdown?: boolean;
  @Input() showPreviewButton = true;
  @Input() textAreaClassName = '';
  @Input() hideUseMarkdownToggle = false;
  @Input() showToolbarToggle = false;
  @Input() showToolbar = true;
  @Input() showToolbarOnFocus = false;
  @Output() editorFocus = new EventEmitter<void>();
  editorHasFocus$ = new Subject<boolean>();
  _height = 'auto';
  @Input() set height(height: string | number) {
    if (typeof height === 'number') {
      this._height = `${height}px`;
    } else {
      this._height = height;
    }
  }

  editorHasFocus = false;
  isDisabled = false;

  value = '';
  showPreview = false;
  @Input() showLineNumbers = false;
  fullScreen = false;
  private _currentTextType = TextTypes.PARAGRAPH;
  lastSelection: { start: number, end: number } = { start: 0, end: 0 };
  insertLinkData = {
    text: '',
    url: '',
  };
  insertAlertData = {
    type: 'info',
    message: '',
  };
  modalOpened = false;
  defaultCodeMirrorOptions = {
    lineNumbers: this.showLineNumbers,
    theme: 'idea',
    mode: 'markdown',
    viewPortMargin: this.lines,
    extraKeys: {
      'Enter': this.handleEnterKey
    },
    placeholder: this.placeholder,
  };
  fileUrl = '';
  imageAlt = '';
  insertFileLabel = 'Insert Image File';
  fileRecord?: FileRecord;
  fileTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml', 'image/svg'];
  extensions = '';

  videoUrl = '';
  videoAlt = '';
  videoFileRecord?: VideoMetaData;
  uploadVideoModalRef!: NgbModalRef;

  //TODO: Figure out video file types and the extensions.

  @Input() set codeMirrorOptions(options: any) {
    this.defaultCodeMirrorOptions = {
      ...this.defaultCodeMirrorOptions,
      ...options
    };
  }

  get codeMirrorOptions() {
    return this.defaultCodeMirrorOptions;
  }

  formatButtons: ToolbarButtonData[] = [
    {
      icon: 'bi-type-bold',
      tooltip: 'Bold',
      action: (editor, selection) => {
        editor.replaceSelection(`**${selection}**`);
        if (!selection) {
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 2);
        }
      }
    },
    {
      icon: 'bi-type-italic',
      tooltip: 'Italic',
      action: (editor, selection) => {
        editor.replaceSelection(`*${selection}*`);
        if (!selection) {
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 1);
        }
      }
    },
    {
      icon: 'bi-type-strikethrough',
      tooltip: 'Strikethrough',
      action: (editor, selection) => {
        editor.replaceSelection(`~~${selection}~~`);
        if (!selection) {
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 2);
        }
      }
    }
  ];

  formatButtons2: ToolbarButtonData[] = [
    // Ordered List
    {
      icon: 'bi-list-ol',
      tooltip: 'Ordered List',
      action: (editor, selection) => {
        // If the selection is empty, insert a list item
        if (!selection) {
          editor.replaceSelection('1. ');
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch);
        } else {
          // If the selection is not empty, insert a list item for each line
          const lines = selection.split('\n');
          const orderedList = lines.map((line, index) => `${index + 1}. ${line}`).join('\n');
          editor.replaceSelection(orderedList);
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch);
        }
      }
    },
    // Unordered List
    {
      icon: 'bi-list-ul',
      tooltip: 'Unordered List',
      action: (editor, selection) => {
        if (!selection) {
          editor.replaceSelection('- ');
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch);
        } else {
          const lines = selection.split('\n');
          const unorderedList = lines.map(line => `- ${line}`).join('\n');
          editor.replaceSelection(unorderedList);
          editor.setCursor(editor.getCursor().line, editor.getCursor().ch);
        }
      }
    },
    // Subscript
    {
      icon: 'bi-subscript',
      tooltip: 'Subscript',
      action: (editor, selection) => {
        if (!selection) {
          return;
        }
        editor.replaceSelection(`<sub>${selection}</sub>`);
      }
    },
    // Superscript
    {
      icon: 'bi-superscript',
      tooltip: 'Superscript',
      action: (editor, selection) => {
        if (!selection) {
          return;
        }
        editor.replaceSelection(`<sup>${selection}</sup>`);
      }
    }
  ];

  insertButtons: ToolbarButtonData[] = [
    {
      icon: 'bi-link-45deg',
      tooltip: 'Insert Link',
      action: (editor, selection) => {
        if (!selection) {
          this.openModal(this.insertLinkModal, 'sm');
          return;
        }
        editor.replaceSelection(`[${selection}]()`);
        editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 1);
      }
    },
    // Horizontal Rule
    {
      icon: 'bi-dash-lg',
      tooltip: 'Horizontal Rule',
      action: (editor) => {
        const line = editor.getCursor().line;
        const lineText = editor.getLine(line);
        const previousLineText = editor.getLine(line - 1);
        if (lineText === '' && previousLineText === '') {
          editor.replaceRange('---\n', { line, ch: 0 });
        } else if (lineText === '') {
          editor.replaceRange('\n---', { line, ch: 0 });
        } else if (lineText) {
          editor.replaceSelection('\n\n---\n');
        } else {
          editor.replaceSelection('\n---\n');
        }
      }
    },
    // Table
    {
      icon: 'bi-table',
      tooltip: 'Table',
      action: (editor) => {
        // TODO: Add modal for table
        const defaultTable = '| Header 1 | Header 2 | Header 3 |\n| --- | --- | --- |\n| Cell 1 | Cell 2 | Cell 3 |';
        editor.replaceSelection(defaultTable);
        // Select the first header
        editor.setSelection({ line: editor.getCursor().line, ch: 2 }, { line: editor.getCursor().line, ch: 8 });
      }
    },
    // Code Snippet
    {
      icon: 'bi-code-square',
      tooltip: 'Code Snippet',
      action: (editor, selection) => {
        if (!selection) {
          editor.replaceSelection('```\n\n```');
          editor.setCursor(editor.getCursor().line - 1, 0);
        } else {
          const startPos = editor.getCursor('start');
          if (selection.split('\n').length > 1) {
            editor.replaceSelection(`\`\`\`\n${selection}\n\`\`\``);
            // Move to the start of the selection
            editor.setCursor(startPos.line, startPos.ch + 3);
          } else {
            editor.replaceSelection(`\`${selection}\``);
          }
        }
      }
    },
    // Image
    {
      icon: 'bi-image',
      tooltip: 'Image',
      action: (editor, selection) => {
        // If no selection ![alt]() is inserted, highlighting alt text
        this.extensions = imageExtensions;
        if (!selection) {
          this.openModal(this.insertImageModal, 'sm');
          return;
        }

        editor.replaceSelection(`![${selection}]()`);
        // Set the cursor to inside the parentheses
        editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 1);
      }
    },

    // Video
    {
      icon: 'bi-camera-video',
      tooltip: 'Video',
      action: (editor, selection) => {
        if (!selection) {
          this.openModal(this.insertVideoModal, 'lg');
          return;
        }
        editor.replaceSelection(`![${selection}]`);
        editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 1);
      }
    },
    // Alert
    {
      icon: 'bi-exclamation-square',
      tooltip: 'Alert',
      action: (editor, selection) => {
        if (!selection) {
          this.openModal(this.insertAlertModal, 'sm');
          return;
        }
        editor.replaceSelection(`!info ${selection}`);
        // Highlight info
        editor.setSelection({ line: editor.getCursor().line, ch: 1 }, { line: editor.getCursor().line, ch: 5 });
      }
    },
    //pdf
    {
      icon: 'bi-file-earmark-pdf', tooltip: 'PDF', action: (editor, selection) => {
        this.extensions = pdfExtensions;
        if (!selection) {
          this.openModal(this.insertPdfModal, 'sm');
          return;
        }
        editor.replaceSelection(`![${selection}]`);
        editor.setCursor(editor.getCursor().line, editor.getCursor().ch - 1);
      }
    }
  ];

  viewButtons: ToolbarButtonData[] = [
    {
      icon: 'bi-eye',
      tooltip: 'Toggle Preview',
      toggleIcon: 'bi-eye-slash',
      getToggleState: () => this.showPreview,
      action: () => {
        this.showPreview = !this.showPreview;
      },
    },
    // {
    //   icon: 'bi-fullscreen',
    //   tooltip: 'Toggle Fullscreen',
    //   toggleIcon: 'bi-fullscreen-exit',
    //   getToggleState: () => this.fullScreen,
    //   action: () => {
    //     this.fullScreen = !this.fullScreen;
    //   },
    // }
  ];

  toggleNgClass(button: ToolbarButtonData) {
    if (!button.toggleIcon)
      return {};
    if (!button.getToggleState)
      return {};
    return {
      [button.toggleIcon]: button.getToggleState(),
      [button.icon]: !button.getToggleState()
    };
  }

  insertLink() {
    if (!this.editorCodemirror) {
      return;
    }

    const doc = this.editorCodemirror.getDoc();
    const from = doc.posFromIndex(this.lastSelection.start);
    const to = doc.posFromIndex(this.lastSelection.end);

    doc.replaceRange(`[${this.insertLinkData.text}](${this.insertLinkData.url})`, from, to);

    this.editorCodemirror.focus();
  }

  insertAlert() {
    if (!this.editorCodemirror) {
      return;
    }

    const doc = this.editorCodemirror.getDoc();
    const from = doc.posFromIndex(this.lastSelection.start);
    const to = doc.posFromIndex(this.lastSelection.end);

    doc.replaceRange(`!${this.insertAlertData.type} ${this.insertAlertData.message}\n\n`, from, to);

    this.editorCodemirror.focus();
  }

  insertFile() {
    if (!this.editorCodemirror) {
      return;
    }

    const doc = this.editorCodemirror.getDoc();
    const from = doc.posFromIndex(this.lastSelection.start);
    const to = doc.posFromIndex(this.lastSelection.end);
    if (!this.fileRecord) {
      if (this.extensions === pdfExtensions) {
        doc.replaceRange(`![PDF DOCUMENT](pdf@${this.fileUrl})`, from, to);
      }
      if (this.extensions === imageExtensions) {
        doc.replaceRange(`![${this.imageAlt}](${this.fileUrl})`, from, to);
      }

    } else {
      const fileName = this.fileRecord.fileName;
      const url = this.api.endpoint(`file-records/${this.fileRecord.id}/public-view`);
      if (this.extensions === pdfExtensions) {
        doc.replaceRange(`![${fileName}](pdf@${url})`, from, to);
      }
      if (this.extensions === imageExtensions) {
        doc.replaceRange(`![${fileName}](${url})`, from, to);
      }
    }

    this.fileUrl = '';
    this.imageAlt = '';
    this.fileRecord = undefined;
  }

  openImageFileModal() {
    this.openModal(this.insertFileModal);
  }

  openInsertPdfModal() {
    this.fileTypes = ['application/pdf'];
    this.insertFileLabel = 'Insert PDF File';
    this.openImageFileModal();
  }

  onFileSelect(event: FileRecord) {
    this.fileRecord = event;
    this.insertFile();
    this.closeModal();
  }

  insertVideo() {
    if (!this.editorCodemirror) {
      return;
    }

    const doc = this.editorCodemirror.getDoc();
    const from = doc.posFromIndex(this.lastSelection.start);
    const to = doc.posFromIndex(this.lastSelection.end);

    if (!this.videoFileRecord) {
      doc.replaceRange(`![${this.videoAlt}](video@${this.videoUrl})`, from, to);
      return;
    }

    const videoName = this.videoFileRecord.title;

    doc.replaceRange(`![${videoName}](video@${this.videoFileRecord.id})`, from, to);

    this.videoUrl = '';
    this.videoAlt = '';
    this.videoFileRecord = undefined;
  }

  openSelectVideoModal() {
    this.openModal(this.selectVideoModal);
  }

  openUploadVideoModal() {
    this.uploadVideoModalRef = this.openModal(this.uploadVideoModal);
  }

  onVideoSelect(video?: VideoMetaData) {
    this.videoFileRecord = video;
    this.insertVideo();
    this.closeModal();
  }


  constructor(private settings: SettingsService,
    private modalService: NgbModal,
    private api: ApiService,
    private userService: UserService,
    private cdr: ChangeDetectorRef) {
  }

  get userEmail() {
    return this.userService.currentUser?.email || '';
  }


  ngOnInit() {
    if (this.useMarkdown === undefined) {
      this.useMarkdown = this.settings.getSettingValue('defaultToMarkdownEditor');
    }

    let focusTimeout: any;

    this.editorHasFocus$.subscribe(hasFocus => {
      this.editorHasFocus = hasFocus;
      if (this.showToolbarOnFocus) {
        if (hasFocus) {
          clearTimeout(focusTimeout);
          this.showToolbar = true;
          this.cdr.detectChanges();
        } else {
          focusTimeout = setTimeout(() => {
            this.showToolbar = false;
            this.cdr.detectChanges();
          }, 10);
        }
      }
    });
  }

  get editorCodemirror() {
    return this.editor?.codeMirror;
  }

  onChange: (value: string) => void = () => {
  };
  onTouched: () => void = () => {
  };

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  get multilineHeight(): string {
    return `${this.lines * 2}em`;
  }

  get selection(): string {
    return this.editorCodemirror?.getSelection() || '';
  }

  openModal(content: any, size: 'sm' | 'lg' | 'xl' = 'lg') {
    this.modalOpened = true;
    return this.modalService.open(content, { size, centered: true });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.modalOpened = false;
  }

  get allTextTypes(): TextType[] {
    return Object.values(TextTypes);
  }

  get selectableTextTypes(): TextType[] {
    return this.allTextTypes.filter(textType => textType !== this.currentTextType);
  }

  get currentTextType(): TextType {
    return this._currentTextType;
  }

  mdAction(btn: ToolbarButtonData) {
    if (!this.editorCodemirror) {
      return;
    }
    if (this.editorHasFocus) {
      btn.action(this.editorCodemirror, this.selection);
      this.editorCodemirror.focus();
    }
  }

  handleFocusChange(focused: boolean) {
    this.editorHasFocus = focused;
    this.setEditorHasFocus(focused);
    if (focused) {
      this.editorFocus.emit();
    }

    const editor = this.editorCodemirror;
    if (editor && focused) {
      const selectionStart = editor.getCursor('start');
      const selectionEnd = editor.getCursor('end');

      this.lastSelection = {
        start: editor.indexFromPos(selectionStart),
        end: editor.indexFromPos(selectionEnd),
      };
    }
  }

  handleEnterKey(editor: Editor) {
    const cursor = editor.getCursor();
    const cursorLine = cursor.line;
    const currentLine = editor.getLine(cursorLine);
    const orderedListRegex = /^\d+\.\s/;
    const unorderedListRegex = /^[*-]\s/;

    const isOrderedList = (line: string) => {
      return orderedListRegex.test(line);
    };

    const isUnorderedList = (line: string) => {
      return unorderedListRegex.test(line);
    };

    const totalLines = editor.lineCount();

    if (isOrderedList(currentLine)) {
      const currentNumber = parseInt(currentLine.match(/^\d+/)?.[0] || '0', 10);

      // Check if the list item is empty
      if (currentLine.match(/^\d+\.\s+$/)) {
        editor.replaceRange('', { line: cursorLine, ch: 0 }, { line: cursorLine + 1, ch: 0 });
      } else {
        editor.replaceSelection(`\n${currentNumber + 1}. `);
      }

      // Start renumbering from the current line where Enter was pressed
      let nextLine = cursorLine + 1;
      let nextNumber = currentNumber;

      // Renumber all subsequent list items
      while (nextLine <= totalLines) {
        const nextLineText = editor.getLine(nextLine);
        if (isOrderedList(nextLineText)) {
          nextNumber++;
          editor.replaceRange(`${nextNumber}.`, { line: nextLine, ch: 0 }, {
            line: nextLine,
            ch: String(nextNumber).length + 1
          });
          // If the currentNumber is more than one digit, we need to add an extra space
          if (nextNumber > 9) {
            editor.replaceRange(' ', { line: nextLine, ch: String(nextNumber).length + 1 }, {
              line: nextLine,
              ch: String(nextNumber).length + 2
            });
          }
        } else {
          break;
        }
        nextLine++;
      }
      return;
    }

    if (isUnorderedList(currentLine)) {
      // Check if the list item is empty
      // Get the current list item prefix
      const prefix = currentLine.match(/^[*-]\s+/)?.[0] || '';
      if (currentLine.match(/^[*-]\s+$/)) {
        editor.replaceRange('', { line: cursorLine, ch: 0 }, { line: cursorLine + 1, ch: 0 });
      } else {
        editor.replaceSelection(`\n${prefix}`);
      }
      return;
    }

    return CodeMirror.Pass;
  }

  handleCursorActivity(editor: Editor) {

    let updatedTextType = TextTypes.PARAGRAPH;  // Default to paragraph
    const line = editor.getCursor().line;
    const lineText = editor.getLine(line);

    let inCodeBlock = false;
    const headings = [
      TextTypes.HEADING_1,
      TextTypes.HEADING_2,
      TextTypes.HEADING_3,
      TextTypes.HEADING_4,
      TextTypes.HEADING_5,
      TextTypes.HEADING_6,
    ];

    const codeBlockRegex = /^```/;
    for (let i = 0; i < line; i++) {
      if (codeBlockRegex.test(editor.getLine(i))) {
        inCodeBlock = !inCodeBlock;
      }
    }

    if (inCodeBlock) {
      updatedTextType = TextTypes.CODE_BLOCK;
    } else if (lineText) {
      const h1UnderlineRegex = /^=+$/;
      const h2UnderlineRegex = /^-+$/;
      const headerRegex = /^#{1,6}\s/;

      if (line < editor.lineCount() - 1) {
        const nextLineText = editor.getLine(line + 1);
        if (h1UnderlineRegex.test(nextLineText)) {
          updatedTextType = TextTypes.HEADING_1;
        } else if (h2UnderlineRegex.test(nextLineText)) {
          updatedTextType = TextTypes.HEADING_2;
        }
      }

      if (headerRegex.test(lineText)) {
        const hashCount = (lineText.match(/^#{1,6}/)?.[0].length) || 0;
        updatedTextType = headings[hashCount - 1];
      }

      if (lineText.startsWith('>')) {
        updatedTextType = TextTypes.BLOCKQUOTE;
      }
    }

    this._currentTextType = updatedTextType;
  }

  setCurrentTextType(textType: TextType) {
    if (!this.editorCodemirror) {
      return;
    }
    // Get the document
    const doc = this.editorCodemirror.getDoc();
    const from = doc.posFromIndex(this.lastSelection.start);
    const to = doc.posFromIndex(this.lastSelection.end);

    // Get the selected text
    const selection = doc.getRange(from, to);
    const isMultiline = selection.split('\n').length > 1;

    // Determine the appropriate prefix and suffix based on conditions
    const prefix = isMultiline || !textType.inline ? textType.prefix : textType.inlinePrefix || '';
    const suffix = isMultiline || !textType.inline ? (textType.suffix || '') : (textType.inlineSuffix || '');

    // Replace the selected text with the new text type
    doc.replaceRange(`${prefix}${selection}${suffix}`, from, to);


    // Set the current text type
    this._currentTextType = textType;

    if (textType.multiline) {
      // Move the cursor to right after the prefix trimmed of whitespace
      // Use from and to get the line number
      const prefixLength = prefix.length;
      const line = from.line;
      const ch = from.ch + prefixLength;
      this.editorCodemirror.setCursor(line, ch);
    }

    // Focus the editor
    this.editorCodemirror.focus();
  }

  setEditorHasFocus(hasFocus: boolean) {
    this.editorHasFocus$.next(hasFocus);
  }


  onVideoUploadComplete($event: any) {
    this.uploadVideoModalRef.close();
    this.onVideoSelect($event);
  }
}
