<div *ngIf="cohort" class="container">
  <div class="row">
    <div class="col-12 col-md-8 my-2">
      <div class="card mb-2">
        <div class="card-body">
          <div class="mb-3 py-2 border-bottom d-flex justify-content-between align-items-center">
            <h3 class="card-title"><span class="bi bi-people text-primary"></span> Cohort Info</h3>
            <button class="btn btn-primary" [routerLink]="['/training', 'cohorts', 'edit', cohort.id]">
              Edit <span class="bi bi-pencil-square"></span>
            </button>
          </div>
          <h4 class="h4">{{cohort.name}}</h4>
          <div *ngIf="cohortStartDate" class="text-muted">
            {{ cohortStartDate | date:'MM/dd/yyyy' }} - {{ cohortEndDate | date:'MM/dd/yyyy' }}
          </div>
          <div class="my-2 d-flex flex-wrap gap-1">
          <span *ngFor="let tag of cohort.technologies" class="badge bg-info rounded-pill">
            {{tag}}
          </span>
          </div>
          <div class="my-3">
            <p class="card-text">{{cohort.description}}</p>
          </div>
          <div *ngIf="cohort.gitlabGroup" class="my-3 border rounded p-3">
            <h5 class="h5 mb-3"><span class="bi bi-gitlab text-primary"></span> Gitlab Groups</h5>
            <div class="d-flex flex-wrap gap-2">
              <a class="btn btn-outline-primary" [href]="cohort.gitlabGroup.groupUrl" target="_blank">
                Cohort Group <span class="bi bi-folder"></span>
              </a>
              <a class="btn btn-outline-primary" [href]="cohort.gitlabGroup.technologyGroupUrl" target="_blank">
                Technology <span class="bi bi-folder"></span>
              </a>
              <a class="btn btn-outline-primary" [href]="cohort.gitlabGroup.organizationsGroupUrl" target="_blank">
                Organizations <span class="bi bi-folder"></span>
              </a>
              <a class="btn btn-outline-primary" [href]="cohort.gitlabGroup.resourcesGroupUrl" target="_blank">
                Resources <span class="bi bi-folder"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div class="card-body">
          <h5 class="card-title">Learning Activities</h5>
          <p class="card-text text-muted small">
            <span class="bi bi-info-circle"></span> View and manage learning activities for this cohort.
          </p>
          <div class="my-2 w-100 d-flex justify-content-start gap-1 align-items-center flex-row-reverse">
            <button class="btn btn-light" (click)="openFiltersModal()">
              Filters <span class="bi bi-funnel"></span>
            </button>
            <div ngbDropdown>
              <button ngbDropdownToggle class="btn btn-light">
                Sort by:  <span class="bi bi-sort-alpha-down"></span>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="sortLearningActivities('id,asc')">ID</button>
                <button ngbDropdownItem (click)="sortLearningActivities('progress,asc')">Least Progress</button>
                <button ngbDropdownItem (click)="sortLearningActivities('progress,desc')">Most Progress</button>
                <button ngbDropdownItem (click)="sortLearningActivities('student_id,asc')">Student ID (Asc)</button>
                <button ngbDropdownItem (click)="sortLearningActivities('student_id,desc')">Student ID (Desc)</button>
                <button ngbDropdownItem (click)="sortLearningActivities('assignedAt,desc')">Recently Assigned</button>
              </div>
            </div>
            <button class="btn btn-primary" (click)="openAssignToCohortModal()">
              Assign to Cohort <span class="bi bi-plus-circle"></span>
            </button>
          </div>

          <table class="table table-striped">
            <thead>
            <tr>
              <th>Type</th>
              <th>Student</th>
              <th>Title</th>
              <th>Progress</th>
              <th>Assigned</th>
              <th>
                <span class="visually-hidden">Actions</span>
              </th>
            </tr>
            </thead>
            <tbody *ngIf="learningActivities.length; else noLearningActivities">
            <tr *ngFor="let activity of learningActivities">
              <td>
                <app-content-icon [materialType]="activity.type" [ngbTooltip]="activity.type | titlecase"></app-content-icon>
              </td>
              <td>
                {{ activity.student.firstName }} {{ activity.student.lastName }}
              </td>
              <td>
                <a class="btn btn-link text-start" (click)="goToLearningActivity(activity)">
                  {{activity.title}}
                </a>
              </td>
              <td>
                <app-percent-circle [percent]="activity.progress" size="xxs"></app-percent-circle>
              </td>
              <td>
                {{activity.assignedAt | date:'MM/dd/yyyy'}}
              </td>
              <td>
                <div ngbDropdown>
                  <button class="btn btn-outline-primary border-0 dropdown-no-caret" ngbDropdownToggle>
                    <span class="bi bi-three-dots-vertical"></span>
                  </button>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem (click)="goToLearningActivity(activity)">
                      <span class="bi bi-eye me-2"></span> View
                    </button>
                    <button ngbDropdownItem class="text-danger" (click)="deleteLearningActivity(activity)">
                      <span class="bi bi-trash me-2"></span> Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <ng-template #noLearningActivities>
              <tbody>
              <tr>
                <td colspan="5" class="text-muted text-center">
                  <span class="bi bi-info-circle"></span> No learning activities found.
                </td>
              </tr>
              </tbody>
            </ng-template>
          </table>
          <div class="my-2 d-flex justify-content-center align-items-center">
            <ngb-pagination [collectionSize]="totalLearningActivities" [maxSize]="10" [boundaryLinks]="true" [(page)]="page" (pageChange)="loadLearningActivities(cohort.id!)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 my-2">
      <div class="card">
        <div class="card-body">
          <h5 class="h5">Students <span class="badge rounded-circle bg-primary">{{cohort.students.length}}</span> </h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let student of cohort.students">
              <div class="d-flex align-items-center">
                <div class="me-2 align-self-start">
                  <span class="bi bi-person-circle text-muted fs-4"></span>
                </div>
                <div class="flex-grow-1">
                  <div class="fw-bold">
                    {{student.firstName}} {{student.lastName}}
                  </div>
                  <div class="text-info x-small">
                    <span class="bi bi-envelope-at"></span> {{student.email}}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filtersModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Filters</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="my-3">
      <h5 class="h5">Activity Types</h5>
      <p class="text-muted small">
        <span class="bi bi-info-circle"></span> Filter learning activities by type.
      </p>
      <app-multi-select-field [(ngModel)]="filter.types" [options]="typesOptions" placeholder="Select activity types..."></app-multi-select-field>
    </div>
    <div class="my-3">
      <h5 class="h5">Learning Material</h5>
      <p class="text-muted small">
        <span class="bi bi-info-circle"></span> Filter learning activities by learning material.
      </p>
      <app-multi-select-field [(ngModel)]="filter.materialIds" [options]="distinctMaterials" placeholder="Select learning materials..."></app-multi-select-field>
    </div>
    <div class="my-3 text-end">
      <button class="btn btn-sm btn-light" (click)="clearFilters()">
        Clear Filters <span class="bi bi-x-circle"></span>
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="loadLearningActivities(cohort?.id!); modal.dismiss()">Apply Filters</button>
  </div>
</ng-template>

<ng-template #assignToCohortModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Assign Learning Material to Cohort</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="my-3">
      <p class="text-muted small">
        <span class="bi bi-info"></span> Select learning material to assign to this cohort.
      </p>

      <ul class="nav nav-tabs">
        <li class="nav-item">
          <button class="nav-link"
                  (click)="setAssignFilterType('COURSE')"
                  [class.active]="assignFilterType === 'COURSE'">
            Courses
          </button>
        </li>
        <li class="nav-item">
          <button class="nav-link"
                  (click)="setAssignFilterType('MODULE')"
                  [class.active]="assignFilterType === 'MODULE'">
            Modules
          </button>
        </li>
        <li class="nav-item">
          <button class="nav-link"
                  (click)="setAssignFilterType('LESSON')"
                  [class.active]="assignFilterType === 'LESSON'">
            Lessons
          </button>
        </li>
        <li class="nav-item">
          <button class="nav-link"
                  (click)="setAssignFilterType('ASSIGNMENT')"
                  [class.active]="assignFilterType === 'ASSIGNMENT'">
            Assignments
          </button>
        </li>
        <li class="nav-item">
          <button class="nav-link"
                  (click)="setAssignFilterType('QUIZ')"
                  [class.active]="assignFilterType === 'QUIZ'">
            Quizzes
          </button>
        </li>
      </ul>

      <div *ngIf="!loadingMaterials; else materialsSpinner" class="list-group list-group-flush">
        <div *ngFor="let material of assignMaterials" class="list-group-item">
          <div class="d-flex justify-content-between">
            <div class="d-flex me-2 align-items-center gap-2">
              <app-content-icon [materialType]="material.materialType"></app-content-icon>
              {{ material.title }}
            </div>
            <div class="text-end align-items-center d-flex">
              <app-assign-learning-material-modal-button [content]="material" [selectedCohorts]="[cohort!]"></app-assign-learning-material-modal-button>
            </div>
          </div>
        </div>
      </div>

      <ng-template #materialsSpinner>
        <div class="d-flex justify-content-center align-items-center my-3">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Close</button>
  </div>
</ng-template>
