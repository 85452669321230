import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailVerificationService } from '@app/api/user/services/email-verification.service';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-email-verification-page',
  templateUrl: './email-verification-page.component.html',
  styleUrls: ['./email-verification-page.component.sass']
})
export class EmailVerificationPageComponent implements OnInit {

  token?: string;
  verified = false;
  verifying = false;

  constructor(private route: ActivatedRoute,
    private emailVerificationService: EmailVerificationService,
    private router: Router,
    private localStorage: LocalStorageService) {
    if (!this.token) {
      this.localStorage.setItem('email-verify-token', this.route.snapshot.queryParams['token']);
      this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
    }
  }

  ngOnInit() {
    if (!this.token) {
      this.token = this.localStorage.getItem('email-verify-token') || undefined;
      this.localStorage.removeItem('email-verify-token');
    }

    if (this.token) {
      this.verifyEmail();
    }
  }

  verifyEmail() {
    this.verifying = true;
    this.emailVerificationService.verifyEmail(this.token!).subscribe({
      next: (response) => {
        this.verified = response.verified;
        this.verifying = false;
      },
      error: (error) => {
        console.error(error);
        this.verifying = false;
      },
      complete: () => {
        this.verifying = false;
      }
    });
  }

}
