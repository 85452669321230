import { Component, Input, OnInit } from '@angular/core';
import { LessonActivityTree } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-lesson-tree-view',
  templateUrl: './lesson-tree-view.component.html',
  styleUrls: ['./lesson-tree-view.component.sass']
})
export class LessonTreeViewComponent implements OnInit {

  @Input({ required: true }) lessonTree!: LessonActivityTree;
  @Input() root = true;
  lessonCollapsed = true;

  get lessonIndent() {
    return {
      'padding-left': this.root ? '1rem' : '4rem'
    };
  }

  get assignmentIndent() {
    return {
      'padding-left': this.root ? '2rem' : '6rem'
    };
  }

  ngOnInit() {
    // Sort assignments by order
    this.lessonTree.assignments.sort((a, b) => a.order - b.order);
  }

}
