import { Component } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ActivatedRoute } from '@angular/router';
import { LearningActivity } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-learning-activity-page',
  templateUrl: './learning-activity-page.component.html',
  styleUrls: ['./learning-activity-page.component.sass']
})
export class LearningActivityPageComponent {

  learningActivity?: LearningActivity;

  constructor(
    private learningActivityService: LearningActivityService,
    private route: ActivatedRoute) {

    const learningActivityId = Number(this.route.snapshot.paramMap.get('id'));
    this.learningActivityService.getLearningActivity(learningActivityId).subscribe(learningActivity => {
      this.learningActivity = learningActivity;
    });
  }

}
