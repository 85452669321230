<div class="card wh-100">
  <div class="card-header">
    <h5 class="card-title mb-0">Recent Activities</h5>
  </div>
  <div class="card-body overflow-y-auto">
    <div *ngIf="!loading; else spinner" class="list-group">
      <ng-container *ngIf="recentActivities && recentActivities.length > 0; else noActivities">
        <div *ngFor="let activity of recentActivities" class="list-group-item">
          <div class="d-flex">
            <div class="d-flex flex-column text-wrap flex-grow-1 text-wrap">
              <span class="fw-bold text-primary">{{activity.type | titlecase}}</span>
              <a class="text-black" [routerLink]="getLearningActivityLink(activity)">
                <div class="text-truncate">{{ activity.title }}</div>
              </a>
            </div>
            <div class="d-flex align-items-end text-muted x-small">
              Last updated {{ activity.updatedAt | date: 'MM/dd/yyyy hh:mm aa' }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="d-flex justify-content-center align-items-center wh-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #noActivities>
  <div class="d-flex justify-content-center align-items-center wh-100">
    <div class="text-muted">No recent activities</div>
  </div>
</ng-template>
