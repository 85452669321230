<form [formGroup]="videoUploadForm">
  <div class="my-3">
    <app-file-upload-field
      controlName="file"
      acceptedExtensions=".mp4"
      label="Drag and drop video here">
    </app-file-upload-field>
  </div>
  <div class="my-3">
    <app-text-field
      controlName="title"
      label="Title">
    </app-text-field>
  </div>

  <div class="my-3">
    <app-markdown-editor
      formControlName="description"
      [useMarkdown]="false"
      placeholder="Description"
      [showPreviewButton]="false">
    </app-markdown-editor>
    <app-control-validation-message
      [control]="videoUploadForm.controls.description">
    </app-control-validation-message>
  </div>

  <div class="my-3">
    <app-text-field
      controlName="tags"
      label="Tags"></app-text-field>
    <!--suppress XmlInvalidId -->
    <label for="tags" class="ms-1 text-muted small">
      Separate tags by commas. (e.g training,internal,java)
    </label>
  </div>

  <div class="my-3">
    <app-file-upload-field
      controlName="thumbnailFile"
      [previewImage]="true"
      restrictAspectRatio="16x9"
      acceptedExtensions=".jpg,.jpeg,.png,.gif"
      label="Drag and drop thumbnail here">
    </app-file-upload-field>

  </div>
</form>
