import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { ModuleData } from '@app/api/models/learning-material-data.model';
import { ModuleApiService } from '@app/api/module/services/module-api.service';

@Component({
  selector: 'app-module-view',
  templateUrl: './module-view.component.html',
  styleUrls: ['./module-view.component.sass']
})
export class ModuleViewComponent implements OnInit {
  @Input({ required: true }) moduleId!: number;
  @Input({ required: true }) learningActivity!: LearningActivity;

  module?: ModuleData;

  constructor(private moduleApi: ModuleApiService) {
  }

  ngOnInit() {
    this.moduleApi.getModuleById(this.moduleId).subscribe(module => {
      this.module = module;
    });
  }



}
