import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

export type quizQuestionState =
  | 'correct'
  | 'incorrect'
  | 'unanswered'
  | 'answered';

@Component({
  selector: 'app-quiz-question-stepper',
  templateUrl: './quiz-questions-stepper.component.html',
  styleUrls: ['./quiz-questions-stepper.component.sass'],
  providers: [
    { provide: CdkStepper, useExisting: QuizQuestionsStepperComponent },
  ],
})
export class QuizQuestionsStepperComponent
  extends CdkStepper
  implements AfterViewInit
{
  @ViewChild('verticalContainer') verticalContainer!: ElementRef;

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.verticalContainer) {
      this.verticalContainer.nativeElement.addEventListener(
        'scroll',
        this.onScroll.bind(this),
      );
    }
  }

  onScroll() {
    const container = this.verticalContainer.nativeElement;
    const steps = container.querySelectorAll('.cdk-step');
    let selectedIndex = this.selectedIndex;

    steps.forEach((step: HTMLElement, index: number) => {
      const rect = step.getBoundingClientRect();

      console.log('container.clientHeight', container.clientHeight);

      if (
        rect.top < container.clientHeight / 2 &&
        rect.bottom > container.clientHeight / 2
      ) {
        selectedIndex = index;
      }

      // if (rect.top < 200 && rect.bottom > 200) {
      //   selectedIndex = index;
      // }
    });

    this.selectedIndex = selectedIndex;
  }

  onSelected(index: number) {
    this.selectedIndex = index;

    // // Scroll to the selected step
    // const container = this.verticalContainer.nativeElement;
    // const steps = container.querySelectorAll('.cdk-step');
    // const selectedStep = steps[index] as HTMLElement;
    // container.scrollTo({
    //   top: selectedStep.offsetTop - 400,
    //   behavior: 'smooth',
    // });
  }
}
