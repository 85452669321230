<div class="mb-2 mx-2 py-3" *ngIf="lessonData">
  <div class="mb-3">
    <h4 class="h4">Lesson Details</h4>

    <p class="text-muted small">
      A lesson is comprised of a presentation/lecture recording on a topic followed by one or more assignments that students may complete.
    </p>
  </div>

  <div class="my-3">
    <div class="form-floating">
      <input type="text" id="title" class="form-control" placeholder="Title" [(ngModel)]="lessonData.title">
      <label for="title">Title</label>
    </div>
  </div>

  <div class="my-3">
    <div class="form-floating">
      <textarea id="description" class="form-control" placeholder="Description" [ngStyle]="{ 'height.em': 7 }"
        [(ngModel)]="lessonData.description" [formControl]="lessonDescriptionControl"
        (input)="onDescriptionInput()"></textarea>
      <label for="description">Description</label>
      <div class="d-flex justify-content-between align-items-center">
        <app-control-validation-message [control]="lessonDescriptionControl"></app-control-validation-message>
        <span class="flex-grow-3"></span>
        <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
          {{ lessonDescriptionControl.value.length }}/{{ maxDescriptionLength }}
        </span>
      </div>
    </div>
  </div>

  <div class="my-3">
    <h5 class="h5">
      Lecture Slides
    </h5>
    <p class="text-muted small">
      Upload or select lecture slides for this lesson.
    </p>
    <div *ngIf="!presentationFileRecord; else slidePreview" class="card card-body">
      <button type="button" class="btn btn-outline-primary" (click)="openSelectLectureSlideModal()">
        Select Lecture Slides <span class="bi bi-file-earmark-slides"></span>
      </button>
    </div>
    <ng-template #slidePreview>
      <div class="card card-body">
        <div class="d-flex align-items-center">
          <span class="bi bi-file-earmark-slides text-primary fs-3"></span>
          <div class="ms-2">
            {{presentationFileRecord?.fileName}}
            <div class="text-muted small">
              {{presentationFileRecord?.size || 0 | fileSize}}
            </div>
          </div>
          <div class="flex-grow-1 text-end">
            <button type="button" class="btn btn-link text-danger" (click)="removePresentationAttachment()">
              <span class="bi bi-x fs-5"></span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="my-3">
    <h5 class="h5">
      Lesson Content
    </h5>
    <p class="text-muted small">
      Provide the lesson content or lecture notes. Content can be formatted using markdown.
    </p>
    <app-markdown-editor [(ngModel)]="lessonData.content" placeholder="Lesson content..." [codeMirrorOptions]="{
        lineWrapping: true,
      }"></app-markdown-editor>
  </div>

  <div class="my-3">
    <div class="d-flex flex-row align-items-center w-100">
      <div class="flex-grow-1">
        <h5 class="h5">
          Assignments
        </h5>
        <p class="text-muted small">
          Drag and drop to reorder assignments.
        </p>
      </div>
      <div *ngIf="lessonData.assignments && lessonData.assignments.length" class="text-end d-flex align-items-center justify-content-center">
        <button (click)="openAddMaterialModal('ASSIGNMENT')" class="btn btn-primary mx-2" type="button">
          <span class="bi bi-file-earmark-plus"></span>
          Add Assignment
        </button>
        <p *ngIf="disableCreateAssignment" class="my-0 mx-2">Or</p>
        <button type="button" class="btn btn-primary mx-2" *ngIf="disableCreateAssignment" (click)="createAssignment()">
          <span class="bi bi-file-earmark-plus"></span>
          Create Assignment
        </button>
      </div>
    </div>
    <div class="w-100 overflow-x-auto bg-light py-2 rounded">
      <div class="d-flex flex-row"
           cdkDropList
           [cdkDropListData]="lessonData.assignments"
           cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="onMoveItem($event)">
        <ng-container *ngIf="lessonData.assignments && lessonData.assignments.length; else noAssignments">
          <div class="drag-drop-card m-2" cdkDrag *ngFor="let assignment of lessonData.assignments">
            <button type="button" class="drag-drop-handle py-4 px-3" cdkDragHandle>
              <app-icon iconName="six-dots" class="svg-muted"></app-icon>
            </button>
            <div class="drag-drop-body d-flex flex-row flex-grow-0 w-100 py-2">
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <div class="py-2 px-3">
                  <span class="bi bi-file-earmark-text text-primary fs-3"></span>
                </div>
                <div class="m-2">
                  <div class="mb-1">
                    <span class="fw-bold">{{assignment.title}}</span>
                  </div>
                  <div class="text-muted small">
                    {{assignment.description}}
                  </div>
                </div>
              </div>
            </div>
            <div class="h-100 px-2 d-flex flex-column justify-content-between">
              <div class="align-self-start delete-btn">
                <button type="button" class="btn btn-link text-danger pe-0" (click)="removeAssignment(assignment)">
                  <span class="bi bi-x fs-5"></span>
                </button>
              </div>
              <div class="align-self-end">
                <span class="badge bg-primary rounded-circle my-2">
                  {{assignment.order + 1}}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noAssignments>
          <div class="my-4 text-center text-muted w-100">
            <div class="mb-3 fs-5">
              No assignments
            </div>
            <div class="d-flex align-items-center justify-content-center w-100 text-center">
              <button (click)="openAddMaterialModal('ASSIGNMENT')" class="btn btn-primary mx-2" type="button">
                <span class="bi bi-file-earmark-plus"></span>
                Add Assignment
              </button>
              <p *ngIf="disableCreateAssignment" class="my-0 mx-2">Or</p>
              <button *ngIf="disableCreateAssignment" type="button" class="btn btn-primary mx-2" (click)="createAssignment()">
                <span class="bi bi-file-earmark-plus"></span>
                Create Assignment
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="my-3">
    <div class="d-flex flex-row align-items-center w-100">
      <div class="flex-grow-1">
        <h5 class="h5">
          Quizzes
        </h5>
        <p class="text-muted small">
          Drag and drop to reorder quizzes.
        </p>
      </div>
      <div *ngIf="lessonData.quizzes && lessonData.quizzes.length"
           class="text-end d-flex align-items-center justify-content-center">
        <button (click)="openAddMaterialModal('QUIZ')" class="btn btn-primary mx-2" type="button">
          <span class="bi bi-file-earmark-plus"></span>
          Add Quiz
        </button>
        <p *ngIf="disableCreateAssignment" class="my-0 mx-2">Or</p>
        <button (click)="createAssignment()" *ngIf="disableCreateAssignment" class="btn btn-primary mx-2" type="button">
          <span class="bi bi-file-earmark-plus"></span>
          Create Quiz
        </button>
      </div>
    </div>
    <div class="w-100 overflow-x-auto bg-light py-2 rounded">
      <div (cdkDropListDropped)="onMoveItem($event)"
           [cdkDropListData]="lessonData.quizzes"
           cdkDropList
           cdkDropListOrientation="horizontal"
           class="d-flex flex-row">
        <ng-container *ngIf="lessonData.quizzes && lessonData.quizzes.length; else noQuiz">
          <div *ngFor="let quiz of lessonData.quizzes" cdkDrag class="drag-drop-card m-2">
            <button cdkDragHandle class="drag-drop-handle py-4 px-3" type="button">
              <app-icon class="svg-muted" iconName="six-dots"></app-icon>
            </button>
            <div class="drag-drop-body d-flex flex-row flex-grow-0 w-100 py-2">
              <div class="d-flex flex-row align-items-center flex-grow-1">
                <div class="py-2 px-3">
                  <span class="bi bi-file-earmark-text text-primary fs-3"></span>
                </div>
                <div class="m-2">
                  <div class="mb-1">
                    <span class="fw-bold">{{ quiz.title }}</span>
                  </div>
                  <div class="text-muted small">
                    {{ quiz.description }}
                  </div>
                </div>
              </div>
            </div>
            <div class="h-100 px-2 d-flex flex-column justify-content-between">
              <div class="align-self-start delete-btn">
                <button (click)="removeQuiz(quiz)" class="btn btn-link text-danger pe-0" type="button">
                  <span class="bi bi-x fs-5"></span>
                </button>
              </div>
              <div class="align-self-end">
                <span class="badge bg-primary rounded-circle my-2">
                  {{ quiz.order + 1 }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noQuiz>
          <div class="my-4 text-center text-muted w-100">
            <div class="mb-3 fs-5">
              No quizzes
            </div>
            <div class="d-flex align-items-center justify-content-center w-100 text-center">
              <button (click)="openAddMaterialModal('QUIZ')" class="btn btn-primary mx-2" type="button">
                <span class="bi bi-file-earmark-plus"></span>
                Add Quiz
              </button>
              <p *ngIf="disableCreateAssignment" class="my-0 mx-2">Or</p>
              <button (click)="createAssignment()" *ngIf="disableCreateAssignment" class="btn btn-primary mx-2"
                      type="button">
                <span class="bi bi-file-earmark-plus"></span>
                Create Quiz
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="mt-3 pb-5 mb-5">
    <h5 class="h5">
      Technology Tags
    </h5>
    <p class="text-muted small">
      Select what technologies this lesson covers.
    </p>
    <div class="pb-5 mb-5">
      <app-technology-tags-field [(ngModel)]="lessonData.technologies"></app-technology-tags-field>
    </div>
  </div>
</div>

<ng-template #addMaterialModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add {{ materialType | titlecase }} to Lesson</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <app-learning-material-selector
      (selectMaterial)="onSelectMaterial($event)"
      [materialFilter]="alreadySelectedFilter"
      [materialType]="materialType"
      [publishedOnly]="true">
    </app-learning-material-selector>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
    <button (click)="addAssignment(); modal.close()" class="btn btn-primary" type="button">
      Add {{ materialType | titlecase }}
    </button>
  </div>
</ng-template>

<ng-template #selectLectureSlideModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Select Lecture Slides</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body p-0">
    <app-file-explorer startPath="/"
                       uploadAcceptedExtensions=".ppt,.pptx,.doc,.docx,.md"
                       (fileSelected)="onFileSelected($event)"
                       [fileTypeFilter]="presentationFileTypes"
                       [userEmail]="userEmail"></app-file-explorer>
  </div>
</ng-template>
