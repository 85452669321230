import { Injectable } from '@angular/core';
import strings from '@app/resources/strings';

@Injectable({
  providedIn: 'root'
})
export class StringResourceService {

  private readonly strings = strings;
  private language = 'en_US';

  constructor() { }

  setLanguage(language: string): void {
    this.language = language;
  }

  $(key: string): string {
    return this.strings[this.language][key] ? this.strings[this.language][key] : key;
  }

  static sentenceCase = (value: string): string => {
    const result = value.toString().replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result?.slice(1);
  };

}
