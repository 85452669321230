import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { Discussion } from '../models/discussion.model';
import { environment } from '@env/environment';
import { AuthService } from '@app/auth/services/auth.service';
import { Page, PageableParams } from '@app/api/models/page.model';
import { TechnologyTag } from '@app/api/categorization/models/technology-tag.model';

@Injectable({
  providedIn: 'root'
})
export class DiscussionsService {
  [x: string]: any;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getMyPosts(): Observable<Discussion[]> {
    const token = this.authService.getToken();
    const userEmail = this.extractUserEmailFromToken(token);

    if (!userEmail) {
      console.error('User email not available.');
      return of([] as Discussion[]);
    }

    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/byUser?userEmail=${userEmail}`).pipe(
      catchError((error) => {
        console.error('Error fetching my posts:', error);
        return of([] as Discussion[]);
      })
    );
  }

  private extractUserEmailFromToken(token: string | null): string | null {
    if (!token) {
      console.error('Token not available.');
      return null;
    }
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userEmail = decodedToken?.email;
      return userEmail || null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  getAllPosts(pageable?: PageableParams): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/discussion/posts`, { params: pageable }).pipe(
      catchError((error) => {
        console.error('Error fetching all posts:', error);
        return of({ content: [], totalElements: 0 });
      })
    );
  }

  getTagsForPost(postId: number): Observable<TechnologyTag[]> {
    return this.http.get<TechnologyTag[]>(`${environment.apiUrl}/discussion/posts/${postId}/technology-tags`).pipe(
      catchError((error) => {
        console.error('Error fetching tags for post:', error);
        return of([] as TechnologyTag[]);
      })
    );
  }

  getPostById(postId: number): Observable<Discussion> {
    return this.http.get<Discussion>(`${environment.apiUrl}/discussion/posts/${postId}`);
  }

  getCommentsForPost(postId: number): Observable<Discussion[]> {
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/${postId}/comments`);
  }

  updateComment(commentId: number, updatedContent: string, postTypeId: number, parentPostId: number | null): Observable<Discussion> {
    const updateData = { content: updatedContent, postTypeId: postTypeId, parentPostId: parentPostId };
    return this.http.put<Discussion>(`${environment.apiUrl}/discussion/posts/${commentId}`, updateData);
  }

  deleteComment(commentId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/discussion/posts/${commentId}/comment`).pipe(
      catchError((error) => {
        console.error('Error deleting comment:', error);
        return throwError(error);
      })
    );
  }

  deleteThread(threadId: number, userId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/discussion/posts/${threadId}/thread`, { params: { userId: userId.toString() } })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  publishComment(data: { content: string, postTypeId: number, parentPostId: number | null, postTypeName: string }): Observable<Discussion> {
    return this.http.post<Discussion>(`${environment.apiUrl}/discussion/posts`, data);
  }

  addUpvoteToPost(postId: number): Observable<Discussion> {
    return this.http.post<Discussion>(`${environment.apiUrl}/discussion/posts/${postId}/upvotes`, null);
  }

  getCommentCountForPost(postId: number): Observable<number[]> {
    return this.http.get<number[]>(`${environment.apiUrl}/discussion/posts/${postId}/comment-count`);
  }

  getPopularTags(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/discussion/posts/popular-tags`).pipe(
      catchError((error) => {
        console.error('Error fetching popular tags:', error);
        return of([] as any);
      })
    )
  }

  getUserBookmarks(userEmail: string): Observable<Discussion[]> {
    const params = new HttpParams().set('userEmail', userEmail);
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/user/bookmarks`, { params }).pipe(
      catchError((error) => {
        console.error('Error fetching user bookmarks:', error);
        return [];
      })
    );
  }

  getUserComments(userEmail: string): Observable<Discussion[]> {
    const params = new HttpParams().set('userEmail', userEmail);
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/user/comments`, { params }).pipe(
      catchError((error) => {
        console.error('Error fetching user comments:', error);
        throw error;
      })
    );
  }

  searchDiscussions(searchTerm: string): Observable<Discussion[]> {
    const params = new HttpParams().set('searchTerm', searchTerm);
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/search`, { params }).pipe(
      catchError((error) => {
        console.error('Error searching discussions:', error);
        return of([]);
      })
    );
  }

  getFlaggedDiscussionsForAdmin(): Observable<Discussion[]> {
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/flagged`);
  }

  getAlertsDiscussionsForAdmin(): Observable<Discussion[]> {
    return this.http.get<Discussion[]>(`${environment.apiUrl}/discussion/posts/alerts`);
  }

  bookmarkPostById(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/bookmark/${postId}/bookmark`, {});
  }

  unbookmarkPostById(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/bookmark/${postId}/unbookmark`, {});
  }

  flagThread(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/discussion/posts/${postId}/flag`, {});
  }

  removeFlag(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/discussion/posts/${postId}/removeFlag`, {});
  }

  alertThread(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/discussion/posts/${postId}/alert`, {});
  }

  removeAlert(postId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/discussion/posts/${postId}/removeAlert`, {});
  }

}
