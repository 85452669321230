<div ngbDropdown [(open)]="isOpen" class="mx-3" (document:click)="closeDropdown($event)">
  <button ngbDropdownAnchor type="button" (click)="toggleDropdown()" class="btn btn-light">
    <span class="bi bi-bell fs-5"></span>
    <span *ngIf="unreadCount > 0 && !isOpen" class="badge bg-primary">{{unreadCount}}</span>
  </button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-notifications dropdown-menu-end">
    <div class="dropdown-header">
      Notifications
      <span *ngIf="notifications.length" class="badge bg-primary">
        {{notifications.length}}
      </span>
      <button class="btn btn-sm btn-link float-end" routerLink="/notifications" (click)="isOpen = false">
        View all
      </button>
    </div>
    <div class="dropdown-divider"></div>
    <div *ngIf="notifications.length; else noNotificationsView">
      <div *ngFor="let notification of notifications" class="d-block" ngbDropdownItem
        (click)="notification.action ? notification.action() : ''">
        <div class="d-flex w-100">
          <div class="flex-shrink-1 mx-2">
            <span class="bi" [ngClass]="{
                        'bi-check-circle-fill text-success': notification.type === 'success',
                        'bi-exclamation-circle-fill text-danger': notification.type === 'danger',
                        'bi-info-circle-fill text-info': notification.type === 'info',
                        'bi-exclamation-triangle-fill text-warning': notification.type === 'warning'
                        }"></span>
          </div>
          <div class="d-flex w-100 align-items-center flex-grow-0">
            <div class="text-start w-100">
              <span class="mx-2">{{notification.label}}</span>
              <p class="text-muted mx-2 small">
                {{notification.message}}
              </p>
            </div>
            <div ngbDropdown *ngIf="notification.closeable" class="flex-grow-0">
              <button ngbDropdownToggle type="button" class="btn btn-link btn-sm" click-stop-propagation>
                <span class="bi bi-three-dots-vertical"></span>
              </button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                <button ngbDropdownItem *ngIf="notification.action" type="button"
                  (click)="notification.action(); $event.stopPropagation()" class="btn btn-outline-primary">
                  View
                </button>
                <button ngbDropdownItem class="dropdown-item" (click)="removeNotification(notification)">
                  <span class="text-danger">Remove</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noNotificationsView>
      <div class="mx-3 my-2">
        No notifications
      </div>
    </ng-template>
  </div>
</div>