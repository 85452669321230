import { Component } from '@angular/core';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';

@Component({
  selector: 'app-assignment-form-page',
  templateUrl: './assignment-form-page.component.html',
  styleUrls: ['./assignment-form-page.component.sass']
})
export class AssignmentFormPageComponent {
  showPreview = false;
  defaultAssignment: AssignmentData = {
    title: 'Untitled',
    content: 'Assignment content here...',
    description: '',
    submissionTypes: [
      'COMPLETE_BUTTON',
    ],
    technologies: [],
    rubric: {
      title: '',
      description: '',
      criteria: [
        {
          title: '',
          description: '',
          ratings: [
            {
              title: '',
              description: '',
              ratingLevel: 'EXCELLENT',
            },
            {
              title: '',
              description: '',
              ratingLevel: 'GREAT',
            },
            {
              title: '',
              description: '',
              ratingLevel: 'GOOD',
            },
            {
              title: '',
              description: '',
              ratingLevel: 'FAIR',
            },
            {
              title: '',
              description: '',
              ratingLevel: 'POOR',
            },
          ],
        },
      ],
    },
    solution: {
      type: 'TEXT',
      solution: '',
    }
  };
}
