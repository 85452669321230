<div class="container-fluid">
    <div class="border-bottom w-100 d-flex flex-row">
        <button type="button"
                (click)="goBack()"
                [disabled]="currentRouteHome"
                class="btn btn-link text-primary text-decoration-none btn-lg"
                [ngClass]="{ 'opacity-0': currentRouteHome }">
            <span class="bi bi-chevron-left"></span>
        </button>
        <h3 class="h3 my-3"><span *ngIf="titleIcon"
                                  [ngClass]="titleIcon"
                                  class="me-2 text-primary"></span> {{frameTitle}}</h3>
    </div>
    <div class="my-2">
        <ng-content></ng-content>
    </div>
</div>
