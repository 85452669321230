import { Component, Input } from '@angular/core';
import { GitLabGroup } from '@app/api/integration/gitlab/models/gitlab-data.model';

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.sass']
})
export class GroupViewComponent {

  @Input() group?: GitLabGroup;

}
