import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, Observable, of } from 'rxjs';

export interface MultiSelectOption {
  icon?: string;
  label: string;
  value: any;
}

@Component({
  selector: 'app-multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styleUrls: ['./multi-select-field.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectFieldComponent),
      multi: true
    }
  ]
})
export class MultiSelectFieldComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() options?: Observable<MultiSelectOption[]> | MultiSelectOption[];
  @Input() minCount = 0;
  @Input() small = false;
  @Input() placeholder = 'Select...';
  @Input() filterPlaceholder = 'Filter...';
  @Input() filterField = false;
  @Input() filterFieldDebounce = 300;
  filterTimeout: any;
  @Input() search = '';
  @Output() searchChange = new EventEmitter<string>();
  @Output() loadOptions = new EventEmitter<void>();


  _options: MultiSelectOption[] = [];

  showDropdown = false;
  isDisabled = false;
  value: any[] = [];
  selectedOptions: MultiSelectOption[] = [];
  loadingOptions = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(target)) {
      this.showDropdown = false;
    }
  }

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.loadMultiSelectOptions();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any[]): void {
    if (obj) {
      this.value = obj;
      if (Array.isArray(obj)) {
        this.selectedOptions = obj.map(opt => this.getOptionByValue(opt));
      }
    } else {
      this.value = [];
      this.selectedOptions = [];
    }
  }

  ngOnInit() {
    this.loadMultiSelectOptions();
  }

  loadMultiSelectOptions() {
    this.loadingOptions = true;
    if (this.options) {
      // If options is an observable
      if (this.options instanceof Observable) {
        this.options
          .pipe(catchError((err) => {
            console.error('Unable to load options');
            return of([]);
          }))
          .subscribe({
            next: (options) => {
              this._options = options;
              this.loadingOptions = false;
            }
          });
      } else {
        this.loadingOptions = false;
        this._options = this.options;
      }
    }
  }

  toggleOption(event: Event, option: MultiSelectOption) {
    event.stopPropagation(); 
    if (this.value.includes(option.value)) {
      this.value.splice(this.value.indexOf(option.value), 1);
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
    } else {
      this.value.push(option.value);
      this.selectedOptions.push(option);
    }
    this.showDropdown = !this.showDropdown;
    this.onChange(this.value);
  }

  getOptionByValue(value: any) {
    return this._options.filter(opt => opt.value === value)[0];
  }

  optionSelected(option: MultiSelectOption) {
    return this.selectedOptions.filter(
      opt => opt.value === option.value
    ).length > 0;
  }

  onSearchInputChange() {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.searchChange.emit(this.search);
    }, this.filterFieldDebounce);
  }

  clearFilter() {
    this.search = '';
    this.searchChange.emit(this.search);
    this.loadMultiSelectOptions();
  }

}
