<form [formGroup]="formGroup">

  <div *ngIf="!submitted; else submittedContent" class="my-2">
    <p class="my-2">Enter your email and we'll send you a password reset email.</p>

    <div class="my-2">
      <app-text-field controlName="email"
                      label="Email"></app-text-field>
    </div>

    <div class="my-2 w-100">
      <button type="submit"
              [disabled]="submitting"
              (click)="sendPasswordResetEmail()"
              class="btn btn-primary w-100">
        Reset Password <span *ngIf="submitting" class="spinner-border spinner-border-sm"></span>
      </button>
    </div>
  </div>

</form>

<ng-template #submittedContent>

  <div class="my-2">
    <p class="my-2">We've sent you an email with a link to reset your password.</p>
    <div class="my-3">
      <a routerLink="/login" class="btn btn-primary">Back to Login</a>
    </div>
  </div>
</ng-template>
