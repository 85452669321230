<div class="container">
  <div class="my-3">
    <h3 class="h3">New Student</h3>
    <p class="text-muted">Create a new student.</p>
  </div>
  <div class="pb-5">
    <app-student-form mode="create"  [saving]="saving" 
                       (submitStudents)="saveStudents($event)">
    </app-student-form>
  </div>
</div>
