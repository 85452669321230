import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncated'
})
export class TruncatedPipe implements PipeTransform {

  transform(value: unknown, maxLength = 20): unknown {
    if (typeof value !== 'string') {
      return value;
    }
    return value.length > maxLength ? value.substring(0, maxLength) + '...' : value;
  }

}
