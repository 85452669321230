import { Component } from '@angular/core';
import { ExportFileService } from '@core/services/export-file.service';
import { MarkdownPipeOptions } from 'ngx-markdown';

@Component({
  selector: 'app-template-download-page',
  templateUrl: './template-download-page.component.html',
  styleUrls: ['./template-download-page.component.sass']
})
export class TemplateDownloadPageComponent {

  template = '<!--title-->\n' +
    'Assignment Title (Template)\n' +
    '=========================================================\n' +
    '<!--/title-->\n' +
    '\n' +
    '<!--description-->\n' +
    '**EDIT DESCRIPTION HERE**\n' +
    '\n' +
    'In a few sentences, describe the purpose of your assignment here.\n' +
    '<!--/description-->\n' +
    '\n' +
    '---\n' +
    '\n' +
    '<!--assignment-content-->\n' +
    '## Overview\n' +
    '<!--overview-->\n' +
    '**EDIT OVERVIEW HERE**\n' +
    '\n' +
    'Give a detailed overview of what the student will be utilizing in this assignment as well as \n' +
    'any deliverables needed to complete the assignment.\n' +
    '<!--/overview-->\n' +
    '\n' +
    '---\n' +
    '\n' +
    '## Assignment\n' +
    '<!--content-->\n' +
    '**EDIT CONTENT HERE**\n' +
    '\n' +
    'Enter the assignment content here. Include screenshots, code snippets, or step-by-step \n' +
    'instructions to properly guide the student in completing this assignment.\n' +
    '\n' +
    '![Image](https://app.learn.smoothstack.com/assets/logos/logo-header.png)\n' +
    '\n' +
    '- Unordered\n' +
    '- List\n' +
    '\n' +
    '1. Ordered\n' +
    '2. List\n' +
    '\n' +
    '<!--/content-->\n' +
    '\n' +
    '---\n' +
    '\n' +
    '<!--/assignment-content-->';

  markdownData = '```markdown\n' + this.template + '\n```\n';

  constructor(private exportService: ExportFileService) {
  }

  downloadTemplate() {
    // Turn the template into a markdown blob file
    const blob = new Blob([this.template], { type: 'text/markdown' });
    this.exportService.triggerDownload(blob, 'assignment-template.md');
  }

}
