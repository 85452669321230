import { Component, Input, OnInit } from '@angular/core';
import { CourseData } from '@app/api/models/learning-material-data.model';

@Component({
  selector: 'app-course-tree',
  templateUrl: './course-tree.component.html',
  styleUrls: ['./course-tree.component.sass'],
})
export class CourseTreeComponent implements OnInit {
  @Input() courseData?: CourseData;
  modulesCollapse = false;

  ngOnInit(): void {
    if (this.courseData) {
      this.courseData.modules.sort((a, b) => a.order - b.order);
    }
  }
}
