import { Component } from '@angular/core';

@Component({
  selector: 'app-lesson-page',
  templateUrl: './lesson-page.component.html',
  styleUrls: ['./lesson-page.component.sass']
})
export class LessonPageComponent {

}
