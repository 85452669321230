import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Cohort } from '@app/api/models/cohort.model';
import { Page, PageableParams, PageParams } from '@app/api/models/page.model';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { Observable } from 'rxjs';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Injectable({
  providedIn: 'root'
})
export class CohortApiService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getCohort(id: number) {
    return this.http.get<Cohort>(this.api.endpoint(`/training/cohorts/${id}`));
  }

  createCohort(cohortData: Cohort) {
    return this.http.post<Cohort>(this.api.endpoint('/training/cohorts'), cohortData);
  }

  updateCohort(cohortData: Cohort) {
    return this.http.put<Cohort>(this.api.endpoint(`/training/cohorts/${cohortData.id}`), cohortData);
  }

  deleteCohort(id: number) {
    return this.http.delete<Cohort>(this.api.endpoint(`/training/cohorts/${id}`));
  }

  createGitlabGroup(cohortId: number) {
    return this.http.post<Cohort>(this.api.endpoint(`/training/cohorts/${cohortId}/gitlab-groups`), {});
  }

  getAllCohorts(search?: string, pageable?: PageableParams) {
    return this.http.get<Page<Cohort>>(this.api.endpoint('/training/cohorts'), {
      params: {
        search: search || '',
        ...pageable
      }
    });
  }

  getLearningActivities(cohortId: number, pageParams: PageParams, types: ActivityMaterialType[] = [], materialIds: number[] = []): Observable<Page<LearningActivity>> {
    return this.http.get<Page<LearningActivity>>(this.api.endpoint(`/training/cohorts/${cohortId}/learning-activities`), {
      params: {
        ...pageParams,
        materialTypes: types.join(','),
        materials: materialIds.join(',')
      }
    });
  }
}
