import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { FormGroup, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CategorizationService } from '@app/api/categorization/services/categorization.service';
import { TechnologyTypeFilterParams } from '@app/api/discussion/models/technology-type-filter-params.model';
import { TechnologyType } from '@app/api/discussion/models/technologyType';
import { KeyBindingService } from '@app/core/services/key-binding.service';

@Component({
  selector: 'app-technology-type-select-field',
  templateUrl: './technology-type-select-field.component.html',
  styleUrls: ['./technology-type-select-field.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechnologyTypeSelectFieldComponent),
      multi: true
    }
  ]
})
export class TechnologyTypeSelectFieldComponent implements ControlValueAccessor {
  @ViewChild('searchInput') searchInput?: any;
  @Input() technologyTypeFilter: TechnologyTypeFilterParams = {};
  @Input() placeholder = 'Search for technologies...';
  searchForm: FormGroup;
  technologyTypeResults: TechnologyType[] = [];
  searchInputFocused = false;
  showDropdown = false;
  searchRequestTimeout: any;
  searching = false;

  @Input() selectedTechnologyTypes: TechnologyType[] = [];
  @Output() selectedTechnologyTypesChange = new EventEmitter<TechnologyType[]>();
  @Input() maxTechnologyTypes = 0;
  value = '';
  isDisabled = false;
  constructor(
    private fb: FormBuilder,
    private keyBindingService: KeyBindingService,
    private categorizationApi: CategorizationService) {

    this.searchForm = this.fb.group({
      search: [''],
    });

    this.keyBindingService.registerKeyBind('backspace', () => {
      if (this.searchForm.get('search')?.value.length === 0) {
        this.selectedTechnologyTypes.pop();
      }
    });

    this.searchForm.valueChanges.subscribe({
      next: (value) => {
        const { search } = value;
        this.showDropdown = search.length > 1;
        this.searching = true;

        if (this.searchRequestTimeout) {
          clearTimeout(this.searchRequestTimeout);
        }

        this.searchRequestTimeout = setTimeout(() => {
          if (this.showDropdown) {
            this.categorizationApi.getTechnologyTypes().subscribe({
              next: (technologyTypes) => {
                this.technologyTypeResults = technologyTypes.filter((tt) => {
                  return !this.selectedTechnologyTypes.find((st) => st.id === tt.id);
                });
                this.searching = false;
              },
              complete: () => {
                this.searching = false;
              }
            });
          }
        }, 250);
      }
    });

  }

  onChange: (value: string) => void = () => {
  };
  onTouched: () => void = () => {
  };

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  addTechnologyType(technologyType: TechnologyType) {

    if (this.maxTechnologyTypes > 0 && this.selectedTechnologyTypes.length >= this.maxTechnologyTypes) {
      return;
    }

    this.selectedTechnologyTypes.push(technologyType);
    const searchField = this.searchForm.get('search');
    searchField?.setValue('');
    this.focusSearchInput();
    this.showDropdown = false;
    this.selectedTechnologyTypesChange.emit(this.selectedTechnologyTypes);
  }

  deleteTechnologyType(technologyType: TechnologyType) {
    this.selectedTechnologyTypes = this.selectedTechnologyTypes.filter((tt) => tt.id !== technologyType.id);
    this.selectedTechnologyTypesChange.emit(this.selectedTechnologyTypes);
  }

  focusSearchInput() {
    this.searchInput?.nativeElement.focus();
  }
}
