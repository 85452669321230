<div class="my-3">
  <button type="button" class="btn btn-primary" (click)="openImportStudentsModal()">
    <span class="bi bi-person-plus"></span> Import Students
  </button>
  <button type="button" ngbTooltip="Add Student from Existing users" class="btn btn-primary mx-2" (click)="openAddExistingUsersModal()">
    <span class="bi bi-person"></span> Existing Users
  </button>
</div>

<form [formGroup]="studentForm" class="my-3" (submit)="onSubmitStudent()">

  <div class="card card-body my-3">
    <div class="mb-3">
      <h4 class="h4">Student Details</h4>
    </div>
    <div formArrayName="students">
      <div class="card card-body my-2" *ngFor="let student of studentsFormArray.controls; let i = index" [formGroupName]="i">
        <div class="d-flex">
          <div class="flex-shrink-1 pe-3 pt-3 text-center">
            <span class="fw-bold text-muted">{{i + 1}}.</span>
          </div>
          <div class="flex-grow-1">
            <div class="my-3">
              <div class="form-floating">
                <input class="form-control" formControlName="email" id="email" placeholder="Email" [readOnly]="isExistingUser">
                <label for="email">Email</label>
              </div>
            </div>
            <div class="my-3">
              <div class="form-floating">
                <input class="form-control" formControlName="firstName" id="firstName" placeholder="First Name" [readOnly]="isExistingUser">
                <label for="firstName">First Name</label>
              </div>
            </div>
            <div class="my-3">
              <div class="form-floating">
                <input class="form-control" formControlName="lastName" id="lastName" placeholder="Last Name" [readOnly]="isExistingUser">
                <label for="lastName">Last Name</label>
              </div>
            </div>
            <div class="my-3" *ngIf="!isExistingUser">
              <div class="form-switch">
                <input class="form-check-input me-2" type="checkbox" formControlName="invite">
                <label class="form-check-label" for="inviteNewUser">Invite User</label>
              </div>
            </div>
          </div>
          <div class="flex-shrink-1 ps-3 pt-3 text-center" *ngIf="i !== 0">
            <button tabindex="-1" type="button" class="btn btn-outline-danger btn-sm" (click)="removeStudent(i)">
              <span class="bi bi-trash"></span>
              <span class="visually-hidden">Remove Student</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-3 text-end">
      <button type="button" class="btn btn-primary mx-2" *ngIf="!isExistingUser" (click)="addStudent()">
        <span class="bi bi-person-plus"></span>
        Add Student
      </button>
      <button class="btn btn-primary" type="submit" [disabled]="studentForm.invalid || saving">
        Save <span [ngClass]="{ 'bi bi-floppy': !saving, 'spinner-border spinner-border-sm': saving }"></span>
      </button>
      <button type="button" *ngIf="isExistingUser" class="btn btn-outline-primary ms-2" (click)="goBack()">
        Cancel
      </button>
  </div>

</form>

<ng-template #importStudentsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Batch Students (csv file)</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="card card-body my-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="m-0">Student Template</h5>
      </div>
      <div class="alert alert-info">
        <p><strong>CSV Format:</strong> Please upload a CSV file with the following format:</p>
        <div class="bullet-list">
          <div class="bullet-item"><strong>Email:</strong> The email address of the student.</div>
          <div class="bullet-item"><strong>First Name:</strong> The first name of the student.</div>
          <div class="bullet-item"><strong>Last Name:</strong> The last name of the student.</div>
          <div class="bullet-item"><strong>Invite:</strong> Whether the student should be sent an invitation (<code>TRUE</code> for sending invitations, <code>FALSE</code> for not sending).</div>
        </div>
        <p class="mt-3">Please download the student template here:
        <button type="button" (click)="downloadTemplate()" class="btn btn-sm btn-outline-primary">
          <i class="bi bi-download"></i> Download Template
        </button></p>
      </div>
    </div>
    <div class="my-3">
      <app-upload-field #uploadField uploadAcceptedExtensions=".csv" [(ngModel)]="files" (ngModelChange)="processFile()" acceptedExtensions=".csv"></app-upload-field>
    </div>
  </div>
  
  
</ng-template>


<ng-template #addExistingUserModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <span class="bi bi-person-lines-fill text-primary"></span>
      Add Student from Existing users
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-user-select-field placeholder="Search for email..." [(selectedUsers)]="selectedUsers"></app-user-select-field>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="addExistingUsers(); modal.close()"  [disabled]="selectedUsers.length === 0">Add Students</button>
  </div>
</ng-template>
