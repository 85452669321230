import { Component, Input } from '@angular/core';
import { TagData, TagDataFn } from '@core/components/forms/tags-field/tag-data.model';
import { map, Observable } from 'rxjs';
import { CategorizationService } from '@app/api/categorization/services/categorization.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-technology-tags-field',
  template: `
      <app-tags-field [(ngModel)]="value"
                      (ngModelChange)="handleNgModelChange($event)"
                      (controlFocus)="controlFocus()"
                      [placeholder]="placeholder"
                      [tagResultsObservable]="technologyTags"
                      [restrictTags]="true"></app-tags-field>

  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TechnologyTagsFieldComponent,
      multi: true
    }
  ]
})
export class TechnologyTagsFieldComponent implements ControlValueAccessor {


  value: string[] = [];
  onChange: any = () => {};
  onTouched: any = () => {};
  @Input() controlFocus = () => {};
  @Input() placeholder = 'Add relevant technology';

  isDisabled = false;

  constructor(private categorization: CategorizationService) {
  }

  handleNgModelChange(value: string[]) {
    this.value = value;
    this.onChange(value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    } else {
      this.value = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get technologyTags(): TagDataFn {
    return (tagQuery: string): Observable<TagData[]> => {
      return this.categorization.getTechnologyTags(tagQuery, 5)
        .pipe(
          map(page => page.content),
          map(tags => tags.map(
            tag => {
              return {
                label: tag.name,
                value: tag.name
              };
            }
          ))
        );
    };
  }

}
