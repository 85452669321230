import { Component } from '@angular/core';

@Component({
  selector: 'app-course-composer-page',
  templateUrl: './course-composer-page.component.html',
  styleUrls: ['./course-composer-page.component.sass']
})
export class CourseComposerPageComponent {

  constructor() {
  }

}
