import { Component, Input } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';

@Component({
  selector: 'app-activity-bottom-navigation',
  templateUrl: './activity-bottom-navigation.component.html',
  styleUrls: ['./activity-bottom-navigation.component.sass']
})
export class ActivityBottomNavigationComponent {

  @Input({ required: true }) activity!: LearningActivity;

  constructor(public learningRouteData: LearningRouteDataService) {
  }

  get hasNextActivity() {
    return this.activity && !!(this.activity.nextActivity);
  }

  get hasPreviousActivity() {
    return this.activity && !!(this.activity.previousActivity);
  }

  get previousActivity() {
    return this.activity && this.activity.previousActivity;
  }

  get nextActivity() {
    return this.activity && this.activity.nextActivity;
  }

  get hasNavigableActivities() {
    return this.hasNextActivity || this.hasPreviousActivity;
  }

  get hasGoBackUp() {
    return this.hasPreviousActivity && this.previousActivity && this.checkPreviousActivities(this.activity, this.previousActivity!);
  }

  get hasGoTo() {
    return this.hasNextActivity && this.nextActivity && this.checkNextActivity(this.activity, this.nextActivity!);
  }

  checkPreviousActivities(currentActivity: LearningActivity, parentActivity: LearningActivity) {

    const currentType = currentActivity.type;
    const previousType = parentActivity.type;

    return currentType === 'ASSIGNMENT' && previousType === 'LESSON' ||
            currentType === 'LESSON' && previousType === 'MODULE' ||
            currentType === 'MODULE' && previousType === 'COURSE';
  }

  checkNextActivity(currentActivity: LearningActivity, nextActivity: LearningActivity) {
    const currentType = currentActivity.type;
    const nextType = nextActivity.type;

    return currentType !== nextType;
  }

  getParentActivity(currentActivity: LearningActivity) {
    const type = currentActivity.type;

    switch (type) {
      case 'ASSIGNMENT':
        return currentActivity.parentLesson;
      case 'LESSON':
        return currentActivity.parentModule;
      case 'MODULE':
        return currentActivity.parentCourse;
      default:
        return undefined;
    }
  }

  getNextButtonMessagePrefix() {
    const currentType = this.activity.type;
    const nextType = this.nextActivity?.type;
    const parentActivityId = this.getParentActivity(this.activity)?.id;
    const nextActivityId = this.nextActivity?.id;

    if (currentType === nextType) {
      return 'Next ';
    } else if (currentType === 'LESSON' && nextType === 'ASSIGNMENT') {
      return 'Start ';
    } else if ((currentType === 'ASSIGNMENT' && nextType === 'LESSON') ||
               (currentType === 'LESSON' && nextType === 'MODULE') ||
               (currentType === 'MODULE' && nextType === 'COURSE')) {
      if (parentActivityId === nextActivityId) {
        return 'Go back to ';
      }
      return 'Go to ';
    } else {
      return 'Go to ';
    }
  }

}
