<div class="row">
  <div class="col-5 col-lg-4 border-end" *ngIf="module; else spinner">
  </div>
  <div class="col-7 col-lg-8">
    <router-outlet></router-outlet>
  </div>
</div>


<ng-template #spinner>
  <div class="flex-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
