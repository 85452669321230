import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentLibraryService, ContentQueryParams } from '@app/api/content-library/services/content-library.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { CourseData, LearningMaterialData, LessonData, ModuleData } from '@app/api/models/learning-material-data.model';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Component({
  selector: 'app-learning-material-selector',
  templateUrl: './learning-material-selector.component.html',
  styleUrls: ['./learning-material-selector.component.sass']
})
export class LearningMaterialSelectorComponent implements OnInit {

  @Input() set publishedOnly(value: boolean) {
    this.contentParams.published = value;
  }

  get publishedOnly() {
    return this.contentParams.published || false;
  }

  @Input() materialFilter: (data: LearningMaterialData) => boolean = () => true;
  @Input() materialType!: ActivityMaterialType;

  contentParams: ContentQueryParams = {
    page: 0,
    size: 10,
    sort: 'title,desc',
    search: '',
    types: [],
    published: true,
    technologies: [],
  };
  materials: LearningMaterialData[] = [];
  selectedMaterialData: LearningMaterialData | null = null;
  hideTechnologiesFilter = true;
  searchTimeout: any;
  loading = false;

  @Output() selectMaterial = new EventEmitter<LearningMaterialData>();
  @Output() selectAssignment = new EventEmitter<AssignmentData>();
  @Output() selectLesson = new EventEmitter<LessonData>();
  @Output() selectModule = new EventEmitter<ModuleData>();
  @Output() selectCourse = new EventEmitter<CourseData>();

  constructor(private contentLibraryService: ContentLibraryService) {
  }

  ngOnInit() {
    this.loadMaterial();
  }

  loadMaterial() {
    this.loading = true;
    const params = {
      ...this.contentParams,
      types: this.materialType ? [this.materialType] : []
    };
    this.contentLibraryService.getContent(params)
      .subscribe({
        next: (page) => {
          this.materials = [...(page.content as LearningMaterialData[])
            .filter(this.materialFilter)];
          this.loading = false;
        }
      });
  }

  onSelectMaterial(data: LearningMaterialData) {
    this.selectedMaterialData = data;
    this.selectMaterial.emit(this.selectedMaterialData);

    if (this.materialIsAssignment(data)) {
      this.selectAssignment.emit(data);
    }

    if (this.materialIsLesson(data)) {
      this.selectLesson.emit(data);
    }

    if (this.materialIsModule(data)) {
      this.selectModule.emit(data);
    }

    if (this.materialIsCourse(data)) {
      this.selectCourse.emit(data);
    }
  }

  initiateSearch() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.loadMaterial();
    }, 250);
  }

  private materialIsAssignment(data: LearningMaterialData): data is AssignmentData {
    return data.materialType === 'ASSIGNMENT';
  }

  private materialIsLesson(data: LearningMaterialData): data is LessonData {
    return data.materialType === 'LESSON';
  }

  private materialIsModule(data: LearningMaterialData): data is ModuleData {
    return data.materialType === 'MODULE';
  }

  private materialIsCourse(data: LearningMaterialData): data is CourseData {
    return data.materialType === 'COURSE';
  }

}
