<div class="w-100 bg-light py-3 px-2">
  <div class="w-100 d-flex justify-content-start my-2 gap-2 flex-row-reverse align-items-center">
    <div class="col-12 col-md-3">
      <app-search-bar-input
        (loadResults)="loadVideos()"
        [(search)]="search"
      ></app-search-bar-input>
    </div>
  </div>
  <div *ngIf="!videosLoading; else spinnerTbody">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 h-100">
      <div *ngFor="let video of videosList"
           class="col mt-5">
        <div
          class="card text-white rounded border-0"
          [ngClass]="{'cursor-pointer': selectionModeEnabled}"
          (click)="selectionModeEnabled ? insertVideo(video) : null"
        >
          <div class="ratio ratio-16x9">
            <img [src]="video.thumbnailUrl"
                 style=" filter: brightness(.5)"
                 class="img-fluid rounded h-100 wh-100" alt="{{video.title}}">
          </div>

          <div class="card-img-overlay ">
            <div class="position-relative h-100">
              <i *ngIf="!selectionModeEnabled" class="bi bi-play-circle cursor-pointer position-absolute start-50 "
                 style=" font-size: 1.5rem; top: 30%;"
                 (click)="insertVideo(video)"></i>
              <div class="col"  *ngIf="!selectionModeEnabled">
                <div ngbDropdown class="d-inline-block position-absolute top-0 end-0">
                  <i
                    class="bi bi-three-dots-vertical cursor-pointer dropdown-no-caret border-0"
                    ngbDropdownToggle
                    id="dropdown"
                  ></i>
                  <div
                    ngbDropdownMenu aria-labelledby="dropdown">
                    <button
                      ngbDropdownItem
                      [state]=" { video: video }"
                      [routerLink]="['/videos/edit', video.id]">
                      <span class="bi bi-pencil-square mx-2"></span>Edit
                    </button>
                    <button ngbDropdownItem class="text-danger"
                      (click)="deleteVideo(video.id)">
                      <span class="bi bi-trash mx-2"></span>Delete
                    </button>
                  </div>
                </div>
              </div>
              <h5 class="card-title position-absolute bottom-0 start-0">{{ video.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="videosList.length else noVideosFound" class="d-flex justify-content-center">
    <div class="my-5 text-center">
      <ngb-pagination
        [size]="'sm'"
        [(page)]="currentPage"
        [collectionSize]="totalItems"
        [pageSize]="pageSize"
        (pageChange)="loadVideos()">
      </ngb-pagination>
    </div>
  </div>
</div>
<ng-template #noVideosFound>
  <div class="card-body">
    <div class="pt-5 my-5 text-muted text-center">
      No videos found.
    </div>
  </div>
</ng-template>
<ng-template #spinnerTbody>

  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 h-100">
    <div *ngFor="let video of placeholderItems " class="col mt-5 placeholder-glow rounded">
      <div class="ratio ratio-16x9 placeholder  rounded">
      </div>
    </div>
  </div>

</ng-template>

