import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/api/user/services/user.service';
import { User } from '@app/api/user/models/user.model';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.sass']
})
export class DashboardPageComponent implements OnInit {

  user?: User;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getSelf()
      .subscribe({
        next: (user) => {
          this.user = user;
        }
      });
  }

}
