import { Injectable } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';

@Injectable({
  providedIn: 'root'
})
export class LearningActivityLinkService {

  constructor() { }

  getLearningActivityLink(learningActivity: LearningActivity): string {
    const parentCourseId = learningActivity.parentCourse?.id;
    const parentModuleId = learningActivity.parentModule?.id;
    const parentLessonId = learningActivity.parentLesson?.id;
    const activityType = learningActivity.type;

    if (activityType === 'COURSE') {
      return `/learning/course/${learningActivity.id}`;
    } else if (activityType === 'MODULE') {
      if (parentCourseId) {
        return `/learning/course/${parentCourseId}/module/${learningActivity.id}`;
      } else {
        return `/learning/module/${learningActivity.id}`;
      }
    } else if (activityType === 'LESSON') {
      if (parentCourseId && parentModuleId) {
        return `/learning/course/${parentCourseId}/module/${parentModuleId}/lesson/${learningActivity.id}`;
      } else {
        return `/learning/lesson/${learningActivity.id}`;
      }
    } else if (activityType === 'QUIZ') {
      return `/learning/quiz/${learningActivity.id}`;
    } else {
      if (parentCourseId && parentModuleId && parentLessonId) {
        return `/learning/course/${parentCourseId}/module/${parentModuleId}/lesson/${parentLessonId}/activity/${learningActivity.id}`;
      } else if (parentCourseId && parentModuleId) {
        return `/learning/course/${parentCourseId}/module/${parentModuleId}/activity/${learningActivity.id}`;
      } else if (parentCourseId) {
        return `/learning/course/${parentCourseId}/activity/${learningActivity.id}`;
      }
      return `/learning/activity/${learningActivity.id}`;
    }
  }

  getLearningActivityRouterLink(learningActivity: LearningActivity): (string | number)[] {
    const parentCourseId = learningActivity.parentCourse?.id;
    const parentModuleId = learningActivity.parentModule?.id;
    const parentLessonId = learningActivity.parentLesson?.id;
    const activityType = learningActivity.type;

    if (activityType === 'COURSE') {
      return ['/learning', 'course', learningActivity.id];
    } else if (activityType === 'MODULE') {
      if (parentCourseId) {
        return ['/learning', 'course', parentCourseId, 'module', learningActivity.id];
      } else {
        return ['/learning', 'module', learningActivity.id];
      }
    } else if (activityType === 'LESSON') {
      if (parentCourseId && parentModuleId) {
        return ['/learning', 'course', parentCourseId, 'module', parentModuleId, 'lesson', learningActivity.id];
      } else {
        return ['/learning', 'lesson', learningActivity.id];
      }
    } else {
      if (parentCourseId && parentModuleId && parentLessonId) {
        return ['/learning', 'course', parentCourseId, 'module', parentModuleId, 'lesson', parentLessonId, 'activity', learningActivity.id];
      } else if (parentCourseId && parentModuleId) {
        return ['/learning', 'course', parentCourseId, 'module', parentModuleId, 'activity', learningActivity.id];
      } else if (parentCourseId) {
        return ['/learning', 'course', parentCourseId, 'activity', learningActivity.id];
      }
      return ['/learning', 'activity', learningActivity.id];
    }
  }

}
