import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, switchMap } from 'rxjs';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuizQuestionsStepperComponent } from '@core/components/quiz-question-stepper/quiz-questions-stepper.component';
import { GlobalModalService } from '@core/services/global-modal.service';
import {
  QuizLearningActivity,
  QuizSubmission,
} from '@app/api/models/quiz-learning-activity';
import { GlobalToastService } from '@core/services/global-toast.service';
import { QuizSubmissionPart } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-quiz-view',
  templateUrl: './quiz-view.component.html',
  styleUrls: ['./quiz-view.component.sass'],
})
export class QuizViewComponent implements OnInit {
  @Input({ required: true }) quiz!: QuizData;
  @Input({ required: true }) learningActivity!: QuizLearningActivity;
  @Input() submission?: QuizSubmission;
  @ViewChild('stepper') stepper?: QuizQuestionsStepperComponent;

  learningActivityId!: number;
  questions: QuestionData[] = [];
  quizForm!: FormArray<FormGroup>;
  correctIndexes: number[] = [];
  submitted = false;
  loading = false;
  start = false;
  currentQuestionIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private questionService: QuestionApiService,
    private learningActivityService: LearningActivityService,
    private globalModalService: GlobalModalService,
    private fb: FormBuilder,
    private toastService: GlobalToastService,
  ) {
    this.quizForm = this.fb.array([this.fb.group({})]);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.learningActivityId = params['activityId'];
      this.loadQuizQuestions();
    });
  }

  startQuiz() {
    this.start = true;
  }

  loadQuizQuestions() {
    this.loading = true;
    const quizQuestions = this.learningActivity.questions.map((activity) => {
      return this.questionService.getQuestionDataForUpdate(
        activity.question.id!,
      );
    });
    forkJoin(quizQuestions).subscribe({
      next: (value) => {
        this.questions = value;
        this.quizForm = this.fb.array(
          this.questions.map((question) => {
            const submissionPart = this.submission?.submissionParts.find(
              (x) => x.question.id == question.id,
            );
            return this.createQuestionFormGroup(question, submissionPart);
          }),
        );
        //when submission is not null start the quiz and set the current question to the first unanswered question
        if (this.submission) {
          this.start = true;
          const unansweredQuestions = this.quizForm.controls.findIndex(
            (control) => control.invalid,
          );
          if (unansweredQuestions !== -1) {
            this.currentQuestionIndex = unansweredQuestions;
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  createQuestionFormGroup(
    question: QuestionData,
    submission?: QuizSubmissionPart,
  ): FormGroup {
    switch (question.type) {
      case 'MULTIPLE_CHOICE': {
        const answer = submission?.answer.split(',');
        const selectedChoices: number[] = [];
        if (question.selectAllThatApply) {
          question.choices!.forEach((choice, index) => {
            if (answer?.includes(choice.choiceText)) {
              selectedChoices.push(index);
            }
          });
        } else {
          const selectedChoice = question.choices!.find(
            (choice) => choice.choiceText === answer?.[0],
          );
          if (selectedChoice) {
            selectedChoices.push(question.choices!.indexOf(selectedChoice));
          }
        }
        return this.fb.group({
          mcAnswer: [selectedChoices, Validators.required],
        });
      }
      case 'TRUE_FALSE':
        return this.fb.group({
          tfAnswer: [
            submission?.answer == null ? null : submission.answer === 'true',
            Validators.required,
          ],
        });
      case 'FILL_IN_THE_BLANK': {
        return this.fb.group({
          fitbAnswer: [
            submission?.answer?.split(',') ?? [],
            Validators.required,
          ],
        });
      }
      case 'SHORT_ANSWER':
        return this.fb.group({
          saAnswer: [submission?.answer ?? '', Validators.required],
        });
      default:
        return this.fb.group({
          mcAnswer: [[]],
        });
    }
  }

  onSelectedIndexChange($event: number) {
    const previousIndex = this.currentQuestionIndex;
    this.currentQuestionIndex = $event;
    const form = this.quizForm.at(previousIndex);
    if (form.valid) {
      const answerControl = this.getAnswerValue(
        this.questions[previousIndex],
        form.value,
      );
      const submissionPart = this.submission?.submissionParts.find(
        (x) => x.question.id == this.questions[previousIndex].id,
      );
      if (submissionPart?.answer !== answerControl) {
        this.submitAnswer(previousIndex);
      }
    }
  }

  submitAnswer(index: number) {
    const answerControl = this.quizForm.at(index).value;
    const question = this.questions[index];
    const answerValue = this.getAnswerValue(question, answerControl);
    this.learningActivityService
      .saveQuizSubmission(this.learningActivityId, {
        questionId: question.id!,
        answer: answerValue,
      })
      .subscribe({
        next: (v) => {
          this.toastService.show({ content: 'Answer saved!', type: 'info' });
          const submissionPart = this.submission?.submissionParts.find(
            (e) => e.question.id == question.id,
          );
          if (submissionPart) {
            submissionPart.answer = answerValue;
          } else {
            this.submission?.submissionParts.push({
              question: question,
              answer: answerValue,
            });
          }
        },
        error: (e) => {
          console.error(e);
          this.submitted = false;
          this.globalModalService.alert({
            type: 'danger',
            title: 'Error',
            content: 'There was an error submitting the quiz.',
            closeable: true,
          });
        },
      });
  }

  completeQuiz() {
    this.submitted = true;
    this.globalModalService
      .confirm('Complete Quiz', {
        title: 'Are you sure you want to complete the quiz?',
        type: 'info',
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        centered: true,
        closeable: true,
        okButtonCallback: (c) => {
          c?.closeModal();
          const modal = this.globalModalService.spinner('Completing quiz...', {
            centered: true,
            content: 'Please wait...',
            title: 'Completing quiz...',
          });
          const submissions = this.quizForm.controls.map((control, index) => {
            const answerControl = control.value;
            const question = this.questions[index];
            return this.learningActivityService.saveQuizSubmission(
              this.learningActivityId,
              {
                questionId: question.id!,
                answer: this.getAnswerValue(question, answerControl),
              },
            );
          });
          forkJoin(submissions)
            .pipe(
              switchMap((v) =>
                this.learningActivityService.completeLearningActivity(
                  this.learningActivityId,
                ),
              ),
            )
            .subscribe({
              next: () => {
                this.globalModalService.alert({
                  type: 'success',
                  title: 'Success',
                  content: 'Quiz completed successfully!',
                  closeable: true,
                });

                this.learningActivity.completed = true;
                this.submission = undefined;
                modal.close();
              },
              error: (e) => {
                this.submitted = false;
                modal.close();
                this.globalModalService.alert({
                  type: 'danger',
                  title: 'Submission Error',
                  content: `${e}`,
                  closeable: true,
                });
              },
            });
        },
      })
      .subscribe();
  }

  getAnswerValue(question: QuestionData, answerControl: any): string {
    let answerValue = '';
    switch (question.type) {
      case 'MULTIPLE_CHOICE': {
        if (question.selectAllThatApply) {
          const selectedChoicesIndexes = answerControl.mcAnswer as number[];
          const selectedChoices = selectedChoicesIndexes.map(
            (i) => question.choices![i].choiceText,
          );
          answerValue = this.formatAnswer(selectedChoices);
        } else {
          const selectedChoiceIndex = answerControl.mcAnswer as number;
          const selectedChoice =
            question.choices![selectedChoiceIndex].choiceText;
          answerValue = this.formatAnswer(selectedChoice);
        }
        break;
      }
      case 'TRUE_FALSE':
        answerValue = this.formatAnswer(answerControl.tfAnswer);
        break;
      case 'FILL_IN_THE_BLANK':
        answerValue = this.formatAnswer(answerControl.fitbAnswer);
        break;
      case 'SHORT_ANSWER':
        answerValue = this.formatAnswer(answerControl.saAnswer);
        break;
      default:
        break;
    }
    return answerValue;
  }

  formatAnswer(answer: any): string {
    if (Array.isArray(answer)) {
      return answer.join(',');
    } else {
      return answer.toString();
    }
  }
}
