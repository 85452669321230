import { Component } from '@angular/core';
import { AppInfoService } from '@core/services/app-info.service';

@Component({
  selector: 'app-landing-page-frame',
  templateUrl: './landing-page-frame.component.html',
  styleUrls: ['./landing-page-frame.component.sass']
})
export class LandingPageFrameComponent {
  constructor(private appInfo: AppInfoService) {
  }

  get version(): string {
    return this.appInfo.version;
  }

  get isProduction(): boolean {
    return this.appInfo.isProduction;
  }
}
