import { Component, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';

@Component({
  selector: 'app-toggle-field',
  template: `
    <ng-container *ngIf="control">
      <div class="d-inline-block form-check" [ngClass]="{
          'form-switch': boxStyle === 'switch'
        }">
        <input class="form-check-input"
               type="checkbox"
               (focus)="controlFocus.emit()"
               [id]="controlName"
               [formControl]="control">
        <label class="form-check-label" [for]="controlName">
          {{label}}
        </label>
      </div>
      <app-control-validation-message [control]="control" [customErrorMessages]="customErrorMessages"></app-control-validation-message>
    </ng-container>
  `
})
export class ToggleFieldComponent extends AbstractCoreFormControlComponent {
  @Input() boxStyle: 'checkbox' | 'switch' = 'checkbox';

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }
}
