import { Component } from '@angular/core';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { DiscussionsService } from '@app/api/discussion/service/discussions.service';
import { User } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { Observable, forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-flagged-discussion',
  templateUrl: './flagged-discussion.component.html',
  styleUrls: ['./flagged-discussion.component.sass']
})
export class FlaggedDiscussionComponent {

  flaggedDiscussions: Discussion[] = [];
  alertsDiscussions: Discussion[] = [];
  userId?: number | undefined;

  constructor(
    private discussionsService: DiscussionsService,
    private globalModalService: GlobalModalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.fetchFlaggedDiscussions();
    this.fetchAlertsDiscussions();
  }

  fetchFlaggedDiscussions(): void {
    this.discussionsService.getFlaggedDiscussionsForAdmin().subscribe(
      discussions => {
        this.flaggedDiscussions = discussions;
        this.fetchCommentCounts(this.flaggedDiscussions);
      },
      error => {
        console.error('Error fetching flagged discussions:', error);
      }
    );
  }

  fetchAlertsDiscussions(): void {
    this.discussionsService.getAlertsDiscussionsForAdmin().subscribe(
      discussions => {
        this.alertsDiscussions = discussions;
        this.fetchCommentCounts(this.alertsDiscussions);
      },
      error => {
        console.error('Error fetching alerts discussions:', error);
      }
    );
  }

  fetchCommentCounts(discussions: Discussion[]): void {
    const commentCountObservables: Observable<number>[] = discussions.map(discussion =>
      this.discussionsService.getCommentCountForPost(discussion.id).pipe(
        map(commentCounts => Array.isArray(commentCounts) ? commentCounts.length : commentCounts)
      )
    );

    forkJoin(commentCountObservables).subscribe(
      commentCounts => {
        commentCounts.forEach((commentCount, index) => {
          discussions[index].commentCount = commentCount;
          console.log("comment count => " + commentCount);
        });
      },
      error => {
        console.error('Error fetching comment counts:', error);
      }
    );
  }

  onDeleteThread(postId: number): void {
    this.userService.getSelf().subscribe((user: User) => {
      if (user && user.id) {
        const userId = user.id;
        this.userService.getSelfRole().subscribe((role: string) => {
          console.log("user role => [" + role + "]");
          if (role === 'Admin') {
            const message = 'Are you sure you want to delete this thread?';

            this.globalModalService.confirm(message).subscribe((result) => {
              if (result) {
                this.deleteThread(postId, userId);
              }
            });
          } else {
            const errorMessage = 'Unauthorized: Only admins can delete threads.';
            this.globalModalService.alert({
              content: errorMessage,
              type: 'warning'
            });
            console.error('Unauthorized: Only admins can delete threads.');
          }
        }, (error) => {
          const errorMessage = 'Error: ' + error.error.message;
          this.globalModalService.alert({
            content: errorMessage,
            type: 'danger'
          });
          console.error('Error deleting thread:', error);
        });
      } else {
        console.error('Error: User ID is undefined');
      }
    }, (error) => {
      console.error('Error fetching user ID:', error);
    });
  }

  private deleteThread(postId: number, userId: number): void {
    this.discussionsService.deleteThread(postId, userId).subscribe(
      () => {
        this.flaggedDiscussions = this.flaggedDiscussions.filter(post => post.id !== postId);
        console.log('Thread deleted successfully');
      },
      (error) => {
        const errorMessage = 'Error: ' + error.error.message;
        this.globalModalService.alert({
          content: errorMessage,
          type: 'danger'
        });
        console.error('Error deleting thread:', error);
      }
    );
  }

}
