import { Pipe, PipeTransform } from '@angular/core';

type FileSizeUnits = 'B' | 'KB' | 'MB' | 'GB' | 'TB';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number, units?: FileSizeUnits): string {
    if (units) {
      return this.getUnits(value, units);
    }
    return this.getReadableFileSize(value);
  }

  getReadableFileSize(value: number) {
    if (value === 0) {
      return '0 B';
    }
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    return parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  getUnits(value: number, units: FileSizeUnits): string {
    switch (units) {
      case 'B':
        return `${value} bytes`;
      case 'KB':
        return `${(value / 1024).toFixed(2)} KB`;
      case 'MB':
        return `${(value / 1024 / 1024).toFixed(2)} MB`;
      case 'GB':
        return `${(value / 1024 / 1024 / 1024).toFixed(2)} GB`;
      case 'TB':
        return `${(value / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
      default:
        return `${value} B`;
    }
  }

}
