<div class="position-relative">
  <h4 class="text-center">Inbox</h4>
  <button type="button" class="btn border-0 position-absolute top-0 end-0" (click)="openAddChatModel()">
    <span class="bi bi-person-add h4 text-primary"></span>
  </button>
</div>


<ng-template #addChatModal>
  <label class="form-label fw-bold">Add Chat</label>
  <div class="my-2">
    <app-user-select-field placeholder="Search for email..." [(selectedUsers)]="selectedUser" [maxUsers]="1" (selectedUsersChange)="assignReceiver()">
    </app-user-select-field>
  </div>
  <div class="my-2">
    <input type="text" [(ngModel)]="content" class="form-control" placeholder="Your message here...">
  </div>
</ng-template>
