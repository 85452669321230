import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-office365-viewer',
  templateUrl: './office365-viewer.component.html',
  styleUrls: ['./office365-viewer.component.sass']
})
export class Office365ViewerComponent implements OnInit {

  @Input() fileUrl = '';
  validFile = true;

  ngOnInit(): void {
    if (this.fileUrl.startsWith('http://localhost:8080')) {
      this.validFile = false;
    }
  }

  get iframeUrl(): string {
    return `https://view.officeapps.live.com/op/embed.aspx?src=${this.fileUrl}`;
  }

}
