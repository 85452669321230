import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagData, TagDataFn } from '@core/components/forms/tags-field/tag-data.model';

@Component({
  selector: 'app-tags-field',
  templateUrl: './tags-field.component.html',
  styleUrls: ['./tags-field.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsFieldComponent),
      multi: true
    }
  ]
})
export class TagsFieldComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('tagInputEl') tagInputEl!: ElementRef;
  @Output() controlFocus = new EventEmitter<void>();
  @Input() placeholder = 'Add a tag';
  tagInput = new FormControl('');
  tags: TagData[] = [];
  tagResults: { viewLabel: string, tagData: TagData }[] = [];
  values: any[] = [];
  focused = false;
  @Input() tagPipe = (tag: any): TagData | null => {
    if (tag) {
      return {
        label: tag,
        value: tag
      };
    }
    return null;
  };
  @Input() searchLength = 3;
  @Input() valueMapper = (tag: TagData): any => tag.value;
  @Input() tagResultsObservable?: TagDataFn;
  @Input() immediatelyShowDropdown = false;
  /**
   * If true, only tags that are in the tagResultsObservable will be allowed.
   */
  @Input() restrictTags = false;
  showDropdown = false;

  private propagateChange = (_: any) => {};
  private propagateTouched = () => {};

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.tagInput.valueChanges.subscribe(() => {
      const tagInputValue = this.tagInput.value ? this.tagInput.value.trim() : '';
      const canSearch = !!(tagInputValue) && tagInputValue.length >= this.searchLength;
      if ((canSearch && this.tagResultsObservable) || this.immediatelyShowDropdown && this.tagResultsObservable) {
        this.populateDropdown(tagInputValue);
      } else {
        this.tagResults = [];
        this.showDropdown = false;
        this.cdr.detectChanges();
      }
    });
  }

  populateDropdown(tagInputValue?: string) {
    if (!this.tagResultsObservable)
      return;
    this.tagResultsObservable(tagInputValue ? tagInputValue : '').subscribe(results => {
      this.tagResults = results.map(result => ({
        viewLabel: result.label,
        tagData: result
      }));
      // Set viewLabel to text with matching characters bolded in markdown
      if (tagInputValue) {
        this.tagResults.forEach(result => {
          const regex = new RegExp(`(${tagInputValue})`, 'gi');
          result.viewLabel = result.viewLabel.replace(regex, '**$1**');
        });
      }
      // Filter out tags that are already in the tags array
      this.tagResults = this.tagResults.filter(result => {
        return !this.tags.find(tag => tag.label.toLowerCase() === result.tagData.label.toLowerCase());
      });
      this.showDropdown = this.tagResults.length > 0;
      this.cdr.detectChanges();
    });
  }

  writeValue(value: any) {
    if (value !== undefined) {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // Check if all values are TagData
        if (value.every((v: any) => v.label && v.value)) {
          this.tags = value;
        } else {
          this.tags = value
            .map((v: any) => this.tagPipe(v))
            .filter((v: any) => v !== null)
            .map((v: any) => v as TagData);
        }
        this.values = this.tags.map(this.valueMapper);
      }
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.tagInput.disable() : this.tagInput.enable();
  }

  setFocused(focused: boolean) {
    this.focused = focused;
    this.cdr.detectChanges();
    if (focused) {
      if (this.immediatelyShowDropdown) {
        this.showDropdown = true;
        this.populateDropdown();
      }
      this.controlFocus.emit();
    } else {
      if (this.immediatelyShowDropdown) {
        setTimeout(() => {
          this.showDropdown = false;
        }, 250);
      }
    }
  }

  onBlur() {
    this.setFocused(false);
    // if (this.tagInput.value) {
    //   this.addTag();
    // }
  }

  focusInputEl(event: Event) {
    event.preventDefault();
    if (!this.focused && this.tagInputEl) {
      this.tagInputEl.nativeElement.focus();
    }
  }

  addTag(tag?: TagData) {
    if (!tag && this.restrictTags) {
      // Add first tag in dropdown
      if (this.tagResults.length > 0) {
        tag = this.tagResults[0].tagData;
      } else {
        return;
      }
    }
    if (!this.tagInput.value && !this.immediatelyShowDropdown) {
      return;
    }
    if (!tag) {
      tag = this.tagPipe(this.tagInput.value!)!;
    }
    if (tag && !this.tags.find(t => t.value === tag?.value)) {
      this.tags.push(tag);
      const value = this.valueMapper(tag);
      this.values.push(value);
      this.propagateChange(this.values);
    }
    this.tagInput.reset();
  }

  backspaceTag() {
    if (!this.tagInput.value && this.focused && this.tags.length > 0) {
      this.tags.pop();
      this.values.pop();
      this.propagateChange(this.values);
    }
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
    this.values.splice(index, 1);
    this.propagateChange(this.values);
  }

}
