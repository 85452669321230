import { Pipe, PipeTransform } from '@angular/core';
import strings from '@app/resources/strings';

@Pipe({
  name: 'stringResource'
})
export class StringResourcePipe implements PipeTransform {

  transform(value: string, lang = 'en_US'): unknown {
    return strings[lang][value] ? strings[lang][value] : value;
  }

}
