import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly key_prefix = 'ss_learn_';
  private readonly prefix = 'encrypted_';

  constructor() { }

  setItem(key: string, value: string) {
    const encryptedValue = btoa(value);
    localStorage.setItem(this.key_prefix + key, this.prefix + encryptedValue);
  }

  getItem(key: string) {
    const encryptedValue = localStorage.getItem(this.key_prefix + key);
    if (!encryptedValue) {
      return null;
    }
    if (encryptedValue.startsWith(this.prefix)) {
      const value = encryptedValue.slice(this.prefix.length);
      try {
        return atob(value);
      } catch (e) {
        console.error('Invalid value found. Removing it.');
        this.removeItem(key);
        return null;
      }
    } else {
      console.error('Invalid value found. Removing it.', this.key_prefix + key, encryptedValue);
      this.removeItem(key);
    }
    return null;
  }

  removeItem(key: string) {
    localStorage.removeItem(this.key_prefix + key);
  }

  clear(externalOnly = false) {
    if (!externalOnly) {
      localStorage.clear();
      return;
    }

    // Save all the values that are prefixed with the key_prefix
    const keys = Object.keys(localStorage).filter(key => key.startsWith(this.key_prefix));
    const values = keys.map(key => localStorage.getItem(key));

    // Clear the local storage
    localStorage.clear();

    // Add back the values that are prefixed with the key_prefix
    keys.forEach((key, index) => {
      const value = values[index];
      if (value) {
        localStorage.setItem(key, value);
      }
    });
  }

  itemExists(key: string) {
    return !!this.getItem(key);
  }
}
