import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { UserRole } from '@app/api/user/models/user.model';
import { AuthService } from '@auth/services/auth.service';

export const roleGuard: (roles: UserRole[]) => CanActivateFn = (roles) => {
  return (route, state) => {
    const authService = inject(AuthService);

    return authService.hasAnyRole(roles);
  };
};
