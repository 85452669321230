import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { PasswordResetResponse } from '@app/api/user/models/password-reset-response.model';
import { PasswordResetRequest } from '@app/api/user/models/password-reset-request.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {

  constructor(private http: HttpClient) { }

  initiatePasswordReset(email: string) {
    return this.http.post<PasswordResetResponse>(`${environment.apiUrl}/password-reset`, { email }, {
      params: {
        state: 'initiate'
      }
    });
  }

  resetPassword(request: PasswordResetRequest) {
    return this.http.post<PasswordResetResponse>(`${environment.apiUrl}/password-reset`, request);
  }

}
