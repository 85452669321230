import { Component } from '@angular/core';
import { VideoUploadFormService } from '@app/api/video/services/video-upload-form.service';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@core/guards/can-component-deactivate.guard';

@Component({
  selector: 'app-video-upload-page',
  templateUrl: './video-upload-page.component.html',
  styleUrls: ['./video-upload-page.component.sass']
})
export class VideoUploadPageComponent implements CanComponentDeactivate {
  constructor(private videoUploadFormService: VideoUploadFormService) {
  }

  get videoUploadForm() {
    return this.videoUploadFormService.videoUploadForm;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.videoUploadForm.dirty) {
      return window.confirm('You have unsaved changes. Are you sure you want to leave?');
    }
    return true;
  }
}
