import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-percent-circle',
  templateUrl: './percent-circle.component.html',
  styleUrls: ['./percent-circle.component.sass']
})
export class PercentCircleComponent {

  @Input() percent = 0;
  @Input() completed = false;
  @Input() size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'primary';

  colorHex(color: string) {
    return `var(--bs-${color})`;
  }

  radius(size: string) {
    switch (size) {
      case 'xxs': return 20;
      case 'xs': return 25;
      case 'sm': return 40;
      case 'md': return 50;
      case 'lg': return 100;
      default: return 50;
    }
  }

  strokeWidth(size: string) {
    switch (size) {
      case 'xxs': return 2;
      case 'xs': return 3;
      case 'sm': return 5;
      case 'md': return 8;
      case 'lg': return 10;
      default: return 8;
    }
  }

  fontSize(size: string, percent = 1) {
    let fontSize = 0;
    switch (size) {
      case 'xxs':
      case 'xs':
        fontSize = 0.75 * percent;
        break;
      case 'sm':
        fontSize = percent;
        break;
      case 'md':
        fontSize = 1.25 * percent;
        break;
      case 'lg':
        fontSize = 1.5 * percent;
        break;
      default:
        fontSize = 1.25 * percent;
    }
    return `${fontSize}rem`;
  }
}
