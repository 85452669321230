import { Component } from '@angular/core';

@Component({
  selector: 'app-assignment-composer-page',
  templateUrl: './assignment-composer-page.component.html',
  styleUrls: ['./assignment-composer-page.component.sass']
})
export class AssignmentComposerPageComponent {

}
