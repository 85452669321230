import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-user-management-home-page',
  templateUrl: './user-management-home-page.component.html',
  styleUrls: ['./user-management-home-page.component.sass']
})
export class UserManagementHomePageComponent {

  userManagementItems: RouteCardItem[] = [
    {
      title: 'Add User',
      description: 'Create and invite new users.',
      icon: 'bi-person-plus',
      routerLink: '/user-management/add-user'
    },
    {
      title: 'View All Users',
      description: 'View and manage all users.',
      icon: 'bi-eye',
      routerLink: '/user-management/view-users'
    },
    {
      title: 'Import Users',
      description: 'Import users from a CSV file.',
      icon: 'bi-file-earmark-arrow-up',
      routerLink: '/user-management/import-users',
    },
    {
      title: 'User Template Download',
      description: 'Download user template for csv file upload',
      icon: 'bi-download',
      routerLink: '/user-management/user-template-download',
    },
  ];

}
