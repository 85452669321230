import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Trainer } from '@app/api/models/trainer.model';
import { TrainerApiService } from '@app/api/training/services/trainer-api.service';

@Component({
  selector: 'app-trainers-edit-page',
  templateUrl: './trainers-edit-page.component.html',
  styleUrls: ['./trainers-edit-page.component.sass'],
})
export class TrainersEditPageComponent {
  trainer: Trainer = {
    id: undefined,
    email: '',
    firstName: '',
    lastName: '',
    technologies: [],
    existingUser: false,
    invite: false,
  };
  state = '';
  constructor(
    private trainerApiService: TrainerApiService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      const trainerId = params['id'];
      this.trainerApiService.getTrainer(trainerId).subscribe({
        next: (trainer) => {
          this.trainer = trainer;
        },
      });
    });
  }
}
