<div class="container-fluid mx-0" style="margin-top: 30px;">
  <div *ngIf="discussion" class="row">
    <div class="col-md-9">
      <div class="card my-3">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <h5 class="card-title" class="text-primary">{{ discussion.postTitle }}</h5>
            </div>
            <div class="col-4 text-end">
              <small>Posted: {{ discussion.createdDate | date: 'dd MMM, yyyy' }}</small>
              <br />
              <small>Updated: {{ discussion.updatedDate | date: 'dd MMM, yyyy' }}</small>
              <br />
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-1 align-self-center">
              <div style="width: 50px; height: 50px;" class="d-flex justify-content-center align-items-center">
                <img [src]="postPictureUrl" class="img-fluid img-thumbnail rounded-circle" alt="" />
              </div>
            </div>
            <div class="col-11 align-self-center">
              <div *ngIf="firstName && lastName">
                <div>{{ firstName }}</div>
                <div>{{ lastName }}</div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <p class="card-text">{{ discussion.content }}</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div *ngIf="loginUserRole == 'Admin'" class="col-12 text-end">
              <button class="btn btn-white btn-sm" (click)="toggleFlagThread()">
                <span [ngClass]="{'bi-flag-fill': isThreadFlagged, 'bi-flag': !isThreadFlagged}"></span>
              </button>
              <button class="btn btn-white btn-sm" (click)="toggleAlertThread()">
                <span [ngClass]="{'bi-bell-fill': isThreadAlert, 'bi-bell': !isThreadAlert}"></span>
              </button>
              <button class="btn btn-white btn-sm" (click)="onDeleteThread(discussion.id); $event.stopPropagation()">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 text-end">
              <button class="btn btn-white btn-sm" (click)="addUpvote()">
                <i class="bi bi-hand-thumbs-up"></i> {{ discussion.upVote || 0 }}
              </button>
              <button class="btn btn-white btn-sm" (click)="toggleBookmarkByUser()">
                <span
                  [ngClass]="{'bi bi-bookmark-plus-fill': discussionBookmarked, 'bi bi-bookmark': !discussionBookmarked}"
                  style="color: #2e2e2e;"></span>
              </button>
              <button class="btn btn-white btn-sm" (click)="showComment=true;">
                <i class="bi bi-reply"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card my-3" *ngIf="showComment">
        <div class="card-body rounded">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="commentForm" class="my-3" (submit)="addComment(discussion)">
                <input type="hidden" formControlName="parentPostId" />
                <input type="hidden" formControlName="postTypeId" />
                <div class="my-3">
                  <app-markdown-editor formControlName="comment" label="Comment" placeholder="Comment">
                  </app-markdown-editor>
                </div>
                <div class="my-3">
                  <button class="btn btn-primary" type="submit" style="margin-top: 50px;">Add Comment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <ul class="list-unstyled">
          <li *ngFor="let comment of myCommentsOfPost; let i = index" class="mb-3 position-relative">
            <div *ngIf="userId == comment.userId &&
                        discussion.id == comment.parentPostId" class="card my-3">
              <div class="card-body rounded" [ngClass]="{'bg-light': userId == comment.userId}">
                <h6 class="card-title" class="text-primary">My Comments:</h6>
                <div *ngIf="!comment.editing" class="row">
                  <div class="col-9">
                    <p style="margin-left: 20px;">{{ comment.content }}</p>
                  </div>
                  <div class="col-3 text-end">
                    <small>
                      Posted: {{ comment.createdDate | date: 'dd MMM, yyyy' }}
                    </small>
                    <br>
                    <small>
                      Updated: {{ comment.updatedDate | date: 'dd MMM, yyyy' }}
                    </small>
                    <div class="row mt-3">
                      <div class="position-absolute bottom-0 end-0">
                        <button class="btn btn-sm btn-light" (click)="toggleCommentEditing(i)">
                          <span class="bi bi-pencil-square me-2"></span>
                        </button>
                        <button class="btn btn-sm btn-light" (click)="deleteComment(comment.id)">
                          <span class="bi bi-trash me-2 text-danger"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="comment.editing" class="row">
                  <div class="col-12">
                    <form [formGroup]="commentForm" *ngIf="commentForm" (submit)="updateComment(comment.id)">
                      <input type="hidden" formControlName="comment" />
                      <app-markdown-editor formControlName="comment" label="Edit" placeholder="Edit Content"
                        [showPreviewButton]="false">
                      </app-markdown-editor>
                      <button class="btn btn-sm btn-primary mt-2" type="submit">Save</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div ngbDropdown [placement]="'bottom-left'">
            <button class="btn btn-primary btn-sm" style="margin-left: 15px;" ngbDropdownToggle>
              SortBy
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="sortComments('Latest')">Latest</button>
              <button class="dropdown-item" (click)="sortComments('Oldest')">Oldest</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <ul class="list-unstyled" style="margin-left: 30px;">
          <ng-container *ngFor="let item of hierarchicalComments; let i = index;">
            <li>
              <div class="card-body">
                <div *ngIf="userId != item.comment.userId"
                  [style.margin-left.px]="[comments[i].commentIndentLevel * 15]">
                  <div class="row">
                    <div class="col-9" style="margin-top: 20px;">
                      <p>{{ item.comment.content }}</p>
                    </div>
                    <div class="col-3" style="margin-top: 20px;">
                      <div class="d-flex flex-column">
                        <small><span style="display: inline-block; width: 60px;">Posted:</span> {{
                          item.comment.createdDate | date: 'dd MMM yyyy' }}</small>
                        <small><span style="display: inline-block; width: 60px;">Updated:</span> {{
                          item.comment.updatedDate | date: 'dd MMM, yyyy' }}</small>
                      </div>
                      <div *ngIf="loginUserRole == 'Admin'" class="mt-2" style="margin-left: 20px;">
                        <button class="btn btn-white btn-sm" (click)="toggleFlagComment(item.comment.id)">
                          <span
                            [ngClass]="{'bi-flag-fill': item.comment.isFlagged, 'bi-flag': !item.comment.isFlagged}"></span>
                        </button>
                        <button class="btn btn-white btn-sm" (click)="toggleAlertComment(item.comment.id)">
                          <span
                            [ngClass]="{'bi-bell-fill': item.comment.isAlert, 'bi-bell': !item.comment.isAlert}"></span>
                        </button>
                        <button class="btn btn-white btn-sm"
                          (click)="onDeleteComment(item.comment.id); $event.stopPropagation()">
                          <i class="bi bi-trash text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-n1">
                    <div class="col-12">
                      <button class="btn btn-white btn-sm" (click)="addCommentUpvote(item.comment)">
                        <i class="bi bi-hand-thumbs-up"></i> {{ item.comment.upVote || 0 }}
                      </button>
                      <button class="btn btn-white btn-sm" (click)="toggleCommentBookmarkforUser(item.comment)">
                        <span
                          [ngClass]="{'bi bi-bookmark-plus-fill': item.comment.isBookmarked, 'bi bi-bookmark': !item.comment.isBookmarked}"
                          style="color: #2e2e2e;"></span>
                      </button>
                      <button class="btn btn-white btn-sm" (click)="toggleShowComment(i)">
                        <i class="bi bi-reply"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="userId == item.comment.userId &&
                            discussion.id != item.comment.parentPostId"
                  [style.margin-left.px]="[item.comment.commentIndentLevel * 15]"
                  [ngClass]="{'bg-light': userId == item.comment.userId}" style="padding: 10px;">
                  <h6 class="card-title" class="text-primary">My Comments:</h6>
                  <div class="row">
                    <div class="col-9" style="margin-top: 20px;">
                      <p>{{ item.comment.content }}</p>
                    </div>
                    <div class="col-3" style="margin-top: 20px;">
                      <div class="d-flex flex-column">
                        <small><span style="display: inline-block; width: 60px;">Posted:</span> {{
                          item.comment.createdDate | date: 'dd MMM yyyy' }}</small>
                        <small><span style="display: inline-block; width: 60px;">Updated:</span> {{
                          item.comment.updatedDate | date: 'dd MMM, yyyy' }}</small>
                      </div>
                      <div *ngIf="loginUserRole == 'Admin'" class="mt-2" style="margin-left: 20px;">
                        <button class="btn btn-white btn-sm" (click)="toggleFlagComment(item.comment.id)">
                          <span
                            [ngClass]="{'bi-flag-fill': item.comment.isFlagged, 'bi-flag': !item.comment.isFlagged}"></span>
                        </button>
                        <button class="btn btn-white btn-sm" (click)="toggleAlertComment(item.comment.id)">
                          <span
                            [ngClass]="{'bi-bell-fill': item.comment.isAlert, 'bi-bell': !item.comment.isAlert}"></span>
                        </button>
                        <button class="btn btn-white btn-sm"
                          (click)="onDeleteComment(item.comment.id); $event.stopPropagation()">
                          <i class="bi bi-trash text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-n1">
                    <div class="col-12">
                      <button class="btn btn-white btn-sm" (click)="addCommentUpvote(item.comment)">
                        <i class="bi bi-hand-thumbs-up"></i> {{ item.comment.upVote || 0 }}
                      </button>
                      <button class="btn btn-white btn-sm" (click)="toggleCommentBookmarkforUser(item.comment)">
                        <span
                          [ngClass]="{'bi bi-bookmark-plus-fill': item.comment.isBookmarked, 'bi bi-bookmark': !item.comment.isBookmarked}"
                          style="color: #2e2e2e;"></span>
                      </button>
                      <button class="btn btn-white btn-sm" (click)="toggleShowComment(i)">
                        <i class="bi bi-reply"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card my-3" *ngIf="showCommentStates[i]">
                  <div class="card-body rounded">
                    <div class="row">
                      <div class="col-12">
                        <form [formGroup]="commentForm" class="my-3" (submit)="addComment(item.comment)">
                          <input type="hidden" formControlName="parentPostId" />
                          <input type="hidden" formControlName="postTypeId" />
                          <div class="my-3">
                            <app-markdown-editor formControlName="comment" label="Comment" placeholder="Comment">
                            </app-markdown-editor>
                          </div>
                          <div class="my-3">
                            <button class="btn btn-primary" type="submit" style="margin-top: 50px;">Add Comment</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>

    </div>
    <div class="col-md-3">
      <div class="card my-3">
        <div class="card-body">
          <div class="bg-light">
            <h6 class="card-title text-primary">Technology</h6>
            <ul class="list-unstyled d-inline-flex">
              <li *ngFor="let tag of discussion.technologyTags"
                style="margin-left: 5px; margin-right: 5px; background-color: white;">
                <i class="bi bi-tag"></i> {{ tag.name }}
              </li>
            </ul>
          </div>
          <h5 class="card-title"></h5>
          <div class="row">
            <div class="col-12">
              <small><span style="display: inline-block; width: 70px;">Posted:</span> {{ discussion.createdDate | date:
                'dd MMM yyyy' }}</small>
            </div>
            <div class="col-12">
              <small><span style="display: inline-block; width: 70px;">Updated:</span> {{ discussion.updatedDate | date:
                'dd MMM yyyy' }}</small>
            </div>
            <div class="col-12">
              <small><span style="display: inline-block; width: 75px;">Comments:</span> {{ comments.length }}</small>
            </div>
          </div>
          <div class="row mt-3 border-top my-1">
            <div class="col-12 d-flex justify-content-center" style="margin-top: 10px;">
              <button class="btn btn-white btn-sm" (click)="addUpvote()">
                <i class="bi bi-hand-thumbs-up"></i> {{ discussion.upVote || 0 }}
              </button>
              <button class="btn btn-white btn-sm" (click)="toggleBookmarkByUser()">
                <span
                  [ngClass]="{'bi bi-bookmark-plus-fill': discussionBookmarked, 'bi bi-bookmark': !discussionBookmarked}"
                  style="color: #2e2e2e;"></span>
              </button>
              <button class="btn btn-white btn-sm" (click)="toggleAlertThread()">
                <span [ngClass]="{'bi-bell-fill': discussion.isAlert, 'bi-bell': !discussion.isAlert}"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loginUserRole == 'Admin'">
        <div class="row mt-3">
          <div class="col-12 text-end">
            <button class="btn btn-primary btn-sm me-1" (click)="toggleFlagThread()" [disabled]="isThreadFlagged"
              [ngClass]="{'disabled-button': isThreadFlagged}">
              <i class="bi bi-flag"></i> Flag Thread
            </button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-primary btn-sm me-1" (click)="toggleAlertThread()" [disabled]="isThreadAlert"
              [ngClass]="{'disabled-button': isThreadAlert}">
              <i class="bi bi-bell"></i> Set Alert
            </button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-end">
            <button class="btn btn-danger btn-sm" (click)="onDeleteThread(discussion.id); $event.stopPropagation()">
              <i class="bi bi-trash"></i> Delete Thread
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-3">
      <ul class="list-unstyled">
        <li *ngFor="let comment of sortedComments" class="mb-3 position-relative">
        </li>
      </ul>
    </div>
  </div>
</div>
