import { Validators } from '@angular/forms';

export const CustomValidators = {
  // Function that takes in a form control as an argument and returns a ValidatorFn that checks if the current value of the form control is equal to the value of the form control passed in
  // Example usage: this.formBuilder.control('', [CustomValidators.equalTo(this.formGroup.get('password'))])
  matches(otherControl: any) {
    return (control: any) => {
      if (control.value !== otherControl.value) {
        return {
          matches: true
        };
      }
      return null;
    };
  },
  password: Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,128}$/),
  requiredMinArrayLength(number: number) {
    return (control: any) => {
      if (!control.value) {
        return {
          requiredArrayLength: true
        };
      }
      if (control.value.length < number) {
        return {
          requiredMinArrayLength: true
        };
      }
      return null;
    };
  }
};
