import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { JwtService } from '@auth/services/jwt.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { catchError, map, of, Subject, tap } from 'rxjs';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { UserService } from '@app/api/user/services/user.service';
import { UserRole } from '@app/api/user/models/user.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { RedirectService } from '@core/services/redirect.service';
import { OidcService } from '@core/services/oidc.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private interactionInProgress = false;
  private inProgress$ = new Subject<boolean>();
  private tokenSaved$ = new Subject<void>();

  authErrorMessage = '';

  constructor(private http: HttpClient,
    private jwt: JwtService,
    private user: UserService,
    private localStorage: LocalStorageService,
    private msal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private redirect: RedirectService,
    private oidc: OidcService,
    private router: Router) {

    this.msal.initialize().subscribe({
      next: () => {
        console.log('MSAL initialized');
        this.subscribeToMsal();
      }
    });
  }

  get tokenSaved() {
    return this.tokenSaved$.asObservable();
  }

  subscribeToMsal() {

    // Check if the user is already logged in
    this.msalBroadcastService.msalSubject$
      .subscribe({
        next: (event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            this.startLogin(event);
          }
        }
      });
  }

  private startLogin(event: EventMessage) {
    console.log('User login success');
    const result = event.payload as AuthenticationResult;
    const { accessToken, idToken } = result;

    this.exchangeToken({ accessToken, idToken })
      .pipe(
        map(response => response.headers.get('Authorization')),
        map(token => token?.replace('Bearer ', ''))
      )
      .subscribe({
        next: (token) => {
          if (token) {
            // Exchange token for JWT
            this.saveToken(token);
            // Clear data that are not ss_learn data
            this.localStorage.clear(true);
            this.tokenSaved$.next();
          } else {
            this.authErrorMessage = 'Something went wrong when authenticating. Please try again later.';
            console.error('No token received from server');
          }
        },
        error: (error) => {
          console.error(error);
          this.authErrorMessage = 'Something went wrong when authenticating. Please try again later.';
        }
      });
  }

  private setInProgress(inProgress = true) {
    this.interactionInProgress = inProgress;
    this.inProgress$.next(inProgress);
  }

  ssoLogin() {
    const authCode$ = this.oidc.getAuthCode({
      client_id: this.oidc.clientId,
      redirect_uri: this.oidc.redirectUri,
      response_type: this.oidc.responseType,
      scope: this.oidc.scope,
      state: this.oidc.state,
      nonce: this.oidc.nonce
    });

    return authCode$.pipe(
      tap((authCode) => {
        this.oidc.authCode = authCode;
        console.log(`Redirecting to ${this.oidc.redirectUri} with authorization code`);
        this.redirect.redirectToExternal(this.oidc.redirectUri, {
          code: authCode,
          scope: this.oidc.scope,
          state: this.oidc.state,
          nonce: this.oidc.nonce
        });
      }),
      catchError((error) => {
        console.error(error);
        this.authErrorMessage = 'Something went wrong when authenticating. Please try again later.';
        return of(error);
      })
    );
  }


  login(email: string, password: string) {
    return this.http.post(`${environment.apiUrl}/authenticate`, {
      email,
      password
    }, {
      observe: 'response'
    });
  }

  logout() {
    this.localStorage.clear();
    this.user.signOutCurrentUser();
    this.router.navigate(['login']);
  }

  exchangeToken({ accessToken, idToken }: { accessToken: string, idToken: string }) {
    return this.http.post(`${environment.apiUrl}/sso-exchange`, {
      accessToken,
      idToken
    }, {
      observe: 'response'
    });
  }

  loginWithMicrosoft() {
    if (this.interactionInProgress)
      return;
    this.setInProgress(true);
    this.msal.loginRedirect({
      scopes: ['openid', 'profile', 'User.Read'],
      prompt: 'select_account',
      redirectUri: `${window.location.origin}/login`
    }).subscribe({
      error: (error) => {
        console.error(error);
        this.authErrorMessage = 'Something went wrong when authenticating. Please try again later.';
        this.setInProgress(false);
      },
      complete: () => {
        this.setInProgress(false);
      }
    });
  }

  saveToken(response: HttpResponse<Object> | string) {
    if (typeof response === 'string') {
      this.localStorage.setItem('jwt', response);
    } else {
      const authorizationHeader = response.headers.get('Authorization');
      if (authorizationHeader) {
        const token = authorizationHeader.replace('Bearer ', '');
        this.localStorage.setItem('jwt', token);
      }
    }
  }

  getToken() {
    return this.localStorage.getItem('jwt');
  }

  authenticated() {
    return !!this.getToken() &&
      !this.jwt.isTokenExpired(this.getToken()!);
  }

  get inProgress() {
    return this.inProgress$.asObservable();
  }

  hasAnyRole(roles: UserRole[]) {
    const userRole = this.user.getSelf();
    return userRole.pipe(
      map(user => user.roles.some(role => roles.includes(role)))
    );
  }
}
