import { Component, Input, OnInit } from '@angular/core';
import { CourseData } from '@app/api/models/learning-material-data.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { CourseApiService } from '../../services/course-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-edit-course',
  templateUrl: './view-edit-course.component.html',
  styleUrls: ['./view-edit-course.component.sass']
})
export class ViewEditCourseComponent implements OnInit {

  @Input() currentCourseId?: number;
  @Input() showTitle = true;
  courses: CourseData [] = [];
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 10;

  constructor(private courseApi: CourseApiService,
    private router: Router,
    private modal: GlobalModalService){
  }

  ngOnInit() {
    this.loadCourses();
  }

  loadCourses() {
    this.loading = true;
    this.courseApi.getCoursesByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.courses = page.content.filter(c => c.id !== this.currentCourseId);
      this.loading = false;
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditCoursePage(course: CourseData) {
    this.router.navigate(['course-composer', 'courses', 'edit', course.id]);
  }

  deleteCourse(id: number) {
    this.modal.confirm('Are you sure you want to delete this course?', {
      title: 'Delete Course',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.courseApi.deleteCourse(id).subscribe({
            next: () => {
              this.courses = this.courses.filter(course => course.id !== id);
            }
          });
        }
      }
    });
  }

}
