import { Routes } from '@angular/router';
import { ContentCatalogPageComponent } from '@pages/library-page/content-catalog-page/content-catalog-page.component';
import {
  ContentLibraryHomePageComponent
} from '@pages/library-page/content-library-home-page/content-library-home-page.component';
import {
  ActivityMaterialType,
  ActivityMaterialTypes
} from '@app/api/categorization/models/activity-material-type.model';

export const ContentLibraryRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    data: { title: 'Content Library' },
    component: ContentLibraryHomePageComponent
  },
  {
    path: 'assignments',
    data: { title: 'Assignments', contentTypes: ['ASSIGNMENT'] as ActivityMaterialType[] },
    component: ContentCatalogPageComponent
  },
  {
    path: 'quizzes',
    data: { title: 'Quizzes', contentTypes: ['QUIZ', 'QUESTION'] as ActivityMaterialType[] },
    component: ContentCatalogPageComponent
  },
  {
    path: 'courses',
    data: { title: 'Courses', contentTypes: ['COURSE'] as ActivityMaterialType[] },
    component: ContentCatalogPageComponent
  },
  {
    path: 'modules',
    data: { title: 'Modules', contentTypes: ['MODULE'] as ActivityMaterialType[] },
    component: ContentCatalogPageComponent
  },
  {
    path: 'lessons',
    data: { title: 'Lessons', contentTypes: ['LESSON'] as ActivityMaterialType[] },
    component: ContentCatalogPageComponent
  },
  {
    path: 'catalog',
    data: { title: 'Content Library', contentTypes: ActivityMaterialTypes.values() },
    component: ContentCatalogPageComponent
  }
];
