<circle-progress [percent]="percent * 100"
                 [innerStrokeColor]="completed ? 'transparent' : colorHex(color + '-bg-subtle')"
                 [innerStrokeWidth]="strokeWidth(size)"
                 [outerStrokeWidth]="strokeWidth(size)"
                 [space]="-strokeWidth(size)"
                 [showSubtitle]="false"
                 [radius]="radius(size)"
                 [backgroundColor]="completed && percent === 1 ? colorHex(color) : 'transparent'"
                 [backgroundPadding]="0"
                 [animateTitle]="percent !== 1"
                 [titleColor]="completed ? 'white' : colorHex(color + '-bg')"
                 [titleFontSize]="fontSize(size)"
                 [unitsColor]="completed ? 'white' : colorHex(color + '-bg')"
                 [unitsFontSize]="fontSize(size, 0.8)"
                 [outerStrokeColor]="colorHex(color)"></circle-progress>
