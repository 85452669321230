import { Component } from '@angular/core';

@Component({
  selector: 'app-create-quiz-page',
  templateUrl: './create-quiz-page.component.html',
  styleUrls: ['./create-quiz-page.component.sass']
})
export class CreateQuizPageComponent {

}
