import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { CustomValidators } from '@core/validation/custom-validators';

@Component({
  template: ''
})
export abstract class AbstractCoreFormControlComponent implements OnInit {

  protected readonly CustomValidators = CustomValidators;
  protected readonly Validators = Validators;

  @Input() label = '';
  @Input() controlName = '';
  @Input() customErrorMessages: { [error: string]: string } = {};
  control?: FormControl<any>;
  @Output() controlFocus = new EventEmitter<FocusEvent>();

  protected constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.control = this.formGroupDirective.control.get(this.controlName) as FormControl<any>;
  }
}
