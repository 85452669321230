<div>
  <h1 class="text-center my-3">
    {{ learningActivity.title }}
  </h1>

  <ng-container *ngIf="!loading; else spinner" class="container">
    <div *ngIf="!start && !learningActivity.completed" class="text-center">
      <p class="mb-3">{{ quiz.description }}</p>
      <p>Number of questions: {{ quiz.questionCount }}</p>
      <div class="text-center my-5">
        <button (click)="startQuiz()" class="btn btn-primary">Start Quiz</button>
      </div>
    </div>

    <div *ngIf="!learningActivity.completed && start">
      <app-quiz-question-stepper
        #stepper
        (selectedIndexChange)="onSelectedIndexChange($event)"
        [selectedIndex]="currentQuestionIndex">
        <cdk-step
          *ngFor="let data of questions; let i = index "
          [completed]="quizForm.at(i).valid">
          <div class="mx-5 cdk-step">
            <div *ngIf="data.text" class="my-3">
              {{ i + 1 }} Question:  {{ data.text }}
            </div>
            <form [formGroup]="quizForm.at(i)" class="card">
              <div class="card-body">
                <div
                  *ngIf="data.type !== 'FILL_IN_THE_BLANK';
                   else fitbQuestionText" class="my-3">
                  <app-markdown-viewer [data]="data.fillInTheBlankText || ''"/>
                </div>
                <ng-template #fitbQuestionText>
                  <div class="my-3 text-muted">
                    Fill in the blank(s).
                  </div>
                  <div class="my-2">
                    <app-question-fill-in-the-blank-answer
                      [correctIndexes]="correctIndexes"
                      [questionText]="data.fillInTheBlankText || ''"
                      [showValidity]="submitted"
                      formControlName="fitbAnswer"/>
                  </div>
                </ng-template>
                <div
                  *ngIf="data.selectAllThatApply" class="my-3 text-muted small">
                  Select all that apply.
                </div>
                <div *ngIf="data.type === 'MULTIPLE_CHOICE' && data.choices" class="my-3">
                  <app-question-choice-group
                    [choices]="data.choices"
                    formControlName="mcAnswer">
                  </app-question-choice-group>
                </div>
                <div *ngIf="data.type === 'TRUE_FALSE'" class="my-3">
                  <app-question-true-false-choice-group formControlName="tfAnswer"/>
                </div>
                <div *ngIf="data.type === 'SHORT_ANSWER' || data.type === 'INTERVIEW'" class="my-3">
                  <div *ngIf="data.type === 'SHORT_ANSWER'" class="my-2">
                    <div class="mb-2 text-muted small">
                      Enter a short answer below:
                    </div>
                    <app-markdown-editor
                      [showPreviewButton]="false"
                      [useMarkdown]="false"
                      formControlName="saAnswer"
                      label="Your answer here..."
                      placeholder="Your answer here..."/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </cdk-step>
      </app-quiz-question-stepper>
      <div
        *ngIf="quizForm.valid"
        class="text-center my-3">
        <button
          (click)="completeQuiz()"
          [disabled]="!quizForm.valid"
          class="btn btn-primary">Complete Quiz
        </button>
      </div>

    </div>

    <div *ngIf="learningActivity.completed">
      <app-quiz-results
        [learningActivity]="learningActivity"
        [questions]="questions"
        [quiz]="quiz"
        [submission]="submission">
      </app-quiz-results>
    </div>

  </ng-container>

  <ng-template #spinner>
    <div class="my-3 flex-center">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
