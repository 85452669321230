import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/api/user/services/user.service';
import { UserRole } from '@app/api/user/models/user.model';
import { CustomValidators } from '@core/validation/custom-validators';
import { map } from 'rxjs';
import { CreateUserRequest } from '@app/api/user/models/create-user-request.model';
import { GlobalModalService } from '@core/services/global-modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MicrosoftUserSearchResult } from '@app/api/user/models/microsoft-user-search-result.model';

@Component({
  selector: 'app-add-user-form',
  templateUrl: './add-user-form.component.html',
  styleUrls: ['./add-user-form.component.sass']
})
export class AddUserFormComponent implements OnInit {

  @Output() cancelClicked = new EventEmitter<void>();
  @ViewChild('addFromMicrosoftModal') addFromMicrosoftModal: any;

  addUserForm: FormGroup;
  showPassword = false;
  roles: { label: string, value: string }[] = [];
  selectedMicrosoftUser?: MicrosoftUserSearchResult;

  inProgress = false;

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private modal: GlobalModalService,
    private ngbModal: NgbModal) {
    this.addUserForm = this.fb.group({
      email: ['', [
        Validators.email,
        Validators.required
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(128),
        CustomValidators.password
      ]],
      confirmPassword: ['', [
        Validators.required
      ]],
      showPassword: [false],
      firstName: ['', [
        Validators.required
      ]],
      lastName: ['', [
        Validators.required
      ]],
      role: [UserRole.Student, [
        Validators.required
      ]],
      invite: [false]
    });

    const passwordControl = this.addUserForm.get('password');
    const confirmPasswordControl = this.addUserForm.get('confirmPassword');

    confirmPasswordControl?.addValidators([
      CustomValidators.matches(passwordControl)
    ]);
  }

  generatePassword() {
    // Generate a random password with 12 characters A-Z, a-z, 0-9, and special characters (e.g. !@#$%^&*()_+)

    // Make sure that the password contains at least one of each type of character
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()_+';

    const randomPassword = [
      upperCase[Math.floor(Math.random() * upperCase.length)],
      lowerCase[Math.floor(Math.random() * lowerCase.length)],
      numbers[Math.floor(Math.random() * numbers.length)],
      specialCharacters[Math.floor(Math.random() * specialCharacters.length)],
      ...Array.from({ length: 8 }, () => {
        const allCharacters = upperCase + lowerCase + numbers + specialCharacters;
        return allCharacters[Math.floor(Math.random() * allCharacters.length)];
      }
      )].join('');

    this.addUserForm.get('password')?.setValue(randomPassword);
    this.addUserForm.get('confirmPassword')?.setValue(randomPassword);
  }

  onSubmit() {
    this.addUserForm.disable();
    this.modal.confirm('Are you sure you want to create this user?')
      .subscribe((confirmed) => {
        if (confirmed) {
          this.inProgress = true;
          this.createUser();
        } else {
          this.addUserForm.enable();
        }
      });
  }

  createUser() {
    const userRole = this.addUserForm.get('role')?.value as string;
    let role = 'Student';
    if (userRole) {
      role = userRole.toUpperCase().replace(' ', '_');
    }
    const request: CreateUserRequest = {
      ...this.addUserForm.value,
      role
    };
    this.userService.createUser(request).subscribe({
      next: (response) => {
        this.modal.alert({
          title: 'Success',
          content: `User ${response.email} created successfully.`,
          type: 'success'
        });
        this.inProgress = false;
        this.addUserForm.reset();
        this.addUserForm.enable();
      },
      error: (error) => {
        this.modal.alert({
          title: 'Error',
          content: error.error.message,
          type: 'danger'
        });
        this.inProgress = false;
        this.addUserForm.enable();
      },
      complete: () => {
        this.inProgress = false;
        this.addUserForm.enable();
      }
    });
  }

  ngOnInit() {
    this.userService.getAllRoles()
      .pipe(
        map(roles => roles
          .map(role => {
            return {
              label: role,
              value: role
            };
          })
        )
      ).subscribe(roles => this.roles = roles);
  }

  openMicrosoftUserModal() {
    this.ngbModal.open(this.addFromMicrosoftModal);
  }

  onSelectMicrosoftUser(user: MicrosoftUserSearchResult) {
    this.selectedMicrosoftUser = user;
  }

  populateFromMicrosoftUser() {
    if (this.selectedMicrosoftUser) {
      this.addUserForm.get('email')?.setValue(this.selectedMicrosoftUser.email);
      this.addUserForm.get('firstName')?.setValue(this.selectedMicrosoftUser.givenName);
      this.addUserForm.get('lastName')?.setValue(this.selectedMicrosoftUser.surname);
      this.addUserForm.get('invite')?.setValue(true);
      this.generatePassword();
      this.ngbModal.dismissAll();
    }
  }

}
