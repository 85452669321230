import { Component } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';

@Component({
  selector: 'app-manage-activities-page',
  templateUrl: './manage-activities-page.component.html',
  styleUrls: ['./manage-activities-page.component.sass']
})
export class ManageActivitiesPageComponent {

  constructor(
    private learningActivityService: LearningActivityService
  ) {
  }

}
