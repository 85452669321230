<div class="modal-header">
  <h5 class="modal-title">Verify Email</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close">
  </button>
</div>
<div class="modal-body">
  <p class="mb-4">Your email verification is still pending. To finalize the process, please click the button below to send a verification email.</p>
  <p *ngIf="error" class="mb-2 text-danger">Something went wrong. Please try again later.</p>
  <div class="my-3 text-center">
    <button type="button"
            *ngIf="!sent; else sentView"
            [disabled]="sending"
            (click)="sendVerificationEmail()"
            class="btn btn-primary">
      Send Verification Email <span *ngIf="!sending; else spinner" class="bi bi-send-fill"></span>
      <ng-template #spinner>
        <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
      </ng-template>
    </button>
    <ng-template #sentView>
      <div class="w-100">
        <p class="my-2">Verification Email Sent <span class="bi bi-check-circle-fill text-success"></span></p>
        <button type="button" class="btn btn-outline-primary" (click)="activeModal.close()">
          Close
        </button>
      </div>
    </ng-template>
  </div>
</div>
