<form ngbDropdown [(open)]="showDropdown" [formGroup]="searchForm">
  <div class="form-control" [class.div-focus]="searchInputFocused" tabindex="0" >
    <div class="d-flex flex-row gap-1 flex-wrap align-items-center">
      <div *ngFor="let cohort of selectedCohorts" [ngClass]="{ 'text-muted': disabled }" class="rounded px-2 py-1 me-2 border bg-light d-flex justify-content-start align-items-center">
        <span class="bi bi-people-fill text-muted me-2"></span>
        {{cohort.name}}
        <button [disabled]="disabled" class="btn btn-link text-decoration-none text-secondary" (click)="deleteCohort(cohort)">
          <span class="bi bi-x"></span>
        </button>
      </div>
      <div *ngIf="maxCohorts === 0 || selectedCohorts.length < maxCohorts" class="flex-grow-1" (focusin)="searchInputFocused = true" (focusout)="searchInputFocused = false">
        <input class="form-control-plaintext"
               #searchInput
               [disabled]="disabled"
               formControlName="search"
               [placeholder]="placeholder"
               (focus)="searchInputFocused = true"
               (blur)="searchInputFocused = false"/>
      </div>
    </div>
  </div>
  <div ngbDropdownMenu class="w-100">
    <ng-container *ngIf="cohortResults.length && !searching; else noResults">
      <button [tabIndex]="i + 1" ngbDropdownItem *ngFor="let result of cohortResults; let i = index" (click)="addCohort(result)" (keyup.enter)="addCohort(result)" class="py-2">
        <span class="bi bi-people-fill text-muted me-2"></span>
        {{result.name}}
      </button>
    </ng-container>
    <ng-template #noResults>
      <button ngbDropdownItem class="text-center text-muted py-2" [disabled]="true">
        <span *ngIf="searching" class="spinner-border text-primary">
        </span>
        <span *ngIf="!searching">
          No Results
        </span>
      </button>
    </ng-template>
  </div>
</form>

