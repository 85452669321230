<div class="container-fluid">
  <div class="row">
    <div class="d-none d-md-block col-sm-6 col-md-5 col-lg-3">
      <div class="m-2 border rounded h-100">
        <div class="mb-3 border-bottom p-2">
          Course Preview
        </div>
        <div class="mx-2">
          <app-course-tree [courseData]="courseData"></app-course-tree>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-7">
      <div class="mb-3 py-2 border-bottom">
        <h3><span class="bi bi-folder-plus text-primary"></span> {{mode | titlecase}} Course</h3>
      </div>
      <app-course-form (editModule)="openEditModulePage($event)" [(courseData)]="courseData" (triggerSaveCourse)="triggerSaveCourse()"></app-course-form>
      <div class="my-3">
        <div class="d-flex justify-content-end gap-1 align-items-center">
          <button [disabled]="!canSave()" class="btn btn-outline-primary" (click)="saveCourse()">
            Save
            <span class="bi bi-floppy"></span>
          </button>
          <button [disabled]="!canPublish()" *ngIf="!courseData.published" class="btn btn-primary" (click)="saveCourse(true)">
            Save & Publish
            <span class="bi bi-check2-circle"></span>
          </button>
          <button [disabled]="!canSave()" *ngIf="courseData.published" class="btn btn-primary" (click)="saveCourse(false)">
            Unpublish
            <span class="bi bi-check2-circle"></span>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
