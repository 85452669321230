import { Component, Input } from '@angular/core';
import { StringResourceService } from '@core/services/string-resource.service';

@Component({
  selector: 'app-string-resource',
  template: '{{$(key)}}',
})
export class StringResourceComponent {

  @Input() key!: string;

  constructor(private stringResourceService: StringResourceService) {
  }

  $(key: string): string {
    return this.stringResourceService.$(key);
  }

}
