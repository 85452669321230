import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-learning-page',
  templateUrl: './learning-page.component.html',
  styleUrls: ['./learning-page.component.sass']
})
export class LearningPageComponent {

  learningActivity?: LearningActivity;
  exitRoute = '/my-learning';

  constructor(
    private learningRouteData: LearningRouteDataService,
    private router: Router) {

    this.learningRouteData.learningActivity.subscribe(learningActivity => {
      this.learningActivity = learningActivity;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const navigation = this.router.getCurrentNavigation();
      const state = navigation?.extras.state;
      if (state) {
        if (state['exitRoute']) {
          this.exitRoute = state['exitRoute'];
        }
      }
    });

  }

  get isInPopup() {
    return !!window.opener;
  }

  openInNewWindow() {
    const routePath = this.learningRouteData.currentRoutePath;
    const pathString = routePath.join('/');

    this.router.navigate([this.exitRoute])
      .then(() => {
        window.open(pathString, '_blank', 'width=1080,height=800,toolbar=0,location=0,menubar=0');
      });
  }

  exit() {
    if (this.isInPopup) {
      window.close();
    } else {
      this.router.navigate([this.exitRoute]);
    }
  }

}
