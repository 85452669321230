import { Component } from '@angular/core';
import { SideBarItem } from '@core/components/side-bar/side-bar-item.interface';
import { sideBarItems } from '@app/routes/sidebar-items.routes';
import { SideBarService } from '@core/services/side-bar.service';

@Component({
  selector: 'app-app-frame-page',
  templateUrl: './app-frame-page.component.html',
  styleUrls: ['./app-frame-page.component.sass']
})
export class AppFramePageComponent {

  sideBarItems: SideBarItem[] = sideBarItems;

  constructor(private sidebarService: SideBarService) { }

  get sidebarExpanded(): boolean {
    return this.sidebarService.expanded;
  }

}
