import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LearningActivityRouteData } from '@app/learning/services/learning-activity-route-data.model';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Component({
  selector: 'app-learning-main-page',
  templateUrl: './learning-main-page.component.html',
  styleUrls: ['./learning-main-page.component.sass']
})
export class LearningMainPageComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('courseView') courseView?: TemplateRef<any>;
  @ViewChild('moduleView') moduleView?: TemplateRef<any>;
  @ViewChild('lessonView') lessonView?: TemplateRef<any>;
  @ViewChild('activityView') activityView?: TemplateRef<any>;
  currentView?: TemplateRef<any>;

  @Input() routeData: LearningActivityRouteData = {};
  activity?: LearningActivity;

  constructor(
    private router: Router,
    private learningActivityService: LearningActivityService,
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.updateLearningActivity();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['routeData']) {
      this.updateLearningActivity();
    }
  }

  ngAfterViewInit() {
    this.updateView();
    this.cdr.detectChanges();
  }

  updateView() {
    if (this.isCourse) {
      this.setCurrentView(this.courseView!);
    } else if (this.isModule) {
      this.setCurrentView(this.moduleView!);
    } else if (this.isLesson) {
      this.setCurrentView(this.lessonView!);
    } else if (this.isActivity) {
      this.setCurrentView(this.activityView!);
    }
  }

  updateLearningActivity() {
    if (this.currentActivityId) {
      this.learningActivityService.getLearningActivity(this.currentActivityId)
        .subscribe({
          next: activity => {
            this.activity = activity;
          }
        });
    }
  }

  get currentActivityId() {
    if (this.isCourse) {
      return this.routeData.courseId;
    } else if (this.isModule) {
      return this.routeData.moduleId;
    } else if (this.isLesson) {
      return this.routeData.lessonId;
    } else if (this.isActivity) {
      return this.routeData.activityId;
    }
    return undefined;
  }

  get isCourse() {
    return this.routeData.courseId !== undefined && this.routeData.moduleId === undefined && this.routeData.lessonId === undefined && this.routeData.activityId === undefined;
  }

  get isModule() {
    return this.routeData.moduleId !== undefined && this.routeData.lessonId === undefined && this.routeData.activityId === undefined;
  }

  get isLesson() {
    return this.routeData.lessonId !== undefined && this.routeData.activityId === undefined;
  }

  get isActivity() {
    return this.routeData.activityId !== undefined;
  }

  setCurrentView(view: TemplateRef<any>) {
    this.currentView = view;
  }

}
