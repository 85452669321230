import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { QuestionResponse } from '@app/api/question/models/question-response.model';
import { QuestionApiService } from '@app/api/question/services/question-api.service';

@Component({
  selector: 'app-question-selector-for-quiz',
  templateUrl: './question-selector-for-quiz.component.html',
  styleUrls: ['./question-selector-for-quiz.component.sass']
})
export class QuestionSelectorForQuizComponent {

  @Input() initialSelectedQuestions: QuestionResponse[] = [];
  @Output() selectedQuestionsChanged = new EventEmitter<QuestionResponse[]>();

  search = '';
  page = {
    page: 0,
    size: 10,
    sortBy: 'id',
    direction: 'desc',
    total: 0
  };

  questions: QuestionData[] = [];
  responses: QuestionResponse[] = [];
  selectedQuestions: QuestionResponse[] = [];
  hideTechnologiesFilter = true;
  searchTimeout: any;
  loading = false;
  published = true;

  constructor(private questionApiService: QuestionApiService) {}

  ngOnInit() {
    
    this.selectedQuestions = [...this.initialSelectedQuestions];
    this.loadQuestions();
  }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes['initialSelectedQuestions'] && !changes['initialSelectedQuestions'].firstChange) {
      this.selectedQuestions = [...this.initialSelectedQuestions]; }
  }

  loadQuestions() {
    this.loading = true;
    
    this.questionApiService.getQuestions(this.search, {
      page: this.page.page - 1,
      size: this.page.size,
      sort: `${this.page.sortBy},${this.page.direction}`
    })
      .subscribe({
        next: (responses) => {
          this.responses = responses.content.filter(question => question.published);
          this.page.page = responses.number + 1;
          this.page.size = responses.size;
          this.page.total = responses.totalElements;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error loading response:', error);
          this.loading = false;
        }
      });
  }

  onSelectedQuestions(question: QuestionResponse) {
    const index = this.selectedQuestions.findIndex(q => q.id === question.id);
    if (index !== -1) {
      this.selectedQuestions.splice(index, 1); 
    } else {
      this.selectedQuestions.push(question); 
    }
    this.selectedQuestionsChanged.emit(this.selectedQuestions);
  }

  isQuestionSelected(question: QuestionResponse): boolean {
    return this.selectedQuestions.some(q => q.id === question.id);
  }

  initiateSearch() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.loadQuestions();
    }, 250);
  }
}
