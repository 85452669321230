import { Component } from '@angular/core';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';
import { FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-type-ahead-field',
  templateUrl: './type-ahead-field.component.html',
  styleUrls: ['./type-ahead-field.component.sass']
})
export class TypeAheadFieldComponent extends AbstractCoreFormControlComponent {

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }

}
