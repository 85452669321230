<div *ngIf="!selected" ngbDropdown [autoClose]="false" [(open)]="showDropdown" class="my-3">
  <div class="form-floating">
    <input ngbDropdownToggle
           appAutofocus
           type="text"
           class="form-control"
           id="searchInput"
           [(ngModel)]="searchQuery"
           (input)="search()"
           placeholder="Search User">
    <label class="form-label" for="searchInput">Search User</label>
  </div>
  <div ngbDropdownMenu class="w-100">
    <ng-container *ngIf="!searching; else spinner">
      <ng-container *ngIf="results.length; else noResults">
        <div ngbDropdownItem *ngFor="let result of results" (click)="selectUser(result); showDropdown = false">
          <div class="d-flex py-2 flex-row justify-content-between align-items-center">
            <div class="flex-shrink-1 mx-2">
              <img *ngIf="result.profilePictureUrl; else defaultUserProfilePicture"
                   [src]="result.profilePictureUrl" class="rounded-circle" width="40" height="40" alt="">
            </div>
            <div class="flex-grow-1">
              <div class="fw-bold">{{result.displayName}}</div>
              <div class="small text-muted">{{result.email}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noResults>
        <div class="text-muted text-center my-3">
          No Results
        </div>
      </ng-template>
    </ng-container>
    <ng-template #spinner>
      <div class="text-center w-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary my-3" role="status"></div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="selected" class="d-flex py-2 flex-row justify-content-between align-items-center">
  <div class="flex-shrink-1 mx-2">
    <img *ngIf="selected.profilePictureUrl; else defaultUserProfilePicture"
         [src]="selected.profilePictureUrl" class="rounded-circle" width="40" height="40" alt="">
    <ng-template #defaultUserProfilePicture>
      <span class="bi bi-person-circle fs-1 text-primary"></span>
    </ng-template>
  </div>
  <div class="flex-grow-1">
    <div class="fw-bold">{{selected.displayName}}</div>
    <div class="small text-muted">{{selected.email}}</div>
  </div>
  <div class="flex-shrink-1 mx-2">
    <button class="btn btn-outline-danger btn-sm" (click)="clearSelected()">
      <span class="bi bi-trash"></span>
    </button>
  </div>
</div>

<ng-template #defaultUserProfilePicture>
  <span class="bi bi-person-circle fs-1 text-primary"></span>
</ng-template>
