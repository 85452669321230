import { Injectable } from '@angular/core';
import { LearningActivityRouteData } from '@app/learning/services/learning-activity-route-data.model';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';
import { ParamMap } from '@angular/router';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LearningRouteDataService {

  private _routeData: LearningActivityRouteData = {};
  private learningActivity$ = new Subject<LearningActivity>();

  constructor(private learningActivityService: LearningActivityService) { }

  updateRouteData(params: ParamMap) {
    const routeData: LearningActivityRouteData = {};

    if (params.has('courseId')) {
      routeData.courseId = Number(params.get('courseId'));
    }

    if (params.has('moduleId')) {
      routeData.moduleId = Number(params.get('moduleId'));
    }

    if (params.has('lessonId')) {
      routeData.lessonId = Number(params.get('lessonId'));
    }

    if (params.has('activityId')) {
      routeData.activityId = Number(params.get('activityId'));
    }

    this.routeData = routeData;
    this.updateLearningActivity();
  }

  get currentRoutePath() {
    if (this.isCourse) {
      return this.getCoursePathArray();
    } else if (this.isModule) {
      return this.getModulePathArray();
    } else if (this.isLesson) {
      return this.getLessonPathArray();
    } else if (this.isActivity) {
      return this.getActivityPathArray();
    }
    return [];
  }

  set routeData(routeData: LearningActivityRouteData) {
    this._routeData = routeData;
  }

  get routeData() {
    return this._routeData;
  }

  getCoursePathArray(courseId?: number) {
    const pathArray: (string | number)[] = ['/learning'];

    if (courseId) {
      pathArray.push('course', courseId);
    } else if (this.routeData.courseId) {
      pathArray.push('course', this.routeData.courseId);
    }

    return pathArray;
  }

  getModulePathArray(moduleId?: number) {
    const pathArray: (string | number)[] = ['/learning'];

    if (this.routeData.courseId) {
      pathArray.push('course', this.routeData.courseId);
    }

    if (moduleId) {
      pathArray.push('module', moduleId);
    } else if (this.routeData.moduleId) {
      pathArray.push('module', this.routeData.moduleId);
    }

    return pathArray;
  }

  getLessonPathArray(lessonId?: number) {
    const pathArray: (string | number)[] = ['/learning'];

    if (this.routeData.courseId) {
      pathArray.push('course', this.routeData.courseId);
    }

    if (this.routeData.moduleId) {
      pathArray.push('module', this.routeData.moduleId);
    }

    if (lessonId) {
      pathArray.push('lesson', lessonId);
    } else if (this.routeData.lessonId) {
      pathArray.push('lesson', this.routeData.lessonId);
    }

    return pathArray;
  }

  getActivityPathArray(activityId?: number) {
    const pathArray: (string | number)[] = ['/learning'];

    if (this.routeData.courseId) {
      pathArray.push('course', this.routeData.courseId);
    }

    if (this.routeData.moduleId) {
      pathArray.push('module', this.routeData.moduleId);
    }

    if (this.routeData.lessonId) {
      pathArray.push('lesson', this.routeData.lessonId);
    }

    if (activityId) {
      pathArray.push('activity', activityId);
    } else if (this.routeData.activityId) {
      pathArray.push('activity', this.routeData.activityId);
    }

    return pathArray;
  }

  getRoutePathArray(type: ActivityMaterialType | 'ACTIVITY', id?: number) {
    switch (type) {
      case 'COURSE':
        return this.getCoursePathArray(id);
      case 'MODULE':
        return this.getModulePathArray(id);
      case 'LESSON':
        return this.getLessonPathArray(id);
      case 'ASSIGNMENT':
      case 'QUIZ':
      case 'ACTIVITY':
        return this.getActivityPathArray(id);
      default:
        return [];
    }
  }

  updateLearningActivity() {
    if (this.currentActivityId) {
      this.learningActivityService.getLearningActivity(this.currentActivityId)
        .subscribe({
          next: activity => {
            this.learningActivity$.next(activity);
          }
        });
    }
  }

  get learningActivity() {
    return this.learningActivity$.asObservable();
  }

  get currentActivityId() {
    if (this.isCourse) {
      return this.routeData.courseId;
    } else if (this.isModule) {
      return this.routeData.moduleId;
    } else if (this.isLesson) {
      return this.routeData.lessonId;
    } else if (this.isActivity) {
      return this.routeData.activityId;
    }
    return undefined;
  }

  get isCourse() {
    return this.routeData.courseId !== undefined && this.routeData.moduleId === undefined && this.routeData.lessonId === undefined && this.routeData.activityId === undefined;
  }

  get isModule() {
    return this.routeData.moduleId !== undefined && this.routeData.lessonId === undefined && this.routeData.activityId === undefined;
  }

  get isLesson() {
    return this.routeData.lessonId !== undefined && this.routeData.activityId === undefined;
  }

  get isActivity() {
    return this.routeData.activityId !== undefined;
  }

}
