import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DiscussionsService } from '@app/api/discussion/service/discussions.service';
import { TechnologyTag } from '@app/api/categorization/models/technology-tag.model';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { UserService } from '@app/api/user/services/user.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-forum-page',
  templateUrl: './forum-page.component.html',
  styleUrls: ['./forum-page.component.sass']
})
export class ForumPageComponent implements OnInit {

  isSidebarOpen: boolean = true;
  selectedTags: TechnologyTag[] = [];
  popularTags: TechnologyTag[] = [];
  currentSortOption: string = 'Latest';
  selectedTag: any;
  removedTag: any;
  searchTerm: string = '';
  loading: boolean = false;
  discussions: Discussion[] = [];
  filteredPosts: Discussion[] = [];
  loginUserRole: string = 'Admin'

  constructor(
    private router: Router,
    private discussionService: DiscussionsService,
    private userService: UserService,
    private globalModalService: GlobalModalService
  ) { }

  ngOnInit(): void {
    this.loadPopularTags();
    this.userService.getSelfRole().subscribe((role: string) => {
      console.log("user role => [" + role + "]");
      this.loginUserRole = role;
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error fetching User Role:', error);
    });
  }

  loadPopularTags(): void {
    this.discussionService.getPopularTags().subscribe(
      (tagsData: any[]) => {
        this.popularTags = tagsData.map(tagInfo => ({
          id: tagInfo[0],
          name: tagInfo[1],
          type: { id: tagInfo[2], name: tagInfo[3] }
        }));
        console.log("tagData: => ", tagsData);
      },
      (error) => {
        console.error('Error loading popular tags:', error);
      }
    );
  }

  onSearchClick(): void {

  }

  goToNewDiscussion(): void {
    this.router.navigate(['/discussion/new-discussion']);
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  onSortOptionClick(sortOption: string): void {
    this.currentSortOption = sortOption;
  }

  selectTag(tag: TechnologyTag): void {
    const index = this.selectedTags.findIndex(selectedTag => selectedTag.id === tag.id);
    if (index === -1) {
      this.selectedTags.push(tag);
      console.log("selected tags : => ", JSON.stringify(this.selectedTags));
      this.selectedTag = tag;
    }
  }

  removeTag(tag: TechnologyTag): void {
    this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag.id !== tag.id);
    this.removedTag = tag;
  }

}
