import { TemplateRef } from '@angular/core';
import { GlobalModalComponent } from '@core/components/global-modal/global-modal.component';

export interface GlobalModalOptions {
  title?: string;
  content: string | TemplateRef<any>;
  type: 'success' | 'danger' | 'warning' | 'info';
  size?: 'sm' | 'lg' | 'xl';
  dismissable?: boolean;
  closeable?: boolean;
  centered?: boolean;
  okButtonText?: string;
  cancelButtonText?: string;
  okButtonCallback?: (modal?: GlobalModalComponent, selectedValue?: string | null) => void;
  cancelButtonCallback?: (modal?: GlobalModalComponent) => void;
  showCancelButton?: boolean;
  hideButtons?: boolean;
  showSpinner?: boolean;
  staticBackdrop?: boolean;
  iconClass?: string;
  resourceName?: string;
  deleteMode?: boolean;
  options?: { label: string, value: string }[];
}

export const GlobalModalDefaultOptions: {
  [key: string]: GlobalModalOptions;
} = {
  success: {
    title: 'Success',
    content: '',
    type: 'success',
    closeable: true,
    centered: true,
    okButtonText: 'OK'
  },
  danger: {
    title: 'Error',
    content: '',
    type: 'danger',
    closeable: true,
    centered: true,
    okButtonText: 'OK'
  },
  warning: {
    title: 'Warning',
    content: '',
    type: 'warning',
    closeable: true,
    centered: true,
    okButtonText: 'OK'
  },
  info: {
    title: 'Info',
    content: '',
    type: 'info',
    closeable: true,
    centered: true,
    okButtonText: 'OK'
  },
  confirm: {
    title: 'Confirm',
    type: 'info',
    content: '',
    closeable: true,
    centered: false,
    okButtonText: 'Yes',
    cancelButtonText: 'No',
    showCancelButton: true
  },
  select: {
    title: 'Select an Option',
    type: 'info',
    content: '',
    closeable: true,
    centered: false,
    okButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    showCancelButton: true,
    options: []
  },
  bodyOnly: {
    type: 'info',
    title: '',
    content: ''
  },
  delete: {
    title: 'Delete',
    type: 'danger',
    content: '',
    closeable: true,
    centered: false,
    okButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    showCancelButton: true,
    deleteMode: true
  }
};
