<div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="container">
          <h2 class="mt-5">How to Use the User Template</h2>
          <p class="lead">Follow these simple steps to effectively utilize the user template and create your user list offline.</p>
          <div class="alert alert-info">
            <strong>Please note:</strong> Ensure that the CSV file adheres to the specified format to be successfully imported.
          </div>
          <hr/>
          <h4>CSV Format</h4>
          <p class="mb-3">
            Please upload a CSV file with the following format:
            <ul>
              <li><strong>Email:</strong> The email address of the user.</li>
              <li><strong>First Name:</strong> The first name of the user.</li>
              <li><strong>Last Name:</strong> The last name of the user.</li>
              <li><strong>Role:</strong> The role of the user (Super Admin, Admin, Developer, Trainer, Training Assistant, Student).</li>
              <li><strong>Password:</strong> The password of the user. Make sure that the password contains at least one uppercase letter, one lowercase letter, one number, and one special character. Minimum length is 8 characters.</li>
              <li><strong>Invite:</strong> true/false</li>
            </ul>
          <div class="alert alert-info">
            <p class="mt-3">Please download the user template here:
            <button type="button" (click)="downloadUserTemplate()" class="btn btn-sm btn-outline-primary">
              <i class="bi bi-download"></i> Download Template
            </button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
