<div class="container-fluid bg-light-subtle min-h-content-100">
    <div class="row h-100 py-3">

      <div class="col-4 col-sm-3 col-md-2">

        <div class="card h-100 panel-height">
          <div class="card-body">

          </div>
        </div>

      </div>

      <div class="col-8 col-sm-9 col-md-10">
        <div class="card h-100">
          <div class="card-body">
          </div>
        </div>
      </div>

    </div>
</div>
