import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EmailVerificationResponse } from '@app/api/user/models/email-verification-response.model';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService {

  constructor(private http: HttpClient) { }

  sendVerificationEmail(email: string) {
    return this.http.post<EmailVerificationResponse>(`${environment.apiUrl}/email-verification`, { action: 'send', email });
  }

  verifyEmail(token: string) {
    return this.http.post<EmailVerificationResponse>(`${environment.apiUrl}/email-verification`, { action: 'verify', token });
  }
}
