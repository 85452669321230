<ng-container *ngIf="deleteTrainerTemplate; else defaultDeleteTrainerButtonTemplate">
    <ng-container *ngTemplateOutlet="deleteTrainerTemplate; context: context"></ng-container>
</ng-container>

<ng-template #defaultDeleteTrainerButtonTemplate>
    <ng-container *ngTemplateOutlet="defaultDeleteTrainerButton; context: context"></ng-container>
</ng-template>

<ng-template #defaultDeleteTrainerButton>
    <button type="button" (click)="openDeleteTrainerModal()" class="btn btn-danger">
        Delete Trainer<span class="bi bi-trash-fill"></span>
    </button>
</ng-template>

<ng-template #deleteTrainerModal>
    <p>Delete the trainer and remove access to all their resources.</p>
    <p class="text-danger fw-bold">This action cannot be undone.</p>
    <p class="text-muted">Type <code class="border px-1 rounded">{{deleteTrainerData.email}}</code> to delete the
        trainer.</p>

    <div class="my-2">
        <input type="text" [(ngModel)]="deleteTrainerEmail" class="form-control">
    </div>
</ng-template>