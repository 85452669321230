import { Component, Input } from '@angular/core';
import { RubricData } from '@app/api/assignment/models/assignment-data.model';

@Component({
  selector: 'app-rubric-preview',
  templateUrl: './rubric-preview.component.html',
  styleUrls: ['./rubric-preview.component.sass']
})
export class RubricPreviewComponent {

  @Input() rubric!: RubricData;

}
