<div class="container-fluid">

    <div class="mt-3 mb-5 d-flex justify-content-between">
      <h2 class="h4">View Students</h2>
      <div class="flex-grow text-end">
        <button type="button"
                class="btn btn-primary w-100"
                routerLink="/training/students/add">
          <span class="bi bi-plus-circle"></span>
          Add Student
        </button>
      </div>
    </div>
  
    <div class="my-2">
      <app-view-students></app-view-students>
    </div>
  
  </div>
