<div class="my-3">
    <app-upload-field #uploadField 
        uploadAcceptedExtensions=".csv" 
        [(ngModel)]="files" 
        acceptedExtensions=".csv">
    </app-upload-field>
</div>

<div class="my-3 text-end">
  <button type="button" class="btn btn-primary" (click)="submitImport()">Submit</button>
</div>