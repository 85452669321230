import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@core/services/environment.service';
import { MicrosoftUserSearchResult } from '@app/api/user/models/microsoft-user-search-result.model';
import { map, mergeMap, toArray } from 'rxjs';
import { UserService } from '@app/api/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftUserService {

  constructor(private http: HttpClient,
    private user: UserService,
    private env: EnvironmentService) { }

  searchUsers(searchQuery: string) {
    return this.http.get<MicrosoftUserSearchResult[]>(
      `${this.env.getEnv('apiUrl')}/users/microsoft-users`,
      {
        params: { searchQuery }
      })
      .pipe(
        // Flatten the array to individual user objects
        mergeMap(users => users),
        // For each user, get the blob and update profilePictureUrl
        mergeMap(user => {
          // If the user has a profile picture, get the blob
          if (user.profilePictureUrl) {
            return this.http.get(user.profilePictureUrl, { responseType: 'blob' }).pipe(
              map(blob => {
                const blobUrl = URL.createObjectURL(blob);
                return { ...user, profilePictureUrl: blobUrl };
              })
            );
          } else {
            // Otherwise, just return the user as-is
            return [{ ...user, profilePictureUrl: undefined }];
          }
        }),
        // Aggregate updated user objects back into an array
        toArray()
      );
  }
}
