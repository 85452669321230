import { Component, OnDestroy } from '@angular/core';
import { CourseData, LessonData, ModuleData } from '@app/api/models/learning-material-data.model';
import { CourseApiService } from '@app/api/course/services/course-api.service';
import { GlobalToastService } from '@core/services/global-toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@core/guards/can-component-deactivate.guard';
import { KeyBindingService } from '@core/services/key-binding.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { catchError, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-course-form-page',
  templateUrl: './course-form-page.component.html',
  styleUrls: ['./course-form-page.component.sass']
})
export class CourseFormPageComponent implements CanComponentDeactivate, OnDestroy {

  courseData: CourseData = {
    title: '',
    description: '',
    modules: [],
    technologies: []
  };
  mode: 'create' | 'edit' = 'create';
  saved = false;

  constructor(
    private courseApi: CourseApiService,
    private toastService: GlobalToastService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: GlobalModalService,
    private keyBindingService: KeyBindingService) {

    this.mode = this.route.snapshot.data['mode'];

    if (this.mode === 'create') {
      this.courseData = {
        title: '',
        description: '',
        modules: [],
        technologies: []
      };
    } else {
      const courseId = this.route.snapshot.params['id'];
      this.courseApi.getCourseById(courseId).subscribe(courseData => {
        this.courseData = courseData;
      });
    }

    this.keyBindingService.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      if (this.canSave()) {
        this.saveCourse();
      }
    });

  }

  ngOnDestroy() {
    this.keyBindingService.deregisterKeyBind('ctrl+s');
  }

  canDeactivate() {
    // Confirm if the user wants to leave the page if the course has not been saved
    if (!this.saved) {
      return this.modalService.confirm('Are you sure you want to leave this page? Any unsaved changes will be lost.');
    }
    return true;
  }

  canSave(): boolean {
    return this.courseData.title.trim().length > 0;
  }

  canPublish(): boolean {
    return this.canSave() && !this.courseData.published;
  }

  saveCourse(publish?: boolean, navigate?: boolean): Observable<CourseData> {

    const courseData$ = new Subject<CourseData>();

    const courseId = this.courseData.id;

    if (publish !== undefined) {
      this.courseData.published = publish;
    }

    if (this.mode === 'create') {
      this.courseApi.createCourse(this.courseData)
        .pipe(catchError(err => {
          if (err.status && err.status === 400) {
            this.modalService.alert({
              title: 'Error',
              type: 'danger',
              content: `There was an error creating the course.\n\n${err.error.message}`
            });
          }
          return of(null);
        }))
        .subscribe(courseData => {
          if (!courseData) {
            console.error('Error creating course');
            return;
          }
          this.courseData = courseData;
          this.saved = true;
          this.toastService.showSuccessToast('Course created successfully!');
          if(navigate){
            this.router.navigate(['/course-composer/modules/create'], {
              queryParams: { courseId: courseData.id },
            });
          }
          else{
            this.router.navigate(['course-composer', 'courses', 'edit', this.courseData.id]);
          }
          courseData$.next(courseData);
        });
    } else {
      this.courseApi.updateCourse(courseId!, this.courseData)
        .pipe(catchError(err => {
          if (err.status && err.status === 400) {
            this.modalService.alert({
              title: 'Error',
              type: 'danger',
              content: 'There was an error creating the course.\n`' + err.error.message + '`'
            });
          }
          return of(null);
        }))
        .subscribe(courseData => {
          if (!courseData) {
            console.error('Error updating course');
            return;
          }
          this.courseData = courseData;
          this.saved = true;
          this.toastService.showSuccessToast('Course updated successfully!');
          if(navigate){
            this.router.navigate(['/course-composer/modules/create'], {
              queryParams: { courseId: courseData.id },
            });
          }
          courseData$.next(courseData);
        });
    }

    return courseData$.asObservable();
  }

  openEditModulePage(moduleData: ModuleData) {
    this.modalService.confirm('You have unsaved changes. Would you like to save this course before leaving?', {
      title: 'Save Course',
      okButtonText: 'Save',
      cancelButtonText: 'Don\'t Save',
      type: 'warning',
      dismissable: true,
      okButtonCallback: (modal) => {
        this.saveCourse().subscribe({
          next: (courseData) => {
            if (courseData) {
              this.courseData = courseData;
              this.saved = true;
              this.router.navigate(['course-composer', 'modules', 'edit', moduleData.id]);
              modal?.closeModal();
            }
          }
        });
      },
      cancelButtonCallback: (modal) => {
        this.router.navigate(['course-composer', 'modules', 'edit', moduleData.id]);
        modal?.closeModal();
      }
    });
  }

  triggerSaveCourse(){
    this.saveCourse(false, true);
  }
}
