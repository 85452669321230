<div class="my-2 d-flex justify-content-center flex-column">
  <div class="w-100 d-flex justify-content-start my-2 gap-2 flex-row-reverse align-items-center">
    <div class="col-12 col-md-3">
      <app-search-bar-input [(search)]="search" (loadResults)="getAllStudents()" ></app-search-bar-input>
    </div>
    <div ngbDropdown>
      <button type="button"
              ngbDropdownToggle
              ngbTooltip="Sort"
              class="btn btn-light">
        <span class="bi bi-sort-alpha-down"></span>
        Sort by: <span class="mx-1">{{mapPropertyToLabel(pagination.sortBy)}}</span>
      </button>
      <div ngbDropdownMenu style="z-index: 1001;">
        <button (click)="pagination.sortBy = 'id'; pagination.direction = 'desc'; getAllStudents()" ngbDropdownItem>
          Newest First
        </button>
        <button (click)="pagination.sortBy = 'id'; pagination.direction = 'asc'; getAllStudents()" ngbDropdownItem>
          Oldest First
        </button>
        <button (click)="pagination.sortBy = 'id'; getAllStudents()" ngbDropdownItem>
          ID
        </button>
        <button (click)="pagination.sortBy = 'user.email'; getAllStudents()" ngbDropdownItem>
          Email
        </button>
        <button (click)="pagination.sortBy = 'user.firstName'; getAllStudents()" ngbDropdownItem>
          First Name
        </button>
        <button (click)="pagination.sortBy = 'user.lastName'; getAllStudents()" ngbDropdownItem>
          Last Name
        </button>
      </div>
    </div>
    <div class="mx-1">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio"
                  [(ngModel)]="pagination.direction"
                  name="sortDirection"
                  value="asc"
                  (change)="getAllStudents()"
                  class="form-check-input"/>
          Ascending
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio"
                  [(ngModel)]="pagination.direction"
                  name="sortDirection"
                  value="desc"
                  (change)="getAllStudents()"
                  class="form-check-input"/>
          Descending
        </label>
      </div>
    </div>
  </div>

  <table class="table table-hover">
    <thead>
      <tr class="table-light">
        <th>ID</th>
        <th>Email</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th><span class="sr-only">Action</span></th>
      </tr>
    </thead>
    <tbody>
      <tr class="align-middle" [routerLink]="[ student.id ]" *ngFor="let student of students">
        <td >
          {{student.id}}
        </td>
        <td >
          {{student.email}}
        </td>
        <td >
          {{student.firstName}}
        </td>
        <td >
          {{student.lastName}}
        </td>

        <td click-stop-propagation ngbDropdown>
          <button type="button"
                  ngbDropdownToggle
                  class="btn btn-link dropdown-no-caret">
            <span class="bi bi-three-dots-vertical"></span>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['/training', 'students', 'edit', student.id]">
              <span class="bi bi-pencil-square"></span>
              Edit
            </button>
            <button class="text-danger" ngbDropdownItem (click)="deleteStudent(student)">
              <span class="bi bi-trash"></span>Delete
            </button>
          </div>
        </td>
      </tr>
      <tr class="align-middle" *ngFor="let _ of emptyRows()">
        <td colspan="6" class="bg-light-subtle">
          <span class="btn bg-transparent cursor-default">
            &nbsp;
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mx-auto">
    <ngb-pagination
      [collectionSize]="pagination.total"
      [(page)]="pagination.page"
      [pageSize]="pagination.size"
      [maxSize]="10" [boundaryLinks]="true"
      (pageChange)="getAllStudents()"></ngb-pagination>
  </div>
</div>

