<div class="position-relative">
  <div class="ratio ratio-16x9">
    <ng-container *ngIf="apiVideo; else youtubeVideoPlayer">
      <video #videoTemplate
             (canplay)="onVideoLoaded()"
             (timeupdate)="onTimeUpdate()"
             (waiting)="showLoader()"
             (playing)="hideLoader()"
             *ngIf="videoMetadata && metadataLoaded; else videoPlaceholder"
             preload="auto"
             [poster]="thumbnailUrl">
        <source [src]="videoMetadata.url">
      </video>
      <ng-template #videoPlaceholder>
        <div class="w-100 h-100 bg-dark d-flex justify-content-center align-items-center text-light">
          <div class="my-2 text-center">
            <ng-container *ngIf="metadataLoaded; else loading">
              <span class="fs-1 bi bi-camera-video-off"></span>
              <p class="my-2">
                We're sorry, but this video is not available.
              </p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #youtubeVideoPlayer>
      <iframe [src]="youtubeUrl | safe: 'resourceUrl'"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
    </ng-template>
  </div>
  <div class="position-absolute top-0 left-0 d-flex justify-content-center align-items-center w-100 h-100 animate__animated animate__faster animate__fadeIn" *ngIf="skipping">
    <div *ngIf="skipForward" class="text-end text-light w-100 animate__animated animate__delay-1s animate__faster animate__fadeOut">
      <span class="bi bi-skip-forward-fill fs-1 me-5 px-3"></span>
    </div>
    <div *ngIf="skipBackward" class="text-start text-light w-100 animate__animated animate__delay-1s animate__faster animate__fadeOut">
      <span class="bi bi-skip-backward-fill fs-1 ms-5 px-3"></span>
    </div>
  </div>
  <div class="position-absolute top-0 left-0 d-flex justify-content-center align-items-center w-100 h-100" *ngIf="buffering">
    <div class="spinner-border text-light"></div>
  </div>
  <div *ngIf="apiVideo" class="video-overlay">
    <div *ngIf="apiVideo && metadataLoaded" class="w-100 h-100 d-flex justify-content-center align-items-center" (dblclick)="checkDblClick($event)" (click)="togglePlayBtn()">
      <button *ngIf="!videoPlayed" class="btn btn-primary btn-lg rounded-4" (click)="doAction('play')" click-stop-propagation>
        <span class="bi bi-play-fill mx-2 my-3"></span>
      </button>
      <span *ngIf="videoData.playing" class="fs-1 text-light animate__animated animate__faster animate__delay-2s animate__fadeOut">
        <span class="bi bi-pause-fill animate__animated animate__fadeIn animate__faster"></span>
      </span>
      <span *ngIf="!videoData.playing && videoPlayed" class="fs-1 text-light animate__animated animate__faster animate__delay-2s animate__fadeOut">
        <span class="bi bi-play-fill animate__animated animate__fadeIn animate__faster"></span>
      </span>
    </div>
    <div class="bottom-fourth-controls">
      <div *ngIf="apiVideo && metadataLoaded" class="video-controls">
      <input type="range"
             [(ngModel)]="videoData.currentTime"
             [max]="videoData.duration"
             [ngStyle]="rangeStyle"
             (input)="seekVideo()"
             class="video-scrubber">
      <div class="button-controls">
        <div class="text-start w-100">
          <button type="button" class="btn-control" (click)="doAction('togglePlay')">
            <span class="bi"
                [ngClass]="{
                  'bi-play-fill': !videoData.playing,
                  'bi-pause-fill': videoData.playing
                }"></span>
          </button>

          <button type="button" class="btn-control" (click)="doAction('skipBackwards10s')">
            <span class="bi bi-skip-backward-fill"></span>
          </button>
          <button type="button" class="btn-control" (click)="doAction('skipForward10s')">
            <span class="bi bi-skip-forward-fill"></span>
          </button>
          <span class="mx-1 text-light small">
            {{ videoData.currentTime / 100 | timeFormat }} / {{ videoData.duration / 100 | timeFormat }}
          </span>
        </div>
        <div class="text-end w-100 pe-3">
          <button type="button" class="btn-control" (click)="doAction('toggleMute')">
            <span class="bi"
                  [ngClass]="{
                  'bi-volume-mute-fill': videoData.muted,
                  'bi-volume-up-fill': !videoData.muted
                }"></span>
          </button>
          <button type="button" class="btn-control" (click)="doAction('toggleFullscreen')">
            <span class="bi"
                  [ngClass]="{
                    'bi-fullscreen': !videoData.fullscreen,
                    'bi-fullscreen-exit': videoData.fullscreen
                  }"></span>
          </button>
        </div>
      </div>
    </div>

    </div>
  </div>
</div>

<ng-template #loading>
  <div class="spinner-border text-primary"></div>
</ng-template>
