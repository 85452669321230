import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-modules-home-page',
  templateUrl: './modules-home-page.component.html',
  styleUrls: ['./modules-home-page.component.sass']
})
export class ModulesHomePageComponent {

  routeCards: RouteCardItem[] = [
    {
      title: 'Create Module',
      description: 'Create a new module for your course.',
      icon: 'bi-folder-plus',
      routerLink: '/course-composer/modules/create'
    },
    {
      title: 'View/Edit Modules',
      description: 'View and edit the modules in your course.',
      icon: 'bi-pencil-square',
      routerLink: '/course-composer/modules/edit'
    }
  ];

}
