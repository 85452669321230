import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-course-composer-home-page',
  templateUrl: './course-composer-home-page.component.html',
  styleUrls: ['./course-composer-home-page.component.sass']
})
export class CourseComposerHomePageComponent {
  items: RouteCardItem[] = [
    {
      title: 'Video Upload',
      description: 'Upload a video to the video library.',
      icon: 'bi-upload',
      routerLink: '/course-composer/video-upload',
    },
    {
      title: 'Create an Assignment',
      description: 'Create a new assignment.',
      icon: 'bi-file-earmark-plus',
      routerLink: '/course-composer/create-assignment',
    },
    {
      title: 'Add to Question Bank',
      description: 'Add a question to the question bank.',
      icon: 'bi-plus-circle',
      routerLink: '/course-composer/question-creator'
    },
    {
      title: 'Create a Quiz',
      description: 'Create a new quiz for a course.',
      icon: 'bi-plus-square',
      routerLink: '/course-composer/quiz-creator'
    },
    {
      title: 'Create a Lesson',
      description: 'Create a new lesson for a course.',
      icon: 'bi-bookmark-plus',
      routerLink: '/course-composer/lessons'
    },
    {
      title: 'Create a Module',
      description: 'Create a new module for a course.',
      icon: 'bi-folder-plus',
      routerLink: '/course-composer/modules'
    },
    {
      title: 'Create a Course',
      description: 'Create a new course.',
      icon: 'bi-journal-plus',
      routerLink: '/course-composer/courses'
    }
  ];
}
