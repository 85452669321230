<app-landing-page-frame>
  <div class="my-3 text-center">
    <div class="fs-1">
      <div *ngIf="verifying; else icon" class="fs-5 spinner-border text-black-50"></div>
      <ng-template #icon>
        <div *ngIf="verified; else notVerifiedIcon" class="bi bi-check-circle-fill text-success"></div>
        <ng-template #notVerifiedIcon>
          <div class="bi bi-exclamation-circle-fill text-danger"></div>
        </ng-template>
      </ng-template>
    </div>
    <h1 *ngIf="!verifying" class="h3">
      {{ verified ? 'Verification Successful!' : 'Unable to Verify' }}
    </h1>
    <p class="my-4">{{
      verifying ? 'Verifying your email address...' :
        verified ? 'Your email address has been verified.' :
          'Your email address could not be verified. Please try again later.'
      }}</p>
    <div *ngIf="!verifying" class="my-2">
      <button type="button"
              class="btn btn-primary"
              routerLink="/">
        <span class="bi bi-arrow-left"></span>
        Go back to Smoothstack Learn
      </button>
    </div>
  </div>
</app-landing-page-frame>
