<div class="container-fluid" *ngIf="lessonData">
  <div class="py-2 border-bottom">
    <div class="d-flex flex-row gap-1 justify-content-between align-items-center px-4">
      <h3 class="my-2">
        <span class="bi bi-bookmark-plus text-primary"></span> {{ this.mode | titlecase }} Lesson
      </h3>
      <div class="d-flex flex-row gap-1 justify-content-end align-items-center">
        <button *ngIf="!showAddToModuleButton" class="btn btn-outline-primary" (click)="saveLesson()">Save <span class="bi bi-floppy"></span></button>
        <button *ngIf="!lessonData.published && !showAddToModuleButton" class="btn btn-primary" (click)="saveLesson(true)">Save & Publish <span class="bi bi-check2-circle"></span></button>
        <button *ngIf="lessonData.published && !showAddToModuleButton" class="btn btn-primary" (click)="saveLesson(false)">Unpublish <span class="bi bi-check2-circle"></span></button>
        <button *ngIf="showAddToModuleButton" (click)="saveLesson(true)" type="button" class="btn btn-primary" [ngbTooltip]="'Add lesson to module'">
          <span class="bi bi-file-earmark-plus"></span>
          Add Lesson
        </button>
      </div>
    </div>
  </div>
  <div class="row mx-3">
    <div class="col-12 col-md-7">
      <app-lesson-form [(lessonData)]="lessonData" (triggerSaveLesson)="triggerSaveLesson()"></app-lesson-form>
    </div>
    <div class="col-12 col-md-5 border-start">
      <app-lesson-preview [lessonData]="lessonData"></app-lesson-preview>
    </div>
  </div>
</div>
