import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { LearningActivity, SubmissionComment } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '../../services/learning-activity.service';
import { UserService } from '@app/api/user/services/user.service';
import { UserRole } from '@app/api/user/models/user.model';

@Component({
  selector: 'app-assignment-view',
  templateUrl: './assignment-view.component.html',
  styleUrls: ['./assignment-view.component.sass']
})
export class AssignmentViewComponent implements OnInit, OnChanges {

  @Input() assignmentId!: number;
  @Input() learningActivity!: LearningActivity;
  @Input() enableSmoothScroll = false;
  assignmentData?: AssignmentData;
  loading = false;
  viewing: 'ASSIGNMENT' | 'RUBRIC' = 'ASSIGNMENT';
  @Input() gradingView = false;
  gradeAvailable = false;
  userRole: UserRole | undefined;
  commentContent = '';
  isSubmitting = false;
  comments: SubmissionComment[] = [];
  commentReply = '';
  replyFormVisibility: { [commentId: number]: boolean } = {};
  replyContent = '';

  constructor(private assignmentApi: AssignmentApiService,
    private learningActivityService: LearningActivityService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.loadAssignmentData();
    this.getUserRole();
    this.fetchComments();
  }

  loadAssignmentData() {
    this.loading = true;
    this.assignmentApi.getAssignment(this.assignmentId).subscribe({
      next: assignmentData => {
        this.assignmentData = assignmentData;
        this.loading = false;
        this.gradeAvailable = this.learningActivity.submission.score !== null;
      }
    });
  }

  getUserRole() {
    this.userService.getSelfRole().subscribe(role => {
      this.userRole = role;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['assignmentId']) {
      this.loadAssignmentData();
    }
  }

  handleProgressChange(progress: number) {
    this.learningActivity.progress = progress;
  }

  addCommentToAssignment() {
    console.log('Before API call - isSubmitting:', this.isSubmitting);
    this.isSubmitting = true;
    console.log('After setting isSubmitting to true - isSubmitting:', this.isSubmitting);

    const currentUser = this.userService.currentUser;
    const author: string = currentUser?.email ?? '';

    const commentData: SubmissionComment = {
      id: 0,
      content: this.commentContent,
      author: author,
      edited: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      replies: []
    };

    this.learningActivityService.addCommentToAssignmentSubmission(this.learningActivity.id, commentData)
      .subscribe({
        next: (newComment: SubmissionComment) => {
          console.log('New comment added:', newComment);
          this.commentContent = '';
          this.fetchComments();
        },
        error: (error) => {
          console.error('Error adding comment:', error);
        },
        complete: () => {
          this.isSubmitting = false;
          console.log('After API call complete - isSubmitting:', this.isSubmitting);
          this.cdRef.detectChanges();
        }
      });
  }

  fetchComments() {
    console.log('Learning Activity ID:', this.learningActivity.id);
    this.learningActivityService.getCommentsForAssignmentSubmission(this.learningActivity.id)
      .subscribe(comments => {
        this.comments = comments;

        this.comments.forEach(comment => {
          this.replyFormVisibility[comment.id] = false;
          this.fetchReplyContent(comment.id);
        });
      });
  }

  replyToFeedback(comment: SubmissionComment) {
    console.log('Replying to feedback:', comment);

    this.replyFormVisibility[comment.id] = !this.replyFormVisibility[comment.id];

    const replyData: SubmissionComment = {
      id: 0,
      content: this.replyContent,
      author: this.userService.currentUser?.email ?? '',
      edited: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      replies: []
    };
    this.learningActivityService.addReplyToComment(comment.id, replyData)
      .subscribe({
        next: (newComment: SubmissionComment) => {
          console.log('Reply added:', newComment);
          this.commentReply = '';
          this.fetchComments();
        },
        error: (error) => {
          console.error('Error replying to feedback:', error);
        }
      });
  }

  toggleReplyForm(comment: SubmissionComment) {
    this.replyFormVisibility[comment.id] = !this.replyFormVisibility[comment.id];
    if (this.replyFormVisibility[comment.id]) {
      this.commentReply = '';
    }
  }

  fetchReplyContent(commentId: number) {
    this.learningActivityService.getReplyContent(commentId)
      .subscribe(response => {
        const comment = this.comments.find(c => c.id === commentId);
        if (comment) {
          comment.replies = response.map(reply => ({
            id: 0,
            content: reply.content,
            author: reply.author,
            createdAt: new Date(),
          }));
        }
      });
  }


}
