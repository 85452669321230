type StringResources = {
  [language: string]: { [key: string]: string };
};

const strings: StringResources = {
  'en_US': {
    'app_name': 'Smoothstack Learn',
    'version': '1.0.0',
    'Sign In': 'Sign In',
    'Sign Up': 'Sign Up',
    'Sign Out': 'Sign Out',
    'Sign in with Microsoft': 'Sign in with Microsoft',
    'Email': 'Email',
    'Password': 'Password',
    'Confirm Password': 'Confirm Password',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Phone': 'Phone',
    'Address': 'Address',
    'City': 'City',
    'State': 'State',
    'Zip': 'Zip',
    'Country': 'Country',
    'Submit': 'Submit',
    'Cancel': 'Cancel',
    'Home': 'Home',
    'About': 'About',
    'Contact': 'Contact',
    'Profile': 'Profile',
    'Settings': 'Settings',
    'Dashboard': 'Dashboard',
    'Sign in to access your learning dashboard.': 'Sign in to access your learning dashboard.',
    'copyright': 'Smoothstack Learn © 2023',
    'or': 'or',
    'Sign in with Smoothstack Learn to access': 'Sign into Smoothstack Learn to access',
    'Sign in with': 'Sign in with',
  },
};

export default strings;
