import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AssignmentData, AssignmentSolutionType } from '@app/api/assignment/models/assignment-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignmentService } from '@app/api/assignment/services/assignment.service';
import { Location } from '@angular/common';
import { CategorizationService } from '@app/api/categorization/services/categorization.service';
import { TagData, TagDataFn } from '@core/components/forms/tags-field/tag-data.model';
import { catchError, map, Observable, of } from 'rxjs';
import { KeyBindingService } from '@core/services/key-binding.service';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { GlobalToastService } from '@core/services/global-toast.service';
import { CanComponentDeactivate } from '@core/guards/can-component-deactivate.guard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportFileService } from '@core/services/export-file.service';
import { AuthService } from '@auth/services/auth.service';
import { UserRole } from '@app/api/user/models/user.model';
import { GitLabGroup, GitLabProject } from '@app/api/integration/gitlab/models/gitlab-data.model';
import { UploadFieldComponent } from '@app/core/components/form-controls/upload-field/upload-field.component';

@Component({
  selector: 'app-assignment-composer',
  templateUrl: './assignment-composer.component.html',
  styleUrls: ['./assignment-composer.component.sass']
})
export class AssignmentComposerComponent implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy {

  @ViewChild('assignmentContent') assignmentContent?: TemplateRef<any>;
  @ViewChild('rubricDetails') rubricDetails?: TemplateRef<any>;
  @ViewChild('assignmentSolution') assignmentSolution?: TemplateRef<any>;
  @ViewChild('relevantTechnologies') relevantTechnologies?: TemplateRef<any>;
  @ViewChild('previewAssignment') previewAssignment?: TemplateRef<any>;
  @ViewChild('assignmentPreview') assignmentPreview?: TemplateRef<any>;
  @ViewChild('openAssignmentModal') openAssignmentModal?: TemplateRef<any>;
  @ViewChild('importAssignmentModal') importAssignmentModal?: TemplateRef<any>;
  @ViewChild('selectGitlabSolutionModal') selectGitlabSolutionModal?: TemplateRef<any>;
  @ViewChild('uploadField') uploadField!: UploadFieldComponent;
  tabMap: { [key: string]: TemplateRef<any> } = {};
  maxLength = 250;
  descriptionLength: number = 0;
  currentTab?: TemplateRef<any>;
  currentTabName = 'assignmentContent';
  data?: AssignmentData;
  showPreviewPanel = true;
  assignmentDirty = false;
  rubricDirty = false;
  technologiesDirty = false;
  solutionDirty = false;
  action = 'new';
  pageTitleUpdateTimeout?: any;
  saving = false;
  saved = false;
  files: File[] = [];
  published = false;
  approved = false;
  previousNavigationId = 0;
  isAdmin = false;
  isImported = false;
  solutionsGroup?: GitLabGroup;
  _selectedProject?: GitLabProject;
  showAddToLessonButton: boolean = false;
  lessonId : number = -1;
  set selectedProject(project: GitLabProject | undefined) {
    this._selectedProject = project;
    this.solutionFormGroup.get('solution')?.setValue(JSON.stringify(project) || '');
  }
  get selectedProject(): GitLabProject | undefined {
    return this._selectedProject;
  }
  currentSolutionType?: AssignmentSolutionType;

  assignmentForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private assignmentService: AssignmentService,
    private location: Location,
    private categorization: CategorizationService,
    private keyBindingService: KeyBindingService,
    private assignmentApi: AssignmentApiService,
    private modal: GlobalModalService,
    private ngbModal: NgbModal,
    private toastService: GlobalToastService,
    private exportFile: ExportFileService,
    private router: Router,
    private auth: AuthService,
    private fb: FormBuilder) {
    this.action = this.route.snapshot.data['action'];
    if (this.action == 'edit') {
      const id = this.route.snapshot.params['id'];
      this.saved = true;
      this.setAssignmentData(id);
    } else {
      this.data = this.defaultAssignmentData;
    }
    this.assignmentForm = this.createAssignmentForm(fb);
    this.setPageTitle(this.action, this.assignmentTitle);


    this.keyBindingService.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      if (this.assignmentForm.valid) {
        this.saveAssignment();
      }
    });

    this.keyBindingService.registerKeyBind('ctrl+k', (event) => {
      event.preventDefault();
      this.newAssignment();
    });

    this.keyBindingService.registerKeyBind('ctrl+o', (event) => {
      event.preventDefault();
      this.openOpenAssignmentModal();
    });

    this.auth.hasAnyRole([UserRole.Admin]).subscribe(isAdmin => this.isAdmin = isAdmin);
  }

  ngOnInit() {
    this.initializeWatchers();
    this.route.queryParams.subscribe((params) => {
      if (params['lessonId']) {
        this.showAddToLessonButton = true;
        this.lessonId = +params['lessonId'];
      }
    });
  }

  initializeWatchers() {
    this.watchSubmissionTypes();
    this.watchAssignmentTitle();
    this.watchAssignmentData();
  }

  get canGoBack() {
    // Check if there is a previous route
    const navigationId = ((this.location.getState() as any) || { navigationId: 0 })['navigationId'];

    if (navigationId > this.previousNavigationId) {
      this.previousNavigationId = navigationId;
    }

    return this.previousNavigationId > 1;
  }

  goBack() {
    // Check if there is a previous route
    this.location.back();
  }

  ngAfterViewInit() {
    this.tabMap = {
      'assignmentContent': this.assignmentContent!,
      'rubricDetails': this.rubricDetails!,
      'assignmentSolution': this.assignmentSolution!,
      'previewAssignment': this.previewAssignment!,
      'relevantTechnologies': this.relevantTechnologies!
    };

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        if (fragment === 'previewAssignment') {
          this.showPreviewPanel = false;
        }
        this.setCurrentTab(this.tabMap[fragment], fragment);
      } else {
        this.setCurrentTab(this.assignmentContent!, '');
      }
    });
  }

  createAssignmentForm(fb: FormBuilder) {
    const { overview, content } = this.assignmentService.parseMarkdown(this.data?.content || '');
    const rubric = this.data?.rubric || this.defaultAssignmentData.rubric;
    const solution = this.data?.solution || this.defaultAssignmentData.solution;

    if (solution.type === 'GITLAB_REPOSITORY' && solution.solution) {
      // Check if solution.solution is a valid JSON string
      try {
        this.selectedProject = JSON.parse(solution.solution);
      } catch (e) {
        console.error('Error parsing solution JSON', e);
      }
    }

    this.currentSolutionType = solution.type || 'TEXT';

    return fb.group({
      id: [this.data?.id],
      title: [this.data?.title],
      description: [this.data?.description, [Validators.required, Validators.maxLength(this.maxLength)]],
      overview: [overview],
      content: [content],
      submissionTypesGroup: this.fb.group({
        FILE_UPLOAD: [this.data?.submissionTypes.includes('FILE_UPLOAD')],
        TEXT: [this.data?.submissionTypes.includes('TEXT')],
        LINK: [this.data?.submissionTypes.includes('LINK')],
        CODE: [this.data?.submissionTypes.includes('CODE')],
        GITLAB_REPOSITORY: [this.data?.submissionTypes.includes('GITLAB_REPOSITORY')],
        COMPLETE_BUTTON: [this.data?.submissionTypes.includes('COMPLETE_BUTTON')]
      }),
      submissionTypes: [this.data?.submissionTypes],
      technologies: [this.data?.technologies],
      rubric: this.fb.group({
        id: [rubric.id],
        title: [rubric.title],
        description: [rubric.description],
        criteria: this.fb.array([
          ...rubric.criteria.map(criterion => {
            return this.fb.group({
              id: [criterion.id],
              title: [criterion.title],
              description: [criterion.description],
              ratings: this.fb.array([
                ...criterion.ratings.reverse().map(rating => {
                  return this.fb.group({
                    id: [rating.id],
                    title: [rating.title],
                    description: [rating.description],
                    ratingLevel: [rating.ratingLevel]
                  });
                })
              ])
            });
          })
        ])
      }),
      owner: [this.data?.owner],
      published: [this.data?.published || false],
      approved: [this.data?.approved || false],
      solution: this.fb.group({
        id: [solution.id],
        type: [solution.type],
        solution: [solution.solution]
      })
    });
  }

  getFormControl(path: string): any {
    const control = this.assignmentForm.get(path);
    return control ? control : null;
  }

  isDescriptionLimitExceeded(): boolean {
    const descriptionControl = this.assignmentForm.get('description');
    return descriptionControl?.value.length > this.maxLength;
  }

  updateCharacterCount(): void {
    const descriptionControl = this.assignmentForm.get('description');
    if (descriptionControl) {
      this.descriptionLength = descriptionControl.value.length;
      descriptionControl.markAsTouched();
    }
  }

  get solutionFormGroup() {
    return this.assignmentForm.get('solution') as FormGroup;
  }

  setPageTitle(action: string, title?: string) {
    const actionTitle = action === 'edit' ? 'Edit' : 'Create';
    this.titleService.setTitle(`${title} | ${actionTitle} Assignment`);
  }

  watchAssignmentTitle() {
    this.assignmentForm.get('title')?.valueChanges.subscribe((title: string) => {
      // Prevent updating the page title too often
      if (this.pageTitleUpdateTimeout) {
        clearTimeout(this.pageTitleUpdateTimeout);
      }
      this.pageTitleUpdateTimeout = setTimeout(() => {
        this.setPageTitle(this.action, this.assignmentTitle);
      }, 500);

      // If rubric title is empty, update it with the assignment title + ' Rubric'
      this.assignmentForm.get('rubric.title')?.setValue(`${title} Rubric`);
    });
  }

  watchSubmissionTypes() {
    const submissionTypesGroup = this.assignmentForm.get('submissionTypesGroup');
    const submissionTypes = this.assignmentForm.get('submissionTypes');
    submissionTypesGroup?.valueChanges.subscribe((value: any) => {
      submissionTypes?.setValue(Object.keys(value).filter(key => value[key]));
    });
  }

  watchAssignmentData() {
    const fields = ['title', 'description', 'overview', 'content', 'submissionTypes'];
    fields.forEach(field => {
      this.assignmentForm.get(field)?.valueChanges.subscribe(() => {
        if (this.assignmentForm.get(field)?.dirty) {
          this.assignmentDirty = true;
        }
      });
    });
    this.assignmentForm.get('rubric')?.valueChanges.subscribe(() => {
      if (this.assignmentForm.get('rubric')?.dirty) {
        this.rubricDirty = true;
      }
    });
    this.assignmentForm.get('technologies')?.valueChanges.subscribe(() => {
      if (this.assignmentForm.get('technologies')?.dirty) {
        this.technologiesDirty = true;
      }
    });
    this.solutionFormGroup.valueChanges.subscribe(() => {
      if (this.solutionFormGroup.dirty) {
        this.solutionDirty = true;
      }
    });

    this.solutionFormGroup.get('type')?.valueChanges.subscribe((type: AssignmentSolutionType) => {
      // If the solution is not empty, confirm that the user wants to change the solution type
      if (this.solutionFormGroup.get('solution')?.value &&
          this.solutionFormGroup.get('solution')?.value !== '' &&
          type !== this.currentSolutionType) {
        this.modal.confirm('Are you sure you want to change the solution type? This will remove the current solution.', {
          title: 'Change Solution Type',
        }).subscribe((confirmed) => {
          if (confirmed) {
            this.solutionFormGroup.get('solution')?.setValue('');
            this.selectedProject = undefined;
            this.currentSolutionType = type;
          } else {
            this.solutionFormGroup.get('type')?.setValue(this.currentSolutionType);
          }
        });
      } else {
        this.currentSolutionType = type;
      }
    });
  }

  mapFormToAssignmentData(): AssignmentData {
    const { id, title, description, overview, content, submissionTypes, technologies, rubric, solution } = this.formValue;
    const contentMd = this.assignmentService.constructContentMd(overview, content);
    return {
      id,
      title,
      description,
      content: contentMd,
      submissionTypes,
      rubric,
      technologies,
      approved: this.approved,
      solution
    };
  }

  setCurrentTab(tab: TemplateRef<any>, name: string) {
    this.currentTab = tab;
    this.currentTabName = name;
    this.setHashToCurrentTab();
    this.cdr.detectChanges();
  }

  setHashToCurrentTab() {
    if (this.currentTabName) {
      this.location.go(this.location.path() + '#' + this.currentTabName);
    }
  }

  get assignmentTitle() {
    const { title } = this.formValue;

    if (!title) {
      return 'Untitled Assignment';
    }

    // Truncate title to 50 characters
    return title.length > 20 ? `${title.substring(0, 20)}...` : title || 'Untitled Assignment';
  }

  get formValueTitle() {
    return this.assignmentForm.get('title')?.value || '';
  }

  get formValue() {
    return this.assignmentForm.value;
  }

  setPublished(published: boolean) {
    this.published = published;
    this.assignmentForm.get('published')?.setValue(published);
  }

  setApproved(approved: boolean) {
    this.approved = approved;
    if (!this.approved) {
      this.setPublished(false);
    }
    this.assignmentForm.get('approved')?.setValue(approved);
  }

  setAssignmentData(id: number) {
    console.log(`Retrieving assignment data for assignment ${id}...`);
    this.assignmentApi.getAssignment(id)
      .pipe(catchError((error) => {
        console.error('Error retrieving assignment', error);
        this.modal.alert({
          title: 'Error',
          content: `There was an error retrieving the assignment.\n\n${error.error.message ? '`' + error.error.message + '`' : ''}`,
          okButtonText: 'OK',
          type: 'danger'
        });
        this.router.navigate(['/assignment-composer']);
        return of(null);
      }))
      .subscribe({
        next: (assignment) => {
          if (!assignment) {
            console.log('Error retrieving assignment');
            return;
          }
          console.log('Assignment retrieved successfully');
          this.data = assignment;
          this.setPublished(!!assignment.published);
          this.assignmentForm = this.createAssignmentForm(this.fb);
          this.setPageTitle(this.action, this.assignmentTitle);
          this.initializeWatchers();
        }
      });
  }

  get defaultAssignmentData(): AssignmentData {
    return {
      title: '',
      description: '',
      content: '',
      submissionTypes: [],
      rubric: {
        title: '',
        description: '',
        criteria: []
      },
      technologies: [],
      solution: {
        type: 'TEXT',
        solution: ''
      }
    };
  }

  onOpenAssignment(id: number) {
    if (this.formNeedsSaving) {
      this.modal.confirm('Are you sure you want to open a new assignment? Your changes will not be saved.', {
        title: 'Unsaved Changes',
      }).subscribe((confirmed) => {
        if (confirmed) {
          this.assignmentForm.reset();
          this.setAssignmentData(id);
          this.saved = true;
          this.ngbModal.dismissAll();
        }
      });
    } else {
      this.assignmentForm.reset();
      this.setAssignmentData(id);
      this.saved = true;
      this.ngbModal.dismissAll();
    }
  }

  saveAssignment() {
    // Set to saving state
    this.saving = true;

    const savingToast = this.toastService.show({
      type: 'spinner',
      content: 'Saving...',
    });

    // Map form data to assignment data
    const assignmentData = this.mapFormToAssignmentData();
    assignmentData.published = this.published;
    const id = this.data?.id;

    if (this.action === 'edit' && !id) {
      console.error('Error saving assignment. No ID.');
      this.saving = false;
      this.toastService.remove(savingToast);
      this.toastService.show({
        type: 'danger',
        content: 'Error saving assignment',
        length: 3000
      });
      return;
    }

    const saveOrUpdate = this.action === 'edit' ?
      this.assignmentApi.updateAssignment(id!, assignmentData) :
      this.assignmentApi.createAssignment(assignmentData);

    // Save assignment data
    saveOrUpdate
      .pipe(catchError((error) => {
        console.error('Error saving assignment', error);
        this.modal.alert({
          title: 'Error',
          content: `There was an error saving the assignment.\n\n\`${error.error.message}\``,
          okButtonText: 'OK',
          type: 'danger'
        });
        return of(null);
      }))
      .subscribe({
        next: (assignment) => {
          if (!assignment) {
            console.log('Error saving assignment');
            this.saving = false;
            this.toastService.remove(savingToast);
            return;
          }
          console.log('Assignment saved successfully');
          this.saving = false;
          this.toastService.remove(savingToast);
          this.setSaved();

          const id = assignment.id;

          if (this.showAddToLessonButton) {
            if (id && assignment.technologies.length >= 1){
              this.assignmentForm.patchValue({ id: id });
              this.assignmentForm.patchValue({ rubric: assignment.rubric });
              this.publishAssignment();
              this.router.navigate(
                [`/course-composer/lessons/edit`, this.lessonId],
                { queryParams: { assignmentId: id } }
              );
            } else{
              this.toastService.show({
                type: 'danger',
                content: 'Unable to add Assignment to Lesson',
                length: 3000,
              });
              console.log('Error publishing ASSIGNEMENT');
            }
          } else {
            if (id) {
              this.router
                .navigate(['/assignment-composer', id], {
                  preserveFragment: true,
                })
                .then(() => {
                  this.toastService.show({
                    type: 'success',
                    content: 'Assignment saved successfully',
                    length: 3000,
                  });
                });
            }
          }
        }
      });
  }

  publishAssignment() {
    // Set to saving state
    this.saving = true;

    // Map form data to assignment data
    const assignmentData = this.mapFormToAssignmentData();

    if (!this.published) {
      const savingToast = this.toastService.show({
        type: 'spinner',
        content: 'Publishing...',
      });

      this.assignmentApi.publishAssignment(assignmentData)
        .pipe(catchError((error) => {
          console.error('Error publishing assignment', error);
          this.modal.alert({
            title: 'Error',
            content: `There was an error publishing the assignment. ${error.error.message}`,
            okButtonText: 'OK',
            type: 'danger'
          });
          return of(null);
        }))
        .subscribe({
          next: (assignment) => {
            if (!assignment) {
              console.log('Error publishing assignment');
              this.saving = false;
              this.toastService.remove(savingToast);
              return;
            }

            // Publish assignment data
            this.setPublished(true);
            console.log('Assignment published successfully');
            this.saving = false;
            this.toastService.remove(savingToast);
            this.setSaved();

            this.toastService.show({
              type: 'success',
              content: 'Assignment published successfully',
              length: 3000
            });
          }
        });
    } else {
      this.setPublished(false);
      this.saveAssignment();
    }

  }

  get canPublish(): boolean {
    return this.isAdmin || this.approved;
  }

  approveAssignment() {
    this.saving = true;

    const assignmentData = this.mapFormToAssignmentData();

    if (!this.approved) {
      this.setApproved(true);
      const savingToast = this.toastService.show({
        type: 'spinner',
        content: 'Approving...',
      });
      this.assignmentApi.approveAssignment(assignmentData)
        .pipe(catchError((error) => {
          console.error('Error approving assignment', error);
          this.modal.alert({
            title: 'Error',
            content: `There was an error approving the assignment. ${error.error.message}`,
            okButtonText: 'OK',
            type: 'danger'
          });
          return of(null);
        }))
        .subscribe({
          next: (assignment) => {
            if (!assignment) {
              console.log('Error approving assignment');
              this.saving = false;
              this.toastService.remove(savingToast);
              return;
            }

            console.log('Assignment approved successfully');
            this.saving = false;
            this.toastService.remove(savingToast);
            this.setSaved();

            this.toastService.show({
              type: 'success',
              content: 'Assignment approved successfully',
              length: 3000
            });
          }
        });
    } else {
      this.setApproved(false);
      this.saveAssignment();
    }
  }

  setSaved() {
    this.saved = true;
    this.assignmentDirty = false;
    this.rubricDirty = false;
    this.technologiesDirty = false;
    this.solutionDirty = false;
  }

  newAssignment() {
    this.router.navigate(['/assignment-composer'], { preserveFragment: false });
  }

  openPreviewAssignmentModal() {
    this.ngbModal.open(this.assignmentPreview, {
      size: 'xl',
      scrollable: true
    });
  }

  openOpenAssignmentModal() {
    this.ngbModal.open(this.openAssignmentModal, {
      size: 'xl'
    });
  }

  openImportAssignmentModal() {
    this.ngbModal.open(this.importAssignmentModal, {
      size: 'xl'
    });
  }

  get technologyTags(): TagDataFn {
    return (tagQuery: string): Observable<TagData[]> => {
      return this.categorization.getTechnologyTags(tagQuery, 5)
        .pipe(
          map(page => page.content),
          map(tags => tags.map(
            tag => {
              return {
                label: tag.name,
                value: tag.name
              };
            }
          ))
        );
    };
  }

  get formNeedsSaving() {
    return this.assignmentDirty || this.rubricDirty || this.technologiesDirty;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.formNeedsSaving ? this.modal.confirm('Are you sure you want to leave this page? Your changes will not be saved.', {
      title: 'Unsaved Changes',
    }) : true;
  }

  ngOnDestroy() {
    this.keyBindingService.deregisterKeyBind('ctrl+s');
    this.keyBindingService.deregisterKeyBind('ctrl+k');
    this.keyBindingService.deregisterKeyBind('ctrl+o');
  }

  processFile() {
    if (this.files && this.files[0]) {
      const selectedFile = this.files[0];
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

      if (fileExtension === 'md') {
        this.readMarkdownFile(selectedFile);
      } else {
        console.log('Invalid file type');
      }
    }
  }

  readMarkdownFile(file: File): void {
    const reader = new FileReader();

    reader.onload = (e) => {
      const markdownContent = e.target?.result as string;
      const parsedData = this.assignmentService.parseMarkdown(markdownContent);

      if (parsedData.title && parsedData.title.endsWith('=')) {
        parsedData.title = parsedData.title.replace(/=+$/, '');
      }

      this.assignmentForm.patchValue(parsedData);
      this.isImported = true;
      this.ngbModal.dismissAll();

    };

    reader.readAsText(file);
  }

  exportAssignment(type: 'HTML' | 'PDF' | 'MARKDOWN') {
    this.assignmentApi.exportAssignment({
      id: this.data?.id || 0,
      type
    }).subscribe({
      next: (response) => {
        const extension = type.toLowerCase() === 'markdown' ? 'md' : type.toLowerCase();
        this.exportFile.triggerDownload(response, `${this.assignmentTitle}.${extension}`);
      }
    });
  }

  getSolutionsGroup() {
    this.assignmentApi.assignmentGitLabSolutions.subscribe({
      next: (group) => {
        this.solutionsGroup = group;
      }
    });
  }

  openSelectGitLabSolutionModal() {
    if (!this.solutionsGroup) {
      this.getSolutionsGroup();
    }

    const modal = this.ngbModal.open(this.selectGitlabSolutionModal, {
      scrollable: true
    });

    modal.result.then((result) => {
      if (result === 'select') {
        this.solutionFormGroup.get('solution')?.setValue(JSON.stringify(this.selectedProject) || '');
      } else {
        this.selectedProject = undefined;
      }
    });
  }

  checkPreviewPanel() {
    if (!this.showPreviewPanel && this.currentTabName === 'previewAssignment') {
      this.setCurrentTab(this.assignmentContent!, 'assignmentContent');
    }
  }
}
