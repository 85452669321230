import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';
import { NotificationType, UserNotification } from '@app/api/notifications/models/user-notification.model';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.sass']
})
export class NotificationViewComponent {

  notification?: UserNotification;

  constructor(private userNotificationService: UserNotificationService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => {
      const notificationId = params['id'];

      if (notificationId) {
        this.userNotificationService.readUserNotification(notificationId).subscribe(notification => {
          this.notification = notification;
          this.userNotificationService.updateNotifications();
        });
      }
    });
  }

  get regardingLearningActivity() {
    const type = this.notification?.type;

    return type === 'STUDENT_COHORT_ENROLLMENT' || type === 'STUDENT_COURSE_ENROLLMENT' || type === 'STUDENT_COURSE_COMPLETION'
      || type === 'STUDENT_NEW_ASSIGNMENT' || type === 'STUDENT_ASSIGNMENT_DUE' || type === 'STUDENT_ASSIGNMENT_LATE'
      || type === 'STUDENT_ASSIGNMENT_GRADE' || type === 'STUDENT_ASSIGNMENT_FEEDBACK';
  }

  getNotificationTypeLabel(notificationType: NotificationType) {
    return this.userNotificationService.getNotificationTypeLabel(notificationType);
  }

  deleteNotification(notificationId: string) {
    this.userNotificationService.deleteUserNotification(notificationId).subscribe(() => {
      this.notification = undefined;
      this.router.navigate(['/notifications']);
      this.userNotificationService.updateNotifications();
    });
  }

}
