<div class="w-100">
  <button type="button"
          (click)="goBack()"
          class="btn btn-link">
    <span class="bi bi-chevron-left fs-4"></span>
    <span class="visually-hidden">Go Back</span>
  </button>
</div>

<div class="row flex-row flex-nowrap">
  <div class="col-5 col-lg-4 col-xl-2 bg-light-subtle p-0" *ngIf="tree; else spinner">
    <app-lesson-tree-view *ngIf="isLesson" [lessonTree]="lessonTree"></app-lesson-tree-view>
    <app-module-tree-view *ngIf="isModule" [moduleTree]="moduleTree"></app-module-tree-view>
    <app-course-tree-view *ngIf="isCourse" [courseTree]="courseTree"></app-course-tree-view>
  </div>
  <div class="col-7 col-lg-8 col-xl-10 panel-height">
    <router-outlet></router-outlet>
  </div>
</div>


<ng-template #spinner>
  <div class="flex-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
