<div class="container-fluid" style="margin-top: 40px;">
  <div class="row">
    <div class="col-md-6">
      <h6 class="text-primary">My Bookmarks</h6>
      <div *ngFor="let bookmark of userBookmarks" class="card mb-3" style="margin-top: 20px;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title text-primary">{{ bookmark.postTitle }}</h5>
            </div>
            <div class="col-md-4 text-end">
              <ng-container *ngFor="let tag of bookmark.technologyTags">
                <div class="btn btn-secondary btn-sm"
                  style="margin-left: 5px; margin-right: 5px; background-color: lightgray !important; border-color: lightgrey !important; color: #2e2e2e !important;">
                  <i class="bi bi-tag"></i> {{ tag.name }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8" style="margin-top: 10px;">
              <p class="card-text">{{ bookmark.content }}</p>
            </div>
            <div class="col-md-4 text-end" style="margin-top: 10px;">
              <small>Posted: {{ bookmark.createdDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Updated: {{ bookmark.updatedDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Comments: {{ bookmark.commentCount }}</small>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userBookmarks.length === 0" class="card mb-3">
        <div class="card-body">
          <p class="card-text">No bookmarks found.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h6 class="text-primary">My Comments</h6>
      <div *ngFor="let comment of userComments" class="card mb-3" style="margin-top: 20px;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title">{{ comment.parentPostTitle }}</h5>
            </div>
            <div class="col-md-4 text-end">
              <ng-container *ngFor="let tag of comment.technologyTags">
                <div class="btn btn-secondary btn-sm"
                  style="margin-left: 5px; margin-right: 5px; background-color: lightgray !important; border-color: lightgrey !important; color: #2e2e2e !important;">
                  <i class="bi bi-tag"></i> {{ tag.name }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8" style="margin-top: 10px;">
              <p class="card-text">{{ comment.content }}</p>
            </div>
            <div class="col-md-4 text-end" style="margin-top: 10px;">
              <small>Posted: {{ comment.createdDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Updated: {{ comment.updatedDate | date: 'dd MMM, yyyy' }}</small><br>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userComments.length === 0" class="card mb-3">
        <div class="card-body">
          <p class="card-text">No comments found.</p>
        </div>
      </div>
    </div>
  </div>
</div>
