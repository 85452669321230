<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-5">
      <div class="container">
        <h2 class="mt-5">How to Use the Assignment Template</h2>
        <p class="lead">Follow these simple steps to effectively utilize the assignment template and create your assignments offline.</p>
        <div class="alert alert-info">
          <strong>Please note:</strong> In order to later import the assignment into the system, the HTML comments must be left intact. Do not remove them as they are used to parse the template.
        </div>
        <hr/>
        <!-- Step 1 -->
        <h4>Step 1: Download the Template</h4>
        <p class="mb-5">
          Click on the download button provided on the template preview to download the assignment template. It will be downloaded as a Markdown file. Open it in your preferred code editor, making sure not to remove the HTML style comments as they are necessary for parsing the template later.
        </p>

        <!-- Step 2 -->
        <h4>Step 2: Edit the Title</h4>
        <p class="mb-5">
          Within the <code>&lt;!--title--&gt;...&lt;!--/title--&gt;</code> section, replace <strong>Assignment Title (Template)</strong> with the title of your assignment. Do not remove the comment tags as they are essential for the template's functionality.
        </p>

        <!-- Step 3 -->
        <h4>Step 3: Edit the Description</h4>
        <p class="mb-5">
          In the description section defined by <code>&lt;!--description--&gt;...&lt;!--/description--&gt;</code>, provide a brief overview of the assignment. This should include the purpose and learning objectives. Remember not to delete the comment tags.
        </p>

        <!-- Step 4 -->
        <h4>Step 4: Detail the Overview Section</h4>
        <p class="mb-5">
          Fill in the overview section between <code>&lt;!--overview--&gt;...&lt;!--/overview--&gt;</code> with detailed information about the assignment. Include what technologies or concepts will be used and any necessary background information.
        </p>

        <!-- Step 5 -->
        <h4>Step 5: Complete the Assignment Content</h4>
        <p class="mb-5">
          In the assignment content section marked by <code>&lt;!--content--&gt;...&lt;!--/content--&gt;</code>, provide detailed instructions, code snippets, screenshots, and any other resources that are necessary. Replace the placeholder content with your own, while keeping the comment tags.
        </p>

        <!-- Step 6 -->
        <h4>Step 6: Review and Save</h4>
        <p class="mb-5">
          After editing, review the template to ensure all information is accurate and clear. Save your changes and your assignment is ready to be published or distributed. Ensure that all HTML comment tags are untouched for proper template processing.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-7">
      <div class="d-flex justify-content-end">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class="card-title my-2">Assignment Template</h5>
            <button type="button" (click)="downloadTemplate()" class="btn btn-sm btn-outline-primary">
              <i class="bi bi-download"></i> Download
            </button>
          </div>
          <div class="card-body">
            <div class="pb-3">
              <div [innerHTML]="markdownData | markdown"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
