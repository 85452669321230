import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { VideoPlayerComponent } from '@core/components/video-player/video-player.component';
import { MarkDownPdfViewerComponent } from '@core/components/mark-down-pdf-viewer/mark-down-pdf-viewer.component';
import { ActivatedRoute, Router } from '@angular/router';

declare var bootstrap: any;

@Component({
  selector: 'app-markdown-viewer',
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.sass']
})
export class MarkdownViewerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data = '';
  @Input() showFrame = false;
  @Input() frameTitle = 'Preview';
  // Only applicable if showFrame is true
  @Input() showExportOptions = false;
  @Input() enableSmoothScroll = false;

  renderVideoTimeout: any;
  selectedKey: string = '';
  sectionHeaders: { [key: string]: { text: string; level: number; subsections?: { text: string; level: number; id: string }[] } } = {};

  currentUrl: string = '';
  objectKeys = Object.keys;
  showAnchorLinks = false;

  get showExport() {
    return this.showExportOptions && this.showFrame;
  }

  constructor(
    private markdown: MarkdownService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  /**
   * Initialize the markdown renderer and set the custom renderer for certain elements
   */
  ngOnInit(): void {

    this.markdown.renderer.image = (href: string, title: string, text: string) => {
      // Check if href starts with "video@"
      if (href.startsWith('video@')) {
        const videoUrl = href.replace('video@', '');
        return `<div class="video-player ratio ratio-16x9" href="${videoUrl}">
                  <div class="bg-dark d-flex justify-content-center align-items-center">
                      <div class="spinner-border text-primary" role="status">
                      </div>
                  </div>
                </div>`;
      }

      //check if pdf
      if (href.startsWith('pdf@')) {

        const pdfUrl = href.replace('pdf@', '');

        return `<div style="height: 350px; width: 100%;" class="pdf-viewer" href="${pdfUrl}">
                  <div  class="d-flex justify-content-center align-items-center" >
                      <div class="spinner-border text-primary" role="status"></div>
                  </div>
                </div>`;

      }

      return `<img src="${href}" alt="${text}" class="img-fluid" ${title ? `title="${title}"` : ''}>`;
    };

    this.markdown.renderer.paragraph = (text: string) => {

      // Alerts
      if (text.startsWith('!')) {
        // !info text, !warning text, !danger text
        const alert = text.match(/!(info|warning|danger) (.*)/);
        if (alert) {
          const type = alert[1];
          const message = alert[2];
          return `<div class="alert alert-${type}">${message}</div>`;
        }
      }

      // Default paragraph
      return `<p class="mt-1 mb-4">${text}</p>`;
    };

    this.markdown.renderer.link = (href: string, title: string, text: string) => {
      return `<a class="link-primary" href="${href}" target="_blank" ${title ? `title="${title}"` : ''} rel="noopener noreferrer">${text}</a>`;
    };

    this.markdown.renderer.blockquote = (quote: string) => {

      // Default blockquote
      return `<blockquote class="border-start border-5 border-primary my-1 me-2 ps-2 py-2 bg-light">
                ${quote}
              </blockquote>`;
    };


    this.markdown.renderer.table = (header: string, body: string) => {
      return `<table class="table table-striped table-bordered">
                <thead class="table-light">${header}</thead>
                <tbody>${body}</tbody>
              </table>`;
    };

    this.currentUrl = this.router.url.split('#')[0];

    this.markdown.renderer.heading = (text: string, level: number) => {
      const id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '');
      const header = { text, level };
  
      if (level === 1 || level === 2) {
        this.sectionHeaders[id] = { ...header, subsections: [] };
      } else if (level > 2) {
        const lastSectionKey = Object.keys(this.sectionHeaders).pop();
        if (lastSectionKey) {
          this.sectionHeaders[lastSectionKey].subsections?.push({ text, level, id });
        }
      }
      
      return `<h${level} id="${id}">${text}</h${level}>`;
    };

    this.route.params.subscribe(params => {
      const activityId = params['id']; 

      const hash = window.location.hash.substring(1);
      if (hash) {
        this.scrollToSection(hash);
      }
    });
    
  }

  scrollToSection(hash: string) {
    const targetElement = document.getElementById(hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  renderVideoComponents() {
    const videoPlayers = document.querySelectorAll('.video-player');

    // Loop through each video player element and replace it with a rendered VideoPlayer component

    videoPlayers.forEach((value: Element, key: number, parent: NodeListOf<Element>) => {

      const videoUrl = value.getAttribute('href');

      // If videoUrl is a number
      if (!isNaN(Number(videoUrl))) {

        if (Number(videoUrl) < 1) {
          return;
        }

        // Replace this element with a VideoPlayer component
        const viewContainerRef = this.viewContainerRef;

        const injector = viewContainerRef.injector;
        const componentFactory = viewContainerRef.createComponent(VideoPlayerComponent, { injector });
        componentFactory.instance.id = Number(videoUrl);
        value.replaceWith(componentFactory.location.nativeElement);
        return;
      }

      if (videoUrl && videoUrl.startsWith('http')) {
        // Replace this element with a VideoPlayer component
        const viewContainerRef = this.viewContainerRef;

        const injector = viewContainerRef.injector;
        const componentFactory = viewContainerRef.createComponent(VideoPlayerComponent, { injector });
        componentFactory.instance.youtubeUrl = videoUrl;
        value.replaceWith(componentFactory.location.nativeElement);
      }

    });

  }

  onSelectKey(key: string): void {
    this.selectedKey = key;
  }

  startRenderVideoComponents() {
    if (this.renderVideoTimeout) {
      clearTimeout(this.renderVideoTimeout);
    }

    this.renderVideoTimeout = setTimeout(() => {
      this.renderVideoComponents();
      this.renderPdf();
    }, 1000);
  }

  renderPdf() {
    const pdfViewers = document.querySelectorAll('.pdf-viewer');
    pdfViewers.forEach((value: Element, key: number, parent: NodeListOf<Element>) => {

      const url = value.getAttribute('href');
      if (!url) {
        return;
      }

      // Replace this element with a Pdf viewer component
      const viewContainerRef = this.viewContainerRef;

      const injector = viewContainerRef.injector;
      const componentFactory = viewContainerRef.createComponent(MarkDownPdfViewerComponent, { injector });
      componentFactory.instance.src = url;
      componentFactory.location.nativeElement.style = 'width: 100%; height: 300px';
      value.replaceWith(componentFactory.location.nativeElement);

    });

  }

  ngAfterViewInit() {
    this.startRenderVideoComponents();
    this.initializeOffCanvas();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.setHeadingIds();
   this.setupSmoothScroll();
  }

  updateHeadings() {
    this.sectionHeaders = {};
    this.setHeadingIds();
  }

  initializeOffCanvas() {
    const offCanvasElement = document.getElementById('offcanvasLinks');
    if (offCanvasElement && bootstrap && bootstrap.Offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offCanvasElement);

      offCanvasElement.addEventListener('shown.bs.offcanvas', () => {
        this.showAnchorLinks = true;
      });

      offCanvasElement.addEventListener('hidden.bs.offcanvas', () => {
        this.showAnchorLinks = false;
      });
    } else {
      console.error('Bootstrap Offcanvas is not loaded or undefined.');
    }
  }

  closeOffCanvas(): void {
    this.showAnchorLinks = false;
  }
  
  setHeadingIds() {
    const headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
    headings.forEach((element: Element) => {
      const heading = element as HTMLElement; 
      const text = heading.innerText.trim();
      const id = text.toLowerCase()
                      .replace(/\s+/g, '-') 
                      .replace(/[^\w-]/g, ''); 
      this.renderer.setAttribute(heading, 'id', id);
    });
  }

  setupSmoothScroll() {
    if (!this.enableSmoothScroll) {
      return;
    }
  
    document.querySelectorAll('.scroll-links').forEach(anchor => {
      const anchorElement = anchor as HTMLAnchorElement;

      if (!anchorElement.dataset['listenerAttached']) {
        anchorElement.addEventListener('click', (event: Event) => {
          event.preventDefault();

          const targetId = anchorElement.getAttribute('href')?.split('#')[1];
          const currentUrl = this.router.url.split('#')[0];

        if (targetId) {
          this.scrollToSection(targetId);
          const targetElement = document.getElementById(targetId);
          const offset = 200;
              const elementPosition = targetElement!.getBoundingClientRect().top;
              const offsetPosition = elementPosition + window.scrollY - offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
          const newUrl = `${currentUrl}#${targetId}`;
          window.history.replaceState(null, '', newUrl); 
        } else {
          alert('No targetId extracted from href attribute.');
        }
      });
        anchorElement.dataset['listenerAttached'] = "true";
      }
    });
  } 
  
  toggleAnchorLinks() {
    this.showAnchorLinks = !this.showAnchorLinks;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.startRenderVideoComponents();
      this.updateHeadings();
    }
    if (changes['enableSmoothScroll']) {
      this.setupSmoothScroll();  
    }
  }


}
