import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Student, StudentPhase } from '@app/api/student/model/student.model';
import { Page, PageParams } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getStudentById(id: number) {
    return this.http.get<Student>(this.api.endpoint(['students', id]));
  }

  getAllStudents(search?: string, pageParams?: PageParams & {
    phases?: string[],
    cohorts?: number[]
  }) {
    return this.http.get<Page<Student>>(this.api.endpoint(['students']), {
      params: {
        ...pageParams,
        search: search || ''
      }
    });
  }

  createStudent(student: Student) {
    return this.http.post<Student>(this.api.endpoint('students'), student);
  }

  createStudents(students: Student[]) {
    return this.http.post<Student[]>(this.api.endpoint('students/import-students'), students);
  }  

  updateStudent(student: Student) {
    return this.http.put<Student>(this.api.endpoint(`students/${student.id}`), student);
  }

  deleteStudent(id: number) {
    return this.http.delete<Student>(this.api.endpoint(`students/${id}`));
  }

  getAllPhases() {
    return this.http.get<StudentPhase[]>(this.api.endpoint('students/phases'));
  }

}
