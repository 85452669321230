import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-control-validation-message',
  template: `
    <div class="m-2 small" *ngIf="control && control.errors && control.touched">
      <ng-container *ngFor="let error of errorMessages | keyvalue">
        <div class="text-danger" *ngIf="control.errors[error.key]">{{error.value}}</div>
      </ng-container>
    </div>
  `
})
export class ControlValidationMessageComponent {

  @Input() control?: FormControl<any>;

  @Input() customErrorMessages: { [error: string]: string } = {};
  @Input() messageVariables: { [variable: string]: string | number } = {};

  get errorMessages(): { [error: string]: string } {
    return {
      required: 'This field is required.',
      email: 'Please enter a valid email address.',
      pattern: 'Please enter a valid value.',
      minlength: `Please enter at least ${this.control?.errors?.['minlength']?.['requiredLength']} characters.`,
      maxlength: `Please enter no more than ${this.control?.errors?.['maxlength']?.['requiredLength']} characters.`,
      ...this.customErrorMessages
    };
  }


}
