import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Trainer } from '@app/api/models/trainer.model';
import { TrainerApiService } from '@app/api/training/services/trainer-api.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-delete-trainer',
  templateUrl: './delete-trainer.component.html',
  styleUrls: ['./delete-trainer.component.sass'],
})
export class DeleteTrainerComponent {
  @Input() deleteTrainerData!: Trainer;
  deleteTrainerEmail: string = '';
  @ViewChild('deleteTrainerModal') deleteTrainerModal!: TemplateRef<any>;
  @Output() trainerDeleted = new EventEmitter<void>();
  @ContentChild(TemplateRef) deleteTrainerTemplate?: TemplateRef<any>;
  context: any = {
    openDeleteTrainerModal: () => this.openDeleteTrainerModal(),
  };
  constructor(
    private modalService: GlobalModalService,
    private trainerApiService: TrainerApiService,
    private router: Router
  ) {}
  openDeleteTrainerModal() {
    this.modalService
      .alert({
        title: 'Delete trainer',
        content: this.deleteTrainerModal,
        type: 'danger',
        showCancelButton: true,
        okButtonText: 'Delete',
      })
      .closed.subscribe({
        next: () => {
          if (this.deleteTrainerEmail === this.deleteTrainerData.email) {
            this.deleteTrainer();
          } else {
            this.modalService
              .alert({
                title: 'Invalid email',
                content:
                  "Please enter the trainer's email address to confirm deletion or cancel deletion.",
                type: 'danger',
              })
              .result.then(() => this.openDeleteTrainerModal());
          }
          this.deleteTrainerEmail = '';
        },
      });
  }

  deleteTrainer() {
    this.trainerApiService.deleteTrainer(this.deleteTrainerData.id).subscribe({
      next: () => {
        this.modalService.alert({
          title: 'Trainer deleted',
          content: `Trainer ${this.deleteTrainerData.email} has been deleted.`,
          type: 'danger',
        });
        this.trainerDeleted.emit();
        this.router.navigate(['/training/trainers']);
      },
      error: () => {
        this.modalService.alert({
          title: 'Error',
          content: 'Failed to delete trainer',
          type: 'danger',
        });
      },
      complete: () => {},
    });
  }
}
