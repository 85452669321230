<div class="container-fluid">
  <div class="border-bottom py-3 mb-3">
    <h1 class="h2">File Manager</h1>
  </div>
  <!-- Replace below with actual content -->
  <div class="card card-body">
    <div class="my-2 text-muted">
      Nothing here yet...
    </div>
  </div>
  <!-- Replace above with actual content -->
</div>
