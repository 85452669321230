<div class="container-fluid my-3">

  <h4 class="h4">Video Upload</h4>

  <div class="row">
    <div class="m-2 col-12 col-md-7 col-lg-5">
      <app-video-upload-form></app-video-upload-form>
    </div>
    <div class="m-2 col-12 col-md-4 col-lg-3">
      <app-video-upload-preview-and-submit></app-video-upload-preview-and-submit>
    </div>
  </div>

</div>
