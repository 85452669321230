import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

export const ssoAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // Get client_id from state.url query params
  const { url } = state;

  // Get client_id from state.url query params
  const urlParams = new URL(window.location.origin + url);
  const clientId = urlParams.searchParams.get('client_id');

  // Redirect to /login with returnUrl as state.url
  return authService.authenticated() ? true : router.navigate(['/login'], {
    queryParams: {
      sso: true,
      client_id: clientId,
      returnUrl: url,
    },
  });
};
