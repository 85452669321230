import { Component, Input } from '@angular/core';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Component({
  selector: 'app-content-icon',
  styleUrls: ['./content-icon.component.sass'],
  template: `
    <div [ngSwitch]="materialType" class="text-primary fs-3 text-center">
      <span *ngSwitchCase="'ASSIGNMENT'" class="bi bi-file-earmark-text">
        <span class="visually-hidden">Assignment</span>
      </span>
      <span *ngSwitchCase="'QUIZ'" class="bi bi-check2-square">
        <span class="visually-hidden">Quiz</span>
      </span>
      <span *ngSwitchCase="'QUESTION'" class="bi bi-question-circle">
        <span class="visually-hidden">Question</span>
      </span>
      <span *ngSwitchCase="'LESSON'" class="bi bi-bookmark">
        <span class="visually-hidden">Lesson</span>
      </span>
      <span *ngSwitchCase="'MODULE'" class="bi bi-folder">
          <span class="visually-hidden">Module</span>
      </span>
      <span *ngSwitchCase="'COURSE'" class="bi bi-journal-bookmark-fill">
        <span class="visually-hidden">Course</span>
      </span>
    </div>
  `
})
export class ContentIconComponent {

  @Input() materialType?: ActivityMaterialType;

}
