export type ActivityMaterialType =
  'COURSE'
  | 'MODULE'
  | 'LESSON'
  | 'LECTURE'
  | 'QUIZ'
  | 'ASSIGNMENT'
  | 'QUESTION'
  | 'VIDEO'
  | 'DOCUMENT'
  | 'LINK'
  | 'IMAGE';

export const ActivityMaterialTypes = {
  COURSE: 'COURSE' as ActivityMaterialType,
  MODULE: 'MODULE' as ActivityMaterialType,
  LESSON: 'LESSON' as ActivityMaterialType,
  LECTURE: 'LECTURE' as ActivityMaterialType,
  QUIZ: 'QUIZ' as ActivityMaterialType,
  ASSIGNMENT: 'ASSIGNMENT' as ActivityMaterialType,
  QUESTION: 'QUESTION' as ActivityMaterialType,
  VIDEO: 'VIDEO' as ActivityMaterialType,
  DOCUMENT: 'DOCUMENT' as ActivityMaterialType,
  LINK: 'LINK' as ActivityMaterialType,
  IMAGE: 'IMAGE' as ActivityMaterialType,
  values: () => {
    return [
      ActivityMaterialTypes.COURSE,
      ActivityMaterialTypes.MODULE,
      ActivityMaterialTypes.LESSON,
      ActivityMaterialTypes.LECTURE,
      ActivityMaterialTypes.QUIZ,
      ActivityMaterialTypes.ASSIGNMENT,
      ActivityMaterialTypes.QUESTION,
      ActivityMaterialTypes.VIDEO,
      ActivityMaterialTypes.DOCUMENT,
      ActivityMaterialTypes.LINK,
      ActivityMaterialTypes.IMAGE
    ];
  }
};
