import { Component, Input } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-route-card',
  template: `
      <div class="card card-active h-100 mx-2 " [routerLink]="item.routerLink">
          <div class="card-body">
              <div class="d-flex flex-row align-items-center">
                  <div class="flex-shrink-1 p-3">
                      <span [ngClass]="['bi', 'fs-1', 'text-primary', item.icon]"></span>
                  </div>
                  <div class="flex-grow-1 px-3 text-start">
                      <div class="h5">{{item.title}}</div>
                      <p class="my-2">
                          {{item.description}}
                      </p>
                  </div>
              </div>
          </div>
      </div>
  `,
  styleUrls: ['./route-card.component.sass']
})
export class RouteCardComponent {
  @Input() item!: RouteCardItem;
}
