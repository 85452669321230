<form [formGroup]="addTrainerForm" class="my-3" (ngSubmit)="onSubmit()">

  <div class="form-switch" *ngIf="mode === 'create'">
    <input class="form-check-input me-2" type="checkbox" formControlName="addExistingUser" id="addExistingUser"
      (change)="showAppUserSelectField($event)">
    <label class="form-check-label" for="addExistingUser">
      Create trainer from existing users
    </label>
  </div>

  <div class="card card-body my-3">
    <div class="my-3" *ngIf="!isAddExistingUser">
      <app-text-field id="Input" controlName="email" type="email" label="Email"
        [readonly]="isExistingUser || mode === 'edit'"></app-text-field>
    </div>

    <div class="my-3" *ngIf="isAddExistingUser">
      <app-user-select-field id="existingUser" placeholder="Search for email..." [(selectedUsers)]="selectedUsers"
        [userFilter]="users" [maxUsers]="1" (selectedUsersChange)="addExistingUser()"></app-user-select-field>
    </div>

    <div class="my-3">
      <app-text-field controlName="firstName" type="text" label="First Name"
        [readonly]="isExistingUser || mode === 'edit'"></app-text-field>
    </div>

    <div class="my-3">
      <app-text-field controlName="lastName" type="text" label="Last Name"
        [readonly]="isExistingUser || mode === 'edit'"></app-text-field>
    </div>

    <div class="my-3">
      <app-technology-tags-field formControlName="technologies"></app-technology-tags-field>
    </div>

    <div class="my-3 text-center">
      <div class="my-3" ngbTooltip="Send trainer an email invite.">
        <app-toggle-field [hidden]="mode === 'edit'" controlName="invite" boxStyle="switch"
          label="Invite Trainer"></app-toggle-field>
      </div>
      <div class="d-flex justify-content-center" *ngIf="mode === 'create'">
        <button class="btn btn-primary mx-2" type="submit" [disabled]="addTrainerForm.invalid || inProgress">Add
          Trainer</button>
        <button class="btn btn-outline-primary mx-2" type="submit" (click)="goBack()">Cancel</button>
      </div>

      <div class="d-flex justify-content-center" *ngIf="mode === 'edit'">
        <button class="btn btn-primary mx-2" type="submit" [disabled]="addTrainerForm.invalid || inProgress">Update
          Trainer</button>

        <app-delete-trainer [deleteTrainerData]="trainer">
          <ng-template let-openDeleteTrainerModal="openDeleteTrainerModal">
            <button class="btn btn-outline-danger mx-2" type="button" (click)="openDeleteTrainerModal()">Delete</button>
          </ng-template>
        </app-delete-trainer>
      </div>

    </div>
  </div>

</form>