<div class="container">
  <div *ngIf="showTitle" class="my-2">
    <h2>My Assignments</h2>
  </div>
  <div class="w-100 d-flex justify-content-end align-items-center flex-wrap gap-2">
    <div class="mx-1 d-flex align-items-start">
      <button (click)="openFilterModal()" class="btn btn-light border me-2" type="button">
        <span class="bi bi-filter"></span>
        Filters
      </button>
      <button (click)="resetFilter(); loadAssignments()" *ngIf="hasFilter"
        class="btn btn-light border me-2 border text-muted align-self-end" type="button">
        <span class="bi bi-x-circle"></span>
        Clear Filters
      </button>
    </div>
    <div class="col-12 col-md-3">
      <app-search-bar-input (loadResults)="filterAssignments()" [(search)]="searchQuery"></app-search-bar-input>
    </div>
  </div>
  <div *ngIf="showFilterModal" class="modal fade show" tabindex="-1" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filters</h5>
          <button type="button" class="btn-close" (click)="toggleFilterModal()"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="technologyFilter">Filter by Technology</label>
            <select id="technologyFilter" class="form-select" [(ngModel)]="selectedTechnology"
              (change)="filterAssignments()">
              <option value="">All Technologies</option>
              <option *ngFor="let tech of technologies" [value]="tech">{{ tech }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="toggleFilterModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply Filters</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div *ngIf="!loading; else spinner" class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-4 mt-1 align-items-stretch">
      <div *ngFor="let assignment of filteredAssignments" class="col my-3">
        <div tabindex="0" class="card card-body h-100 assignment-card" (click)="onOpenAssignment(assignment.id!)">
          <div class="d-flex flex-row align-items-center h-100">
            <div class="bi bi-file-earmark-text text-primary display-6"></div>
            <div class="mx-2 flex-grow-1">
              <div class="fw-bold text-wrap">{{ truncateText(assignment.title, 15) || 'Untitled Assignment' }}</div>
              <p class="text-muted">{{ truncateText(assignment.description, 25) || 'No description' }}</p>
            </div>
            <div class="h-100 align-top" ngbDropdown placement="right-top" click-stop-propagation>
              <button class="btn btn-sm btn-link text-primary border-0 dropdown-no-caret" click-stop-propagation
                ngbDropdownToggle>
                <span class="bi bi-three-dots-vertical"></span>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem>
                  <div class="d-flex justify-content-between" (click)="onOpenAssignment(assignment.id!)">
                    Edit <span class="bi bi-pencil-square"></span>
                  </div>
                </button>
                <button ngbDropdownItem (click)="deleteAssignment(assignment.id!)">
                  <div class="d-flex justify-content-between">
                    Delete <span class="bi bi-trash text-danger"></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-top py-2 bg-white d-flex justify-content-center">
    <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="currentPage" [collectionSize]="totalItems"
      [pageSize]="pageSize" [boundaryLinks]="true">
    </ngb-pagination>
  </div>
</div>
<ng-template #spinner>
  <div class="row row-cols-1">
    <div class="col d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>