<div class="my-2">

  <div class="ratio ratio-16x9 bg-secondary">
    <video *ngIf="videoUploadForm.value['file']; else uploadVideoMessage" [src]="videoBlobUrl" [poster]="thumbnailBlobUrl" [controls]="true"></video>
    <ng-template #uploadVideoMessage>
      <div class="w-100 h-100 d-flex justify-content-center align-items-center text-light text-center">
        Upload a video to preview here
      </div>
    </ng-template>
  </div>

  <div *ngIf="videoUploadForm.value['title']" class="my-2 fs-5 py-2 mb-3 border-bottom">
    {{videoUploadForm.value['title']}}
  </div>

  <div class="my-2 video-description-min-height">
    <app-markdown-viewer [data]="videoUploadForm.value['description']"></app-markdown-viewer>
  </div>

  <div class="mt-4 py-3 border-top text-end">
    <button type="button"
            [disabled]="videoUploadForm.invalid"
            class="btn btn-primary"
            (click)="uploadVideo()">
        Upload <span class="ms-1 bi bi-upload"></span>
    </button>
  </div>
</div>

<ng-template #uploadingVideo>
  <div class="modal-body">
    <div class="my-5">
      <div class="w-100 d-flex justify-content-center align-items-center flex-column">
        <span class="spinner-border text-primary" role="status"></span>
        <span class="fs-4">Uploading...</span>
        <span class="small text-muted my-3">Please don't close this tab while we upload your video.</span>
      </div>
      <div class="mt-2">
        <ngb-progressbar [value]="uploadProgress"></ngb-progressbar>
        <div *ngIf="uploadProgress >= 99" class="text-center small text-muted mt-2">
          Just a moment while we finish up...
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #videoUploadSuccess>
  <div class="modal-body">
    <div class="my-5">
      <div class="w-100 d-flex justify-content-center align-items-center flex-column">
        <span class="bi bi-check-circle-fill text-success fs-1 mb-3"></span>
        <span class="fs-4">Video uploaded successfully!</span>
        <div class="my-2">
          <!-- Add a link to the video using input group for easy copying -->
          <div class="input-group">
            <input type="text" class="form-control" [value]="watchLink" readonly>
            <button class="btn btn-outline-primary" type="button" [cdkCopyToClipboard]="watchLink" ngbTooltip="Copied!" [triggers]="'click'" placement="bottom">
              <span class="bi bi-clipboard"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="editAgain()">Edit</button>
    <button type="button" class="btn btn-primary" (click)="watchNow()">Watch it now</button>
  </div>
</ng-template>
