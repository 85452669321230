<div *ngIf="submission && submissionForm" [formGroup]="submissionForm" class="my-5">

  <h3 class="h3">Completion</h3>

  <div class="my-3">

    <div *ngIf="hasSubmissionType('FILE_UPLOAD')" formGroupName="FILE_UPLOAD">
      <p class="my-2 text-muted">
        Upload a file.
      </p>
      <div *ngIf="!submissionForm.get('FILE_UPLOAD.submitted')?.value && !submission.completed; else fileUploadCompleted">
        <app-upload-field label="Upload File" [(ngModel)]="uploadedFiles" [ngModelOptions]="{ standalone: true }"></app-upload-field>
        <div *ngIf="uploadedFiles.length" class="my-2 text-end w-100">
          <button [disabled]="savingSubmission || submission.completed" type="button" class="btn btn-primary" (click)="saveFileSubmissionPart(true)">
            Save <span *ngIf="!savingSubmission; else btnSpinner" class="bi bi-floppy"></span>
          </button>
        </div>
      </div>
      <ng-template #fileUploadCompleted>
        <div class="card card-body bg-light">
          <div class="flex-center small" *ngIf="submissionForm.get('FILE_UPLOAD.content')?.value">
            <span class="fs-4 bi bi-check2-circle text-success"></span>
            File Uploaded
          </div>
          <div class="flex-center small text-muted" *ngIf="!submissionForm.get('FILE_UPLOAD.content')?.value">
            <span class="fs-4 bi bi-x-circle me-1"></span>
            No File Uploaded
          </div>
          <div *ngIf="submissionForm.get('FILE_UPLOAD.content')?.value" class="my-2 text-center">
            <button [disabled]="loadingViewFileUrl" type="button" class="btn btn-link" (click)="viewSubmissionFile()">
              View File
            </button>
          </div>
          <div *ngIf="!submission.completed" class="my-2 text-center">
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="saveFileSubmissionPart(false)">
              Replace File <span class="bi bi-pencil-square"></span>
            </button>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="hasSubmissionType('TEXT')"  formGroupName="TEXT">
      <p class="text-muted">
        Write a response.
      </p>
      <div *ngIf="!submissionForm.get('TEXT.submitted')?.value && !submission.completed; else previewResponse">
        <app-markdown-editor formControlName="content" placeholder="Your response..."></app-markdown-editor>
        <div class="my-2">
          <button type="button" class="btn btn-primary" (click)="saveTextSubmissionPart(true)">
            Save <span class="ms-1 bi bi-floppy"></span>
          </button>
        </div>
      </div>
      <ng-template #previewResponse>
        <div class="card card-body">
          <div class="d-flex justify-content-between">
            <span class="text-muted small fw-bold">Your Response</span>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="submission.completed" (click)="saveTextSubmissionPart(false)">
              Edit <span class="bi bi-pencil-square"></span>
            </button>
          </div>
          <app-markdown-viewer *ngIf="submissionForm.get('TEXT.content')?.value; else noResponse" [data]="submissionForm.get('TEXT.content')?.value" frameTitle="Your Response"></app-markdown-viewer>
          <ng-template #noResponse>
            <div class="text-muted small">No response</div>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <div *ngIf="hasSubmissionType('LINK')">
      <p class="text-muted">
        Submit link(s) to a file or website.
      </p>
      <div class="my-2" *ngIf="!submissionForm.get('LINK.submitted')?.value && !submission.completed; else previewLinks">
        <ul *ngIf="linkSubmissions.length" class="my-3 list-group">
          <li *ngFor="let linkSubmission of linkSubmissions; let index = index" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <a [href]="linkSubmission" target="_blank">{{ linkSubmission }}</a>
              <button type="button"
                      (click)="linkSubmissions.splice(index, 1)"
                      class="btn btn-outline-danger">
                <span class="visually-hidden">Delete link</span>
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </li>
        </ul>
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 #linkInput="ngModel"
                 name="linkInput"
                 [(ngModel)]="linkText"
                 [ngModelOptions]="{ standalone: true }"
                 required
                 (keyup.enter)="addLinkSubmission()"
                 [pattern]="urlRegex" placeholder="Link to file or website">
          <button class="btn btn-outline-primary" [disabled]="linkInput.invalid" (click)="addLinkSubmission()" type="button">Add Link</button>
        </div>
        <div *ngIf="linkSubmissions.length" class="text-end my-2">
          <button [disabled]="savingSubmission" type="button" class="btn btn-primary" (click)="saveLinkSubmissionPart(true)">
            Save <span *ngIf="!savingSubmission; else btnSpinner" class="bi bi-floppy"></span>
          </button>
        </div>
      </div>
      <ng-template #previewLinks>
        <div class="my-2">
          <ul *ngIf="linkSubmissions && linkSubmissions.length; else noLinks" class="list-group">
            <li *ngFor="let link of linkSubmissions" class="list-group-item">
              <a [href]="link" target="_blank" class="link-primary">{{link}}</a>
            </li>
          </ul>
          <ng-template #noLinks>
            <div class="text-muted py-2 px-3 border rounded">No links submitted</div>
          </ng-template>
          <div class="my-2 text-end">
            <button [disabled]="submission.completed" type="button" class="btn btn-primary" (click)="saveLinkSubmissionPart(false)">
              Edit <span class="bi bi-pencil-square"></span>
            </button>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="hasSubmissionType('COMPLETE_BUTTON'); else allFieldsRequiredButton">
      <p class="my-2 text-muted">
        Click the button to complete this activity.
      </p>
      <button type="button" [disabled]="savingSubmission || submission.completed" class="btn btn-primary w-100" (click)="completeSubmission()">
        {{ submission.completed ? 'Completed' : 'Complete' }} <span *ngIf="submission.completed" class="bi bi-check2-circle"></span>
      </button>
    </div>

    <ng-template #allFieldsRequiredButton>
      <div class="d-flex justify-content-end align-items-center my-3">
        <button type="button" [disabled]="savingSubmission || submission.completed" class="btn btn-primary" (click)="completeSubmission()">
          {{ submission.completed ? 'Completed' : 'Complete' }} <span class="bi bi-check2-circle"></span>
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #btnSpinner>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
</ng-template>
