import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import { Page, PageParams } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) {}

  getQuizById(id: number) {
    return this.http.get<QuizData>(this.api.endpoint(['quizzes', id]));
  }

  getQuizzesByOwner(ownerId: number | 'self', pageParams?: PageParams) {
    return this.http.get<Page<QuizData>>(this.api.endpoint(['/quizzes', 'owner', ownerId]), {
      params: {
        ...pageParams
      }
    });
  }

  createQuiz(quiz: QuizData) {
    return this.http.post<QuizData>(this.api.endpoint('quizzes'), quiz);
  }

  updateQuiz(id: number, quiz: QuizData) {
    return this.http.put<QuizData>(this.api.endpoint(['quizzes', id]), quiz);
  }

  deleteQuiz(id: number) {
    return this.http.delete(this.api.endpoint(['quizzes', id]));
  }
}
