import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { FormGroupDirective } from '@angular/forms';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';

export type AspectRatios = '*' | '21x9' | '16x9' | '4x3' | '1x1';

@Component({
  selector: 'app-file-upload-field',
  templateUrl: './file-upload-field.component.html'
})
export class FileUploadFieldComponent extends AbstractCoreFormControlComponent implements OnInit, OnDestroy {

  @Input() override controlName = '';
  @Input() override label = 'Drag and drop files here';
  @Input() acceptedExtensions = '*';
  @Input() multipleFiles = false;
  @Input() maxFiles = 1;
  @Input() previewImage = false;
  @Input() restrictAspectRatio: AspectRatios = '*';
  @Output() filesChanged = new EventEmitter<File[]>();
  @ViewChild('imagePreviewImg') imagePreviewImg?: ElementRef;
  files: File[] = [];

  imagePreviewFile?: File;
  @Input() imagePreviewUrl?: string;

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }


  dropFile(fileDropEntries: NgxFileDropEntry[]): void {
    const currentErrors = this.control?.errors;
    this.control?.markAsTouched();

    if (this.maxFilesReached) {
      console.error('Max files reached');
      this.control?.setErrors({ ...currentErrors, maxFilesReached: true });
    }

    if (this.files.length + fileDropEntries.length > this.maxFiles) {
      console.log('Max files exceeded');
      if (this.multipleFiles) {
        const remainingFiles = this.maxFiles - this.files.length;
        fileDropEntries = fileDropEntries.slice(0, remainingFiles);
        this.filesChanged.emit(this.files);
      } else {
        fileDropEntries = fileDropEntries.slice(0, 1);
        this.filesChanged.emit(this.files);
      }
    }

    // Split the accepted extensions string into an array of extensions if it is not '*'
    const acceptedExtensions = this.acceptedExtensions === '*' ? [] : this.acceptedExtensions.split(',');
    // Check if the dropped fileEntry is a file and if it has an accepted extension
    const isFileAccepted = (fileEntry: NgxFileDropEntry): boolean => {
      const { fileEntry: { name, isDirectory } } = fileEntry;
      if (!isDirectory) {
        const fileExtension = '.' + name.split('.').pop()?.toLowerCase();
        if (fileExtension) {
          return acceptedExtensions.includes(fileExtension) || this.acceptedExtensions === '*';
        }
      }
      return false;
    };

    if (this.multipleFiles) {
      for (const fileDropEntry of fileDropEntries) {
        const { fileEntry } = fileDropEntry;
        if (fileEntry.isFile) {
          const fileSystemEntry = fileEntry as FileSystemFileEntry;
          fileSystemEntry.file((file: File) => {
            // Check if the file has an accepted extension
            if (!isFileAccepted(fileDropEntry)) {
              console.error('Invalid file type');
              this.control?.setErrors({ ...currentErrors, invalidFileType: true });
              return;
            }
            this.files.push(file);
            this.control?.setValue(this.files);
            this.filesChanged.emit(this.files);
          });
          this.control?.markAsDirty();
        }
      }
    } else {
      const fileDropEntry = fileDropEntries[0];
      const { fileEntry } = fileDropEntry;
      if (fileEntry.isFile) {
        const fileSystemEntry = fileEntry as FileSystemFileEntry;
        fileSystemEntry.file((file: File) => {
          // Check if the file has an accepted extension
          if (!isFileAccepted(fileDropEntry)) {
            console.error('Invalid file type');
            this.control?.setErrors({ ...currentErrors, invalidFileType: true });
            return;
          }

          this.files.push(file);
          this.control?.setValue(file);
          this.filesChanged.emit(this.files);

          if (this.previewImage) {
            this.imagePreviewFile = file;
            this.imagePreviewUrl = URL.createObjectURL(file);
            this.validateAspectRatio();
          }
        });
        this.control?.markAsDirty();
      }
    }
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.control?.setValue(this.files);
    this.control?.markAsDirty();
    this.filesChanged.emit(this.files);

    this.imagePreviewFile = undefined;
    if (this.imagePreviewUrl) {
      URL.revokeObjectURL(this.imagePreviewUrl);
      this.imagePreviewUrl = undefined;
    }
  }

  get maxFilesReached(): boolean {
    return this.files.length >= this.maxFiles;
  }

  getIconByFileType(fileType: string): string {
    switch (fileType) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/gif':
        return 'bi-file-earmark-image';
      case 'video/mp4':
      case 'video/ogg':
      case 'video/webm':
        return 'bi-file-earmark-play';
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/wav':
        return 'bi-file-earmark-music';
      case 'application/pdf':
        return 'bi-file-earmark-pdf';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'bi-file-earmark-word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'bi-file-earmark-spreadsheet';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'bi-file-earmark-presentation';
      case 'application/zip':
      case 'application/x-zip-compressed':
      case 'application/x-7z-compressed':
      case 'application/x-rar-compressed':
      case 'application/x-tar':
        return 'bi-file-earmark-zip';
      default:
        return 'bi-file-earmark';
    }
  }

  mapLongFileTypeToShort(fileType: string): string {
    switch (fileType) {
      case 'application/pdf':
        return 'pdf';
      case 'application/msword':
        return 'doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.ms-powerpoint':
        return 'ppt';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      case 'application/zip':
      case 'application/x-zip-compressed':
      case 'application/x-7z-compressed':
      case 'application/x-rar-compressed':
      case 'application/x-tar':
        return 'zip';
      default:
        return fileType;
    }
  }

  openFileBrowser(openFileSelect: () => never) {
    this.control?.markAsTouched();
    openFileSelect();
  }

  get shouldPreviewImage() {
    if (this.multipleFiles) {
      console.error('Preview image is not supported for multiple files');
      return false;
    }
    return this.previewImage;
  }

  validateAspectRatio() {
    if (!this.imagePreviewImg) {
      console.error('Image preview img not found. Cannot validate aspect ratio.');
      return;
    }

    const image = this.imagePreviewImg.nativeElement;

    image.addEventListener('load', () => {
      const { width, height } = image;

      // Get aspect ratio of image with 2 decimal places
      const aspectRatio = width / height;

      const setAspectRatioError = () => {
        const currentErrors = this.control?.errors;
        this.deleteFile(0);
        this.control?.setErrors({ ...currentErrors, invalidAspectRatio: true });
      };

      const compareAspectRatio = (actualRatio: number, expectedRatio: number) => {
        return Math.abs(actualRatio - expectedRatio) < 0.01;
      };

      switch (this.restrictAspectRatio) {
        case '21x9':
          if (!compareAspectRatio(aspectRatio, 21 / 9)) {
            setAspectRatioError();
          }
          break;
        case '16x9':
          if (!compareAspectRatio(aspectRatio, 16 / 9)) {
            setAspectRatioError();
          }
          break;
        case '4x3':
          if (!compareAspectRatio(aspectRatio, 4 / 3)) {
            setAspectRatioError();
          }
          break;
        case '1x1':
          if (!compareAspectRatio(aspectRatio, 1)) {
            setAspectRatioError();
          }
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.files = [];
    if (this.imagePreviewUrl) {
      URL.revokeObjectURL(this.imagePreviewUrl);
    }
    this.filesChanged.emit(this.files);
  }

  clearFiles() {
    this.files = [];
    this.control?.setValue(null);
    this.control?.markAsDirty();
    this.filesChanged.emit(this.files);

    this.imagePreviewFile = undefined;
    if (this.imagePreviewUrl) {
      URL.revokeObjectURL(this.imagePreviewUrl);
      this.imagePreviewUrl = undefined;
    }
  }

}
