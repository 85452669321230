<div class="container-fluid">
  <ktd-grid [cols]="4" [rowHeight]="100" [layout]="widgetLayout" (layoutUpdated)="onLayoutUpdate($event)" [gap]="10">

    <ktd-grid-item *ngFor="let widget of widgetLayout; trackBy: trackById" [id]="widget.id">

      <app-recent-activities-widget *ngIf="widget.id === 'widget_recent_activities'"></app-recent-activities-widget>

      <!-- Place widget components here -->

      <ng-template ktdGridItemPlaceholder>
        <div class="wh-100 rounded bg-primary-subtle">
        </div>
      </ng-template>
    </ktd-grid-item>

  </ktd-grid>
</div>
