import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-question-fill-in-the-blank-answer',
  templateUrl: './question-fill-in-the-blank-answer.component.html',
  styleUrls: ['./question-fill-in-the-blank-answer.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuestionFillInTheBlankAnswerComponent,
      multi: true,
    }
  ]
})
export class QuestionFillInTheBlankAnswerComponent implements ControlValueAccessor {

  @Input()
    questionText = '';

  /**
   * Which blanks are correct (This is used to highlight the correct answers)
   */
  @Input()
    correctIndexes: number[] = [];

  @Input()
    showValidity = false;

  value: string[] = [];
  isDisabled = false;

  // {Single Answer}, {Answer}, {Answer 1|Answer 2|Answer 3}, {Answer1|Answer2|Answer3|Answer4}, Curly braces are not allowed in answers.
  private fitbAnswerRegex = /\{([^}|]+)}|\{([^}]+)\|([^}]+)}/g;

  onChange: (value: string[]) => void = () => {};
  onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.registerOnChange = fn;
  }

  processQuestionText(): string[] {
    // Return an array of strings.
    // Each string is either a blank or a non-blank.
    // A blank is a string surrounded by curly braces. (fitbAnswerRegex)
    const blanks: string[] = [];
    let match: RegExpExecArray | null;
    let lastIndex = 0;
    let blankIndex = 0;

    while ((match = this.fitbAnswerRegex.exec(this.questionText)) !== null) {
      if (match.index > lastIndex) {
        blanks.push(this.questionText.substring(lastIndex, match.index));
      }
      blanks.push(`{QUESTION_BLANK_${blankIndex++}}`);
      lastIndex = this.fitbAnswerRegex.lastIndex;
    }

    if (lastIndex < this.questionText.length) {
      blanks.push(this.questionText.substring(lastIndex));
    }

    return blanks;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    } else {
      this.value = [];
    }
  }

  isQuestionBlank(blankString: string): boolean {
    const blankRegex = /{QUESTION_BLANK_\d+}/g;
    return blankRegex.test(blankString);
  }

  getBlankId(blankString: string): number {
    const blankRegex = /{QUESTION_BLANK_(\d+)}/g;
    const match = blankRegex.exec(blankString);
    if (match) {
      return parseInt(match[1], 10);
    }
    return -1;
  }

  isCorrect(blankString: string): boolean {
    const blankId = this.getBlankId(blankString);
    return this.correctIndexes.includes(blankId);
  }
}
