import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VideoUploadForm } from '@app/api/video/components/video-upload-form/video-upload-form.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoUploadFormService {

  videoUploadForm: FormGroup;
  videoUploadFormData?: FormData;
  thumbnailBlobUrl?: string;
  videoBlobUrl?: string;

  constructor() {
    this.videoUploadForm = new FormGroup<VideoUploadForm>({
      file: new FormControl(null, { validators: [
        Validators.required,
      ], nonNullable: true }),
      title: new FormControl('', { validators: [
        Validators.required,
      ], nonNullable: true }),
      description: new FormControl(''),
      tags: new FormControl('', { nonNullable: true }),
      thumbnailFile: new FormControl(null),
    });

    this.videoUploadForm.valueChanges.subscribe({
      next: (value) => {
        // Create file name from title
        const fileName = value.title.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-') + '.mp4';
        // Convert the form value to a FormData object
        const formData = new FormData();
        formData.append('file', value.file);
        formData.append('fileName', fileName);
        formData.append('path', 'uploads');
        formData.append('title', value.title);
        formData.append('description', value.description);
        formData.append('overwrite', 'false');
        formData.append('tags', value.tags);
        if (value.thumbnailFile) {
          formData.append('thumbnailFile', value.thumbnailFile);
        }
        this.videoUploadFormData = formData;
      }
    });

    this.videoUploadForm.get('file')?.valueChanges.subscribe({
      next: (file) => {
        if (file instanceof File) {
          this.videoBlobUrl = URL.createObjectURL(file);
        } else {
          this.videoBlobUrl = undefined;
        }
      }
    });

    this.videoUploadForm.get('thumbnailFile')?.valueChanges.subscribe({
      next: (file) => {
        if (file instanceof File) {
          this.thumbnailBlobUrl = URL.createObjectURL(file);
        } else {
          this.thumbnailBlobUrl = undefined;
        }
      }
    });
  }
}
