<div>
  <div class="container d-flex justify-content-center align-items-center">
    <nav aria-label="Page navigation" class="pagination-container">
      <ul class="pagination">
        <li class="page-item pagination-arrow left">
          <button
            aria-label="Previous"
            cdkStepperPrevious class="step-item">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        <div class="page-scroll-container">
          <!--when the state has a value render in view mode-->
          <li (click)="onSelected(index)"
              *ngFor="let step of steps; index as index"
              [ngClass]="
                {
                  'active': index === selectedIndex && !step.state,
                  'disabled': !step.completed,
                }"
              class="page-item">
            <button
              [class.completed]="step.completed && index !== selectedIndex && !step.state"
              [class.correct]="step.state === 'correct'"
              [class.incorrect]="step.state === 'incorrect'"
              class="step-item">
              {{ index + 1 }}
            </button>
          </li>
        </div>
        <li class="page-item pagination-arrow right">
          <button
            aria-label="Next"
            cdkStepperNext class="step-item">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>

  <ng-container
    *ngIf="orientation === 'horizontal' else vertical"
    [ngTemplateOutlet]="selected ? selected.content : null">
  </ng-container>
  <ng-template #vertical>
    <div #verticalContainer class="vertical-container my-5">
      <ng-container
        *ngFor="let step of steps"
        [ngTemplateOutlet]="step.content" class="step">
      </ng-container>
    </div>
  </ng-template>
</div>
