import { Component, EventEmitter, Output, TemplateRef, ViewChild, } from '@angular/core';
import { AddChatService } from '@app/api/messages/services/AddChatService.service';
import { User } from '@app/api/user/models/user.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-add-chat-button',
  templateUrl: './add-chat-button.component.html',
  styleUrls: ['./add-chat-button.component.sass'],
})
export class AddChatButtonComponent {
  @ViewChild('addChatModal') addChatModal!: TemplateRef<any>;
  @Output() addChatEmit = new EventEmitter<void>();

  content = '';
  selectedUser: User[] = [];

  constructor(
    private modalService: GlobalModalService,
    private addChatService: AddChatService
  ) {}

  openAddChatModel() {
    const modalRef = this.modalService
      .alert({
        title: 'Add Chat',
        content: this.addChatModal,
        type: 'info',
        showCancelButton: true,
        okButtonText: 'Add',
      })
      .closed.subscribe({
        next: () => {
          this.addChatService
            .assignContentAndTriggerSendMessage(this.content)
            .subscribe(
              {
                next: (response) => {
                  this.addChatEmit.emit();
                  this.content = '';
                  console.log('Message sent successfully:', response);
                },
                error: (error) => {
                  console.error('Failed to send message:', error);
                }
              }
            );
        },
      });
  }

  assignReceiver() {
    this.addChatService.assignReceiver(this.selectedUser[0]);
  }
}
