import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {

  expanded = false;

  constructor() { }

  toggleSidebar() {
    this.expanded = !this.expanded;
  }
}
