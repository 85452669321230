import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { repeat, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-mark-down-pdf-viewer',
  templateUrl: './mark-down-pdf-viewer.component.html',
  styleUrls: ['./mark-down-pdf-viewer.component.sass']
})
export class MarkDownPdfViewerComponent implements OnInit {
  @Input() src = '';
  destroyRef$ = inject(DestroyRef);
  pageNumber = 1;
  totalPages = 0;
  hasLoaded = false;
  showControls = true;
  autoHideControls = true;

  afterLoadComplete($event: PDFDocumentProxy) {
    this.totalPages = $event.numPages;
    this.hasLoaded = true;
  }

  ngOnInit(): void {
    timer(5000).pipe(
      takeUntilDestroyed(this.destroyRef$),
      repeat()
    ).subscribe(() => {
      if (this.autoHideControls) {
        this.showControls = false;
      }
    });
  }

  downloadPdf() {
    window.open(this.src, '_blank');
  }
}
