import { Component, Input, OnInit } from '@angular/core';
import { QuizData } from '../../models/quiz.model';
import { Router } from '@angular/router';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-view-edit-quiz',
  templateUrl: './view-edit-quiz.component.html',
  styleUrls: ['./view-edit-quiz.component.sass'],
})
export class ViewEditQuizComponent implements OnInit {
  @Input() showTitle = true;
  @Input() currentQuizId?: number;
  quizzes: QuizData[] = [];
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  searchQuery = '';
  filteredQuiz: QuizData[] = [];
  selectedTechnology = ''; 
  technologies: string[] = []; 
  showFilterModal = false; 
  hasFilter = false; 

  constructor(
    private quizService: QuizService,
    private router: Router,
    private modal: GlobalModalService,
  ) {}

  ngOnInit() {
    this.loadQuizzes();
  }

  loadQuizzes() {
    this.loading = true;
    this.quizService.getQuizzesByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.totalItems = page.totalElements;
      this.quizzes = page.content.filter(q => q.id !== this.currentQuizId);
      console.log("Quizzes=>", this.quizzes);
      this.filteredQuiz = [...this.quizzes]; 
      this.loading = false;
      this.extractTechnologies(); 
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditQuizPage(quiz: QuizData) {
    console.log(quiz.id);

    this.router.navigate([
      'course-composer',
      'quiz-creator',
      'edit-quiz',
      quiz.id,
    ]);
  }

  deleteQuiz(id: number) {
    this.modal
      .confirm('Are you sure you want to delete this quiz?', {
        title: 'Delete quiz',
        type: 'danger',
        okButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      })
      .subscribe({
        next: (result) => {
          if (result) {
            this.quizService.deleteQuiz(id).subscribe({
              next: () => {
                this.quizzes = this.quizzes.filter((quiz) => quiz.id !== id);
                this.filterQuizzes();
              },
              error: (err) => {
                if (err.status === 400) {
                  this.modal.alert({
                    type: 'danger',
                    title: 'Unable to Delete quiz',
                    content: err.error.message,
                    okButtonText: 'Ok',
                  });
                }
              },
            });
          }
        },
      });
  }

  extractTechnologies() {
    this.technologies = Array.from(new Set(
      this.quizzes.flatMap(quiz => 
        quiz.questions.flatMap(question => 
          question.questionData.technologies || [] 
        )
      )
    ));
    console.log('Extracted Technologies:', this.technologies);
  }
  
  openFilterModal() {
    this.showFilterModal = true;
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal; 
  }

  resetFilter() {
    this.selectedTechnology = ''; 
    this.searchQuery = ''; 
    this.filteredQuiz = [...this.quizzes]; 
    this.hasFilter = false; 
  }

  filterQuizzes() {
    this.hasFilter = this.selectedTechnology !== '' || this.searchQuery !== ''; 
    const query = this.searchQuery.toLowerCase();
    this.filteredQuiz = this.quizzes.filter(quiz => {
      const technologyMatches = this.selectedTechnology === '' || 
        quiz.questions.some(question => 
          question.questionData.technologies?.map(t => t.toLowerCase()).includes(this.selectedTechnology.toLowerCase())
        );
      const titleMatches = !query || quiz.title.toLowerCase().includes(query);
      return technologyMatches && titleMatches;
    });
    console.log('Filtered Quizzes:', this.filteredQuiz); 
  }

  applyFilter() {
    this.filterQuizzes(); 
    this.toggleFilterModal(); 
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage; 
    this.loadQuizzes(); 
  }
}
