import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '@app/api/user/services/user.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { FileRecord, FileTree } from '@core/components/file-explorer/file-record.model';
import { FilesApiService } from '@core/services/files-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.sass']
})
export class FileExplorerComponent implements OnInit {

  @ViewChild('newFolderModal') newFolderModal!: TemplateRef<any>;
  @ViewChild('uploadFileModal') uploadFileModal!: TemplateRef<any>;

  @Input() startPath = '';
  @Input() showNavigation = true;
  @Input() set userEmail(email: string) {
    this.startPath = `users/${email}`;
  }
  @Input() fileTypeFilter?: string[];
  @Input() uploadAcceptedExtensions = '*';
  path = '';
  @Output() fileSelected = new EventEmitter<FileRecord>();
  selectedFile?: FileRecord;
  fileTree?: FileTree;
  pathArray: { path: string, folderName: string }[] = [];
  loading = false;
  newFolderName = '';
  uploadedFiles: File[] = [];
  uploading = false;
  overwriteFile = false;
  publicAccessFile = true;
  newFileName = '';
  currentUserRole: string = '';

  constructor(
    private filesApi: FilesApiService,
    private modalService: NgbModal,
    private userService: UserService,
    private globalModalService: GlobalModalService
  ) {}

  loadPath(path: string): void {
    this.path = path;
    this.loading = true;
    this.filesApi.getFileTree(path, true)
      .pipe(map(fileTree => {
        if (this.fileTypeFilter) {
          fileTree.files = fileTree.files.filter(file => this.fileTypeFilter?.includes(file.contentType));
        }
        return fileTree;
      }))
      .subscribe({
        next: (fileTree) => {
          this.fileTree = fileTree;
          this.pathArray = [];
          const pathParts = path.replace(this.startPath, '').split('/');
          pathParts.forEach((pathPart, index) => {
            if (pathPart) {
              this.pathArray.push({ path: pathParts.slice(0, index + 1).join('/'), folderName: pathPart });
            }
            this.loading = false;
          });
        }
      });
  }

  get currentRelativePath(): string {
    const currentRelativePath = this.pathArray
      .map((pathPart) => pathPart.folderName)
      .join('/');

    if (this.currentUserRole === 'Admin' || this.currentUserRole === 'Super Admin') {
      return currentRelativePath.substring(
        currentRelativePath.indexOf('/') + 1
      );
    } else {
      return this.pathArray.map((pathPart) => pathPart.folderName).join('/');
    }
  }

  selectFolder(folderName: string) {
    this.path = this.path + '/' + folderName;
    this.loadPath(this.path);
  }

  previousDirectory(): void {
    const pathParts = this.path.split('/');
    pathParts.pop();
    this.path = pathParts.join('/');
    this.loadPath(this.path);
  }

  get hasPreviousDirectory(): boolean {
    // Compare to startPath
    return this.path !== this.startPath;
  }

  selectFile(file: FileRecord): void {
    this.selectedFile = file;
  }

  selectFileAndEmit(file: FileRecord): void {
    this.selectedFile = file;
    this.fileSelected.emit(file);
  }

  ngOnInit(): void {
    this.path = this.startPath;

    this.userService.getSelfRole().subscribe({
      next: (res) => {
        this.currentUserRole = res;
      },
    });

    if (this.currentUserRole === 'Admin' || this.currentUserRole === 'Super Admin') {
      this.startPath = 'users';
      this.path = 'users';
    } else {
      this.path = this.startPath;
    }

    this.loadPath(this.path);
  }

  getIconFromFileType(file: FileRecord): string {
    const { contentType } = file;

    switch (contentType) {
      case 'application/pdf':
        return 'bi-file-earmark-pdf';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'bi-file-earmark-word';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'bi-file-earmark-spreadsheet';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'bi-file-earmark-presentation';
      case 'application/zip':
        return 'bi-file-earmark-zip';
      case 'application/vnd.ms-powerpoint':
        return 'bi-file-earmark-ppt';
      case 'application/vnd.ms-excel':
        return 'bi-file-earmark-spreadsheet';
      case 'application/vnd.ms-word':
        return 'bi-file-earmark-word';
      case 'image/png':
      case 'image/jpeg':
      case 'image/gif':
      case 'image/bmp':
      case 'image/webp':
        return 'bi-file-earmark-image';
      case 'video/mp4':
      case 'video/mpeg':
      case 'video/ogg':
      case 'video/webm':
      case 'video/3gpp':
        return 'bi-file-earmark-play';
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/wav':
      case 'audio/webm':
        return 'bi-file-earmark-music';
      case 'text/plain':
        return 'bi-file-earmark-text';
      case 'text/html':
      case 'text/css':
      case 'text/javascript':
      case 'application/json':
      case 'application/xml':
      case 'text/markdown':
        return 'bi-file-earmark-code';
      default:
        return 'bi-file-earmark';
    }
  }

  createNewFolder(): void {
    this.fileTree?.folders.push(this.newFolderName);
    this.newFolderName = '';
  }

  openNewFolderModal(): void {
    this.newFolderName = '';
    this.modalService.open(this.newFolderModal);
  }

  uploadFile(modal?: any): void {
    if (this.uploadedFiles[0]) {
      this.uploading = true;
      this.filesApi.uploadFile('self',
        this.uploadedFiles[0],
        this.currentRelativePath,
        this.overwriteFile,
        this.publicAccessFile, this.newFileName).subscribe({
        next: () => {
          this.uploading = false;
          this.uploadedFiles = [];
          this.loadPath(this.path);
          if (modal) {
            modal.dismiss();
          }
        },
        error: () =>
        {
          this.uploading = false;
          if (modal) {
            modal.dismiss();
          }
          this.globalModalService
            .alert({
              title: 'Upload File',
              content: 'Failed to upload file',
              type: 'danger',
              okButtonText: 'Ok',
            })
        }
      });
    }
  }

  openUploadFileModal(): void {
    this.modalService.open(this.uploadFileModal, {
      backdrop: 'static'
    });
  }

  changeNewFileName(files: File[]): void {
    if (files[0]) {
      this.newFileName = files[0].name;
    }
  }

  deleteFile(file: FileRecord): void {
    this.filesApi.deleteFile(file.id).subscribe({
      next: () => {
        this.loadPath(this.path);
      }
    });
  }

  handleFileRightClick(event: MouseEvent, fileRecord: FileRecord): void {
    event.preventDefault();
  }


}
