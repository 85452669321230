import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { QuestionFormService } from '@app/api/question/services/question-form.service';
import {
  getQuestionCategoryLabel,
  QuestionCategories,
  QuestionCategory,
  QuestionDifficulties,
  QuestionDifficulty,
  QuestionType,
  QuestionTypes
} from '@app/api/question/models/question-data-types';
import { FormArray, FormGroup } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { TagData, TagDataFn } from '@core/components/forms/tags-field/tag-data.model';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { CategorizationService } from '@app/api/categorization/services/categorization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { KeyBindingService } from '@core/services/key-binding.service';
import { CreateQuestionRequest } from '@app/api/question/models/create-question-request.model';
import { GlobalToastService } from '@app/core/services/global-toast.service';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.sass']
})
export class QuestionFormComponent implements OnInit, OnDestroy {

  currentStep = 1;
  updateMode = false;
  questionId?: number;
  @ViewChild('savingModal')
    savingModal!: TemplateRef<any>;
  correctAnswerId?: number;
  collapseHowToFitb = true;
  smoothstackClientOptions: {
    label: string,
    value: number
  } [] = [];
  questionRequest!: CreateQuestionRequest;
  @Input() questionData: QuestionData = {
    title: '',
    description: '',
    text: '',
    difficulty: 'EASY',
    type: 'MULTIPLE_CHOICE',
    categories: [],
    tags: [],
    published: false
  };
  @Output() questionDataChange = new EventEmitter<QuestionData>();
  @Input() set createQuestionRequest(request: CreateQuestionRequest | undefined) {
    this.questionFormService.setCreateQuestionRequest(request);
  }
  @Input() type = '';

  @Output() createQuestionRequestChange = new EventEmitter<CreateQuestionRequest>();
  @Output() questionCreated = new EventEmitter<CreateQuestionRequest>();

  @Input() allowSave = true;

  set saving(value: boolean) {
    this.questionFormService.saving = value;
  }

  get saving() {
    return this.questionFormService.saving;
  }

  constructor(private questionFormService: QuestionFormService,
    private categorization: CategorizationService,
    private toastService: GlobalToastService,
    private route: ActivatedRoute,
    private router: Router,
    private keybinding: KeyBindingService,
    private modalService: NgbModal,
    private questionApi: QuestionApiService) {
    this.keybinding.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      if (!this.saving && this.allowSave) {
        this.saveQuestion(false, this.updateMode);
      }
    });
  }

  ngOnInit(): void {
    this.saving = false;

    if (this.route.snapshot.url.length == 1) {
      this.questionFormService.setCreateMode();
      this.updateMode = false;
    }

    if (this.route.snapshot.url.length === 2) {
      const [view, id] = this.route.snapshot.url;
      if (view.path !== 'edit') {
        return;
      }

      this.questionId = +(id.path);

      this.questionApi.getQuestionDataForUpdate(this.questionId).subscribe({
        next: (questionData) => {
          this.questionFormService.setUpdateMode(questionData);
          this.correctAnswerId = questionData.correctAnswer?.id;
          this.questionData.published = questionData.published || false;
          this.updateMode = true;
        },
        error: () => {
          this.router.navigate(['../../create'], { relativeTo: this.route });
          this.questionFormService.setCreateMode();
          this.updateMode = false;
        }
      });
    } else {
      // Get question type from query param (type)
      const questionType = this.route.snapshot.queryParamMap.get('type');
      if (questionType) {
        this.questionFormService.setQuestionType(questionType as QuestionType);
      }
    }

    this.questionFormService.formGroup.valueChanges.subscribe({
      next: (value) => {
        this.createQuestionRequestChange.emit(value);
      }
    });

    this.categorization.smoothstackClientTags.subscribe({
      next: (clients) => {
        this.smoothstackClientOptions = clients.map((client) => {
          return {
            label: client.name,
            value: client.id as number
          };
        });
      }
    });
  }

  get formGroup() {
    return this.questionFormService.formGroup;
  }

  get questionType(): QuestionType {
    return this.formGroup.get('questionType')?.value;
  }

  get questionTypes(): {
    value: QuestionType,
    label: string
  }[] {
    return [
      {
        value: QuestionTypes.MULTIPLE_CHOICE,
        label: 'Multiple Choice'
      },
      {
        value: QuestionTypes.SHORT_ANSWER,
        label: 'Short Answer'
      },
      {
        value: QuestionTypes.FILL_IN_THE_BLANK,
        label: 'Fill in the Blank'
      },
      {
        value: QuestionTypes.TRUE_FALSE,
        label: 'True/False'
      },
      {
        value: QuestionTypes.INTERVIEW,
        label: 'Interview'
      }
    ];
  }

  get questionDifficulties(): {
    value: QuestionDifficulty,
    label: string
  }[] {
    return [
      {
        value: QuestionDifficulties.EASY,
        label: 'Easy'
      },
      {
        value: QuestionDifficulties.MEDIUM,
        label: 'Medium'
      },
      {
        value: QuestionDifficulties.HARD,
        label: 'Hard'
      },
      {
        value: QuestionDifficulties.EXPERT,
        label: 'Expert'
      }
    ];
  }

  addChoice() {
    this.questionFormService.addChoice();
  }

  removeChoice(index: number) {
    this.questionFormService.removeChoice(index);
  }

  addExplanation(index: number) {
    this.questionFormService.addExplanationToChoice(index);
  }

  removeExplanation(index: number) {
    this.questionFormService.removeExplanationFromChoice(index);
  }

  get choices() {
    return (this.formGroup.get('choices') as FormArray).controls as FormGroup[];
  }

  stepOrderPerQuestionType(): {
    [key in QuestionType]: string[]
  } {
    return {
      MULTIPLE_CHOICE: [
        'questionDetails',
        'questionText',
        'options',
        'tagsAndCategories'
      ],
      SHORT_ANSWER: [
        'questionDetails',
        'questionText',
        'shortAnswerCorrectAnswer',
        'tagsAndCategories'
      ],
      TRUE_FALSE: [
        'questionDetails',
        'questionText',
        'trueFalseCorrectAnswer',
        'tagsAndCategories'
      ],
      FILL_IN_THE_BLANK: [
        'questionDetails',
        'questionText',
        'tagsAndCategories'
      ],
      INTERVIEW: [
        'questionDetails',
        'interviewQuestionDetails',
        'tagsAndCategories'
      ],
    };
  }

  getStepLength() {
    return this.stepOrderPerQuestionType()[this.questionType].length;
  }

  getStepOrder(stepName: string) {
    if (!this.questionType) {
      return 0;
    }
    return this.stepOrderPerQuestionType()[this.questionType].indexOf(stepName) + 1;
  }

  get STEP_QUESTION_DETAILS() {
    return this.getStepOrder('questionDetails') | 1;
  }

  get STEP_QUESTION_TEXT() {
    return this.getStepOrder('questionText') | 2;
  }

  get STEP_OPTIONS() {
    return this.getStepOrder('options');
  }

  get STEP_TAGS_AND_CATEGORIES() {
    return this.getStepOrder('tagsAndCategories');
  }

  get SAVE_STEP() {
    return this.getStepLength() + 1;
  }

  get STEP_TF_CORRECT_ANSWER() {
    return this.getStepOrder('trueFalseCorrectAnswer');
  }

  get STEP_SA_CORRECT_ANSWER() {
    return this.getStepOrder('shortAnswerCorrectAnswer');
  }

  get STEP_INTERVIEW_QUESTION_DETAILS() {
    return this.getStepOrder('interviewQuestionDetails');
  }

  stepFocus(step: number) {
    this.currentStep = step;
  }

  get tagResults(): TagDataFn {
    return (tagQuery: string): Observable<TagData[]> => {
      return this.questionApi.getQuestionTags(tagQuery)
        .pipe(
          map(page => page.content),
          map(tags => tags.map(
            tag => {
              return {
                label: tag.name,
                value: tag.name
              };
            }
          ))
        );
    };
  }

  get questionCategories(): {
    value: QuestionCategory,
    label: string
  }[] {
    return [
      {
        value: QuestionCategories.TECHNOLOGY,
        label: 'Technology'
      },
      {
        value: QuestionCategories.INTERVIEW,
        label: 'Interview'
      },
      {
        value: QuestionCategories.SOFT_SKILLS,
        label: 'Soft Skills'
      }
    ];
  }

  get categoryResults(): TagDataFn {
    return (tagQuery: string): Observable<TagData[]> => {
      if (tagQuery)
        return of(this.questionCategories.filter(category => category.label.toLowerCase().includes(tagQuery.toLowerCase())));
      return of(this.questionCategories);
    };
  }

  getCategoryContains(category: QuestionCategory) {
    const categories = this.formGroup.get('categories')?.value;
    if (!categories) {
      return false;
    }
    return categories.includes(category);
  }

  get technologyTags(): TagDataFn {
    return (tagQuery: string): Observable<TagData[]> => {
      return this.categorization.getTechnologyTags(tagQuery, 5)
        .pipe(
          map(page => page.content),
          map(tags => tags.map(
            tag => {
              return {
                label: tag.name,
                value: tag.name
              };
            }
          ))
        );
    };
  }

  showSavingModal() {
    this.modalService.open(this.savingModal, {
      centered: true,
      backdrop: 'static',
    });
  }

  closeSavingModal() {
    // Wait 2 seconds: this is to prevent the modal from closing too quickly
    setTimeout(() => {
      this.modalService.dismissAll();
      this.currentStep = 1;
    }, 1500);
  }

  saveQuestion(publish?: boolean, updateMode = false) {

    if (!this.allowSave) {
      return;
    }

    if(publish !== undefined){
      this.questionData.published = publish;
    }
    
    this.saving = true;
    this.stepFocus(this.SAVE_STEP);
    this.showSavingModal();
    // Set form value for publish to publish
    this.formGroup.get('published')?.setValue(publish);

    if (this.questionType === QuestionTypes.MULTIPLE_CHOICE) {
      // If there are multiple correct answers, set the selectAllThatApply to true
      const choices = this.formGroup.get('choices') as FormArray;
      const correctChoices = choices.controls.filter((choice) => {
        return (choice as FormGroup).get('correct')?.value;
      });

      if (correctChoices.length > 1) {
        this.formGroup.get('selectAllThatApply')?.setValue(true);
      } else {
        this.formGroup.get('selectAllThatApply')?.setValue(false);
      }
    }

    // Save question
    if (!updateMode) {
      this.questionApi.createQuestion(this.formGroup.value)
        .subscribe({
          next: (response) => {
            this.saveSuccessful(response.id!, updateMode);
            this.questionRequest = this.formGroup.value;
            this.questionRequest.id = response.id;
            this.questionCreated.emit(this.questionRequest);
          },
          error: () => {
            this.saving = false;
            this.closeSavingModal();
            this.toastService.showDangerToast('Error occurred while creating the question.');
          }
        });
    } else if (this.questionId) {
      const value = this.formGroup.value;
      this.questionApi.updateQuestion(this.questionId, {
        ...value,
        id: this.questionId,
        type: this.questionType,
        text: this.questionType === 'FILL_IN_THE_BLANK' ? 'Fill in the blank.' : value.text,
        fillInTheBlankText: this.questionType === 'FILL_IN_THE_BLANK' ? value.text : null,
        correctAnswer: this.correctAnswerId ? {
          id: this.correctAnswerId,
          correctAnswer: value.correctAnswer,
          explanation: value.explanation
        } : null,
        trueFalseExplanation: value.explanation,
        trueFalseCorrect: value.correctAnswerIsTrue,
      })
        .subscribe({
          next: (response) => {
            this.saveSuccessful(response.id!, updateMode);
          },
          error: () => {
            this.saving = false;
            this.closeSavingModal();
            this.toastService.showDangerToast('Error occurred while updating the question.');
          }
        });
    }
  }

  saveSuccessful(id: number, updateMode: boolean) {
    console.log('Question created successfully');
    if (this.type != 'quiz') {
      this.router
        .navigate(['course-composer', 'question-creator', 'edit', id])
        .then(() => {
          this.closeSavingModal();
          this.toastService.showSuccessToast(updateMode ? 'Question saved successfully!' : 'Question created successfully!');
        this.saving = false;
        });
    } else {
      this.closeSavingModal();
      this.saving = false;
    }
  }

  categoryTagPipe(value: QuestionCategory) {
    const label = getQuestionCategoryLabel(value);
    return {
      label,
      value
    };
  }

  resetForm() {
    this.questionFormService.resetForm();
  }

  ngOnDestroy() {
    this.currentStep = 1;
    this.resetForm();
    this.keybinding.deregisterKeyBind('ctrl+s');
  }

  getAllErrors() {
    const errors: any = {
    };
    const controls = this.formGroup.controls;

    for (const key in controls) {
      if (controls[key].errors) {
        errors[key] = controls[key].errors;
      }
    }

    // If there are no errors, return null
    if (Object.keys(errors).length === 0) {
      return null;
    }

    return errors;
  }

}
