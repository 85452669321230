import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
      <div *ngIf="!centered" class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="centered" class="d-flex justify-content-center align-items-center my-3">
          <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>
  `
})
export class SpinnerComponent {

  @Input() centered = false;

}
