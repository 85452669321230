<div class="container-fluid">
  <div *ngFor="let post of filteredPosts">
    <div *ngIf="post.parentPostId === null" class="card my-3" (click)="fetchPostById(post.id)"
      [routerLink]="['/discussion/view-discussion', post.id]">
      <div class="card-body" style="cursor: pointer;">
        <div class="row">
          <div class="col-7">
            <h5 class="card-title" class="text-primary" *ngIf="post && post.postTitle">{{ post.postTitle }}</h5>
          </div>
          <div class="col-5">
            <div *ngFor="let tag of post.technologyTags" style="pointer-events: none;" class="btn btn-secondary btn-sm"
              style="margin-left: 5px; margin-right: 5px; background-color: lightgray !important; border-color: lightgrey !important; color: #2e2e2e !important;">
              <i class="bi bi-tag"></i> {{ tag.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-{{ loginUserRole === 'Admin' ? '8' : '9' }}" style="margin-top: 10px;">
            <p class="card-text">{{ post.content }}</p>
          </div>
          <div class="col-{{ loginUserRole === 'Admin' ? '2' : '3' }} text-end"
            style="margin-right: 0; padding-right: 0; margin-top: 10px;">
            <small>Posted : {{ post.createdDate | date: 'dd MMM, yyyy' }}</small>
            <br>
            <small>Updated : {{ post.updatedDate | date: 'dd MMM, yyyy' }}</small>
            <br>
            <small>Comments: {{ post.commentCount }}</small>
          </div>
          <div *ngIf="loginUserRole === 'Admin'" class="col-2 text-end" style="margin-top: 10px;">
            <button class="btn btn-primary btn-sm me-1" (click)="flagThread(post.id); $event.stopPropagation()"
              [disabled]="post.isFlagged" [ngClass]="{'disabled-button': post.isFlagged}">
              <i class="bi bi-flag"></i> Flag Thread
            </button>
            <button class="btn btn-primary btn-sm me-1" style="margin-top: 10px;"
              (click)="setAlert(post.id); $event.stopPropagation()" [disabled]="post.isAlert"
              [ngClass]="{'disabled-button': isThreadAlert}">
              <i class="bi bi-bell"></i> Set Alert
            </button>
            <button class="btn btn-danger btn-sm" style="margin-top: 10px;"
              (click)="onDeleteThread(post.id); $event.stopPropagation()">
              <i class="bi bi-trash"></i> Delete Thread
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <div class="pagination">
      <span class="bi bi-chevron-left" style="margin-top: 10px; padding-right: 10px; cursor: pointer;"
        *ngIf="pagination.page > 0" (click)="goToPage(pagination.page - 1)"></span>
      <button class="btn btn-primary active">{{ pagination.page + 1 }}</button>
      <span class="bi bi-chevron-right" style="margin-top: 10px; padding-left: 10px; cursor: pointer;"
        *ngIf="pagination.page < pagination.totalPages - 1" (click)="goToPage(pagination.page + 1)"></span>
    </div>
  </div>
</div>
