import { Routes } from '@angular/router';
import { DashboardPageComponent } from '@pages/dashboard-page/dashboard-page.component';
import { TrainingPageComponent } from '@pages/training-page/training-page.component';
import { MyLearningPageComponent } from '@pages/my-learning-page/my-learning-page.component';
import { MyCapstonePageComponent } from '@pages/my-capstone-page/my-capstone-page.component';
import { roleGuard } from '@auth/guards/role.guard';
import { UserRole } from '@app/api/user/models/user.model';
import { CapstoneProjectsPageComponent } from '@pages/capstone-projects-page/capstone-projects-page.component';
import { CourseComposerPageComponent } from '@pages/course-composer-page/course-composer-page.component';
import { FileManagerPageComponent } from '@pages/file-manager-page/file-manager-page.component';
import { LibraryPageComponent } from '@pages/library-page/library-page.component';
import { VideosPageComponent } from '@pages/videos-page/videos-page.component';
import { WatchPageComponent } from '@pages/videos-page/watch-page/watch-page.component';
import { UserManagementPageComponent } from '@pages/user-management-page/user-management-page.component';
import { SettingsPageComponent } from '@pages/settings-page/settings-page.component';
import { UserManagementRoutes } from '@app/routes/user-management.routes';
import { CourseComposerRoutes } from '@app/routes/course-composer.routes';
import {
  AssignmentComposerComponent
} from '@app/api/assignment/components/assignment-composer/assignment-composer.component';
import { canComponentDeactivateGuard } from '@core/guards/can-component-deactivate.guard';
import { ContentLibraryRoutes } from '@app/routes/content-library.routes';
import { LearningActivityPageComponent } from '@pages/learning-activity-page/learning-activity-page.component';
import {
  LearningActivityViewPanelComponent
} from '@app/api/learning/components/learning-activity-view-panel/learning-activity-view-panel.component';
import { TrainingResourcesRoutes } from '@app/routes/training-resources.routes';
import { LandingPageComponent } from '@app/discussion/components/landing-page/landing-page.component';
import { DiscussionRoutes } from './discussion.routes';
import {
  ManageActivitiesPageComponent
} from '@pages/learning-page/manage-activities-page/manage-activities-page.component';
import { ProfilePageComponent } from '@app/pages/profile-page/profile-page.component';
import { NotificationsPageComponent } from '@pages/notifications-page/notifications-page.component';
import {
  NotificationViewComponent
} from '@app/api/notifications/components/notification-view/notification-view.component';
import { VideoCatalogPageComponent } from '@app/pages/videos-page/video-catalog-page/video-catalog-page.component';
import {
  VideoUpdatePageComponent
} from '@pages/videos-page/video-update-page/video-update-page.component';

const learningActivityRoutes: Routes = [
  {
    path: 'learning-activity/:id',
    data: { title: 'Learning Activity' },
    component: LearningActivityPageComponent,
    children: [
      {
        path: '',
        data: { title: 'Learning Activity', type: 'ANY' },
        component: LearningActivityViewPanelComponent
      },
      {
        path: 'lesson/:id',
        data: { title: 'Lesson', showNav: false, type: 'LESSON' },
        component: LearningActivityViewPanelComponent
      },
      {
        path: 'assignment/:id',
        data: { title: 'Assignment', type: 'ASSIGNMENT' },
        component: LearningActivityViewPanelComponent
      }
    ]
  },
];

export const AuthenticatedRoutes: Routes = [
  {
    path: 'dashboard',
    data: { title: 'Dashboard' },
    component: DashboardPageComponent
  },
  {
    path: 'training',
    data: { title: 'Training' },
    component: TrainingPageComponent,
    canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    canActivateChild: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    children: TrainingResourcesRoutes
  },
  {
    path: 'my-learning',
    data: { title: 'My Learning' },
    component: MyLearningPageComponent,
  },
  {
    path: 'discussion',
    data: { title: 'Forum' },
    component: LandingPageComponent,
    canActivate: [roleGuard([UserRole.Admin])],
    canActivateChild: [roleGuard([UserRole.Admin])],
    children: DiscussionRoutes
  },
  {
    path: 'my-capstone',
    data: { title: 'Capstone Project' },
    component: MyCapstonePageComponent,
    canActivate: [roleGuard([UserRole.Student])],
  },
  {
    path: 'capstone-projects',
    data: { title: 'Capstone Projects' },
    component: CapstoneProjectsPageComponent
  },
  {
    path: 'course-composer',
    data: { title: 'Course Composer' },
    component: CourseComposerPageComponent,
    canActivate: [roleGuard([UserRole.Admin,UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    canActivateChild: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    children: CourseComposerRoutes
  },
  {
    path: 'file-manager',
    data: { title: 'File Manager' },
    component: FileManagerPageComponent
  },
  {
    path: 'content-library',
    data: { title: 'Content Library' },
    component: LibraryPageComponent,
    canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    canActivateChild: [roleGuard([UserRole.Admin,UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    children: ContentLibraryRoutes
  },
  {
    path: 'videos',
    data: { title: 'Videos' },
    component: VideosPageComponent,
    children: [
      {
        path: '',
        data: { title: 'Video Catalog' },
        component: VideoCatalogPageComponent
      },
      {
        path: 'watch/:id',
        data: { title: 'Watch Video' },
        component: WatchPageComponent
      },
      {
        path: 'edit/:id',
        data: { title: 'Update Video' },
        component: VideoUpdatePageComponent,
        canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
      },


    ]
  },
  {
    path: 'user-management',
    data: { title: 'User Management' },
    component: UserManagementPageComponent,
    canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin])],
    canActivateChild: [roleGuard([UserRole.Admin, UserRole.Super_Admin])],
    children: UserManagementRoutes
  },
  {
    path: 'assignment-composer',
    data: { title: 'Assignment Composer', action: 'new' },
    component: AssignmentComposerComponent,
    canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    canDeactivate: [canComponentDeactivateGuard]
  },
  {
    path: 'notifications',
    data: { title: 'Notifications' },
    component: NotificationsPageComponent,
    children: [
      {
        path: ':id',
        data: { title: 'Notification' },
        component: NotificationViewComponent
      }
    ]
  },
  {
    path: 'assignment-composer/:id',
    data: { title: 'Assignment Composer', action: 'edit' },
    component: AssignmentComposerComponent,
    canActivate: [roleGuard([UserRole.Admin, UserRole.Super_Admin, UserRole.Trainer, UserRole.Training_Assistant])],
    canDeactivate: [canComponentDeactivateGuard]
  },
  ...learningActivityRoutes,
  {
    path: 'learning/manage-activities',
    data: { title: 'Manage Activities' },
    component: ManageActivitiesPageComponent
  },
  {
    path: 'settings',
    data: { title: 'Settings' },
    component: SettingsPageComponent
  },
  {
    path: 'profile',
    data: { title: 'Profile' },
    component: ProfilePageComponent,
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
];

