<div class="container">
    <div class="my-3">
      <h3 class="h3">Edit Student</h3>
      <p class="text-muted">Edit an existing student's information.</p>
    </div>
    <div class="pb-5">
      <app-student-edit-form [student]="student"
                             (deleteStudent)="deleteStudent($event)"
                             (saveChanges)="saveStudent($event)"></app-student-edit-form>
    </div>
  </div>
