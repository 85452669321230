import { Component } from '@angular/core';

@Component({
  selector: 'app-lesson-activity-page',
  templateUrl: './lesson-activity-page.component.html',
  styleUrls: ['./lesson-activity-page.component.sass']
})
export class LessonActivityPageComponent {

}
