import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor() { }

  exportJson(json: any, fileName: string, formatted = true) {
    const blob = new Blob([
      JSON.stringify(
        json,
        (_, value) => {
          if (value === null) {
            return undefined;
          }
          return value;
        },
        formatted ? 2 : 0)
    ], {
      type: 'application/json'
    });
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(blobUrl);
  }

  triggerDownload(blob: Blob, fileName: string) {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(blobUrl);
  }

}
