<div *ngIf="showFrame; else markdownElement" class="border rounded my-8">
  <div class="border-bottom d-flex align-items-center py-2">
    <div class="mx-2 pb-2 flex-grow-1">
      <span class="bi bi-markdown"></span> {{frameTitle}}
    </div>
    <div *ngIf="showExport" ngbDropdown class="m-2">
      <button type="button"
              ngbDropdownToggle
              class="btn btn-sm btn-outline-primary border-0">
        <span class="bi bi-three-dots-vertical"></span>
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem>
          Export as PDF
        </button>
        <button ngbDropdownItem>
          Export as HTML
        </button>
        <button ngbDropdownItem>
          Download markdown
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid py-2">
    <p *ngIf="!data; else markdownElement" class="text-muted">Preview markdown here...</p>
  </div>
</div>

<script src="https://unpkg.com/x-frame-bypass" type="module"></script>

<ng-template #markdownElement>
  <div class="d-flex justify-content-between align-items-start mb-3 position-relative">
    
    <div *ngIf="enableSmoothScroll" class="position-absolute top-0 end-0 p-2">
      <button class="btn btn-primary btn-sm"
              (click)="toggleAnchorLinks()">
        {{ showAnchorLinks ? 'Hide Outline' : 'Show Outline' }}
      </button>
    </div>

    <div class="offcanvas offcanvas-start" [ngClass]="{'show': showAnchorLinks}" tabindex="-1" id="offcanvasLinks" aria-labelledby="offcanvasLinksLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasLinksLabel">Outline</h5>
        <button type="button" class="btn-close" (click)="closeOffCanvas()" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="list-unstyled">
          <li *ngFor="let section of objectKeys(sectionHeaders)">
               <div>
                <a class="text-decoration-none scroll-links" [href]="currentUrl + '#' + section">{{ sectionHeaders[section].text }}</a>
                <div *ngIf="sectionHeaders[section].subsections">
                  <div *ngIf="sectionHeaders[section].subsections && sectionHeaders[section].subsections!.length > 0; else noSubsections">
                    <ul>
                      <li *ngFor="let subsection of sectionHeaders[section].subsections">
                        <a class="text-decoration-none scroll-links" [href]="currentUrl + '#' + subsection.id">{{ subsection.text }}</a>
                      </li>
                    </ul>
                  </div>
                  <ng-template #noSubsections>
                    <p>No subsections available.</p>
                  </ng-template>
                </div>
              </div>
              
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="content" [ngClass]="{'offcanvas-content': showAnchorLinks}">
    <markdown [data]="data" class="markdown-viewer"></markdown>
  </div>


</ng-template>
