<div *ngIf="moduleData" class="my-2">

  <div class="mb-3">
    <h4>Module Details</h4>
    <p class="text-muted small">A module is a comprehensive collection of lessons to teach a larger topic.</p>
  </div>

  <div class="form-floating my-2">
    <input [(ngModel)]="moduleData.title" class="form-control" id="module-title" placeholder="Module Title" type="text">
    <label class="form-label" for="module-title">Module Title</label>
  </div>

  <div class="form-floating my-2">
    <textarea (input)="onDescriptionInput()" [(ngModel)]="moduleData.description"
              [formControl]="moduleDescriptionControl" class="form-control"
              id="module-description" placeholder="Module Description"
              style="height: 100px"></textarea>
    <label for="module-description">Module Description</label>
    <div class="d-flex justify-content-between align-items-center">
      <app-control-validation-message [control]="moduleDescriptionControl"></app-control-validation-message>
      <span class="flex-grow-3"></span>
      <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
        {{ moduleDescriptionControl.value.length }}/{{ maxDescriptionLength }}
      </span>
    </div>
  </div>

  <div class="my-3">

    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1">
        <h4>Lessons</h4>
        <p class="text-muted small">Add lessons to this module in the order it is to be presented.</p>
      </div>
      <div *ngIf="moduleData.lessons && moduleData.lessons.length"
           class="text-end d-flex align-items-center justify-content-center">
        <button (click)="openAddMaterialModal('LESSON')" class="btn btn-primary mx-2"><span
          class="bi bi-bookmark-plus"></span>
          Add Lesson
        </button>
        <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
        <button (click)="createLesson()" *ngIf="disableCreateLesson" class="btn btn-primary mx-2" type="button">
          <span class="bi bi-file-earmark-plus"></span>
          Create Lesson
        </button>
      </div>
    </div>

    <div class="border rounded my-2 bg-light">
      <ul (cdkDropListDropped)="onMoveItem($event)"
          *ngIf="moduleData.lessons && moduleData.lessons.length; else noLessons"
          [cdkDropListData]="moduleData.lessons"
          cdkDropList class="list-group list-group-flush rounded">
        <li *ngFor="let lesson of orderedLessons" [cdkDragPreviewContainer]="previewContainer" cdkDrag
            class="list-group-item list-group-item-action px-0 bg-white lesson-item">
          <div class="d-flex align-items-center">
            <button cdkDragHandle class="drag-drop-handle" type="button">
              <app-icon class="svg-muted" iconName="six-dots"></app-icon>
            </button>
            <div class="flex-shrink-1 px-3">
              <span class="badge bg-primary rounded-circle">{{ lesson.order + 1 }}</span>
            </div>
            <div class="me-2 w-100">
              <div class="mb-2">
                <div class="fw-bold">{{ lesson.title }}</div>
                <div class="d-flex gap-1 flex-wrap">
                  <span *ngFor="let technology of lesson.technologies"
                        class="badge bg-info rounded-pill">{{ technology }}</span>
                </div>
              </div>
              <div class="text-muted small text-wrap">
                {{ lesson.description }}
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-1 w-100 pe-3 flex-shrink-1">
              <button (click)="openEditLessonPage(lesson)" class="btn btn-sm btn-outline-primary">
                <span class="bi bi-pencil"></span>
                <span class="visually-hidden">Edit Lesson</span>
              </button>
              <button (click)="openPreviewModal(lesson)" class="btn btn-sm btn-outline-primary">
                <span class="bi bi-eye"></span>
                <span class="visually-hidden">Preview Lesson</span>
              </button>
              <button (click)="onRemoveMaterial(lesson)" class="btn btn-sm btn-outline-danger">
                <span class="bi bi-trash"></span>
                <span class="visually-hidden">Remove from Module</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #noLessons>
        <div class="py-5 text-center">
          No lessons have been added to this module yet.
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <button (click)="openAddMaterialModal('LESSON')" class="btn btn-primary mx-2">
              <span class="bi bi-bookmark-plus"></span> Add Lesson
            </button>
            <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
            <button (click)="createLesson()" *ngIf="disableCreateLesson" class="btn btn-primary mx-2" type="button">
              <span class="bi bi-file-earmark-plus"></span>
              Create Lesson
            </button>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="my-5"></div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1">
        <h4>Quizzes</h4>
        <p class="text-muted small">Add quiz to this module in the order it is to be presented.</p>
      </div>
      <div *ngIf="moduleData.quizzes && moduleData.quizzes.length"
           class="text-end d-flex align-items-center justify-content-center">
        <button (click)="openAddMaterialModal('QUIZ')" class="btn btn-primary mx-2"><span
          class="bi bi-bookmark-plus"></span>
          Add Quiz
        </button>
        <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
        <button *ngIf="disableCreateLesson" class="btn btn-primary mx-2" type="button">
          <span class="bi bi-file-earmark-plus"></span>
          Create Quiz
        </button>
      </div>
    </div>
    <div class="border rounded my-2 bg-light">
      <ul (cdkDropListDropped)="onMoveItem($event)"
          *ngIf="moduleData.quizzes && moduleData.quizzes.length; else noQuizzes"
          [cdkDropListData]="moduleData.lessons"
          cdkDropList class="list-group list-group-flush rounded">
        <li *ngFor="let quiz of orderedQuizzes" [cdkDragPreviewContainer]="previewContainer" cdkDrag
            class="list-group-item list-group-item-action px-0 bg-white lesson-item">
          <div class="d-flex align-items-center">
            <button cdkDragHandle class="drag-drop-handle" type="button">
              <app-icon class="svg-muted" iconName="six-dots"></app-icon>
            </button>
            <div class="flex-shrink-1 px-3">
              <span class="badge bg-primary rounded-circle">{{ quiz.order + 1 }}</span>
            </div>
            <div class="me-2 w-100">
              <div class="mb-2">
                <div class="fw-bold">{{ quiz.title }}</div>
                <div class="d-flex gap-1 flex-wrap">
                  <span *ngFor="let technology of quiz.technologies"
                        class="badge bg-info rounded-pill">{{ technology }}</span>
                </div>
              </div>
              <div class="text-muted small text-wrap">
                {{ quiz.description }}
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-1 w-100 pe-3 flex-shrink-1">
              <button class="btn btn-sm btn-outline-primary">
                <span class="bi bi-pencil"></span>
                <span class="visually-hidden">Edit Quiz</span>
              </button>
              <button (click)="openPreviewModal(quiz)" class="btn btn-sm btn-outline-primary">
                <span class="bi bi-eye"></span>
                <span class="visually-hidden">Preview Quiz</span>
              </button>
              <button (click)="onRemoveMaterial(quiz)" class="btn btn-sm btn-outline-danger">
                <span class="bi bi-trash"></span>
                <span class="visually-hidden">Remove from Module</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #noLessons>
        <div class="py-5 text-center">
          No lessons have been added to this module yet.
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <button (click)="openAddMaterialModal('LESSON')" class="btn btn-primary mx-2">
              <span class="bi bi-bookmark-plus"></span> Add Lesson
            </button>
            <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
            <button (click)="createLesson()" *ngIf="disableCreateLesson" class="btn btn-primary mx-2" type="button">
              <span class="bi bi-file-earmark-plus"></span>
              Create Lesson
            </button>
          </div>
        </div>
      </ng-template>

      <ng-template #noQuizzes>
        <div class="py-5 text-center">
          No quiz have been added to this module yet.
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <button (click)="openAddMaterialModal('QUIZ')" class="btn btn-primary mx-2">
              <span class="bi bi-bookmark-plus"></span> Add Quiz
            </button>
            <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
            <button (click)="createLesson()" *ngIf="disableCreateLesson" class="btn btn-primary mx-2" type="button">
              <span class="bi bi-file-earmark-plus"></span>
              Create Quiz
            </button>
          </div>
        </div>
      </ng-template>
    </div>

  </div>

</div>

<ng-template #addLessonModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add {{ materialType | titlecase }} to Module</h5>
    <button (click)="modal.close()" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">
    <app-learning-material-selector
      (selectMaterial)="onSelectLesson($event)"
      [materialFilter]="alreadySelectedFilter"
      [materialType]="materialType"
      [publishedOnly]="true">
    </app-learning-material-selector>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close()" class="btn btn-secondary" type="button">Cancel</button>
    <button (click)="addLesson(); modal.close()" class="btn btn-primary" type="button">
      Add {{ materialType | titlecase }}
    </button>
  </div>
</ng-template>

<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Preview {{ materialType|titlecase }}</h5>
    <button (click)="modal.close()" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">

    <app-lesson-preview
      *ngIf="previewLessonData && materialType === 'LESSON'"
      [lessonData]="previewLessonData!">
    </app-lesson-preview>

    <app-quiz-preview
      *ngIf="previewQuizData && materialType === 'QUIZ'"
      [data]="previewQuizData!">
    </app-quiz-preview>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close()" class="btn btn-secondary" type="button">Close</button>
  </div>
</ng-template>
