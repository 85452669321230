import { Component, TemplateRef } from '@angular/core';
import { GlobalToastService, ToastData } from '@core/services/global-toast.service';

@Component({
  selector: 'app-global-toast',
  templateUrl: './global-toast.component.html',
  styleUrls: ['./global-toast.component.sass']
})
export class GlobalToastComponent {

  constructor(public toastService: GlobalToastService) {
  }

  isToastTemplate(toast: ToastData) {
    return toast.content instanceof TemplateRef;
  }

  isToastString(toast: ToastData) {
    return typeof toast.content === 'string';
  }

  toastAsTemplate(toast: ToastData) {
    return toast.content as TemplateRef<any>;
  }

}
