<div class="my-2 border-bottom pb-3">
  <form (ngSubmit)="initiateSearch()">
    <div class="input-group">
      <input type="text"
             name="search"
             class="form-control"
             placeholder="Search"
             [(ngModel)]="contentParams.search"
             (ngModelChange)="initiateSearch()">
      <button class="btn btn-light border"
              type="button"
              (click)="hideTechnologiesFilter = !hideTechnologiesFilter">
        <span class="bi bi-filter"></span>
      </button>
    </div>
    <div [ngbCollapse]="hideTechnologiesFilter">
      <div class="my-3">
        <app-technology-tags-field placeholder="Filter by technology"
                                   name="technologies"
                                   [(ngModel)]="contentParams.technologies"
                                   (ngModelChange)="initiateSearch()"></app-technology-tags-field>
      </div>
    </div>
  </form>
</div>

<div class="list-group list-group-flush">
  <ng-container *ngIf="!loading; else spinnerListItem">
    <button (click)="onSelectMaterial(assignment)"
            *ngFor="let assignment of materials"
            [class.bg-primary-subtle]="assignment.id === selectedMaterialData?.id"
            class="list-group-item list-group-item-action">
      <div class="d-flex w-100 align-items-center">
        <div class="me-3 my-2 align-self-start">
          <span class="bi bi-file-earmark-richtext text-primary display-6"></span>
        </div>
        <div class="flex-grow-1">
          <div class="my-2">
            <div class="fw-bold">{{assignment.title}}</div>
            <div class="d-flex flex-wrap gap-1 align-items-center mb-3">
            <span *ngFor="let technology of assignment.technologies"
                  class="badge small rounded-pill bg-primary">{{technology}}</span>
            </div>
            <p class="mb-3 small">{{assignment.description}}</p>
          </div>
        </div>
      </div>
    </button>
  </ng-container>
  <ng-template #spinnerListItem>
    <div class="list-group-item">
      <div class="text-center my-2">
        <span class="spinner-border text-primary"></span>
      </div>
    </div>
  </ng-template>
</div>
