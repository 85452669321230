import { Component } from '@angular/core';

@Component({
  selector: 'app-modules-page',
  templateUrl: './modules-page.component.html',
  styleUrls: ['./modules-page.component.sass']
})
export class ModulesPageComponent {

}
