<div class="card" [hidden]="imagePreviewUrl && shouldPreviewImage">
  <div class="card-body">
    <div *ngIf="!maxFilesReached" class="my-5">
      <ngx-file-drop [accept]="acceptedExtensions"
                     (onFileDrop)="dropFile($event)"
                     [multiple]="multipleFiles"
                     dropZoneClassName="border-none">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="text-center w-100">
            <div class="fs-1 text-black-50">
              <span class="bi bi-download"></span>
            </div>
            <div class="text-black">{{label}}</div>
            <div class="small">
              <div class="text-muted my-1">or</div>
              <div class="text-center">
                <button type="button"
                        [disabled]="files.length >= maxFiles"
                        (click)="openFileBrowser(openFileSelector)"
                        class="btn btn-sm btn-outline-primary">Browse Files</button>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div *ngIf="files.length" [ngClass]="{ 'border-top mt-5': !maxFilesReached }">
      <div *ngFor="let file of files; let index = index" class="d-flex flex-row py-2">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="bi flex-shrink-1 mx-2" [ngClass]="getIconByFileType(file.type)"></span>
            <div class="flex-grow-1 mx-2 w-100">
              <div class="text-info small">{{file.name}}</div>
              <div class="text-muted small">{{mapLongFileTypeToShort(file.type)}}</div>
            </div>
            <div class="small fst-italic mx-2 text-end w-100">
              {{file.size | fileSize}}
            </div>
          </div>
          <div class="flex-shrink-1 my-2">
            <button type="button"
                    (click)="deleteFile(index)"
                    class="btn btn-sm btn-outline-danger rounded-circle">
              <span class="bi bi-trash"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="border rounded col-12 col-md-10 col-lg-8" [ngClass]="{ 'd-none': !imagePreviewUrl }">
  <div class="border-bottom p-2 d-flex align-items-center justify-content-between small">
    {{imagePreviewFile?.name}}
    <button type="button"
            (click)="deleteFile(0)"
            class="btn btn-outline-danger btn-sm rounded-circle">
      <span class="bi bi-trash"></span>
    </button>
  </div>
  <img #imagePreviewImg
       [src]="imagePreviewUrl"
       class="img-fluid w-100"
       alt=""/>
</div>
<app-control-validation-message [control]="control" [customErrorMessages]="{
  maxFilesReached: 'The maximum amount of file has been reached.',
  invalidFileType: 'Invalid file type.',
  invalidAspectRatio: 'Invalid image size. Please upload a file that is ' + restrictAspectRatio + '.'
}"></app-control-validation-message>
