<form [formGroup]="cohortForm" class="my-3" (submit)="onSubmitCohort()">

  <div class="card card-body my-3">
    <div class="mb-3">
      <h4 class="h4">Cohort Details</h4>
      <p class="text-muted small">Specify the cohort's name, description, as well as start date.</p>
    </div>

    <div class="my-3">
      <div class="input-group">
        <div class="form-floating">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            formControlName="startDate"
            id="startDate"
            ngbDatepicker
            readonly
            (click)="d.toggle()"
            (keydown.enter)="d.toggle()"
            [placement]="datePickerPlacement"
            #d="ngbDatepicker"/>
          <label for="startDate">Start Date</label>
        </div>
        <button class="btn btn-outline-primary bi bi-calendar3" [disabled]="saving" (click)="d.toggle()" type="button">
          <span class="visually-hidden">Toggle Calendar</span>
        </button>
      </div>
    </div>

    <div class="my-3">
      <app-text-field controlName="name" label="Name"></app-text-field>
    </div>

    <div class="my-3">
      <app-text-field controlName="description" label="Description"></app-text-field>
    </div>

    <div class="my-3">
      <app-technology-tags-field formControlName="technologies"></app-technology-tags-field>
    </div>

    <div class="my-3" *ngIf="mode === 'create'">
      <div class="form-switch">
        <input [disabled]="saving" class="form-check-input me-2" type="checkbox" formControlName="createGitlabGroups" id="createGitlabGroups">
        <label class="form-check-label" for="createGitlabGroups">Create GitLab Groups <span class="text-muted small">(Optional)</span></label>
      </div>
    </div>
  </div>

  <div class="card card-body my-3">
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="h4">Students</h4>
        <p class="text-muted small">List the students that will be part of this cohort.</p>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex justify-content-md-end justify-content-center align-items-center mt-2">
          <button type="button" class="btn btn-primary" [disabled]="saving" (click)="openAddNewStudentModal()">
            <span class="bi bi-person-plus"></span>
            New Student
          </button>
          <span class="text-muted flex-shrink-1 mx-3">or</span>
          <button type="button" class="btn btn-primary" [disabled]="saving" (click)="openAddExistingStudentModal()">
            <span class="bi bi-person-lines-fill"></span>
            Existing Student
          </button>
        </div>
      </div>
    </div>

    <div class="my-3" *ngIf="cohortStudents && cohortStudents.length; else noStudents">
      <div class="text-end mb-3">
        <div class="form-switch" ngbTooltip="Send an invitation email to all new users.">
          <input [disabled]="saving" class="form-check-input me-2" type="checkbox" formControlName="inviteAll" id="inviteAll">
          <label class="form-check-label" for="inviteAll">Invite All <span class="bi bi-envelope"></span></label>
        </div>
      </div>
      <div class="list-group">
        <div *ngFor="let student of cohortStudents; let index = index" class="list-group-item">
          <div class="my-2 d-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
              <div class="mb-1">
                {{student.firstName}} {{student.lastName}}
              </div>
              <div class="text-muted small">
                <span class="bi bi-envelope-at"></span>
                {{student.email}}
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <div class="mx-2">
                <div class="text-muted x-small">
                  Invite <span class="bi bi-envelope"></span>
                </div>
                <div class="form-switch">
                  <input class="form-check-input me-2" type="checkbox" [disabled]="saving" [checked]="student.invite"/>
                </div>
              </div>
              <button type="button" class="btn btn-outline-danger btn-sm" [disabled]="saving" (click)="removeStudent(index)">
                <span class="bi bi-trash"></span>
                <span class="visually-hidden">Remove Student</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noStudents>

    <div class="my-3">
      <div class="card card-body">
        <div class="d-flex justify-content-center align-items-center text-center text-muted">
          No students...
        </div>
      </div>
    </div>

  </ng-template>

  <div class="my-3 text-end">
    <button *ngIf="mode === 'edit'" class="btn btn-danger me-2" type="button" [disabled]="saving" (click)="openDeleteCohortConfirm()">
      Delete <span class="bi bi-trash"></span>
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="cohortForm.invalid || saving">
      Save <span [ngClass]="{ 'bi bi-floppy': !saving, 'spinner-border spinner-border-sm': saving }"></span>
    </button>
  </div>

</form>

<ng-template #addExistingStudentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <span class="bi bi-person-lines-fill text-primary"></span>
      Add Existing Students
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-user-select-field placeholder="Search for students..." [(selectedUsers)]="selectedUsers" [userFilter]="studentsOnly"></app-user-select-field>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="addExistingStudents(); modal.close()">Add Students</button>
  </div>
</ng-template>

<ng-template #addNewStudentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <span class="bi bi-person-lines-fill text-primary"></span>
      Add New Students
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body" [formGroup]="newStudentForm">
    <div formArrayName="students">
      <div class="card card-body my-2" *ngFor="let student of studentsFormArray.controls; let i = index" [formGroupName]="i">
        <div class="d-flex">
          <div class="flex-shrink-1 pe-3 pt-2 text-center">
            <span class="fw-bold text-muted">{{i + 1}}.</span>
          </div>
          <div class="flex-grow-1">
            <div class="my-2">
              <div class="form-floating">
                <input class="form-control" formControlName="firstName" id="firstName" placeholder="First Name">
                <label for="firstName">First Name</label>
              </div>
            </div>
            <div class="my-2">
              <div class="form-floating">
                <input class="form-control" formControlName="lastName" id="lastName" placeholder="Last Name">
                <label for="lastName">Last Name</label>
              </div>
            </div>
            <div class="my-2">
              <div class="form-floating">
                <input class="form-control" formControlName="email" id="email" placeholder="Email">
                <label for="email">Email</label>
              </div>
            </div>
          </div>
          <div class="flex-shrink-1 ps-3 pt-2 text-center">
            <button tabindex="-1" type="button" class="btn btn-outline-danger btn-sm" (click)="removeNewStudent(i)">
              <span class="bi bi-trash"></span>
              <span class="visually-hidden">Remove Student</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="my-2">
      <button type="button" class="btn btn-outline-primary w-100" (click)="addNewStudent()">
        <span class="bi bi-person-plus"></span>
        Add Student
      </button>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="newStudentForm.invalid" (click)="addNewStudents(); modal.close()">Add Students</button>
  </div>
</ng-template>
