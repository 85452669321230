import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { DiscussionsService } from '@app/api/discussion/service/discussions.service';
import { UserService } from '@app/api/user/services/user.service';
import { Observable, forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-my-discussions',
  templateUrl: './my-discussions.component.html',
  styleUrls: ['./my-discussions.component.sass']
})
export class MyDiscussionsComponent {
  userBookmarks: Discussion[] = [];
  userComments: Discussion[] = [];
  userEmail: string = '';

  constructor(private discussionsService: DiscussionsService, private userService: UserService) { }

  ngOnInit(): void {
    this.fetchUserEmail();
  }

  fetchUserEmail(): void {
    this.userService.getSelf().subscribe(
      (user) => {
        this.userEmail = user.email;
        this.fetchUserBookmarks();
        this.fetchUserComments();
      },
      (error) => {
        console.error('Error fetching user email:', error);
      }
    );
  }

  fetchUserBookmarks(): void {
    this.discussionsService.getUserBookmarks(this.userEmail).subscribe(
      (bookmarks) => {
        this.userBookmarks = bookmarks;
        this.fetchCommentCounts(this.userBookmarks);
      },
      (error) => {
        console.error('Error fetching user bookmarks:', error);
      }
    );
  }

  fetchUserComments(): void {
    this.discussionsService.getUserComments(this.userEmail).subscribe(
      (comments) => {
        this.userComments = comments;
        this.fetchCommentCounts(this.userComments);
      },
      (error) => {
        console.error('Error fetching user comments:', error);
      }
    );
  }

  fetchCommentCounts(discussions: Discussion[] | Discussion): void {
    if (!Array.isArray(discussions)) {
      discussions = [discussions];
    }
    const commentCountObservables: Observable<number>[] = discussions.map(discussion =>
      this.discussionsService.getCommentCountForPost(discussion.id).pipe(
        map(commentCounts => Array.isArray(commentCounts) ? commentCounts.length : commentCounts)
      )
    );
    forkJoin(commentCountObservables).subscribe(
      commentCounts => {
        commentCounts.forEach((commentCount, index) => {
          (discussions as Discussion[])[index].commentCount = commentCount;
          console.log("comment count => " + commentCount);
        });
      },
      error => {
        console.error('Error fetching comment counts:', error);
      }
    );
  }

}
