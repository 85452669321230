import { Component } from '@angular/core';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Cohort } from '@app/api/models/cohort.model';
import { GlobalToastService } from '@core/services/global-toast.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-cohorts-edit-page',
  templateUrl: './cohorts-edit-page.component.html',
  styleUrls: ['./cohorts-edit-page.component.sass']
})
export class CohortsEditPageComponent {

  cohort: Cohort = {
    day: 1, month: 1,
    description: '', name: '', students: [], technologies: [], year: 0,
    createGitlabGroups: false
  };

  saving = false;

  constructor(private cohortApi: CohortApiService, private route: ActivatedRoute, private toastService: GlobalToastService, private router: Router) {
    this.route.params.subscribe(params => {
      const cohortId = params['id'];
      this.cohortApi.getCohort(cohortId).subscribe({
        next: (cohort) => {
          this.cohort = cohort;
        }
      });
    });
  }

  saveCohort(cohort: Cohort) {
    this.saving = true;
    this.cohortApi.updateCohort(cohort)
      .pipe(catchError((_err) => {
        this.toastService.showDangerToast('Error saving cohort.');
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (cohort) => {
          if (!cohort) return;
          this.cohort = cohort;
          this.saving = false;
          this.toastService.showSuccessToast('Cohort successfully saved!');
        }
      });
  }

  deleteCohort(cohort: Cohort) {
    this.saving = true;
    this.cohortApi.deleteCohort(cohort.id!)
      .pipe(catchError((_err) => {
        this.toastService.showDangerToast('Error deleting cohort.');
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (cohort) => {
          if (!cohort) return;
          this.saving = false;
          this.toastService.showSuccessToast('Cohort successfully deleted!');
          this.router.navigate(['/training/cohorts']);
        }
      });
  }

}
