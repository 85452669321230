import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, take } from 'rxjs';
import { RedirectService } from '@core/services/redirect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private redirect: RedirectService, private titleService: Title) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.router.routerState.snapshot.root;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.data['title']) {
            return child.data['title'];
          }
          return null;
        })
      )
      .subscribe((title: string) => {
        const baseTitle = 'Smoothstack Learn';
        if (title) {
          this.titleService.setTitle(`${title} | ${baseTitle}`);
        } else {
          this.titleService.setTitle(baseTitle);
        }
      });
  }
}
