import { Component } from '@angular/core';

@Component({
  selector: 'app-view-edit-modules-page',
  templateUrl: './view-edit-modules-page.component.html',
  styleUrls: ['./view-edit-modules-page.component.sass']
})
export class ViewEditModulesPageComponent {

}
