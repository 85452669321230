import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  LearningMaterialData,
  LessonAssignmentData,
  LessonData,
} from '@app/api/models/learning-material-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/api/user/services/user.service';
import { FileRecord } from '@core/components/file-explorer/file-record.model';
import { FilesApiService } from '@core/services/files-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';
import { QuizData } from '@app/api/quiz/models/quiz.model';

@Component({
  selector: 'app-lesson-form',
  templateUrl: './lesson-form.component.html',
  styleUrls: ['./lesson-form.component.sass'],
})
export class LessonFormComponent implements OnInit {
  @ViewChild('addMaterialModal') addAssignmentModal!: TemplateRef<any>;
  @ViewChild('addQuizModal') addQuizModal!: TemplateRef<any>;
  @ViewChild('selectLectureSlideModal')
  selectLectureSlideModal!: TemplateRef<any>;

  @Input() lessonData!: LessonData;
  @Input() maxDescriptionLength = 250;
  @Output() lessonDataChange = new EventEmitter<LessonData>();
  @Output() triggerSaveLesson = new EventEmitter();
  userEmail = '';
  presentationFileTypes = [
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'text/markdown',
    'text/plain',
  ];
  presentationFileRecord: FileRecord | null = null;
  modalSelectedMaterial: LearningMaterialData | null = null;
  lessonDescriptionControl!: FormControl;
  disableCreateAssignment = true;
  materialType: ActivityMaterialType = 'ASSIGNMENT';

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private filesApi: FilesApiService,
    private route: ActivatedRoute,
    private assignmentApiService: AssignmentApiService,
    private router: Router,
  ) {
    if (this.userService.currentUser) {
      this.userEmail = this.userService.currentUser.email;
    }
  }

  get alreadySelectedFilter() {
    return (data: LearningMaterialData) => {
      return !this.lessonData.assignments.find((a) => a.id === data.id);
    };
  }

  onDescriptionInput() {
    this.lessonDescriptionControl.markAsTouched();
    this.lessonDescriptionControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    if (!this.lessonData.assignments) {
      this.lessonData.assignments = [];
    }

    if (!this.lessonData.lessonAttachments) {
      this.lessonData.lessonAttachments = [];
    } else {
      // Find first presentation attachment
      const presentationAttachment = this.lessonData.lessonAttachments.find(
        (attachment) => attachment.attachmentType === 'PRESENTATION',
      );
      if (presentationAttachment) {
        this.filesApi
          .getFileRecord(presentationAttachment.fileRecordId)
          .subscribe({
            next: (fileRecord) => {
              this.presentationFileRecord = fileRecord;
            },
          });
      }
    }
    this.lessonDescriptionControl = new FormControl(
      this.lessonData.description,
      [Validators.required, Validators.maxLength(this.maxDescriptionLength)],
    );
    this.lessonDescriptionControl.valueChanges.subscribe((value) => {
      this.lessonData.description = value;
    });
    this.updateAssignmentOrder();

    this.route.queryParams.subscribe((params) => {
      if (params['assignmentId']) {
        this.assignmentApiService
          .getAssignment(params['assignmentId'])
          .subscribe({
            next: (assignment) => {
              this.modalSelectedMaterial = assignment;
              this.addAssignment();
              this.router.navigate([], {
                queryParams: {
                  assignmentId: null,
                },
                queryParamsHandling: 'merge',
              });
            },
          });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['moduleId']) {
        this.disableCreateAssignment = false;
      }
    });
  }

  isDescriptionLimitExceeded(): boolean {
    return (
      this.lessonDescriptionControl.value.length > this.maxDescriptionLength
    );
  }

  updateAssignmentOrder(): void {
    this.lessonData.assignments.forEach((assignment, index) => {
      assignment.order = index;
    });
  }

  onMoveItem(event: any): void {
    moveItemInArray(
      this.lessonData.assignments,
      event.previousIndex,
      event.currentIndex,
    );
    this.lessonData.assignments[event.currentIndex].order = event.currentIndex;
    this.updateAssignmentOrder();
    this.lessonDataChange.emit(this.lessonData);
  }

  openAddMaterialModal(type: ActivityMaterialType): void {
    this.materialType = type;
    this.modalSelectedMaterial = null;
    this.modalService.open(this.addAssignmentModal, {
      size: 'lg',
      scrollable: true,
    });
  }

  addAssignment(): void {
    if (this.modalSelectedMaterial) {
      switch (this.modalSelectedMaterial.materialType) {
        case 'ASSIGNMENT': {
          const assigment = this.modalSelectedMaterial as AssignmentData;
          this.lessonData.assignments.push({
            id: this.modalSelectedMaterial.id || 0,
            order: 0,
            title: this.modalSelectedMaterial.title || '',
            description: assigment.description || '',
            deprecated: assigment.deprecated || false,
            published: assigment.published || false,
            approved: assigment.approved || false,
            technologies: assigment.technologies || [],
            submissionTypes: assigment.submissionTypes || [],
          });
          this.updateAssignmentOrder();
          break;
        }
        case 'QUIZ': {
          const quiz = this.modalSelectedMaterial as QuizData;
          this.lessonData.quizzes.push({
            id: quiz.id,
            title: quiz.title,
            description: quiz.description,
            published: quiz.published,
            deprecated: quiz.deprecated,
            approved: quiz.approved,
            questionCount: 0,
            questions: [],
            randomizeQuestions: false,
            technologies: quiz.technologies,
            order: 0,
          });
          break;
        }
      }

      this.lessonDataChange.emit(this.lessonData);
    }
  }

  removeAssignment(assignment: LessonAssignmentData): void {
    this.lessonData.assignments = this.lessonData.assignments.filter(
      (a) => a.id !== assignment.id,
    );
    this.updateAssignmentOrder();
    this.lessonDataChange.emit(this.lessonData);
  }

  openSelectLectureSlideModal(): void {
    this.modalService.open(this.selectLectureSlideModal);
  }

  removeQuiz(quiz: QuizData): void {
    this.lessonData.quizzes = this.lessonData.quizzes.filter(
      (q) => q.id !== quiz.id,
    );
    this.lessonDataChange.emit(this.lessonData);
  }

  removePresentationAttachment(): void {
    this.lessonData.lessonAttachments = [];
    this.presentationFileRecord = null;
  }

  onFileSelected(file: FileRecord): void {
    this.presentationFileRecord = file;
    this.lessonData.lessonAttachments[0] = {
      id: 0,
      fileRecordId: file.id,
      attachmentType: 'PRESENTATION',
    };
    this.modalService.dismissAll();
  }

  onSelectMaterial(assignment: LearningMaterialData): void {
    this.modalSelectedMaterial = assignment;
  }

  createAssignment(): void {
    this.triggerSaveLesson.emit();
  }
}
