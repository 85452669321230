import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { Page, PageParams } from '@app/api/models/page.model';
import { videoUpdateRequest } from '@app/api/video/interfaces/video-update.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpClient) {}

  getVideoMetaData(videoId: number) {
    return this.http.get<VideoMetaData>(`${environment.apiUrl}/videos/${videoId}/metadata`);
  }

  getVideoThumbnail(videoId: number) {
    return this.http.get(`${environment.apiUrl}/videos/${videoId}/thumbnail`, { responseType: 'blob' });
  }

  getAllVideos(query: string, pageParams?: PageParams) {
    return this.http.get<Page<VideoMetaData>>(`${environment.apiUrl}/videos`, {
      params: {
        ...pageParams,
        search: query
      }
    });
  }

  getVideo(videoId: number) {
    return this.getVideoMetaData(videoId).pipe(
      map((videoMetaData) => {
        return videoMetaData.url;
      }));
  }

  updateVideo(id: number,data: videoUpdateRequest): Observable<any> {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('tags', data.tags);
    if(data.thumbnailFile){
      formData.append('thumbnailFile', data.thumbnailFile);
    }
    return this.http.put(`${environment.apiUrl}/videos/${id}`, formData);
  }

  deleteVideo(videoId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/videos/${videoId}`, { responseType: 'text' });
  }
}
