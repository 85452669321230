<ng-container *ngIf="template; else defaultButtonTemplate">
  <ng-container *ngTemplateOutlet="template; context: context"></ng-container>
</ng-container>
<ng-template #defaultButtonTemplate>
  <ng-container *ngTemplateOutlet="defaultButton; context: context"></ng-container>
</ng-template>
<ng-template #defaultButton>
  <button type="button"
          [disabled]="assigning"
          class="btn btn-outline-primary" (click)="openModal()">
    <span class="bi bi-person-plus me-1"></span> Assign
  </button>
</ng-template>

<ng-template #assignModal>
  <div class="modal-header">
    <h5 class="modal-title"><span class="bi bi-person-plus text-primary"></span> Assign {{content?.materialType | titlecase}}</h5>
    <button [disabled]="assigning" type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="content; else noContent" class="row justify-content-center">
      <div class="col-5">
        <div class="card card-body">
          <div class="mb-4">
            <span class="badge bg-primary rounded-pill">
              {{content.materialType | titlecase}}
            </span>
          </div>
          <h5 class="card-title">
            {{content.title}}
          </h5>
          <div *ngIf="content.technologies && content.technologies.length" class="d-flex flex-wrap gap-1">
            <span *ngFor="let tag of content.technologies"
                  [ngClass]=" {'bg-primary': tag === 'Raven360', 'bg-info': tag !== 'Raven360'}"
                  class="badge  rounded-pill small">
              {{tag}}
            </span>
          </div>
          <div class="my-2">
            {{ content.description | truncated: 250 }}
          </div>
          <div class="my-2 text-muted small">
            Created by {{content.createdBy}} on {{content.createdAt | date: 'medium'}}
          </div>
        </div>
      </div>
      <div class="col-2 flex-shrink px-3 text-muted text-center align-self-center">
        <p>Assign to</p>
        <div class="bi bi-arrow-right-circle text-primary fs-4"></div>
      </div>
      <div class="col-5">
        <h5 class="h5">Select assignees</h5>
        <p class="small text-muted">Please select the student(s)/cohorts you would like to assign this content to.</p>
        <div class="my-2">
          <app-cohort-select-field [disabled]="assigning" [(selectedCohorts)]="selectedCohorts"></app-cohort-select-field>
        </div>
        <div class="my-2">
          <app-user-select-field [disabled]="assigning" [userFilter]="{ role: 'STUDENT' }" placeholder="Search students..." [(selectedUsers)]="selectedUsers"></app-user-select-field>
        </div>

      </div>
    </div>
    <ng-template #noContent>
      <div class="alert alert-warning" role="alert">
        No content selected
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button [disabled]="assigning" type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button [disabled]="assigning" type="button" class="btn btn-outline-primary" (click)="assign()">Assign <span *ngIf="assigning" class="spinner-border spinner-border-sm"></span></button>
  </div>
</ng-template>
