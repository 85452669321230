<ngb-toast *ngFor="let toast of toastService.toasts"
           [autohide]="true"
           [delay]="toast.length || 5000"
           (hidden)="toastService.remove(toast)"
           [header]="toast.title || ''">
  <div *ngIf="isToastString(toast); else toastTemplate" class="d-flex align-items-center gap-2">
    <div [ngSwitch]="toast.type">
      <span *ngSwitchCase="'spinner'" class="spinner-border text-primary spinner-border-sm"></span>
      <span *ngSwitchCase="'success'" class="text-success bi bi-check-circle-fill"></span>
      <span *ngSwitchCase="'info'" class="text-info bi bi-info-circle-fill"></span>
      <span *ngSwitchCase="'warning'" class="text-warning bi bi-exclamation-circle-fill"></span>
      <span *ngSwitchCase="'danger'" class="text-danger bi bi-x-circle-fill"></span>
    </div>
    {{ toast.content }}
  </div>
  <ng-template #toastTemplate>
    <ng-container [ngTemplateOutlet]="toastAsTemplate(toast)"></ng-container>
  </ng-template>
</ngb-toast>
