<div class="mb-3">
  <h4 class="h4">Course Details</h4>
  <p class="text-muted small">A course consists of several modules, each designed to cover different aspects of a comprehensive curriculum.</p>
</div>

<div class="my-3">
  <div class="form-floating">
    <input type="text"
           class="form-control"
           id="courseTitle"
           placeholder="Course Title"
           [(ngModel)]="courseData.title"
           (input)="triggerCourseDataChange()"/>
    <label class="form-label" for="courseTitle">Course Title</label>
  </div>
</div>

<div class="my-3">
  <div class="form-floating">
    <textarea class="form-control"
              id="courseDescription"
              placeholder="Course Description"
              style="height: 100px"
              [(ngModel)]="courseData.description"
              (input)="triggerCourseDataChange()"
              [formControl]="courseDescriptionControl" (input)="onDescriptionInput()"></textarea>
    <label class="form-label" for="courseDescription">Course Description</label>
    <div class="d-flex justify-content-between align-items-center">
      <app-control-validation-message [control]="courseDescriptionControl"></app-control-validation-message>
      <span class="flex-grow-3"></span>
      <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
        {{ courseDescriptionControl.value.length }}/{{ maxDescriptionLength }}
      </span>
    </div>  </div>
</div>

<div class="my-3">
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex-grow-1">
      <h4 class="h4">Course Modules</h4>
      <p class="text-muted small">Please list and arrange all the modules associated with this course.</p>
    </div>
    <div *ngIf="courseData.modules && courseData.modules.length" class="text-end text-end d-flex align-items-center justify-content-center">
        <button class="btn btn-primary mx-2" (click)="openAddModuleModal()"><span class="bi bi-folder-plus"></span> Add Module</button>
        <p class="my-0 mx-2">Or</p>
        <button type="button" class="btn btn-primary mx-2" (click)="createModule()">
        <span class="bi bi-file-earmark-plus"></span>
          Create Module
        </button>
    </div>
  </div>

  <div class="border rounded my-2 bg-light">
    <ul *ngIf="courseData.modules && courseData.modules.length; else noModules"
        cdkDropList
        (cdkDropListDropped)="onMoveItem($event)"
        [cdkDropListData]="courseData.modules" class="list-group list-group-flush rounded">
      <li cdkDrag *ngFor="let module of orderedModules" class="list-group-item list-group-item-action px-0 bg-white lesson-item">
        <div class="d-flex align-items-center">
          <button type="button" class="drag-drop-handle" cdkDragHandle>
            <app-icon iconName="six-dots" class="svg-muted"></app-icon>
          </button>
          <div class="flex-shrink-1 px-3">
            <span class="badge bg-primary rounded-circle">{{module.order + 1}}</span>
          </div>
          <div class="me-2 w-100">
            <div class="mb-2">
              <div class="fw-bold">{{module.title}}</div>
              <div class="d-flex gap-1 flex-wrap">
                <span *ngFor="let technology of module.technologies" class="badge bg-info rounded-pill">{{technology}}</span>
              </div>
            </div>
            <div class="text-muted small">
              {{module.description}}
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center gap-1 w-100 pe-3 flex-shrink-1">
            <button class="btn btn-sm btn-outline-primary" (click)="openEditModulePage(module)">
              <span class="bi bi-pencil"></span>
              <span class="visually-hidden">Edit Module</span>
            </button>
            <button class="btn btn-sm btn-outline-danger" (click)="deleteModule(module)">
              <span class="bi bi-trash"></span>
              <span class="visually-hidden">Remove from Course</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <ng-template #noModules>
      <div class="py-5 text-center">
        No modules have been added to this course yet.
        <div class="mt-2 text-end d-flex align-items-center justify-content-center">
          <button class="btn btn-primary mx-2" (click)="openAddModuleModal()"><span class="bi bi-folder-plus"></span> Add Module</button>
          <p class="my-0 mx-2">Or</p>
          <button type="button" class="btn btn-primary mx-2" (click)="createModule()">
          <span class="bi bi-file-earmark-plus"></span>
            Create Module
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>


<ng-template #addModuleModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Module to Course</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <app-learning-material-selector [publishedOnly]="true" (selectModule)="onSelectModule($event)" materialType="MODULE" [materialFilter]="alreadySelectedFilter"></app-learning-material-selector>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="addModule(); modal.close()">Add Module</button>
  </div>
</ng-template>
