<form ngbDropdown [(open)]="showDropdown" [formGroup]="searchForm">
  <div class="form-control" [class.div-focus]="searchInputFocused" tabindex="0">
    <div class="d-flex flex-row gap-1 flex-wrap align-items-center">
      <div *ngFor="let technologyType of selectedTechnologyTypes"
        class="rounded px-2 py-1 me-2 border bg-light d-flex justify-content-start align-items-center">
        {{technologyType.name}}
        <button class="btn btn-link text-decoration-none text-secondary" (click)="deleteTechnologyType(technologyType)">
          <span class="bi bi-x"></span>
        </button>
      </div>
      <div *ngIf="selectedTechnologyTypes.length < 1" class="flex-grow-1" (focusin)="searchInputFocused = true"
        (focusout)="searchInputFocused = false">
        <input class="form-control-plaintext" #searchInput formControlName="search" [placeholder]="placeholder"
          (focus)="searchInputFocused = true" (blur)="searchInputFocused = false" />
      </div>
    </div>
  </div>
  <div ngbDropdownMenu class="w-100">
    <ng-container *ngIf="technologyTypeResults.length && !searching; else noResults">
      <button [tabIndex]="i + 1" ngbDropdownItem *ngFor="let technologyType of technologyTypeResults; let i = index"
        (click)="addTechnologyType(technologyType)" (keyup.enter)="addTechnologyType(technologyType)" class="py-2">
        {{technologyType.name}}
      </button>
    </ng-container>
    <ng-template #noResults>
      <button ngbDropdownItem class="text-center text-muted py-2" [disabled]="true">
        <span *ngIf="searching" class="spinner-border text-primary">
        </span>
        <span *ngIf="!searching">
          No Results
        </span>
      </button>
    </ng-template>
  </div>
</form>
