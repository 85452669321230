<div class="container">
  <div class="my-3">
    <h3 class="h3">Edit Cohort</h3>
    <p class="text-muted">Edit an existing cohort's information.</p>
    <div class="alert alert-info">
      <p class="mb-0">
        <span class="bi bi-info-circle-fill"></span> This does not affect GitLab groups or projects attached to or associated with this cohort.
      </p>
    </div>
  </div>
  <div class="pb-5">
    <app-cohort-form mode="edit"
                     [cohort]="cohort"
                     [saving]="saving"
                     (deleteCohort)="deleteCohort($event)"
                     (submitCohort)="saveCohort($event)"></app-cohort-form>
  </div>
</div>
