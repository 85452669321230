import { Component } from '@angular/core';
import { Student } from '@app/api/student/model/student.model';
import { StudentService } from '@app/api/student/services/student.service';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-students-add-page',
  templateUrl: './students-add-page.component.html',
  styleUrls: ['./students-add-page.component.sass']
})
export class StudentsAddPageComponent {

  saving = false;
  students: Student[] = [];

  constructor(private studentService: StudentService, private toastService: GlobalToastService) { }


  saveStudents(students: Student[]) {
    this.saving = true;
    this.studentService.createStudents(students)
      .pipe(catchError((error) => {
        this.toastService.showDangerToast(error.error.message);
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (students) => {
          if (!students) return;
          this.students = students;
          this.saving = false;
          this.toastService.showSuccessToast('Students successfully created!');
        }
      });
  }
  
}