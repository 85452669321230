import { Component, OnInit } from '@angular/core';
import { OidcService } from '@core/services/oidc.service';
import { AuthService } from '@auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sso-redirect',
  template: `
    <div class="position-absolute top-0 left-0 wh-100">
      <div class="wh-100 bg-secondary-subtle d-flex justify-content-center align-items-center">
        <div class="card">
          <div class="card-body px-5 py-3">
            <div class="d-flex align-items-center justify-content-center gap-2">
              Signing in <span class="spinner-border text-primary"></span>
            </div>
            <div class="text-muted mt-2 text-center small">
              If you are not signed in automatically, <a [href]="redirectUriWithCode">click here</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class SsoRedirectComponent implements OnInit {

  constructor(private oidc: OidcService, private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.oidc.queryParamsValid) {
      this.auth.ssoLogin().subscribe();
    } else {
      this.router.navigate(['/']);
    }
  }

  get redirectUriWithCode() {
    return this.oidc.redirectUriWithCode;
  }

}
