<div class="container-fluid">

  <div class="mt-3 mb-5 d-flex justify-content-between">
    <h2 class="h4">View Users</h2>
    <div class="flex-grow text-end">
      <button type="button"
              class="btn btn-primary w-100"
              routerLink="/user-management/add-user">
        <span class="bi bi-plus-circle"></span>
        Add User
      </button>
    </div>
  </div>

  <div class="my-2">
    <app-view-users-table></app-view-users-table>
  </div>

</div>
