import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-content-library-home-page',
  templateUrl: './content-library-home-page.component.html',
  styleUrls: ['./content-library-home-page.component.sass']
})
export class ContentLibraryHomePageComponent {

  routeItems: RouteCardItem[] = [
    {
      icon: 'bi bi-collection',
      title: 'All Content',
      description: 'View all published content and resources available in the LMS',
      routerLink: '/content-library/catalog'
    },
    {
      icon: 'bi bi-journal-bookmark-fill',
      title: 'Courses',
      description: 'View, search, and manage all courses available in the LMS.',
      routerLink: '/content-library/courses'
    },
    {
      icon: 'bi bi-folder',
      title: 'Modules',
      description: 'View, search, and manage all modules available in the LMS.',
      routerLink: '/content-library/modules'
    },
    {
      icon: 'bi bi-bookmark',
      title: 'Lessons',
      description: 'View, search, and manage all lessons available in the LMS.',
      routerLink: '/content-library/lessons'
    },
    {
      icon: 'bi bi-file-earmark-text',
      title: 'Assignments',
      description: 'View, search, and manage all assignments available in the LMS.',
      routerLink: '/content-library/assignments'
    },
    {
      icon: 'bi bi-check2-square',
      title: 'Quizzes',
      description: 'View, search, and manage all quizzes and questions available in the LMS.',
      routerLink: '/content-library/quizzes'
    }
  ];

}
