

<ng-template #moduleView>
  <ng-container *ngIf="isModule && routeData.moduleId">
    <app-module-activity-view></app-module-activity-view>
  </ng-container>
</ng-template>

<ng-template #lessonView>
  <ng-container *ngIf="isLesson && routeData.lessonId">
    <app-lesson-activity-view></app-lesson-activity-view>
  </ng-container>
</ng-template>

<ng-template #activityView>

  <ng-container *ngIf="isActivity && routeData.activityId">
    <app-activity-view></app-activity-view>
  </ng-container>
</ng-template>
