import { Component, Input, OnInit } from '@angular/core';
import { CourseData } from '@app/api/models/learning-material-data.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { CourseApiService } from '../../services/course-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-edit-course',
  templateUrl: './view-edit-course.component.html',
  styleUrls: ['./view-edit-course.component.sass']
})
export class ViewEditCourseComponent implements OnInit {

  @Input() currentCourseId?: number;
  @Input() showTitle = true;
  courses: CourseData [] = [];
  filteredCourses: CourseData[] = []; 
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  searchQuery = '';
  selectedTechnology = ''; 
  technologies: string[] = []; 
  showFilterModal = false; 
  hasFilter = false; 

  constructor(private courseApi: CourseApiService,
    private router: Router,
    private modal: GlobalModalService){
  }

  ngOnInit() {
    this.loadCourses();
  }

  loadCourses() {
    this.loading = true;
    this.courseApi.getCoursesByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.totalItems = page.totalElements;
      this.courses = page.content.filter(c => c.id !== this.currentCourseId);
      this.filteredCourses = [...this.courses]; 
      this.extractTechnologies(); 
      this.loading = false;
      this.filterCourses();
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditCoursePage(course: CourseData) {
    this.router.navigate(['course-composer', 'courses', 'edit', course.id]);
  }

  deleteCourse(id: number) {
    this.modal.confirm('Are you sure you want to delete this course?', {
      title: 'Delete Course',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.courseApi.deleteCourse(id).subscribe({
            next: () => {
              this.courses = this.courses.filter(course => course.id !== id);
              this.filterCourses();
            }
          });
        }
      }
    });
  }

  extractTechnologies() {
    this.technologies = Array.from(new Set(this.courses.flatMap(course => 
      course.modules.flatMap(module => 
        module.lessons.flatMap(lesson => lesson.technologies || [])
      )
    )));
    console.log('Extracted technologies:', this.technologies); 
  }

  openFilterModal() {
    this.showFilterModal = true;
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal; 
  }

  resetFilter() {
    this.selectedTechnology = ''; 
    this.searchQuery = ''; 
    this.filteredCourses = [...this.courses]; 
    this.hasFilter = false; 
  }

  filterCourses() {
    this.hasFilter = this.selectedTechnology !== '' || this.searchQuery !== ''; 
    const query = this.searchQuery.toLowerCase();
    this.filteredCourses = this.courses.filter(course => {
      const technologyMatches = this.selectedTechnology === '' || course.modules.some(module => 
        module.lessons?.some(lesson => 
          lesson.technologies?.map(t => t.toLowerCase()).includes(this.selectedTechnology.toLowerCase())
        )
      );
      const titleMatches = !query || course.title.toLowerCase().includes(query);
      return technologyMatches && titleMatches;
    });
    console.log('Filtered courses:', this.filteredCourses); 
  }

  applyFilter() {
    this.filterCourses(); 
    this.toggleFilterModal(); 
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage; 
    this.loadCourses(); 
  }

}
