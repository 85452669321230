import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { ModuleData } from '@app/api/models/learning-material-data.model';
import { Page, PageParams } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleApiService {

  constructor(private api: ApiService, private http: HttpClient) { }

  getModuleById(id: number) {
    return this.http.get<ModuleData>(this.api.endpoint(['modules', id]));
  }

  getModulesByOwner(ownerId: number | 'self', pageParams?: PageParams) {
    return this.http.get<Page<ModuleData>>(this.api.endpoint(['/modules', 'owner', ownerId]), {
      params: {
        ...pageParams
      }
    });
  }

  createModule(moduleData: ModuleData) {
    return this.http.post<ModuleData>(this.api.endpoint(['modules']), moduleData);
  }

  updateModule(id: number, moduleData: ModuleData) {
    return this.http.put<ModuleData>(this.api.endpoint(['modules', id]), moduleData);
  }

  deleteModule(id: number) {
    return this.http.delete(this.api.endpoint(['modules', id]));
  }

}
