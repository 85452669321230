import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { VerticalMultistepFormDirective } from '@core/directives/multistep-form/vertical-multistep-form.directive';

@Directive({
  selector: '[appFormStep]'
})
export class FormStepDirective implements OnInit, AfterViewInit, OnChanges {

  @Input() step = 0;
  @Input() stepTitle = '';
  @Input() stepDescription = '';
  stepTitleDiv: any;
  stepDescriptionDiv: any;
  stepNumberDiv: any;
  progressBarDiv: any;
  private afterViewInit$ = new Subject<FormStepDirective>();

  constructor(private el: ElementRef, private renderer: Renderer2, private formDirective: VerticalMultistepFormDirective) {

    renderer.addClass(el.nativeElement, 'form-step');

    // Add a child div with class 'form-progress'
    const progressDiv = renderer.createElement('div');
    renderer.addClass(progressDiv, 'form-step-progress');
    renderer.appendChild(el.nativeElement, progressDiv);

    // Add a child div with class 'form-progress-bar'
    this.progressBarDiv = renderer.createElement('div');
    renderer.addClass(this.progressBarDiv, 'form-step-progress-bar');
    renderer.appendChild(progressDiv, this.progressBarDiv);

    // Add a child div to element with class 'form-step-header'
    const stepHeader = renderer.createElement('div');
    renderer.addClass(stepHeader, 'form-step-header');
    renderer.appendChild(el.nativeElement, stepHeader);

    // Add a div with class 'form-step-number' to element with class 'form-step-header'
    this.stepNumberDiv = renderer.createElement('div');
    renderer.addClass(this.stepNumberDiv, 'form-step-number');
    renderer.appendChild(stepHeader, this.stepNumberDiv);

    // Add a div with class 'form-step-details' to element with class 'form-step-header'
    const stepDetails = renderer.createElement('div');
    renderer.addClass(stepDetails, 'form-step-details');
    renderer.appendChild(stepHeader, stepDetails);

    // Add a div with class 'form-step-title' to element with class 'form-step-details'
    this.stepTitleDiv = renderer.createElement('div');
    renderer.addClass(this.stepTitleDiv, 'form-step-title');
    renderer.appendChild(stepDetails, this.stepTitleDiv);

    // Add a div with class 'form-step-description' to element with class 'form-step-details'
    this.stepDescriptionDiv = renderer.createElement('div');
    renderer.addClass(this.stepDescriptionDiv, 'form-step-description');
    renderer.appendChild(stepDetails, this.stepDescriptionDiv);

  }

  ngOnInit(): void {
    // Add the appropriate text to each element
    this.renderer.appendChild(this.stepTitleDiv, this.renderer.createText(this.stepTitle));
    this.renderer.appendChild(this.stepDescriptionDiv, this.renderer.createText(this.stepDescription));
    this.stepNumberDiv.innerText = this.step.toString();
    this.afterViewInit$.next(this);
  }

  ngAfterViewInit() {
    this.afterViewInit$.next(this);
  }

  get active() {
    return this.el.nativeElement.classList.contains('active');
  }

  set active(val: boolean) {
    if (val) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }

  get complete() {
    return this.el.nativeElement.classList.contains('complete');
  }

  set complete(val: boolean) {
    if (val) {
      this.renderer.addClass(this.el.nativeElement, 'complete');
      this.renderer.addClass(this.progressBarDiv, 'complete');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'complete');
      this.renderer.removeClass(this.progressBarDiv, 'complete');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['step']) {
      this.stepNumberDiv.innerText = this.step.toString();
    }
  }

}
