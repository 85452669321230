import { Component, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';

@Component({
  selector: 'app-select-field',
  template: `
    <ng-container *ngIf="control">
      <div class="form-floating">
        <select class="form-select"
                [id]="controlName"
                (focus)="controlFocus.emit()"
                [formControl]="control">
          <option [value]="null" disabled selected>-- {{placeholder}} --</option>
          <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
        </select>
        <label class="form-label" [for]="controlName">
          <span [ngClass]="{ 'required': control.hasValidator(Validators.required) }">{{label}}</span>
        </label>
      </div>
      <app-control-validation-message [control]="control" [customErrorMessages]="customErrorMessages"></app-control-validation-message>
    </ng-container>
  `
})
export class SelectFieldComponent extends AbstractCoreFormControlComponent {

  @Input() placeholder = 'Select an option';
  @Input() options: { value: any, label: string }[] | any[] = [];
  @Input() optionMapper: (option: any) => { value: any, label: string } = (option: any) => ({ value: option, label: option });

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }
}
