<form [formGroup]="editForm" (submit)="onSubmit()">
  <div class="card card-body my-2">

    <div class="my-2">
      <app-text-field controlName="email" label="Email"></app-text-field>
    </div>

    <div class="my-2">
      <app-text-field controlName="firstName" label="First Name"></app-text-field>
    </div>

    <div class="my-2">
      <app-text-field controlName="lastName" label="Last Name"></app-text-field>
    </div>

  </div>

  <div class="my-2 text-end">
    <button class="btn btn-danger me-2" type="button" (click)="openDeleteStudentConfirm()">
      Delete <span class="bi bi-trash"></span>
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="editForm.invalid">Save
      <span class="bi bi-floppy"></span> </button>
  </div>
  </form>
  
