<p class="text-primary">Fields marked with <span class="text-danger">*</span> are required.</p>

<form [formGroup]="addUserForm">

  <div class="my-3">
    <button type="button"
            (click)="openMicrosoftUserModal()"
            class="btn btn-primary">
      <span class="bi bi-microsoft me-2"></span>
      Add User from Microsoft
    </button>
  </div>

  <div class="my-3">
    <app-text-field controlName="email"
                    type="email"
                    label="Email"></app-text-field>
  </div>

  <div class="my-3">
    <app-text-field controlName="password"
                    [type]="showPassword ? 'text' : 'password'"
                    [customErrorMessages]="{ pattern: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.' }"
                    label="Password"></app-text-field>
  </div>

  <div class="my-3">
    <app-text-field controlName="confirmPassword"
                    [type]="showPassword ? 'text' : 'password'"
                    [customErrorMessages]="{
                      matches: 'Passwords do not match'
                    }"
                    label="Confirm Password"></app-text-field>
  </div>

  <div class="my-2 d-flex flex-row justify-content-end align-items-center">
    <div class="mx-4 form-check form-switch">
      <input class="form-check-input"
             type="checkbox"
             id="showPassword"
             (change)="showPassword = !showPassword">
      <label class="form-check-label"
             for="showPassword">Show Password</label>
    </div>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="addUserForm.controls['password'].value" (click)="generatePassword()">Generate Password</button>
  </div>

  <div class="my-3">
    <app-dropdown-select-field
        controlName="role"
        label="Role"
        [options]="roles"></app-dropdown-select-field>
  </div>

  <div class="my-3">
    <app-text-field controlName="firstName"
                    type="text"
                    label="First Name"></app-text-field>
  </div>

  <div class="my-3">
    <app-text-field controlName="lastName"
                    type="text"
                    label="Last Name"></app-text-field>
  </div>

  <div class="mt-5 py-3 text-center">
    <div class="my-3" ngbTooltip="Send user an email invite.">
      <app-toggle-field controlName="invite"
                        boxStyle="switch"
                        label="Invite User"></app-toggle-field>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-2"
              type="submit"
              [disabled]="addUserForm.invalid || inProgress"
              (click)="onSubmit()">Add User</button>
      <button class="btn btn-outline-primary mx-2"
              type="submit" (click)="cancelClicked.emit()">Cancel</button>
    </div>
  </div>

</form>

<ng-template #addFromMicrosoftModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add from Microsoft</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-search-microsoft-user-form (selectMicrosoftUser)="onSelectMicrosoftUser($event)"></app-search-microsoft-user-form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mx-2" [disabled]="!selectedMicrosoftUser" (click)="populateFromMicrosoftUser(); modal.close()">Populate</button>
    <button type="button" class="btn btn-outline-primary mx-2" (click)="modal.dismiss()">Cancel</button>
  </div>
</ng-template>
