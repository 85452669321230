import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModalOptions } from '@core/interfaces/global-modal-options.interface';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
})
export class GlobalModalComponent {
  @Input() options?: GlobalModalOptions;
  selectedValue: string | null = null;
  get modalOptions(): GlobalModalOptions {
    return { 
      okButtonText: 'OK',
      okButtonCallback: () => this.okCallback(),
      cancelButtonText: 'Cancel',
      cancelButtonCallback: () => this.dismissModal(),
      showCancelButton: false,
      ...this.options!
    };
  }
  deleteInput = '';

  okCallback() {
    if (this.options?.okButtonCallback) {
      this.options.okButtonCallback(this, this.selectedValue); 
    }
    this.closeModal(this.selectedValue);
  }

  cancelCallback() {
    if (this.modalOptions.cancelButtonCallback) {
      this.modalOptions.cancelButtonCallback(this);
    }
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  get isTemplate() {
    return this.modalOptions?.content instanceof TemplateRef;
  }

  get contentAsTemplate() {
    return this.modalOptions?.content as TemplateRef<any>;
  }

  get contentAsString() {
    return this.modalOptions?.content as string;
  }

  closeModal(selectedValue: string | null = null) {
    this.activeModal.close(selectedValue);
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  disableOkButton() {
    if (this.modalOptions.deleteMode) {
      return this.deleteInput !== this.modalOptions.resourceName;
    }
    return false;
  }
}
