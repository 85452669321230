<div class="my-2 d-flex justify-content-center flex-column">
  <div class="w-100 d-flex justify-content-start my-2 gap-2 flex-row-reverse align-items-center">
    <div class="col-12 col-md-3">
      <app-search-bar-input (loadResults)="getAllTrainers()" [(search)]="searchQuery"></app-search-bar-input>
    </div>
    <div ngbDropdown>
      <button type="button"
              ngbDropdownToggle
              ngbTooltip="Sort"
              class="btn btn-light">
        <span class="bi bi-sort-alpha-down"></span>
        Sort by: <span class="mx-1">{{mapPropertyToLabel(pagination.sortBy)}}</span>
      </button>
      <div ngbDropdownMenu style="z-index: 1001;">
        <button (click)="pagination.sortBy = 'id'; pagination.direction = 'desc'; getAllTrainers()" ngbDropdownItem>
          Newest First
        </button>
        <button (click)="pagination.sortBy = 'id'; pagination.direction = 'asc'; getAllTrainers()" ngbDropdownItem>
          Oldest First
        </button>
        <button (click)="pagination.sortBy = 'id'; getAllTrainers()" ngbDropdownItem>
          ID
        </button>
        <button (click)="pagination.sortBy = 'user.email'; getAllTrainers()" ngbDropdownItem>
          Email
        </button>
        <button (click)="pagination.sortBy = 'user.firstName'; getAllTrainers()" ngbDropdownItem>
          First Name
        </button>
        <button (click)="pagination.sortBy = 'user.lastName'; getAllTrainers()" ngbDropdownItem>
          Last Name
        </button>
      </div>
    </div>
    <div class="mx-1">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" [(ngModel)]="pagination.direction" name="sortDirection" value="asc"
            (change)="getAllTrainers()" class="form-check-input" />
          Ascending
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" [(ngModel)]="pagination.direction" name="sortDirection" value="desc"
            (change)="getAllTrainers()" class="form-check-input" />
          Descending
        </label>
      </div>
    </div>
  </div>


  <table class="table table-hover">
    <thead>
      <tr class="table-light">
        <th>ID</th>
        <th>Email</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Technologies</th>
        <th><span class="sr-only">Action</span></th>
      </tr>
    </thead>
    <tbody>
      <tr class="align-middle" [routerLink]="[ trainer.id ]" *ngFor="let trainer of trainers">
        <td [ngClass]="trainerCellClass(trainer)">
          {{trainer.id}}
        </td>
        <td [ngClass]="trainerCellClass(trainer)">
          {{trainer.email}} <span *ngIf="trainer.invite"
            class="bi bi-check-circle-fill text-success d-inline-block h-100" ngbTooltip="Invited"
            placement="right"></span>
        </td>
        <td [ngClass]="trainerCellClass(trainer)">
          {{trainer.firstName}}
        </td>
        <td [ngClass]="trainerCellClass(trainer)">
          {{trainer.lastName}}
        </td>
        <td [ngClass]="trainerCellClass(trainer)">
          <div class="d-flex flex-wrap gap-1">
            <div class="text-info bg-info text-white my-1 px-2 py-1 badge small"
              *ngFor="let technologies of trainer.technologies">
              {{technologies}}
            </div>
          </div>
        </td>
        <td click-stop-propagation ngbDropdown [ngClass]="trainerCellClass(trainer)">
          <button type="button" ngbDropdownToggle class="btn btn-link dropdown-no-caret">
            <span class="bi bi-three-dots-vertical"></span>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['edit', trainer.id]">
              <span class="bi bi-pencil-square"></span>
              Edit
            </button>

            <app-delete-trainer [deleteTrainerData]="trainer" (trainerDeleted)="onDeleteTrainer()">
              <ng-template let-openDeleteTrainerModal="openDeleteTrainerModal">
                <button ngbDropdownItem class="btn btn-danger" (click)="openDeleteTrainerModal()">
                  <span class="bi bi-trash-fill"></span>
                  Delete
                </button>
              </ng-template>
            </app-delete-trainer>
          </div>
        </td>
      </tr>

      <tr class="align-middle" *ngFor="let _ of emptyRows()">
        <td colspan="6" class="bg-light-subtle">
          <span class="btn bg-transparent cursor-default">
            &nbsp;
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mx-auto">
    <ngb-pagination [collectionSize]="pagination.total" [maxSize]="10" [boundaryLinks]="true" [(page)]="pagination.page" [pageSize]="pagination.size"
      (pageChange)="getAllTrainers()"></ngb-pagination>
  </div>
</div>
