<div class="my-3 card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="me-2">
        <h5 class="card-title">Manage Learning Activities</h5>
        <p class="text-muted small">
          <span class="bi bi-info-circle me-1"></span>
          Use the filters to narrow down the list of learning activities.
        </p>
      </div>
      <button (click)="hideFilters = !hideFilters" class="btn btn-outline-primary border-0">
        <span class="visually-hidden">Toggle show filters</span>
        <span *ngIf="hideFilters" class="bi bi-chevron-down"></span>
        <span *ngIf="!hideFilters" class="bi bi-chevron-up"></span>
      </button>
    </div>
    <div [ngbCollapse]="hideFilters">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 col-md-3 my-2">
            <h6 class="h6">Cohort</h6>
            <div class="form-floating">
              <app-multi-select-field (ngModelChange)="loadLearningActivities()"
                                      (searchChange)="onCohortSearchChange($event)" [(ngModel)]="selectedCohortIds"
                                      [filterField]="true"
                                      [options]="cohortOptions"
                                      placeholder="Filter by cohorts"></app-multi-select-field>
            </div>
          </div>
          <div class="col-6 col-md-3 my-2">
            <h6 class="h6">Students</h6>
            <app-multi-select-field (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onStudentSearchChange($event)" [(ngModel)]="selectedStudentIds"
                                    [filterField]="true"
                                    [options]="studentOptions"
                                    placeholder="Filter by students"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Course</h6>
            <app-multi-select-field (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onCourseSearchChange($event)" [(ngModel)]="selectedCourseIds"
                                    [filterField]="true"
                                    [options]="courseOptions"
                                    placeholder="Filter by course"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Module</h6>
            <app-multi-select-field (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onModuleSearchChange($event)" [(ngModel)]="selectedModuleIds"
                                    [filterField]="true"
                                    [options]="moduleOptions"
                                    placeholder="Filter by module"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Lesson</h6>
            <app-multi-select-field (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onLessonSearchChange($event)" [(ngModel)]="selectedLessonIds"
                                    [filterField]="true"
                                    [options]="lessonOptions"
                                    placeholder="Filter by lesson"></app-multi-select-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row my-3">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row flex-md-row-reverse">
          <div class="col-12 col-md-3 my-2">
            <app-search-bar-input
              (searchChange)="onActivitySearch($event)"
              [searchDelay]="500">
            </app-search-bar-input>
          </div>
          <div class="col-12 col-md-3 my-2">
            <app-multi-select-field
              (ngModelChange)="loadLearningActivities()"
              [(ngModel)]="selectedTypes"
              [options]="activityTypeOptions"
              placeholder="Filter by activity type">
            </app-multi-select-field>
          </div>
          <div class="col-12 col-md-6 my-2">
            <div class="d-flex align-items-center">
              <div class="col align-self-center">
                <button (click)="deleteSelectedActivities()" *ngIf="anyActivitySelected()" class="btn btn-danger">
                  <span class="bi bi-trash me-2"></span> Delete
                </button>
              </div>
              <div class="mx-5" ngbDropdown>
                <button class="btn btn-light"
                        ngbDropdownToggle
                        ngbTooltip="Sort"
                        type="button">
                  <span class="bi bi-sort-alpha-down"></span>
                  Sort by: <span class="mx-1">{{ getLabel() }}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    (click)="onSort('id')"
                    ngbDropdownItem>
                    ID
                  </button>
                  <button
                    (click)="onSort( 'type')"
                    ngbDropdownItem>
                    Type
                  </button>
                  <button
                    (click)="onSort( 'progress')"
                    ngbDropdownItem>

                    Progress
                  </button>
                  <button
                    (click)="onSort( 'dueDate')"
                    ngbDropdownItem>
                    Due Date
                  </button>
                </div>
              </div>
              <div class="col align-self-center">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input (ngModelChange)="onSortDirectionChange()"
                           [(ngModel)]="sortDirection"
                           class="form-check-input"
                           name="sortDirection"
                           type="radio"
                           value="asc"/>
                    Ascending
                  </label>
                </div>
              </div>
              <div class="col align-self-center">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input (ngModelChange)="onSortDirectionChange()"
                           [(ngModel)]="sortDirection"
                           class="form-check-input"
                           name="sortDirection"
                           type="radio"
                           value="desc"/>
                    Descending
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row my-3">
  <div class="col-7">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">
          <input (change)="selectAll($event)" [checked]="allSelected" type="checkbox">
        </th>
        <th scope="col">Learning Activity</th>
        <th scope="col">Type
        </th>
        <th scope="col">Assigned to</th>
        <th scope="col">Due Date
        </th>
        <th scope="col">Progress
        </th>
        <th scope="col">Grade</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody *ngIf="!loading; else loadingRowsSkeleton">
      <tr *ngFor="let learningActivity of learningActivities">
        <td>
          <input (change)="toggleSelection(learningActivity.id, $event)" [checked]="isSelected(learningActivity.id)"
                 type="checkbox">
        </td>
        <td class="align-items-start text-start">
          <button
            (click)="previewActivity(learningActivity)"
            *ngIf="learningActivity.type === 'LESSON' || learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'QUIZ'; else plainTitle"
            class="btn btn-link text-start">
              <span [ngClass]="{ 'fw-bold': selectLearningActivity?.id === learningActivity.id }">
                {{ learningActivity.title }}
              </span>
          </button>
          <ng-template #plainTitle>
            <div class="px-3 py-2 d-flex align-items-center">
              {{ learningActivity.title }}
            </div>
          </ng-template>
        </td>
        <td>{{ learningActivity.type | titlecase }}</td>
        <td>{{ learningActivity.student.firstName }} {{ learningActivity.student.lastName }}</td>
        <td>{{ learningActivity.dueDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ learningActivity.progress | percent }}</td>
        <td>
            <span *ngIf="learningActivity.submission">
              <span *ngIf="learningActivity.submission.graded"
                    [ngClass]="{ 'text-success': learningActivity.submission.score >= 0.7, 'text-warning': learningActivity.submission.score < 0.7 && learningActivity.submission.score >= 0.6, 'text-danger': learningActivity.submission.score < 0.6 }"
                    class="fw-bold">
                {{ (learningActivity.submission.score ? learningActivity.submission.score : 0) | percent }}
              </span>
              <span *ngIf="!learningActivity.submission.graded" class="text-muted">
                <span *ngIf="learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'QUIZ'">
                  Not Graded
                </span>
              </span>
            </span>
        </td>
        <td>
          <div ngbDropdown>
            <button class="btn btn-outline-primary dropdown-no-caret border-0" ngbDropdownToggle>
              <span class="bi bi-three-dots-vertical"></span>
            </button>
            <div ngbDropdownMenu>
              <button
                (click)="previewActivity(learningActivity)"
                *ngIf="learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'LESSON' || learningActivity.type === 'QUIZ'"
                class="dropdown-item">
                <span class="bi bi-eye me-2"></span>
                Preview
              </button>
              <button (click)="showDeleteActivityModal(learningActivity)" class="dropdown-item text-danger">
                <span class="bi bi-trash me-2"></span>
                Delete
              </button>
            </div>
          </div>
        </td>
      </tbody>
    </table>

    <div class="my-2 text-center">
      <ngb-pagination (pageChange)="loadLearningActivities()" [(page)]="currentPage" [boundaryLinks]="true"
                      [collectionSize]="totalItems"
                      [maxSize]="10"></ngb-pagination>
    </div>
  </div>

  <div class="col-5">
    <div class="ms-lg-3">
      <div *ngIf="selectLearningActivity; else noSelectedLearningActivity" class="card overflow-y-scroll max-h-preview">
        <div class="card-body">
          <app-activity-view
            *ngIf="selectLearningActivity.type === 'ASSIGNMENT'"
            [activityId]="selectLearningActivity.id"
            [showNavigation]="false"
            [enableSmoothScroll]="false">
          </app-activity-view>
          <app-quiz-activity-view
            *ngIf="selectLearningActivity.type === 'QUIZ'"
            [learningActivityId]=" selectLearningActivity.id"
          />
          <app-lesson-activity-view
            *ngIf="selectLearningActivity.type === 'LESSON'"
            [lessonActivityId]="selectLearningActivity.id"
            [showNavigation]="false"
            [enableSmoothScroll]="false">
          </app-lesson-activity-view>
        </div>
      </div>
      <ng-template #noSelectedLearningActivity>
        <div class="card max-h-preview">
          <div class="card-body text-muted">
            <h5 class="card-title">No Learning Activity Selected</h5>
            <p class="card-text">Select a learning activity from the table to preview it.</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #loadingRowsSkeleton>
  <tbody>
  <tr *ngFor="let _ of [].constructor(10)">
    <td class="animate__animated animate__slow animate__flash" colspan="7">
      <div class="py-2">
        &nbsp;
      </div>
    </td>
  </tr>
  </tbody>
</ng-template>
