import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

@Component({
  selector: 'app-sso-button',
  template: `
    <button #ssoButton [disabled]="inProgress" type="button" class="btn btn-primary w-100 py-3 rounded-3" (click)="loginWithSSO()">
      <i class="bi bi-microsoft"></i> {{ 'Sign in with Microsoft' | stringResource }}
    </button>
  `
})
export class SsoButtonComponent implements OnInit {

  @Input() redirectUrl = '';

  inProgress = false;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.auth.inProgress.subscribe({
      next: (inProgress) => {
        this.inProgress = inProgress;
      }
    });
  }

  loginWithSSO() {
    this.inProgress = true;
    this.auth.loginWithMicrosoft();
  }

}
