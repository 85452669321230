import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  LearningMaterialData,
  LessonData,
  ModuleData,
} from '@app/api/models/learning-material-data.model';
import { moveItemInArray, PreviewContainer } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';
import { QuizService } from '@app/api/quiz/services/quiz.service';
import { QuizData } from '@app/api/quiz/models/quiz.model';

@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.sass'],
})
export class ModuleFormComponent implements OnInit {
  @ViewChild('previewModal') previewModal!: TemplateRef<any>;
  @ViewChild('addLessonModal') addLessonModal!: TemplateRef<any>;

  @Input() previewContainer: PreviewContainer = 'global';
  @Input() moduleData!: ModuleData;
  @Input() maxDescriptionLength = 250;
  @Output() moduleDataChange = new EventEmitter<ModuleData>();
  @Output() editLesson = new EventEmitter<LessonData>();
  @Output() triggerSaveModule = new EventEmitter();

  selectedMaterial: LearningMaterialData | null = null;
  previewLessonData: LessonData | null = null;
  previewQuizData: QuizData | null = null;
  moduleDescriptionControl!: FormControl;
  disableCreateLesson = true;
  materialType: ActivityMaterialType = 'LESSON';

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private globalModal: GlobalModalService,
    private lessonApi: LessonApiService,
    private quizApi: QuizService,
  ) {}

  get alreadySelectedFilter() {
    return (data: LearningMaterialData) => {
      return !this.moduleData.lessons.some((lesson) => lesson.id === data.id);
    };
  }

  get orderedQuizzes() {
    return this.moduleData.quizzes?.sort((a, b) => a.order - b.order);
  }

  onDescriptionInput() {
    this.moduleDescriptionControl.markAsTouched();
    this.moduleDescriptionControl.updateValueAndValidity();
  }

  addLesson() {
    if (!this.selectedMaterial) {
      return;
    }

    switch (this.materialType) {
      case 'LESSON': {
        if (!this.moduleData.lessons) {
          this.moduleData.lessons = [];
        }
        this.lessonApi.getLesson(this.selectedMaterial.id!).subscribe({
          next: (lessonData) => {
            this.moduleData.lessons.push(lessonData);
            this.updateLessonOrder();
          },
        });
        break;
      }
      case 'QUIZ':
        {
          if (!this.moduleData.quizzes) {
            this.moduleData.quizzes = [];
          }
          this.quizApi.getQuizById(this.selectedMaterial.id!).subscribe({
            next: (quizData) => {
              this.moduleData.quizzes.push(quizData);
              this.updateQuizOrder();
            },
          });
        }
        break;
    }
    this.moduleDataChange.emit(this.moduleData);
  }

  ngOnInit() {
    this.updateLessonOrder();

    if (this.moduleData.lessons && this.moduleData.lessons.length) {
      for (let i = 0; i < this.moduleData.lessons.length; i++) {
        this.lessonApi.getLesson(this.moduleData.lessons[i].id!).subscribe({
          next: (lessonData) => {
            this.moduleData.lessons[i] = lessonData;
          },
        });
      }
    }
    this.moduleDescriptionControl = new FormControl(
      this.moduleData.description,
      [Validators.required, Validators.maxLength(250)],
    );
    this.moduleDescriptionControl.valueChanges.subscribe((value) => {
      this.moduleData.description = value;
    });
    this.route.queryParams.subscribe((params) => {
      if (params['lessonId']) {
        this.lessonApi.getLesson(params['lessonId']).subscribe({
          next: (lesson) => {
            this.selectedMaterial = lesson;
            this.addLesson();
            this.router.navigate([], {
              queryParams: {
                lessonId: null,
              },
              queryParamsHandling: 'merge',
            });
          },
        });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['courseId']) {
        this.disableCreateLesson = false;
      }
    });
  }

  updateLessonOrder() {
    this.moduleData.lessons.forEach((lesson, index) => {
      lesson.order = index;
    });
  }

  isDescriptionLimitExceeded(): boolean {
    return (
      this.moduleDescriptionControl.value.length > this.maxDescriptionLength
    );
  }

  onMoveItem(event: any): void {
    moveItemInArray(
      this.moduleData.lessons,
      event.previousIndex,
      event.currentIndex,
    );
    this.moduleData.lessons[event.currentIndex].order = event.currentIndex;
    this.updateLessonOrder();
    this.moduleDataChange.emit(this.moduleData);
  }

  updateQuizOrder() {
    this.moduleData.quizzes.forEach((quiz, index) => {
      quiz.order = index;
    });
  }

  onSelectLesson(materialData: LearningMaterialData) {
    this.selectedMaterial = materialData;
  }

  openAddMaterialModal(material: ActivityMaterialType) {
    this.materialType = material;
    this.selectedMaterial = null;
    this.modalService.open(this.addLessonModal, {
      size: 'lg',
      scrollable: true,
    });
  }

  createLesson() {
    this.triggerSaveModule.emit();
  }

  openEditLessonPage(lesson: LessonData) {
    if (!lesson.id) {
      return;
    }

    this.editLesson.emit(lesson);
  }

  get orderedLessons() {
    return this.moduleData.lessons?.sort((a, b) => a.order - b.order);
  }

  openPreviewModal(materialData: LearningMaterialData) {
    if (!materialData.id) {
      return;
    }
    this.materialType = materialData.materialType!;
    this.modalService.open(this.previewModal, {
      size: 'lg',
      scrollable: true,
    });
    if (materialData.materialType === 'LESSON') {
      this.lessonApi.getLesson(materialData.id!).subscribe({
        next: (lessonData) => {
          this.previewLessonData = lessonData;
        },
      });
    }
    if (materialData.materialType === 'QUIZ') {
      this.quizApi.getQuizById(materialData.id!).subscribe({
        next: (quizData) => {
          this.previewQuizData = quizData;
        },
      });
    }
  }

  onRemoveMaterial(materialData: LearningMaterialData) {
    this.globalModal
      .confirm(
        `Are you sure you want to remove this ${materialData.materialType?.toLowerCase()}?`,
        {
          title: `Remove ${materialData!.materialType![0].toUpperCase() + materialData.materialType!.slice(1).toLowerCase()}`,
          okButtonText: 'Remove',
          type: 'danger',
        },
      )
      .subscribe({
        next: (result) => {
          if (result) {
            if (materialData.materialType === 'LESSON') {
              this.moduleData.lessons = this.moduleData.lessons.filter(
                (l) => l.id !== materialData.id,
              );
              this.moduleDataChange.emit(this.moduleData);
              this.updateLessonOrder();
            } else if (materialData.materialType === 'QUIZ') {
              this.moduleData.quizzes = this.moduleData.quizzes.filter(
                (q) => q.id !== materialData.id,
              );
              this.moduleDataChange.emit(this.moduleData);
              this.updateQuizOrder();
            }
          }
        },
      });
  }
}
