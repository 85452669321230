import { Component } from '@angular/core';

@Component({
  selector: 'app-my-capstone-page',
  templateUrl: './my-capstone-page.component.html',
  styleUrls: ['./my-capstone-page.component.sass']
})
export class MyCapstonePageComponent {

}
