<div class="container-fluid p-2">
  <div class="row">
    <!-- for inbox -->
    <div class="col-4">
      <div class="card border-2 border-light h-100">

        <!-- inbox header -->
        <div class="card-header">
          <app-add-chat-button (addChatEmit)="onAddChat()"></app-add-chat-button>
        </div>

        <div class="card-body overflow-auto p-0 m-0">

          <!-- inbox body -->
          <table class="table table-hover">
            <tbody>
              <tr class="align-middle m-0 p-0" *ngFor="let inbox of inboxList ; index as i" [class.table-secondary]="i === selectedRow" (click)="selectRow(i)">
                <!-- For show profile picture -->
                <td>
                  <span *ngIf="loading; else userIcon" class="spinner-border text-secondary"></span>
                  <ng-template #userIcon>
                    <img *ngIf="profilePictureUrl; else defaultUserIcon" [src]="getProfilePicture(inbox.receiver.id)"
                      class="rounded-circle text-center" alt="" width="50" height="50">
                    <ng-template #defaultUserIcon>
                      <span class="bi bi-person-circle fs-2 text-primary text-center"></span>
                    </ng-template>
                  </ng-template>
                </td>
                <!-- for show user name with last message -->
                <td class="w-75">
                  <button class="btn border-0 p-0 mb-2 text-start w-100" (click)="updateReceiverId(inbox); loadMessages(inbox.chatId)"
                    style="height: 40px;">
                    <b>
                      {{ currentUserId == inbox.receiver.id ? (inbox.sender.firstName + ' ' + inbox.sender.lastName) :
                      (inbox.receiver.firstName + ' ' + inbox.receiver.lastName) }}
                    </b>
                    <br>
                    <p class="text-truncate" style="max-width: 200px;">{{ inbox.lastMessage }}</p>
                  </button>
                </td>
                <!-- for show delete button -->
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button ngbDropdownToggle class="btn btn-sm btn-link border-0 dropdown-no-caret text-dark">
                    <span class="bi bi-three-dots-vertical"></span>
                    </button>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem class="btn btn-danger"
                        (click)="openDeleteChatDialog(inbox, i)">
                        <i class="bi bi-trash"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
      </div>

    </div>

    <!-- for chat -->
    <div class="col-8">
      <div class="card border-2 border-light" style="height: 75vh">

        <!-- chat header -->
        <div class="card-header p-0 m-0 d-flex" *ngIf="showChatBox">
          <span *ngIf="loading; else userIcon" class="spinner-border text-secondary"></span>
          <ng-template #userIcon>
            <img *ngIf="profilePictureUrl; else defaultUserIcon" [src]="getProfilePicture(currentReceiver?.id)"
              class="rounded-circle ms-3" alt="" width="50" height="50">
            <ng-template #defaultUserIcon>
              <span class="bi bi-person-circle fs-1 text-primary ms-3"></span>
            </ng-template>
          </ng-template>
          <h4 class="p-2 mx-auto text-center">
            {{currentReceiver?.firstName+' '+ currentReceiver?.lastName}}
          </h4>
        </div>

        <!-- chat body -->
        <div class="card-body overflow-auto">
          <div *ngFor="let message of messages; index as i">
            <div
              [ngClass]="{'d-flex flex-row' :message.receiver.id === currentUserId, 'd-flex flex-row-reverse '  : message.receiver.id !== currentUserId}">
              <div class="m-1 p-2 d-inline-block overflow-auto"
                [ngClass]="{'bg-light border border-primary rounded-end rounded-start border-opacity-50' :message.receiver.id === currentUserId, 'bg-primary bg-opacity-25 rounded'  : message.receiver.id !== currentUserId}">
                {{ message.content }}
              </div>
            </div>
          </div>
        </div>

        <!-- chat footer -->
        <div class="card-footer" *ngIf="showChatBox">
          <div class="input-group">
            <textarea [(ngModel)]="messageRequest.content" class="form-control border-2 border-light"
              placeholder="Your message here..." (keyup.enter)="sendMessage()"></textarea>
            <button class="btn btn-primary border-2 border-light" type="button" ng-touched (click)="sendMessage()">
              <i class="bi bi-send"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteChatModal>
  <p>Do you want to delete the <span class="text-danger fw-bold">{{ deletedChat+"'s" }}</span> chat</p>
</ng-template>
