import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { Router } from '@angular/router';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-open-assignments',
  templateUrl: './open-assignments.component.html',
  styleUrls: ['./open-assignments.component.sass']
})
export class OpenAssignmentsComponent implements OnInit {

  @Output() openAssignment = new EventEmitter<number>();
  @Input() currentAssignmentId?: number;
  @Input() showTitle = true;
  assignments: AssignmentData[] = [];
  filteredAssignments: AssignmentData[] = []; 
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  searchQuery = '';
  selectedTechnology = ''; 
  technologies: string[] = []; 
  showFilterModal = false; 
  hasFilter = false; 

  constructor(private assignmentApi: AssignmentApiService,
    private modal: GlobalModalService,
    private router: Router) {
  }

  ngOnInit() {
    this.loadAssignments();
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  onOpenAssignment(id: number) {
    this.router.navigate(['/assignment-composer', id]);
    if (this.openAssignment) {
      this.openAssignment.emit(id);
    }
  }

  deleteAssignment(id: number) {
    this.modal.confirm('Are you sure you want to delete this assignment and learning materials under this assignment?', {
      title: 'Delete Assignment',
      iconClass: 'bi bi-trash text-danger',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.assignmentApi.deleteAssignment(id).subscribe({
            next: () => {
              this.assignments = this.assignments.filter(a => a.id !== id);
              this.filterAssignments();
            }
          });
        }
      }
    });
  }

  loadAssignments() {
    this.loading = true;
    this.assignmentApi.getAssignmentsByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.totalItems = page.totalElements;
      this.assignments = page.content.filter(a => a.id !== this.currentAssignmentId);
      this.filteredAssignments = [...this.assignments]; 
      this.extractTechnologies(); 
      this.loading = false;
      this.filterAssignments();
    });

  }

  extractTechnologies() {
    this.technologies = Array.from(new Set(this.assignments.flatMap(a => a.technologies || [])));
  }


  openFilterModal() {
    this.showFilterModal = true;
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal; 
  }

  resetFilter() {
    this.selectedTechnology = ''; 
    this.searchQuery = ''; 
    this.filteredAssignments = [...this.assignments]; 
    this.hasFilter = false; 
  }

  filterAssignments() {
    this.hasFilter = this.selectedTechnology !== '' || this.searchQuery !== ''; 
    const query = this.searchQuery.toLowerCase();
    this.filteredAssignments = this.assignments.filter(assignment =>
      (this.selectedTechnology === '' || assignment.technologies?.includes(this.selectedTechnology)) &&
      (!query || assignment.title.toLowerCase().includes(query))
    );
  }

  applyFilter() {
    this.filterAssignments(); 
    this.toggleFilterModal(); 
  }


  onPageChange(newPage: number) {
    this.currentPage = newPage; 
    this.loadAssignments(); 
  }
  
}
