import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private route: ActivatedRoute, private router: Router) { }

  get returnUrl(): string {
    return this.route.snapshot.queryParams['returnUrl'];
  }

  redirect() {
    const returnUrl: string = this.returnUrl;

    // returnRoute should be the returnUrl without the query params if there are any query params
    const returnRoute = returnUrl ? returnUrl.indexOf('?') > -1 ? returnUrl.substring(0, returnUrl.indexOf('?')) : returnUrl : '/';


    const queryParams = returnUrl ? returnUrl.substring(returnUrl.indexOf('?') + 1) : '';

    const queryParamsObj = queryParams.split('&')
      .map(param => param.split('=', 2))
      .filter(param => param[0] && param[1]) // filter out params with no value or are undefined
      .map(param => ({ [param[0]]: decodeURIComponent(param[1]) }));

    const queryParamsMap = Object.assign({}, ...queryParamsObj);

    this.router.navigate([returnRoute], { queryParams: queryParamsMap });
  }

  redirectToExternal(redirectUri: string, queryParams: { [key: string]: string }) {
    const queryStrings = [];

    for (const key in queryParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (queryParams.hasOwnProperty(key)) {
        queryStrings.push(`${key}=${queryParams[key]}`);
      }
    }

    const constructedQueries = queryStrings.join('&');

    window.location.href = `${redirectUri}?${constructedQueries}`;
  }
}
