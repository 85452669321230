<div class="container-fluid">
  <div class="mb-4 pb-3 border-bottom">
    <h3 class="h3">Cohorts</h3>
    <p class="my-2">
      View and manage all cohorts.
    </p>
    <app-route-card-grid [items]="cohortCardItems"></app-route-card-grid>
  </div>
  <div class="mb-4 pb-3 border-bottom">
    <h3 class="h3">Trainers</h3>
    <p class="my-2">
      View and manage all trainers. Access and update their schedules and availability.
    </p>
    <app-route-card-grid [items]="trainerCardItems"></app-route-card-grid>
  </div>
  <div class="pb-3 mb-4">
    <h3 class="h3">Students</h3>
    <p class="my-2">
      View and manage all students. Assign students to cohorts and view their profile information.
    </p>
    <app-route-card-grid [items]="studentCardItems"></app-route-card-grid>
  </div>
</div>
