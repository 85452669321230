import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SideBarService } from '@core/services/side-bar.service';
import { AuthService } from '@auth/services/auth.service';
import { User, UserRole } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';
import { ApiBuildInfoProperties, AppInfoService } from '@core/services/app-info.service';
import { BuildInfo } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.sass']
})
export class NavBarComponent implements OnInit {

  @ViewChild('buildInfoModal') buildInfoModal?: TemplateRef<any>;
  profilePictureUrl?: string;
  loading = false;
  user?: User;
  apiBuildInfo?: ApiBuildInfoProperties;
  isImpersonating: boolean = false;
  userRole: UserRole | undefined;


  constructor(
    private sidebar: SideBarService,
    private auth: AuthService,
    private appInfo: AppInfoService,
    private modal: NgbModal,
    private userService: UserService) {
      this.userService.isImpersonating$.subscribe((isImpersonating) => {
        this.isImpersonating = isImpersonating;
      });
  }

  get version(): string {
    return this.appInfo.version;
  }

  get isProduction(): boolean {
    return this.appInfo.isProduction;
  }

  get buildInfo(): BuildInfo {
    return this.appInfo.buildInfo;
  }

  toggleSidebar() {
    this.sidebar.toggleSidebar();
  }

  logout() {
    this.auth.logout();
  }

  ngOnInit(): void {
    this.userService.getSelf().subscribe({
      next: (user) => {
        this.user = user;
      }
    });

    this.loading = true;

    this.userService.getProfilePicture().subscribe({
      next: (url) => {
        this.profilePictureUrl = url;
      },
      complete: () => {
        this.loading = false;
      }
    });

    this.appInfo.apiBuildInfo.subscribe({
      next: (buildInfo) => {
        this.apiBuildInfo = buildInfo;
      }
    });

    this.userService.isImpersonatingSubject.subscribe(isImpersonating => {
      this.isImpersonating = isImpersonating;
    });
  }

  openBuildInfoModal() {
    if (!this.isProduction) {
      this.modal.open(this.buildInfoModal, { size: 'lg' });
    }
  }

  exitImpersonation(): void {
    this.userService.stopImpersonation().subscribe({
      next: () => {
        console.log('Impersonation stopped successfully');
      },
      error: (error) => {
        console.error('Error stopping impersonation:', error);
      }
    });
  }

  canExitImpersonate(): boolean {
    return this.isImpersonating;
  }

}
