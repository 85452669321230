import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Discussion } from '../models/discussion.model';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { TechnologyType } from '../models/technologyType';
import { TechnologyTag } from '@app/api/categorization/models/technology-tag.model';

@Injectable({
  providedIn: 'root'
})
export class NewDiscussionService {

  constructor(private httpClient: HttpClient) { }

  publishPost(data:
    {
      postTitle: string, content: string, postTypeId: number, parentPostId: number | null,
      postTypeName: string,
      technologyType: TechnologyType, technologyTags: TechnologyTag[]
    }):
    Observable<Discussion> {
    return this.httpClient.post<Discussion>(`${environment.apiUrl}/discussion/posts`, data);
  }

}
