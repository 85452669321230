import { Component, Input, OnInit } from '@angular/core';
import { ModuleActivityTree } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-module-tree-view',
  templateUrl: './module-tree-view.component.html',
  styleUrls: ['./module-tree-view.component.sass']
})
export class ModuleTreeViewComponent implements OnInit {

  @Input({ required: true }) moduleTree!: ModuleActivityTree;
  @Input() root = true;

  moduleCollapsed = false;

  get moduleIndent() {
    return {
      'padding-left': this.root ? '1rem' : '3rem'
    };
  }

  ngOnInit() {
    // Sort lessons by order
    this.moduleTree.lessons.sort((a, b) => a.order - b.order);
  }

}
