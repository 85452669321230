import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { UserService } from '@app/api/user/services/user.service';
import { Router } from '@angular/router';
import { Student } from '@app/api/student/model/student.model';
import { StudentService } from '@app/api/student/services/student.service';
import { PageParams } from '@app/api/models/page.model';
import { LearningActivityQuery } from '@app/api/learning/models/learning-activity-query.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-my-courses-page',
  templateUrl: './my-learning-page.component.html',
  styleUrls: ['./my-learning-page.component.sass'],
})
export class MyLearningPageComponent implements OnInit {
  @ViewChild('assignmentsPage') assignmentsPage?: TemplateRef<any>;
  @ViewChild('lessonsPage') lessonsPage?: TemplateRef<any>;
  @ViewChild('coursesPage') coursesPage?: TemplateRef<any>;
  @ViewChild('modulesPage') modulesPage?: TemplateRef<any>;
  @ViewChild('quizzesPage') quizzesPage?: TemplateRef<any>;

  profilePictureUrl?: string;
  student?: Student;
  learningActivities: LearningActivity[] = [];
  gradedActivities: LearningActivity[] = [];
  gradedPage = 1;
  totalGradedActivities = 0;

  search = '';
  currentPageOfCourses = 1;
  totalCourses = 0;
  currentPageOfModules = 1;
  totalModules = 0;
  currentPageOfLessons = 1;
  totalLessons = 0;
  currentPageOfAssignments = 1;
  totalAssignments = 0;
  currentPageOfQuizzes = 1;
  totalQuizzes = 0;
  query: LearningActivityQuery & PageParams = {
    page: 0,
    size: 10,
    sort: 'id,desc',
  };
  selectedStudentId!: number;
  loading = false;
  allModules: LearningActivity[] = [];
  allCourses: LearningActivity[] = [];
  allLessons: LearningActivity[] = [];
  allAssignments: LearningActivity[] = [];
  allQuizzes: LearningActivity[] = [];

  constructor(
    private learningActivityService: LearningActivityService,
    private studentService: StudentService,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.userService.getSelf().subscribe((user) => {
      const userId = user.id;
      if (!userId) {
        console.error('User is not logged in');
        return;
      }

      this.userService.fetchProfilePicture(userId).subscribe({
        next: (url) => {
          this.profilePictureUrl = url;
        },
      });

      const studentId = user.studentId;
      this.selectedStudentId = user.studentId;
      if (studentId) {
        this.studentService.getStudentById(studentId).subscribe({
          next: (student) => {
            this.student = student;
          },
        });

        this.loadCourses();
        this.loadModules();
        this.loadLessons();
        this.loadAssignments();
        this.loadQuizzes();
        this.loadGradedAssignments(studentId);
      }
    });
  }

  loadCourses() {
    this.query.page = this.currentPageOfCourses - 1;
    this.loading = true;
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        students: [this.selectedStudentId],
        materialTypes: ['COURSE'],
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.allCourses = learningActivities.content.filter(
          (activity) => !activity.childActivity
        );
        this.loading = false;
        this.currentPageOfCourses = learningActivities.number + 1;
        this.totalCourses = learningActivities.totalElements;
      });
  }

  loadModules() {
    this.query.page = this.currentPageOfModules - 1;
    this.loading = true;
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        students: [this.selectedStudentId],
        materialTypes: ['MODULE'],
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.allModules = learningActivities.content.filter(
          (activity) => !activity.childActivity
        );
        this.loading = false;
        this.currentPageOfModules = learningActivities.number + 1;
        this.totalModules = learningActivities.totalElements;
      });
  }

  loadLessons() {
    this.query.page = this.currentPageOfLessons - 1;
    this.loading = true;
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        students: [this.selectedStudentId],
        materialTypes: ['LESSON'],
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.allLessons = learningActivities.content.filter(
          (activity) => !activity.childActivity
        );
        this.loading = false;
        this.currentPageOfLessons = learningActivities.number + 1;
        this.totalLessons = learningActivities.totalElements;
      });
  }

  loadAssignments() {
    this.loading = true;
    this.query.page = this.currentPageOfAssignments - 1;
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        students: [this.selectedStudentId],
        materialTypes: ['ASSIGNMENT'],
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.allAssignments = learningActivities.content.filter(
          (activity) => !activity.childActivity
        );
        this.loading = false;
        this.currentPageOfAssignments = learningActivities.number + 1;
        this.totalAssignments = learningActivities.totalElements;
      });
  }

  loadQuizzes() {
    this.loading = true;
    this.query.page = this.currentPageOfQuizzes - 1;
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        students: [this.selectedStudentId],
        materialTypes: ['QUIZ'],
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.allQuizzes = learningActivities.content.filter(
          (activity) => !activity.childActivity
        );
        this.loading = false;
        this.currentPageOfQuizzes = learningActivities.number + 1;
        this.totalQuizzes = learningActivities.totalElements;
      });
  }

  loadCoursesPage() {
    this.modalService.open(this.coursesPage, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  loadModulesPage() {
    this.modalService.open(this.modulesPage, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  loadLessonsPage() {
    this.modalService.open(this.lessonsPage, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  loadAssignmentsPage() {
    this.modalService.open(this.assignmentsPage, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  loadQuizzesPage() {
    this.modalService.open(this.quizzesPage, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  onDismissModal(activity: string) {
    if (activity === 'Course') {
      this.currentPageOfCourses = 1;
      this.loadCourses();
    } else if (activity === 'Module') {
      this.currentPageOfModules = 1;
      this.loadModules();
    } else if (activity === 'Lesson') {
      this.currentPageOfLessons = 1;
      this.loadLessons();
    } else if (activity === 'Assignment') {
      this.currentPageOfAssignments = 1;
      this.loadAssignments();
    } else if (activity === 'Quiz') {
      this.currentPageOfQuizzes = 1;
      this.loadQuizzes();
    }
  }

  get courses() {
    return this.allCourses.slice(0, 5);
  }

  get modules() {
    return this.allModules.slice(0, 5);
  }

  get lessons() {
    return this.allLessons.slice(0, 5);
  }

  get assignments() {
    return this.allAssignments.slice(0, 5);
  }

  get quizzes() {
    return this.allQuizzes.slice(0, 5);
  }

  goToLearningActivity(learningActivity: LearningActivity) {
    const type = learningActivity.type;

    let route = type.toLowerCase();
    if (type === 'ASSIGNMENT') {
      route = 'activity';
    }
    this.router.navigate(['/learning', route, learningActivity.id], {
      state: { exitRoute: '/my-learning' },
    });
  }

  loadGradedAssignments(studentId: number) {
    this.learningActivityService
      .getAllLearningActivities({
        graded: true,
        page: this.gradedPage - 1,
        size: 10,
        sort: 'updatedAt,desc',
        students: [studentId],
      })
      .subscribe({
        next: (activities) => {
          this.gradedActivities = activities.content;
          this.totalGradedActivities = activities.totalElements;
          this.gradedPage = activities.number + 1;
        },
      });
  }

  getColorFromScore(score: number) {
    if (score >= 0.75) {
      return 'success';
    } else if (score >= 0.5) {
      return 'warning';
    } else if (score >= 0) {
      return 'danger';
    } else {
      return 'secondary';
    }
  }
}
