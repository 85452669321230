<div class="list-group list-group-flush m-0 p-0" *ngIf="group; else spinner">
  <ng-container *ngFor="let subgroup of group.subgroups">
    <button (click)="subgroupDropdown[subgroup.id] = !subgroupDropdown[subgroup.id]" class="list-group-item list-group-item-action d-flex justify-content-between">
      <span class="me-1">
        <span class="bi text-primary me-1" [ngClass]="{ 'bi-folder-fill': !subgroupDropdown[subgroup.id], 'bi-folder': subgroupDropdown[subgroup.id] }"></span> {{subgroup.name}}
      </span>
      <span class="bi bi-chevron-down text-primary"></span>
    </button>
    <div [ngbCollapse]="!subgroupDropdown[subgroup.id]" class="list-group-item list-group-item-action py-0 mx-0" *ngIf="subgroup.subgroups.length || subgroup.projects.length">
      <div class="ps-4">
        <app-gitlab-group-explorer [group]="subgroup" [(selectedProject)]="selectedProject"></app-gitlab-group-explorer>
      </div>
    </div>
  </ng-container>
  <button *ngFor="let project of group.projects"
          (click)="doAction(project)"
          [class.active]="project.id === selectedProject?.id"
          class="list-group-item list-group-item-action project-item">
    <div class="d-flex flex-column py-2">
      <div class="mb-2">
        <span class="bi bi-code-square text-primary me-1" [class.text-light]="project.id === selectedProject?.id"></span>
        {{project.name}}
        <a [href]="project.url" target="_blank" class="bi bi-box-arrow-up-right text-primary project-link" >
          <span class="visually-hidden">Open in GitLab</span>
        </a>
      </div>
      <span class="small text-muted">{{project.path}}</span>
    </div>
  </button>
</div>

<ng-template #spinner>
  <div class="d-flex justify-content-center align-items-center">
    <div class="my-2 me-2">
      Connecting to GitLab <span class="bi bi-gitlab text-primary"></span>
    </div>
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
