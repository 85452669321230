import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-lesson-home-page',
  templateUrl: './lesson-home-page.component.html',
  styleUrls: ['./lesson-home-page.component.sass']
})
export class LessonHomePageComponent {

  routeItems: RouteCardItem[] = [
    {
      title: 'Create a Lesson',
      description: 'Create a new lesson. Attach files, assignments, and quizzes.',
      icon: 'bi-bookmark-plus',
      routerLink: '/course-composer/lessons/create'
    },
    {
      title: 'Existing Lesson',
      description: 'Continue editing an lesson.',
      icon: 'bi-pencil-square',
      routerLink: '/course-composer/lessons/edit'
    }
  ];

}
