<div class="list-group list-group-flush p-0 m-0 w-100">
  <button type="button"
          [routerLink]="root ? '.' : ['./lesson', lessonTree.id]"
          routerLinkActive="bg-white text-black border-0 border-bottom tab-active-lesson"
          [ngStyle]="lessonIndent"
          class="list-group-item list-group-item-action bg-light py-4 d-flex align-items-center">
    <span class="icon-stack ms-2 me-3">
      <span class="bi bi-bookmark-fill text-success me-2 fs-4"></span>
      <span *ngIf="!root" class="icon-text text-white pb-1">{{lessonTree.order + 1}}</span>
    </span>

    <div class="flex-grow-1 mx-1">
      <div class="text-muted x-small">
        Lesson
      </div>
      {{lessonTree.title}}
    </div>

    <button type="button"
            click-stop-propagation
            class="btn btn-sm btn-outline-success border-0 ms-auto me-2"
            (click)="lessonCollapsed = !lessonCollapsed">
      <span class="bi bi-chevron-up transitioned d-inline-block" [class.transform-rotate-180]="lessonCollapsed"></span>
    </button>
  </button>
  <div class="list-group-item p-0 m-0" [ngbCollapse]="lessonCollapsed">
    <div class="list-group list-group-flush me-0">
      <button *ngFor="let assignment of lessonTree.assignments"
              [routerLink]="['./assignment', assignment.id]"
              routerLinkActive="bg-white text-black border-0 border-bottom fw-bold"
              type="button"
              [ngStyle]="assignmentIndent"
              class="list-group-item list-group-item-action bg-light text-black py-3 d-flex align-items-center">
        <span class="icon-stack me-3">
          <span class="bi bi-file-earmark-text text-info me-2 fs-4"></span>
          <span class="icon-badge text-white">
            <span class="badge bg-info rounded-circle">{{assignment.order + 1}}</span>
          </span>
        </span>
        <div class="flex-grow-1">
          <div class="x-small text-muted">
            Assignment
          </div>
          {{assignment.title}}
        </div>
      </button>
    </div>
  </div>
</div>
