<div class="w-100 vh-100 bg-launch-your-career">
  <div *appIfBreakpoint="'lg'; else mobileForm" class="d-none d-lg-inline-block container-fluid h-100">
    <div class="row h-100 justify-content-end">
      <div class="col-md-4 col-lg-3 bg-white">
        <div class="p-4">
          <ng-container *ngTemplateOutlet="mainContent"></ng-container>
        </div>
        <div class="position-absolute bottom-0 end-0">
          <div class="text-end small text-muted m-2">
            <div class="text-warning my-2" *ngIf="!isProduction">
              Non-production build
            </div>
            <div class="my-2">
              Client v{{ version }}
            </div>
            Smoothstack Learn &copy; 2023
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #mobileForm>
    <div class="d-flex justify-content-center align-items-center w-100 h-100 ">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-7">
            <div class="card">
              <div class="card-body">
                <div class="pb-5">
                  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
                </div>
                <div class="text-end small text-muted">
                  <div class="text-warning" *ngIf="!isProduction">
                    Non-production build
                  </div>
                  <div class="my-2">
                    Client v{{ version }}
                  </div>
                  Smoothstack Learn &copy; 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #mainContent>

  <div class="mb-5">
    <div class="d-flex flex-column justify-content-center align-items-center my-5">
      <div class="row justify-content-center">
        <a class="d-block col-6" routerLink="/">
          <img [priority]="true" ngSrc="assets/logos/logo-header.png" alt="logo" class="img-fluid" height="300" width="500">
        </a>
      </div>
    </div>
    <ng-content></ng-content>
  </div>

</ng-template>
