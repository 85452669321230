import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LearningMaterialData } from '@app/api/models/learning-material-data.model';
import { User } from '@app/api/user/models/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { CreateLearningActivityRequest, LearningActivity } from '@app/api/models/learning-activity.model';
import { GlobalModalService } from '@core/services/global-modal.service';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { Cohort } from '@app/api/models/cohort.model';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';
import { Student } from '@app/api/student/model/student.model';

@Component({
  selector: 'app-assign-learning-material-modal-button',
  templateUrl: './assign-learning-material-modal-button.component.html',
  styleUrls: ['./assign-learning-material-modal-button.component.sass']
})
export class AssignLearningMaterialModalButtonComponent {

  @Input() content: LearningMaterialData | null = null;
  @ContentChild(TemplateRef) template?: TemplateRef<any>;
  @ViewChild('assignModal') assignModal?: TemplateRef<any>;

  @Input() selectedUsers: User[] = [];
  @Input() selectedCohorts: Cohort[] = [];

  assigning = false;

  context: any = {
    openModal: () => this.openModal(),
  };

  constructor(
    private modal: NgbModal,
    private globalModal: GlobalModalService,
    private userNotificationService: UserNotificationService,
    private modalService: GlobalModalService,
    private http: HttpClient,
    private api: ApiService) { }

  openModal() {
    this.modal.open(this.assignModal, { size: 'xl' });
  }

  closeModal() {
    this.selectedUsers = [];
    this.selectedCohorts = [];
    this.modal.dismissAll();
  }

  assign() {
    if (this.content) {
      this.assigning = true;
  
      const selectedUserIds = this.selectedUsers.map(u => u.id);
      const selectedCohortIds = this.selectedCohorts.filter(c => c.id).map(c => c.id!);
  
      this.checkExistingAssignments(selectedUserIds, this.content.id!, this.content!.materialType!)
        .pipe(
          switchMap(existingAssignedStudents => {
            if (existingAssignedStudents.length > 0) {
              const assignedUsernames = existingAssignedStudents.map(u => `${u.firstName} ${u.lastName}`).join(', ');

              return this.modalService.confirm(
                `The following users already have this material assigned: ${assignedUsernames}. Do you want to assign it again?`,
                {
                  okButtonText: 'Yes, Assign Anyway',
                  cancelButtonText: 'No, Cancel',
                  type: 'warning',
                  dismissable: true,
                }
              ).pipe(
                switchMap((confirmed) => {
                  if (!confirmed) {
                    this.assigning = false;
                    return of([]);
                  }
  
                  return this.assignMaterial({
                    type: this.content!.materialType!,
                    materialId: this.content!.id!,
                    userIds: selectedUserIds,
                    cohortIds: selectedCohortIds,
                    dueDate: new Date(),
                  });
                })
              );
            }
  
            return this.assignMaterial({
              type: this.content!.materialType!,
              materialId: this.content!.id!,
              userIds: selectedUserIds,
              cohortIds: selectedCohortIds,
              dueDate: new Date(),
            });
          }),
  
          catchError(err => {
            console.error(err);
            this.assigning = false;
            return of({ error: err });
          })
        )
        .subscribe({
          next: (activities) => {
            if (Array.isArray(activities)) {
              if (activities.length === 0) {
                return;
              }
              this.globalModal.icon(
                `Successfully assigned the ${this.content?.materialType?.toLowerCase()}`,
                'bi-check-circle-fill text-success',
                {
                  okButtonCallback: () => this.closeModal(),
                }
              );
              this.userNotificationService.updateNotifications();
              this.assigning = false;
            } else if (activities.error) {
              this.closeModal();
              this.globalModal.alert({
                type: 'danger',
                title: 'Error',
                content: activities.error?.message || 'An error occurred while assigning the material.',
              });
            }
          },
          error: (err) => {
            console.error(err);
            this.assigning = false;
            this.globalModal.alert({
              type: 'danger',
              title: 'Error',
              content: err.message || 'An unexpected error occurred during assignment.',
            });
          }
        });
    }
  }
  
  checkExistingAssignments(userIds: number[], materialId: number, type: string): Observable<Student[]> {
    const params = new HttpParams()
      .set('selectedUserIds', userIds.join(','))
      .set('type', type); 
  
    return this.http.get<Student[]>(this.api.endpoint(`/learning-activities/${materialId}/assigned-students`), { params });
  }  

  assignMaterial(request: CreateLearningActivityRequest) {
    return this.http.post<LearningActivity[]>(this.api.endpoint('/learning-activities'), request);
  }


}
