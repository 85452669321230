import { Component, Input } from '@angular/core';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { AssignmentService } from '@app/api/assignment/services/assignment.service';
import { AuthService } from '@auth/services/auth.service';
import { UserRole } from '@app/api/user/models/user.model';
import { GitLabProject } from '@app/api/integration/gitlab/models/gitlab-data.model';

@Component({
  selector: 'app-assignment-preview',
  templateUrl: './assignment-preview.component.html',
  styleUrls: ['./assignment-preview.component.sass']
})
export class AssignmentPreviewComponent  {

  @Input() data?: AssignmentData;
  showSolution = false;
  isStaff = false;


  constructor(private assignmentService: AssignmentService, private auth: AuthService) {
    this.auth.hasAnyRole([UserRole.Training_Assistant, UserRole.Trainer, UserRole.Admin])
      .subscribe(isStaff => this.isStaff = isStaff);
  }

  get hasOverviewOrContent() {

    const overview = this.assignmentService.parseContent('overview', this.data?.content || '');
    const content = this.assignmentService.parseContent('content', this.data?.content || '');

    return overview || content;
  }

  solutionToGitLabProject(solutionJson?: string): GitLabProject {
    if (!solutionJson) {
      return {
        id: 0,
        name: '',
        url: '',
        path: '',
        nameWithNamespace: '',
        repoUrl: ''
      };
    }
    try {
      return JSON.parse(solutionJson) as GitLabProject;
    } catch (e) {
      return {
        id: 0,
        name: '',
        url: '',
        path: '',
        nameWithNamespace: '',
        repoUrl: ''
      };
    }
  }

}
