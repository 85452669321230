import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KeyBindingService } from '@core/services/key-binding.service';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { Cohort } from '@app/api/models/cohort.model';

@Component({
  selector: 'app-cohort-select-field',
  templateUrl: './cohort-select-field.component.html',
  styleUrls: ['./cohort-select-field.component.sass']
})
export class CohortSelectFieldComponent {
  @ViewChild('searchInput') searchInput?: any;
  @Input() placeholder = 'Search for cohorts...';
  searchForm: FormGroup;
  cohortResults: Cohort[] = [];
  searchInputFocused = false;
  showDropdown = false;
  searchRequestTimeout: any;
  searching = false;

  @Input() disabled = false;

  @Input() selectedCohorts: Cohort[] = [];
  @Output() selectedCohortsChange = new EventEmitter<Cohort[]>();
  @Input() maxCohorts = 0;

  constructor(
    private fb: FormBuilder,
    private keyBindingService: KeyBindingService,
    private cohortApi: CohortApiService) {

    this.searchForm = this.fb.group({
      search: [''],
    });

    this.keyBindingService.registerKeyBind('backspace', () => {
      if (this.searchForm.get('search')?.value.length === 0) {
        this.selectedCohorts.pop();
      }
    });

    this.searchForm.valueChanges.subscribe({
      next: (value) => {
        const { search } = value;
        this.showDropdown = search.length > 1;
        this.searching = true;

        if (this.searchRequestTimeout) {
          clearTimeout(this.searchRequestTimeout);
        }

        this.searchRequestTimeout = setTimeout(() => {
          if (this.showDropdown) {
            this.cohortApi.getAllCohorts(search, {
              size: 5
            }).subscribe({
              next: (users) => {
                this.cohortResults = users.content.filter((u) => {
                  return !this.selectedCohorts.find((su) => su.id === u.id);
                });
                this.searching = false;
              },
              complete: () => {
                this.searching = false;
              }
            });
          }
        }, 250);
      }
    });

  }

  addCohort(cohort: Cohort) {

    if (this.maxCohorts > 0 && this.selectedCohorts.length >= this.maxCohorts) {
      return;
    }

    this.selectedCohorts.push(cohort);
    const searchField = this.searchForm.get('search');
    searchField?.setValue('');
    this.focusSearchInput();
    this.showDropdown = false;
    this.selectedCohortsChange.emit(this.selectedCohorts);
  }

  deleteCohort(cohort: Cohort) {
    this.selectedCohorts = this.selectedCohorts.filter((u) => u.id !== cohort.id);
    this.selectedCohortsChange.emit(this.selectedCohorts);
  }

  focusSearchInput() {
    this.searchInput?.nativeElement.focus();
  }
}
