<div class="d-flex flex-wrap align-items-baseline my-3">
 <ng-container *ngFor="let segment of processQuestionText()">
   <div *ngIf="isQuestionBlank(segment); else segmentText" class="mx-1 d-flex align-items-center">
     <ng-container *ngIf="showValidity">
       <span *ngIf="isCorrect(segment); else incorrect" class="bi bi-check2 text-success"></span>
       <ng-template #incorrect>
         <span class="bi bi-x text-danger"></span>
       </ng-template>
     </ng-container>
     <input [(ngModel)]="value[getBlankId(segment)]"
            (ngModelChange)="onChange(value)"
            [disabled]="isDisabled"
            type="text"
            class="form-control"
            (focusout)="onTouched()"
            [ngStyle]="{
              width: segment.length * 0.6 + 'em',
             }"/>
   </div>
   <ng-template #segmentText>
     <div class="mx-1">{{segment}}</div>
   </ng-template>
 </ng-container>
</div>
