import { Injectable } from '@angular/core';
import { CreateBulkQuestionRequest } from '@app/api/question/models/create-question-request.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BulkQuestionFileProcessorService {

  constructor() { }

  processFile(file: File) {
    if (!file) {
      throw new Error('File is not present.');
    }
    const extension = file.name.split('.').pop();
    switch(extension) {
      case 'csv':
        throw new Error('Not implemented');
      case 'xlsx':
        throw new Error('Not implemented');
      case 'json':
        return this.processJsonFile(file);
      default:
        throw new Error('Invalid file type');
    }
  }

  private processJsonFile(file: File): Observable<CreateBulkQuestionRequest> {
    return new Observable<CreateBulkQuestionRequest>(subscriber => {
      const reader = new FileReader();
      reader.onload = () => {
        const json = JSON.parse(reader.result as string);
        if (this.isCreateBulkQuestionRequest(json)) {
          subscriber.next(json);
        } else {
          subscriber.error('Invalid questions format.');
        }
      };
      reader.readAsText(file);
    });
  }

  private isCreateBulkQuestionRequest(json: any): json is CreateBulkQuestionRequest {
    return json.questions !== undefined && Array.isArray(json.questions);
  }

}
