<div class="list-group list-group-flush p-0 m-0 w-100">
  <button type="button" class="list-group-item bg-light list-group-item-action py-4 d-flex align-items-center border-end" (click)="moduleCollapsed = !moduleCollapsed" [style]="moduleIndent">
    <span class="icon-stack ms-1 me-3">
      <span class="bi bi-folder-fill text-info fs-4"></span>
      <span *ngIf="!root" class="icon-text text-white">{{moduleTree.order + 1}}</span>
    </span>
    <span class="flex-grow-1 mx-1">
      <div class="x-small text-muted">
        Module
      </div>
      {{moduleTree.title}}
    </span>
    <span class="bi bi-chevron-up d-inline-block transitioned text-primary" [class.transform-rotate-180]="moduleCollapsed"></span>
  </button>
  <div [(ngbCollapse)]="moduleCollapsed" class="list-group-item p-0 m-0">
    <div class="list-group list-group-flush p-0 m-0">
      <div class="list-group-item p-0 m-0" *ngFor="let lessonTree of moduleTree.lessons">
        <app-lesson-tree-view [lessonTree]="lessonTree" [root]="false"></app-lesson-tree-view>
      </div>
    </div>
  </div>
</div>
