<div class="list-group list-group-flush" *ngIf="moduleData; else spinner">

  <ng-container *ngIf="moduleData.title">
    <button type="button"
            (click)="lessonsCollapse = !lessonsCollapse"
            class="list-group-item list-group-item-action">
      <div class="d-flex justify-content-between" [class.ps-3]="indent">
        <div class="me-2">
          <div class="d-flex">
            <span class="bi bi-folder-fill text-info me-1"></span>
            <div>
              <div class="fw-bold">{{moduleData.title}}</div>
              <div *ngIf="moduleData.technologies && moduleData.technologies.length" class="mb-1">
                <span class="badge bg-info rounded-pill small me-1" *ngFor="let techTag of moduleData.technologies">
                  {{techTag}}
                </span>
              </div>
              <div class="d-none d-md-block text-muted small text-wrap">
                {{moduleData.description}}
              </div>
            </div>
          </div>

        </div>
        <span *ngIf="moduleData.lessons && moduleData.lessons.length" class="bi bi-chevron-down align-self-center"></span>
      </div>
    </button>

    <div class="list-group-item p-0 m-0 list-group-item-light">
      <div class="list-group list-group-flush" [ngbCollapse]="lessonsCollapse">
        <li *ngFor="let lesson of moduleData.lessons" class="list-group-item py-3 pe-0">
          <div class="d-flex justify-content-between ps-3" [class.ps-5]="indent">
            <div class="me-2">
              <div class="d-flex">
                <span class="bi bi-bookmark-fill text-success me-1"></span>
                <div>
                  <div class="fw-bold"><a class="link text-body" (click)="openLessonPreview(lesson)">{{ lesson.title }}</a></div>
                  <div *ngIf="lesson.technologies && lesson.technologies.length" class="mb-1">
                    <span class="badge bg-info rounded-pill small me-1" *ngFor="let techTag of lesson.technologies">
                      {{techTag}}
                    </span>
                  </div>
                  <div class="d-none d-md-block text-muted small text-wrap">
                    {{lesson.description | truncated: 50}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li *ngFor="let quiz of moduleData.quizzes" class="list-group-item py-3 pe-0">
          <div [class.ps-5]="indent" class="d-flex justify-content-between ps-3">
            <div class="me-2">
              <div class="d-flex">
                <span class="bi bi-bookmark-fill text-success me-1"></span>
                <div>
                  <div class="fw-bold"><a (click)="openQuizPreview(quiz)" class="link text-body">{{ quiz.title }}</a>
                  </div>
                  <div *ngIf="quiz.technologies && quiz.technologies.length" class="mb-1">
                    <span *ngFor="let techTag of quiz.technologies" class="badge bg-info rounded-pill small me-1">
                      {{ techTag }}
                    </span>
                  </div>
                  <div class="d-none d-md-block text-muted small text-wrap">
                    {{ quiz.description | truncated: 50 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #spinner>
  <div class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      Preview {{ lesson ? 'Lesson' : 'Quiz' }}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <app-quiz-preview *ngIf="quiz" [data]="quiz"></app-quiz-preview>
      <app-lesson-preview *ngIf="lesson" [lessonData]="lesson"></app-lesson-preview>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close()">Close Preview</button>
  </div>
</ng-template>
