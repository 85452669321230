import { Component } from '@angular/core';

@Component({
  selector: 'app-quiz-creator-page',
  templateUrl: './quiz-creator-page.component.html',
  styleUrls: ['./quiz-creator-page.component.sass']
})
export class QuizCreatorPageComponent {

}
