import { Component, Input, OnInit } from '@angular/core';
import { CourseActivityTree, ModuleActivityTree } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-course-tree-view',
  templateUrl: './course-tree-view.component.html',
  styleUrls: ['./course-tree-view.component.sass']
})
export class CourseTreeViewComponent implements OnInit {

  @Input({ required: true }) courseTree!: CourseActivityTree;
  @Input() root = true;

  courseCollapsed = false;

  get courseIndent() {
    return {
      'padding-left': this.root ? '1.5rem' : '2rem'
    };
  }

  ngOnInit() {
    // Sort modules by order
    this.courseTree.modules.sort((a, b) => a.order - b.order);
  }

}
