<div class="my-2">
  <h1>{{data?.title}}</h1>
  <div class="d-flex flex-wrap gap-1 align-items-center mb-3">
    <span *ngFor="let technology of data?.technologies"
          class="badge rounded-pill bg-primary">{{technology}}</span>
  </div>
  <p class="mb-3">{{data?.description}}</p>
</div>
<app-markdown-viewer *ngIf="hasOverviewOrContent" [data]="data?.content || ''"></app-markdown-viewer>

<div *ngIf="data && data.submissionTypes" class="mt-4 mb-5">
  <h3 class="mb-2" *ngIf="data.submissionTypes.length">Completion</h3>
  <app-submission-preview *ngFor="let type of data?.submissionTypes" [submissionType]="type"></app-submission-preview>
</div>

<div class="mb-3" *ngIf="data && data.rubric">
  <app-rubric-preview [rubric]="data.rubric"></app-rubric-preview>
</div>

<ng-container *ngIf="isStaff && data && data.solution && data.solution.solution">
  <hr/>

  <div class="my-5">
    <button type="button" class="btn btn-primary w-100 mb-5" (click)="showSolution = !showSolution">
      Reveal Solution <span class="bi bi-check-circle"></span>
    </button>
    <div [ngbCollapse]="!showSolution" class="my-2">
      <div class="card card-body py-3">
        <div *ngIf="data.solution.type! === 'GITLAB_REPOSITORY' && solutionToGitLabProject(data.solution.solution).url !== ''">
          <div class="py-2 ps-2 d-flex align-items-center">
            <div class="bi bi-file-earmark-code-fill text-primary me-3 fs-4"></div>
            <div class="text-start">
              <div class="mb-2">
                <div class="fw-bold">
                  {{solutionToGitLabProject(data.solution.solution).name}}
                  <a [href]="solutionToGitLabProject(data.solution.solution).url" target="_blank" class="ms-2 bi bi-box-arrow-up-right" ngbTooltip="Open Project in GitLab">
                    <span class="visually-hidden">Open Project</span>
                  </a>
                </div>
                <div class="text-muted small">{{solutionToGitLabProject(data.solution.solution).path}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data.solution.type === 'TEXT'">
          <app-markdown-viewer [data]="data.solution.solution"></app-markdown-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-container>
