import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { MessageResponse } from '../models/MessageResponse.model';
import { MessageRequest } from '../models/MessageRequest.model';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  constructor(private http: HttpClient) {}

  getInbox(page: number, size: number, userId?: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<any>(
      `${environment.apiUrl}/messages/inbox/${userId}`,
      { params }
    );
  }

  getChat(chatId: number, page: number, size: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<any>(`${environment.apiUrl}/messages/chat/${chatId}`, {
      params,
    });
  }

  sendMessage(messageRequest: MessageRequest): Observable<MessageResponse> {
    const url = `${environment.apiUrl}/messages`;
    return this.http.post<MessageResponse>(url, messageRequest);
  }

  deleteChat(chatId: number): Observable<string> {
    const url = `${environment.apiUrl}/messages/chat/${chatId}`;
    return this.http.delete(url, { responseType: 'text' });
  }
}
