<div class="d-flex flex-column align-items-center p-0 m-0 h-100 bg-light">
  <button *ngFor="let sideBarItem of items"
          class="btn btn-link sidebar-item m-0 rounded-0 w-100 text-decoration-none text-black"
          [routerLink]="[sideBarItem.routerLink]"
          routerLinkActive="active-sidebar-item"
          placement="right"
          [ngbTooltip]="sideBarItem.label"
          [disableTooltip]="sidebarExpanded">
    <span class="d-flex flex-row justify-content-between">
      <span [ngClass]="{ 'sidebar-expanded': sidebarExpanded }" class="my-2 text-start sidebar-text">
        {{sideBarItem.label}}
      </span>
      <span [ngClass]="[ 'bi', 'mx-3 my-2', sideBarItem.icon, 'text-muted' ]"></span>
    </span>
  </button>
</div>

