import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.auth.authenticated()) {
      const authenticatedRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.auth.getToken()}`)
      });
      return next.handle(authenticatedRequest);
    }

    return next.handle(request);
  }
}
