import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordResetService } from '@app/api/user/services/password-reset.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.sass']
})
export class ForgotPasswordFormComponent {

  submitting = false;
  submitted = false;

  formGroup: FormGroup;

  constructor(private fb: FormBuilder, private passwordReset: PasswordResetService) {
    this.formGroup = this.fb.group({
      email: ['', [
        Validators.email,
        Validators.required
      ]]
    });
  }

  sendPasswordResetEmail() {
    this.submitting = true;
    this.passwordReset.initiatePasswordReset(this.formGroup.value.email).subscribe({
      next: () => {
        this.submitted = true;
      },
      error: () => {
        this.submitted = false;
        this.submitting = false;
        throw new Error('Unable to send password reset email');
      },
      complete: () => {
        this.submitting = false;
      }
    });
  }

}
