<div class="mx-2" *ngIf="notification">
  <div class="fw-bold text-primary">{{ getNotificationTypeLabel(notification.type)}}</div>
  <h4 class="h4">{{ notification.title }}</h4>

  <p class="mb-3" [innerHTML]="notification.message | markdown"></p>

  <div class="mb-3" *ngIf="regardingLearningActivity">
    <button type="button" class="btn btn-sm btn-outline-primary" routerLink="/my-learning">
      Go to My Learning
    </button>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-sm btn-outline-danger" (click)="deleteNotification(notification.id)">Delete</button>
  </div>
</div>
