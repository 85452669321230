import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { Title } from '@angular/platform-browser';
import { OidcService } from '@core/services/oidc.service';
import { RedirectService } from '@core/services/redirect.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.sass']
})
export class LoginPageComponent implements OnInit {

  ssoClientName = '';

  constructor(private title: Title,
    private auth: AuthService,
    private redirect: RedirectService,
    private oidc: OidcService) {
  }

  get ssoMode() {
    return this.oidc.ssoMode;
  }

  ngOnInit(): void {
    if (this.oidc.ssoMode) {
      this.title.setTitle('Sign in with Smoothstack Learn');

      if (this.oidc.hasClientId) {
        this.oidc.getClientName(this.oidc.clientId).subscribe(clientName => this.ssoClientName = clientName);
      }
    }

    this.auth.tokenSaved.subscribe(() => {
      this.redirect.redirect();
    });
  }

}
