import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Page } from '@app/api/models/page.model';
import { TechnologyTag } from '@app/api/categorization/models/technology-tag.model';
import { map, Observable } from 'rxjs';
import { NamedEntity } from '@app/api/models/named-entity.model';
import { TechnologyType } from '@app/api/discussion/models/technologyType';


@Injectable({
  providedIn: 'root'
})
export class CategorizationService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getTechnologyTags(search: string, size = 10): Observable<Page<TechnologyTag>> {
    return this.http.get<Page<TechnologyTag>>(this.api.endpoint('categorization/technologies'), {
      params: {
        search,
        size: size.toString()
      }
    });
  }

  getTechnologyTagsById(id: number[]): Observable<TechnologyTag[]> {
    return this.http.get<TechnologyTag[]>(this.api.endpoint('categorization/technologies'), {
      params: {
        id: id.join(','),
        size: id.length.toString(),
        sort: 'name,asc'
      }
    });
  }

  get smoothstackClientTags(): Observable<NamedEntity[]> {
    return this.http.get<NamedEntity[]>(this.api.endpoint('categorization/clients'));
  }

  getTechnologyTypes(): Observable<TechnologyType[]> {
    return this.http.get<TechnologyType[]>(this.api.endpoint('categorization/technology-types'));
  }

}
