<form [formGroup]="quizForm" class="my-3" (submit)="onSubmitQuiz()">

  <div class="card card-body my-3">
    <div class="mb-3" *ngIf="mode === 'create'">
      <h4 class="h4">Quiz Details</h4>
      <p class="text-muted small">Specify the Quiz's title, description, as well as questions.</p>
    </div>

    <div *ngIf="mode === 'edit'">
      <h4 class="h4">Edit Quiz</h4>
    </div>

    <div class="my-3">
      <app-text-field controlName="title" label="Title"></app-text-field>
    </div>

    <div class="my-3">
      <app-text-field controlName="description" label="Description" [maxLength]="250" [showCharacterCount]="true"></app-text-field>
    </div>
  </div>

  <div class="card card-body my-3">

    <div class="d-flex justify-content-between align-items-start">
      <div>
        <h4 class="h4">Questions</h4>
        <p class="text-muted small">List the questions that will be part of this Quiz.</p>
      </div>
      <div class="d-flex align-items-center mt-2">
        <div class="form-check me-3">
          <input class="form-check-input" type="checkbox" id="randomizeQuestions" formControlName="randomizeQuestions"
            (change)="toggleRandomize()">
          <label class="form-check-label" for="randomizeQuestions">Randomize Questions</label>
        </div>
        <button type="button" class="btn btn-primary" [disabled]="saving" (click)="openAddNewQuestionModal()">
          <span class="bi bi-person-plus"></span>
          New Question
        </button>
        <span class="text-muted mx-3">or</span>
        <button type="button" class="btn btn-primary" [disabled]="saving" (click)="openAddExistingQuestionsModal()">
          <span class="bi bi-person-lines-fill"></span>
          Existing Question
        </button>
      </div>
    </div>

    <ul *ngIf="quizQuestions && quizQuestions.length; else noQuestions" cdkDropList
      (cdkDropListDropped)="onMoveItem($event)" [cdkDropListData]="quizQuestions"
      class="list-group list-group-flush rounded card card-body mb-2">
      <li cdkDrag [cdkDragPreviewContainer]="previewContainer"
        *ngFor="let quizQuestion of quizQuestions; let index = index"
        class="list-group-item list-group-item-action px-0 bg-white question-item">
        <div class="d-flex align-items-center">
          <button type="button" class="drag-drop-handle" cdkDragHandle>
            <app-icon iconName="six-dots" class="svg-muted"></app-icon>
          </button>
          <div class="flex-shrink-1 px-3">
            <span class="badge bg-primary rounded-circle">{{ quizQuestion.order + 1}}</span>
          </div>
          <div class="me-2 w-100">
            <div class="mb-2">
              <div class="fw-bold">{{quizQuestion.questionData.title}}</div>
              <div class="d-flex gap-1 flex-wrap">
                <span *ngFor="let technology of quizQuestion.questionData.technologies"
                  class="badge bg-info rounded-pill">{{technology}}</span>
              </div>
            </div>
            <div class="text-muted small text-wrap">
              {{quizQuestion.questionData.description}}
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center gap-1 w-100 pe-3 flex-shrink-1">
            <div class="form-check me-2" *ngIf="quiz.randomizeQuestions">
              <input class="form-check-input" type="checkbox" [checked]="quizQuestion.alwaysInclude"
                (change)="toggleAlwaysInclude(index)" id="alwaysIncludeCheckbox{{index}}">
              <label class="form-check-label" for="alwaysIncludeCheckbox{{index}}">
                Always Include
              </label>
            </div>
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="previewQuestion(index)">
              <span class="bi bi-eye"></span>
              <span class="visually-hidden">Preview Question</span>
            </button>
            <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeQuestion(index)">
              <span class="bi bi-trash"></span>
              <span class="visually-hidden">Remove from Quiz</span>
            </button>
          </div>
        </div>
      </li>
    </ul>

  </div>

  <div class="my-3 text-end">
    <button class="btn btn-outline-primary me-2" type="button" (click)="openQuizPreviewModal()">
      <span class="bi bi-eyebi bi-eye"></span> Preview
    </button>
    <button *ngIf="mode === 'edit'" class="btn btn-danger me-2" type="button" [disabled]="saving"
      (click)="openDeleteQuizModal()">
      Delete <span class="bi bi-trash"></span>
    </button>
    <button *ngIf="mode === 'edit'" class="btn btn-primary" type="button" [disabled]="saving" (click)="updateQuiz()">
      Update
    </button>
    <button *ngIf="mode === 'create'" class="btn btn-primary" type="submit"
      [disabled]="quizForm.invalid || !quizQuestions.length">
      Save <span [ngClass]="{ 'bi bi-floppy': !saving, 'spinner-border spinner-border-sm': saving }"></span>
    </button>
  </div>

</form>

<ng-template #noQuestions>
  <div class="my-3">
    <div class="card card-body">
      <div class="d-flex justify-content-center align-items-center text-center text-muted">
        No Questions...
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addNewQuestionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <span class="bi bi-person-lines-fill text-primary"></span>
      Add New Question
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body" [formGroup]="newQuestionForm">
    <app-question-form (questionCreated)="onAddQuestion($event)" type="quiz"></app-question-form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
  </div>
</ng-template>

<ng-template #addExistingQuestionsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Questions to Quiz</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <app-question-selector-for-quiz [initialSelectedQuestions]="selectedQuestions"
      (selectedQuestionsChanged)="handleSelectedQuestions($event)"></app-question-selector-for-quiz>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="selectedQuestions.length === 0"
      (click)="addExistingQuestion(); modal.close()">Add Questions</button>
  </div>
</ng-template>

<ng-template #questionPreview let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Preview Question</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-question-preview *ngIf="currentQuestion" [data]="currentQuestion"></app-question-preview>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #quizPreview let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Quiz Preview</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="mx-2">
      <h1>{{quiz.title}}</h1>
      <div class="d-flex flex-row gap-1 align-items-center mb-3">
        <span *ngFor="let technology of quiz?.technologies" class="badge rounded-pill bg-primary">{{technology}}</span>
      </div>
      <p class="mb-3">{{quiz.description}}</p>
      <ul class="list-group list-group-flush">
        <li *ngFor="let quizQuestion of quizQuestions; let i = index" class="list-group-item">
          <h5>Question {{ i + 1 }}</h5>
          <app-question-preview [data]="quizQuestionPreviews[i]"></app-question-preview>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Close Preview</button>
  </div>
</ng-template>