import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Inbox } from '../../models/Inbox.model';
import { InboxService } from '../../services/InboxService.service';
import { MessageResponse } from '../../models/MessageResponse.model';
import { MessageRequest } from '../../models/MessageRequest.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { User } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';

@Component({
  selector: 'app-view-inbox',
  templateUrl: './view-inbox.component.html',
  styleUrls: ['./view-inbox.component.sass'],
})
export class ViewInboxComponent implements OnInit {
  @ViewChild('deleteChatModal') deleteChatModal!: TemplateRef<any>;

  inboxList: Inbox[] = [];
  page = 0;
  size = 10;
  messages: MessageResponse[] = [];
  messageRequest: MessageRequest = {};
  showChatBox: boolean = false;
  currentUserId: any;
  deletedMessage!: string;
  deletedChat!: string;
  currentReceiver?: User;
  profilePictureUrl?: string;
  loading = false;
  selectedRow?: number;

  constructor(
    private inboxService: InboxService,
    private modalService: GlobalModalService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getSelf().subscribe({
      next: (user) => {
        this.currentUserId = user.id;
        this.loadInbox(this.page, this.size, this.currentUserId);
      },
    });
  }

  loadInbox(page: number, size: number, userId?: number) {
    this.currentUserId = this.userService.currentUser?.id;

    this.inboxService.getInbox(page, size, userId).subscribe(
      (data) => {
        this.currentUserId = this.userService.currentUser?.id;
        this.inboxList = data.content;
      },
      (error) => {
        console.error('Error fetching inbox:', error);
      }
    );
  }

  loadMessages(chatId: number) {
    this.inboxService
      .getChat(chatId, this.page, this.size)
      .subscribe((messages) => {
        this.messages = messages.content;
      });
    this.loadInbox(this.page, this.size, this.userService.currentUser?.id);
  }

  selectRow(selectedRow: number) {
    this.selectedRow = selectedRow;
  }

  getProfilePicture(id?: number) {
    this.loading = true;
    id = this.currentUserId == id ? this.currentUserId : id;
    this.userService.getProfilePicture(id).subscribe({
      next: (url) => {
        return url;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  onAddChat() {
    this.loadInbox(this.page, this.size, this.userService.currentUser?.id);
  }

  updateReceiverId(inbox: Inbox) {
    this.showChatBox = true;
    if (inbox.receiver.id === this.userService.currentUser?.id) {
      this.messageRequest.receiverId = inbox.sender.id;
      this.currentReceiver = inbox.sender;
    } else {
      this.messageRequest.receiverId = inbox.receiver.id;
      this.currentReceiver = inbox.receiver;
    }
  }

  sendMessage(): void {
    this.inboxService.sendMessage(this.messageRequest).subscribe((response) => {
      let messageResponse: MessageResponse = {
        messageId: response.messageId,
        content: response.content,
        receiver: response.receiver,
        sender: response.sender,
      };
      this.messages.push(messageResponse);
      this.messageRequest.content = '';
    });
    this.loadInbox(this.page, this.size, this.userService.currentUser?.id);
  }

  openDeleteChatDialog(inbox: Inbox, index: number) {
    this.deletedChat =
      this.currentUserId === inbox.receiver.id
        ? `${inbox.sender.firstName} ${inbox.sender.lastName}`
        : `${inbox.receiver.firstName} ${inbox.receiver.lastName}`;

    this.modalService
      .alert({
        title: 'Delete Chat',
        content: this.deleteChatModal,
        type: 'danger',
        showCancelButton: true,
        okButtonText: 'Delete',
      })
      .closed.subscribe({
        next: () => {
          this.deleteChat(inbox.chatId, index);
        },
      });
  }

  deleteChat(chatId: number, index: number): void {
    this.inboxService.deleteChat(chatId).subscribe((response) => {
      this.inboxList.splice(index, 1);
      this.messages = [];
      this.showChatBox = false;
    });
  }
}
