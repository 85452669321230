<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="w-100 my-4">
        <h3 class="text-center">
          Notification Center
        </h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="my-2 px-1 d-flex justify-content-between">
        <span class="small text-muted">
          Unread ({{ unreadCount }})
        </span>
        <div class="text-end">
          <button [disabled]="!unreadCount" class="btn btn-sm btn-outline-primary me-1" (click)="markAllAsRead()">
            Mark All Read
          </button>
          <button [disabled]="!notifications.length" class="btn btn-sm btn-outline-primary" (click)="clearAllNotifications()">
            Clear All
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div *ngIf="notifications.length; else noNotifications" class="list-group mb-5">
        <button *ngFor="let notification of notifications"
                class="list-group-item list-group-item-action"
                routerLinkActive="bg-light fw-bold"
                [routerLink]="['/notifications', notification.id]"
                (click)="selectNotification(notification)">
          <div class="w-100 d-flex">
            <div class="flex-grow-1">
              <div class="text-primary x-small fw-bold">
                {{ getNotificationTypeLabel(notification.type) }}
              </div>
              <div class="mb-2">
                {{ notification.title }}
                <p class="text-muted small fw-normal" [innerHTML]="notification.message | markdown">
                </p>
              </div>
            </div>
            <div class="flex-shrink-1 d-flex flex-column justify-content-between align-items-end">
              <span class="bi bi-circle-fill text-info small" *ngIf="!notification.readAt"></span>
              <span class="text-muted x-small fw-normal">
                {{ notification.sentAt | date: 'MM/dd/yyyy' }}
              </span>
            </div>
          </div>
        </button>
      </div>
    </div>
    <ng-template #noNotifications>
      <div class="col-12">
        <div class="alert alert-info">
          No notifications
        </div>
      </div>
    </ng-template>
    <div class="col-12 col-md-6">
      <div *ngIf="selectedNotification; else noNotificationSelected" class="border rounded px-2 py-3">
        <router-outlet></router-outlet>
      </div>
      <ng-template #noNotificationSelected>
          <p class="text-muted text-center">No notification selected</p>
      </ng-template>
    </div>
  </div>
</div>
