import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar-input',
  templateUrl: './search-bar-input.component.html',
  styleUrls: ['./search-bar-input.component.sass']
})
export class SearchBarInputComponent {

  @Input() placeholder = 'Search';
  @Output() loadResults = new EventEmitter<void>();

  @Input() search = '';
  @Output() searchChange = new EventEmitter<string>();

  @Input() searchDelay = 500;

  searchTimeout: any;

  onLoadResults() {
    this.loadResults.emit();
  }

  searchChanged() {
    this.searchChange.emit(this.search);

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.onLoadResults();
    }, this.searchDelay);
  }

}
