import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';

@Component({
  selector: 'app-video-catalog-page',
  templateUrl: './video-catalog-page.component.html',
  styleUrls: ['./video-catalog-page.component.sass']
})
export class VideoCatalogPageComponent {

  constructor(private router: Router) { }

  selectVideo(video: VideoMetaData) {

    this.router.navigate([`videos/watch/${video.id}`]);
  }
}
