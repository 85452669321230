<ng-container *ngIf="deleteUserTemplate; else defaultDeleteUserButtonTemplate">
    <ng-container *ngTemplateOutlet="deleteUserTemplate; context: context"></ng-container>
  </ng-container>
  <ng-template #defaultDeleteUserButtonTemplate>
    <ng-container *ngTemplateOutlet="defaultDeleteUserButton; context: context"></ng-container>
  </ng-template>
  <ng-template #defaultDeleteUserButton>
  <button type="button"
            (click)="openDeleteUserModal()"
            class="btn btn-danger">
      Delete User <span class="bi bi-trash-fill"></span>
    </button>
  </ng-template>
<ng-template #deleteUserModal>
<p>Delete the user and remove access to all their resources.</p>
<p class="text-danger fw-bold">This action cannot be undone.</p>
<p class="text-muted">Type <code class="border px-1 rounded">{{user?.email}}</code> to delete the user.</p>

<div class="my-2">
<input type="text"
        [(ngModel)]="deleteUserEmail"
        class="form-control">
</div>
</ng-template>