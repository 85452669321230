export interface TextType {
  name: string;
  prefix: string;
  suffix?: string;
  inlineSuffix?: string;
  inlinePrefix?: string;
  multiline?: boolean;
  inline?: boolean;
  className?: string;
}

export const TextTypes = {
  PARAGRAPH: {
    name: 'Paragraph',
    prefix: '',
  },
  HEADING_1: {
    name: 'Heading 1',
    prefix: '# ',
    className: 'h1',
  },
  HEADING_2: {
    name: 'Heading 2',
    prefix: '## ',
    className: 'h2',
  },
  HEADING_3: {
    name: 'Heading 3',
    prefix: '### ',
    className: 'h3',
  },
  HEADING_4: {
    name: 'Heading 4',
    prefix: '#### ',
    className: 'h4',
  },
  HEADING_5: {
    name: 'Heading 5',
    prefix: '##### ',
    className: 'h5',
  },
  HEADING_6: {
    name: 'Heading 6',
    prefix: '###### ',
    className: 'h6',
  },
  BLOCKQUOTE: {
    name: 'Blockquote',
    prefix: '> ',
    multiline: true,
  },
  CODE_BLOCK: {
    name: 'Code Block',
    prefix: '```\n',
    suffix: '\n```',
    inlinePrefix: '`',
    inlineSuffix: '`',
    multiline: true,
    inline: true,
    className: 'code small',
  },
};
