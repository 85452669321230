<app-landing-page-frame>
  <div *ngIf="!ssoMode; else ssoModeHeaders" class="ms-3">
    <h1 class="text-start fs-3">
      {{ 'Sign In' | stringResource }}
    </h1>

    <p class="text-start">
      {{ 'Sign in to access your learning dashboard.' | stringResource }}
    </p>
  </div>

  <ng-template #ssoModeHeaders>
    <div class="ms-3">
      <h1 class="text-start fs-3 mb-3">
        {{ 'Sign in' | stringResource }}
      </h1>

      <p class="text-start mb-3">
        {{ 'Sign in with Smoothstack Learn to access' | stringResource }} <span class="fw-bold">{{ssoClientName}}</span>
      </p>
    </div>
  </ng-template>

  <div class="container-fluid mt-4 mb-3">
    <app-sso-button></app-sso-button>
  </div>

  <div class="container-fluid d-flex flex-row justify-content-around my-4">
    <hr class="w-100"/>
    <span class="px-4">
      {{ 'or' | stringResource }}
    </span>
    <hr class="w-100"/>
  </div>

  <app-login-form></app-login-form>

  <div class="container-fluid my-2 text-end">
    <a [routerLink]="['/forgot-password']" class="text-decoration-none">
      {{ 'Forgot your password?' | stringResource }}
    </a>
  </div>
</app-landing-page-frame>
