import { Component, Input } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';

@Component({
  selector: 'app-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.sass']
})
export class CourseViewComponent {
  @Input() courseId!: number;
  @Input() learningActivity!: LearningActivity;

}
