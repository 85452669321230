import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { User, UserRole } from '@app/api/user/models/user.model';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { CreateUserRequest } from '@app/api/user/models/create-user-request.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyEmailModalComponent } from '@app/api/user/components/verify-email-modal/verify-email-modal.component';
import { Page, PageableParams } from '@app/api/models/page.model';
import { UserFilterParams } from '@app/api/user/models/user-filter-params.model';
import { UserUpdateRequest } from '@app/api/user/models/user-update-request.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser?: User;
  isImpersonatingSubject = new BehaviorSubject<boolean>(false);
  public isImpersonating$: Observable<boolean> = this.isImpersonatingSubject.asObservable();

  constructor(private http: HttpClient,
    private localStorage: LocalStorageService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private router: Router) {
      const originalToken = this.localStorage.getItem('original_jwt');
      this.isImpersonatingSubject.next(!!originalToken);
     }

  getSelf() {
    if (this.currentUser) {
      return of(this.currentUser);
    }
    return this.http.get<User>(`${environment.apiUrl}/users/self`)
      .pipe(map(user => {
        this.currentUser = user;
        if (!this.currentUser.emailVerified) {
          this.notificationService.addNotification({
            uniqueName: 'EMAIL_NOT_VERIFIED', // This is used to prevent duplicate notifications
            label: 'Email not verified',
            message: 'Please verify your email address.',
            type: 'warning',
            action: () => {
              this.modalService.open(VerifyEmailModalComponent);
            }
          });
        }
        return this.currentUser;
      }));
  }

  signOutCurrentUser() {
    this.currentUser = undefined;
  }

  getSelfRole() {
    return this.getSelf().pipe(map(user => user.role));
  }

  getEmailVerified() {
    return this.getSelf().pipe(map(user => user.emailVerified));
  }

  getProfilePicture(id: number | 'self' = 'self') {
    const cached = this.localStorage.getItem('profilePictureUrl');

    if (cached) {
      return this.validateBlobURL(cached)
        .pipe(
          switchMap((isValid) => {
            if (isValid) {
              return of(cached);
            } else {
              return this.fetchProfilePicture(id);
            }
          })
        );
    }
    return this.fetchProfilePicture();
  }

  validateBlobURL(blobURL: string): Observable<boolean> {
    try {
      return new Observable((observer) => {
        fetch(blobURL)
          .then(response => {
            if (response.status === 200 || response.status === 0) {
              observer.next(true);
            } else {
              observer.next(false);
              URL.revokeObjectURL(blobURL);
            }
            observer.complete();
          })
          .catch(() => {
            observer.next(false);
            URL.revokeObjectURL(blobURL);
            observer.complete();
          });
      });
    } catch (error) {
      return of(false);
    }
  }


  fetchProfilePicture(id: number | 'self' = 'self') {
    const apiUrl = `${environment.apiUrl}/users/${id}/profile-picture`;

    return this.http.get(apiUrl, { responseType: 'blob' })
      .pipe(
        map((blob) => {
          const url = URL.createObjectURL(blob);
          this.localStorage.setItem('profilePictureUrl', url);
          return url;
        }),
        catchError(e => {
          console.log(e);
          return of('assets/images/default-avatar.png');
        })
      );
  }

  getAllRoles() {
    return this.http.get<UserRole[]>(`${environment.apiUrl}/users/roles`);
  }

  createUser(createUserRequest: CreateUserRequest) {
    return this.http.post<User>(`${environment.apiUrl}/users`, createUserRequest);
  }

  createUsers(createUserRequest: CreateUserRequest[]): Observable<any> {
    return this.http.post<CreateUserRequest[]>(`${environment.apiUrl}/users/import-users`, createUserRequest)
      .pipe(
        catchError(error => {
          throw error;
        })
      );
  }

  fetchProfilePictureByEmail(email: string) {
    const apiUrl = `${environment.apiUrl}/users/profile-picture`;
    return this.http.get(apiUrl, { responseType: 'blob', params: { email } });
  }

  getAllUsers(search?: string, userFilters?: UserFilterParams, pageable?: PageableParams): Observable<Page<User>> {

    if (userFilters && userFilters.role) {
      if (Array.isArray(userFilters.role)) {
        userFilters.role = userFilters.role.join(',');
      }
    }

    return this.http.get<Page<User>>(`${environment.apiUrl}/users`, {
      params: {
        search: search || '',
        ...userFilters,
        ...pageable
      }
    });
  }

  getUserById(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  toggleUserStatus(userId: number, enabled: boolean): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/users/${userId}/status`,  enabled );
  }

  updateUser(id: number, request: UserUpdateRequest) {
    return this.http.put<User>(`${environment.apiUrl}/users/${id}`, request);
  }

  deleteUser(id: number): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.apiUrl}/users/${id}`);
  }

  impersonateUser(username: string): Observable<any> {
    const originalToken = this.localStorage.getItem('jwt'); 
    if (!originalToken) {
      console.error('Original token is null before impersonation');
      return throwError(() => new Error('Original token is null before impersonation'));
    }

    this.localStorage.setItem('original_jwt', originalToken); 
    console.log('Original token saved:', originalToken);

    const params = new HttpParams().set('username', username);

    return this.http.post<any>(`${environment.apiUrl}/users/impersonate`, {}, { params }).pipe(
      tap((response) => {
        const { token, role } = response;
        if (token && role) {
          this.localStorage.setItem('jwt', token);
          this.isImpersonatingSubject.next(true);
          const dashboardUrl = this.getDashboardUrl(role);
          this.router.navigate([dashboardUrl]).then(() => {
            location.reload();
          });
        } else {
          console.error('Invalid impersonation response:', response);
          throw new Error('Invalid impersonation response');
        }
      }),
      catchError((error) => {
        console.error('Error impersonating user:', error);
        return throwError(() => error);
      })
    );
  }

  stopImpersonation(): Observable<any> {
    const originalToken = this.localStorage.getItem('original_jwt');
    if (!originalToken) {
      console.error('Original token not found in local storage');
      return throwError(() => 'Original token not found');
    }

    return this.http.post(`${environment.apiUrl}/users/exitImpersonate`, {}).pipe(
      tap((response: any) => {
        if (response && response.token) {
          this.localStorage.setItem('jwt', response.token);
          this.localStorage.removeItem('original_jwt');
          this.isImpersonatingSubject.next(false);
          console.log('Impersonation stopped successfully. New token:', response.token);
          this.router.navigate(['/dashboard']).then(() => {
            location.reload();
          });
        } else {
          console.error('Error stopping impersonation: Invalid response', response);
          throw new Error('Error stopping impersonation: Invalid response');
        }
      }),
      catchError((error) => {
        console.error('Error stopping impersonation:', error);
        return throwError(() => error);
      })
    );
  }

  private getDashboardUrl(role: any): string {
    const roleName = role[0]?.name || 'default';
    console.log("role=>", roleName);

    const roleToUrlMap: { [key: string]: string } = {
      'Admin': '/user-management',
      'Trainer': '/training',
      'Student': '/dashboard',
      'default': '/dashboard'
    };

    return roleToUrlMap[roleName] || '/dashboard';
  }
}
