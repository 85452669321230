<ng-container *ngIf="lessonData && !loading; else spinner">

  <div class="my-3 flex-center">
    <app-percent-circle
      [completed]="learningActivity.completed || false"
      [percent]="learningActivity.progress || 0">
    </app-percent-circle>
  </div>

  <div class="my-3">
    <div class="text-center">
      <div class="text-muted small">Lesson</div>
      <h3 class="text-center">{{ lessonData.title }}</h3>
    </div>

    <div class="flex-center my-3 gap-1">
      <span *ngFor="let tag of lessonData.technologies" class="badge bg-info">
        {{ tag }}
      </span>
    </div>
    <div *ngIf="!isRavenLesson" class="my-3 text-muted text-center">
      {{ lessonData.description }}
    </div>
  </div>

  <div *ngIf="presentationFileUrl" class="my-3">
    <div class="ratio ratio-16x9 mb-5">
      <app-office365-viewer [fileUrl]="presentationFileUrl"></app-office365-viewer>
    </div>
  </div>

  <div class="my-3">
    <app-markdown-viewer [data]="lessonData.content"></app-markdown-viewer>
  </div>

  <div *ngIf="isRavenLesson && !learningActivity.completed">
    <div class="my-3">
      <a [href]="lessonData.sourceUrl" class="btn btn-secondary w-100" target="_blank">
        Start lesson <span class="bi bi-box-arrow-up-right"></span>
      </a>
    </div>

  </div>

  <div *ngIf="!hasAssignments" class="my-3">
    <button
      (click)="completeLesson()"
      *ngIf="!completed"
      [disabled]="completing || (learningActivity.completed || false)" class="btn btn-primary w-100">
      {{ learningActivity.completed ? 'Completed' : 'Complete Lesson' }} <span
      [ngClass]="{'bi bi-check2-circle': !completing, 'spinner-border spinner-border-sm': completing}"></span>
    </button>
  </div>
</ng-container>

<ng-template #spinner>
  <div *ngIf="showSpinner" class="my-3 flex-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
