import { Component } from '@angular/core';

@Component({
  selector: 'app-assignment-activity-page',
  templateUrl: './assignment-activity-page.component.html',
  styleUrls: ['./assignment-activity-page.component.sass']
})
export class AssignmentActivityPageComponent {

}
