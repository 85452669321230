<div class="container">
  <div class="my-3">
    <h5 class="h5" style="margin-top: 70px;">Basic Details</h5>
  </div>
  <div class="pb-5">
    <form [formGroup]="discussionForm" *ngIf="discussionForm" class="my-3" (submit)="publishPost()">
      <input type="hidden" formControlName="parentPostId" />
      <div class="my-3">
        <app-text-field controlName="postTitle" label="Title"></app-text-field>
      </div>
      <div class="my-3">
        <h5 class="h5" style="margin-top: 30px;">Content</h5>
        <app-markdown-editor formControlName="content" label="Content" [showPreviewButton]="true" placeholder="Content">
        </app-markdown-editor>
      </div>
      <div class="my-3">
        <app-technology-type-select-field formControlName="technologyType"
          [selectedTechnologyTypes]="selectedTechnologyTypes"
          (selectedTechnologyTypesChange)="handleSelectedTechnologyTypesChange($event)">
        </app-technology-type-select-field>
      </div>
      <div class="my-3">
        <app-technology-tags-field formControlName="technologyTags"></app-technology-tags-field>
      </div>
      <div class="my-3">
        <button class="btn btn-primary" type=submit style="margin-top: 50px;">
          Publish Post
        </button>
      </div>
    </form>
  </div>
</div>
