import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { Page, PageableParams } from '@app/api/models/page.model';
import { QuestionResponse } from '@app/api/question/models/question-response.model';
import {
  CreateBulkQuestionRequest,
  CreateQuestionRequest
} from '@app/api/question/models/create-question-request.model';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { AnswerResponse } from '@app/api/question/models/answer-response.model';
import { NamedEntity } from '@app/api/models/named-entity.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionApiService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getQuestions(query: string, pageable: PageableParams): Observable<Page<QuestionResponse>> {
    return this.http.get<Page<QuestionResponse>>(this.api.endpoint('questions'), {
      params: {
        query,
        ...pageable
      }
    });
  }

  getQuestionById(id: number): Observable<QuestionResponse> {
    return this.http.get<QuestionResponse>(this.api.endpoint(`questions/${id}`))
      .pipe(this.api.catchHttpError({
        404: 'Question not found',
        403: 'You do not have permission to view this question'
      }));
  }

  createQuestion(question: CreateQuestionRequest): Observable<QuestionResponse> {
    return this.http.post<QuestionResponse>(this.api.endpoint('questions'), question)
      .pipe(this.api.catchHttpError());
  }

  createBulkQuestions(questions: CreateBulkQuestionRequest): Observable<QuestionResponse[]> {
    return this.http.post<QuestionResponse[]>(this.api.endpoint('questions/bulk'), questions)
      .pipe(this.api.catchHttpError());
  }

  getQuestionDataForUpdate(id: number): Observable<QuestionData> {
    return this.http.get<QuestionData>(this.api.endpoint(`questions/${id}/data`));
  }

  updateQuestion(id: number, question: QuestionData): Observable<QuestionData> {
    return this.http.put<QuestionData>(this.api.endpoint(`questions/${id}`), question);
  }

  deleteQuestion(id: number): Observable<void> {
    return this.http.delete<void>(this.api.endpoint(`questions/${id}`));
  }

  getQuestionAnswerById(id: number): Observable<AnswerResponse> {
    return this.http.get<AnswerResponse>(this.api.endpoint(`questions/${id}/answer`));
  }

  getQuestionTags(search: string): Observable<Page<NamedEntity>> {
    return this.http.get<Page<NamedEntity>>(this.api.endpoint('questions/tags'), {
      params: {
        search,
        size: 5
      }
    });
  }

}
