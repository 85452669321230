import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyBindingService implements OnDestroy {

  private keyEventSubject = new Subject<KeyboardEvent>();
  private keyBinds: {
    [keyBind: string]: Subscription,
  } = {};

  get keyEvents() {
    return this.keyEventSubject.asObservable();
  }

  constructor() {
    window.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.handleKeydown.bind(this));
    // Unsubscribe from all key binds
    for (const keyBind in this.keyBinds) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.keyBinds.hasOwnProperty(keyBind)) {
        this.keyBinds[keyBind].unsubscribe();
      }
    }
    // Unsubscribe from all subjects
    this.keyEventSubject.unsubscribe();
  }

  private handleKeydown(event: KeyboardEvent) {
    this.keyEventSubject.next(event);
  }

  private getKeyBind(keyBind: string): {
    parts: string[],
    keyBind: string
  } {
    // Key bind separator is a plus with optional spaces
    const keyBindSeparator = /\s*\+\s*/;
    const keyBindParts = keyBind.split(keyBindSeparator)
      .map(key => key.trim().toLowerCase());
    // Create standardized key bind string for map
    return {
      parts: keyBindParts,
      keyBind: keyBindParts.join('+'),
    };
  }

  registerKeyBind(keyBindString: string, callback: (event: KeyboardEvent) => void) {
    const { keyBind, parts } = this.getKeyBind(keyBindString);

    this.keyBinds[keyBind] = this.keyEvents.subscribe((event: KeyboardEvent) => {
      const hasCtrl = parts.includes('ctrl') === event.ctrlKey;
      const hasShift = parts.includes('shift') === event.shiftKey;
      const hasAlt = parts.includes('alt') === event.altKey;
      if (hasCtrl && hasShift && hasAlt && parts.includes(event.key.toLowerCase())) {
        callback(event);
      }
    });
  }

  deregisterKeyBind(keyBindString: string) {
    const { keyBind, parts } = this.getKeyBind(keyBindString);
    if (this.keyBinds[keyBind]) {
      this.keyBinds[keyBind].unsubscribe();
      delete this.keyBinds[keyBind];
    }
  }
}
