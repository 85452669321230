import { Component } from '@angular/core';

@Component({
  selector: 'app-cohorts-page',
  templateUrl: './cohorts-page.component.html',
  styleUrls: ['./cohorts-page.component.sass']
})
export class CohortsPageComponent {

}
