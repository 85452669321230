import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Student } from '@app/api/student/model/student.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/api/user/models/user.model';
import { ExportFileService } from '@app/core/services/export-file.service';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.sass']
})
export class StudentFormComponent {

  @Output() submitStudents = new EventEmitter<Student[]>();
  @ViewChild('importStudentsModal') importStudentsModal?: TemplateRef<any>;
  @ViewChild('addExistingUserModal') addExistingUserModal?: TemplateRef<any>;

  studentForm: FormGroup;
  files: File[] = [];
  selectedUsers: User[] = [];
  students: Student[] = [];
  isExistingUser = false;

  template = 'email,firstName,lastName,invite';

  private _saving = false;
  @Input() set saving(value: boolean) {
    if(this.studentForm){
      if (value) {
        this.studentForm.disable();
      } else {
        this.studentForm.enable();
      }
      this._saving = value;
    }
  }
  get saving() {
    return this._saving;
  }

  @Input() mode: 'create' | 'edit' = 'create';

  constructor( private fb: FormBuilder,
    private modalService: NgbModal,
    private exportService: ExportFileService) { 

    this.studentForm = new FormGroup({
      students: new FormArray([])
    });
    this.addStudent();
  }

  addStudent() {
    const students = this.studentForm.get('students') as FormArray;
    const studentFormGroup = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      invite: [false]
    });
    students.push(studentFormGroup);
  }

  get studentsFormArray(): FormArray {
    return this.studentForm.get('students') as FormArray;
  }

  removeStudent(index: number) {
    const students = this.studentForm.get('students') as FormArray;
    students.removeAt(index);
  }

  processFile() {
    if (this.files && this.files[0]) {
      const selectedFile = this.files[0];
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension === 'csv') {
        this.readCsvFile(selectedFile);
        this.selectedUsers = [];
      } else {
        console.log('Invalid file type');
      }
    }
  }
  
  readCsvFile(file: File): void {
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const csvData = reader.result?.toString();
      if (csvData) {
        const lines = csvData.split(/\r?\n/);
        let isFirstRow = true;
        this.studentsFormArray.clear();
        this.isExistingUser = false;
        for (const line of lines) {
          const [email, firstName, lastName, invite] = line.split(',').map(item => item.trim());
          if (isFirstRow) {
            isFirstRow = false;
            if (line.toLowerCase().includes('email') && line.toLowerCase().includes('firstname') && line.toLowerCase().includes('lastname') && line.toLowerCase().includes('invite')) {
              continue;
            }    
          } 

          if (email  && firstName && lastName && invite) { 
            const studentFormGroup = this.fb.group({
              email: [email, [Validators.email, Validators.required]],
              firstName: [firstName, [Validators.required]],
              lastName: [lastName, [Validators.required]],
              invite: [invite]
            });
            this.studentsFormArray.push(studentFormGroup);
          }
        }
      }
      this.modalService.dismissAll();
    };
    reader.readAsText(file);
  }
  
  openImportStudentsModal() {
    this.modalService.open(this.importStudentsModal, {
      size: 'lg'
    });
  }

  openAddExistingUsersModal() {
    this.modalService.open(this.addExistingUserModal, {
      size: 'lg'
    });
  }

  goBack(){
    this.studentsFormArray.clear();
    this.isExistingUser = false;
    this.selectedUsers = [];
    this.addStudent();
  }

  downloadTemplate() {
    const blob = new Blob([this.template], { type: 'text/csv' });
    this.exportService.triggerDownload(blob, 'student-template.csv');
  }

  addExistingUsers() {
    if (!this.isExistingUser) {
      this.studentsFormArray.clear();
    }

    this.selectedUsers.forEach(user => {
       
      const existingUser = this.studentsFormArray.controls.find(control => {
        const emailControl = control.get('email');
        return emailControl && emailControl.value === user.email;
      });
      if (!existingUser) {
        const studentFormGroup = this.fb.group({
          email: [user.email, [Validators.email, Validators.required]],         
          firstName: [user.firstName, [Validators.required]],
          lastName: [user.lastName, [Validators.required]],
          invite: [false]
        });
        this.studentsFormArray.push(studentFormGroup);
        this.isExistingUser = true;
      }     
    });
  }

  onSubmitStudent() {
    if (this.studentForm.valid) {
      const students: Student[] = this.studentForm.value.students.map((student: any) => ({
        email: student.email,
        firstName: student.firstName,
        lastName: student.lastName,
        invite: student.invite
      }));
      this.submitStudents.emit(students);
      this.studentForm.reset();
      this.selectedUsers = [];
      this.studentsFormArray.clear();
      this.isExistingUser = false;
      this.addStudent();     
    }  
  }
}