import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-question-creator-home-page',
  templateUrl: './question-creator-home-page.component.html',
  styleUrls: ['./question-creator-home-page.component.sass']
})
export class QuestionCreatorHomePageComponent {

  items: RouteCardItem[] = [
    {
      title: 'Create a Question',
      description: 'Create a new question or a set of new questions.',
      icon: 'bi-plus-circle',
      routerLink: '/course-composer/question-creator/create',
    },
    {
      title: 'Import Questions',
      description: 'Import questions from a file.',
      icon: 'bi-file-earmark-arrow-up',
      routerLink: '/course-composer/question-creator/import',
    },
  ];

}
