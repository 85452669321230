import { Component, TemplateRef, ViewChild } from '@angular/core';
import { User } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';
import { EmailVerificationService } from '@app/api/user/services/email-verification.service';
import { PasswordResetService } from '@app/api/user/services/password-reset.service';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass'],
})
export class ProfileComponent {
  user?: User;
  profilePictureUrl?: string;
  sendingVerificationEmail = false;
  sendingPasswordResetEmail = false;

  @ViewChild('updateRolesModal') updateRolesModal!: TemplateRef<any>;

  constructor(
    private userService: UserService,
    private emailVerificationService: EmailVerificationService,
    private passwordResetService: PasswordResetService,
    private modalService: GlobalModalService
  ) {
    this.userService.getSelf().subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.userService.fetchProfilePicture(this.user?.id).subscribe({
      next: (url) => {
        this.profilePictureUrl = url;
      },
    });
  }

  get userEmail() {
    return this.user?.email || '';
  }

  sendVerificationEmail() {
    this.sendingVerificationEmail = true;
    this.emailVerificationService
      .sendVerificationEmail(this.userEmail)
      .subscribe({
        next: () => {
          this.modalService.alert({
            title: 'Email sent',
            content: `Verification email sent to ${this.userEmail}`,
            type: 'success',
          });
        },
        complete: () => {
          this.sendingVerificationEmail = false;
        },
      });
  }

  sendPasswordResetEmail() {
    this.sendingPasswordResetEmail = true;
    this.passwordResetService.initiatePasswordReset(this.userEmail).subscribe({
      next: () => {
        this.modalService.alert({
          title: 'Email sent',
          content: `Password reset email sent to ${this.userEmail}`,
          type: 'success',
        });
      },
      complete: () => {
        this.sendingPasswordResetEmail = false;
      },
    });
  }
}
