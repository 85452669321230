import { Component, OnDestroy, OnInit } from '@angular/core';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { LessonData } from '@app/api/models/learning-material-data.model';
import { GlobalToastService } from '@core/services/global-toast.service';
import { catchError, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyBindingService } from '@core/services/key-binding.service';
import { CanComponentDeactivate } from '@core/guards/can-component-deactivate.guard';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-lesson-form-page',
  templateUrl: './lesson-form-page.component.html',
  styleUrls: ['./lesson-form-page.component.sass'],
})
export class LessonFormPageComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  lessonData?: LessonData;
  mode?: 'create' | 'edit';
  saved = false;
  showAddToModuleButton = false;
  moduleId = -1;

  constructor(
    private lessonApi: LessonApiService,
    private toastService: GlobalToastService,
    private globalModalService: GlobalModalService,
    private route: ActivatedRoute,
    private router: Router,
    private keyBindingService: KeyBindingService,
  ) {
    const routeData = this.route.snapshot.data;
    this.mode = routeData['mode'];

    if (this.mode === 'create') {
      this.lessonData = {
        assignments: [],
        quizzes: [],
        content: '',
        description: '',
        lessonAttachments: [],
        title: '',
        order: 0,
      };
    } else if (this.mode === 'edit') {
      const lessonId = this.route.snapshot.params['id'];
      this.lessonApi.getLesson(lessonId).subscribe((lessonData) => {
        this.lessonData = lessonData;
      });
    }

    this.keyBindingService.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      this.saveLesson();
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['moduleId']) {
        this.showAddToModuleButton = true;
        this.moduleId = +params['moduleId'];
      }
    });
  }

  ngOnDestroy(): void {
    this.keyBindingService.deregisterKeyBind('ctrl+s');
  }

  saveLesson(publish?: boolean, navigate?: boolean): void {
    if (!this.isLessonDataValid()) {
      this.toastService.show({
        content: 'Please fill out all required fields before saving.',
        type: 'danger',
      });
      return;
    }

    const lessonId = this.lessonData?.id;

    if (publish !== undefined) {
      this.lessonData!.published = publish;
    }

    if (lessonId) {
      this.lessonApi
        .updateLesson(lessonId, this.lessonData!)
        .pipe(
          catchError((err) => {
            this.toastService.show({
              content: 'Unable to save lesson',
              type: 'danger',
            });
            console.error(err);
            return of(null);
          }),
        )
        .subscribe({
          next: (lessonData) => {
            if (lessonData) {
              this.lessonData = lessonData;
              this.saved = true;
              this.toastService.show({
                content: 'Lesson saved successfully!',
                type: 'success',
              });
              if (navigate) {
                this.router.navigate(['/assignment-composer'], {
                  queryParams: { lessonId: lessonData?.id },
                });
              }
            }
          },
        });
    } else {
      this.lessonApi
        .createLesson(this.lessonData!)
        .pipe(
          catchError(() => {
            this.toastService.show({
              content: 'Unable to create lesson',
              type: 'danger',
            });
            return of(null);
          }),
        )
        .subscribe({
          next: (lessonData) => {
            if (navigate) {
              this.router.navigate(['/assignment-composer'], {
                queryParams: { lessonId: lessonData?.id },
              });
            } else {
              if (lessonData) {
                if (this.showAddToModuleButton) {
                  this.router.navigate(
                    ['/course-composer/modules/edit', this.moduleId],
                    { queryParams: { lessonId: lessonData.id } },
                  );
                } else {
                  this.saved = true;
                  this.router
                    .navigate([
                      'course-composer',
                      'lessons',
                      'edit',
                      lessonData.id,
                    ])
                    .then(() => {
                      this.toastService.show({
                        content: 'Lesson created successfully!',
                        type: 'success',
                      });
                    });
                }
              }
            }
          },
        });
    }
  }

  isLessonDataValid(): boolean {
    if (!this.lessonData) return false;
    if (
      !this.lessonData.title ||
      !this.lessonData.description ||
      !this.lessonData.content
    ) {
      return false;
    }
    return true;
  }

  canDeactivate() {
    if (!this.saved) {
      return this.globalModalService.confirm(
        'Are you sure you want to leave this page? Any unsaved changes will be lost.',
      );
    }
    return true;
  }

  triggerSaveLesson() {
    this.saveLesson(false, true);
  }
}
