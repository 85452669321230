<div class="container skeleton-container">

  <div [class.my-5]="structureType === 'courseOrModule'" class="pt-3 flex-center flex-column">
    <div class="mb-3">
      <div class="percent-circle-skeleton bg-skeleton-light"></div>
    </div>
    <div class="text-center">
      <div class="text-muted small bg-skeleton-light rounded my-2 py-2 sub-title-skeleton mx-auto"></div>
      <h3 class="text-center bg-skeleton-light rounded my-2 title-skeleton">
        &nbsp;
      </h3>
    </div>
    <div class="my-2 flex-center gap-1">
      <span class="badge rounded-pill small bg-skeleton-light pill-skeleton">
        &nbsp;
      </span>
      <span class="badge rounded-pill small bg-skeleton-light pill-skeleton">
        &nbsp;
      </span>
      <span class="badge rounded-pill small bg-skeleton-light pill-skeleton">
        &nbsp;
      </span>
    </div>
    <p class="mt-2 text-muted bg-skeleton-light rounded text-skeleton">
      &nbsp;
    </p>
  </div>

  <div *ngIf="structureType === 'courseOrModule'" class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card-body">

        <div class="list-group border-0">

          <div class="list-group-item bg-skeleton-light border-0 list-group-item-skeleton">
          </div>

          <div class="list-group-item bg-skeleton-light border-0 list-group-item-skeleton">
          </div>

          <div class="list-group-item bg-skeleton-light border-0 list-group-item-skeleton">
          </div>

          <div class="list-group-item bg-skeleton-light border-0 list-group-item-skeleton">
          </div>

        </div>

      </div>
    </div>

  </div>

  <div *ngIf="structureType === 'lesson'" class="my-3">
    <div class="ratio ratio-16x9">
      <div class="bg-skeleton-light">
        &nbsp;
      </div>
    </div>
  </div>

  <div *ngIf="structureType === 'assignment'" class="border-top">
    <div class="my-3">
      <div class="col-md-4 offset-md-4 my-3">
        <div class="py-2 rounded bg-skeleton-light">
          &nbsp;
        </div>
      </div>
      <div class="bg-skeleton-light rounded my-3 py-3 col-4">
        &nbsp;
      </div>
      <div class="w-100 bg-skeleton-light rounded my-3 py-1">
        &nbsp;
      </div>
      <div class="pe-5">
        <div class="w-100 bg-skeleton-light rounded my-3 py-1">
          &nbsp;
        </div>
      </div>
      <hr/>
      <div class="bg-skeleton-light rounded my-3 py-3 col-4">
        &nbsp;
      </div>
      <div class="w-100 bg-skeleton-light rounded my-3 py-3">
        &nbsp;
      </div>
      <div class="ps-5">
        <div class="w-100 bg-skeleton-light rounded my-3 py-1">
          &nbsp;
        </div>
      </div>
      <div class="ps-5">
        <div class="w-100 bg-skeleton-light rounded my-3 py-1">
          &nbsp;
        </div>
      </div>
      <div class="ps-5">
        <div class="w-100 bg-skeleton-light rounded my-3 py-1">
          &nbsp;
        </div>
      </div>
      <div class="mt-4">
        <div class="my-2 bg-skeleton-light rounded my-3 col-12 py-5">
          &nbsp;
        </div>
        <div class="my-2 d-flex justify-content-end">
          <div class="bg-skeleton-light rounded my-3 py-1 col-4">
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="structureType === 'quiz'" class="my-3">
    <div class="ratio ratio-16x9">
      <div class="bg-skeleton-light">
        &nbsp;
      </div>
    </div>

  </div>

