<div *ngIf="fileTree">
  <div class="py-3 px-2 d-flex justify-content-end border-bottom">
    <div class="btn-group btn-group-sm">
      <button class="btn btn-outline-primary" (click)="openNewFolderModal()">
        New Folder <span class="bi bi-folder-plus"></span>
      </button>
      <button class="btn btn-primary" (click)="openUploadFileModal()">
        Upload <span class="bi bi-upload"></span>
      </button>
    </div>
  </div>
  <div *ngIf="!loading; else spinner" class="list-group list-group-flush">
    <button *ngIf="hasPreviousDirectory" class="list-group-item list-group-item-action py-2" (click)="previousDirectory()">
      <span class="bi bi-folder text-primary me-2"></span>
      ..
    </button>
    <button *ngFor="let folder of fileTree.folders"
            class="list-group-item list-group-item-action py-3"
            (click)="selectFolder(folder)">
      <span class="bi bi-folder text-primary me-2"></span>
      {{folder}}
    </button>
    <button *ngFor="let file of fileTree.files"
            class="list-group-item list-group-item-action py-3"
            [class.bg-primary]="file === selectedFile"
            (contextmenu)="handleFileRightClick($event, file)"
            (click)="selectFile(file)">
      <span class="bi text-info me-2" [ngClass]="getIconFromFileType(file)"></span>
      {{file.fileName}}
    </button>
  </div>
  <ng-template #spinner>
    <div class="d-flex justify-content-center py-3">
      <div class="spinner-grow text-black-50" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
<div class="w-100 bg-light py-3 px-2">
  <div class="input-group">
    <input readonly class="form-control" type="text" placeholder="Select a file..." [value]="selectedFile?.fileName">
    <button class="btn btn-primary" [disabled]="!selectedFile" (click)="selectFileAndEmit(selectedFile!)">Select</button>
  </div>
</div>
<div class="w-100 bg-light rounded-bottom px-2 pb-2">
  <div class="d-flex align-items-center text-muted">
    <span class="bi bi-folder-fill btn-sm text-primary mx-1"></span>
    <ng-container *ngFor="let path of pathArray">
      /
      <button *ngIf="currentUserRole !== 'Admin' && currentUserRole !== 'Super Admin'"
        class="btn btn-light btn-sm px-1 text-muted" (click)="loadPath(path.path)">
        {{path.folderName}}
      </button>
      <button *ngIf="currentUserRole === 'Admin' || currentUserRole === 'Super Admin'"
        class="btn btn-light btn-sm px-1 text-muted" (click)="loadPath('users'+path.path)">
        {{path.folderName}}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #newFolderModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title">New Folder</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <input class="form-control" type="text" placeholder="Folder name" [(ngModel)]="newFolderName">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="createNewFolder(); modal.dismiss()">Create</button>
  </div>

</ng-template>

<ng-template #uploadFileModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title">Upload File</h5>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="uploading" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-upload-field [acceptedExtensions]="uploadAcceptedExtensions" [(ngModel)]="uploadedFiles" (filesChanged)="changeNewFileName($event)"></app-upload-field>

    <div class="my-2">
      <div class="form-floating my-2">
        <input type="text" id="newFileName" class="form-control" placeholder="File name (optional)" [(ngModel)]="newFileName"/>
        <label for="newFileName">File name (optional)</label>
      </div>

      <div class="my-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="overwriteFile" [(ngModel)]="overwriteFile">
          <label class="form-check-label" for="overwriteFile">Overwrite file if exists</label>
        </div>
      </div>

      <div class="my-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="publicAccess" [(ngModel)]="publicAccessFile">
          <label class="form-check-label" for="publicAccess">File is publicly available</label>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" [disabled]="uploading" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="uploading" (click)="uploadFile(modal)">Upload<span *ngIf="uploading">ing</span> <span *ngIf="uploading" class="spinner-border spinner-border-sm"></span></button>
  </div>

</ng-template>
