import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageRequest } from '@app/api/messages/models/MessageRequest.model';
import { MessageResponse } from '@app/api/messages/models/MessageResponse.model';
import { User } from '@app/api/user/models/user.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AddChatService {
  constructor(private http: HttpClient) {}
  messageRequest: MessageRequest = {};

  assignReceiver(user?: User) {
    this.messageRequest.receiverId = user?.id;
  }
  assignContentAndTriggerSendMessage(content: string) {
    this.messageRequest.content = content;
    return this.sendMessage(this.messageRequest);
  }
  sendMessage(messageRequest: MessageRequest): Observable<MessageResponse> {
    const url = `${environment.apiUrl}/messages`;
    return this.http.post<MessageResponse>(url, messageRequest);
  }
}
