import { Component, OnInit } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { UserService } from '@app/api/user/services/user.service';
import { LearningActivityLinkService } from '@core/services/learning-activity-link.service';

@Component({
  selector: 'app-recent-activities-widget',
  templateUrl: './recent-activities-widget.component.html',
  styleUrls: ['./recent-activities-widget.component.sass']
})
export class RecentActivitiesWidgetComponent implements OnInit {

  recentActivities: LearningActivity[] = [];
  loading = true;

  getLearningActivityLink = this.learningActivityLinkService.getLearningActivityLink;

  constructor(private userService: UserService, private learningActivityApi: LearningActivityService, private learningActivityLinkService: LearningActivityLinkService) {
  }

  ngOnInit() {
    this.loadRecentActivities();
  }

  loadRecentActivities() {
    this.userService.getSelf().subscribe(user => {
      const studentId = user.studentId;
      if (studentId) {
        this.learningActivityApi.getRecentLearningActivitiesForStudent(studentId)
          .subscribe({
            next: activities => {
              this.recentActivities = activities || [];
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
      }
    });
  }

}

