import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { Cohort } from '@app/api/models/cohort.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModalService } from '@core/services/global-modal.service';
import { StringResourceService } from '@core/services/string-resource.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-cohorts',
  templateUrl: './view-cohorts.component.html',
  styleUrls: ['./view-cohorts.component.sass']
})
export class ViewCohortsComponent implements OnInit {

  @ViewChild('viewStudentsModal') viewStudentsModal?: TemplateRef<any>;

  search = '';
  loading = false;
  cohorts: Cohort[] = [];
  sortDirection = 'asc';
  page = {
    page: 0,
    size: 10,
    sortBy: 'id',
    direction: 'asc',
    total: 0
  };

  currentCohort?: Cohort;

  constructor(private cohortApi: CohortApiService, private modalService: NgbModal,
              private globalModal: GlobalModalService, private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.search = params['search'] || '';
      this.loadCohorts();
    });
  }

  loadCohorts() {
    this.loading = true;
    const queryParams: any = {
      page: this.page.page,
      size: this.page.size,
      sort: this.page.sortBy + ',' + this.page.direction,
    };

    if (this.search.trim()) {
      queryParams.search = this.search;
    } else {
      queryParams.search = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.cohortApi.getAllCohorts(this.search, {
      page: this.page.page - 1,
      size: this.page.size,
      sort: `${this.page.sortBy},${this.page.direction}`
    }).subscribe(cohorts => {
      this.cohorts = cohorts.content;
      this.page.page = cohorts.number + 1;
      this.page.size = cohorts.size;
      this.page.total = cohorts.totalElements;
      this.loading = false;
    });
  }

  getStartDate(cohort: Cohort) {
    const year = cohort.year || 2020;
    const month = cohort.month || 1;
    const day = cohort.day || 1;
    return new Date(year, month - 1, day);
  }

  openViewStudentsModal(cohort: Cohort) {
    this.currentCohort = cohort;
    this.modalService.open(this.viewStudentsModal, { size: 'lg', scrollable: true })
      .dismissed
      .subscribe(() => {
        this.currentCohort = undefined;
      });
  }

  deleteCohort(cohort: Cohort) {
    this.globalModal.confirmDelete('Are you sure you want to delete this cohort? This cannot be undone.', cohort.name)
      .subscribe({
        next: (result) => {
          if (result) {
            this.cohortApi.deleteCohort(cohort.id!).subscribe({
              next: () => {
                this.loadCohorts();
              }
            });
          }
        }
      });

  }

  onSort(sort: string) {
    this.page.sortBy = sort;
    this.loadCohorts();
  }

  getLabel() {
    return StringResourceService.sentenceCase(this.page.sortBy);
  }

  onSortDirectionChange() {

    this.page.direction = this.sortDirection;
    this.loadCohorts();
  }
}
