import { Routes } from '@angular/router';
import {
  FlaggedDiscussionComponent
} from '@app/discussion/components/forum-page/flagged-discussion/flagged-discussion.component';
import { ForumPageComponent } from '@app/discussion/components/forum-page/forum-page.component';
import { MyDiscussionsComponent } from '@app/discussion/components/forum-page/my-discussions/my-discussions.component';
import { NewDiscussionComponent } from '@app/discussion/components/forum-page/new-discussion/new-discussion.component';
import {
  ViewDiscussionComponent
} from '@app/discussion/components/forum-page/view-discussion/view-discussion.component';

export const DiscussionRoutes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    component: ForumPageComponent
  },
  {
    path: 'new-discussion',
    data: { title: 'New Discussion' },
    component: NewDiscussionComponent
  },

  {
    path: 'my-discussions',
    data: { Title: 'My Discussions' },
    component: MyDiscussionsComponent
  },

  {
    path: 'view-discussion/:id',
    data: { Title: 'view Discussion' },
    component: ViewDiscussionComponent
  },

  {
    path: 'flagged-discussions',
    data: { Title: 'Flag Discussions' },
    component: FlaggedDiscussionComponent
  }


];
