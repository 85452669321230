<form [formGroup]="formGroup">
  <div appVerticalMultistepForm [(currentStep)]="currentStep">
    <div appFormStep [step]="STEP_QUESTION_DETAILS" stepTitle="Question Details" stepDescription="Select a question type">
      <div class="form-step-body">
        <div class="my-3">
          <app-select-field controlName="questionType"
                            placeholder="Select a question type"
                            [options]="questionTypes"
                            (controlFocus)="stepFocus(STEP_QUESTION_DETAILS)"
                            label="Question Type"></app-select-field>
        </div>
        <div class="my-3">
          <app-text-field (controlFocus)="stepFocus(STEP_QUESTION_DETAILS)" controlName="title" label="Title"></app-text-field>
        </div>
        <div class="my-3">
          <app-text-field (controlFocus)="stepFocus(STEP_QUESTION_DETAILS)" controlName="description" [lines]="3" label="Description" [maxLength]="250" [showCharacterCount]="true"></app-text-field>
        </div>
        <div class="my-3">
          <app-select-field controlName="difficulty"
                            placeholder="Select a difficulty level"
                            [options]="questionDifficulties"
                            (controlFocus)="stepFocus(1)"
                            label="Question Difficulty"></app-select-field>
        </div>
      </div>
    </div>
    <ng-container *ngIf="questionType">
      <div *ngIf="questionType !== 'INTERVIEW'; else interviewQuestionDetails" appFormStep [step]="STEP_QUESTION_TEXT"
           [stepTitle]="questionType !== 'FILL_IN_THE_BLANK' ? 'Question Text' : 'Question Template'"
           [stepDescription]="questionType !== 'FILL_IN_THE_BLANK' ? 'Enter the question text. Markdown formatting is supported.' : 'Enter the template for the fill-in-the-blank question.'">
        <div class="form-step-body">
          <div *ngIf="questionType !== 'FILL_IN_THE_BLANK'; else fitbTextArea" class="my-3">
            <app-markdown-editor formControlName="text"
                                 label="Question Text"
                                 (editorFocus)="stepFocus(STEP_QUESTION_TEXT)"
                                 [showPreviewButton]="false"
                                 placeholder="Question Text"></app-markdown-editor>
          </div>
          <ng-template #fitbTextArea>
            <div class="my-3">
              <div class="alert alert-info">
                <div class="py-2">
                  <span class="bi bi-info-circle-fill"></span>
                  <span class="fw-bold">
                    How to Create a Fill-in-the-blank Question
                  </span>
                </div>
                <div [(ngbCollapse)]="collapseHowToFitb" class="py-2">
                  <p>
                    Encase the answers that will be blank in curly braces. The answers in the curly braces should be the correct answers.
                    <span class="small d-block text-muted">For example:</span>
                    <code>JavaScript is a {{"{"}}dynamically{{"}"}} typed programming language.</code>
                  </p>
                  <p>The correct answer is <span class="fw-bold">"dynamically"</span>.</p>
                  <p>If an answer can have multiple correct answers, use a pipe character as a delimiter for each correct answer.
                    <span class="small d-block text-muted">For example:</span>
                    <code>TypeScript is a {{"{"}}strongly|statically{{"}"}} typed superset of JavaScript.</code>
                  </p>
                  <p>The correct answer can either be <span class="fw-bold">"strongly"</span> or <span class="fw-bold">"statically"</span>.</p>
                </div>
                <button class="btn btn-link link-info" (click)="collapseHowToFitb = !collapseHowToFitb">
                  {{ collapseHowToFitb ? 'Show' : 'Hide' }} Tutorial
                </button>
              </div>
            </div>
            <div class="my-3">
              <app-text-field controlName="text" [lines]="3" label="Question Template"></app-text-field>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-template #interviewQuestionDetails>
        <div appFormStep [step]="STEP_INTERVIEW_QUESTION_DETAILS" stepTitle="Interview Question" stepDescription="Fill out the details of your interview question.">
          <div class="form-step-body">
            <div class="mt-3 mb-5">
              <div class="my-2">
                Did this question involve live coding?
              </div>
              <app-toggle-field controlName="liveCoding"
                                boxStyle="switch"
                                (controlFocus)="stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)"
                                label="Live Coding Question"></app-toggle-field>
            </div>
            <div class="mt-3 mb-5">
              <div class="my-2">
                Describe the question that was asked during the interview. Markdown formatting is supported.
              </div>
              <app-markdown-editor formControlName="text"
                                   label="Describe the question"
                                   (editorFocus)="stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)"
                                   [showPreviewButton]="false"
                                   [useMarkdown]="false"
                                   placeholder="Describe the question"></app-markdown-editor>
            </div>
            <div class="mt-3 mb-5">
              <div class="my-2">
                Give an example of a correct answer to the question. Markdown formatting is supported. <span class="text-muted small">(Optional)</span>
              </div>
              <app-markdown-editor formControlName="correctAnswer"
                                   label="Example of a correct answer"
                                   (editorFocus)="stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)"
                                   [showPreviewButton]="true"
                                   [lines]="3"
                                   [useMarkdown]="false"
                                   placeholder="Example of a correct answer"></app-markdown-editor>
            </div>
            <div class="mt-3 mb-5">
              <div class="my-2">
                When was the interview conducted? <span class="text-muted small">(Optional)</span>
              </div>
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control"
                         placeholder="yyyy-mm-dd"
                         id="interviewDate"
                         formControlName="interviewDate"
                         (focus)="stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)"
                         ngbDatepicker
                         #datePicker="ngbDatepicker">
                  <label for="interviewDate">Interview Date <span class="text-muted small">(yyyy-mm-dd)</span></label>
                </div>
                <button class="btn btn-outline-primary" (click)="datePicker.toggle(); stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)" type="button">
                  <span class="bi bi-calendar3"></span>
                </button>
              </div>
            </div>
            <div class="mt-3">
              <div class="my-2">
                Which client was the interview for?
              </div>
              <app-select-field controlName="smoothstackClientId"
                                placeholder="Select a client"
                                (controlFocus)="stepFocus(STEP_INTERVIEW_QUESTION_DETAILS)"
                                [options]="smoothstackClientOptions"
                                label="Asked by Client"></app-select-field>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- True/False flow -->
      <ng-container *ngIf="questionType === 'TRUE_FALSE'">
        <div appFormStep [step]="STEP_TF_CORRECT_ANSWER" stepTitle="Correct Answer" stepDescription="Set whether the correct answer to the question is true or false.">
          <div class="form-step-body">
            <div class="my-3">
              <div class="form-check">
                <input id="isTrue"
                       type="radio"
                       [value]="true"
                       (focus)="stepFocus(STEP_TF_CORRECT_ANSWER)"
                       class="form-check-input"
                       formControlName="correctAnswerIsTrue">
                <label for="isTrue" class="form-check-label">True</label>
              </div>
              <div class="form-check">
                <input id="isFalse"
                       type="radio"
                       [value]="false"
                       (focus)="stepFocus(STEP_TF_CORRECT_ANSWER)"
                       class="form-check-input"
                       formControlName="correctAnswerIsTrue">
                <label for="isFalse" class="form-check-label">False</label>
              </div>
            </div>

            <div class="my-3 text-muted fst-italic">
              Optionally, you can add an explanation to explain why the correct answer is true or false.
            </div>

            <div class="my-3">
              <app-text-field controlName="explanation"
                              (controlFocus)="stepFocus(STEP_TF_CORRECT_ANSWER)"
                              label="Explanation"></app-text-field>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Short Answer flow -->
      <ng-container *ngIf="questionType === 'SHORT_ANSWER'">
        <div appFormStep [step]="STEP_SA_CORRECT_ANSWER" stepTitle="Correct Answer" stepDescription="Set the correct answer to the question.">
          <div class="form-step-body">
            <div class="my-3">
              <app-text-field controlName="correctAnswer" [lines]="3" label="Correct Answer"></app-text-field>
              <div class="my-2">
                <app-toggle-field controlName="mustBeExact"
                                  (controlFocus)="stepFocus(STEP_SA_CORRECT_ANSWER)"
                                  label="Answer must be exact"></app-toggle-field>
              </div>
            </div>
            <div class="my-3 text-muted fst-italic">
              Optionally, you can add an explanation to explain why the correct answer is correct.
            </div>
            <div class="my-3">
              <app-text-field controlName="explanation" label="Explanation"></app-text-field>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Multiple Choice flow -->
      <ng-container *ngIf="questionType === 'MULTIPLE_CHOICE'">
        <div appFormStep [step]="STEP_OPTIONS" stepTitle="Multiple Choice Options" stepDescription="Add choices for your question and select the correct answers.">
          <div class="form-step-body w-100">
            <div class="my-3 text-muted small fst-italic">
              Optionally, you can add explanations to explain why a choice is correct or incorrect.
            </div>
            <div class="my-3">
              <div *ngFor="let choice of choices; let i = index" [formGroup]="choice" class="pb-2" [ngClass]="{'border-top': i > 0}">
                <div class="d-flex justify-content-start align-items-start">
                  <div class="flex-grow-1">
                    <div class="my-3 d-flex border rounded-3">
                      <label class="flex-shrink-1 p-3 bg-light rounded-start-3">
                        <input type="checkbox"
                               formControlName="correct"
                               (focus)="stepFocus(STEP_OPTIONS)"
                               class="form-check-input">
                      </label>
                      <div class="flex-grow-1 bg-light rounded-end-3">
                        <app-markdown-editor formControlName="choiceText"
                                             textAreaClassName="rounded-start-0 border-0 rounded-end-3"
                                             [label]="'Choice ' + (i + 1)"
                                             [lines]="1"
                                             (editorFocus)="stepFocus(STEP_OPTIONS)"
                                             [useMarkdown]="false"
                                             [showPreviewButton]="false"
                                             [placeholder]="'Choice ' + (i + 1)"></app-markdown-editor>
                      </div>
                    </div>
                    <div *ngIf="choice.controls['explanation']">
                      <div class="ms-5">
                        <input type="text"
                               placeholder="Explanation"
                               formControlName="explanation"
                               (focus)="stepFocus(STEP_OPTIONS)"
                               class="form-control">
                      </div>
                    </div>
                  </div>
                  <div ngbDropdown class="flex-shrink- my-3">
                    <button type="button"
                            class="btn btn-link text-decoration-none"
                            ngbDropdownToggle>
                      <span class="bi bi-three-dots-vertical"></span>
                    </button>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem type="button" (click)="removeChoice(i)">
                        <span class="text-danger bi bi-trash"></span> Remove
                      </button>
                      <button *ngIf="!choice.controls['explanation']; else removeExplanationButton" ngbDropdownItem type="button" (click)="addExplanation(i)">
                        <span class="bi bi-plus-circle"></span> Add Explanation
                      </button>
                      <ng-template #removeExplanationButton>
                        <button ngbDropdownItem type="button" (click)="removeExplanation(i)">
                          <span class="bi bi-dash-circle"></span> Remove Explanation
                        </button>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-2 text-center row w-100">
                <button type="button"
                        (click)="addChoice()"
                        (focus)="stepFocus(STEP_OPTIONS)"
                        class="btn btn-primary col-12 col-lg-6">
                  Add Choice <span class="bi bi-plus-circle"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div appFormStep [step]="STEP_TAGS_AND_CATEGORIES" stepTitle="Tags and Categories" stepDescription="Add relevant tags and categories to categorize your question.">
        <div class="form-step-body">
          <div class="my-3">
            <h6 class="required">Tags</h6>
            <app-tags-field formControlName="tags" [tagResultsObservable]="tagResults" (controlFocus)="stepFocus(4)"></app-tags-field>
          </div>
          <div class="my-3">
            <h6 class="required">Categories</h6>
            <app-tags-field formControlName="categories"
                            placeholder="Select categories"
                            [searchLength]="0"
                            [immediatelyShowDropdown]="true"
                            [restrictTags]="true"
                            [tagResultsObservable]="categoryResults"
                            [tagPipe]="categoryTagPipe"
                            (controlFocus)="stepFocus(STEP_TAGS_AND_CATEGORIES)"></app-tags-field>
          </div>
          <div *ngIf="getCategoryContains('TECHNOLOGY')" class="my-3">
            <h6 class="required">Technologies</h6>
            <app-tags-field formControlName="technologies"
                            placeholder="Add relevant technology"
                            [tagResultsObservable]="technologyTags"
                            [restrictTags]="true"
                            (controlFocus)="stepFocus(STEP_TAGS_AND_CATEGORIES)"></app-tags-field>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<div class="my-5 text-end" *ngIf="allowSave">
  <button type="button"
          [disabled]="getAllErrors()"
          class="btn btn-outline-primary mx-1"
          (click)="saveQuestion(false, updateMode)">Save  Question</button>
  <button *ngIf="!questionData.published" type="button"
          [disabled]="getAllErrors()"
          class="btn btn-primary mx-1"
          (click)="saveQuestion(true, updateMode)">Save & Publish Question</button>
  <button *ngIf="questionData.published" type="button"
          [disabled]="getAllErrors()"
          class="btn btn-primary mx-1"
          (click)="saveQuestion(true, updateMode)">Unpublish</button>
</div>

<ng-template #savingModal>
  <div class="modal-body py-3">
    <div class="d-flex justify-content-center align-items-center">
      <div class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-3">Saving question...</div>
      </div>
    </div>
  </div>
</ng-template>
