import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { AssignmentData, AssignmentExportRequest } from '@app/api/assignment/models/assignment-data.model';
import { Page, PageParams } from '@app/api/models/page.model';
import { Observable } from 'rxjs';
import { GitLabGroup } from '@app/api/integration/gitlab/models/gitlab-data.model';

@Injectable({
  providedIn: 'root'
})
export class AssignmentApiService {

  constructor(private http: HttpClient, private api: ApiService) { }

  createAssignment(assignment: AssignmentData) {
    return this.http.post<AssignmentData>(this.api.endpoint('assignments'), assignment);
  }

  getAssignment(id: number) {
    return this.http.get<AssignmentData>(this.api.endpoint(['assignments', id]));
  }

  updateAssignment(id: number, assignment: AssignmentData) {
    return this.http.put<AssignmentData>(this.api.endpoint(['assignments', id]), assignment);
  }

  publishAssignment(assignment: AssignmentData) {
    return this.http.post<AssignmentData>(this.api.endpoint(['assignments', 'publish']), assignment);
  }

  deleteAssignment(id: number) {
    return this.http.delete(this.api.endpoint(['assignments', id]));
  }

  getAssignmentsByOwner(ownerId: number | 'self', pageParams?: PageParams) {
    return this.http.get<Page<AssignmentData>>(this.api.endpoint(['/assignments', 'owner', ownerId]), {
      params: {
        ...pageParams
      }
    });
  }

  exportAssignment(request: AssignmentExportRequest) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob' as 'json',
    };
    return this.http.post<Blob>(this.api.endpoint(['assignments', 'export']), request, options);
  }

  approveAssignment(assignment: AssignmentData) {
    return this.http.post<AssignmentData>(this.api.endpoint(['assignments', 'approve']), assignment);
  }

  get assignmentGitLabSolutions(): Observable<GitLabGroup> {
    return this.http.get<GitLabGroup>(this.api.endpoint(['assignments', 'solutions', 'gitlab']));
  }

}
