<div class="d-flex flex-row justify-content-center gap-1" style="margin-top: 20px;">
  <app-search-bar-input [(search)]="searchTerm" (loadResults)="onSearchClick()"
    style="width: 500px;"></app-search-bar-input>
</div>
<div class="container-fluid" style="margin-top: 50px; margin-left: 0px;">
  <div *ngIf="loginUserRole != 'Admin'" class="row mb-3">
    <div class="col-6">
      <button class="btn btn-primary btn-sm" [routerLink]="['/discussion/new-discussion']">
        Start a Discussion
      </button>
    </div>
    <div class="col-6 text-end">
      <button class="btn btn-primary btn-sm" [routerLink]="['/discussion/my-discussions']">
        <i class="bi bi-chat-dots"></i> My Discussions
      </button>
    </div>
  </div>
  <div *ngIf="loginUserRole === 'Admin'" class="row mb-3">
    <div class="col-6">
      <button class="btn btn-primary btn-sm" [routerLink]="['/discussion/new-discussion']">
        Start a Discussion
      </button>
    </div>
    <div class="col-6 text-end">
      <button class="btn btn-primary btn-sm" [routerLink]="['/discussion/flagged-discussions']">
        <i class="bi bi-chat-dots"></i> Flagged Discussions
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-2 border-end border-dark">
      <div class="collapse" [ngClass]="{'show': isSidebarOpen}">
        <span class="bi bi-chevron-left" style="margin-left: 180px; cursor: pointer;" (click)="toggleSidebar()"></span>
        <h6 class="text-primary">
          <i class="bi bi-tags"></i> Technologies
        </h6>
        <div>
          <div *ngFor="let tag of popularTags" style="margin-top: 15px;">
            <button class="btn btn-primary btn-sm" (click)="selectTag(tag)"
              style="background-color: transparent; border-color: transparent; color: #7322a9;">
              <i class="bi bi-chat-right-dots"></i> {{ tag.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'col-10': isSidebarOpen, 'col-12': !isSidebarOpen}">
      <div class="row">
        <div class="d-flex flex-row justify-content-between">
          <div class="col-1">
            <div ngbDropdown [placement]="'bottom-left'">
              <span *ngIf="!isSidebarOpen" class="bi bi-chevron-right" style="margin-left: -12px; cursor: pointer;"
                (click)="toggleSidebar()"></span>
              <button class="btn btn-primary btn-sm" style="margin-left: 15px;" ngbDropdownToggle>
                {{ currentSortOption }}
              </button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" (click)="onSortOptionClick('Latest')">Latest</button>
                <button class="dropdown-item" (click)="onSortOptionClick('TopRated')">Top Rated</button>
                <button class="dropdown-item" (click)="onSortOptionClick('Oldest')">Oldest</button>
              </div>
            </div>
          </div>
          <div class="col-9 d-flex flex-wrap align-items-center">
            <div *ngFor="let tag of selectedTags" class="tag" style="margin-left: 30px;">
              <i class="bi bi-tag" style="margin-right: 5px;"></i>{{ tag.name }}
              <span class="remove-tag" (click)="removeTag(tag)" style="cursor: pointer;">&times;</span>
            </div>
          </div>
          <div *ngIf="loginUserRole === 'Admin'" class="col-2 text-end">
            <button class="btn btn-primary btn-sm" [routerLink]="['/discussion/my-discussions']">
              <i class="bi bi-chat-dots"></i> My Discussions
            </button>
          </div>
        </div>
      </div>
      <app-all-discussions [currentSortOption]="currentSortOption" [selectedTags]="selectedTags"
        [selectedTag]="selectedTag" [removedTag]="removedTag" [searchTerm]="searchTerm"></app-all-discussions>
    </div>
  </div>
</div>
