<div class="container-fluid my-2">

  <div class="row">
    <div class="col-12 col-md-7 mt-3 me-3">
      <h1 *ngIf="!questionFormService.isUpdateMode; else editTitle" class="h4">Create a Question</h1>
      <ng-template #editTitle>
        <h1 class="h4">Edit Question</h1>
      </ng-template>
      <div class="my-4">
        <app-question-form></app-question-form>
      </div>
    </div>
    <div class="col-12 col-md-3 mt-3 card ms-3">
      <div class="card-body">
        <h1 class="h4">Question Preview</h1>
        <div class="my-4">
          <app-question-preview [data]="questionData"></app-question-preview>
        </div>
      </div>
    </div>
  </div>

</div>
