<div class="row container-fluid">

  <div class="col-md-8 col-xl-6 my-2">
    <app-video-player [id]="videoId"></app-video-player>
  </div>

  <div class="col my-2 mx-2">
    <h3 class="h3 my-2">{{ videoMetadata?.title }}</h3>
    <app-markdown-viewer [data]="videoMetadata?.description??''"></app-markdown-viewer>
  </div>

</div>
