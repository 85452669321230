import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { CourseData } from '@app/api/models/learning-material-data.model';
import { MessageResponse } from '@app/api/models/message-response.model';
import { Page, PageParams } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root'
})
export class CourseApiService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  getCourseById(id: number) {
    return this.http.get<CourseData>(this.api.endpoint(['courses', id]));
  }

  getCoursesByOwner(ownerId: number | 'self', pageParams?: PageParams) {
    return this.http.get<Page<CourseData>>(this.api.endpoint(['/courses', 'owner', ownerId]), {
      params: {
        ...pageParams
      }
    });
  }

  createCourse(courseData: CourseData) {
    return this.http.post<CourseData>(this.api.endpoint(['courses']), courseData);
  }

  updateCourse(id: number, courseData: CourseData) {
    return this.http.put<CourseData>(this.api.endpoint(['courses', id]), courseData);
  }

  deleteCourse(id: number) {
    return this.http.delete<MessageResponse>(this.api.endpoint(['courses', id]));
  }
}
