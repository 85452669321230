<div class="container-fluid" style="margin-top: 40px;">
  <div class="row">
    <div class="col-md-6">
      <h6 class="text-primary">Flagged Discussions</h6>
      <div *ngFor="let discussion of flaggedDiscussions" class="card mb-3" style="margin-top: 20px;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title text-primary">{{ discussion.postTitle }}</h5>
            </div>
            <div class="col-md-4 text-end">
              <ng-container *ngFor="let tag of discussion.technologyTags">
                <div class="btn btn-secondary btn-sm"
                  style="margin-left: 5px; margin-right: 5px; background-color: lightgray !important; border-color: lightgrey !important; color: #2e2e2e !important;">
                  <i class="bi bi-tag"></i> {{ tag.name }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8" style="margin-top: 10px;">
              <p class="card-text">{{ discussion.content }}</p>
            </div>
            <div class="col-md-4 text-end" style="margin-top: 10px;">
              <small>Posted: {{ discussion.createdDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Updated: {{ discussion.updatedDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Comments: {{ discussion.commentCount }}</small>
            </div>
          </div>
          <div class="row">
            <div class="text-end">
              <button class="btn btn-sm btn-light" (click)="onDeleteThread(discussion.id)">
                <span class="bi bi-trash me-2 text-danger"></span>
              </button>
            </div> 
          </div>
        </div>
      </div>
      <div *ngIf="flaggedDiscussions.length === 0" class="card mb-3">
        <div class="card-body">
          <p class="card-text">No flagged discussions found.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h6 class="text-primary">Alert Discussions</h6>
      <div *ngFor="let discussion of alertsDiscussions" class="card mb-3" style="margin-top: 20px;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h5 class="card-title text-primary">{{ discussion.postTitle }}</h5>
            </div>
            <div class="col-md-4 text-end">
              <ng-container *ngFor="let tag of discussion.technologyTags">
                <div class="btn btn-secondary btn-sm"
                  style="margin-left: 5px; margin-right: 5px; background-color: lightgray !important; border-color: lightgrey !important; color: black !important;">
                  <i class="bi bi-tag"></i> {{ tag.name }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8" style="margin-top: 10px;">
              <p class="card-text">{{ discussion.content }}</p>
            </div>
            <div class="col-md-4 text-end">
              <small>Posted: {{ discussion.createdDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Updated: {{ discussion.updatedDate | date: 'dd MMM, yyyy' }}</small><br>
              <small>Comments: {{ discussion.commentCount }}</small>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="alertsDiscussions.length === 0" class="card mb-3">
        <div class="card-body">
          <p class="card-text">No alert discussions found.</p>
        </div>
      </div>
    </div>
  </div>
</div>