<div class="content" [ngClass]="{
  'sidebar-expanded': sidebarExpanded
}">
  <router-outlet></router-outlet>
</div>

<div class="d-flex flex-column vh-100 min-vw-100 position-fixed top-0 start pointer-events-none z-front">
  <app-nav-bar class="pointer-events"></app-nav-bar>
  <div class="flex-grow-1">
    <div class="d-flex flex-row h-100">
      <div class="h-100 flex-shrink-1">
        <app-side-bar class="pointer-events" [sideBarItems]="sideBarItems"></app-side-bar>
      </div>
    </div>
  </div>
</div>




