
<div class="card card-body my-5">

  <div class="d-flex flex-row flex-row-reverse gap-1">
    <app-search-bar-input [(search)]="search" (loadResults)="loadCohorts()" ></app-search-bar-input>
    <button class="btn btn-primary btn-sm" [routerLink]="['/training', 'cohorts', 'add']">
      <span class="bi bi-plus-circle"></span>
      New Cohort
    </button>
    <div>
      <div class="row align-items-start">
        <div class="col" ngbDropdown>
          <button type="button"
                  ngbDropdownToggle
                  ngbTooltip="Sort"
                  class="btn btn-light">
            <span class="bi bi-sort-alpha-down"></span>
            Sort by: <span class="mx-1">{{getLabel()}}</span>
          </button>
          <div ngbDropdownMenu>
            <button
              (click)="onSort( 'id')"
              ngbDropdownItem>
              ID
            </button>
            <button
              (click)="onSort( 'startDate')"
              ngbDropdownItem>
              Start Date
            </button>
            <button
              (click)="onSort('endDate')"
              ngbDropdownItem>
              End Date
            </button>

          </div>
        </div>
        <div class="col align-self-center">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input type="radio"
                     [(ngModel)]="sortDirection"
                     name="sortDirection"
                     value="asc"
                     (ngModelChange)="onSortDirectionChange()"
                     class="form-check-input"/>
              Ascending
            </label>
          </div>
        </div>
        <div class="col align-self-center">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input type="radio"
                     [(ngModel)]="sortDirection"
                     name="sortDirection"
                     value="desc"
                     (ngModelChange)="onSortDirectionChange()"
                     class="form-check-input"/>
              Descending
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card my-2 position-relative">
  <div class="card-body">
    <table class="table table-bordered  table-striped">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Start Date</th>
        <th scope="col">End Date</th>
        <th>Technologies</th>
        <th>Students</th>
        <th>
          <span class="visually-hidden">
            Actions
          </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let cohort of cohorts">
        <td>{{cohort.id}}</td>
        <td>
          <a class="link-info" [routerLink]="['/training', 'cohorts', cohort.id]">
            {{cohort.name}}
          </a>
        </td>
        <td>{{cohort.description}}</td>
        <td>
          <span class="text-nowrap">
            {{cohort.startDate | date: 'yyyy-MM-dd'}}
          </span>
        </td>
        <td>
          <span class="text-nowrap">
            {{cohort.endDate | date: 'yyyy-MM-dd'}}
          </span>
        </td>
        <td>
          <span *ngFor="let tech of cohort.technologies" class="badge bg-info me-1">{{tech}}</span>
        </td>
        <td>
          <button class="btn btn-link text-info" (click)="openViewStudentsModal(cohort)">
            {{cohort.students.length || 0}}
          </button>
        </td>
        <td>
          <div ngbDropdown>
            <button type="button" class="btn btn-outline-primary dropdown-no-caret border-0" ngbDropdownToggle>
              <span class="bi bi-three-dots-vertical"></span>
              <span class="visually-hidden">Actions</span>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem [routerLink]="['/training', 'cohorts', 'edit', cohort.id]">
                <span class="bi bi-pencil-square mx-2"></span>Edit
              </button>
              <button class="text-danger" ngbDropdownItem (click)="deleteCohort(cohort)">
                <span class="bi bi-trash mx-2"></span>Delete
              </button>
            </div>
          </div>
        </td>
      </tbody>
    </table>
    <div class="my-2 text-center d-flex justify-content-center align-items-center">
       <ngb-pagination [collectionSize]="page.total" [maxSize]="10" [boundaryLinks]="true" [(page)]="page.page" (pageChange)="loadCohorts()"></ngb-pagination>
    </div>
  </div>

  <div *ngIf="loading" class="position-absolute top-0 left-0 wh-100 bg-black-50 rounded d-flex justify-content-center align-items-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>


<ng-template #viewStudentsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Students</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <ul class="list-group" *ngIf="currentCohort && currentCohort.students && currentCohort.students.length; else noStudents">
      <li *ngFor="let student of currentCohort.students" class="list-group-item">
        <div class="my-2">
          <div class="mb-1">{{student.firstName}} {{student.lastName}}</div>
          <div class="text-info small"><span class="bi bi-envelope-at me-1"></span>{{student.email}}</div>
        </div>
      </li>
    </ul>
    <ng-template #noStudents>
      <div class="text-center text-muted">No students in this cohort</div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>
