import { Directive, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Breakpoint } from '@core/interfaces/breakpoint.enum';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appIfBreakpoint]'
})
export class IfBreakpointDirective implements OnInit {

  private breakpoint: Breakpoint = 'xs';
  resize$ = new Subject<{ windowWidth: number; parentWidth: number }>();

  @Input('appIfBreakpointDirection') direction: 'up' | 'down' = 'up';
  @Input() set appIfBreakpoint(breakpoint: Breakpoint) {
    this.breakpoint = breakpoint;
    const parentWidth = this.getParentDimensions().width;
    this.resize$.next({ windowWidth: window.innerWidth, parentWidth });
  }
  @Input() appIfBreakpointContainer?: Element;
  @Input() appIfBreakpointElse?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private elementRef: ElementRef
  ) {
    this.resize$.subscribe(({ windowWidth, parentWidth }) => {
      this.toggleElement(windowWidth, parentWidth);
    });
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    const parentWidth = this.getParentDimensions().width;
    this.resize$.next({ windowWidth: width, parentWidth });
  }

  ngOnInit() {
    const parentWidth = this.getParentDimensions().width;
    this.resize$.next({ windowWidth: window.innerWidth, parentWidth });
  }

  toggleElement(windowWidth: number, parentWidth: number) {

    let breakpointRange: number[] = [];
    switch (this.breakpoint) {
      case 'xs':
        breakpointRange = [0, 575];
        break;
      case 'sm':
        breakpointRange = [576, 767];
        break;
      case 'md':
        breakpointRange = [768, 991];
        break;
      case 'lg':
        breakpointRange = [992, 1199];
        break;
      case 'xl':
        breakpointRange = [1200, 1399];
        break;
      case 'xxl':
        breakpointRange = [1400, Infinity];
        break;
    }

    this.viewContainer.clear();
    if ((this.direction === 'up' && windowWidth >= breakpointRange[0] && parentWidth >= breakpointRange[0] ) ||
      (this.direction === 'down' && windowWidth <= breakpointRange[1] && windowWidth <= breakpointRange[1])) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.appIfBreakpointElse) {
      this.viewContainer.createEmbeddedView(this.appIfBreakpointElse);
    }
  }

  getParentDimensions(): { width: number; height: number } {
    let parentElement = this.elementRef.nativeElement.parentElement;
    if (this.appIfBreakpointContainer) {
      parentElement = this.appIfBreakpointContainer;
    }
    return {
      width: parentElement.offsetWidth,
      height: parentElement.offsetHeight
    };
  }
}
