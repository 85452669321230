<div class="wh-100">
  <iframe *ngIf="validFile; else invalidFileDisplay" [src]="iframeUrl | safe: 'resourceUrl'" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>

  <ng-template #invalidFileDisplay>
    <div class="bg-light wh-100 border rounded d-flex align-items-center justify-content-center">
      <div class="p-3">
        <div class="text-center mb-3">
          <div class="icon-stack">
            <span class="bi bi-file-earmark text-muted fs-1"></span>
            <span class="bi bi-exclamation-triangle text-muted"></span>
          </div>
        </div>
        <h4 class="text-center">Unable to show file</h4>
        <p class="text-center text-muted small">We are unable to display this file in your browser. Click the link below to download instead.</p>
        <p class="text-center"><a [href]="fileUrl" class="link-primary" target="_blank">View File</a></p>
      </div>
    </div>
  </ng-template>
</div>
