<nav class="navbar navbar-light bg-light p-0 m-0">
  <div class="container-fluid p-0 m-0">
    <div class="d-flex navbar-brand my-0 py-0">
      <button class="btn btn-light max-width-72 rounded-0 py-4" (click)="toggleSidebar()">
        <span class="bi bi-list mx-3 border-0 text-primary"></span>
      </button>
      <img ngSrc="assets/logos/logo@0.5x.png" class="logo-size mx-2" width="250" height="250" alt="Smoothstack Learn">
    </div>

    <div class="d-flex">
      <div class="d-flex flex-row mx-3 justify-content-end align-items-center">
        <app-notification-button></app-notification-button>
        <div class="d-flex flex-column">
          <span class="text-muted small">{{user?.role}}</span>
          <span class="text-primary fw-bold">{{user?.firstName}} {{user?.lastName}}</span>
        </div>
        <div ngbDropdown class="d-inline-block" [placement]="['bottom-start', 'bottom-end'] //noinspection ValidateTypes">
          <button class="btn btn-link" ngbDropdownToggle>
            <span *ngIf="loading; else userIcon" class="spinner-border text-secondary"></span>
            <ng-template #userIcon>
              <img *ngIf="profilePictureUrl; else defaultUserIcon"
                   [src]="profilePictureUrl"
                   class="rounded-circle"
                   alt=""
                   width="50"
                   height="50">
              <ng-template #defaultUserIcon>
                <span class="bi bi-person-circle fs-1 text-primary"></span>
              </ng-template>
            </ng-template>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem routerLink="/profile"><span class="bi bi-person-fill"></span> Profile</button>
            <button ngbDropdownItem routerLink="/settings"><span class="bi bi-gear-fill"></span> Settings</button>
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem class="text-danger" (click)="logout()"><span class="bi bi-arrow-right-square"></span> Sign Out</button>
            <div class="my-2 mx-3 small text-muted">
              <span class="d-inline-block">Client v{{version}}</span>
              <span *ngIf="!isProduction" class="d-inline-block text-warning">
              Non-production build
            </span>
              <div *ngIf="!isProduction" class="mt-1">
                <button (click)="openBuildInfoModal()" class="btn btn-sm btn-link px-0">
                  Build info...
                </button>
              </div>
            </div>
            <button *ngIf="canExitImpersonate()" class="btn btn-sm btn-link" (click)="exitImpersonation()">Exit Impersonation</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #buildInfoModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <span class="bi bi-gear-fill text-info"></span>
      Build Info
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="buildInfo" class="my-3">
        <h5 class="border-bottom mb-3 pb-2 d-flex justify-content-between">
          Client <span class="badge rounded-pill small bg-primary">{{buildInfo.environment}}</span>
        </h5>
        <div class="row my-1">
          <div class="col-4">Version</div>
          <div class="col-8 text-muted font-monospace">{{version}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Build Time</div>
          <div class="col-8 text-muted font-monospace">{{buildInfo.buildTime}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Build Number</div>
          <div class="col-8 text-muted font-monospace">{{buildInfo.buildNumber}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Commit</div>
          <div class="col-8 text-muted font-monospace">{{buildInfo.commit}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Branch</div>
          <div class="col-8 text-muted font-monospace">{{buildInfo.branch}}</div>
        </div>
      </div>
      <div *ngIf="apiBuildInfo" class="my-3">
        <h5 class="border-bottom mb-3 pb-2 d-flex justify-content-between">
          API <span class="badge rounded-pill small bg-primary">{{apiBuildInfo.environment}}</span>
        </h5>
        <div class="row my-1">
          <div class="col-4">Version</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.version}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Build Time</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.time}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Build Number</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.buildNumber}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Artifact</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.artifact}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Image Tag</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.imageTag}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Commit</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.commit}}</div>
        </div>
        <div class="row my-1">
          <div class="col-4">Branch</div>
          <div class="col-8 text-muted font-monospace">{{apiBuildInfo.branch}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

