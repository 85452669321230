import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModalOptions } from '@core/interfaces/global-modal-options.interface';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
})
export class GlobalModalComponent {
  @Input() options?: GlobalModalOptions;
  get modalOptions(): GlobalModalOptions {
    return {
      okButtonText: 'OK',
      okButtonCallback: () => this.closeModal(),
      cancelButtonText: 'Cancel',
      cancelButtonCallback: () => this.dismissModal(),
      showCancelButton: false,
      ...this.options!
    };
  }
  deleteInput = '';

  okCallback() {
    if (this.modalOptions.okButtonCallback) {
      this.modalOptions.okButtonCallback(this);
    }
  }

  cancelCallback() {
    if (this.modalOptions.cancelButtonCallback) {
      this.modalOptions.cancelButtonCallback(this);
    }
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  get isTemplate() {
    return this.modalOptions?.content instanceof TemplateRef;
  }

  get contentAsTemplate() {
    return this.modalOptions?.content as TemplateRef<any>;
  }

  get contentAsString() {
    return this.modalOptions?.content as string;
  }

  closeModal() {
    this.activeModal.close();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  disableOkButton() {
    if (this.modalOptions.deleteMode) {
      return this.deleteInput !== this.modalOptions.resourceName;
    }
    return false;
  }
}
