import { Routes } from '@angular/router';
import { LearningPageComponent } from '@pages/learning-page/learning-page.component';
import { authGuard } from '@auth/guards/auth.guard';
import { CourseActivityViewComponent } from '@app/learning/components/course-activity-view/course-activity-view.component';
import { ModuleActivityViewComponent } from '@app/learning/components/module-activity-view/module-activity-view.component';
import { LessonActivityViewComponent } from '@app/learning/components/lesson-activity-view/lesson-activity-view.component';
import { ActivityViewComponent } from '@app/learning/components/activity-view/activity-view.component';
import { QuizActivityViewComponent } from '@app/learning/components/quiz-activity-view/quiz-activity-view.component';

export const learningRoutes: Routes = [
  {
    path: 'learning',
    component: LearningPageComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/my-learning',
      },
      {
        path: 'course/:courseId',
        component: CourseActivityViewComponent,
      },
      {
        path: 'course/:courseId/module/:moduleId',
        component: ModuleActivityViewComponent,
      },
      {
        path: 'course/:courseId/module/:moduleId/lesson/:lessonId',
        component: LessonActivityViewComponent,
      },
      {
        path: 'course/:courseId/module/:moduleId/lesson/:lessonId/activity/:activityId',
        component: ActivityViewComponent,
      },
      {
        path: 'module/:moduleId',
        component: ModuleActivityViewComponent,
      },
      {
        path: 'module/:moduleId/:type/:activityId',
        component: ActivityViewComponent,
      },

      {
        path: 'module/:moduleId/:type/:activityId/:activity/:activityId',
        component: ActivityViewComponent,
      },
      {
        path: 'lesson/:lessonId',
        component: LessonActivityViewComponent,
      },
      {
        path: 'lesson/:lessonId/activity/:activityId',
        component: ActivityViewComponent,
      },
      {
        path: 'activity/:activityId',
        component: ActivityViewComponent,
      },
      {
        path: 'quiz/:activityId',
        component: QuizActivityViewComponent,
      },
    ],
  },
];
