import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { VideoUploadPreviewAndSubmitComponent } from '@app/api/video/components/video-upload-preview-and-submit/video-upload-preview-and-submit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AddUserFormComponent } from './user/components/add-user-form/add-user-form.component';
import { SearchMicrosoftUserFormComponent } from './user/components/search-microsoft-user-form/search-microsoft-user-form.component';
import { ForgotPasswordFormComponent } from './user/components/forgot-password-form/forgot-password-form.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { VerifyEmailModalComponent } from './user/components/verify-email-modal/verify-email-modal.component';
import { MarkdownModule } from 'ngx-markdown';
import { QuestionFormComponent } from './question/components/question-form/question-form.component';
import { QuestionPreviewComponent } from '@app/api/question/components/question-preview/question-preview.component';
import { QuestionChoiceGroupComponent } from './question/components/question-choice-group/question-choice-group.component';
import { QuestionTrueFalseChoiceGroupComponent } from './question/components/question-true-false-choice-group/question-true-false-choice-group.component';
import { QuestionFillInTheBlankAnswerComponent } from './question/components/question-fill-in-the-blank-answer/question-fill-in-the-blank-answer.component';
import { ViewUsersTableComponent } from './user/components/view-users-table/view-users-table.component';
import { EditUserComponent } from './user/components/edit-user/edit-user.component';
import { AssignmentComposerComponent } from './assignment/components/assignment-composer/assignment-composer.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AssignmentPreviewComponent } from './assignment/components/assignment-preview/assignment-preview.component';
import { SubmissionPreviewComponent } from './assignment/components/submission-preview/submission-preview.component';
import { RubricFormComponent } from './assignment/components/rubric-form/rubric-form.component';
import { OpenAssignmentsComponent } from './assignment/components/open-assignments/open-assignments.component';
import { ContentLibraryComponent } from './content-library/components/content-library/content-library.component';
import { ContentIconComponent } from './content-library/components/content-icon/content-icon.component';
import { GitlabGroupExplorerComponent } from '@app/api/integration/gitlab/components/gitlab-group-explorer/gitlab-group-explorer.component';
import { GroupViewComponent } from './integration/gitlab/components/group-view/group-view.component';
import { LessonFormComponent } from './lesson/components/lesson-form/lesson-form.component';
import { LessonPreviewComponent } from './lesson/components/lesson-preview/lesson-preview.component';
import { LearningMaterialSelectorComponent } from '@app/api/components/learning-material-selector/learning-material-selector.component';
import { ModuleFormComponent } from './module/components/module-form/module-form.component';
import { CourseFormComponent } from './course/components/course-form/course-form.component';
import { DeleteUserButtonComponent } from './user/components/delete-user-button/delete-user-button.component';
import { CourseTreeComponent } from './course/components/course-tree/course-tree.component';
import { ModuleTreeComponent } from './module/components/module-tree/module-tree.component';
import { LearningActivityViewComponent } from './learning/components/learning-activity-view/learning-activity-view.component';
import { AssignmentViewComponent } from './learning/components/assignment-view/assignment-view.component';
import { RubricPreviewComponent } from './assignment/components/rubric-preview/rubric-preview.component';
import { AssignmentSubmissionFormComponent } from './learning/components/assignment-submission-form/assignment-submission-form.component';
import { ModuleViewComponent } from './learning/components/module-view/module-view.component';
import { LessonTreeViewComponent } from './learning/components/lesson-tree-view/lesson-tree-view.component';
import { ModuleTreeViewComponent } from './learning/components/module-tree-view/module-tree-view.component';
import { CourseTreeViewComponent } from './learning/components/course-tree-view/course-tree-view.component';
import { LessonViewComponent } from './learning/components/lesson-view/lesson-view.component';
import { CourseViewComponent } from './learning/components/course-view/course-view.component';
import { LearningActivityViewPanelComponent } from './learning/components/learning-activity-view-panel/learning-activity-view-panel.component';
import { ViewEditLessonsComponent } from './lesson/components/view-edit-lessons/view-edit-lessons.component';
import { ViewEditModulesComponent } from './module/components/view-edit-modules/view-edit-modules.component';
import { ViewEditCourseComponent } from './course/components/view-edit-course/view-edit-course.component';
import { CohortFormComponent } from './training/components/cohorts/cohort-form/cohort-form.component';
import { ViewCohortsComponent } from './training/components/cohorts/view-cohorts/view-cohorts.component';
import { CreateTrainerComponent } from './training/components/trainers/create-trainer/create-trainer.component';
import { ViewTrainersComponent } from './training/components/trainers/view-trainers/view-trainers.component';
import { StudentFormComponent } from './training/components/students/student-form/student-form.component';
import { ViewStudentsComponent } from './training/components/students/view-students/view-students.component';
import { AssignmentGradingFormComponent } from './learning/components/assignment-grading-form/assignment-grading-form.component';
import { ProfileComponent } from './profile/components/profile-component/profile.component';
import { StudentEditFormComponent } from './training/components/students/student-edit-form/student-edit-form.component';
import { ViewStudentComponent } from './training/components/students/view-student/view-student.component';
import { DeleteTrainerComponent } from './training/components/trainers/delete-trainer/delete-trainer.component';
import { NotificationViewComponent } from './notifications/components/notification-view/notification-view.component';
import { ViewInboxComponent } from './messages/components/view-inbox/view-inbox.component';
import { UserSettingsComponent } from './user/components/user-settings/user-settings.component';
import { VideoUpdateFormComponent } from '@core/components/video-update-form/video-update-form.component';
import { QuizCreatorComponent } from './quiz/components/quiz-creator/quiz-creator.component';
import { QuestionSelectorForQuizComponent } from './quiz/components/question-selector-for-quiz/question-selector-for-quiz.component';
import { ViewEditQuizComponent } from './quiz/components/view-edit-quiz/view-edit-quiz.component';
import { ImportQuizComponent } from './quiz/components/import-quiz/import-quiz.component';
import { ImportUsersComponent } from './user/components/import-users/import-users.component';
import { QuizViewComponent } from './learning/components/quiz-view/quiz-view.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { QuizResultsComponent } from '@app/api/learning/components/quiz-results/quiz-results.component';
import { QuizPreviewComponent } from './quiz/components/quiz-preview/quiz-preview.component';

@NgModule({
  declarations: [
    VideoUploadPreviewAndSubmitComponent,
    AddUserFormComponent,
    SearchMicrosoftUserFormComponent,
    ForgotPasswordFormComponent,
    VerifyEmailModalComponent,
    QuestionFormComponent,
    QuestionPreviewComponent,
    QuestionChoiceGroupComponent,
    QuestionTrueFalseChoiceGroupComponent,
    QuestionFillInTheBlankAnswerComponent,
    ViewUsersTableComponent,
    EditUserComponent,
    AssignmentComposerComponent,
    AssignmentPreviewComponent,
    SubmissionPreviewComponent,
    RubricFormComponent,
    OpenAssignmentsComponent,
    ContentLibraryComponent,
    ContentIconComponent,
    GitlabGroupExplorerComponent,
    GroupViewComponent,
    LessonFormComponent,
    LessonPreviewComponent,
    LearningMaterialSelectorComponent,
    ModuleFormComponent,
    CourseFormComponent,
    DeleteUserButtonComponent,
    CourseTreeComponent,
    ModuleTreeComponent,
    LearningActivityViewComponent,
    AssignmentViewComponent,
    RubricPreviewComponent,
    AssignmentSubmissionFormComponent,
    ModuleViewComponent,
    LessonTreeViewComponent,
    ModuleTreeViewComponent,
    CourseTreeViewComponent,
    LessonViewComponent,
    CourseViewComponent,
    LearningActivityViewPanelComponent,
    ViewEditLessonsComponent,
    ViewEditModulesComponent,
    ViewEditCourseComponent,
    CohortFormComponent,
    ViewCohortsComponent,
    CreateTrainerComponent,
    ViewTrainersComponent,
    StudentFormComponent,
    ViewStudentsComponent,
    AssignmentGradingFormComponent,
    ProfileComponent,
    StudentEditFormComponent,
    ViewStudentComponent,
    DeleteTrainerComponent,
    NotificationViewComponent,
    ViewInboxComponent,
    UserSettingsComponent,
    VideoUpdateFormComponent,
    QuizCreatorComponent,
    QuestionSelectorForQuizComponent,
    ViewEditQuizComponent,
    ImportQuizComponent,
    ImportUsersComponent,
    QuizViewComponent,
    QuizResultsComponent,
    QuizPreviewComponent,
  ],
  exports: [
    VideoUploadPreviewAndSubmitComponent,
    AddUserFormComponent,
    ForgotPasswordFormComponent,
    QuestionFormComponent,
    QuestionPreviewComponent,
    ViewUsersTableComponent,
    OpenAssignmentsComponent,
    ContentLibraryComponent,
    LessonFormComponent,
    LessonPreviewComponent,
    ModuleFormComponent,
    CourseFormComponent,
    ModuleTreeComponent,
    CourseTreeComponent,
    LearningActivityViewComponent,
    LessonViewComponent,
    AssignmentViewComponent,
    ViewEditLessonsComponent,
    ViewEditModulesComponent,
    ViewEditCourseComponent,
    CohortFormComponent,
    ViewCohortsComponent,
    ContentIconComponent,
    ProfileComponent,
    StudentFormComponent,
    StudentEditFormComponent,
    ViewStudentsComponent,
    ViewStudentComponent,
    CreateTrainerComponent,
    ViewTrainersComponent,
    DeleteTrainerComponent,
    ViewInboxComponent,
    UserSettingsComponent,
    VideoUpdateFormComponent,
    UserSettingsComponent,
    QuizCreatorComponent,
    ViewEditQuizComponent,
    ImportQuizComponent,
    ImportUsersComponent,
    QuizViewComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    NgOptimizedImage,
    NgbModule,
    ClipboardModule,
    FormsModule,
    RouterLink,
    MarkdownModule,
    CodemirrorModule,
    RouterOutlet,
    RouterLinkActive,
    CdkStepperModule,
  ],
})
export class ApiModule {}
