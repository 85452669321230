<div class="d-flex justify-content-between border w-100 bg-light">
  <div class="d-flex justify-content-start align-items-center flex-grow-1">
    <div class="d-flex flex-row px-2">
      <button *ngIf="canGoBack" class="btn btn-light rounded-0" (click)="goBack()">
        <span class="bi bi-chevron-left"></span>
        <span class="visually-hidden">Back</span>
      </button>
      <div class="px-2 py-2">
        <span class="bi bi-file-earmark-plus-fill text-primary my-2"></span>
      </div>
    </div>
    <div class="btn-group">
      <div ngbDropdown class="btn-group" placement="bottom-left">
        <button ngbDropdownToggle class="btn btn-light rounded-0">
          Assignment Composer
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="newAssignment()">
            <div class="d-flex flex-row justify-content-between align-items-end">
              New <span class="text-muted small">Ctrl+K</span>
            </div>
          </button>
          <button ngbDropdownItem (click)="openOpenAssignmentModal()">
            <div class="d-flex flex-row justify-content-between align-items-end">
              Open <span class="text-muted small">Ctrl+O</span>
            </div>
          </button>
          <button ngbDropdownItem (click)="saveAssignment()" [disabled]="assignmentForm.invalid">
            <div class="d-flex flex-row justify-content-between align-items-end">
              Save <span class="text-muted small">Ctrl+S</span>
            </div>
          </button>
          <button ngbDropdownItem (click)="openImportAssignmentModal()">
            <div class="d-flex flex-row justify-content-between align-items-end">
              Import From File
            </div>
          </button>
          <div class="dropdown-divider"></div>
          <div #exportDropdown="ngbDropdown" ngbDropdown class="dropend w-100" placement="end-top">
            <button class="dropdown-item px-3 dropdown-toggle w-100 dropdown-no-caret d-flex justify-content-between align-items-center"
                    (mouseover)="exportDropdown.open()"
                    (focus)="exportDropdown.close()"
                    ngbDropdownToggle>
              Export to File <span class="right-caret"></span>
            </button>
            <div ngbDropdownMenu (mouseleave)="exportDropdown.close()">
              <button (click)="exportAssignment('MARKDOWN')" ngbDropdownItem>
                Markdown
              </button>
              <button (click)="exportAssignment('HTML')" ngbDropdownItem>
                HTML
              </button>
              <button ngbDropdownItem>
                Embed Code
              </button>
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <button (mouseover)="exportDropdown.close()"
                  (focus)="exportDropdown.close()"
                  ngbDropdownItem [disabled]="!canPublish" (click)="publishAssignment()">
            <span *ngIf="!published">Publish</span>
            <span *ngIf="published">Unpublish</span>
            <span *ngIf="!data?.id" class="small fst-italic">Not saved</span>
            <span *ngIf="!canPublish" class="small fst-italic ms-2 text-warning">Needs approval</span>
            <span *ngIf="published" class="small bi bi-check text-success"></span>
          </button>

          <button (mouseover)="exportDropdown.close()"
                  (focus)="exportDropdown.close()"
                  *ngIf="isAdmin" ngbDropdownItem [disabled]="!data?.id" (click)="approveAssignment()">
            <span *ngIf="!approved">Approve</span>
            <span *ngIf="approved">Unapprove</span>
            <span *ngIf="!data?.id" class="small fst-italic">Not saved</span>
            <span *ngIf="approved" class="small bi bi-check text-success"></span>
          </button>

        </div>
      </div>
      <div ngbDropdown class="btn-group" placement="bottom-left">
        <button ngbDropdownToggle class="btn btn-light rounded-0">
          Preview
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="showPreviewPanel = !showPreviewPanel">
            Toggle Preview Panel
          </button>
          <div class="dropdown-divider"></div>
          <div>
            <div class="btn-group w-100">
              <button (click)="openPreviewAssignmentModal()" ngbDropdownItem class="flex-grow-1">
                Open Preview
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mb-5 mt-2 h-100">
  <div class="d-flex justify-content-between nav-tabs">
    <div ngbNav>
      <div ngbNavItem>
        <button ngbNavLink (click)="setCurrentTab(assignmentContent, 'assignmentContent')"
                [class.active]="currentTabName === 'assignmentContent' || currentTabName === ''"
                [ngbTooltip]="formValueTitle"
                [disableTooltip]="formValueTitle.length <= 20">
        <span class="bi" [ngClass]="{
          'bi-file-earmark-text-fill text-primary': !assignmentDirty,
          'bi-file-earmark-text text-muted': assignmentDirty
        }"></span>
          <span *ngIf="published" class="small bi bi-check text-success" style="margin-left: -0.55em"></span>
          {{ assignmentTitle }}
        </button>
      </div>
      <div ngbNavItem>
        <button ngbNavLink (click)="setCurrentTab(rubricDetails, 'rubricDetails')"
                [class.active]="currentTabName === 'rubricDetails'">
        <span class="bi" [ngClass]="{
          'bi-file-earmark-spreadsheet-fill text-primary': !rubricDirty,
          'bi-file-earmark-spreadsheet text-muted': rubricDirty
        }"></span>
          Rubric
        </button>
      </div>
      <div ngbNavItem>
        <button ngbNavLink (click)="setCurrentTab(assignmentSolution, 'assignmentSolution')"
                [class.active]="currentTabName === 'assignmentSolution'">
          <span class="bi" [ngClass]="{
          'bi-check-circle-fill text-primary': !solutionDirty,
          'bi-check-circle text-muted': solutionDirty
        }"></span>
          Solution
        </button>
      </div>
      <div ngbNavItem>
        <button ngbNavLink (click)="setCurrentTab(relevantTechnologies, 'relevantTechnologies')"
                [class.active]="currentTabName === 'relevantTechnologies'">
        <span class="bi" [ngClass]="{
          'bi-tags-fill text-primary': !technologiesDirty,
          'bi-tags text-muted': technologiesDirty
        }"></span>
          Technology Tags
        </button>
      </div>
      <div ngbNavItem *ngIf="!showPreviewPanel">
        <button ngbNavLink (click)="setCurrentTab(previewAssignment, 'previewAssignment')"
                [class.active]="currentTabName === 'previewAssignment'">
        <span class="bi bi-eye-fill"></span>
          Preview Assignment
        </button>
      </div>
    </div>
    <div>
      <button *ngIf="showAddToLessonButton" (click)="saveAssignment()" type="button" class="btn btn-primary" [ngbTooltip]="'Add assignment to lesson'">
        <span class="bi bi-file-earmark-plus"></span>
        Add Assignment
      </button>
    </div>
  </div>
  <div class="row overflow-x-hidden overflow-y-visible pb-5 position-relative">
    <div class="col-12 pe-0 h-100" [class.col-md-7]="showPreviewPanel">
      <div class="p-4" [ngClass]="{
      'container': !showPreviewPanel
    }">
        <ng-container [ngTemplateOutlet]="currentTab || spinner"></ng-container>
      </div>
    </div>
    <div *ngIf="showPreviewPanel" class="col-12 col-md-5 ps-0 d-none d-lg-block h-100 border-lg-start">
      <div *ngIf="assignmentForm.dirty || assignmentForm.controls['id'].value" class="my-2 py-2 px-3 text-muted small border-bottom w-100">
          <span class="bi bi-eye"></span>
          Preview
      </div>
      <div *ngIf="assignmentForm.dirty || assignmentForm.controls['id'].value || isImported; else previewLabel" class="container">
        <div class="my-2 mx-3">
          <app-assignment-preview [data]="mapFormToAssignmentData()"></app-assignment-preview>
        </div>
      </div>
      <ng-template #previewLabel>
        <div class="container">
          <div class="text-black-50 text-center py-5">
            <h1 class="display-2">
              <span class="bi bi-eye-slash-fill"></span>
            </h1>
            <h3 class="display-6">
              Preview
            </h3>
            <p class="lead">
              The assignment preview will appear here.
            </p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="position-absolute top-0 end-0 w-100 text-end py-2 d-lg-block d-none">
      <span class="me-1 ps-2"
            [ngbTooltip]="showPreviewPanel ? 'Hide Preview' : 'Show Preview'"
            placement="left">
        <label for="togglePreview" class="form-check-label">
          <span class="bi me-2" [ngClass]="{
            'bi-eye-fill text-primary': !showPreviewPanel,
            'bi-eye-slash-fill text-muted': showPreviewPanel
          }"></span>
        </label>
        <span class="form-switch">
          <input type="checkbox"
                 id="togglePreview"
                 [(ngModel)]="showPreviewPanel"
                 (click)="checkPreviewPanel()"
                 class="form-check-input">
        </span>
      </span>
    </div>
  </div>
</div>

<ng-template [formGroup]="assignmentForm" #assignmentContent>
  <div class="my-3">
    <input type="text"
           formControlName="title"
           class="form-control-plaintext h3 border-bottom py-3 px-2"
           id="assignment-title-input"
           placeholder="Assignment Title">
  </div>
  <div class="my-3 border-bottom assignment-composer h-100">
    <div class="my-3 w-100">
      <textarea class="form-control-plaintext py-3 px-2 textarea-no-resize"
                formControlName="description"
                placeholder="Short description of the assignment."
                id="assignment-description-input"              
                style="height: 4.2em"
                (input)="updateCharacterCount()"></textarea>
      <div class="d-flex justify-content-between align-items-center">
        <app-control-validation-message [control]="getFormControl('description')"></app-control-validation-message>               
        <span class="flex-grow-3"></span> 
        <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
          {{ descriptionLength }}/{{ maxLength }}
        </span>
      </div>
    </div>
  </div>
  <div class="my-3 border-bottom assignment-composer h-100">
    <h4 class="mb-3">Overview</h4>
    <p class="text-muted">
      Overview of the assignment. Describe deliverables, requirements, etc...
    </p>
    <div class="my-3 w-100">
      <app-markdown-editor [showPreviewButton]="false"
                           [codeMirrorOptions]="{
                                  placeholder: 'Assignment overview.',
                                  lineWrapping: true
                                 }"
                           formControlName="overview"
                           [showToolbar]="false"
                           [hideUseMarkdownToggle]="true"
                           [showToolbarOnFocus]="true"></app-markdown-editor>
    </div>
  </div>
  <div class="my-3 assignment-composer">
    <h4 class="mb-3">Assignment</h4>
    <p class="text-muted">
      Explain assignment here. Should thoroughly describe what is expected of the student.
    </p>
    <div class="my-3 w-100">
      <app-markdown-editor [showPreviewButton]="false"
                           [codeMirrorOptions]="{
                                  placeholder: 'Assignment content. Include any code snippets, images, etc...',
                                  lineWrapping: true
                                 }"
                           formControlName="content"
                           [showToolbar]="false"
                           [hideUseMarkdownToggle]="true"
                           [showToolbarOnFocus]="true"></app-markdown-editor>
    </div>
  </div>
  <div formGroupName="submissionTypesGroup" class="my-3">
    <h4 class="mb-3">Completion</h4>
    <p class="text-muted">
      What does the student need to do to complete the assignment?
    </p>

    <div class="my-2">
      <label for="file-upload-switch" class="card card-body d-flex flex-row align-items-center">
        <div class="fs-1 me-2 bi bi-upload text-primary"></div>
        <div class="ms-2 flex-grow-1">
          <h5 class="card-title">
            Upload a File
          </h5>
          <p class="card-text text-muted small">
            Require the student to upload a file.
          </p>
        </div>
        <div class="ms-1">
          <div class="form-switch">
            <input class="form-check-input" formControlName="FILE_UPLOAD" type="checkbox" id="file-upload-switch">
          </div>
        </div>
      </label>
    </div>
    <div class="my-2">
      <label for="link-submission-switch" class="card card-body d-flex flex-row align-items-center">
        <div class="fs-1 me-2 bi bi-link-45deg text-primary"></div>
        <div class="ms-2 flex-grow-1">
          <h5 class="card-title">
            Submit Link(s)
          </h5>
          <p class="card-text text-muted small">
            Require the student submit a link to a file or website.
          </p>
        </div>
        <div class="ms-1">
          <div class="form-switch">
            <input class="form-check-input" formControlName="LINK" type="checkbox" id="link-submission-switch">
          </div>
        </div>
      </label>
    </div>
    <div class="my-2">
      <label for="text-input-switch" class="card card-body d-flex flex-row align-items-center">
        <div class="fs-1 me-2 bi bi-text-paragraph text-primary"></div>
        <div class="ms-2 flex-grow-1">
          <h5 class="card-title">
            Write a Response
          </h5>
          <p class="card-text text-muted small">
            Require the student to submit a text response.
          </p>
        </div>
        <div class="ms-1">
          <div class="form-switch">
            <input class="form-check-input" formControlName="TEXT" type="checkbox" id="text-input-switch">
          </div>
        </div>
      </label>
    </div>
    <div class="my-2">
      <label for="complete-button-switch" class="card card-body d-flex flex-row align-items-center">
        <div class="fs-1 me-2 bi bi-check-circle text-primary"></div>
        <div class="ms-2 flex-grow-1">
          <h5 class="card-title">
            Complete Button
          </h5>
          <p class="card-text text-muted small">
            Include a complete button for the student to click when they have completed the assignment. This will complete the assignment regardless of other submission requirements.
          </p>
        </div>
        <div class="ms-1">
          <div class="form-switch">
            <input class="form-check-input" type="checkbox" formControlName="COMPLETE_BUTTON" id="complete-button-switch">
          </div>
        </div>
      </label>
    </div>
  </div>
</ng-template>

<ng-template #rubricDetails>
  <div class="my-2">
    <h4 class="mb-3">Rubric</h4>
    <p class="text-muted">List the criteria and define expectations for each grade. Include key elements required for each performance level.</p>
  </div>
  <div class="my-2" [formGroup]="assignmentForm">
    <app-rubric-form></app-rubric-form>
  </div>
</ng-template>

<ng-template #relevantTechnologies [formGroup]="assignmentForm">

  <div class="my-2 pb-5 h-100">
    <h4 class="mb-3">Technology Tags</h4>
    <p class="text-muted">List the technologies that are relevant to this assignment.</p>
    <div class="mb-5">
      <app-tags-field formControlName="technologies"
                      placeholder="Add relevant technology"
                      [immediatelyShowDropdown]="true"
                      [tagResultsObservable]="technologyTags"
                      [restrictTags]="true"></app-tags-field>
    </div>
  </div>

</ng-template>

<ng-template #assignmentSolution [formGroup]="solutionFormGroup">
  <div class="my-2">
    <h4 class="mb-3">Solution Type</h4>
    <p class="text-muted">How will the assignment solution be presented?</p>
    <div class="my-3">
      <select formControlName="type" class="form-select">
        <option value="TEXT">Text</option>
        <option value="GITLAB_REPOSITORY">GitLab Repository</option>
      </select>
    </div>
    <div class="my-3" *ngIf="currentSolutionType === 'TEXT'">
      <app-markdown-editor formControlName="solution"
                           placeholder="Enter the assignment solution here..."
                           [codeMirrorOptions]="{
                              placeholder: 'Enter the assignment solution here...'
                            }"></app-markdown-editor>
    </div>
    <div class="my-3" *ngIf="currentSolutionType === 'GITLAB_REPOSITORY'">
      <div class="input-group">
        <div class="form-control" *ngIf="selectedProject; else selectAProjectPlaceholder">
          <div class="py-2 ps-2 d-flex align-items-center">
            <div class="bi bi-code-square text-primary me-3 fs-4"></div>
            <div class="text-start">
              <div class="mb-2">
                <div class="fw-bold">
                  {{selectedProject.name}}
                  <a [href]="selectedProject.url" target="_blank" class="ms-2 bi bi-box-arrow-up-right" ngbTooltip="Open Project in GitLab">
                    <span class="visually-hidden">Open Project</span>
                  </a>
                </div>
                <div class="text-muted small">{{selectedProject.nameWithNamespace}}</div>
              </div>
            </div>
            <div class="text-end flex-grow-1">
              <button type="button" class="btn btn-outline-danger btn-sm" (click)="selectedProject = undefined">
                <span class="bi bi-trash"></span>
                <span class="visually-hidden">Remove project</span>
              </button>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="openSelectGitLabSolutionModal()">
          <span class="bi bi-folder2-open me-1"></span>
          <span class="visually-hidden">Select a GitLab solution</span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #selectAProjectPlaceholder>
    <input type="text" (click)="openSelectGitLabSolutionModal()" class="form-control" readonly placeholder="Select a GitLab Solution..."/>
  </ng-template>
</ng-template>

<ng-template #selectGitlabSolutionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Select GitLab Solution</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <app-gitlab-group-explorer [group]="solutionsGroup" [(selectedProject)]="selectedProject"></app-gitlab-group-explorer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!selectedProject" (click)="modal.close('select')">Select</button>
  </div>
</ng-template>

<ng-template #previewAssignment>
  <div class="my-2">
    <h4 class="mb-3">Preview Assignment</h4>
    <p class="text-muted">Preview the assignment as a student would see it.</p>
    <div class="my-3">
      <app-assignment-preview [data]="mapFormToAssignmentData()"></app-assignment-preview>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="w-100 text-center py-2">
    <span class="spinner-border text-primary"></span>
  </div>
</ng-template>

<ng-template #assignmentPreview let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Assignment Preview</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="mx-2">
      <app-assignment-preview [data]="mapFormToAssignmentData()"></app-assignment-preview>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Close Preview</button>
  </div>
</ng-template>

<ng-template #openAssignmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Open Assignment</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <app-open-assignments (openAssignment)="onOpenAssignment($event)" [currentAssignmentId]="data?.id || 0" [showTitle]="false"></app-open-assignments>
  </div>
</ng-template>

<ng-template #importAssignmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Import Assignment</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <app-upload-field #uploadField [(ngModel)]="files" (ngModelChange)="processFile()" acceptedExtensions=".md"> </app-upload-field> 
  </div>
</ng-template>