<div class="my-2 p-3 border rounded">
  <label class="form-check">
    <input name="tf_choices"
           [checked]="value === true"
           [disabled]="isDisabled"
           [value]="true"
           (change)="onChange(true)"
           type="radio"
           class="form-check-input me-2"/>
    <span class="form-check-label">True</span>
  </label>
</div>
<div class="my-2 p-3 border rounded">
  <label class="form-check">
    <input name="tf_choices"
           type="radio"
           [checked]="value === false"
           [disabled]="isDisabled"
           [value]="false"
           (change)="onChange(false)"
           class="form-check-input me-2"/>
    <span class="form-check-label">False</span>
  </label>
</div>
