<div *ngIf="!loading && quiz && quizLearningActivity; else loader" class="container">
  <app-quiz-view
    [learningActivity]="quizLearningActivity"
    [quiz]="quiz"
    [submission]="submission">
  </app-quiz-view>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure structureType="lesson"></app-activity-skeleton-structure>
</ng-template>
