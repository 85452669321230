import { Component, TemplateRef, ViewChild } from '@angular/core';
import { User } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { EmailVerificationService } from '@app/api/user/services/email-verification.service';
import { PasswordResetService } from '@app/api/user/services/password-reset.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { GlobalToastService } from '@core/services/global-toast.service';
import { map } from 'rxjs';
import { MultiSelectOption } from '@core/components/form-controls/multi-select-field/multi-select-field.component';
import { UserUpdateRequest } from '@app/api/user/models/user-update-request.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass']
})
export class EditUserComponent {

  user?: User;
  profilePictureUrl?: string;
  sendingVerificationEmail = false;
  sendingPasswordResetEmail = false;
  statusToggled = false;
  selectedUserRoles: string[] = [];
  allRoles: MultiSelectOption[] = [];
  currentUserRole : string = '';

  @ViewChild('updateRolesModal') updateRolesModal!: TemplateRef<any>;

  constructor(private userService: UserService,
    private emailVerificationService: EmailVerificationService,
    private passwordResetService: PasswordResetService,
    private modalService: GlobalModalService,
    private toastService: GlobalToastService,
    private route: ActivatedRoute) {
      this.userService.getSelfRole().subscribe({
        next: (res) => {
          this.currentUserRole = res;
        },
      });
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.userService.getUserById(id).subscribe({
        next: user => {
          this.user = user;
          this.statusToggled = user.enabled;
        }
      });

      this.userService.fetchProfilePicture(id).subscribe({
        next: url => {
          this.profilePictureUrl = url;
        }
      });
    });
  }

  get userEmail() {
    return this.user?.email || '';
  }

  sendVerificationEmail() {
    this.sendingVerificationEmail = true;
    this.emailVerificationService.sendVerificationEmail(this.userEmail).subscribe({
      next: () => {
        this.modalService.alert({
          title: 'Email sent',
          content: `Verification email sent to ${this.userEmail}`,
          type: 'success'
        });
      },
      complete: () => {
        this.sendingVerificationEmail = false;
      }
    });
  }

  sendPasswordResetEmail() {
    this.sendingPasswordResetEmail = true;
    this.passwordResetService.initiatePasswordReset(this.userEmail).subscribe({
      next: () => {
        this.modalService.alert({
          title: 'Email sent',
          content: `Password reset email sent to ${this.userEmail}`,
          type: 'success'
        });
      },
      complete: () => {
        this.sendingPasswordResetEmail = false;
      }
    });
  }

  toggleUserStatus() {
    if (this.user?.id) {
      const requestBody = this.statusToggled;
      
      this.userService.toggleUserStatus(this.user.id, requestBody).subscribe({
        next: () => {
          this.toastService.show({
            content: `User status has been ${this.statusToggled ? 'enabled' : 'disabled'}`,
            type: 'success',
          });
        },
        error: (error) => {
          this.statusToggled = !this.statusToggled;
          console.error('Error:', error);
  
          this.toastService.show({
            title: 'Error',
            content: 'Failed to update user status',
            type: 'danger',
          });
        },
      });
    } else {
      console.error('User ID is undefined. Cannot toggle user status.');
    }
  }
  

  get currentUserRoles() {
    return [...(this.user?.roles || [])];
  }

  openUpdateRolesModal() {
    this.userService.getAllRoles()
      .pipe(map(roles => roles.map(role => {
        return {
          label: role,
          value: role,
        };
      } ))
      ).subscribe({
        next: roles => {
          this.allRoles = roles;
          this.selectedUserRoles = this.currentUserRoles || [];
          const modal = this.modalService.alert({
            title: 'Update Roles',
            content: this.updateRolesModal,
            type: 'info',
            showCancelButton: true,
            okButtonText: 'Update'
          });
          modal.closed.subscribe({
            next: () => {
              this.updateUserRoles(this.selectedUserRoles);
            }
          });
        }
      });
  }

  updateUserRoles(roles: string[]) {
    const user: UserUpdateRequest = {
      email: this.user?.email || '',
      firstName: this.user?.firstName || '',
      lastName: this.user?.lastName || '',
      roles: roles,
      enabled: this.user?.enabled || false
    };

    this.userService.updateUser(this.user?.id || 0, user).subscribe({
      next: (user) => {
        this.modalService.alert({
          title: 'Roles updated',
          content: `Roles updated for ${this.user?.email}`,
          type: 'success'
        });

        this.user = user;
      }
    });
  }

}
