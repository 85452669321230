import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, PageableParams } from '@app/api/models/page.model';
import { Trainer } from '@app/api/models/trainer.model';
import { ApiService } from '@app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TrainerApiService {
  constructor(private http: HttpClient, private api: ApiService) {}

  getTrainer(id: number) {
    return this.http.get<Trainer>(
      this.api.endpoint(`/training/trainers/${id}`)
    );
  }

  getAllTrainers(
    search?: string,
    technologies: string[] = [],
    pageable?: PageableParams
  ) {
    return this.http.get<Page<Trainer>>(
      this.api.endpoint('/training/trainers'),
      {
        params: {
          search: search || '',
          technologies: technologies || [],
          ...pageable,
        },
      }
    );
  }

  createTrainer(trainer?: Trainer) {
    return this.http.post<Trainer>(
      this.api.endpoint('/training/trainers'),
      trainer
    );
  }

  updateTrainer(trainer: Trainer, id?: number) {
    return this.http.put<Trainer>(
      this.api.endpoint(`/training/trainers/${id}`),
      trainer
    );
  }

  deleteTrainer(id?: number) {
    return this.http.delete<string>(
      this.api.endpoint(`/training/trainers/${id}`)
    );
  }
}
