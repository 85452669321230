import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CreateBulkQuestionRequest } from '@app/api/question/models/create-question-request.model';
import { BulkQuestionFileProcessorService } from '@app/api/question/services/bulk-question-file-processor.service';
import { getQuestionTypeLabel, QuestionType } from '@app/api/question/models/question-data-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { QuestionResponse } from '@app/api/question/models/question-response.model';
import { UploadFieldComponent } from '@core/components/form-controls/upload-field/upload-field.component';
import { ExportFileService } from '@core/services/export-file.service';

@Component({
  selector: 'app-import-questions-page',
  templateUrl: './import-questions-page.component.html',
  styleUrls: ['./import-questions-page.component.sass']
})
export class ImportQuestionsPageComponent {

  files?: File[];
  currentIndex = -1;
  bulkQuestionRequest?: CreateBulkQuestionRequest;
  saving = false;
  savedQuestions: QuestionResponse[] = [];

  @ViewChild('removeQuestionModal') removeQuestionModal!: TemplateRef<any>;
  @ViewChild('previewModal') previewModal!: TemplateRef<any>;
  @ViewChild('savedQuestionsListModal') savedQuestionsListModal!: TemplateRef<any>;
  @ViewChild('uploadField') uploadField!: UploadFieldComponent;

  constructor(private questionApi: QuestionApiService,
    private bulkProcessor: BulkQuestionFileProcessorService,
    private modal: NgbModal,
    private exportFile: ExportFileService,
    private globalModal: GlobalModalService) {
  }

  processFile() {
    if (this.files && this.files[0]) {
      this.bulkProcessor.processFile(this.files[0]).subscribe({
        next: request => {
          if (request.questions && request.questions[0]) {
            this.bulkQuestionRequest = request;
            this.currentIndex = 0;
          }
        }
      });
    }
  }

  trackByIndex(index: number): any {
    return index;
  }

  get currentQuestion() {
    return this.bulkQuestionRequest?.questions[this.currentIndex];
  }

  set currentQuestion(value) {
    if (this.bulkQuestionRequest && value) {
      this.bulkQuestionRequest.questions[this.currentIndex] = value;
    }
  }

  getQuestionTypeLabel(questionType: QuestionType): string {
    return getQuestionTypeLabel(questionType);
  }

  addQuestion() {
    if (this.bulkQuestionRequest) {
      this.bulkQuestionRequest.questions.push({
        title: '',
        difficulty: 'EASY',
        published: false,
        questionType: 'MULTIPLE_CHOICE',
        text: '',
        tags: [],
        technologies: [],
        categories: [],
        choices: [
          {
            choiceText: '',
            correct: false
          },
          {
            choiceText: '',
            correct: false
          },
          {
            choiceText: '',
            correct: false
          },
          {
            choiceText: '',
            correct: false
          }
        ]
      });
      this.currentIndex = this.bulkQuestionRequest.questions.length - 1;
    }
  }

  removeQuestion(index: number) {
    if (this.bulkQuestionRequest) {
      this.bulkQuestionRequest.questions.splice(index, 1);
      if (this.currentIndex === index) {
        // Set currentIndex to the previous question if it exists, otherwise set it to the next question if it exists.
        this.currentIndex = this.bulkQuestionRequest.questions[index - 1] ? index - 1 : index;
      }
    }
  }

  openRemoveQuestionModal(index: number) {
    this.modal.open(this.removeQuestionModal, {
      backdrop: 'static',
      keyboard: false
    }).result.then(() => {
      this.currentIndex = index;
      this.removeQuestion(index);
    });
  }

  openPreviewModal(index: number) {
    this.currentIndex = index;
    if (!this.currentQuestion) {
      return;
    }
    this.modal.open(this.previewModal, {
      size: 'xl'
    });
  }

  showSavingModal() {
    return this.globalModal.spinner('Saving questions...', {
      centered: true
    });
  }

  showSaveSuccessModal() {
    return this.globalModal.alert({
      title: 'Questions Saved',
      type: 'success',
      size: 'lg',
      content: this.savedQuestionsListModal
    });
  }

  saveQuestion(publish?: boolean) {
    this.saving = true;
    const saveModal = this.showSavingModal();
    if (!this.bulkQuestionRequest) {
      console.error('No bulk question request found.');
      return;
    }

    // Override the published flag
    this.bulkQuestionRequest.overridePublished = publish;

    this.questionApi.createBulkQuestions(this.bulkQuestionRequest)
      .subscribe({
        next: (questions) => {
          this.clearFiles();
          // Show save success message
          saveModal.dismiss();
          this.savedQuestions = questions;
          this.showSaveSuccessModal();
        },
        error: () => {
          saveModal.dismiss();
          this.saving = false;
        },
        complete: () => {
          this.saving = false;
        }
      });
  }

  openEditQuestionInAnotherWindow(question: QuestionResponse) {
    window.open(`/course-composer/question-creator/edit/${question.id}`, '_blank');
  }

  clearFiles() {
    this.files = [];
    this.bulkQuestionRequest = undefined;
    this.uploadField.clearFiles();
  }

  exportJson() {
    if (this.bulkQuestionRequest) {
      this.exportFile.exportJson(this.bulkQuestionRequest, 'questions.json');
    }
  }

}
