<div class="my-2 border-bottom pb-3">
    <form (ngSubmit)="initiateSearch()">
      <div class="input-group">
        <input type="text"
               name="search"
               class="form-control"
               placeholder="Search"
               [(ngModel)]="search"
               (ngModelChange)="initiateSearch()">
        <button class="btn btn-light border"
                type="button"
                (click)="hideTechnologiesFilter = !hideTechnologiesFilter">
          <span class="bi bi-filter"></span>
        </button>
      </div>
      <div [ngbCollapse]="hideTechnologiesFilter">
        <div class="my-3">
          <app-technology-tags-field placeholder="Filter by technology"
                                     name="technologies"
                                     (ngModelChange)="initiateSearch()"></app-technology-tags-field>
        </div>
      </div>
    </form>
  </div>
  
  <div class="list-group list-group-flush">
    <ng-container *ngIf="!loading; else spinnerListItem">
      <button 
              *ngFor="let question of responses"
              class="list-group-item list-group-item-action">
        <div class="d-flex w-100 align-items-center">
          <div class="me-3 my-2 align-self-start">
            <input type="checkbox" class="form-check-input" 
                   [checked]="isQuestionSelected(question)"
                   (change)="onSelectedQuestions(question)">
          </div>
          <div class="flex-grow-1">
            <div class="my-2">
              <div class="fw-bold">{{question.title}}</div>
              <div class="d-flex flex-wrap gap-1 align-items-center mb-3">
                <span *ngFor="let technology of question.technologies"
                      class="badge small rounded-pill bg-primary">{{technology}}</span>
                </div>
              <p class="mb-3 small">{{question.description}}</p>
            </div>
          </div>
        </div>
      </button>
      <div class="my-2 text-center d-flex justify-content-center align-items-center">
        <ngb-pagination [collectionSize]="page.total" [maxSize]="10" [boundaryLinks]="true" [(page)]="page.page" (pageChange)="loadQuestions()"></ngb-pagination>
      </div>
    </ng-container>
    <ng-template #spinnerListItem>
      <div class="list-group-item">
        <div class="text-center my-2">
          <span class="spinner-border text-primary"></span>
        </div>
      </div>
    </ng-template>
  </div>
  