import { Component, HostListener, Input } from '@angular/core';
import { AbstractCoreFormControlComponent } from '@core/components/forms/abstract-core-form-control.component';
import { FormGroupDirective } from '@angular/forms';
import { DropdownSelectOption } from '@core/components/forms/dropdown-select-field/dropdown-select-option.interface';

@Component({
  selector: 'app-dropdown-select-field',
  templateUrl: './dropdown-select-field.component.html',
  styleUrls: ['./dropdown-select-field.component.sass']
})
export class DropdownSelectFieldComponent extends AbstractCoreFormControlComponent {
  value: any;
  @Input() options: DropdownSelectOption[] | any[] = [];
  @Input() optionMapper: (option: any) => DropdownSelectOption = (option: any) => {
    if (option.value && option.label)
      return option as DropdownSelectOption;
    return { value: option, label: option } as DropdownSelectOption;
  };
  @Input() multiple = false;
  @Input() placeholder = 'Select an option';
  isOpen = false;

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!event.target.closest('[ngbDropdownMenu]') && !event.target.closest('[ngbDropdownToggle]')) {
      this.isOpen = false;
    }
  }

  constructor(formGroupDirective: FormGroupDirective) {
    super(formGroupDirective);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  override ngOnInit(): void {
    super.ngOnInit();
    // TODO: Add multiple select functionality
    this.options = this.options.map(option => {
      const mappedOption = this.optionMapper(option);
      if (this.multiple) {
        mappedOption.checked = this.value.includes(mappedOption.value);
      }
      return mappedOption;
    });
    this.value = this.multiple ? this.value : [this.value];
    this.control?.valueChanges.subscribe(value => {
      this.value = value;
    });
  }

  setValue(value: any): void {
    if (!this.multiple) {
      this.value = [value];
      this.control?.setValue(this.value[0]);
      return;
    }
    this.control?.setValue(this.value);
  }

  selectOption(option: any): void {
    this.setValue(option);
  }

  updateValueFromChecked() {
    // TODO: Add multiple select functionality
  }

}
