import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-assignment-composer-home-page',
  templateUrl: './assignment-composer-home-page.component.html',
  styleUrls: ['./assignment-composer-home-page.component.sass']
})
export class AssignmentComposerHomePageComponent {

  items: RouteCardItem[] = [
    {
      title: 'Start New Assignment',
      description: 'Create a new assignment from scratch. Customize deliverables and define a rubric.',
      icon: 'bi-file-earmark-plus',
      routerLink: '/assignment-composer',
    },
    {
      title: 'Continue Existing Assignment',
      description: 'Edit an existing assignment.',
      icon: 'bi-file-earmark-text',
      routerLink: '/course-composer/create-assignment/open'
    },
    {
      title: 'Assignment Templates',
      description: 'Download a template to create an assignment offline.',
      icon: 'bi-download',
      routerLink: '/course-composer/create-assignment/templates'
    }
  ];

}
