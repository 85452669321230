import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { TechnologyType } from '@app/api/discussion/models/technologyType';
import { NewDiscussionService } from '@app/api/discussion/service/new-discussion.service';
import { GlobalToastService } from '@app/core/services/global-toast.service';
@Component({
  selector: 'app-new-discussion',
  templateUrl: './new-discussion.component.html',
  styleUrls: ['./new-discussion.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NewDiscussionComponent,
    multi: true
  }]
})
export class NewDiscussionComponent implements OnInit {

  @Output() postCreated: EventEmitter<Discussion> = new EventEmitter<Discussion>();
  @Output() commentCreated: EventEmitter<Discussion> = new EventEmitter<Discussion>();
  @Input() parentPostId: number | null = null;
  discussionForm!: FormGroup;
  selectedTechnologyTypes: TechnologyType[] = [];

  postTypes = [
    { id: 1, name: 'Post' },
    { id: 2, name: 'Comment' },
  ];

  constructor(
    private fb: FormBuilder,
    private discussionService: NewDiscussionService,
    private toastService: GlobalToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
      this.discussionForm = new FormGroup({
      postTitle: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]),
      content: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(255)
      ]),
      postTypeId: new FormControl(1, [
        Validators.required,
      ]),
      parentPostId: new FormControl(this.parentPostId),
      technologyTags: new FormControl([]),
      technologyType: new FormControl('', [
        Validators.required,
      ]),
    });
    console.log('parentId : ' + this.parentPostId);
    this.route.queryParams.subscribe((params) => {
      this.parentPostId = params['parentId'] || null;
      this.updateFormValues();
    });
    this.discussionForm.get('postTypeId')?.valueChanges.subscribe((postTypeId) => {
      this.handlePostTypeChange(postTypeId);
    });
  }

  updateFormValues(): void {
    if (this.parentPostId) {
      // This is a reply (comment)
      this.discussionForm.patchValue({
        postTitle: '',
        content: '',
        postTypeId: 2, // Set to comment
      });
    } else {
      // This is a new post
      this.discussionForm.patchValue({
        postTitle: '',
        content: '',
        postTypeId: 1, // Set to post
      });
    }
  }

  handlePostTypeChange(postTypeId: number): void {
    const parentPostIdControl = this.discussionForm.get('parentPostId');

    if (postTypeId === 2 && this.parentPostId !== null) {
      parentPostIdControl?.setValue(this.parentPostId);
    } else {
      parentPostIdControl?.setValue(null);
    }
    parentPostIdControl?.setValidators(postTypeId === 2 ? [Validators.required] : null);
    parentPostIdControl?.updateValueAndValidity();
  }

  handleSelectedTechnologyTypesChange(selectedTechnologyTypes: TechnologyType[]) {
    const selectedTechnologyType = selectedTechnologyTypes.length > 0 ? selectedTechnologyTypes[0] : null;
    this.discussionForm.get('technologyType')?.setValue(selectedTechnologyType);
  }

  publishPost(): void {
    const formData = {
      content: this.discussionForm.get('content')?.value,
      postTypeId: this.discussionForm.get('postTypeId')?.value,
      parentPostId: this.discussionForm.get('parentPostId')?.value,
      postTypeName: '',
      postTitle: this.discussionForm.get('postTitle')?.value,
      technologyTags: this.discussionForm.get('technologyTags')?.value,
      technologyType: this.discussionForm.get('technologyType')?.value,
    };
    console.log('FormData:', formData);

    formData.postTypeName = formData.postTypeId === 1 ? 'POST' : 'COMMENT';

    if (formData.postTypeName === 'COMMENT' && !formData.parentPostId) {
      formData.parentPostId = this.parentPostId;
    }
    this.discussionService.publishPost(formData).subscribe(
      (response) => {
        console.log('Post published successfully:', response);
        this.toastService.showSuccessToast('Post Published Successfully');
        this.postCreated.emit(response);
        // If it's a comment, add it to the comments array
        if (formData.postTypeName === 'COMMENT') {
          this.commentCreated.emit(response);
        }
        this.discussionForm.reset();
        this.selectedTechnologyTypes = [];
        this.discussionForm.get('content')?.setValue('');
      },
      (error) => {
        console.error('Error publishing post:', error);
        this.toastService.showDangerToast('Error While Publishing...');
      }
    );
  }
}
