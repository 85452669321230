<div class="list-group list-group-flush" *ngIf="courseData; else spinner">

  <ng-container *ngIf="courseData.title">
    <button type="button"
            (click)="modulesCollapse = !modulesCollapse"
            class="list-group-item list-group-item-action">
      <div class="d-flex justify-content-between">
        <div class="me-2">
          <div class="d-flex">
            <span class="bi bi-journal-bookmark-fill text-primary me-1"></span>
            <div>
              <div class="fw-bold">{{courseData.title}}</div>
              <div class="mb-1">
                <span class="badge bg-info rounded-pill small me-1" *ngFor="let techTag of courseData.technologies">
                  {{techTag}}
                </span>
              </div>
              <div class="d-none d-md-block text-muted small text-wrap">
                {{courseData.description}}
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="courseData.modules && courseData.modules.length" class="bi bi-chevron-down align-self-center"></span>
      </div>
    </button>

    <div class="list-group-item p-0 m-0 list-group-item-light">
      <div class="list-group list-group-flush" [ngbCollapse]="modulesCollapse">
        <li *ngFor="let module of courseData.modules" class="list-group-item p-0 m-0">
          <app-module-tree [indent]="true" [moduleData]="module"></app-module-tree>
        </li>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #spinner>
  <div class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
