import { Component } from '@angular/core';
import { KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';

@Component({
  selector: 'app-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.sass']
})
export class DashboardGridComponent {

  widgetLayout: KtdGridLayout = [
    { id: 'widget_recent_activities', x: 0, y: 0, w: 2, h: 4, minW: 2, minH: 3 },
    // Add more widgets here...
  ];

  trackById = ktdTrackById;

  onLayoutUpdate(layout: KtdGridLayout) {
    // Layout has been updated
  }

}
