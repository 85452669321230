import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';


export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };


export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[appSortable]'
})
export class SortableDirective {

  @Input() sortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  @HostBinding('class.asc') get asc() {
    return this.direction === 'asc';
  }

  @HostBinding('class.desc') get desc() {
    return this.direction === 'desc';
  }

  @HostBinding('style.cursor') get cursor() {
    return 'pointer';
  }

  @HostListener('click', ['$event']) onClick() {
    this.direction = rotate[this.direction];

    this.sort.emit({ column: this.sortColumn, direction: this.direction });
  }


}
