import { Component, Input } from '@angular/core';
import { SubmissionType } from '@app/api/assignment/models/assignment-data.model';

@Component({
  selector: 'app-submission-preview',
  templateUrl: './submission-preview.component.html',
  styleUrls: ['./submission-preview.component.sass']
})
export class SubmissionPreviewComponent {

  @Input() submissionType: SubmissionType = 'COMPLETE_BUTTON';

  responseText = '';
  responseSaved = false;

  linkSubmissions: string[] = [];
  linkText = '';

  urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].\S*$/i;

  addLinkSubmission() {
    if (this.linkText && this.linkText.match(this.urlRegex)) {
      this.linkSubmissions.push(this.linkText);
      this.linkText = '';
    }
  }

}
