import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { CoreModule } from "../core/core.module";
import { AppRoutingModule } from '@app/app-routing.module';
import { ForumPageComponent } from './components/forum-page/forum-page.component';
import { NewDiscussionComponent } from './components/forum-page/new-discussion/new-discussion.component';
import { ApiModule } from "../api/api.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MyDiscussionsComponent } from './components/forum-page/my-discussions/my-discussions.component';
import { AllDiscussionsComponent } from './components/forum-page/all-discussions/all-discussions.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewDiscussionComponent } from './components/forum-page/view-discussion/view-discussion.component';
import { TechnologyTypeSelectFieldComponent } from './components/forum-page/new-discussion/technology-type-select-field/technology-type-select-field.component';
import { FlaggedDiscussionComponent } from './components/forum-page/flagged-discussion/flagged-discussion.component';


@NgModule({
    declarations: [
        LandingPageComponent,
        ForumPageComponent,
        NewDiscussionComponent,
        MyDiscussionsComponent,
        AllDiscussionsComponent,
        ViewDiscussionComponent,
        TechnologyTypeSelectFieldComponent,
        FlaggedDiscussionComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        ApiModule,
        NgbDropdownModule
    ]
})
export class DiscussionModule { }
