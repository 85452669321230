import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentActivitiesWidgetComponent } from './components/recent-activities-widget/recent-activities-widget.component';
import { RouterLink } from '@angular/router';



@NgModule({
  declarations: [
    RecentActivitiesWidgetComponent
  ],
  exports: [
    RecentActivitiesWidgetComponent
  ],
  imports: [
    CommonModule,
    RouterLink
  ]
})
export class WidgetsModule { }
