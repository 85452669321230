<div ngbDropdown [autoClose]="false" [(open)]="showDropdown" placement="bottom-right" class="position-relative">
  <div class="input-group" [ngClass]="{ 'input-group-sm': small }">
    <div class="form-control d-flex flex-row flex-wrap gap-1" (click)="showDropdown = !showDropdown" tabindex="-1" (keydown.enter)="showDropdown = !showDropdown">
      <div *ngFor="let option of selectedOptions" class="rounded border bg-light px-2">
        <ng-container *ngIf="option">
          <span *ngIf="option.icon" [ngClass]="[ 'bi', option.icon, 'me-2' ]"></span>
          {{option.label}}
          <button [disabled]="selectedOptions.length <= minCount"
                  class="btn btn-link text-dark px-0 mx-0"
                  (click)="toggleOption($event, option)">
            <span class="bi bi-x"></span>
          </button>
        </ng-container>
      </div>
      <div *ngIf="selectedOptions.length === 0" class="text-muted">
        {{placeholder}}
      </div>
    </div>
    <button class="btn btn-outline-primary dropdown-no-caret" [ngClass]="{ 'px-3': small }" type="button" ngbDropdownToggle>
      <span class="visually-hidden">Select options</span>
      <span class="bi bi-chevron-down small"></span>
    </button>
  </div>
  <div ngbDropdownMenu class="w-100" [style.max-height.px]="170" style="overflow-y: auto;">
    <div *ngIf="filterField" ngbDropdownItem>
      <div class="input-group">
        <input class="form-control" [placeholder]="filterPlaceholder" [(ngModel)]="search" (input)="onSearchInputChange()"/>
        <button class="btn btn-outline-secondary" (click)="clearFilter()">
          <span class="bi bi-x"></span>
        </button>
      </div>
    </div>
    <button *ngFor="let option of _options" ngbDropdownItem (click)="toggleOption($event, option)" [disabled]="optionSelected(option)">
      <div class="d-flex align-items-center gap-2">
        <span *ngIf="option.icon" [ngClass]="[ 'bi', option.icon ]"></span>
        <span>{{option.label}}</span>
      </div>
    </button>
  </div>
</div>
