import { Component, DestroyRef, OnInit } from '@angular/core';
import { VideoService } from '@app/api/video/services/video.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-video-metadata-update-page',
  templateUrl: './video-update-page.component.html',
  styleUrls: ['./video-update-page.component.sass']
})
export class VideoUpdatePageComponent {

}
