import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserRequest } from '../../models/create-user-request.model';
import { UserService } from '../../services/user.service';
import { catchError, of } from 'rxjs';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.sass']
})
export class ImportUsersComponent {

  files: File[] = [];
  createUserRequests: CreateUserRequest[] = [];

  constructor(
    private ngbModal: NgbModal,
    private userService: UserService,
    private toastService: GlobalToastService,
    private router: Router
  ) {}

  processFile() {
    if (this.files && this.files[0]) {
      const selectedFile = this.files[0];
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension === 'csv') {
        this.readCsvFile(selectedFile);
      } else {
        console.log('Invalid file type');
      }
    }
  }

  readCsvFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = reader.result?.toString();
      if (csvData) {
        const lines = csvData.split(/\r?\n/);
        const createUserRequests: CreateUserRequest[] = [];
        
        for (let i = 1; i < lines.length; i++) {
          const line = lines[i];
          const [email, firstName, lastName, role, password, invite] = line.split(',').map(item => item.trim());
          
         
          if (email && firstName && lastName && role && password && invite) {
            const formattedRole = role.toUpperCase().replace(' ', '_');
            const inviteValue = invite.toLowerCase() === 'true';
            const user: CreateUserRequest = {
              email,
              firstName,
              lastName,
              role: formattedRole,
              password: password,
              confirmPassword: password,
              invite: inviteValue,
            };
            createUserRequests.push(user);
          }
        }

        this.userService.createUsers(createUserRequests)
          .pipe(catchError((error) => {
            this.toastService.showDangerToast(error.error.message);
            return of(null);
          }))
          .subscribe({
            next: (createUserRequests) => {
              if (!createUserRequests) return;
              this.createUserRequests = createUserRequests;
              this.toastService.showSuccessToast('Users successfully created!');
              setTimeout(() => {
                this.router.navigate(['/user-management']);
              }, 2000);
            }
          });
      }
      this.ngbModal.dismissAll();
    };
    reader.readAsText(file);
  } 

  submitImport() {
    this.processFile();
  }
}
