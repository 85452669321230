import { Routes } from '@angular/router';
import { TrainingHomePageComponent } from '@pages/training-page/training-home-page/training-home-page.component';
import { CohortsPageComponent } from '@pages/training-page/cohorts-page/cohorts-page.component';
import { TrainersPageComponent } from '@pages/training-page/trainers-page/trainers-page.component';
import { StudentsPageComponent } from '@pages/training-page/students-page/students-page.component';
import { CohortsAddPageComponent } from '@pages/training-page/cohorts-add-page/cohorts-add-page.component';
import { TrainersAddPageComponent } from '@pages/training-page/trainers-add-page/trainers-add-page.component';
import { StudentsAddPageComponent } from '@pages/training-page/students-add-page/students-add-page.component';
import { CohortsEditPageComponent } from '@pages/training-page/cohorts-edit-page/cohorts-edit-page.component';
import { CohortViewPageComponent } from '@pages/training-page/cohort-view-page/cohort-view-page.component';
import {
  TrainerViewPageComponent
} from '@pages/training-page/trainers-page/trainer-view-page/trainer-view-page.component';
import {
  StudentViewPageComponent
} from '@pages/training-page/students-page/student-view-page/student-view-page.component';
import { StudentsEditPageComponent } from '@pages/training-page/students-edit-page/students-edit-page.component';
import { TrainersEditPageComponent } from '@app/pages/training-page/trainers-edit-page/trainers-edit-page.component';

export const TrainingResourcesRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    data: { title: 'Training' },
    component: TrainingHomePageComponent
  },
  {
    path: 'cohorts',
    data: { title: 'Cohorts' },
    component: CohortsPageComponent
  },
  {
    path: 'cohorts/add',
    data: { title: 'Create new Cohort' },
    component: CohortsAddPageComponent
  },
  {
    path: 'cohorts/edit/:id',
    data: { title: 'View/Edit Cohort' },
    component: CohortsEditPageComponent
  },
  {
    path: 'cohorts/:id',
    data: { title: 'View Cohort' },
    component: CohortViewPageComponent
  },
  {
    path: 'trainers',
    data: { title: 'Trainers' },
    component: TrainersPageComponent
  },
  {
    path: 'trainers/add',
    data: { title: 'Create new Trainer' },
    component: TrainersAddPageComponent
  },
  {
    path: 'trainers/edit/:id',
    data: { title: 'View/Edit Trainer' },
    component: TrainersEditPageComponent
  },
  {
    path: 'trainers/:id',
    data: { title: 'View Trainer' },
    component: TrainerViewPageComponent
  },
  {
    path: 'students',
    data: { title: 'Students' },
    component: StudentsPageComponent
  },
  {
    path: 'students/add',
    data: { title: 'Create new Student' },
    component: StudentsAddPageComponent
  },
  {
    path: 'students/edit/:id',
    data: { title: 'View/Edit Student' },
    component: StudentsEditPageComponent
  },
  {
    path: 'students/:id',
    data: { title: 'View Student' },
    component: StudentViewPageComponent
  },
];
