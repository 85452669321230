<div class="container">

    <div class="card card-body my-4" *ngIf="trainer; else spinner">
        <div class="my-2 d-flex justify-content-between align-items-start gap-3">

            <div class="d-flex align-items-center gap-3">
                <div style="width: 120px; height: 120px" class="d-flex justify-content-center align-items-center">
                    <img *ngIf="profilePictureUrl; else spinner" [src]="profilePictureUrl"
                        class="img-fluid img-thumbnail rounded-circle" alt="" />
                </div>

                <div class="d-flex flex-column">
                    <div class="h2">{{trainer.firstName}} {{trainer.lastName}}</div>
                    <div [ngbTooltip]="trainer.invite ? 'Email invited' : 'Email not invited'" placement="end">
                        <span class="bi bi-envelope-fill text-info"></span>
                        {{trainer.email}}
                        <span class="bi bi-check-circle-fill text-success" *ngIf="trainer.invite"></span>
                    </div>
                    <div class="my-1">
                        <span *ngFor="let technologies of trainer.technologies"
                            class="badge rounded-pill bg-info text-white me-1">{{technologies}}</span>
                    </div>
                </div>

            </div>

            <div>
                <button class="btn btn-primary" type="button"
                    [routerLink]="['/training', 'trainers', 'edit', trainer.id]">
                    Edit<span class="bi bi-pencil-square"></span>
                </button>
            </div>
        </div>

    </div>

    <div class="list-group list-group-flush">
        <div class="list-group-item py-3">
            <h4>Password Reset</h4>
            <p>Send a password reset link to the trainer's email address.</p>
            <button type="button" [disabled]="sendingPasswordResetEmail" (click)="sendPasswordResetEmail()"
                class="btn btn-primary">
                Reset Password <span *ngIf="!sendingPasswordResetEmail; else inlineSpinner"
                    class="bi bi-key-fill"></span>
            </button>
        </div>
        <div class="list-group-item py-3">
            <h4>Technologies</h4>
            <p>Assign one or multiple technologies to a trainer.</p>
            <div class="my-2">
                <span *ngFor="let technologies of trainer.technologies"
                    class="bg-info text-light small border rounded p-1 px-2 me-1">
                    {{technologies}}
                </span>
            </div>
            <button type="button" (click)="updateTechnologies()" class="btn btn-primary">
                Update Technologies <span class="bi bi-book-half"></span>
            </button>
        </div>

        <div class="list-group-item py-3">
            <h4>Delete Trainer</h4>
            <p>Delete the Trainer and remove access to all their resources.</p>

            <app-delete-trainer [deleteTrainerData]="trainer">
                <ng-template let-openDeleteTrainerModal="openDeleteTrainerModal">
                    <button type="button" class="btn btn-danger" (click)="openDeleteTrainerModal()">
                        Delete Trainer
                        <span class="bi bi-trash-fill"></span>
                    </button>
                </ng-template>
            </app-delete-trainer>
        </div>
    </div>

    <ng-template #spinner>
        <div class="spinner-border text-primary"></div>
    </ng-template>

    <ng-template #inlineSpinner>
        <span class="spinner-border spinner-border-sm"></span>
    </ng-template>

    <ng-template #updateTechnologiesContent>
        <form [formGroup]="updateTechnologiesForm" class="my-3">
            <app-technology-tags-field formControlName="technologies"></app-technology-tags-field>
        </form>
    </ng-template>
</div>