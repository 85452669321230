import { Component, Input, OnInit } from '@angular/core';
import { RatingLevel, RubricData } from '@app/api/assignment/models/assignment-data.model';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ManageActivitiesUpdateGradeService } from '../../services/manage-activities-update-grade.service';

@Component({
  selector: 'app-assignment-grading-form',
  templateUrl: './assignment-grading-form.component.html',
  styleUrls: ['./assignment-grading-form.component.sass']
})
export class AssignmentGradingFormComponent implements OnInit {

  @Input() rubric!: RubricData;
  @Input() learningActivity!: LearningActivity;

  scoredPoints = 0;
  totalPoints = 0;
  grade = 0;
  useRubricCriteria = false;
  useRubricScoring = false;
  overrideGrade = false;
  graded = false;

  criteriaScores: number[] = [];

  saving = false;

  constructor(private learningActivityService: LearningActivityService, private manageActivitiesUpdateGrade: ManageActivitiesUpdateGradeService) {
  }

  ngOnInit() {
    this.totalPoints = this.rubric.criteria.length * 5;
    this.useRubricCriteria = this.rubric && this.rubric.criteria && this.rubric.criteria.length > 0;
    this.useRubricScoring = this.rubric && this.rubric.criteria && this.rubric.criteria.length > 0;
    this.criteriaScores = this.rubric.criteria.map(c => 0);
    this.grade = this.learningActivity.submission?.score || 0;
    this.graded = this.learningActivity.submission?.graded || false;
    this.overrideGrade = this.noRubric;
  }

  calculateGrade() {
    this.grade = this.scoredPoints / this.totalPoints;
  }

  calculatePoints() {
    this.scoredPoints = this.criteriaScores.reduce((a, b) => a + b, 0);
    this.calculateGrade();
  }

  get noRubric() {
    return !this.rubric || !this.rubric.criteria || this.rubric.criteria.length === 0;
  }

  saveGrade() {
    this.saving = true;

    if (this.grade > 1) {
      this.grade = 1;
    }

    this.learningActivityService.updateLearningActivity(this.learningActivity.id, {
      action: 'GRADE',
      grade: this.grade
    }).subscribe(learningActivity => {
      this.learningActivity = learningActivity;
      this.graded = true;
      this.saving = false;
      this.manageActivitiesUpdateGrade.gradeUpdated.emit({ id: this.learningActivity.id, grade: this.grade });
    });
  }

  cancelGrade() {
    this.graded = true;
    this.grade = this.learningActivity.submission?.score || 0;
  }

  get learningActivityGraded() {
    return this.learningActivity.submission?.graded || false;
  }

  editGrade() {
    this.graded = false;
    this.grade = 0;
  }

  getPointsFromRatingLevel(ratingLevel: RatingLevel) {
    switch (ratingLevel) {
      case 'EXCELLENT':
        return 5;
      case 'GREAT':
        return 4;
      case 'GOOD':
        return 3;
      case 'FAIR':
        return 2;
      case 'POOR':
        return 1;
      default:
        return 0;
    }
  }

}
