import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Discussion } from '@app/api/discussion/models/discussion.model';
import { DiscussionsService } from '@app/api/discussion/service/discussions.service';
import { UserService } from '@app/api/user/services/user.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { User } from '@app/api/user/models/user.model';

@Component({
  selector: 'app-view-discussion',
  templateUrl: './view-discussion.component.html',
  styleUrls: ['./view-discussion.component.sass']
})
export class ViewDiscussionComponent {

  discussion: Discussion | undefined;

  postPictureUrl?: string;

  comments: Discussion[] = [];

  showComment: Boolean = false;

  userId?: number;

  firstName?: string;

  lastName?: string;

  commentForm!: FormGroup;

  discussionBookmarked: boolean = false;

  sortedComments: Discussion[] = [];

  currentSortOption: string = 'Latest';

  showCommentStates: boolean[] = [];

  loginUserRole: string = 'Admin';

  isThreadFlagged: boolean = false;

  isThreadAlert: boolean = false;

  isCommentAlert: boolean = false;

  isCommentFlagged: boolean = false;

  commentBookmarked: boolean = false;

  adminDiscussion: Discussion[] = [];

  showCommentReplyStates: boolean[] = [];

  myCommentsOfPost: Discussion[] = [];

  hierarchicalComments: { comment: Discussion, replies: Discussion[] }[] = [];

  commentIndentCounter: number = 0;

  localStorageKey: string = '';

  @Input() parentPostId: number | null = null;
  @Output() postCreated: EventEmitter<Discussion> = new EventEmitter<Discussion>();
  @Output() commentCreated: EventEmitter<Discussion> = new EventEmitter<Discussion>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private discussionsService: DiscussionsService,
    private router: Router,
    private globalModalService: GlobalModalService,
    private toastService: GlobalToastService,
    private cdr: ChangeDetectorRef,
  ) {
    this.userService.fetchProfilePicture(this.userId).subscribe({
      next: url => {
        this.postPictureUrl = url;
      }
    });
    this.userService.getSelf().subscribe(user => {
      const userId = user.id;
      const firstName = user.firstName;
      const lastName = user.lastName;
      console.log("user firstName -> " + firstName);
      if (!userId) {
        console.error('User is not logged in');
        return;
      }
      this.userId = userId;
      this.firstName = firstName;
      this.lastName = lastName;
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const postId = params['id'];
      if (postId) {
        this.commentForm = this.fb.group({
          comment: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
          postTypeId: [2, Validators.required],
          parentPostId: [postId],
          parentCommentId: ['']
        });
        this.fetchDiscussionDetails(postId);
        this.fetchComments(postId);
        this.userService.getSelfRole().subscribe((role: string) => {
          console.log("user role => [" + role + "]");
          this.loginUserRole = role;
        }, (error) => {
          const errorMessage = 'Error: ' + error.error.message;
          this.globalModalService.alert({
            content: errorMessage,
            type: 'danger'
          });
          console.error('Error fetching User Role:', error);
        });
      }
    });
    this.retrieveCommentFlagAndAlertStatus();
  }

  private retrieveCommentFlagAndAlertStatus(): void {
    this.comments.forEach(comment => {
      this.isCommentFlagged = comment.isFlagged;
      this.isCommentAlert = comment.isAlert;
    });
  }

  private populateMyCommentsOfPost(): void {
    this.myCommentsOfPost = [];
    console.log("populateMyCommentsOfPost => comments => ", this.comments);
    this.comments.forEach(comment => {
      console.log("Processing comment:", comment);
      if (this.userId === comment.userId) {
        this.myCommentsOfPost.push(comment);
      }
    });
    console.log("MyComments Of Post:", this.myCommentsOfPost);
  }

  private populateHierarchicalComments(): void {
    this.hierarchicalComments = [];
    console.log("commnets => ", this.comments);
    this.comments.forEach(comment => {
      console.log("Processing comment:", comment);
      const parentIndex = this.hierarchicalComments.findIndex(item => item.comment.id === comment.parentCommentId);
      if (parentIndex !== -1) {
        this.hierarchicalComments[parentIndex].replies.push(comment);
      } else {
        this.hierarchicalComments.push({ comment, replies: [] });
      }
    });
    console.log("Hierarchical comments:", this.hierarchicalComments);
  }

  onSortOptionClick(sortOption: string): void {
    this.currentSortOption = sortOption;
    this.sortComments(sortOption);
  }

  toggleShowComment(index: number): void {
    this.showCommentStates[index] = !this.showCommentStates[index];
    localStorage.setItem('showCommentStates', JSON.stringify(this.showCommentStates));
  }

  fetchDiscussionDetails(postId: number): void {
    this.discussionsService.getPostById(postId).subscribe(
      (post) => {
        this.discussion = post;
        console.log('Discussion details:', this.discussion);
        this.discussionBookmarked = this.discussion.isBookmarked;
        this.isThreadFlagged = this.discussion.isFlagged;
        this.isThreadAlert = this.discussion.isAlert;
      },
      (error) => {
        console.error('Error fetching post details:', error);
      }
    );
  }

  fetchComments(postId: number): void {
    this.discussionsService.getCommentsForPost(postId).subscribe(
      (comments) => {
        console.log('Comments for post:', comments);
        this.comments = comments;
        this.comments = comments.map(comment => ({ ...comment, editing: false }));
        this.populateMyCommentsOfPost();
        this.showCommentStates = new Array(comments.length).fill(false);
        this.comments.forEach(comment => {
          this.isCommentFlagged = comment.isFlagged;
          this.isCommentAlert = comment.isAlert;
        });
        this.populateHierarchicalComments();
      },
      (error) => {
        console.error('Error fetching comments:', error);
      }
    );
  }

  goToNewDiscussionPage(): void {
    this.router.navigate(['/discussion/new-discussion'], { queryParams: { parentId: this.discussion?.id } });
  }

  addComment(comment: Discussion): void {
    const formData = {
      content: this.commentForm.get('comment')?.value,
      postTypeId: this.commentForm.get('postTypeId')?.value,
      parentPostId: comment.id || null,
      postTypeName: '',
    };

    formData.postTypeId = 2;
    formData.postTypeName = 'COMMENT';

    this.discussionsService.publishComment(formData).subscribe(
      (response) => {
        console.log('Comment Added Successfully:', response);
        this.toastService.showSuccessToast('Comment Added Successfully');
        this.postCreated.emit(response);
        this.commentCreated.emit(response);
        this.comments.push(response);
        this.populateHierarchicalComments();
        console.log('showComment before setting to false:', this.showComment);
        this.commentForm.reset();
        this.showComment = false;
        this.showCommentStates = this.showCommentStates.map(() => false);
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error adding comment:', error);
        this.toastService.showDangerToast('Error While Adding Comment...');
      }
    );
  }

  addUpvote(): void {
    if (this.discussion) {
      const postId = this.discussion.id;
      this.discussionsService.addUpvoteToPost(postId).subscribe(
        (updatedPost) => {
          console.log('Upvote added successfully:', updatedPost);
          if (this.discussion) {
            this.discussion.upVote = updatedPost.upVote;
            this.cdr.detectChanges();
          }
        },
        (error) => {
          console.error('Error adding upvote:', error);
        }
      );
    }
  }

  addCommentUpvote(comment: Discussion): void {
    this.discussionsService.addUpvoteToPost(comment.id).subscribe(
      (updatedComment) => {
        console.log('Upvote added successfully:', updatedComment);
        comment.upVote = updatedComment.upVote;
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error adding upvote:', error);
      }
    );
  }

  toggleCommentEditing(index: number): void {
    this.comments.forEach((c) => {
      c.editing = false;
    });
    const comment = this.comments[index];
    comment.editing = !comment.editing;
    this.commentForm.get('comment')?.setValue(comment.content);
  }

  updateComment(commentId: number): void {
    const editedComment = this.commentForm.get('comment')?.value;
    const postTypeId = this.commentForm.get('postTypeId')?.value;
    const parentPostId = this.commentForm.get('parentPostId')?.value;
    const index = this.comments.findIndex(comment => comment.editing);
    if (index !== -1) {
      this.discussionsService.updateComment(commentId, editedComment, postTypeId, parentPostId).subscribe(
        (updatedComment) => {
          console.log('Comment updated successfully:', updatedComment);
          this.comments[index] = updatedComment;
          this.comments[index].editing = false;
        },
        (error) => {
          console.error('Error updating comment:', error);
        }
      );
    }
  }

  deleteComment(commentId: number): void {
    const message = 'Are you sure you want to delete this comment?';

    this.globalModalService.confirm(message)
      .subscribe((result) => {
        if (result) {
          console.log(`Deleting comment with ID: ${commentId}`);
          this.discussionsService.deleteComment(commentId).subscribe(
            () => {
              this.fetchComments(this.discussion!.id);
            },
            (error) => {
              console.error('Error deleting comment:', error);
            }
          );
        }
      });
  }

  sortComments(sortOption: string): void {
    if (sortOption === 'Latest') {
      this.comments.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    } else if (sortOption === 'Oldest') {
      this.comments.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    }
  }

  toggleFlagThread(): void {
    if (!this.discussion) {
      console.error('Discussion is not available');
      return;
    }
    const postId = this.discussion.id;
    const isThreadFlagged = this.isThreadFlagged;

    if (!isThreadFlagged) {
      this.flagThread(postId);
    } else {
      this.removeFlag(postId);
    }
  }

  flagThread(postId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }
    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.discussionsService.flagThread(postId).subscribe(
          () => {
            console.log('Thread flagged successfully.');
            this.isThreadFlagged = true;
            this.globalModalService.alert({
              content: 'Thread has been flagged successfully.',
              type: 'success'
            });
            console.log("isThreadFlagged => " + this.isThreadFlagged);
          },
          (error) => {
            console.error('Error flagging thread:', error);
            this.globalModalService.alert({
              content: 'Error flagging thread.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can flag threads.');
        this.globalModalService.alert({
          content: 'Only admins can flag threads.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

  removeFlag(postId: number): void {
    this.discussionsService.removeFlag(postId).subscribe(
      () => {
        console.log('Thread unflagged successfully.');
        this.isThreadFlagged = false;
        this.globalModalService.alert({
          content: 'Thread has been unflagged successfully.',
          type: 'danger'
        });
        console.log("isThreadFlagged => " + this.isThreadFlagged);
      },
      (error) => {
        console.error('Error unflagging thread:', error);
        this.globalModalService.alert({
          content: 'Error unflagging thread.',
          type: 'danger'
        });
      }
    );
  }

  toggleAlertThread(): void {
    if (!this.discussion) {
      console.error('Discussion is not available');
      return;
    }
    const postId = this.discussion.id;
    const isThreadAlert = this.isThreadAlert;

    if (!isThreadAlert) {
      this.alertThread(postId);
    } else {
      this.removeAlert(postId);
    }
  }

  alertThread(postId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }
    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.discussionsService.alertThread(postId).subscribe(
          () => {
            console.log('Thread Alert successfully.');
            this.isThreadAlert = true;
            this.globalModalService.alert({
              content: 'Thread has been Alert successfully.',
              type: 'success'
            });
            console.log("isThreadAlert => " + this.isThreadAlert);
          },
          (error) => {
            console.error('Error Alert thread:', error);
            this.globalModalService.alert({
              content: 'Error Alert thread.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can alert threads.');
        this.globalModalService.alert({
          content: 'Only admins can alert threads.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

  removeAlert(postId: number): void {
    this.discussionsService.removeAlert(postId).subscribe(
      () => {
        console.log('Thread alert removed successfully.');
        this.isThreadAlert = false;
        this.globalModalService.alert({
          content: 'Thread alert has been removed successfully.',
          type: 'danger'
        });
        console.log("isThreadAlert => " + this.isThreadAlert);
      },
      (error) => {
        console.error('Error removing alert from thread:', error);
        this.globalModalService.alert({
          content: 'Error removing alert from thread.',
          type: 'danger'
        });
      }
    );
  }

  toggleFlagComment(commentId: number): void {
    if (!this.comments) {
      console.error('Comments are not available');
      return;
    }
    const isCommentFlagged = this.comments.find(comment => comment.id === commentId)?.isFlagged;

    if (isCommentFlagged === undefined) {
      console.error('Comment with ID ' + commentId + ' not found.');
      return;
    }

    if (!isCommentFlagged) {
      this.flagComment(commentId);
    } else {
      this.removeFlagFromComment(commentId);
    }
  }

  flagComment(commentId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }
    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.discussionsService.flagThread(commentId).subscribe(
          () => {
            console.log('Comment flagged successfully.');
            const commentIndex = this.comments.findIndex(comment => comment.id === commentId);
            if (commentIndex !== -1) {
              this.comments[commentIndex].isFlagged = true;
            }
            this.globalModalService.alert({
              content: 'Comment has been flagged successfully.',
              type: 'success'
            });
            console.log("isCommentFlagged => " + this.comments[commentIndex].isFlagged);
          },
          (error) => {
            console.error('Error flagging comment:', error);
            this.globalModalService.alert({
              content: 'Error flagging comment. Comment Already Flagged.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can flag comments.');
        this.globalModalService.alert({
          content: 'Only admins can flag comments.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

  removeFlagFromComment(commentId: number): void {
    this.discussionsService.removeFlag(commentId).subscribe(
      () => {
        console.log('Comment unflagged successfully.');
        const commentIndex = this.comments.findIndex(comment => comment.id === commentId);
        if (commentIndex !== -1) {
          this.comments[commentIndex].isFlagged = false;
        }
        this.globalModalService.alert({
          content: 'Comment has been unflagged successfully.',
          type: 'success'
        });
        console.log("isCommentFlagged => " + this.comments[commentIndex].isFlagged);
      },
      (error) => {
        console.error('Error unflagging comment:', error);
        this.globalModalService.alert({
          content: 'Error unflagging comment.',
          type: 'danger'
        });
      }
    );
  }

  toggleAlertComment(commentId: number): void {
    if (!this.comments) {
      console.error('Comments are not available');
      return;
    }
    const isCommentAlert = this.comments.find(comment => comment.id === commentId)?.isAlert;

    if (isCommentAlert === undefined) {
      console.error('Comment with ID ' + commentId + ' not found.');
      return;
    }

    if (!isCommentAlert) {
      this.setCommentAlert(commentId);
    } else {
      this.removeAlertFromComment(commentId);
    }
  }

  setCommentAlert(commentId: number): void {
    if (!this.userId) {
      console.error('User ID not available.');
      return;
    }
    this.userService.getSelfRole().subscribe((userRole: string) => {
      console.log("User role => [" + userRole + "]");
      if (userRole === 'Admin') {
        this.discussionsService.alertThread(commentId).subscribe(
          () => {
            console.log('Alert set successfully for comment:', commentId);
            const commentIndex = this.comments.findIndex(comment => comment.id === commentId);
            if (commentIndex !== -1) {
              this.comments[commentIndex].isAlert = true;
            }
            this.globalModalService.alert({
              content: 'Comment has been Alert successfully.',
              type: 'success'
            });
            console.log("isCommentAlerted => " + this.comments[commentIndex].isAlert);
          },
          (error) => {
            console.error('Error setting alert for comment:', error);
            this.globalModalService.alert({
              content: 'Error setting alert for comment.',
              type: 'danger'
            });
          }
        );
      } else {
        console.error('Only admins can set alerts.');
        this.globalModalService.alert({
          content: 'Only admins can set alerts.',
          type: 'danger'
        });
      }
    }, (error) => {
      const errorMessage = 'Error: ' + error.error.message;
      this.globalModalService.alert({
        content: errorMessage,
        type: 'danger'
      });
      console.error('Error getting user role:', error);
    });
  }

  removeAlertFromComment(commentId: number): void {
    this.discussionsService.removeAlert(commentId).subscribe(
      () => {
        console.log('Comment alert removed successfully.');
        const commentIndex = this.comments.findIndex(comment => comment.id === commentId);
        if (commentIndex !== -1) {
          this.comments[commentIndex].isAlert = false;
        }
        this.globalModalService.alert({
          content: 'Comment alert has been removed successfully.',
          type: 'success'
        });
        console.log("isCommentAlert => " + this.comments[commentIndex].isAlert);
      },
      (error) => {
        console.error('Error removing comment alert:', error);
        this.globalModalService.alert({
          content: 'Error removing comment alert.',
          type: 'danger'
        });
      }
    );
  }

  onDeleteThread(postId: number): void {
    this.userService.getSelf().subscribe((user: User) => {
      const userId = user.id;
      this.userService.getSelfRole().subscribe((role: string) => {
        console.log("user role => [" + role + "]");
        if (role === 'Admin') {
          const message = 'Are you sure you want to delete this thread?';

          this.globalModalService.confirm(message).subscribe((result) => {
            if (result) {
              this.deleteThread(postId, this.userId!);
            }
          });
        } else {
          const errorMessage = 'Unauthorized: Only admins can delete threads.';
          this.globalModalService.alert({
            content: errorMessage,
            type: 'warning'
          });
          console.error('Unauthorized: Only admins can delete threads.');
        }
      }, (error) => {
        const errorMessage = 'Error: ' + error.error.message;
        this.globalModalService.alert({
          content: errorMessage,
          type: 'danger'
        });
        console.error('Error deleting thread:', error);
      });
    }, (error) => {
      console.error('Error fetching user ID:', error);
    });
  }

  private deleteThread(postId: number, userId: number): void {
    this.discussionsService.deleteThread(postId, userId).subscribe(
      () => {
        this.adminDiscussion = this.adminDiscussion.filter(post => post.id !== postId);
        console.log('Thread deleted successfully');
        this.router.navigate(['/discussion']);
      },
      (error) => {
        const errorMessage = 'Error: ' + error.error.message;
        this.globalModalService.alert({
          content: errorMessage,
          type: 'danger'
        });
        console.error('Error deleting thread:', error);
      }
    );
  }

  onDeleteComment(commentId: number): void {
    if (this.loginUserRole === 'Admin') {
      const message = 'Are you sure you want to delete this comment?';

      this.globalModalService.confirm(message).subscribe((result) => {
        if (result) {
          console.log(`Deleting comment with ID: ${commentId}`);
          this.discussionsService.deleteComment(commentId).subscribe(
            () => {
              this.fetchComments(this.discussion!.id);
            },
            (error) => {
              console.error('Error deleting comment:', error);
            }
          );
        }
      });
    } else {
      console.error('Unauthorized: Only admins can delete comments.');
      this.globalModalService.alert({
        content: 'Unauthorized: Only admins can delete comments.',
        type: 'warning'
      });
    }
  }

  toggleBookmarkByUser(): void {
    if (!this.discussion) {
      console.error('Discussion is not available');
      return;
    }
    const postId = this.discussion.id;
    const isBookmarked = this.discussionBookmarked;

    if (!isBookmarked) {
      this.discussionsService.bookmarkPostById(postId).subscribe(
        () => {
          console.log('Discussion bookmarked successfully');
          this.discussionBookmarked = true;
        },
        error => {
          console.error('Failed to bookmark discussion:', error);
        }
      );
    } else {
      this.discussionsService.unbookmarkPostById(postId).subscribe(
        () => {
          console.log('Discussion unbookmarked successfully');
          this.discussionBookmarked = false;
        },
        error => {
          console.error('Failed to unbookmark discussion:', error);
        }
      );
    }
  }

  toggleCommentBookmarkforUser(comment: Discussion): void {
    const isBookmarked = comment.isBookmarked;

    if (!isBookmarked) {
      this.bookmarkCommentForUser(comment.id);
    } else {
      this.removeCommentBookmarkForUser(comment.id);
    }
  }

  private bookmarkCommentForUser(commentId: number): void {
    this.discussionsService.bookmarkPostById(commentId).subscribe(
      () => {
        console.log('Comment bookmarked');
        const comment = this.comments.find(comment => comment.id === commentId);
        if (comment) {
          comment.isBookmarked = true;
        }
      },
      (error) => {
        console.error('Error bookmarking comment:', error);
      }
    );
  }

  private removeCommentBookmarkForUser(commentId: number): void {
    this.discussionsService.unbookmarkPostById(commentId).subscribe(
      () => {
        console.log('Comment unbookmarked');
        const comment = this.comments.find(comment => comment.id === commentId);
        if (comment) {
          comment.isBookmarked = false;
        }
      },
      (error) => {
        console.error('Error removing comment bookmark:', error);
      }
    );
  }

}
