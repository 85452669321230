import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-frame',
  templateUrl: './page-frame.component.html',
  styleUrls: ['./page-frame.component.sass']
})
export class PageFrameComponent implements OnInit {
  @Input() homeRoute = '';
  @Input() titleIcon = '';
  @Input() frameTitle = 'Page Frame Title';
  currentRouteHome = true;

  constructor(private router: Router, private location: Location) {
    this.currentRouteHome = this.router.url === this.homeRoute;
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event) => {
          return event as NavigationEnd;
        }),
        map((event) => {
          return event.url === this.homeRoute;
        })
      )
      .subscribe({
        next: value => {
          this.currentRouteHome = value;
        }
      });
  }

  ngOnInit(): void {
    this.currentRouteHome = this.router.url === this.homeRoute;
  }

  goBack(): void {
    // Go back to the parent route
    this.location.back();
  }
}
