<div class="container">

  <div class="card card-body my-4" *ngIf="user; else spinner">
    <div class="my-2 d-flex justify-content-start align-items-center gap-3">
      <div style="width: 200px; height: 200px" class="d-flex justify-content-center align-items-center">
        <img *ngIf="profilePictureUrl; else spinner" [src]="profilePictureUrl"
          class="img-fluid img-thumbnail rounded-circle" alt="" />
      </div>
      <div class="d-flex flex-column">
        <div class="h2">{{user.firstName}} {{user.lastName}}</div>
        <div class="text-info"><span class="bi bi-person-badge"></span> {{user.role}}</div>
        <div [ngbTooltip]="user.emailVerified ? 'Email verified' : 'Email not verified'" placement="end">
          <span class="bi bi-envelope-fill text-info"></span>
          {{user.email}}
          <span class="bi bi-check-circle-fill text-success" *ngIf="user.emailVerified"></span>
        </div>
        <div class="my-2">
          <div class="text-muted">Joined {{user.createdAt | date}}</div>
          <div class="text-muted">Last Seen {{user.updatedAt | date: 'MMM dd, yyyy HH:mm:ss a'}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="list-group list-group-flush">

    <div class="list-group-item py-3">
      <h4>Email Verification</h4>
      <p class="small text-muted">Email{{ user?.emailVerified ? ' ' : ' not ' }}verified <span class="bi" [ngClass]="{
          'bi-check-circle-fill text-success': user?.emailVerified,
          'bi-x-circle-fill text-danger': !user?.emailVerified
        }"></span></p>
      <p>Send a verification link to email address.</p>
      <button type="button" [disabled]="user?.emailVerified || sendingVerificationEmail"
        (click)="sendVerificationEmail()" class="btn btn-primary">
        Send Verification Email <span *ngIf="!sendingVerificationEmail; else inlineSpinner"
          class="bi bi-envelope-fill"></span>
      </button>
    </div>
    <div class="list-group-item py-3">
      <h4>Password Reset</h4>
      <p>Send a password reset link to email address.</p>
      <button type="button" [disabled]="sendingPasswordResetEmail" (click)="sendPasswordResetEmail()"
        class="btn btn-primary">
        Reset Password <span *ngIf="!sendingPasswordResetEmail; else inlineSpinner" class="bi bi-key-fill"></span>
      </button>
    </div>
  </div>

  <ng-template #spinner>
    <div class="spinner-border text-primary"></div>
  </ng-template>

  <ng-template #inlineSpinner>
    <span class="spinner-border spinner-border-sm"></span>
  </ng-template>

</div>