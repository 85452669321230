import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '@auth/interceptors/auth.interceptor';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { SsoButtonComponent } from '@auth/components/sso-button/sso-button.component';
import { CoreModule } from '@core/core.module';
import { SsoRedirectComponent } from './components/sso-redirect/sso-redirect.component';

const msalInstance: IPublicClientApplication = new PublicClientApplication({
  auth: {
    clientId: environment.azureClientId,
    authority: `https://login.microsoftonline.com/${environment.azureTenantId}`
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    temporaryCacheLocation: 'localStorage',
  }
});
const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['user.read']
  }
};
const interceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([])
};

@NgModule({
  declarations: [
    LoginFormComponent,
    SsoButtonComponent,
    SsoRedirectComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule.forRoot(msalInstance, guardConfig, interceptorConfig),
    CoreModule,
  ],
  exports: [
    LoginFormComponent,
    MsalModule,
    SsoButtonComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {
}
