import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-rubric-form',
  templateUrl: './rubric-form.component.html',
  styleUrls: ['./rubric-form.component.sass']
})
export class RubricFormComponent {

  constructor(private fb: FormBuilder, private formGroup: FormGroupDirective) {
  }

  get rubricForm() {
    return this.formGroup.control.get('rubric') as FormGroup;
  }

  get criteria() {
    return this.rubricForm.get('criteria') as FormArray;
  }

  addCriteria() {
    const formGroup = this.fb.group({
      title: [''],
      description: [''],
      ratings: this.fb.array([
        this.fb.group({
          title: [''],
          description: [''],
          ratingLevel: ['EXCELLENT']
        }),
        this.fb.group({
          title: [''],
          description: [''],
          ratingLevel: ['GREAT']
        }),
        this.fb.group({
          title: [''],
          description: [''],
          ratingLevel: ['GOOD']
        }),
        this.fb.group({
          title: [''],
          description: [''],
          ratingLevel: ['FAIR']
        }),
        this.fb.group({
          title: [''],
          description: [''],
          ratingLevel: ['POOR']
        })
      ])
    });

    this.criteria.push(formGroup);
  }

  removeCriteria(index: number) {
    this.criteria.controls.splice(index, 1);
    this.criteria.removeAt(index);
  }

  getRatings(formGroup: AbstractControl): FormGroup[] {
    return (formGroup.get('ratings') as FormArray).controls as FormGroup[];
  }

  getRatingId(index: number) {
    const ids = [
      'rateExcellent',
      'rateGreat',
      'rateGood',
      'rateFair',
      'ratePoor'
    ];

    return ids[index];
  }

  // Inside your component class:

  getPlaceholder(index: number): string {
    const labels = [
      'Exceeds expectations: Define criteria that demonstrate a superior level of performance, exceeding all established benchmarks.',
      'Meets expectations: Outline clear standards for satisfactory performance that align with goals and objectives.',
      'Meets expectations with some improvement needed: List specific indicators of acceptable performance, including areas that require enhancement for higher achievement.',
      'Meets minimal expectations: Identify the characteristics of performance that fails to meet some standards, including examples and potential areas for development.',
      'Does not meet expectations: Describe the traits of work that is significantly below standards, lacking in critical aspects and requiring significant improvement.'
    ];

    return labels[index] || 'Rating description for unspecified level';
  }

}
