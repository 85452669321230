import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GitLabGroup, GitLabProject } from '@app/api/integration/gitlab/models/gitlab-data.model';

@Component({
  selector: 'app-gitlab-group-explorer',
  templateUrl: './gitlab-group-explorer.component.html',
  styleUrls: ['./gitlab-group-explorer.component.sass']
})
export class GitlabGroupExplorerComponent implements OnInit {

  @Input() group?: GitLabGroup;
  @Input() title = 'GitLab Explorer';
  @Input() linkToProject = false;
  _selectedProject?: GitLabProject;
  @Input() set selectedProject(project: GitLabProject | undefined) {
    this._selectedProject = project;
    this.selectedProjectChange.emit(project);
  }
  get selectedProject(): GitLabProject | undefined {
    return this._selectedProject;
  }
  @Output() selectedProjectChange = new EventEmitter<GitLabProject>();

  subgroupDropdown: { [key: number]: boolean } = {};

  doAction(project: GitLabProject) {
    // Set the selected project
    // If the current project is already selected, then deselect it

    if (this.selectedProject && this.selectedProject.id === project.id) {
      this.selectedProject = undefined;
    } else {
      this.selectedProject = project;
    }

    if (this.linkToProject) {
      window.open(project.url, '_blank');
    }
  }

  ngOnInit(): void {
    if (this.group) {
      this.group.subgroups.forEach(subgroup => {
        this.subgroupDropdown[subgroup.id] = false;
      });
    }
  }

}
