import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Trainer } from '@app/api/models/trainer.model';
import { TrainerApiService } from '@app/api/training/services/trainer-api.service';

@Component({
  selector: 'app-view-trainers',
  templateUrl: './view-trainers.component.html',
  styleUrls: ['./view-trainers.component.sass'],
})
export class ViewTrainersComponent implements OnInit {
  pagination = {
    page: 1,
    size: 10,
    currentPageSize: 0,
    total: 0,
    sortBy: 'id',
    direction: 'asc',
  };
  searchQuery = '';
  trainers: Trainer[] = [];

  constructor(private trainerApiService: TrainerApiService, private route: ActivatedRoute, 
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchQuery = params['search'] || '';
      this.getAllTrainers();
    });
  }

  getAllTrainers(reset?: boolean) {
    if (reset) {
      this.pagination.page = 0;
      this.pagination.currentPageSize = 0;
      this.pagination.total = 0;
    }

    const queryParams: any = {
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction,
    };
    if (this.searchQuery.trim()) {
      queryParams.search = this.searchQuery;
    } else {
      queryParams.search = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.trainerApiService
      .getAllTrainers(this.searchQuery, [], {
        page: this.pagination.page - 1,
        size: this.pagination.size,
        sort: this.pagination.sortBy + ',' + this.pagination.direction,
      })
      .subscribe({
        next: (response) => {
          this.trainers = response.content;
          this.pagination.currentPageSize = response.numberOfElements;
          this.pagination.total = response.totalElements;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  trainerCellClass(trainer: Trainer) {
    return {
      'text-black-50 bg-light-subtle': trainer.invite,
    };
  }

  emptyRows() {
    return Array(this.pagination.size - this.pagination.currentPageSize);
  }

  onDeleteTrainer() {
    this.getAllTrainers();
  }

  mapPropertyToLabel(property: string) {
    switch (property) {
      case 'id':
        return 'ID';
      case 'user.email':
        return 'Email';
      case 'user.firstName':
        return 'First Name';
      case 'user.lastName':
        return 'Last Name';
      default:
        return property;
    }
  }
}
