<div class="container-fluid">

  <div class="my-4">
    <h4>Add User</h4>
  </div>
  <div class="col-12 col-md-6">
    <app-add-user-form (cancelClicked)="goBack()"></app-add-user-form>
  </div>

</div>
