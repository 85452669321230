import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cohort } from '@app/api/models/cohort.model';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectOption } from '@core/components/form-controls/multi-select-field/multi-select-field.component';
import {
  ActivityMaterialType,
  ActivityMaterialTypes
} from '@app/api/categorization/models/activity-material-type.model';
import { TitleCasePipe } from '@angular/common';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { LearningMaterialData } from '@app/api/models/learning-material-data.model';
import { GlobalModalService } from '@core/services/global-modal.service';
import { ContentLibraryService } from '@app/api/content-library/services/content-library.service';

@Component({
  selector: 'app-cohort-view-page',
  templateUrl: './cohort-view-page.component.html',
  styleUrls: ['./cohort-view-page.component.sass']
})
export class CohortViewPageComponent {

  @ViewChild('filtersModal') filtersModal?: TemplateRef<any>;
  @ViewChild('assignToCohortModal') assignToCohortModal?: TemplateRef<any>;

  filter = {
    types: [],
    materialIds: []
  };

  assignFilterType: ActivityMaterialType = 'COURSE';
  assignMaterials: LearningMaterialData[] = [];
  loadingMaterials = false;

  cohort?: Cohort;
  currentRoute = '';
  learningActivities: LearningActivity[] = [];
  distinctLearningMaterials: LearningMaterialData[] = [];
  totalLearningActivities = 0;
  search = '';
  page = 0;
  sort = '';

  constructor(
    private route: ActivatedRoute,
    private cohortApi: CohortApiService,
    private learningActivitiesApi: LearningActivityService,
    private router: Router,
    private globalModalService: GlobalModalService,
    private contentLibrary: ContentLibraryService,
    private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      const cohortId = params['id'];
      this.currentRoute = `/training/cohorts/${cohortId}`;
      this.loadCohort(cohortId);
      this.loadLearningActivities(cohortId);
      this.loadLearningMaterials(cohortId);
      this.loadAssignMaterials();
    });
  }

  loadCohort(cohortId: number) {
    this.cohortApi.getCohort(cohortId).subscribe({
      next: (cohort) => {
        this.cohort = cohort;
      }
    });
  }

  loadLearningActivities(cohortId: number) {
    this.cohortApi.getLearningActivities(cohortId, {
      size: 10,
      search: this.search,
      page: this.page - 1,
      sort: this.sort
    }, this.filter.types, this.filter.materialIds).subscribe({
      next: (learningActivities) => {
        this.learningActivities = learningActivities.content;
        this.totalLearningActivities = learningActivities.totalElements;
        this.page = learningActivities.number + 1;
      }
    });
  }

  loadAssignMaterials() {
    this.loadingMaterials = true;
    this.contentLibrary.getContent({
      published: true,
      types: [this.assignFilterType]
    }).subscribe({
      next: (materials) => {
        this.assignMaterials = materials.content;
        this.loadingMaterials = false;
      }
    });
  }

  loadLearningMaterials(cohortId: number) {
    this.learningActivitiesApi.getDistinctLearningMaterialsForCohort(cohortId).subscribe({
      next: (learningMaterials) => {
        this.distinctLearningMaterials = learningMaterials;
      }
    });
  }

  setAssignFilterType(type: ActivityMaterialType) {
    this.assignFilterType = type;
    this.loadAssignMaterials();
  }

  get typesOptions(): MultiSelectOption[] {

    const excludeType = (type: ActivityMaterialType) => {
      // Only include COURSE, MODULE, LESSON, ASSIGNMENT, and QUIZ
      return type !== 'LECTURE' && type !== 'QUESTION' && type !== 'VIDEO' && type !== 'DOCUMENT' && type !== 'LINK' && type !== 'IMAGE';
    };

    return ActivityMaterialTypes.values()
      .filter((type) => excludeType(type))
      .map((type) => {
        return {
          value: type,
          label: new TitleCasePipe().transform(type)
        };
      });
  }

  get distinctMaterials() {
    const getOptionIcon = (type: ActivityMaterialType) => {
      switch (type) {
        case 'COURSE':
          return 'bi-journal-bookmark-fill';
        case 'MODULE':
          return 'bi-folder';
        case 'LESSON':
          return 'bi-bookmark';
        case 'ASSIGNMENT':
          return 'bi-file-earmark-text';
        case 'QUIZ':
          return 'bi-file-earmark-check';
        default:
          return 'bi-file-earmark';
      }
    };
    return this.distinctLearningMaterials.map((material) => {
      return {
        icon: getOptionIcon(material.materialType!),
        value: material.id,
        label: material.title
      };
    });
  }

  get cohortStartDate() {
    return this.cohort?.startDate || new Date();
  }

  get cohortEndDate() {
    return this.cohort?.endDate || new Date();
  }

  goToLearningActivity(learningActivity: LearningActivity) {
    const type = learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'QUIZ' ? 'activity' : learningActivity.type.toLowerCase();
    this.router.navigate(['/learning', type, learningActivity.id], {
      state: {
        exitRoute: this.currentRoute
      }
    });
  }

  openFiltersModal() {
    this.modalService.open(this.filtersModal, { size: 'lg' });
  }

  clearFilters() {
    this.filter = {
      types: [],
      materialIds: []
    };
    this.loadLearningActivities(this.cohort!.id!);
  }

  sortLearningActivities(sort: string) {
    this.sort = sort;
    this.loadLearningActivities(this.cohort!.id!);
  }

  deleteLearningActivity(learningActivity: LearningActivity) {
    this.globalModalService.confirmDelete('Are you sure you want to delete this learning activity? This will delete all linked activities and will be unassigned from linked students.', learningActivity.title!)
      .subscribe((result) => {
        if (result) {
          this.learningActivitiesApi.deleteLearningActivity(learningActivity.id!).subscribe({
            next: () => {
              this.loadLearningActivities(this.cohort!.id!);
            }
          });
        }
      });
  }

  openAssignToCohortModal() {
    this.modalService.open(this.assignToCohortModal, { size: 'lg', scrollable: true });
  }

}
