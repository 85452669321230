<ng-container *ngIf="assignmentData && !loading; else spinner">
  <div class="my-3 flex-center">
    <app-percent-circle [percent]="learningActivity.progress || 0"
      [completed]="learningActivity.completed || false"></app-percent-circle>
  </div>

  <div class="my-3">
    <div class="text-center pb-2 mb-3 border-bottom">
      <div class="text-center">
        <h4 *ngIf="gradeAvailable">
          <span class="badge bg-info rounded-pill">Grade: {{learningActivity.submission.score * 100}}%</span>
        </h4>
        <div class="text-muted small">Assignment</div>
        <h3 class="text-center">{{assignmentData.title}}</h3>
      </div>
      <div class="flex-center flex-wrap gap-1 mb-2">
        <span class="badge bg-info rounded-pill" *ngFor="let tag of assignmentData.technologies">
          {{tag}}
        </span>
      </div>
      <div class="text-muted">
        {{assignmentData.description}}
      </div>
    </div>
    <div class="col-12 col-md-6 offset-md-3 my-3">
      <div class="input-group justify-content-center">
        <button type="button" class="btn w-50" [class.btn-outline-primary]="viewing !== 'ASSIGNMENT'"
          [class.btn-primary]="viewing === 'ASSIGNMENT'" (click)="viewing = 'ASSIGNMENT'">
          Assignment
        </button>
        <button
          [disabled]="!gradingView && (!assignmentData.rubric || !assignmentData.rubric.criteria || !assignmentData.rubric.criteria.length)"
          type="button" class="btn btn-outline-primary w-50" [class.btn-outline-primary]="viewing !== 'RUBRIC'"
          [class.btn-primary]="viewing === 'RUBRIC'" (click)="viewing = 'RUBRIC'">
          {{ gradingView ? 'Grading' : 'Rubric' }}
        </button>
      </div>
    </div>

    <div class="my-3" *ngIf="viewing === 'ASSIGNMENT'">
      <app-markdown-viewer *ngIf="hasOverviewOrContent" [data]="assignmentData.content || ''"
                           [enableSmoothScroll]="enableSmoothScroll"></app-markdown-viewer>
    </div>

    <div class="my-3" *ngIf="viewing === 'RUBRIC'">
      <app-rubric-preview *ngIf="!gradingView" [rubric]="assignmentData.rubric"></app-rubric-preview>
      <app-assignment-grading-form *ngIf="gradingView" [rubric]="assignmentData.rubric"
        [learningActivity]="learningActivity"></app-assignment-grading-form>
    </div>

    <div class="my-3">
      <app-assignment-submission-form [learningActivityId]="learningActivity.id" [assignment]="assignmentData"
        (progressChange)="handleProgressChange($event)"
        [(submission)]="learningActivity.submission"></app-assignment-submission-form>
    </div>

    <ng-container *ngIf="learningActivity.submission">
      <ng-container *ngIf="userRole === 'Trainer' || userRole === 'Student' || userRole === 'Admin'">
        <ng-container *ngFor="let comment of comments" class="comment-container mb-4">
          <h6>Feedback:</h6>
          <div class="card">
            <div class="card-body">
              <div class="comment-content-container">
                <div class="comment-content">{{ comment.content }}</div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button *ngIf="userRole === 'Student' || userRole === 'Trainer' || userRole === 'Admin'" type="button"
                  class="btn btn-sm btn-primary" (click)="toggleReplyForm(comment)">
                  <span class="bi bi-reply"></span> Reply
                </button>
              </div>
            </div>
            <div class="card-footer">
              <div class="comment-metadata">
                <span class="comment-author">{{ comment.author }}</span>
                <span class="comment-date">{{ comment.createdAt | date: 'MM/dd/yyyy hh:mm:ss aa' }}</span>
              </div>
            </div>
          </div>
          <div *ngFor="let reply of comment.replies" class="card mt-1 reply-card">
            <div class="card-body bg-light rounded">
              <div class="reply-content">
                {{ reply.content }}<br>
                <div class="text-muted small d-flex justify-content-between w-100 mt-2">
                  <span class="reply-author">{{ reply.author }}</span>
                  <span class="reply-date">{{ reply.createdAt | date: 'MM/dd/yyyy hh:mm:ss aa' }}</span>
                </div>
              </div>
            </div>
          </div>

          <form class="mt-2" (submit)="replyToFeedback(comment)">
            <div class="card mt-2" *ngIf="replyFormVisibility[comment.id]">
              <div class="card-body">
                <div class="mb-3">
                  <label for="replyContent" class="form-label">
                    <strong style="font-size: 1.1rem;">Reply to Feedback</strong>
                  </label>
                  <textarea class="form-control" id="replyContent" rows="3" placeholder="Type your reply here..."
                    [(ngModel)]="replyContent" name="replyContent" required></textarea>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="isSubmitting">Submit</button>
              </div>
            </div>
          </form>
        </ng-container>

        <form (submit)="addCommentToAssignment()" class="mt-4">
          <div class="mb-3">
            <label for="commentContent" class="form-label">
              <strong style="font-size: 1.1rem;">Provide Feedback</strong>
            </label>
            <textarea class="form-control" id="commentContent" rows="3" placeholder="Type your feedback here..."
              [(ngModel)]="commentContent" name="commentContent" required></textarea>
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="isSubmitting">Submit</button>
        </form>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="my-3 text-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
