import { Component, Input, OnInit } from '@angular/core';
import {
  CourseActivityTree,
  LearningActivity,
  LearningActivityTree,
  LessonActivityTree,
  ModuleActivityTree
} from '@app/api/models/learning-activity.model';
import { Location } from '@angular/common';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-learning-activity-view',
  templateUrl: './learning-activity-view.component.html',
  styleUrls: ['./learning-activity-view.component.sass']
})
export class LearningActivityViewComponent implements OnInit {

  @Input() learningActivity!: LearningActivity;
  tree?: LearningActivityTree;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private learningActivityService: LearningActivityService
  ) { }

  ngOnInit() {
    this.learningActivityService.getLearningActivityTree(this.learningActivity.id).subscribe(tree => {
      this.tree = tree;

      if (this.isModule) {
        const lesson = this.moduleTree.lessons[0];
        this.router.navigate(['./lesson', lesson.id], {
          relativeTo: this.route
        });
      }

      if (this.isCourse) {
        const module = this.courseTree.modules[0];
        const lesson = module.lessons[0];
        this.router.navigate(['./lesson', lesson.id], {
          relativeTo: this.route
        });
      }

    });
  }

  get lessonTree(): LessonActivityTree {
    return this.tree as LessonActivityTree;
  }

  get moduleTree(): ModuleActivityTree {
    return this.tree as ModuleActivityTree;
  }

  get courseTree(): CourseActivityTree {
    return this.tree as CourseActivityTree;
  }

  get isCourse(): boolean {
    return this.learningActivity.type === 'COURSE';
  }

  get isModule(): boolean {
    return this.learningActivity.type === 'MODULE';
  }

  get isLesson(): boolean {
    return this.learningActivity.type === 'LESSON';
  }

  get isQuiz(): boolean {
    return this.learningActivity.type === 'QUIZ';
  }

  get isAssignment(): boolean {
    return this.learningActivity.type === 'ASSIGNMENT';
  }

  get materialId(): number {
    return this.learningActivity.materialId;
  }

  goBack() {
    this.location.back();
  }

}
