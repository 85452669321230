import { StudentService } from '@app/api/student/services/student.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student } from '@app/api/student/model/student.model';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-students-edit-page',
  templateUrl: './students-edit-page.component.html',
  styleUrls: ['./students-edit-page.component.sass']
})
export class StudentsEditPageComponent {

  student: Student = {
    email: '', firstName: '', lastName: '', learningActivities: [], phases: [],cohorts:[], invite: false
  };

  saving =  false;

  constructor(private studentService: StudentService,
    private route: ActivatedRoute,
    private toastService: GlobalToastService,
    private router: Router){
    this.route.params.subscribe(params => {
      const studentId = params['id'];
      this.studentService.getStudentById(studentId).subscribe({
        next: (student) => {
          this.student = student;
        }
      });
    });
  }

  saveStudent(student: Student){
    this.saving = true;
    this.studentService.updateStudent(student)
      .pipe(catchError((_err) => {
        this.toastService.showDangerToast('Error saving student.');
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (student) => {
          if (!student) return;
          this.student = student;          
          this.saving = false;
          this.toastService.showSuccessToast('student successfully saved!');
        }
      });
  }

  deleteStudent(student: Student) {
    this.saving = true;
    this.studentService.deleteStudent(student.id!)
      .pipe(catchError((_err) => {
        this.toastService.showDangerToast('Error deleting student.');
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (student) => {
          if (!student) return;
          this.saving = false;
          this.toastService.showSuccessToast('student successfully deleted!');
          this.router.navigate(['/training/students']);
        }
      });
  }
}
