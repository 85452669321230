import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-training-home-page',
  templateUrl: './training-home-page.component.html',
  styleUrls: ['./training-home-page.component.sass']
})
export class TrainingHomePageComponent {
  cohortCardItems: RouteCardItem[] = [
    {
      title: 'View Cohorts',
      icon: 'bi bi-people',
      description: 'View all cohorts',
      routerLink: '/training/cohorts'
    },
    {
      title: 'Create new Cohort',
      icon: 'bi bi-plus-circle',
      description: 'Add a new cohort',
      routerLink: '/training/cohorts/add'
    },
  ];

  trainerCardItems: RouteCardItem[] = [
    {
      title: 'View Trainers',
      icon: 'bi bi-people',
      description: 'View all trainers and their details',
      routerLink: '/training/trainers'
    },
    {
      title: 'Create new Trainer',
      icon: 'bi bi-person-fill-add',
      description: 'Add a new trainer',
      routerLink: '/training/trainers/add'
    },
  ];

  studentCardItems: RouteCardItem[] = [
    {
      title: 'View Students',
      icon: 'bi bi-people',
      description: 'View all students and their details',
      routerLink: '/training/students'
    },
    {
      title: 'Create new Student',
      icon: 'bi bi-person-fill-add',
      description: 'Add a new student',
      routerLink: '/training/students/add'
    },
  ];

  learningActivitiesCardItems: RouteCardItem[] = [

  ];
}
