import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef, EventEmitter,
  Input,
  OnChanges, Output,
  QueryList,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { FormStepDirective } from '@core/directives/multistep-form/form-step.directive';

@Directive({
  selector: '[appVerticalMultistepForm]'
})
export class VerticalMultistepFormDirective implements OnChanges, AfterViewInit {

  _currentStep = 0;
  currentStep$ = new Subject<number>();
  @ContentChildren(FormStepDirective) _formSteps!: QueryList<FormStepDirective>;
  formSteps: FormStepDirective[] = [];
  @Output() currentStepChange = new EventEmitter<number>();

  @Input()
  set currentStep(step: number) {
    this._currentStep = step;
    this.currentStep$.next(step);
    this.currentStepChange.emit(step);
    this.updateProgress();
  }

  get currentStep() {
    return this._currentStep;
  }

  constructor(private el: ElementRef, renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'vertical-multistep-form');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentStep']) {
      this.updateProgress();
    }
  }

  ngAfterViewInit() {
    this.formSteps = this._formSteps.toArray();
    this.updateProgress();
    this._formSteps.changes.subscribe((formSteps) => {
      this.formSteps = formSteps.toArray();
      this.updateProgress();
    });
    this.currentStepChange.subscribe(() => {
      this.updateProgress();
    });
  }

  updateProgress() {
    if (this.formSteps.length > 0) {
      this.formSteps.forEach((step) => {
        if (step.step < this.currentStep) {
          step.active = false;
          step.complete = true;
        } else if (step.step === this.currentStep) {
          step.active = true;
          step.complete = false;
        } else {
          step.active = false;
          step.complete = false;
        }
      });
    }
  }

  get top() {
    return this.el.nativeElement.getBoundingClientRect().top;
  }

}
