import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { LessonData } from '@app/api/models/learning-material-data.model';
import { HttpClient } from '@angular/common/http';
import { Page, PageParams } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root'
})
export class LessonApiService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getLesson(id: number): Observable<LessonData> {
    return this.http.get<LessonData>(this.apiService.endpoint(['lessons', id]));
  }

  getLessonsByOwner(ownerId: number | 'self', pageParams?: PageParams) {
    return this.http.get<Page<LessonData>>(this.apiService.endpoint(['/lessons', 'owner', ownerId]), {
      params: {
        ...pageParams,
      }
    });
  }

  createLesson(lessonData: LessonData): Observable<LessonData> {
    return this.http.post<LessonData>(this.apiService.endpoint(['lessons']), lessonData);
  }

  duplicateLesson(id: number) {
    return this.http.get<LessonData>(this.apiService.endpoint(['lessons/duplicate', id]));

  }

  updateLesson(id: number, lessonData: LessonData): Observable<LessonData> {
    return this.http.put<LessonData>(this.apiService.endpoint(['lessons', id]), lessonData);
  }

  deleteLesson(id: number): Observable<void> {
    return this.http.delete<void>(this.apiService.endpoint(['lessons', id]));
  }

}
