import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  readonly environment = environment;

  constructor() { }

  get env(): { [key: string]: any } {
    return this.environment;
  }

  getEnv(key: string) {
    return this.env[key];
  }
}
