import { Component, Input, ViewChild } from '@angular/core';
import {
  AttachmentData,
  LessonAssignmentData,
  LessonData,
} from '@app/api/models/learning-material-data.model';
import { ApiService } from '@core/services/api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import { QuizService } from '@app/api/quiz/services/quiz.service';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-lesson-preview',
  templateUrl: './lesson-preview.component.html',
  styleUrls: ['./lesson-preview.component.sass'],
})
export class LessonPreviewComponent {
  @ViewChild('previewAssignmentModal') previewAssignmentModal!: NgbModal;
  @ViewChild('previewQuizModal') previewQuizModal!: NgbModal;

  @Input() lessonData?: LessonData;
  loadingAssignmentPreview = false;
  previewAssignmentData?: AssignmentData;
  previewQuizData?: QuizData;

  constructor(
    private apiService: ApiService,
    private assignmentApi: AssignmentApiService,
    private quizApi: QuizService,
    private modalService: NgbModal,
    private globalModalService: GlobalModalService,
  ) {}

  get presentationAttachment(): AttachmentData | undefined {
    return this.lessonData?.lessonAttachments.find(
      (attachment) => attachment.attachmentType === 'PRESENTATION',
    );
  }

  get presentationFileUrl(): string {
    const fileRecordId = this.presentationAttachment?.fileRecordId;
    return fileRecordId
      ? this.apiService.endpoint(['file-records', fileRecordId, 'public-view'])
      : '';
  }

  get hasAssignments(): boolean {
    return this.lessonData?.assignments.length !== 0;
  }

  get hasQuizzes(): boolean {
    return this.lessonData?.quizzes.length !== 0;
  }

  openAssignmentPreview(assignment: LessonAssignmentData): void {
    this.loadingAssignmentPreview = true;
    this.assignmentApi.getAssignment(assignment.id).subscribe({
      next: (assignmentData) => {
        this.previewAssignmentData = assignmentData;
        this.loadingAssignmentPreview = false;

        this.modalService.open(this.previewAssignmentModal, {
          size: 'xl',
          scrollable: true,
        });
      },
      error: () => {
        this.loadingAssignmentPreview = false;
      },
    });
  }

  openQuizPreview(quiz: QuizData): void {
    this.quizApi.getQuizById(quiz.id!).subscribe({
      next: (quizData) => {
        this.previewQuizData = quizData;
        this.modalService.open(this.previewQuizModal, {
          size: 'xl',
          scrollable: true,
        });
      },
      error: () => {
        this.modalService.dismissAll();
        this.globalModalService.alert({
          type: 'danger',
          content: 'Failed to load quiz',
        });
      },
    });
  }
}
