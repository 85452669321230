import { Component, Input, OnInit } from '@angular/core';
import { LessonData } from '@app/api/models/learning-material-data.model';
import { LessonApiService } from '../../services/lesson-api.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-edit-lessons',
  templateUrl: './view-edit-lessons.component.html',
  styleUrls: ['./view-edit-lessons.component.sass']
})
export class ViewEditLessonsComponent implements OnInit {

  @Input() currentLessonId?: number;
  @Input() showTitle = true;
  lessons: LessonData[] = [];
  filteredLessons: LessonData[] = [];
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  searchQuery = '';
  selectedTechnology = ''; 
  technologies: string[] = []; 
  showFilterModal = false; 
  hasFilter = false; 

  constructor(private lessonApi: LessonApiService,
    private router: Router,
    private modal: GlobalModalService){
  }

  ngOnInit() {
    this.loadLessons();
  }

  loadLessons() {
    this.loading = true;
    this.lessonApi.getLessonsByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.totalItems = page.totalElements;
      this.lessons = page.content.filter(l => l.id !== this.currentLessonId);
      this.filteredLessons = [...this.lessons]; 
      this.extractTechnologies(); 
      this.loading = false;
      this.filterLessons();
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditLessonPage(lesson: LessonData) {
    this.router.navigate(['course-composer', 'lessons', 'edit', lesson.id]);
  }

  deleteLesson(id: number) {
    this.modal.confirm('Are you sure you want to delete this lesson?', {
      title: 'Delete Lesson',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.lessonApi.deleteLesson(id)
            .subscribe({
              next: () => {
                this.lessons = this.lessons.filter(lesson => lesson.id !== id);
                this.filterLessons();
              },
              error: (err) => {
                if (err.status === 400) {
                  this.modal.alert({
                    type: 'danger',
                    title: 'Unable to Delete Lesson',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }
              }
            });
        }
      }
    });
  }

  extractTechnologies() {
    this.technologies = Array.from(new Set(this.lessons.flatMap(a => a.technologies || [])));
  }

  openFilterModal() {
    this.showFilterModal = true;
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal; 
  }

  resetFilter() {
    this.selectedTechnology = ''; 
    this.searchQuery = ''; 
    this.filteredLessons = [...this.lessons]; 
    this.hasFilter = false; 
  }

  filterLessons() {
    this.hasFilter = this.selectedTechnology !== '' || this.searchQuery !== ''; 
    const query = this.searchQuery.toLowerCase();
    this.filteredLessons = this.lessons.filter(lesson =>
      (this.selectedTechnology === '' || lesson.technologies?.includes(this.selectedTechnology)) &&
      (!query || lesson.title.toLowerCase().includes(query))
    );
  }

  applyFilter() {
    this.filterLessons(); 
    this.toggleFilterModal(); 
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage; 
    this.loadLessons(); 
  }

}
