<div class="m-2 py-3" *ngIf="lessonData && (lessonData.title || lessonData.description || lessonData.assignments.length || lessonData.lessonAttachments.length || lessonData.content); else noLessonPreview">
  <h1>{{lessonData.title}}</h1>
  <div class="d-flex flex-wrap gap-1 align-items-center mb-3">
    <span *ngFor="let technology of lessonData.technologies"
          class="badge rounded-pill bg-primary">{{technology}}</span>
  </div>
  <p class="text-muted">{{lessonData.description}}</p>
  <div class="my-3" *ngIf="presentationAttachment">
    <h5 class="h5">Lecture Slides</h5>
    <div class="ratio ratio-16x9 mb-5">
      <app-office365-viewer [fileUrl]="presentationFileUrl"></app-office365-viewer>
    </div>
  </div>
  <div class="my-3 pt-3">
    <app-markdown-viewer [data]="lessonData.content"></app-markdown-viewer>
  </div>
  <div class="my-3" *ngIf="hasAssignments; else completeButton">
    <h5 class="h5">Assignments <span class="badge bg-info rounded-circle">{{lessonData.assignments.length}}</span></h5>
    <div class="card card-body bg-light overflow-x-auto">
      <div class="d-flex flex-row gap-2">
        <div class="card assignment-card" *ngFor="let assignment of lessonData.assignments">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex flex-row">
              <h5 class="card-title flex-grow-1 w-100">
                {{assignment.title}}
              </h5>
              <div class="text-end">
                <span class="badge rounded-circle bg-primary">{{assignment.order + 1}}</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-text">
              {{assignment.description}}
            </div>
          </div>
          <div class="card-footer bg-transparent border-0">
            <button class="btn btn-primary w-100" (click)="openAssignmentPreview(assignment)">
              Start <span class="bi bi-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasQuizzes" class="my-3">
    <h5 class="h5">Quizzes <span class="badge bg-info rounded-circle">{{ lessonData.quizzes.length }}</span></h5>
    <div class="card card-body bg-light overflow-x-auto">
      <div class="d-flex flex-row gap-2">
        <div *ngFor="let quiz of lessonData.quizzes" class="card assignment-card">
          <div class="card-header bg-transparent border-0">
            <div class="d-flex flex-row">
              <h5 class="card-title flex-grow-1 w-100">
                {{ quiz.title }}
              </h5>
              <div class="text-end">
                <span class="badge rounded-circle bg-primary">{{ quiz.order + 1 }}</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-text">
              {{ quiz.description }}
            </div>
          </div>
          <div class="card-footer bg-transparent border-0">
            <button (click)="openQuizPreview(quiz)" class="btn btn-primary w-100">
              Start <span class="bi bi-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #completeButton>
  <div class="my-3">
    <button class="btn btn-primary w-100">
      Complete Lesson <span class="bi bi-check2-circle"></span>
    </button>
  </div>
</ng-template>

<ng-template #previewAssignmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Assignment Preview</h5>
    <button class="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-assignment-preview *ngIf="previewAssignmentData" [data]="previewAssignmentData"></app-assignment-preview>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #previewQuizModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Quiz Preview</h5>
    <button (click)="modal.dismiss()" class="btn-close"></button>
  </div>
  <div class="modal-body">
    <app-quiz-preview *ngIf="previewQuizData" [data]="previewQuizData"></app-quiz-preview>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="btn btn-outline-primary">Close</button>
  </div>
</ng-template>

<ng-template #noLessonPreview>
  <div class="text-center py-3 text-black-50">
    <div class="text-center my-5">
      <div class="icon-stack">
        <span class="bi bi-file-earmark display-1"></span>
        <span class="bi bi-eye-slash display-5"></span>
      </div>
    </div>
    <div class="my-3 text-center">
      <h4 class="display-6">No Preview</h4>
      <p class="lead">Lesson preview will appear here.</p>
    </div>
  </div>
</ng-template>
