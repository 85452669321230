import { Component } from '@angular/core';

@Component({
  selector: 'app-view-users-page',
  templateUrl: './view-users-page.component.html',
  styleUrls: ['./view-users-page.component.sass']
})
export class ViewUsersPageComponent {

}
