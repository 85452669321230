import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student } from '@app/api/student/model/student.model';
import { StudentService } from '@app/api/student/services/student.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-view-students',
  templateUrl: './view-students.component.html',
  styleUrls: ['./view-students.component.sass']
})
export class ViewStudentsComponent implements OnInit {

  pagination = {
    page: 0,
    size: 10,
    currentPageSize: 0,
    total: 0,
    sortBy: 'id',
    direction: 'asc',
  };
  search = '';
  students: Student[] = [];

  constructor(private studentService: StudentService, private globalModal: GlobalModalService, 
              private route: ActivatedRoute, private router: Router) { }

  getAllStudents(reset?: boolean) {
    if (reset) {
      this.pagination.page = 0;
      this.pagination.currentPageSize = 0;
      this.pagination.total = 0;
    }
    const queryParams: any = {
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction,
    };
    if (this.search.trim()) {
      queryParams.search = this.search;
    } else {
      queryParams.search = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    return this.studentService.getAllStudents(this.search, {
      page: this.pagination.page - 1,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    })
      .subscribe(students => {
        this.students = students.content;
        this.pagination.currentPageSize = students.numberOfElements;
        this.pagination.total = students.totalElements;
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.search = params['search'] || '';
      this.getAllStudents();
    });
  }

  emptyRows() {
    return Array(this.pagination.size - this.pagination.currentPageSize);
  }

  mapPropertyToLabel(property: string) {
    switch (property) {
      case 'id':
        return 'ID';
      case 'user.email':
        return 'Email';
      case 'user.firstName':
        return 'First Name';
      case 'user.lastName':
        return 'Last Name';
      default:
        return property;
    }
  }

  deleteStudent(student: Student) {
    this.globalModal.confirmDelete('Are you sure you want to delete this student? This cannot be undone.', student.firstName)
      .subscribe({
        next: (result) => {
          if (result) {
            this.studentService.deleteStudent(student.id!).subscribe({
              next: () => {
                this.getAllStudents();
              }
            });
          }
        }
      });
  }
}
