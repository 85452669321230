import { Component } from '@angular/core';

@Component({
  selector: 'app-import-quiz-page',
  templateUrl: './import-quiz-page.component.html',
  styleUrls: ['./import-quiz-page.component.sass']
})
export class ImportQuizPageComponent {

}
