import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  static readonly TITLE_START_MARKER = '<!--title-->';
  static readonly TITLE_END_MARKER = '<!--/title-->';
  static readonly DESCRIPTION_START_MARKER = '<!--description-->';
  static readonly DESCRIPTION_END_MARKER = '<!--/description-->';
  static readonly OVERVIEW_START_MARKER = '<!--overview-->';
  static readonly OVERVIEW_END_MARKER = '<!--/overview-->';
  static readonly CONTENT_START_MARKER = '<!--content-->';
  static readonly CONTENT_END_MARKER = '<!--/content-->';
  static readonly RUBRIC_START_MARKER = '<!--rubric-->';
  static readonly RUBRIC_END_MARKER = '<!--/rubric-->';
  static readonly ASSIGNMENT_CONTENT_TEMPLATE =
    '## Overview\n' +
    AssignmentService.OVERVIEW_START_MARKER +
    '\n' +
    '%overview%\n' +
    AssignmentService.OVERVIEW_END_MARKER +
    '\n' +
    '\n---\n\n' +
    '## Assignment\n' +
    AssignmentService.CONTENT_START_MARKER +
    '\n' +
    '%content%\n' +
    AssignmentService.CONTENT_END_MARKER +
    '\n' +
    '\n---\n';
  static readonly ASSIGNMENT_TEMPLATE =
    '# ' +
    AssignmentService.TITLE_START_MARKER +
    ' %title% ' +
    AssignmentService.TITLE_END_MARKER +
    '\n\n' +
    AssignmentService.DESCRIPTION_START_MARKER +
    '\n%description%\n' +
    AssignmentService.DESCRIPTION_END_MARKER +
    '\n' +
    '\n---\n\n' +
    AssignmentService.ASSIGNMENT_CONTENT_TEMPLATE +
    '\n' +
    '\n---\n\n' +
    AssignmentService.RUBRIC_START_MARKER +
    '\n' +
    '%rubric%\n' +
    AssignmentService.RUBRIC_END_MARKER +
    '\n';

  static readonly OVERVIEW_SECTION =
    '## Overview\n' +
    AssignmentService.OVERVIEW_START_MARKER +
    '\n' +
    '%overview%\n' +
    AssignmentService.OVERVIEW_END_MARKER +
    '\n';

  static readonly CONTENT_SECTION =
    '## Assignment\n' +
    AssignmentService.CONTENT_START_MARKER +
    '\n' +
    '%content%\n' +
    AssignmentService.CONTENT_END_MARKER +
    '\n';

  constructor() {}

  constructContentMd(overview: string, content: string) {
    if (content && !overview) {
      return AssignmentService.ASSIGNMENT_CONTENT_TEMPLATE.replace(
        AssignmentService.OVERVIEW_SECTION,
        '',
      )
        .replace('---', '')
        .replace('%content%', content);
    } else if (overview && !content) {
      return AssignmentService.ASSIGNMENT_CONTENT_TEMPLATE.replace(
        AssignmentService.CONTENT_SECTION,
        '',
      )
        .replace('---', '')
        .replace('%overview%', overview);
    }
    return AssignmentService.ASSIGNMENT_CONTENT_TEMPLATE.replace(
      '%overview%',
      overview || '',
    ).replace('%content%', content || '');
  }

  parseMarkdown(markdown: string) {
    return {
      title: this.parseContent('title', markdown),
      description: this.parseContent('description', markdown),
      overview: this.parseContent('overview', markdown),
      content: this.parseContent('content', markdown),
    };
  }

  parseContent(marker: string, markdown: string) {
    const start = `<!--${marker}-->`;
    const end = `<!--/${marker}-->`;
    const startIdx = markdown.indexOf(start) + start.length;
    const endIdx = markdown.indexOf(end);
    return markdown.substring(startIdx, endIdx).trim() || '';
  }
}
