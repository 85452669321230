<div class="row">
  <div class="row">
    <div class="col-12 col-md-7 mt-3 me-3">
      <h1 class="h4">Import Questions</h1>
      <div class="my-3">
        <app-upload-field #uploadField [(ngModel)]="files" (ngModelChange)="processFile()" acceptedExtensions=".json,.csv,.xlsx"></app-upload-field>
      </div>
      <div [class.d-none]="!currentQuestion || !files || !files[0]" class="my-3">
        <app-question-form [(createQuestionRequest)]="currentQuestion" [allowSave]="false"></app-question-form>
      </div>
    </div>
    <div *ngIf="currentQuestion && files && files[0]" class="col-12 col-md-3 mt-3 ms-3">
      <div *ngIf="bulkQuestionRequest" class="list-group list-group-flush border rounded-end rounded-bottom border-start-0 mt-4">
        <div *ngFor="let question of bulkQuestionRequest.questions; let index = index; trackBy: trackByIndex"
                tabindex="0"
                (focus)="currentIndex = index"
                (click)="currentIndex = index"
                [ngClass]="{
                  'border-start-0 border-bottom-0': currentIndex === index,
                  'rounded-bottom-left': currentIndex - 1 === index,
                  'rounded-top-left border-top': currentIndex + 1 === index,
                  'no-focus': currentIndex === index
                }"
                class="list-group-item border-start list-group-item-action text-start d-flex justify-content-between align-items-center py-4 px-4">
          <div class="d-block p-transition" [ngClass]="{
            'py-5': currentIndex === index
          }">
            <div class="small text-muted">
              Question {{ index + 1 }}
            </div>
            <div class="mt-1">
              {{ question.title }}
            </div>
            <span class="mb-1">
              <span class="badge bg-primary">
                {{ getQuestionTypeLabel(question.questionType) }}
              </span>
            </span>
          </div>
          <div ngbDropdown>
            <button ngbDropdownToggle click-stop-propagation class="btn btn-sm btn-outline-primary border-0">
              <span class="bi bi-three-dots-vertical"></span>
            </button>
            <div ngbDropdownMenu>
              <button *ngIf="currentIndex !== index" ngbDropdownItem click-stop-propagation (click)="currentIndex = index">
                <span class="bi bi-pencil me-2 text-muted"></span>
                Edit
              </button>
              <button ngbDropdownItem click-stop-propagation (click)="openPreviewModal(index)">
                <span class="bi bi-eye me-2 text-muted"></span>
                Preview
              </button>
              <button ngbDropdownItem click-stop-propagation (click)="openRemoveQuestionModal(index)">
                <span class="bi bi-trash me-2 text-danger"></span>
                Remove
              </button>
            </div>
          </div>
        </div>
        <div class="list-group-item p-0">
          <button (click)="addQuestion()" class="m-0 w-100 h-100 btn py-3 btn-primary rounded-top-0">
            Add Question <span class="bi bi-plus-circle"></span>
          </button>
        </div>
      </div>
      <div *ngIf="bulkQuestionRequest" class="my-3 d-flex gap-2 justify-content-end">
        <div ngbDropdown>
          <button type="button"
                  ngbDropdownToggle
                  class="btn btn-outline-primary">Export Questions <span class="bi bi-chevron-down"></span>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="exportJson()">
              <span class="bi bi-filetype-json me-1"></span> Export JSON
            </button>
            <button ngbDropdownItem>
              <span class="bi bi-filetype-csv me-1"></span> Export CSV
            </button>
            <button ngbDropdownItem>
              <span class="bi bi-filetype-xlsx me-1"></span> Export Excel
            </button>
          </div>
        </div>
        <button type="button" class="btn btn-outline-primary" (click)="saveQuestion()">Save All</button>
        <button type="button" class="btn btn-primary" (click)="saveQuestion(true)">Save & Publish All</button>
      </div>
    </div>
  </div>
</div>

<ng-template #removeQuestionModal let-modal>
  <div class="modal-header">
    Are you sure you want to remove this question?
  </div>
  <div class="modal-body">
    This action cannot be undone.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="removeQuestion(currentIndex); modal.dismiss()">Remove</button>
  </div>
</ng-template>

<ng-template #previewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Preview Question</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-question-preview [requestData]="currentQuestion!"></app-question-preview>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #savedQuestionsListModal>
  <div class="my-3">
    Successfully saved questions.
  </div>
  <div class="my-3">
    <div class="list-group list-group-flush">
      <div *ngFor="let question of savedQuestions; let index = index" class="list-group-item py-3 px-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="mx-2">
            <div class="small text-muted">
              Question {{ index + 1 }}
            </div>
            <div class="mt-1">
              {{ question.title }}
            </div>
            <span class="mb-1">
              <span class="badge bg-primary">
                {{ question.questionType.name }}
              </span>
            </span>
          </div>
          <div class="mx-2">
            <button type="button"
                    class="btn btn-outline-primary"
                    (click)="openEditQuestionInAnotherWindow(question)"
                    ngbTooltip="Edit Question">
              <span class="bi bi-pencil-square"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
