<div class="position-relative" style="height: 350px; width: 100%;">
  <pdf-viewer
    (after-load-complete)=" afterLoadComplete($event)"
    (click)=" showControls = !showControls"
    (pageChange)="pageNumber = $event"
    [(page)]="pageNumber"
    [external-link-target]="'_blank'"
    [fit-to-page]="true"
    [original-size]="true"
    [render-text]="true"
    [show-all]="false"
    [src]="src"
    class="wh-100"
  />

  <div *ngIf="!hasLoaded"
       class="position-absolute top-50 start-50">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div
    (focus)="autoHideControls = false"
    (mouseleave)=" autoHideControls = true"
    (mouseover)=" autoHideControls = false"
    *ngIf="hasLoaded && showControls"
    class="position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-center justify-content-center p-2 bg-black rounded">
    <button
      (click)="pageNumber = pageNumber - 1"
      class="btn btn-secondary d-flex align-items-center justify-content-center p-0 rounded-circle"
      style="height: 40px; width: 40px;">
      <svg class="w-4 h-4 text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
           xmlns="http://www.w3.org/2000/svg">
        <path d="m18 15-6-6-6 6"></path>
      </svg>
    </button>
    <button
      (click)="pageNumber = pageNumber + 1"
      class="btn btn-secondary d-flex align-items-center justify-content-center p-0 rounded-circle ms-2"
      style="height: 40px; width: 40px;">
      <svg class="w-4 h-4 text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
           xmlns="http://www.w3.org/2000/svg">
        <path d="m6 9 6 6 6-6"></path>
      </svg>
    </button>
    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-circle text-white ms-2"
         style="width: 32px; height: 32px;">{{ pageNumber }}
    </div>
    <span class="text-white mx-2">/</span>
    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-circle text-white"
         style="width: 32px; height: 32px;">{{ totalPages }}
    </div>
    <!--    <button class="btn btn-secondary d-flex align-items-center justify-content-center p-0 rounded-circle ms-2"-->
    <!--            style="height: 40px; width: 40px;">-->
    <!--      <svg class="w-4 h-4 text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round"-->
    <!--           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"-->
    <!--           xmlns="http://www.w3.org/2000/svg">-->
    <!--        <circle cx="11" cy="11" r="8"></circle>-->
    <!--        <line x1="21" x2="16.65" y1="21" y2="16.65"></line>-->
    <!--        <line x1="11" x2="11" y1="8" y2="14"></line>-->
    <!--        <line x1="8" x2="14" y1="11" y2="11"></line>-->
    <!--      </svg>-->
    <!--    </button>-->
    <!--    <button class="btn btn-secondary d-flex align-items-center justify-content-center p-0 rounded-circle ms-2" style="height: 40px; width: 40px;">-->
    <!--      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-white">-->
    <!--        <circle cx="11" cy="11" r="8"></circle>-->
    <!--        <line x1="21" x2="16.65" y1="21" y2="16.65"></line>-->
    <!--        <line x1="11" x2="11" y1="8" y2="14"></line>-->
    <!--        <line x1="8" x2="14" y1="11" y2="11"></line>-->
    <!--      </svg>-->
    <!--    </button>-->
    <button

      (click)="downloadPdf()"

      class="btn btn-secondary d-flex align-items-center justify-content-center p-0 rounded-circle ms-2"
            style="height: 40px; width: 40px;">
      <svg class="w-4 h-4 text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.466 7.5C15.643 4.237 13.952 2 12 2 9.239 2 7 6.477 7 12s2.239 10 5 10c.342 0 .677-.069 1-.2"></path>
        <path d="m15.194 13.707 3.814 1.86-1.86 3.814"></path>
        <path
          d="M19 15.57c-1.804.885-4.274 1.43-7 1.43-5.523 0-10-2.239-10-5s4.477-5 10-5c4.838 0 8.873 1.718 9.8 4"></path>
      </svg>
    </button>
  </div>
</div>
