import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from '@auth/auth.module';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { DashboardPageComponent } from '@pages/dashboard-page/dashboard-page.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { CoreModule } from '@core/core.module';
import { LocationStrategy, NgOptimizedImage, PathLocationStrategy } from '@angular/common';
import { TrainingPageComponent } from '@pages/training-page/training-page.component';
import { MyCapstonePageComponent } from '@pages/my-capstone-page/my-capstone-page.component';
import { ApiModule } from '@app/api/api.module';
import { MyLearningPageComponent } from '@pages/my-learning-page/my-learning-page.component';
import { UserManagementPageComponent } from '@pages/user-management-page/user-management-page.component';
import { CapstoneProjectsPageComponent } from '@pages/capstone-projects-page/capstone-projects-page.component';
import { FileManagerPageComponent } from '@pages/file-manager-page/file-manager-page.component';
import { LibraryPageComponent } from '@pages/library-page/library-page.component';
import { VideosPageComponent } from '@pages/videos-page/videos-page.component';
import { ErrorPageComponent } from '@pages/error-page/error-page.component';
import { CourseComposerPageComponent } from '@pages/course-composer-page/course-composer-page.component';
import { VideoUploadPageComponent } from '@pages/course-composer-page/video-upload-page/video-upload-page.component';
import {
  CourseComposerHomePageComponent
} from '@pages/course-composer-page/course-composer-home-page/course-composer-home-page.component';
import { WatchPageComponent } from '@pages/videos-page/watch-page/watch-page.component';
import { AddUserPageComponent } from '@pages/user-management-page/add-user-page/add-user-page.component';
import {
  UserManagementHomePageComponent
} from '@pages/user-management-page/user-management-home-page/user-management-home-page.component';
import { ForgotPasswordPageComponent } from '@pages/forgot-password-page/forgot-password-page.component';
import { PasswordResetPageComponent } from '@pages/password-reset-page/password-reset-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailVerificationPageComponent } from '@pages/email-verification-page/email-verification-page.component';
import { ProfilePageComponent } from '@pages/profile-page/profile-page.component';
import { SettingsPageComponent } from '@pages/settings-page/settings-page.component';
import { MarkdownModule } from 'ngx-markdown';
import {
  QuestionCreatorPageComponent
} from '@pages/course-composer-page/question-creator-page/question-creator-page.component';
import {
  QuestionCreatorHomePageComponent
} from '@pages/course-composer-page/question-creator-page/question-creator-home-page/question-creator-home-page.component';
import {
  QuestionFormPageComponent
} from '@pages/course-composer-page/question-creator-page/question-form-page/question-form-page.component';
import {
  ContentLibraryHomePageComponent
} from '@pages/library-page/content-library-home-page/content-library-home-page.component';
import {
  ImportQuestionsPageComponent
} from '@pages/course-composer-page/question-creator-page/import-questions-page/import-questions-page.component';
import { ViewUsersPageComponent } from '@pages/user-management-page/view-users-page/view-users-page.component';
import {
  AssignmentComposerPageComponent
} from '@pages/course-composer-page/assignment-composer-page/assignment-composer-page.component';
import {
  AssignmentComposerHomePageComponent
} from '@pages/course-composer-page/assignment-composer-page/assignment-composer-home-page/assignment-composer-home-page.component';
import {
  AssignmentFormPageComponent
} from '@pages/course-composer-page/assignment-composer-page/assignment-form-page/assignment-form-page.component';
import {
  TemplateDownloadPageComponent
} from '@pages/course-composer-page/assignment-composer-page/template-download-page/template-download-page.component';
import {
  OpenAssignmentPageComponent
} from '@pages/course-composer-page/assignment-composer-page/open-assignment-page/open-assignment-page.component';
import { ContentCatalogPageComponent } from '@pages/library-page/content-catalog-page/content-catalog-page.component';
import { LessonPageComponent } from '@pages/course-composer-page/lesson-page/lesson-page.component';
import { CoursePageComponent } from '@pages/course-composer-page/course-page/course-page.component';
import {
  LessonHomePageComponent
} from '@pages/course-composer-page/lesson-page/lesson-home-page/lesson-home-page.component';
import {
  LessonFormPageComponent
} from '@pages/course-composer-page/lesson-page/lesson-form-page/lesson-form-page.component';
import {
  ViewEditLessonsPageComponent
} from '@pages/course-composer-page/lesson-page/view-edit-lessons-page/view-edit-lessons-page.component';
import { ModulesPageComponent } from '@pages/course-composer-page/modules-page/modules-page.component';
import {
  ModulesHomePageComponent
} from '@pages/course-composer-page/modules-page/modules-home-page/modules-home-page.component';
import {
  ModuleFormPageComponent
} from '@pages/course-composer-page/modules-page/module-form-page/module-form-page.component';
import {
  CourseHomePageComponent
} from '@pages/course-composer-page/course-page/course-home-page/course-home-page.component';
import {
  ViewCoursePageComponent
} from '@pages/course-composer-page/course-page/view-course-page/view-course-page.component';
import {
  CourseFormPageComponent
} from '@pages/course-composer-page/course-page/course-form-page/course-form-page.component';
import { LearningActivityPageComponent } from '@pages/learning-activity-page/learning-activity-page.component';
import {
  CourseActivityPageComponent
} from '@pages/learning-activity-page/course-activity-page/course-activity-page.component';
import {
  ModuleActivityPageComponent
} from '@pages/learning-activity-page/module-activity-page/module-activity-page.component';
import {
  LessonActivityPageComponent
} from '@pages/learning-activity-page/lesson-activity-page/lesson-activity-page.component';
import {
  AssignmentActivityPageComponent
} from '@pages/learning-activity-page/assignment-activity-page/assignment-activity-page.component';
import { LearningPageComponent } from '@pages/learning-page/learning-page.component';
import { LearningModule } from '@app/learning/learning.module';
import { TrainingHomePageComponent } from '@pages/training-page/training-home-page/training-home-page.component';
import { CohortsPageComponent } from '@pages/training-page/cohorts-page/cohorts-page.component';
import { CohortsAddPageComponent } from '@pages/training-page/cohorts-add-page/cohorts-add-page.component';
import { StudentsPageComponent } from '@pages/training-page/students-page/students-page.component';
import { StudentsAddPageComponent } from '@pages/training-page/students-add-page/students-add-page.component';
import { TrainersPageComponent } from '@pages/training-page/trainers-page/trainers-page.component';
import { TrainersAddPageComponent } from '@pages/training-page/trainers-add-page/trainers-add-page.component';
import { CohortsEditPageComponent } from '@pages/training-page/cohorts-edit-page/cohorts-edit-page.component';
import { TrainersEditPageComponent } from '@pages/training-page/trainers-edit-page/trainers-edit-page.component';
import { StudentsEditPageComponent } from '@pages/training-page/students-edit-page/students-edit-page.component';
import { CohortViewPageComponent } from '@pages/training-page/cohort-view-page/cohort-view-page.component';
import {
  TrainerViewPageComponent
} from '@pages/training-page/trainers-page/trainer-view-page/trainer-view-page.component';
import {
  StudentViewPageComponent
} from '@pages/training-page/students-page/student-view-page/student-view-page.component';
import { DiscussionModule } from './discussion/discussion.module';
import { HttpClientModule } from '@angular/common/http';

import {
  ViewEditModulesPageComponent
} from './pages/course-composer-page/modules-page/view-edit-modules-page/view-edit-modules-page.component';
import {
  ManageActivitiesPageComponent
} from './pages/learning-page/manage-activities-page/manage-activities-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { VideoCatalogPageComponent } from '@pages/videos-page/video-catalog-page/video-catalog-page.component';
import { CreateQuizPageComponent } from '@pages/course-composer-page/create-quiz-page/create-quiz-page.component';
import {
  CreateQuizHomePageComponent
} from './pages/course-composer-page/create-quiz-page/create-quiz-home-page/create-quiz-home-page.component';
import {
  QuizCreatorPageComponent
} from './pages/course-composer-page/create-quiz-page/quiz-creator-page/quiz-creator-page.component';
import {
  ViewEditQuizzesPageComponent
} from './pages/course-composer-page/create-quiz-page/view-edit-quizzes-page/view-edit-quizzes-page.component';
import {
  ImportQuizPageComponent
} from './pages/course-composer-page/create-quiz-page/import-quiz-page/import-quiz-page.component';
import { VideoUpdatePageComponent } from '@pages/videos-page/video-update-page/video-update-page.component';
import { ImportUsersPageComponent } from './pages/user-management-page/import-users-page/import-users-page.component';
import { UserTemplateDownloadPageComponent } from './pages/user-management-page/user-template-download-page/user-template-download-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardPageComponent,
    TrainingPageComponent,
    MyCapstonePageComponent,
    CourseComposerPageComponent,
    VideoUploadPageComponent,
    CourseComposerHomePageComponent,
    MyLearningPageComponent,
    UserManagementPageComponent,
    CapstoneProjectsPageComponent,
    FileManagerPageComponent,
    LibraryPageComponent,
    VideosPageComponent,
    ErrorPageComponent,
    WatchPageComponent,
    AddUserPageComponent,
    UserManagementHomePageComponent,
    ForgotPasswordPageComponent,
    PasswordResetPageComponent,
    EmailVerificationPageComponent,
    ProfilePageComponent,
    SettingsPageComponent,
    QuestionCreatorPageComponent,
    QuestionCreatorHomePageComponent,
    QuestionFormPageComponent,
    ContentLibraryHomePageComponent,
    ImportQuestionsPageComponent,
    ViewUsersPageComponent,
    AssignmentComposerPageComponent,
    AssignmentComposerHomePageComponent,
    AssignmentFormPageComponent,
    TemplateDownloadPageComponent,
    OpenAssignmentPageComponent,
    ContentCatalogPageComponent,
    LessonPageComponent,
    CoursePageComponent,
    LessonHomePageComponent,
    LessonFormPageComponent,
    ViewEditLessonsPageComponent,
    ModulesPageComponent,
    ModulesHomePageComponent,
    ModuleFormPageComponent,
    CourseHomePageComponent,
    ViewCoursePageComponent,
    CourseFormPageComponent,
    ViewEditModulesPageComponent,
    LearningActivityPageComponent,
    CourseActivityPageComponent,
    ModuleActivityPageComponent,
    LessonActivityPageComponent,
    AssignmentActivityPageComponent,
    LearningPageComponent,
    TrainingHomePageComponent,
    CohortsPageComponent,
    CohortsAddPageComponent,
    StudentsPageComponent,
    StudentsAddPageComponent,
    TrainersPageComponent,
    TrainersAddPageComponent,
    CohortsEditPageComponent,
    TrainersEditPageComponent,
    StudentsEditPageComponent,
    CohortViewPageComponent,
    TrainerViewPageComponent,
    StudentViewPageComponent,
    ManageActivitiesPageComponent,
    NotificationsPageComponent,
    MessagesComponent,
    CreateQuizPageComponent,
    VideoCatalogPageComponent,
    CreateQuizHomePageComponent,
    QuizCreatorPageComponent,
    ViewEditQuizzesPageComponent,
    ImportQuizPageComponent,
    VideoCatalogPageComponent,
    VideoUpdatePageComponent,
    ImportUsersPageComponent,
    UserTemplateDownloadPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AuthModule,
    NgOptimizedImage,
    CoreModule,
    ApiModule,
    ReactiveFormsModule,
    MarkdownModule,
    FormsModule,
    LearningModule,
    DiscussionModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  exports: [
    CourseActivityPageComponent,
    VideoUploadPageComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
