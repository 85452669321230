/**
 * User response model
 * com.smoothstack.learn.api.user.dto.UserResponse
 */
export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  emailVerified: boolean;
  emailVerifiedAt: Date;
  role: UserRole;
  roles: UserRole[];
  createdAt: Date;
  updatedAt: Date;
  studentId: number;
  enabled: boolean;
  lastSeenAt: Date;
}

export enum UserRole {
  Super_Admin = 'Super Admin',
  Admin = 'Admin',
  Student = 'Student',
  Trainer = 'Trainer',
  Training_Assistant = 'Training Assistant'
}
