import { Routes } from '@angular/router';
import {
  CourseComposerHomePageComponent
} from '@pages/course-composer-page/course-composer-home-page/course-composer-home-page.component';
import { VideoUploadPageComponent } from '@pages/course-composer-page/video-upload-page/video-upload-page.component';
import {
  QuestionCreatorPageComponent
} from '@pages/course-composer-page/question-creator-page/question-creator-page.component';
import {
  QuestionCreatorHomePageComponent
} from '@pages/course-composer-page/question-creator-page/question-creator-home-page/question-creator-home-page.component';
import {
  QuestionFormPageComponent
} from '@pages/course-composer-page/question-creator-page/question-form-page/question-form-page.component';
import { canComponentDeactivateGuard } from '@core/guards/can-component-deactivate.guard';
import {
  ImportQuestionsPageComponent
} from '@pages/course-composer-page/question-creator-page/import-questions-page/import-questions-page.component';
import {
  AssignmentComposerPageComponent
} from '@pages/course-composer-page/assignment-composer-page/assignment-composer-page.component';
import {
  AssignmentComposerHomePageComponent
} from '@pages/course-composer-page/assignment-composer-page/assignment-composer-home-page/assignment-composer-home-page.component';
import {
  TemplateDownloadPageComponent
} from '@pages/course-composer-page/assignment-composer-page/template-download-page/template-download-page.component';
import {
  OpenAssignmentPageComponent
} from '@pages/course-composer-page/assignment-composer-page/open-assignment-page/open-assignment-page.component';
import { LessonPageComponent } from '@pages/course-composer-page/lesson-page/lesson-page.component';
import {
  LessonHomePageComponent
} from '@pages/course-composer-page/lesson-page/lesson-home-page/lesson-home-page.component';
import {
  LessonFormPageComponent
} from '@pages/course-composer-page/lesson-page/lesson-form-page/lesson-form-page.component';
import {
  ViewEditLessonsPageComponent
} from '@pages/course-composer-page/lesson-page/view-edit-lessons-page/view-edit-lessons-page.component';
import { ModulesPageComponent } from '@pages/course-composer-page/modules-page/modules-page.component';
import {
  ModulesHomePageComponent
} from '@pages/course-composer-page/modules-page/modules-home-page/modules-home-page.component';
import {
  ModuleFormPageComponent
} from '@pages/course-composer-page/modules-page/module-form-page/module-form-page.component';
import {
  CourseFormPageComponent
} from '@pages/course-composer-page/course-page/course-form-page/course-form-page.component';
import { CoursePageComponent } from '@pages/course-composer-page/course-page/course-page.component';
import {
  CourseHomePageComponent
} from '@pages/course-composer-page/course-page/course-home-page/course-home-page.component';
import {
  ViewCoursePageComponent
} from '@pages/course-composer-page/course-page/view-course-page/view-course-page.component';
import { ViewEditModulesPageComponent } from '@pages/course-composer-page/modules-page/view-edit-modules-page/view-edit-modules-page.component';
import { CreateQuizPageComponent } from '@pages/course-composer-page/create-quiz-page/create-quiz-page.component';
import {
  QuizCreatorPageComponent
} from '@pages/course-composer-page/create-quiz-page/quiz-creator-page/quiz-creator-page.component';
import {
  CreateQuizHomePageComponent
} from '@pages/course-composer-page/create-quiz-page/create-quiz-home-page/create-quiz-home-page.component';
import {
  ImportQuizPageComponent
} from '@pages/course-composer-page/create-quiz-page/import-quiz-page/import-quiz-page.component';
import { ViewEditQuizzesPageComponent } from '@app/pages/course-composer-page/create-quiz-page/view-edit-quizzes-page/view-edit-quizzes-page.component';

export const CourseComposerRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CourseComposerHomePageComponent
  },
  {
    path: 'video-upload',
    data: { title: 'Video Upload' },
    component: VideoUploadPageComponent,
    canDeactivate: [canComponentDeactivateGuard]
  },
  {
    path: 'create-assignment',
    data: { title: 'Create Assignment' },
    component: AssignmentComposerPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AssignmentComposerHomePageComponent
      },
      {
        path: 'open',
        data: { title: 'Open Assignment' },
        component: OpenAssignmentPageComponent
      },
      {
        path: 'templates',
        data: { title: 'Assignment Templates' },
        component: TemplateDownloadPageComponent
      }
    ]
  },
  {
    path: 'question-creator',
    data: { title: 'Question Creator' },
    component: QuestionCreatorPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: QuestionCreatorHomePageComponent
      },
      {
        path: 'create',
        data: { title: 'Create Question', mode: 'create' },
        component: QuestionFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'edit/:id',
        data: { title: 'Edit Question', mode: 'edit' },
        component: QuestionFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'import',
        data: { title: 'Import Questions' },
        component: ImportQuestionsPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      }
    ]
  },
  {
    path: 'quiz-creator',
    data: { title: 'Quiz Creator' },
    component: CreateQuizPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CreateQuizHomePageComponent
      },
      {
        path: 'create',
        data: { title: 'Create Quiz', mode: 'create' },
        component: QuizCreatorPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'edit',
        data: { title: 'Edit Existing Quiz', mode: 'edit' },
        component: ViewEditQuizzesPageComponent,
      },
      {
        path: 'edit-quiz/:id',
        data: { title: 'Edit Quiz', mode: 'edit' },
        component: QuizCreatorPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'import',
        data: { title: 'Import Quiz' },
        component: ImportQuizPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      }
    ]
  },
  {
    path: 'lessons',
    data: { title: 'Lessons' },
    component: LessonPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LessonHomePageComponent
      },
      {
        path: 'create',
        data: { title: 'Create Lesson', mode: 'create' },
        component: LessonFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'edit',
        data: { title: 'Edit Existing Lesson', mode: 'edit' },
        component: ViewEditLessonsPageComponent
      },
      {
        path: 'edit/:id',
        data: { title: 'Edit Lesson', mode: 'edit' },
        component: LessonFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      }
    ]
  },
  {
    path: 'modules',
    data: { title: 'Modules' },
    component: ModulesPageComponent,
    canDeactivate: [canComponentDeactivateGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ModulesHomePageComponent
      },
      {
        path: 'create',
        data: { title: 'Create Module', mode: 'create' },
        component: ModuleFormPageComponent
      },
      {
        path: 'edit',
        data: { title: 'Edit Existing Module', mode: 'edit' },
        component: ViewEditModulesPageComponent
      },
      {
        path: 'edit/:id',
        data: { title: 'Edit Module', mode: 'edit' },
        component: ModuleFormPageComponent
      }
    ]
  },
  {
    path: 'courses',
    data: { title: 'Courses' },
    component: CoursePageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CourseHomePageComponent
      },
      {
        path: 'create',
        data: { title: 'Create Course', mode: 'create' },
        component: CourseFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      },
      {
        path: 'edit',
        data: { title: 'Edit Existing Course', mode: 'edit' },
        component: ViewCoursePageComponent
      },
      {
        path: 'edit/:id',
        data: { title: 'Edit Course', mode: 'edit' },
        component: CourseFormPageComponent,
        canDeactivate: [canComponentDeactivateGuard]
      }
    ]
  }
];
