<div class="container">

  <div class="card card-body my-4" *ngIf="user; else spinner">
    <div class="my-2 d-flex justify-content-start align-items-center gap-3">
      <div style="width: 200px; height: 200px" class="d-flex justify-content-center align-items-center">
        <img *ngIf="profilePictureUrl; else spinner" [src]="profilePictureUrl" class="img-fluid img-thumbnail rounded-circle" alt=""/>
      </div>
      <div class="d-flex flex-column">
        <div class="h2">{{user.firstName}} {{user.lastName}}</div>
        <div class="text-info"><span class="bi bi-person-badge"></span> {{user.role}}</div>
        <div [ngbTooltip]="user.emailVerified ? 'Email verified' : 'Email not verified'" placement="end">
          <span class="bi bi-envelope-fill text-info"></span>
          {{user.email}}
          <span class="bi bi-check-circle-fill text-success" *ngIf="user.emailVerified"></span>
        </div>
        <div class="my-2">
          <div class="text-muted">Joined {{user.createdAt | date}}</div>
          <div class="text-muted">Last Seen {{user.updatedAt | date: 'MMM dd, yyyy HH:mm:ss a'}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="list-group list-group-flush">
    <div *ngIf="currentUserRole === 'Super Admin' || currentUserRole === 'Admin' " class="list-group-item py-3">
      <h4>User status</h4>
      <p>Enable/disable user status.</p>
      <div class="my-2 form-check form-switch">
        <input class="form-check-input" type="checkbox" id="statusToggle" [(ngModel)]="statusToggled" (change)="toggleUserStatus()">
      </div>
    </div>
    <div class="list-group-item py-3">
      <h4>Email Verification</h4>
      <p class="small text-muted">Email{{ user?.emailVerified ? ' ' : ' not ' }}verified <span class="bi" [ngClass]="{
        'bi-check-circle-fill text-success': user?.emailVerified,
        'bi-x-circle-fill text-danger': !user?.emailVerified
      }"></span></p>
      <p>Send a verification link to the user's email address.</p>
      <button type="button"
              [disabled]="user?.emailVerified || sendingVerificationEmail"
              (click)="sendVerificationEmail()"
              class="btn btn-primary">
        Send Verification Email <span *ngIf="!sendingVerificationEmail; else inlineSpinner" class="bi bi-envelope-fill"></span>
      </button>
    </div>
    <div class="list-group-item py-3">
      <h4>Password Reset</h4>
      <p>Send a password reset link to the user's email address.</p>
      <button type="button"
              [disabled]="sendingPasswordResetEmail"
              (click)="sendPasswordResetEmail()"
              class="btn btn-primary">
        Reset Password <span *ngIf="!sendingPasswordResetEmail; else inlineSpinner" class="bi bi-key-fill"></span>
      </button>
    </div>
    <div class="list-group-item py-3">
      <h4>Roles</h4>
      <p>Select/deselect a single or multiple roles to grant/revoke privileges.</p>
      <div class="my-2">
        <span *ngFor="let role of currentUserRoles" class="bg-info text-light small border rounded p-1 px-2 me-1">
          {{role}}
        </span>
      </div>
      <button type="button"
              (click)="openUpdateRolesModal()"
              class="btn btn-primary">
       Update Roles <span class="bi bi-person-badge"></span>
      </button>
    </div>
    <div class="list-group-item py-3">
      <h4>Delete User</h4>
      <p>Delete the user and remove access to all their resources.</p>
      <app-delete-user-button [user]="user">
        <ng-template let-openDeleteUserModal="openDeleteUserModal">
          <button type="button" class="btn btn-danger" (click)="openDeleteUserModal()">
            <span class="bi bi-trash-fill"></span>
            Delete User
          </button>
        </ng-template></app-delete-user-button>
    </div>
  </div>

  <ng-template *ngIf="user" #updateRolesModal>
    <p>Select/deselect a single or multiple roles to grant/revoke privileges.</p>
    <app-multi-select-field [options]="allRoles" [minCount]="1" [(ngModel)]="selectedUserRoles"></app-multi-select-field>
  </ng-template>

  <ng-template #spinner>
    <div class="spinner-border text-primary"></div>
  </ng-template>

  <ng-template #inlineSpinner>
    <span class="spinner-border spinner-border-sm"></span>
  </ng-template>

</div>
