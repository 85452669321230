import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Cohort } from '@app/api/models/cohort.model';
import { Student } from '@app/api/student/model/student.model';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { UserFilterParams } from '@app/api/user/models/user-filter-params.model';
import { User } from '@app/api/user/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-cohort-form',
  templateUrl: './cohort-form.component.html',
  styleUrls: ['./cohort-form.component.sass']
})
export class CohortFormComponent implements OnInit, OnChanges {

  @ViewChild('addExistingStudentModal') addExistingStudentModal?: TemplateRef<any>;
  @ViewChild('addNewStudentModal') addNewStudentModal?: TemplateRef<any>;

  @Input() cohort?: Cohort;
  @Output() submitCohort = new EventEmitter<Cohort>();
  @Output() deleteCohort = new EventEmitter<Cohort>();
  cohortForm: FormGroup;
  newStudentForm: FormGroup;
  datePickerPlacement: PlacementArray = ['bottom-left', 'bottom-right'];
  selectedUsers: User[] = [];
  cohortStudents: Student[] = [];

  private _saving = false;
  @Input() set saving(value: boolean) {
    if (value) {
      this.cohortForm.disable();
    } else {
      this.cohortForm.enable();
    }
    this._saving = value;
  }
  get saving() {
    return this._saving;
  }
  @Input() mode: 'create' | 'edit' = 'create';

  constructor(private modalService: NgbModal, private globalModal: GlobalModalService) {
    const today = new Date();
    this.cohortForm = new FormGroup({
      id: new FormControl(),
      startDate: new FormControl({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      }, [
        Validators.required
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9\s_-]+$/),
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      description: new FormControl('', [
        Validators.maxLength(255)
      ]),
      inviteAll: new FormControl(false),
      technologies: new FormControl([]),
      createGitlabGroups: new FormControl(false)
    });

    this.cohortForm.get('inviteAll')?.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          this.cohortStudents.forEach((s) => {
            s.invite = true;
          });
        } else {
          this.cohortStudents.forEach((s) => {
            s.invite = false;
          });
        }
      }
    });

    this.newStudentForm = new FormGroup({
      students: new FormArray([])
    });
    this.addNewStudent();
  }

  ngOnInit(): void {
    this.syncForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cohort']) {
      this.syncForm();
    }
  }

  addNewStudent() {
    // Add control to new student form
    const students = this.newStudentForm.get('students') as FormArray;
    const studentFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
    });

    students.push(studentFormGroup);
  }

  removeNewStudent(index: number) {
    const students = this.newStudentForm.get('students') as FormArray;
    students.removeAt(index);
  }

  removeStudent(index: number) {
    this.globalModal.confirm('Are you sure you want to remove this student?', {
      type: 'danger'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.cohortStudents.splice(index, 1);
        }
      }
    });
  }

  get studentsFormArray(): FormArray {
    return this.newStudentForm.get('students') as FormArray;
  }

  syncForm() {
    if (this.cohort) {
      this.cohortForm.patchValue(this.cohort);
      const today = new Date();
      const year = this.cohort.year || today.getFullYear();
      const month = this.cohort.month || today.getMonth() + 1;
      const day = this.cohort.day || today.getDate();

      this.cohortForm.get('startDate')?.patchValue({
        year, month, day
      });

      this.cohortStudents = this.cohort.students || [];
    }
  }

  onSubmitCohort() {
    const cohort = this.cohortForm.value;
    cohort.year = cohort.startDate.year;
    cohort.month = cohort.startDate.month;
    cohort.day = cohort.startDate.day;
    cohort.students = this.cohortStudents;
    this.submitCohort.emit(cohort);
  }

  get studentsOnly(): UserFilterParams {
    return {
      role: 'STUDENT',
      enabled: true,
    };
  }

  openAddExistingStudentModal() {
    this.modalService.open(this.addExistingStudentModal, {
      size: 'lg',
      backdrop: 'static',
    });
  }

  openAddNewStudentModal() {
    this.modalService.open(this.addNewStudentModal, {
      size: 'lg',
      scrollable: true,
      backdrop: 'static',
    });
  }

  addNewStudents() {
    const students = this.newStudentForm.get('students')?.value as Student[];
    this.cohortStudents = this.cohortStudents.concat(students);
    this.newStudentForm.reset();
  }

  addExistingStudents() {
    const students: Student[] = this.selectedUsers.map((u) => {
      return {
        email: u.email,
        firstName: u.firstName,
        lastName: u.lastName
      } as Student;
    });

    this.cohortStudents = this.cohortStudents.concat(students);
    this.selectedUsers = [];
  }

  openDeleteCohortConfirm() {
    this.globalModal.confirmDelete(
      'Are you sure you want to delete this cohort? This cannot be undone.',
      this.cohort?.name)
      .subscribe({
        next: (result) => {
          if (result) {
            this.deleteCohort.emit(this.cohort);
          }
        }
      });
  }

}
