import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Student } from '@app/api/student/model/student.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-student-edit-form',
  templateUrl: './student-edit-form.component.html',
  styleUrls: ['./student-edit-form.component.sass']
})
export class StudentEditFormComponent implements OnChanges {
  
  @Input() student?: Student;
  @Output() saveChanges = new EventEmitter<Student>();
  @Output() deleteStudent = new EventEmitter<Student>();

  editForm: FormGroup;

  constructor(private globalModal: GlobalModalService,
    private fb: FormBuilder) {
    this.editForm = this.fb.group({
      id: [null],
      email: ['', [Validators.email, Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      invite: [false],
    });
  }

  ngOnChanges() {
    if (this.student) {
      this.editForm.patchValue({
        id: this.student.id,
        email: this.student.email,
        firstName: this.student.firstName,
        lastName: this.student.lastName,
        invite: this.student.invite
      });
    }
  }

  onSubmit() {
    if (this.editForm.valid) {
      const student = this.editForm.value;
      this.saveChanges.emit(student);
    }
  }

  openDeleteStudentConfirm() {
    this.globalModal.confirmDelete(
      'Are you sure you want to delete this student? This cannot be undone.',
      this.student?.firstName)
      .subscribe({
        next: (result) => {
          if (result) {
            this.deleteStudent.emit(this.student);
          }
        }
      });
  }
}
