import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { VideoService } from '@app/api/video/services/video.service';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';

@Component({
  selector: 'app-watch-page',
  templateUrl: './watch-page.component.html',
  styleUrls: ['./watch-page.component.sass']
})
export class WatchPageComponent {

  videoId = 0;
  videoMetadata?: VideoMetaData;

  constructor(
    public video: VideoService,
    private route: ActivatedRoute,
    private title: Title,
    private router: Router
  ) {

    const routeSnapshot = this.route.snapshot;

    const videoId = routeSnapshot.paramMap.get('id');

    if (!videoId || isNaN(parseInt(videoId, 10))) {
      this.router.navigate(['/videos']);
      throw new Error('No video ID provided.');
    }

    this.videoId = parseInt(videoId, 10);

    this.video.getVideoMetaData(this.videoId).subscribe((videoMetadata) => {
      this.videoMetadata = videoMetadata;
      this.title.setTitle(videoMetadata.title);
    });

  }



}
