import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.sass']
})
export class IconComponent {

  @Input() iconName = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') className = '';
  defaultStyle = {
    'width.em': 1
  };

  get iconSrc(): string {
    return `assets/icons/${this.iconName}.svg`;
  }

}
