import { Injectable } from '@angular/core';
import { Notification } from '@core/interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: Notification[] = [];
  autoIncrementCounter = 0;

  constructor() { }

  addNotification(notification: Notification) {
    if (this.notifications.find(n => n.uniqueName === notification.uniqueName)) {
      return;
    }
    if (!notification.id) {
      notification.id = this.autoIncrementCounter++;
    } else {
      this.deleteNotification(notification.id);
    }
    this.notifications.push(notification);
  }

  deleteNotification(id: number | string) {
    this.notifications = this.notifications.filter(n => n.id !== id);
  }

  clearAll() {
    this.notifications = [];
  }

}
