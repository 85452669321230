import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AspectRatios,
  FileUploadFieldComponent
} from '@core/components/forms/file-upload-field/file-upload-field.component';

@Component({
  selector: 'app-upload-field',
  template: `
    <div [formGroup]="formGroup">
      <app-file-upload-field    controlName="file"
                                #fileUploadField
                                [multipleFiles]="multipleFiles"
                                [maxFiles]="maxFiles"
                                [previewImage]="previewImage"
                                [restrictAspectRatio]="restrictAspectRatio"
                                [acceptedExtensions]="acceptedExtensions"
                                (filesChanged)="onChange($event); filesChanged.emit($event)"
                                [label]="label"></app-file-upload-field>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadFieldComponent,
      multi: true,
    }
  ]
})
export class UploadFieldComponent implements ControlValueAccessor {

  @ViewChild('fileUploadField') fileUploadField?: FileUploadFieldComponent;

  value: File[] = [];
  formGroup: FormGroup;
  isDisabled = false;
  @Input() label = 'Drag and drop files here';
  @Input() acceptedExtensions = '*';
  @Input() multipleFiles = false;
  @Input() maxFiles = 1;
  @Input() previewImage = false;
  @Input() restrictAspectRatio: AspectRatios = '*';
  @Output() filesChanged = new EventEmitter<File[]>();

  onChange: (files: File[]) => void = (files: File[]) => {};
  onTouched: any = () => {};

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      file: [null]
    });
    this.formGroup.controls['file'].valueChanges.subscribe((files: File[]) => {
      this.onChange(files);
    });
  }

  registerOnChange(fn: (files: File[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    } else {
      this.value = [];
    }
  }

  clearFiles() {
    this.fileUploadField?.clearFiles();
  }

}
