<ng-container *ngIf="options">
  <div class="modal-header" *ngIf="modalOptions.title">
    <h5 class="modal-title">
        <span class="bi text-{{options.type}}" [ngClass]="{
          'bi-check-circle-fill': modalOptions.type === 'success',
          'bi-exclamation-circle-fill': modalOptions.type === 'danger',
          'bi-exclamation-triangle-fill': modalOptions.type === 'warning',
          'bi-info-circle-fill': modalOptions.type === 'info'
        }"></span>
      {{ modalOptions.title }}
    </h5>
    <button *ngIf="modalOptions.dismissable" type="button" class="btn-close" aria-label="Close"
            (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="isTemplate; else stringContent">
      <ng-template [ngTemplateOutlet]="contentAsTemplate"></ng-template>
    </ng-container>
    <ng-template #stringContent>
      <div *ngIf="modalOptions.showSpinner || modalOptions.iconClass; else noIconStringContent"
           class="d-flex flex-column justify-content-center align-items-center">
        <div *ngIf="modalOptions.showSpinner" class="spinner-border text-primary" role="status">
        </div>
        <div *ngIf="modalOptions.iconClass" class="bi {{modalOptions.iconClass}} fs-1">
        </div>
        <div class="my-2" [innerHTML]="contentAsString | markdown">
        </div>
      </div>
      <ng-template #noIconStringContent>
        <div [innerHTML]="contentAsString | markdown">
        </div>
      </ng-template>
    </ng-template>
    <div *ngIf="modalOptions.deleteMode" class="mt-3">
      <p>To delete this resource, type <code>{{ modalOptions.resourceName }}</code> below.</p>
      <input type="text" class="form-control" [(ngModel)]="deleteInput" (keyup.enter)="okCallback()">
    </div>
  </div>
  <div *ngIf="!modalOptions.hideButtons" class="modal-footer" [ngSwitch]="modalOptions.type">
    <ng-container *ngSwitchCase="'success'">
      <button type="button" class="btn btn-success" (click)="okCallback()">
        {{ modalOptions.okButtonText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'danger'">
      <button type="button" class="btn btn-danger" (click)="okCallback()" [disabled]="disableOkButton()">
        {{ modalOptions.okButtonText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'warning'">
      <button type="button" class="btn btn-warning" (click)="okCallback()">
        {{ modalOptions.okButtonText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'info'">
      <button type="button" class="btn btn-primary" (click)="okCallback()">
        {{ modalOptions.okButtonText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button type="button" class="btn btn-primary" (click)="okCallback()">
        {{ modalOptions.okButtonText }}
      </button>
    </ng-container>
    <button *ngIf="modalOptions.showCancelButton" type="button" class="btn btn-outline-secondary"
            (click)="cancelCallback()">
      {{ modalOptions.cancelButtonText }}
    </button>
  </div>
</ng-container>
