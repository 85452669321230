<div class="container-fluid">
  <div class="row">
    <div class="d-none d-md-block col-sm-6 col-md-5 col-lg-3">
      <div class="m-2 border rounded h-100">
        <div class="mb-3 border-bottom p-2">
          Module Preview
        </div>
        <div class="mx-2">
          <app-module-tree [moduleData]="moduleData"></app-module-tree>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-7">
      <div class="mb-3 py-2 border-bottom">
        <h3><span class="bi bi-folder-plus text-primary"></span> {{mode | titlecase}} Module</h3>
      </div>
      <app-module-form (editLesson)="openEditLessonPage($event)" [(moduleData)]="moduleData" (triggerSaveModule)="triggerSaveModule()"></app-module-form>
      <div class="my-3">
        <div class="d-flex justify-content-end gap-1 align-items-center">
          <button *ngIf="!showAddToCourseButton" [disabled]="cannotSave" class="btn btn-outline-primary" (click)="saveModule()">
            Save
            <span class="bi bi-floppy"></span>
          </button>
          <button [disabled]="cannotPublish" *ngIf="!moduleData.published && !showAddToCourseButton" class="btn btn-primary" (click)="saveModule(true)">
            Save & Publish
            <span class="bi bi-check2-circle"></span>
          </button>
          <button [disabled]="cannotSave" *ngIf="moduleData.published && !showAddToCourseButton" class="btn btn-primary" (click)="saveModule(false)">
            Unpublish
            <span class="bi bi-check2-circle"></span>
          </button>
          <button *ngIf="showAddToCourseButton" (click)="saveModule(true)" type="button" class="btn btn-primary" [ngbTooltip]="'Add module to course'">
            <span class="bi bi-file-earmark-plus"></span>
            Add Module
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
