import { Component, NgZone, OnInit } from '@angular/core';
import { CanComponentDeactivate } from '@core/guards/can-component-deactivate.guard';
import { Observable } from 'rxjs';
import { QuestionFormService } from '@app/api/question/services/question-form.service';
import { QuestionData } from '@app/api/question/models/question-data.model';

@Component({
  selector: 'app-create-question-page',
  templateUrl: './question-form-page.component.html',
  styleUrls: ['./question-form-page.component.sass']
})
export class QuestionFormPageComponent implements CanComponentDeactivate {

  get questionData(): QuestionData {
    return this.questionFormService.mapRequestToQuestionData(this.questionFormService.formGroup.value);
  }

  constructor(public questionFormService: QuestionFormService) {
  }


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.questionFormService.canDeactivate();
  }

}
