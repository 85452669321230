import { Component, Input } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-route-card-grid',
  templateUrl: './route-card-grid.component.html',
  styleUrls: ['./route-card-grid.component.sass']
})
export class RouteCardGridComponent {
  @Input() items: RouteCardItem[] = [];
}
