<div class="container">
    <div *ngIf="showTitle" class="my-2">
      <h2>My Courses</h2>
    </div>
    <div class="my-3">
      <div *ngIf="!loading; else spinner" class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-4 my-5">
        <div *ngFor="let course of courses" class="col my-3">
          <div tabindex="0" class="card card-body h-100 course-card" (click)="openEditCoursePage(course)">
            <div class="d-flex flex-row align-items-center h-100">
              <div class="bi bi-file-earmark-text text-primary display-6"></div>
              <div class="mx-2 flex-grow-1">
                <div class="fw-bold text-wrap">{{truncateText(course.title, 15) || 'Untitled Course'}}</div>
                <p class="text-muted">{{truncateText(course.description, 25) || 'No description'}}</p>
              </div>
              <div class="h-100 align-top" ngbDropdown placement="right-top" click-stop-propagation>
                <button ngbDropdownToggle class="btn btn-sm btn-link text-primary border-0 dropdown-no-caret" click-stop-propagation>
                  <span class="bi bi-three-dots-vertical"></span>
                </button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>
                    <div class="d-flex justify-content-between" (click)="openEditCoursePage(course)">
                      Edit <span class="bi bi-pencil-square"></span>
                    </div>
                  </button>
                  <button ngbDropdownItem >
                    <div class="d-flex justify-content-between" (click)="deleteCourse(course.id!)">
                      Delete <span class="bi bi-trash text-danger"></span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <br class="my-5">
  <div class="position-fixed bottom-0 border-top py-5 w-content-100 bg-white">
    <ngb-pagination
      (pageChange)="loadCourses()"
      [(page)]="currentPage"
      [collectionSize]="totalItems"
      [pageSize]="pageSize"
      [size]="'sm'"
      class="position-fixed start-50 translate-middle-x">
    </ngb-pagination>
  </div>
  </div>
  <ng-template #spinner>
    <div class="row row-cols-1">
      <div class="col d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>

