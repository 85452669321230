import { Component } from '@angular/core';

@Component({
  selector: 'app-course-activity-page',
  templateUrl: './course-activity-page.component.html',
  styleUrls: ['./course-activity-page.component.sass']
})
export class CourseActivityPageComponent {

}
