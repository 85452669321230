<div class="modal-header">
  <h5 class="modal-title">Upload Video</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal.emit()">

  </button>
</div>
<div class="modal-body">
  <app-video-upload-form/>
</div>
<div class="modal-footer">
  <p class="text-danger" *ngIf="error">{{ error }}</p>
  <button
    [disabled]="videoUploadForm.invalid"
    type="button"
    class="btn btn-primary"
    (click)="uploadVideo()">
    Upload
  </button>

  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="closeModal.emit()">Cancel
  </button>
</div>

<ng-template #uploadingVideo>
  <div class="modal-body">
    <div class="my-5">
      <div class="w-100 d-flex justify-content-center align-items-center flex-column">
        <span class="spinner-border text-primary" role="status"></span>
        <span class="fs-4">Uploading...</span>
        <span class="small text-muted my-3">Please don't close this tab while we upload your video.</span>
      </div>
      <div class="mt-2">
        <ngb-progressbar [value]="uploadProgress"></ngb-progressbar>
        <div *ngIf="uploadProgress >= 99" class="text-center small text-muted mt-2">
          Just a moment while we finish up...
        </div>
      </div>
    </div>
  </div>
</ng-template>
