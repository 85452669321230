import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { EnvironmentService } from '@core/services/environment.service';
import { AuthorizeRequest } from '@core/interfaces/authorize-request.model';

@Injectable({
  providedIn: 'root'
})
export class OidcService {

  private _authCode = '';

  constructor(private route: ActivatedRoute, private http: HttpClient, private env: EnvironmentService) {}

  get authCode() {
    return this._authCode;
  }

  set authCode(authCode: string) {
    this._authCode = authCode;
  }

  get ssoMode() {
    return this.route.snapshot.queryParams['sso'] === 'true';
  }

  get hasClientId(): boolean {
    return !!this.route.snapshot.queryParams['client_id'];
  }

  get clientId(): string {
    return this.route.snapshot.queryParams['client_id'];
  }

  get redirectUri(): string {
    return this.route.snapshot.queryParams['redirect_uri'];
  }

  get responseType(): string {
    return this.route.snapshot.queryParams['response_type'];
  }

  get scope(): string {
    return this.route.snapshot.queryParams['scope'];
  }

  get state(): string {
    return this.route.snapshot.queryParams['state'];
  }

  get nonce() {
    return this.route.snapshot.queryParams['nonce'];
  }

  getClientName(clientId: string) {
    return this.http.get(`${this.getOidcUrl()}/clients/${clientId}`)
      .pipe(map((response: any) => response['client_name'] as string));
  }

  getAuthCode(authRequest: AuthorizeRequest) {
    return this.http.post(`${this.getOidcUrl()}/authorize`, authRequest)
      .pipe(map((response: any) => response['code'] as string));
  }

  getOidcUrl(): string {
    return this.env.getEnv('apiUrl').replace('/api/v1', '/openid-connect');
  }

  get queryParamsValid() {
    return this.hasClientId && this.redirectUri && this.responseType && this.scope;
  }

  get redirectUriWithCode() {
    return `${this.redirectUri}?code=${this.authCode}&scope=${this.scope}&state=${this.state}`;
  }

}
