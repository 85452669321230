import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';
import { VideoService } from '@app/api/video/services/video.service';
import { combineLatest, finalize, tap } from 'rxjs';

export type VideoAction =
  'play' |
  'pause' |
  'togglePlay' |
  'skipBackwards10s' |
  'skipForward10s' |
  'mute' |
  'unmute' |
  'toggleMute' |
  'fullscreen' |
  'exitFullscreen' |
  'toggleFullscreen';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.sass']
})
export class VideoPlayerComponent implements OnInit {

  @Input() id = 0;
  @Input() youtubeUrl = '';
  @Input() trackStartPrimaryColor = '#4D36C6';
  @Input() trackPrimaryColor = '#7322A9FF';
  @Input() trackSecondaryColor = '#212529FF';
  @Input() scrubScale = 100;
  videoMetadata?: VideoMetaData;
  thumbnailUrl?: string;
  apiVideo = false;
  metadataLoaded = false;
  videoLoaded = false;
  videoPlayed = false;
  skipping = false;
  skipForward = false;
  skipBackward = false;
  // Get video template ref
  @ViewChild('videoTemplate') videoTemplateRef?: ElementRef<HTMLVideoElement>;
  videoData: {
    src: string;
    poster: string;
    duration: number;
    currentTime: number;
    volume: number;
    muted: boolean;
    playbackRate: number;
    playbackQuality: VideoPlaybackQuality | null;
    fullscreen: boolean;
    ended: boolean;
    paused: boolean;
    playing: boolean;
  } = {
      src: '',
      poster: '',
      duration: 0,
      currentTime: 0,
      volume: 0,
      muted: false,
      playbackRate: 0,
      playbackQuality: null,
      fullscreen: false,
      ended: false,
      paused: false,
      playing: false
    };
  rangeStyle: any = {};
  playBtnTimeout: any = null;
  buffering = false;

  get videoElement() {
    if (!this.videoTemplateRef) {
      throw new Error('Cannot get video element before it is initialized.');
    }
    return this.videoTemplateRef.nativeElement;
  }

  constructor(private video: VideoService) {
  }

  ngOnInit() {
    this.apiVideo = this.id > 0;

    if (this.id && this.youtubeUrl) {
      throw new Error('You cannot provide both an ID and a YouTube URL to the video player.');
    }

    if (!this.id && !this.youtubeUrl) {
      throw new Error('You must provide either an ID or a YouTube URL to the video player.');
    }

    if (this.id) {
      combineLatest([
        this.video.getVideoMetaData(this.id).pipe(
          tap(videoMetadata => this.videoMetadata = videoMetadata)
        ),
        this.video.getVideoThumbnail(this.id).pipe(
          tap(thumbnail => this.thumbnailUrl = URL.createObjectURL(thumbnail))
        )
      ])
        .pipe(
          finalize(() => this.metadataLoaded = true)
        )
        .subscribe();
    }
  }

  onVideoLoaded() {
    this.videoDataUpdate();
    this.updateRangeStyle();
  }

  doAction(action: VideoAction) {
    switch (action) {
      case 'play':
        this.videoElement.play();
        this.videoPlayed = true;
        this.videoData.playing = true;
        break;
      case 'pause':
        this.videoElement.pause();
        this.videoData.playing = false;
        break;
      case 'togglePlay':
        this.doAction(this.videoData.playing ? 'pause' : 'play');
        break;
      case 'skipBackwards10s':
        this.videoElement.currentTime -= 10;
        break;
      case 'skipForward10s':
        this.videoElement.currentTime += 10;
        break;
      case 'mute':
        this.videoElement.muted = true;
        break;
      case 'unmute':
        this.videoElement.muted = false;
        break;
      case 'toggleMute':
        this.videoElement.muted = !this.videoElement.muted;
        break;
      case 'fullscreen':
        this.videoElement.requestFullscreen();
        break;
      case 'exitFullscreen':
        document.exitFullscreen();
        break;
      case 'toggleFullscreen':
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          this.videoElement.requestFullscreen();
        }
        break;
      default:
        break;
    }

    this.videoDataUpdate();

  }

  // Set video data based on video element
  videoDataUpdate() {
    this.videoLoaded = true;
    this.videoData.src = this.videoElement.currentSrc;
    this.videoData.poster = this.videoElement.poster;
    this.videoData.duration = this.videoElement.duration * this.scrubScale;
    this.videoData.currentTime = this.videoElement.currentTime * this.scrubScale;
    this.videoData.volume = this.videoElement.volume;
    this.videoData.muted = this.videoElement.muted;
    this.videoData.playbackRate = this.videoElement.playbackRate;
    this.videoData.playbackQuality = this.videoElement.getVideoPlaybackQuality();
  }

  togglePlayBtn() {
    if (this.playBtnTimeout) {
      clearTimeout(this.playBtnTimeout);
    }
    this.playBtnTimeout = setTimeout(() => {
      this.doAction('togglePlay');
      this.videoPlayed = true;
    }, 250);
  }

  seekVideo() {
    this.videoElement.currentTime = this.videoData.currentTime / this.scrubScale;
    this.updateRangeStyle();
  }

  get videoPercentPlayed() {
    if (!this.videoElement) {
      return 0;
    }
    return (this.videoElement.currentTime / this.videoElement.duration) * 100;
  }

  updateRangeStyle() {
    // linear-gradient(to right, $primary 0%, $dark 0%)
    const percent = this.videoPercentPlayed;
    const startColor = this.trackStartPrimaryColor ? this.trackStartPrimaryColor : this.trackPrimaryColor;
    this.rangeStyle = {
      background: `linear-gradient(to right, ${startColor} 0%, ${this.trackPrimaryColor} ${percent}%, ${this.trackSecondaryColor} ${percent}%)`
    };
  }

  onTimeUpdate() {
    this.videoData.currentTime = this.videoElement.currentTime * this.scrubScale;
    this.updateRangeStyle();
  }

  checkDblClick(event: any) {
    clearTimeout(this.playBtnTimeout);
    this.skipping = true;
    const middlePoint = this.videoElement.offsetWidth / 2;
    if (event.offsetX < middlePoint) {
      this.doAction('skipBackwards10s');
      this.skipForward = false;
      this.skipBackward = true;
    } else {
      this.doAction('skipForward10s');
      this.skipForward = true;
      this.skipBackward = false;
    }

    setTimeout(() => {
      this.skipping = false;
      this.skipForward = false;
      this.skipBackward = false;
    }, 1250);
  }

  showLoader() {
    this.buffering = true;
  }

  hideLoader() {
    this.buffering = false;
  }

}
