<div class="container" *ngIf="!loading && course && courseActivity; else loader">

  <div class="my-5 pt-3 flex-center flex-column">
    <div class="mb-3">
      <app-percent-circle [percent]="courseActivity.progress"></app-percent-circle>
    </div>
    <div class="text-center">
      <div class="text-muted small">
        Course
      </div>
      <h3 class="text-center">
        {{ course.title }}
      </h3>
    </div>
    <div class="my-2 flex-center gap-1">
      <span *ngFor="let tag of course.technologies" class="badge rounded-pill small bg-info text-white">
        {{tag}}
      </span>
    </div>
    <p class="my-2 text-muted">
      {{ course.description }}
    </p>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card-body">

        <div class="list-group">

          <button *ngFor="let moduleActivity of courseActivity.linkedActivities"
                  [routerLink]="[ './module', moduleActivity.id ]"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  type="button">

            <div class="d-flex align-items-center">
              <span class="bi bi-folder-fill text-info fs-2 mx-3"></span>

              <div class="my-3">
                <div class="text-muted small">
                  Module
                </div>
                <div class="fw-bold">
                  {{ moduleActivity.order + 1 }}. {{ moduleActivity.title }}
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="mx-2">
                <app-percent-circle [percent]="moduleActivity.progress" size="xxs"></app-percent-circle>
              </div>
              <span class="bi bi-chevron-right text-primary mx-3 fs-5"></span>
            </div>

          </button>

        </div>

      </div>
    </div>

  </div>

</div>

<ng-template #loader>
  <app-activity-skeleton-structure></app-activity-skeleton-structure>
</ng-template>
