import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseActivityTree, LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Component({
  selector: 'app-learning-activity-view-panel',
  templateUrl: './learning-activity-view-panel.component.html',
  styleUrls: ['./learning-activity-view-panel.component.sass']
})
export class LearningActivityViewPanelComponent implements OnInit {

  learningActivity?: LearningActivity;
  contentType?: ActivityMaterialType;
  parentActivityId?: number;

  constructor(private learningActivityService: LearningActivityService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {

    this.route.parent?.paramMap.subscribe(params => {
      this.parentActivityId = Number(params.get('id'));
    });

    this.route.paramMap.subscribe(params => {
      const id = Number(params.get('id'));
      this.updateLearningActivity(id);
    });
  }

  updateLearningActivity(id: number) {
    this.learningActivityService.getLearningActivity(id)
      .subscribe(learningActivity => {
        this.learningActivity = learningActivity;

        this.route.data.subscribe(data => {
          const type = data['type'];

          if (type === 'ANY') {
            this.contentType = this.learningActivity?.type as ActivityMaterialType;
          } else {
            this.contentType = type.toUpperCase() as ActivityMaterialType;
          }
        });
      });
  }

  get isCourse(): boolean {
    return this.learningActivity?.type === 'COURSE' && this.contentType === 'COURSE';
  }

  get isModule(): boolean {
    return this.learningActivity?.type === 'MODULE' && this.contentType === 'MODULE';
  }

  get isLesson(): boolean {
    return this.learningActivity?.type === 'LESSON' && this.contentType === 'LESSON';
  }

  get isQuiz(): boolean {
    return this.learningActivity?.type === 'QUIZ' && this.contentType === 'QUIZ';
  }

  get isAssignment(): boolean {
    return this.learningActivity?.type === 'ASSIGNMENT' && this.contentType === 'ASSIGNMENT';
  }

  get validRequest(): boolean {
    return this.learningActivity?.parentId === this.parentActivityId;
  }

}
