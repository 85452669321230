export type QuestionType = 'MULTIPLE_CHOICE' | 'FILL_IN_THE_BLANK' | 'TRUE_FALSE' | 'SHORT_ANSWER' | 'INTERVIEW';

export type QuestionDifficulty = 'EASY' | 'MEDIUM' | 'HARD' | 'EXPERT';

export type QuestionCategory = 'TECHNOLOGY' | 'INTERVIEW' | 'SOFT_SKILLS' | 'OTHER';

export const QuestionTypes: {
  MULTIPLE_CHOICE: QuestionType,
  FILL_IN_THE_BLANK: QuestionType,
  TRUE_FALSE: QuestionType,
  SHORT_ANSWER: QuestionType,
  INTERVIEW: QuestionType,
} = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  FILL_IN_THE_BLANK: 'FILL_IN_THE_BLANK',
  TRUE_FALSE: 'TRUE_FALSE',
  SHORT_ANSWER: 'SHORT_ANSWER',
  INTERVIEW: 'INTERVIEW',
};

export const QuestionDifficulties: {
  EASY: QuestionDifficulty,
  MEDIUM: QuestionDifficulty,
  HARD: QuestionDifficulty,
  EXPERT: QuestionDifficulty,
} = {
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  HARD: 'HARD',
  EXPERT: 'EXPERT',
};

export const QuestionCategories: {
  TECHNOLOGY: QuestionCategory,
  INTERVIEW: QuestionCategory,
  SOFT_SKILLS: QuestionCategory,
  OTHER: QuestionCategory,
} = {
  TECHNOLOGY: 'TECHNOLOGY',
  INTERVIEW: 'INTERVIEW',
  SOFT_SKILLS: 'SOFT_SKILLS',
  OTHER: 'OTHER',
};

export function getQuestionTypeLabel(type: QuestionType): string {
  switch (type) {
    case QuestionTypes.MULTIPLE_CHOICE:
      return 'Multiple Choice';
    case QuestionTypes.FILL_IN_THE_BLANK:
      return 'Fill in the Blank';
    case QuestionTypes.TRUE_FALSE:
      return 'True/False';
    case QuestionTypes.SHORT_ANSWER:
      return 'Short Answer';
    case QuestionTypes.INTERVIEW:
      return 'Interview';
    default:
      throw new Error(`Unknown question type: ${type}`);
  }
}

export function getQuestionDifficultyLabel(difficulty: QuestionDifficulty): string {
  switch (difficulty) {
    case QuestionDifficulties.EASY:
      return 'Easy';
    case QuestionDifficulties.MEDIUM:
      return 'Medium';
    case QuestionDifficulties.HARD:
      return 'Hard';
    case QuestionDifficulties.EXPERT:
      return 'Expert';
    default:
      throw new Error(`Unknown question difficulty: ${difficulty}`);
  }
}

export function getQuestionCategoryLabel(category: QuestionCategory): string {
  switch (category) {
    case QuestionCategories.TECHNOLOGY:
      return 'Technology';
    case QuestionCategories.INTERVIEW:
      return 'Interview';
    case QuestionCategories.SOFT_SKILLS:
      return 'Soft Skills';
    case QuestionCategories.OTHER:
      return 'Other';
    default:
      throw new Error(`Unknown question category: ${category}`);
  }
}

export function mapToQuestionType(type: string): QuestionType {
  switch (type) {
    case 'MULTIPLE_CHOICE':
      return QuestionTypes.MULTIPLE_CHOICE;
    case 'FILL_IN_THE_BLANK':
      return QuestionTypes.FILL_IN_THE_BLANK;
    case 'TRUE_FALSE':
      return QuestionTypes.TRUE_FALSE;
    case 'SHORT_ANSWER':
      return QuestionTypes.SHORT_ANSWER;
    case 'INTERVIEW':
      return QuestionTypes.INTERVIEW;
    default:
      throw new Error(`Unknown question type: ${type}`);
  }
}

export function mapToQuestionDifficulty(difficulty: string): QuestionDifficulty {
  switch (difficulty) {
    case 'EASY':
      return QuestionDifficulties.EASY;
    case 'MEDIUM':
      return QuestionDifficulties.MEDIUM;
    case 'HARD':
      return QuestionDifficulties.HARD;  
    case 'EXPERT':
      return QuestionDifficulties.EXPERT;  
    default:
      throw new Error(`Unknown question difficulty: ${difficulty}`);    
  } 
}

