<app-landing-page-frame>

  <h1 class="text-start ms-3 fs-3">
    {{ 'Forgot Password' | stringResource }}
  </h1>

  <div class="container-fluid my-4">
    <app-forgot-password-form></app-forgot-password-form>
  </div>
</app-landing-page-frame>
