<div [ngSwitch]="submissionType">

  <div *ngSwitchCase="'FILE_UPLOAD'" class="my-2">
    <p class="text-muted">
      Upload file(s).
    </p>
    <app-upload-field></app-upload-field>
  </div>

  <div *ngSwitchCase="'TEXT'" class="my-2">
    <p class="text-muted">
      Write a response.
    </p>
    <div *ngIf="!responseSaved; else previewResponse">
      <app-markdown-editor [(ngModel)]="responseText" placeholder="Your response..."></app-markdown-editor>
      <div class="my-2">
        <button type="button" class="btn btn-primary" (click)="responseSaved = true">
          Save <span class="ms-1 bi bi-floppy"></span>
        </button>
      </div>
    </div>
    <ng-template #previewResponse>
      <div class="card card-body">
        <div class="d-flex justify-content-between">
          <span class="text-muted small fw-bold">Your Response</span>
          <button type="button" class="btn btn-sm btn-primary" (click)="responseSaved = false">
            Edit <span class="bi bi-pencil-square"></span>
          </button>
        </div>
        <app-markdown-viewer [data]="responseText" frameTitle="Your Response"></app-markdown-viewer>
      </div>
    </ng-template>
  </div>

  <div *ngSwitchCase="'LINK'" class="my-2">
    <p class="text-muted">
      Submit link(s) to a file or website.
    </p>
    <ul *ngIf="linkSubmissions.length" class="my-3 list-group">
      <li *ngFor="let linkSubmission of linkSubmissions; let index = index" class="list-group-item">
        <div class="d-flex justify-content-between align-items-center">
          <a [href]="linkSubmission" target="_blank">{{ linkSubmission }}</a>
          <button type="button"
                  (click)="linkSubmissions.splice(index, 1)"
                  class="btn btn-outline-danger">
            <span class="visually-hidden">Delete link</span>
            <span class="bi bi-trash"></span>
          </button>
        </div>
      </li>
    </ul>
    <div class="input-group">
      <input type="text"
             class="form-control"
             #linkInput="ngModel"
             name="linkInput"
             [(ngModel)]="linkText"
             required
             (keyup.enter)="addLinkSubmission()"
             [pattern]="urlRegex" placeholder="Link to file or website">
      <button class="btn btn-outline-primary" (click)="addLinkSubmission()" [disabled]="linkInput.invalid" type="button">Submit</button>
    </div>
  </div>

  <div *ngSwitchCase="'COMPLETE_BUTTON'" class="my-2">
    <p class="text-muted">
      Click the button to complete the assignment.
    </p>
    <button type="button" class="btn btn-primary w-100">Complete</button>
  </div>

</div>
