import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { NotificationType, UserNotification } from '@app/api/notifications/models/user-notification.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  private updateNotificationsSubject = new Subject<boolean>();

  constructor(private http: HttpClient, private api: ApiService) { }

  receiveUserNotifications(userId: number) {
    return this.http.get<UserNotification[]>(this.api.endpoint(`notifications/users/${userId}`));
  }

  readUserNotification(notificationId: string) {
    return this.http.get<UserNotification>(this.api.endpoint(`notifications/${notificationId}`));

  }

  deleteUserNotification(notificationId: string) {
    return this.http.delete(this.api.endpoint(`notifications/${notificationId}`));
  }

  updateNotifications() {
    this.updateNotificationsSubject.next(true);
  }

  get notificationsUpdated() {
    return this.updateNotificationsSubject.asObservable();
  }

  getNotificationTypeLabel(notificationType: NotificationType) {
    switch (notificationType) {
      case 'STUDENT_COHORT_ENROLLMENT':
      case 'STUDENT_COURSE_ENROLLMENT':
        return 'Student Enrollment';
      case 'STUDENT_COURSE_COMPLETION':
      case 'TRAINER_STUDENT_COURSE_COMPLETION':
        return 'Completed';
      case 'STUDENT_NEW_ASSIGNMENT':
        return 'New Assignment';
      case 'STUDENT_ASSIGNMENT_DUE':
        return 'Assignment Due';
      case 'STUDENT_ASSIGNMENT_LATE':
      case 'TRAINER_STUDENT_ASSIGNMENT_LATE':
        return 'Assignment Late';
      case 'STUDENT_ASSIGNMENT_GRADE':
        return 'Assignment Grade';
      case 'STUDENT_ASSIGNMENT_FEEDBACK':
      case 'TRAINER_STUDENT_ASSIGNMENT_FEEDBACK':
        return 'Feedback';
      case 'TRAINER_REMINDER':
      case 'GENERAL_REMINDER':
      case 'STUDENT_REMINDER':
        return 'Reminder';
      case 'USER_CHAT_MESSAGE':
        return 'Chat Message';
      default:
        return 'Notification';
    }
  }
}
