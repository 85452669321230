import { Component } from '@angular/core';
import { PasswordResetService } from '@app/api/user/services/password-reset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordResetRequest } from '@app/api/user/models/password-reset-request.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from '@core/validation/custom-validators';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.sass']
})
export class PasswordResetPageComponent {

  passwordResetForm: FormGroup;
  showPassword = false;
  resetComplete = false;
  resetting = false;
  email = '';
  token?: string;
  resetError?: string;

  constructor(private passwordReset: PasswordResetService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router) {

    if (!this.email || !this.token) {
      this.localStorage.setItem('password-reset-email', this.route.snapshot.queryParams['email']);
      this.localStorage.setItem('password-reset-token', this.route.snapshot.queryParams['token']);
      this.router.navigate([], { queryParams: { email: null, token: null }, queryParamsHandling: 'merge' });
    }

    this.passwordResetForm = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(128),
        CustomValidators.password
      ]],
      confirmPassword: ['', [
        Validators.required
      ]],
    });

    const passwordControl = this.passwordResetForm.get('password');
    const confirmPasswordControl = this.passwordResetForm.get('confirmPassword');

    confirmPasswordControl?.addValidators(CustomValidators.matches(passwordControl));
  }

  resetPassword() {

    if (this.localStorage.getItem('password-reset-email')) {
      this.email = this.localStorage.getItem('password-reset-email') || '';
      this.localStorage.removeItem('password-reset-email');
    }

    if (this.localStorage.getItem('password-reset-token')) {
      this.token = this.localStorage.getItem('password-reset-token') || '';
      this.localStorage.removeItem('password-reset-token');
    }

    if (this.email && this.token) {
      this.resetting = true;
      const request: PasswordResetRequest = {
        email: this.email,
        password: this.passwordResetForm.get('password')?.value,
        confirmPassword: this.passwordResetForm.get('confirmPassword')?.value,
        token: this.token
      };

      this.passwordReset.resetPassword(request).subscribe({
        next: (response) => {
          if (response.success) {
            this.resetComplete = true;
            this.resetting = false;
          }
        },
        error: (error) => {
          this.resetting = false;
          this.resetError = error.error.message;
        },
        complete: () => {
          this.resetting = false;
        }
      });
    } else {
      this.resetError = 'Invalid reset link';
    }
  }

}
