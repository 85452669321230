<app-landing-page-frame>
  <div class="container-fluid">
    <h1 class="text-start ms-3 fs-3">
      {{ 'Reset your password' | stringResource }}
    </h1>

    <form *ngIf="!resetComplete; else resetCompleteView" class="container-fluid mt-4" [formGroup]="passwordResetForm">
      <p class="my-3">Enter your new password.</p>
      <div class="my-3">
        <app-text-field controlName="password"
                        [type]="showPassword ? 'text' : 'password'"
                        [customErrorMessages]="{ pattern: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.' }"
                        label="Password"></app-text-field>
      </div>

      <div class="my-3">
        <app-text-field controlName="confirmPassword"
                        [type]="showPassword ? 'text' : 'password'"
                        [customErrorMessages]="{
                      matches: 'Passwords do not match'
                    }"
                        label="Confirm Password"></app-text-field>
      </div>
      <div class="my-2 d-flex flex-row justify-content-end align-items-center">
        <div class="mx-4 form-check form-switch">
          <input class="form-check-input"
                 type="checkbox"
                 id="showPassword"
                 (change)="showPassword = !showPassword">
          <label class="form-check-label"
                 for="showPassword">Show Password</label>
        </div>
      </div>
      <div *ngIf="resetError" class="my-2 text-danger">
        {{resetError}}
      </div>
      <div class="my-3">
        <button type="submit"
                class="btn btn-primary w-100"
                [disabled]="passwordResetForm.invalid"
                (click)="resetPassword()">
          Reset Password <span *ngIf="resetting" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </form>
  </div>
</app-landing-page-frame>

<ng-template #resetCompleteView>

  <div class="my-3">
    <p class="my-3">Your password has been reset. You may now login.</p>
    <button type="button"
            class="btn btn-primary w-100"
            [routerLink]="['/login']">
      Login
    </button>
  </div>

</ng-template>
