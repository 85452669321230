import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { ModuleApiService } from '../../services/module-api.service';
import { ModuleData } from '@app/api/models/learning-material-data.model';

@Component({
  selector: 'app-view-edit-modules',
  templateUrl: './view-edit-modules.component.html',
  styleUrls: ['./view-edit-modules.component.sass']
})
export class ViewEditModulesComponent implements OnInit {

  @Input() showTitle = true;
  @Input() currentModuleId?: number;
  modules: ModuleData [] = [];
  filteredModules: ModuleData[] = [];
  loading = false;
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  searchQuery = '';
  selectedTechnology = ''; 
  technologies: string[] = []; 
  showFilterModal = false; 
  hasFilter = false; 

  constructor(private moduleApi: ModuleApiService,
    private router: Router,
    private modal: GlobalModalService){
  }

  ngOnInit() {
    this.loadModules();
  }

  loadModules() {
    this.loading = true;
    this.moduleApi.getModulesByOwner('self', {
      size: this.pageSize,
      page: this.currentPage - 1,
    }).subscribe(page => {
      this.totalItems = page.totalElements;
      this.modules = page.content.filter(m => m.id !== this.currentModuleId);
      this.filteredModules = [...this.modules]; 
      this.extractTechnologies(); 
      this.loading = false;
      this.filterModules();
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditModulePage(module: ModuleData) {
    this.router.navigate(['course-composer', 'modules', 'edit', module.id]);
  }

  deleteModule(id: number) {
    this.modal.confirm('Are you sure you want to delete this Module?', {
      title: 'Delete Module',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.moduleApi.deleteModule(id)
            .subscribe({
              next: () => {
                this.modules = this.modules.filter(module => module.id !== id);
                this.filterModules();
              },
              error: (err) => {
                if (err.status === 400) {
                  this.modal.alert({
                    type: 'danger',
                    title: 'Unable to Delete Lesson',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }
              }
            });
        }
      }
    });
  }

  extractTechnologies() {
    this.technologies = Array.from(new Set(this.modules.flatMap(module =>
      module.lessons.flatMap(lesson => lesson.technologies || [])
    )));
  }

  openFilterModal() {
    this.showFilterModal = true;
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal; 
  }

  resetFilter() {
    this.selectedTechnology = ''; 
    this.searchQuery = ''; 
    this.filteredModules = [...this.modules]; 
    this.hasFilter = false; 
  }

  filterModules() {
    this.hasFilter = this.selectedTechnology !== '' || this.searchQuery !== ''; 
    const query = this.searchQuery.toLowerCase();
    this.filteredModules = this.modules.filter(module => {
      const technologyMatch = this.selectedTechnology === '' || module.lessons.some(lesson =>
        lesson.technologies?.includes(this.selectedTechnology)
      );
      const searchMatch = !query || module.title.toLowerCase().includes(query);
      return technologyMatch && searchMatch;
    });
  }
  
  applyFilter() {
    this.filterModules(); 
    this.toggleFilterModal(); 
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage; 
    this.loadModules(); 
  }

}
