import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';

@Component({
  selector: 'app-content-catalog-page',
  templateUrl: './content-catalog-page.component.html',
  styleUrls: ['./content-catalog-page.component.sass']
})
export class ContentCatalogPageComponent {

  contentTypes: ActivityMaterialType[] = [];

  constructor(private route: ActivatedRoute) {
    const { data } = this.route.snapshot;
    this.contentTypes = data['contentTypes'] || [];
  }

}
