<div *ngIf="rubric.criteria && rubric.criteria.length">

  <h3 class="mb-2">Rubric</h3>

  <p class="text-muted">Meet expectations for this assignment.</p>

  <div class="my-2 table-responsive">
    <table class="table table-bordered">
      <thead class="table-light">
      <tr>
        <th><span class="visually-hidden">Criteria</span></th>
        <th>Excellent <span class="text-muted small">(5 points)</span></th>
        <th>Great <span class="text-muted small">(4 points)</span></th>
        <th>Good <span class="text-muted small">(3 points)</span></th>
        <th>Fair <span class="text-muted small">(2 points)</span></th>
        <th>Poor <span class="text-muted small">(1 points)</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let criterion of rubric.criteria">
        <td>
          <div class="fw-bold mb-1">{{criterion.title}}</div>
          <div class="text-muted">{{criterion.description}}</div>
        </td>
        <td *ngFor="let rating of criterion.ratings">
          {{rating.description}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
