import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deEnumerate'
})
export class DeEnumeratePipe implements PipeTransform {

  transform(value: string): string {
    let deEnum = value.replace(/_/g, ' ');
    deEnum = deEnum.toLowerCase();
    deEnum = deEnum.replace(/\b\w/g, (char) => char.toUpperCase());
    deEnum = deEnum.replace(/^\s/, '');
    deEnum = deEnum.replace(/ In /g, ' in ');
    deEnum = deEnum.replace(/ The /g, ' the ');
    deEnum = deEnum.replace(/ Of /g, ' of ');
    return deEnum;
  }

}
