import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageActivitiesUpdateGradeService {

  gradeUpdated: EventEmitter<{ id: number; grade: number; }> = new EventEmitter();

  constructor() { }
}
