import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VideoUploadForm } from '@app/api/video/components/video-upload-form/video-upload-form.interface';
import { VideoUploadFormService } from '@app/api/video/services/video-upload-form.service';

@Component({
  selector: 'app-video-upload-form',
  templateUrl: './video-upload-form.component.html'
})
export class VideoUploadFormComponent implements OnInit{


  constructor(private videoUpload: VideoUploadFormService) {}


  get videoUploadForm(): FormGroup<VideoUploadForm> {
    return this.videoUpload.videoUploadForm;
  }

  ngOnInit(): void {
    this.videoUpload.videoUploadForm.reset();
  }


}
