<div tabindex="0" (click)="focusInputEl($event)" (keydown.enter)="focusInputEl($event)" class="form-control d-flex flex-wrap align-items-center" [class.div-focus]="focused">
  <div *ngFor="let tag of tags; let index=index" class="bg-light text-black m-1 p-2 rounded">
    {{tag.label}}
    <button class="btn btn-light btn-sm m-0" (click)="removeTag(index)">
      <span class="bi bi-x m-auto"></span>
    </button>
  </div>
  <div class="m-1" ngbDropdown [(open)]="showDropdown">
    <input type="text"
           #tagInputEl
           [placeholder]="placeholder"
           [formControl]="tagInput"
           (focus)="setFocused(true)"
           (blur)="onBlur()"
           (keyup.enter)="addTag()"
           (keydown.backspace)="backspaceTag()"
           ngbDropdownAnchor
           class="form-control-plaintext">
    <div ngbDropdownMenu class="z-1">
      <button ngbDropdownItem
              [tabIndex]="index"
              (click)="addTag(tagResult.tagData); showDropdown = false"
              *ngFor="let tagResult of tagResults; let index = index">
        <markdown [data]="tagResult.viewLabel">
        </markdown>
      </button>
    </div>
  </div>
</div>
