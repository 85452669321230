import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionChoiceData, QuestionData } from '@app/api/question/models/question-data.model';
import { QuestionType, QuestionTypes } from '@app/api/question/models/question-data-types';
import { CreateQuestionRequest } from '@app/api/question/models/create-question-request.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionFormService {

  formGroup: FormGroup;
  saving = false;
  editMode = false;
  currentType: QuestionType | null = null;

  constructor() {
    this.formGroup = new FormGroup<any>({
      questionType: new FormControl(null, [
        Validators.required
      ]),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(1),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(250),
        Validators.minLength(1),
      ]),
      text: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(1),
      ]),
      difficulty: new FormControl(null, [
        Validators.required
      ]),
      tags: new FormControl([], [
        Validators.required
      ]),
      categories: new FormControl([], [
        Validators.required
      ]),
      technologies: new FormControl([]),
      published: new FormControl(false),
      choices: new FormArray([]),
      selectAllThatApply: new FormControl(false),
      mustBeExact: new FormControl(false),
      correctAnswerIsTrue: new FormControl(false),
      explanation: new FormControl(null),
      smoothstackClientId: new FormControl(null),
      interviewDate: new FormControl(null),
      liveCoding: new FormControl(false),
      interviewer: new FormControl(null),
      selfReported: new FormControl(false),
      reporterId: new FormControl(null),
      correctAnswer: new FormControl(null),
    });

    // Add 4 default choices
    for (let i = 0; i < 4; i++) {
      this.addChoice();
    }

    // Add validators to the form based on the question type
    this.formGroup.get('questionType')?.valueChanges.subscribe((questionType: string) => {

      this.currentType = questionType as QuestionType;

      if (!questionType) {
        return;
      }

      const type = questionType as QuestionType;
      const choices = this.formGroup.get('choices') as FormArray;
      const correctAnswer = this.formGroup.get('correctAnswer');
      const correctAnswerIsTrue = this.formGroup.get('correctAnswerIsTrue');

      // Remove validators from the form
      choices.clearValidators();
      correctAnswer?.clearValidators();

      // Choices are required for multiple choice questions
      if (type === QuestionTypes.MULTIPLE_CHOICE) {
        choices.controls.forEach((choice) => {
          const group = choice as FormGroup;
          group.get('choiceText')?.setValidators([
            Validators.required,
            Validators.maxLength(255)
          ]);
          group.get('correct')?.setValidators([
            Validators.required
          ]);
          group.get('explanation')?.setValidators([
            Validators.maxLength(255),
          ]);
        });
      } else {
        choices.controls.forEach((choice) => {
          const group = choice as FormGroup;
          group.get('choiceText')?.setValidators([
            Validators.maxLength(255),
          ]);
          group.get('correct')?.setValidators([]);
          group.get('explanation')?.setValidators([]);
        });
      }

      // Correct answer is required for short answer or interview questions
      if (type === QuestionTypes.SHORT_ANSWER || type === QuestionTypes.INTERVIEW) {
        correctAnswer?.setValidators([
          Validators.required
        ]);
      } else {
        correctAnswer?.setValidators([]);
      }

      // Correct answer is True is required for true false questions
      if (type === QuestionTypes.TRUE_FALSE) {
        correctAnswerIsTrue?.setValidators([
          Validators.required,
        ]);
      } else {
        correctAnswerIsTrue?.setValidators([]);
      }

      // Update the form
      choices.updateValueAndValidity();
      // Update choice individual validators
      choices.controls.forEach((choice) => {
        const group = choice as FormGroup;
        group.get('choiceText')?.updateValueAndValidity();
        group.get('correct')?.updateValueAndValidity();
      });
      correctAnswer?.updateValueAndValidity();
    });

    // Add validators to the form based on the categories selected
    this.formGroup.get('categories')?.valueChanges.subscribe((categories: string[]) => {
      const technologies = this.formGroup.get('technologies');
      if (categories && categories.includes('TECHNOLOGY')) {
        technologies?.setValidators([
          Validators.required
        ]);
      } else {
        technologies?.setValidators([]);
      }

      // Update the form
      technologies?.updateValueAndValidity();
    });
  }

  setCreateQuestionRequest(request: CreateQuestionRequest | undefined) {
    if (!request) {
      return;
    }
    // Clear the form
    this.formGroup.reset();
    this.formGroup.patchValue({ ...request });

    if (request.questionType === 'MULTIPLE_CHOICE') {
      const choices = this.formGroup.get('choices') as FormArray;
      const choiceRequests = request.choices || [];
      // Check if the choices and choice requests are the same length
      if (choices.length !== choiceRequests.length) {
        choices.clear();
        // Add the choices from the request
        choiceRequests.forEach((choiceRequest) => {
          this.addChoice(choiceRequest);
        });
      }
    }
  }

  setUpdateMode(questionData: QuestionData) {
    this.currentType = questionData.type;

    this.editMode = true;

    // Disable changing the question type
    this.formGroup.get('questionType')?.disable();

    // Set the form values
    this.formGroup.patchValue({
      ...questionData,
      questionType: questionData.type
    });

    const questionType = questionData.type as QuestionType;

    if (questionType === QuestionTypes.SHORT_ANSWER || questionType === QuestionTypes.INTERVIEW) {
      this.formGroup.patchValue({
        correctAnswer: questionData.correctAnswer?.correctAnswer,
        explanation: questionData.correctAnswer?.explanation,
      });
    } else if (questionType === QuestionTypes.TRUE_FALSE) {
      this.formGroup.patchValue({
        correctAnswerIsTrue: questionData.trueFalseCorrect,
        explanation: questionData.trueFalseExplanation
      });
    } else if (questionType === QuestionTypes.FILL_IN_THE_BLANK) {
      this.formGroup.patchValue({
        text: questionData.fillInTheBlankText
      });
    }

    // For each choice, if the question type is multiple choice, and the choice has an explanation, add the explanation field to the form
    if (questionType === QuestionTypes.MULTIPLE_CHOICE) {

      // Remove all choices from the form
      const choices = this.formGroup.get('choices') as FormArray;
      const choiceData = questionData.choices || [];

      if (choices.length !== choiceData.length) {
        choices.clear();
        // Add the count of choices from the question data
        for (let i = 0; i < (questionData.choices?.length || 0); i++) {
          this.addChoice(questionData.choices?.[i]);
        }

        questionData.choices?.forEach((choice, index) => {
          if (choice.explanation) {
            this.addExplanationToChoice(index, choice.explanation);
          }
        });
      }
    }
  }

  setCreateMode() {
    this.editMode = false;
    this.formGroup.get('questionType')?.enable();
    this.resetForm();
  }

  get isUpdateMode() {
    return this.editMode;
  }

  canDeactivate(): boolean {
    if (this.saving) {
      this.saving = false;
      return true;
    }
    if (this.formGroup.dirty) {
      return confirm('You have unsaved changes. Are you sure you want to leave this page?');
    }
    return true;
  }

  addChoice(choice?: QuestionChoiceData) {
    const choices = this.formGroup.get('choices') as FormArray;
    choices.push(new FormGroup({
      id: new FormControl(choice?.id || null),
      choiceText: new FormControl((choice?.choiceText || ''), [
        Validators.maxLength(255)
      ]),
      correct: new FormControl(choice?.correct || false, [
        Validators.required
      ])
    }));
  }

  removeChoice(index: number) {
    const choices = this.formGroup.get('choices') as FormArray;
    choices.removeAt(index);
  }

  addExplanationToChoice(index: number, value?: string) {
    const choices = this.formGroup.get('choices') as FormArray;
    const choice = choices.at(index) as FormGroup;
    choice.addControl('explanation', new FormControl(value));
  }

  removeExplanationFromChoice(index: number) {
    const choices = this.formGroup.get('choices') as FormArray;
    const choice = choices.at(index) as FormGroup;
    choice.removeControl('explanation');
  }

  resetForm() {
    this.formGroup.reset();
  }

  mapRequestToQuestionData(request: CreateQuestionRequest): QuestionData {
    const selectAllThatApply = (
      (request.choices || []).filter(choice => choice.correct).length > 1
    ) || false;
    return {
      correctAnswer: {
        correctAnswer: request.correctAnswer || '',
        explanation: request.explanation || '',
      },
      deprecated: false,
      deprecationReason: '',
      description: request.description || '',
      difficulty: request.difficulty,
      fillInTheBlankText: request.text,
      interviewDate: request.interviewDate,
      liveCoding: request.liveCoding,
      mustBeExact: request.mustBeExact,
      published: request.published,
      reporterEmail: '',
      selectAllThatApply: selectAllThatApply,
      smoothstackClientId: request.smoothstackClientId,
      tags: [
        ...request.tags || [],
      ],
      technologies: [
        ...request.technologies || [],
      ],
      text: request.text,
      title: request.title,
      trueFalseCorrect: request.correctAnswerIsTrue,
      trueFalseExplanation: request.explanation,
      type: this.currentType!,
      categories: request.categories || [],
      choices: request.choices?.map(choiceRequest => {
        return {
          choiceText: choiceRequest.choiceText,
          correct: choiceRequest.correct,
          explanation: choiceRequest.explanation,
        };
      }) || []
    };

  }

  setQuestionType(type: QuestionType) {
    if (!this.isUpdateMode) {
      this.formGroup.get('questionType')?.setValue(type);
    }
  }
}
