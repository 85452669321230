import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { catchError, OperatorFunction, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private environment: EnvironmentService, private globalModal: GlobalModalService) { }

  get api(): string {
    return this.environment.getEnv('apiUrl').replace(/\/+$/, ''); // Remove trailing slash
  }

  endpoint(path: string | number | Array<string | number>): string {
    // Convert everything to an array to normalize processing
    const pathArray = (Array.isArray(path) ? path : [path])
      .map((pathItem) => String(pathItem)); // Convert all items to strings

    // Reduce the array to a single path string
    return pathArray.reduce((accumulatedPath, currentPath) => {
      // Convert to string and remove leading/trailing slashes
      const normalizedPath = String(currentPath).replace(/^\/+|\/+$/g, '');
      // Append with proper slashes
      return `${accumulatedPath}${accumulatedPath.endsWith('/') ? '' : '/'}${normalizedPath}`;
    }, this.api);
  }

  catchHttpError<T>(statusCodesAndMessage?: {
    [code: number]: string
  }): OperatorFunction<T, T> {
    return catchError((error, caught) => {
      if (error instanceof HttpErrorResponse) {
        const message = statusCodesAndMessage?.[error.status];
        this.globalModal.alert({
          type: 'danger',
          title: 'Error',
          content: message || error.error.message
        });
        return throwError(() => new Error(message || error.error.message));
      }
      return throwError(() => new Error(error.message));
    });
  }

}
