import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuestionChoiceData } from '@app/api/question/models/question-data.model';

@Component({
  selector: 'app-question-choice-group',
  templateUrl: './question-choice-group.component.html',
  styleUrls: ['./question-choice-group.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuestionChoiceGroupComponent,
      multi: true,
    }
  ]
})
export class QuestionChoiceGroupComponent implements ControlValueAccessor {
  @Input() choices: QuestionChoiceData[] = [];
  selectedIndexes: number[] = [];
  isDisabled = false;

  get selectAllThatApply(): boolean {
    // Multiple correct answers
    return this.choices.filter(choice => choice.correct).length > 1;
  }

  // Function to call when the value changes.
  private onChange: (value: number[]) => void = () => {};

  // Function to call when the input is touched.
  private onTouched: () => void = () => {};

  writeValue(value: number[]): void {
    if (value) {
      this.selectedIndexes = value;
    } else {
      this.selectedIndexes = [];
    }
  }

  registerOnChange(fn: (value: number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  // Single choice selection
  onChoiceChange(event: any) {
    const index = parseInt(event.target.value, 10);
    if (event.target.checked) {
      this.selectedIndexes = [index];
    }
    this.onChange(this.selectedIndexes);
  }

  // Multiple choice selection
  onChoicesChange(event: any) {
    const index = parseInt(event.target.value, 10);
    if (event.target.checked) {
      this.selectedIndexes.push(index);
    } else {
      const indexToRemove = this.selectedIndexes.indexOf(index);
      if (indexToRemove > -1) {
        this.selectedIndexes.splice(indexToRemove, 1);
      }
    }
    this.onChange(this.selectedIndexes);
  }

}
