import { Component } from '@angular/core';

@Component({
  selector: 'app-open-assignment-page',
  templateUrl: './open-assignment-page.component.html',
  styleUrls: ['./open-assignment-page.component.sass']
})
export class OpenAssignmentPageComponent {

}
