import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailVerificationService } from '@app/api/user/services/email-verification.service';
import { UserService } from '@app/api/user/services/user.service';

@Component({
  selector: 'app-verify-email-modal',
  templateUrl: './verify-email-modal.component.html',
  styleUrls: ['./verify-email-modal.component.sass']
})
export class VerifyEmailModalComponent {

  sending = false;
  sent = false;
  error = false;

  constructor(public activeModal: NgbActiveModal,
    private emailVerificationService: EmailVerificationService,
    private userService: UserService) {
  }

  sendVerificationEmail(): void {
    if (this.userService.currentUser) {
      this.sending = true;
      this.emailVerificationService.sendVerificationEmail(this.userService.currentUser.email).subscribe({
        next: () => {
          this.sent = true;
          this.sending = false;
        },
        error: () => {
          this.error = true;
          this.sending = false;
        },
        complete: () => {
          this.sending = false;
        }
      });
    }
  }

}
