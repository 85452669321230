import { Component, OnInit } from '@angular/core';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';
import { NotificationType, UserNotification } from '@app/api/notifications/models/user-notification.model';
import { UserService } from '@app/api/user/services/user.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.sass']
})
export class NotificationsPageComponent implements OnInit {

  loading = false;
  notifications: UserNotification[] = [];
  selectedNotification: UserNotification | null = null; 

  constructor(private userNotificationService: UserNotificationService, private userService: UserService, private router: Router) {
    this.userNotificationService.notificationsUpdated.subscribe(() => {
      this.loadNotifications();
    });
  }

  ngOnInit() {
    this.loadNotifications();
  }

  loadNotifications() {
    this.loading = true;
    this.userService.getSelf().subscribe(currentUser => {
      this.userNotificationService.receiveUserNotifications(currentUser.id)
        .subscribe(notifications => {
          this.notifications = notifications;
          this.loading = false;
        });
    });
  }

  getNotificationTypeLabel(notificationType: NotificationType) {
    return this.userNotificationService.getNotificationTypeLabel(notificationType);
  }

  clearAllNotifications() {
    forkJoin(this.notifications.map(n => this.userNotificationService.deleteUserNotification(n.id))).subscribe(() => {
      this.notifications = [];
      this.userNotificationService.updateNotifications();
      this.router.navigate(['/notifications']);
    });
  }

  markAllAsRead() {
    forkJoin(this.notifications.filter(n => !n.readAt).map(n => this.userNotificationService.readUserNotification(n.id))).subscribe(() => {
      this.userNotificationService.updateNotifications();
    });
  }

  get unreadCount() {
    return this.notifications.filter(n => !n.readAt).length;
  }

  selectNotification(notification: UserNotification) {
    this.selectedNotification = notification;
  }

}
