import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { LessonData } from '@app/api/models/learning-material-data.model';
import { ApiService } from '@core/services/api.service';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';

@Component({
  selector: 'app-lesson-view',
  templateUrl: './lesson-view.component.html',
  styleUrls: ['./lesson-view.component.sass'],
})
export class LessonViewComponent implements OnInit, OnChanges {
  @Input({ required: true }) learningActivity!: LearningActivity;
  @Input({ required: true }) lessonId!: number;
  @Input() enableSmoothScroll = false;
  lessonData?: LessonData;
  loading = false;
  completing = false;
  completed = false;
  @Input() showSpinner = true;

  constructor(
    private lessonApi: LessonApiService,
    private learningActivityService: LearningActivityService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.loadLesson();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lessonId']) {
      this.loadLesson();
    }
  }

  get presentationFileUrl() {
    if (!this.lessonData) {
      return '';
    }

    const presentationAttachment = this.lessonData.lessonAttachments.find(
      (attachment) => attachment.attachmentType === 'PRESENTATION',
    );

    if (!presentationAttachment) {
      return '';
    }

    const fileRecordId = presentationAttachment.fileRecordId;
    return fileRecordId
      ? this.apiService.endpoint(['file-records', fileRecordId, 'public-view'])
      : '';
  }

  get isRavenLesson() {
    return this.lessonData?.source?.toLowerCase()?.includes('raven360');
  }

  get hasAssignments() {
    if (!this.lessonData) {
      return false;
    }

    if (!this.lessonData.assignments) {
      return false;
    }

    return this.lessonData.assignments.length > 0;
  }

  loadLesson() {
    this.loading = true;
    this.lessonApi.getLesson(this.lessonId).subscribe({
      next: (lessonData) => {
        this.lessonData = lessonData;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  completeLesson() {
    this.completing = true;
    this.learningActivityService
      .completeLearningActivity(this.learningActivity.id)
      .subscribe({
        next: (activity) => {
          this.learningActivity = activity;
          this.completing = false;
          this.completed = true;
        },
      });
  }
}
