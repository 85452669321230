
<div class="my-2 d-flex justify-content-center flex-column">
  <div class="w-100 d-flex justify-content-start my-2 gap-2 flex-row-reverse align-items-center">
    <div class="col-12 col-md-3">
      <app-search-bar-input (loadResults)="getAllUsers()" [(search)]="searchQuery"></app-search-bar-input>
    </div>
    <div ngbDropdown>
      <button type="button"
              ngbDropdownToggle
              ngbTooltip="Sort"
              class="btn btn-light">
        <span class="bi bi-sort-alpha-down"></span>
        Sort by: <span class="mx-1">{{mapPropertyToLabel(pagination.sortBy)}}</span>
      </button>
      <div ngbDropdownMenu style="z-index: 1001;">
        <button (click)="pagination.sortBy = 'createdAt'; pagination.direction = 'desc'; getAllUsers()" ngbDropdownItem>
          Newest First
        </button>
        <button (click)="pagination.sortBy = 'createdAt'; pagination.direction = 'asc'; getAllUsers()" ngbDropdownItem>
          Oldest First
        </button>
        <button (click)="pagination.sortBy = 'id'; getAllUsers()" ngbDropdownItem>
          ID
        </button>
        <button (click)="pagination.sortBy = 'email'; getAllUsers()" ngbDropdownItem>
          Email
        </button>
        <button (click)="pagination.sortBy = 'firstName'; getAllUsers()" ngbDropdownItem>
          First Name
        </button>
        <button (click)="pagination.sortBy = 'lastName'; getAllUsers()" ngbDropdownItem>
          Last Name
        </button>
      </div>
    </div>
    <div class="mx-1">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio"
                 [(ngModel)]="pagination.direction"
                 name="sortDirection"
                 value="asc"
                 (change)="getAllUsers()"
                 class="form-check-input"/>
          Ascending
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio"
                 [(ngModel)]="pagination.direction"
                 name="sortDirection"
                 value="desc"
                 (change)="getAllUsers()"
                 class="form-check-input"/>
          Descending
        </label>
      </div>
    </div>
  </div>

  <table class="table table-hover">
    <thead>
      <tr class="table-light">
        <th>ID</th>
        <th>Email</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Roles</th>
        <th><span class="sr-only">Action</span></th>
      </tr>
    </thead>
    <tbody>
      <tr class="align-middle" [routerLink]="[ user.id ]" *ngFor="let user of users">
        <td [ngClass]="userCellClass(user)">
          {{user.id}}
        </td>
        <td [ngClass]="userCellClass(user)">
          {{user.email}} <span *ngIf="user.emailVerified"
                               class="bi bi-check-circle-fill text-success d-inline-block h-100"
                               ngbTooltip="Email verified"
                               placement="right"></span>
        </td>
        <td [ngClass]="userCellClass(user)">
          {{user.firstName}}
        </td>
        <td [ngClass]="userCellClass(user)">
          {{user.lastName}}
        </td>
        <td [ngClass]="userCellClass(user)">
          <div class="d-flex flex-wrap gap-1">
            <div class="text-info bg-info text-white my-1 px-2 py-1 badge small"
                 *ngFor="let role of user.roles">
              {{role}}
            </div>
          </div>
        </td>
        <td click-stop-propagation ngbDropdown [ngClass]="userCellClass(user)">
          <button type="button"
                  ngbDropdownToggle
                  class="btn btn-link dropdown-no-caret">
            <span class="bi bi-three-dots-vertical"></span>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="[ user.id, 'edit' ]">
              <span class="bi bi-pencil-square"></span>
              Edit
            </button>
            <app-delete-user-button  [user]="user" (userDeleted)="onUserDeleted()">
              <ng-template let-openDeleteUserModal="openDeleteUserModal">
                <button ngbDropdownItem class="btn btn-danger"(click)="openDeleteUserModal()">
                  <span class="bi bi-trash-fill"></span>
                  Delete User
                </button>
              </ng-template>
            </app-delete-user-button>
            <button *ngIf="canImpersonate(user)" ngbDropdownItem (click)="impersonateUser(user.email)">
              <span class="bi bi-person"></span>
              Impersonate
            </button>
          </div>
        </td>
      </tr>
      <!--Empty rows-->
      <tr class="align-middle" *ngFor="let _ of emptyRows()">
        <td colspan="6" class="bg-light-subtle">
          <span class="btn bg-transparent cursor-default">
            &nbsp;
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mx-auto">
    <ngb-pagination
      [collectionSize]="pagination.total"
      [(page)]="pagination.page"
      [pageSize]="pagination.size"
      [maxSize]="10" [boundaryLinks]="true"
      (pageChange)="getAllUsers()"></ngb-pagination>
  </div>

</div>
