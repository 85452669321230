<ng-container *ngIf="control">
  <div ngbDropdown [autoClose]="!multiple" [(open)]="isOpen">
    <div class="form-floating">
      <input type="text"
             class="form-control"
             readonly
             [id]="controlName"
             (focus)="controlFocus.emit()"
             ngbDropdownToggle
             [placeholder]="label"
             [formControl]="control">
      <label [for]="controlName">{{label}}</label>
    </div>
    <div ngbDropdownMenu class="w-100">
      <ng-container *ngIf="!multiple; else multipleOption">
        <button *ngFor="let option of options" ngbDropdownItem (click)="selectOption(option.value)">{{option.label}}</button>
      </ng-container>
      <ng-template #multipleOption>
        <div ngbDropdownItem *ngFor="let option of options; let index = index" (click)="$event.stopPropagation()">
          <div class="form-check w-100 h-100">
            <input type="checkbox"
                   id="option-{{index}}"
                   [checked]="option.checked"
                   (focus)="controlFocus.emit()"
                   (change)="updateValueFromChecked()"
                   class="form-check-input">
            <label class="form-check-label w-100" for="option-{{index}}" (click)="$event.stopPropagation()">{{option.label}}</label>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

</ng-container>
