import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Trainer } from '@app/api/models/trainer.model';
import { TrainerApiService } from '@app/api/training/services/trainer-api.service';
import { PasswordResetService } from '@app/api/user/services/password-reset.service';
import { UserService } from '@app/api/user/services/user.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-trainer-view-page',
  templateUrl: './trainer-view-page.component.html',
  styleUrls: ['./trainer-view-page.component.sass'],
})
export class TrainerViewPageComponent implements OnInit {
  @ViewChild('updateTechnologiesContent')
  updateTechnologiesContent!: TemplateRef<any>;

  trainer: Trainer = {
    id: undefined,
    email: '',
    firstName: '',
    lastName: '',
    technologies: [],
    existingUser: false,
    invite: false,
  };
  sendingInviteEmail: boolean = false;
  sendingPasswordResetEmail: boolean = false;
  profilePictureUrl: string = '';
  updateTechnologiesForm: FormGroup<any>;
  constructor(
    private trainerApiService: TrainerApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    private passwordResetService: PasswordResetService,
    private localStorage: LocalStorageService,
    private modalService: GlobalModalService
  ) {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.trainerApiService.getTrainer(id).subscribe({
        next: (trainer) => {
          this.trainer = trainer;
        },
      });
    });

    this.updateTechnologiesForm = new FormGroup({
      technologies: new FormControl([]),
    });
  }
  ngOnInit(): void {
    this.userService.fetchProfilePictureByEmail(this.trainer.email).subscribe({
      next: (url) => {
        const profile = URL.createObjectURL(url);
        this.localStorage.setItem('profilePictureUrl', profile);
        this.profilePictureUrl = profile;
      },
      error: (error) => {
        of('assets/images/default-avatar.png').subscribe({
          next: (url) => {
            this.profilePictureUrl = url;
          },
        });
      },
    });
  }

  updateTechnologies() {
    this.updateTechnologiesForm
      .get('technologies')
      ?.setValue(this.trainer.technologies);
    this.modalService
      .alert({
        title: 'Update Technologies',
        content: this.updateTechnologiesContent,
        type: 'info',
        showCancelButton: true,
        okButtonText: 'Update',
      })
      .closed.subscribe({
        next: () => {
          this.trainer.technologies =
            this.updateTechnologiesForm.get('technologies')?.value;
          this.trainerApiService
            .updateTrainer(this.trainer, this.trainer.id)
            .subscribe({
              next: () => {
                this.modalService.alert({
                  title: 'Technologies updated',
                  content: 'Technologies updated for ' + this.trainer.email,
                  type: 'success',
                  okButtonText: 'OK',
                });
              },
            });
        },
      });
  }

  sendPasswordResetEmail() {
    this.sendingPasswordResetEmail = true;
    this.passwordResetService
      .initiatePasswordReset(this.trainer.email)
      .subscribe({
        next: () => {
          this.modalService.alert({
            title: 'Email sent',
            content: `Password reset email sent to ${this.trainer.email}`,
            type: 'success',
          });
        },
        complete: () => {
          this.sendingPasswordResetEmail = false;
        },
      });
  }
}
