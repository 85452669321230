import { SideBarItem } from '@core/components/side-bar/side-bar-item.interface';
import { UserRole } from '@app/api/user/models/user.model';

export const sideBarItems: SideBarItem[] = [
  {
    label: 'Dashboard',
    icon: 'bi-speedometer',
    routerLink: '/dashboard'
  },
  {
    label: 'User Management',
    icon: 'bi-people',
    routerLink: '/user-management',
    roles: [UserRole.Admin, UserRole.Super_Admin]
  },
  {
    label: 'My Learning',
    icon: 'bi-journal-bookmark-fill',
    routerLink: '/my-learning',
    roles: [UserRole.Student]
  },
  {
    label: 'Manage Activities',
    icon: 'bi-journal-check',
    routerLink: '/learning/manage-activities',
    roles: [UserRole.Trainer, UserRole.Admin, UserRole.Super_Admin]
  },
  {
    label: 'Training',
    icon: 'bi-rocket-takeoff',
    routerLink: '/training',
    roles: [UserRole.Trainer, UserRole.Admin, UserRole.Super_Admin]
  },
  {
    label: 'Course Composer',
    icon: 'bi-magic',
    routerLink: '/course-composer',
    roles: [UserRole.Trainer, UserRole.Admin, UserRole.Super_Admin]
  },
  {
    label: 'Content Library',
    icon: 'bi-book-half',
    routerLink: '/content-library',
    roles: [UserRole.Trainer, UserRole.Admin, UserRole.Super_Admin]
  },
  {
    label: 'Videos',
    icon: 'bi-play-circle',
    routerLink: '/videos',
  },
  {
    label: 'File Manager',
    icon: 'bi-folder2',
    routerLink: '/file-manager',
    roles: [UserRole.Trainer, UserRole.Admin, UserRole.Super_Admin]
  }
];
