import { Component, EventEmitter, Output } from '@angular/core';
import { MicrosoftUserSearchResult } from '@app/api/user/models/microsoft-user-search-result.model';
import { MicrosoftUserService } from '@app/api/user/services/microsoft-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-microsoft-user-form',
  templateUrl: './search-microsoft-user-form.component.html',
  styleUrls: ['./search-microsoft-user-form.component.sass']
})
export class SearchMicrosoftUserFormComponent {

  searchQuery = '';
  selected?: MicrosoftUserSearchResult;
  searching = false;

  @Output()
    selectMicrosoftUser = new EventEmitter<MicrosoftUserSearchResult>();

  results: MicrosoftUserSearchResult[] = [];
  search$?: Subscription;

  searchTimeout: any;

  showDropdown = true;

  constructor(private microsoftUserService: MicrosoftUserService) { }

  search() {
    if (this.searchQuery.length < 3) {
      this.results = [];
      this.searching = false;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if (this.search$) {
        this.search$.unsubscribe();
      }
      return;
    }

    this.searching = true;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    if (this.search$) {
      this.search$.unsubscribe();
    }
    this.searchTimeout = setTimeout(() => {
      this.search$ = this.microsoftUserService.searchUsers(this.searchQuery)
        .subscribe(results => {
          this.results = results;
          this.searching = false;
        });
    }, 500);
  }

  selectUser(user: MicrosoftUserSearchResult) {
    this.selected = user;
    this.selectMicrosoftUser.emit(this.selected);
  }

  clearSelected() {
    this.selected = undefined;
    this.selectMicrosoftUser.emit(this.selected);
  }

}
