import { Component } from '@angular/core';
import { ExportFileService } from '@app/core/services/export-file.service';

@Component({
  selector: 'app-user-template-download-page',
  templateUrl: './user-template-download-page.component.html',
  styleUrls: ['./user-template-download-page.component.sass']
})
export class UserTemplateDownloadPageComponent {

  userTemplate = 'Email,First Name,Last Name,Role,Password,Invite';

  constructor(private exportService: ExportFileService) { }

  downloadUserTemplate() {
    const blob = new Blob([this.userTemplate], { type: 'text/csv' });
    this.exportService.triggerDownload(blob, 'user-template.csv');
  }

}
