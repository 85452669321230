import { Component, OnDestroy, OnInit } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { CourseApiService } from '@app/api/course/services/course-api.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { CourseData } from '@app/api/models/learning-material-data.model';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';

@Component({
  selector: 'app-course-activity-view',
  templateUrl: './course-activity-view.component.html',
  styleUrls: ['./course-activity-view.component.sass']
})
export class CourseActivityViewComponent implements OnInit, OnDestroy {

  courseActivityId?: number;

  courseActivity?: LearningActivity;
  course?: CourseData;
  learningActivitySubscription?: Subscription;

  loading = false;

  constructor(
    private route: ActivatedRoute,
    private learningRouteData: LearningRouteDataService,
    private learningActivityService: LearningActivityService,
    private courseApi: CourseApiService) {
    this.route.paramMap.subscribe(params => {

      this.learningRouteData.updateRouteData(params);

      const courseId = params.get('courseId');

      if (courseId) {
        this.courseActivityId = Number(courseId);
        this.loadCourseActivity(this.courseActivityId);
      }

    });
  }

  ngOnInit() {
    if (this.courseActivityId) {
      this.loadCourseActivity(this.courseActivityId);
    }
  }

  loadCourseActivity(courseActivityId: number) {
    this.loading = true;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }

    this.learningActivitySubscription = this.learningActivityService.getLearningActivity(courseActivityId)
      .pipe(
        switchMap(courseActivity => {
          this.courseActivity = courseActivity;
          return this.courseApi.getCourseById(courseActivity.materialId);
        })
      )
      .subscribe({
        next: course => {
          this.course = course;
          this.loading = false;
        }
      });
  }

  unloadCourseActivity() {
    this.courseActivity = undefined;
    this.course = undefined;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unloadCourseActivity();
  }


}
