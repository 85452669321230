import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UserService } from '@app/api/user/services/user.service';
import { User } from '@app/api/user/models/user.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserFilterParams } from '@app/api/user/models/user-filter-params.model';
import { KeyBindingService } from '@core/services/key-binding.service';

@Component({
  selector: 'app-user-select-field',
  templateUrl: './user-select-field.component.html',
  styleUrls: ['./user-select-field.component.sass']
})
export class UserSelectFieldComponent {

  @ViewChild('searchInput') searchInput?: any;
  @Input() userFilter: UserFilterParams = {};
  @Input() placeholder = 'Search for users...';
  searchForm: FormGroup;
  userResults: User[] = [];
  searchInputFocused = false;
  showDropdown = false;
  searchRequestTimeout: any;
  searching = false;
  @Input() disabled = false;

  @Input() selectedUsers: User[] = [];
  @Output() selectedUsersChange = new EventEmitter<User[]>();
  @Input() maxUsers = 0;

  constructor(
    private fb: FormBuilder,
    private keyBindingService: KeyBindingService,
    private userApi: UserService) {

    this.searchForm = this.fb.group({
      search: [''],
    });

    this.keyBindingService.registerKeyBind('backspace', () => {
      if (this.searchForm.get('search')?.value.length === 0 && this.searchInputFocused) {
        this.selectedUsers.pop();
      }
    });

    this.searchForm.valueChanges.subscribe({
      next: (value) => {
        const { search } = value;
        this.showDropdown = search.length > 1;
        this.searching = true;

        if (this.searchRequestTimeout) {
          clearTimeout(this.searchRequestTimeout);
        }

        this.searchRequestTimeout = setTimeout(() => {
          if (this.showDropdown) {
            this.userApi.getAllUsers(search, this.userFilter, {
              size: 5
            }).subscribe({
              next: (users) => {
                this.userResults = users.content.filter((u) => {
                  return !this.selectedUsers.find((su) => su.id === u.id);
                });
                this.searching = false;
              },
              complete: () => {
                this.searching = false;
              }
            });
          }
        }, 250);
      }
    });

  }

  addUser(user: User) {

    if (this.maxUsers > 0 && this.selectedUsers.length >= this.maxUsers) {
      return;
    }

    this.selectedUsers.push(user);
    const searchField = this.searchForm.get('search');
    searchField?.setValue('');
    this.focusSearchInput();
    this.showDropdown = false;
    this.selectedUsersChange.emit(this.selectedUsers);
  }

  deleteUser(user: User) {
    this.selectedUsers = this.selectedUsers.filter((u) => u.id !== user.id);
    this.selectedUsersChange.emit(this.selectedUsers);
  }

  focusSearchInput() {
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    });
  }

}
