import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-modal-button',
  templateUrl: './confirm-modal-button.component.html',
  styleUrls: ['./confirm-modal-button.component.sass']
})
export class ConfirmModalButtonComponent {

}
