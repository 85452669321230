import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { UserService } from '@app/api/user/services/user.service';
import { UserSettingsModel } from '@app/api/user/models/user-settings.model';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(private http: HttpClient, private userService: UserService, private api: ApiService) { }

  getUserSettings() {
    return this.userService.getSelf().pipe(
      switchMap(user => this.http.get<UserSettingsModel>(this.api.endpoint(['users', user.id, 'settings'])))
    );
  }

  updateUserSetting(key: string, value: string | null) {
    return this.userService.getSelf().pipe(
      switchMap(user => this.http.put<UserSettingsModel>(this.api.endpoint(['users', user.id, 'settings', key]), { value }))
    );
  }

  resetUserSettings() {
    return this.userService.getSelf().pipe(
      switchMap(user => this.http.delete<UserSettingsModel>(this.api.endpoint(['users', user.id, 'settings'])))
    );
  }
}
