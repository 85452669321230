<div *ngFor="let choice of choices; let i = index">
  <div *ngIf="!selectAllThatApply; else selectAllThatApplyCheckboxes" class="my-2 p-3 border rounded">
    <label class="form-check">
      <input name="choice"
             type="radio"
             [disabled]="isDisabled"
             [value]="i"
             [checked]="i === selectedIndexes[0]"
             (change)="onChoiceChange($event)"
             class="form-check-input me-2"/>
      <span class="form-check-label" [innerHTML]="choice.choiceText | markdown: { inline: true }">
      </span>
    </label>
  </div>
  <ng-template #selectAllThatApplyCheckboxes>
    <div class="my-2 p-3 border rounded">
      <label class="form-check">
        <input name="choice"
               type="checkbox"
               [disabled]="isDisabled"
               [value]="i"
               [checked]="selectedIndexes.includes(i)"
               (change)="onChoicesChange($event)"
               class="form-check-input me-2"/>
        <span class="form-check-label" [innerHTML]="choice.choiceText | markdown: { inline: true }">
      </span>
      </label>
    </div>
  </ng-template>
</div>
