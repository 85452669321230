<div class="row">
  <div class="col-12 col-md-6 cursor-text">
    <!-- Toolbar and Editor -->
    <div class="d-flex flex-column rounded-3" [ngClass]="{
      'bg-dark': showToolbar && useMarkdown,
    }">
      <!-- Toolbar -->
      <div *ngIf="useMarkdown && showToolbar"
           class="cursor-default bg-dark text-light rounded-top d-flex align-items-center">
        <div #toolbar class="m-2 d-flex flex-row align-items-center flex-grow-1">
          <!-- Formatting -->
          <div class="border-end pe-2 me-2">
            <button *ngFor="let btn of formatButtons"
                    [ngbTooltip]="btn.tooltip"
                    [openDelay]="350"
                    [disabled]="isDisabled"
                    type="button"
                    triggers="hover"
                    placement="bottom"
                    (focus)="setEditorHasFocus(true)"
                    (click)="mdAction(btn)"
                    class="btn btn-sm btn-dark text-light">
              <span class="bi" [ngClass]="btn.icon"></span>
            </button>
          </div>
          <!-- Formatting 2 -->
          <div class="border-end pe-2 me-2 d-flex flex-row align-items-center">
            <div ngbDropdown>
              <button type="button"
                      ngbDropdownToggle
                      ngbTooltip="Format"
                      [openDelay]="500"
                      [disabled]="isDisabled"
                      triggers="hover"
                      placement="bottom"
                      (focus)="setEditorHasFocus(true)"
                      (click)="setCurrentTextType(currentTextType)"
                      class="btn btn-sm btn-dark text-light">
                {{ currentTextType.name }}
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem
                        type="button">
                  <span [ngClass]="currentTextType.className">{{ currentTextType.name }}</span>
                </button>
                <div class="dropdown-divider"></div>
                <button *ngFor="let type of selectableTextTypes"
                        ngbDropdownItem
                        (focus)="setEditorHasFocus(true)"
                        [disabled]="isDisabled"
                        (click)="setCurrentTextType(type)"
                        type="button">
                  <span [ngClass]="type.className">{{ type.name }}</span>
                </button>
              </div>
            </div>
            <div *appIfBreakpoint="'sm'; else formatButtons2Dropdown; container: toolbar" class="mx-2">
              <button *ngFor="let btn of formatButtons2"
                      [ngbTooltip]="btn.tooltip"
                      [openDelay]="350"
                      type="button"
                      triggers="hover"
                      placement="bottom"
                      (focus)="setEditorHasFocus(true)"
                      [disabled]="isDisabled"
                      (click)="mdAction(btn)"
                      class="btn btn-sm btn-dark text-light">
                <span class="bi" [ngClass]="btn.icon"></span>
              </button>
            </div>
            <ng-template #formatButtons2Dropdown>
              <div ngbDropdown>
                <button ngbDropdownToggle
                        [disabled]="isDisabled"
                        (focus)="setEditorHasFocus(true)"
                        type="button"
                        ngbTooltip="Format"
                        placement="bottom"
                        class="btn btn-sm btn-dark text-light">
                  <span class="bi bi-list-columns-reverse"></span>
                </button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem
                          (focus)="setEditorHasFocus(true)"
                          [disabled]="isDisabled"
                          *ngFor="let btn of formatButtons2" (click)="mdAction(btn)">
                    <span class="bi" [ngClass]="btn.icon"></span>
                    {{ btn.tooltip }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- Insert Buttons -->
          <div *appIfBreakpoint="'sm'; else insertButtonDropdown" class="mx-2">
            <button *ngFor="let btn of insertButtons"
                    [ngbTooltip]="btn.tooltip"
                    [openDelay]="350"
                    type="button"
                    (focus)="setEditorHasFocus(true)"
                    triggers="hover"
                    placement="bottom"
                    (click)="mdAction(btn)"
                    [disabled]="isDisabled"
                    class="btn btn-sm btn-dark text-light">
              <span class="bi" [ngClass]="btn.icon"></span>
            </button>
          </div>
          <ng-template #insertButtonDropdown>
            <div ngbDropdown>
              <button ngbDropdownToggle
                      type="button"
                      (focus)="setEditorHasFocus(true)"
                      ngbTooltip="Insert"
                      placement="bottom"
                      class="btn btn-sm btn-dark text-light">
                <span class="bi bi-box-arrow-in-down"></span>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem
                        (focus)="setEditorHasFocus(true)"
                        [disabled]="isDisabled"
                        *ngFor="let btn of insertButtons" (click)="mdAction(btn)">
                  <span class="bi" [ngClass]="btn.icon"></span>
                  {{ btn.tooltip }}
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="showPreviewButton" class="text-end mx-2">
          <button *ngFor="let btn of viewButtons"
                  [ngbTooltip]="btn.tooltip"
                  [openDelay]="350"
                  [disabled]="isDisabled"
                  type="button"
                  (focus)="setEditorHasFocus(true)"
                  triggers="hover"
                  placement="bottom"
                  (click)="mdAction(btn)"
                  class="btn btn-sm btn-dark text-light">
            <span class="bi" [ngClass]="toggleNgClass(btn)"></span>
          </button>
        </div>
      </div>
      <!-- Editor -->
      <div class="position-relative"
           [ngClass]="{
              'rounded-bottom-2 bg-white pb-3 border px-2': useMarkdown,
              'rounded-top-2 pt-3': !showToolbar,
            }">
        <div [ngStyle]="{
          height: _height
        }">
          <ng-container *ngIf="useMarkdown; else defaultTextarea">
            <ngx-codemirror
              #editor
              [(ngModel)]="value"
              (ngModelChange)="onChange(value)"
              (cursorActivity)="handleCursorActivity($event)"
              (focusChange)="handleFocusChange($event)"
              [disabled]="isDisabled"
              className="p-3 h-100"
              [options]="codeMirrorOptions"></ngx-codemirror>
          </ng-container>
        </div>
        <ng-template #defaultTextarea>
          <div class="form-floating">
            <textarea
              [(ngModel)]="value"
              (ngModelChange)="onChange(value)"
              [style]="{ 'height': multilineHeight }"
              [rows]="lines"
              (focus)="editorFocus.emit(); setEditorHasFocus(true)"
              [disabled]="isDisabled"
              id="defaultTextArea"
              [placeholder]="placeholder"
              class="form-control {{textAreaClassName ? textAreaClassName : ''}}"></textarea>
            <label for="defaultTextArea" class="form-label">{{ placeholder }}</label>
          </div>
        </ng-template>
        <div class="position-absolute top-0 end-0 m-2">
          <div class="form-switch" *ngIf="!hideUseMarkdownToggle"
               [ngbTooltip]="useMarkdown ? 'Disable editor' : 'Enable editor'" container="body" [openDelay]="500">
            <input type="checkbox" [(ngModel)]="useMarkdown" class="form-check-input">
          </div>
          <div class="form-switch" *ngIf="hideUseMarkdownToggle && showToolbarToggle"
               [ngbTooltip]="showToolbar ? 'Hide toolbar' : 'Show toolbar'" container="body" [openDelay]="500">
            <input type="checkbox" [(ngModel)]="showToolbar" class="form-check-input">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Preview panel -->
  <div *ngIf="showPreview" class="col-12 col-md-6">
    <app-markdown-viewer [data]="value" [showFrame]="true" [showExportOptions]="true"></app-markdown-viewer>
  </div>
</div>

<ng-template #insertLinkModal>
  <div class="modal-header">
    <h5 class="modal-title">Insert link</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div class="form-floating mb-3">
      <input appAutofocus (focus)="setEditorHasFocus(true)" type="text" id="insertLinkText" class="form-control"
             [(ngModel)]="insertLinkData.text" placeholder="Link text">
      <label for="insertLinkText" class="form-label">Link text</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" id="insertLinkUrl" class="form-control" [(ngModel)]="insertLinkData.url"
             placeholder="Link URL">
      <label for="insertLinkUrl" class="form-label">Link URL</label>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="insertLink(); closeModal()">Insert</button>
  </div>

</ng-template>

<ng-template #insertAlertModal>
  <div class="modal-header">
    <h5 class="modal-title">Insert Alert</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div class="form-floating mb-3">
      <select id="insertAlertType" class="form-select" [(ngModel)]="insertAlertData.type" appAutofocus
              (focus)="setEditorHasFocus(true)">
        <option value="info">Info</option>
        <option value="warning">Warning</option>
        <option value="danger">Danger</option>
      </select>
      <label for="insertAlertType" class="form-label">Type</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" id="insertAlertText" class="form-control" [(ngModel)]="insertAlertData.message"
             placeholder="Link URL">
      <label for="insertLinkUrl" class="form-label">Message</label>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="insertAlert(); closeModal()">Insert</button>
  </div>
</ng-template>

<ng-template #insertImageModal>
  <div class="modal-header">
    <h5 class="modal-title">Insert Image</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">

    <div class="my-2 text-center">
        <button (click)="openImageFileModal()" class="btn btn-primary">
        Select Image File
      </button>
    </div>

    <div class="container-fluid d-flex flex-row justify-content-around my-4">
      <hr class="w-100"/>
      <span class="px-4">
      {{ 'or' | stringResource }}
    </span>
      <hr class="w-100"/>
    </div>

    <div class="form-floating mb-3">
        <input [(ngModel)]="fileUrl" class="form-control" id="insertImageUrl" placeholder="Image URL" type="text">
      <label for="insertImageUrl" class="form-label">Image URL</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" id="insertImageAlt" class="form-control" [(ngModel)]="imageAlt" placeholder="Image Alt">
      <label for="insertImageAlt" class="form-label">Image Alt</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
      <button (click)="insertFile(); closeModal()" class="btn btn-primary" type="button">Insert</button>
  </div>
</ng-template>


<ng-template #insertPdfModal>
  <div class="modal-header">
      <h5 class="modal-title">Insert PDF</h5>
      <button (click)="closeModal()" aria-label="Close" class="btn-close" type="button"></button>
  </div>
    <div class="modal-body">

        <div class="my-2 text-center">
            <button (click)="openInsertPdfModal()" class="btn btn-primary">
                Select PDF File
            </button>
        </div>

        <div class="container-fluid d-flex flex-row justify-content-around my-4">
            <hr class="w-100"/>
            <span class="px-4">
      {{ 'or' | stringResource }}
    </span>
            <hr class="w-100"/>
        </div>

        <div class="form-floating mb-3">
            <input [(ngModel)]="fileUrl" class="form-control" placeholder="File URL" type="text">
            <label class="form-label" for="insertImageUrl">URL</label>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="closeModal()" class="btn btn-outline-primary" type="button">Cancel</button>
        <button (click)="insertFile(); closeModal()" class="btn btn-primary" type="button">Insert</button>
    </div>
</ng-template>

<ng-template #insertFileModal>
    <div class="modal-header">
        <h5 class="modal-title">{{ insertFileLabel }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body p-0">
      <app-file-explorer
              (fileSelected)="onFileSelect($event)"
              [fileTypeFilter]="fileTypes"
              [uploadAcceptedExtensions]="extensions"
              [userEmail]="userEmail">
      </app-file-explorer>
  </div>
</ng-template>
<ng-template #insertVideoModal>
  <div class="modal-header">
    <h5 class="modal-title">Insert Video</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body ">
    <div class="row">
      <button class="col btn btn-primary m-2 text-center" (click)="openSelectVideoModal()">
        Select Video
      </button>
      <button class="col btn btn-primary m-2 text-center" (click)="openUploadVideoModal()">
        Upload Video
      </button>
    </div>
    <div class="container-fluid d-flex flex-row justify-content-around my-4">
      <hr class="w-100"/>
      <span class="px-4">
        {{ 'or' | stringResource }}
      </span>
      <hr class="w-100"/>
    </div>
    <div class="form-floating mb-3">
      <input type="text" id="insertVideoUrl" class="form-control" [(ngModel)]="videoUrl" placeholder="Video URL">
      <label for="insertVideoUrl" class="form-label">Youtube URL</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" id="insertVideoAlt" class="form-control" [(ngModel)]="videoAlt" placeholder="Video Alt">
      <label for="insertVideoAlt" class="form-label">Video Alt</label>
    </div>
    <div class="form-floating mb-3">
      <div>Note: Use Youtube "embedded" url.</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="insertVideo(); closeModal()">Insert</button>
  </div>
</ng-template>
<ng-template #selectVideoModal>
  <div class="modal-header">
    <h5 class="modal-title">Select Video</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <app-video-selector
      [selectionModeEnabled]="true"
      (videoToInsert)="onVideoSelect($event)"></app-video-selector>
  </div>
</ng-template>

<ng-template #uploadVideoModal>
  <app-video-upload-modal
    (uploadComplete)=" onVideoUploadComplete($event)"
    (closeModal)="uploadVideoModalRef.close()"/>
</ng-template>

