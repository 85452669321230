import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Trainer } from '@app/api/models/trainer.model';
import { TrainerApiService } from '@app/api/training/services/trainer-api.service';
import { UserFilterParams } from '@app/api/user/models/user-filter-params.model';
import { User } from '@app/api/user/models/user.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-create-trainer',
  templateUrl: './create-trainer.component.html',
  styleUrls: ['./create-trainer.component.sass'],
})
export class CreateTrainerComponent implements OnChanges {
  @Output() cancelClicked = new EventEmitter<void>();

  @Input() mode: 'create' | 'edit' = 'create';
  @Input() trainer!: Trainer;

  trainerData: Trainer = {
    id: undefined,
    email: '',
    firstName: '',
    lastName: '',
    technologies: [],
    existingUser: false,
    invite: false,
  };
  addTrainerForm: FormGroup<any>;
  inProgress: boolean = false;
  selectedUsers: User[] = [];
  selectedTrainer!: User;
  isExistingUser: boolean = false;
  isAddExistingUser: boolean = false;
  maxUsers: number = 1;

  constructor(
    private trainerApiService: TrainerApiService,
    private modalService: GlobalModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.addTrainerForm = new FormGroup({
      addExistingUser: new FormControl(),
      email: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      technologies: new FormControl([]),
      invite: new FormControl(false),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainer']) {
      this.updateForm();
    }
  }

  updateForm() {
    if (this.trainer) {
      this.addTrainerForm.patchValue(this.trainer);
      if (this.trainer.invite === true) {
        this.addTrainerForm.get('invite')?.setValue(true);
      }
    }
  }

  get users(): UserFilterParams {
    return {
      enabled: true,
      role: ['Admin', 'Student', 'Training Assistant', 'Developer'],
    };
  }

  showAppUserSelectField(event: Event) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    this.isAddExistingUser = isChecked;
  }

  addExistingUser() {
    const user: User[] = this.selectedUsers.map((u) => {
      return {
        email: u.email,
        firstName: u.firstName,
        lastName: u.lastName,
      } as User;
    });

    this.selectedTrainer = user[0];
    this.isAddExistingUser = false;
    this.isExistingUser = true;
    this.selectedUsers = [];
    if (this.selectedTrainer) {
      this.addTrainerForm.get('email')?.setValue(this.selectedTrainer.email);
      this.addTrainerForm
        .get('firstName')
        ?.setValue(this.selectedTrainer.firstName);
      this.addTrainerForm
        .get('lastName')
        ?.setValue(this.selectedTrainer.lastName);
    }
    this.trainerData.existingUser = true;
  }

  onSubmit() {
    this.trainerData.email = this.addTrainerForm.get('email')?.value;
    this.trainerData.firstName = this.addTrainerForm.get('firstName')?.value;
    this.trainerData.lastName = this.addTrainerForm.get('lastName')?.value;
    this.trainerData.technologies =
      this.addTrainerForm.get('technologies')?.value;
    this.trainerData.invite = this.addTrainerForm.get('invite')?.value;

    this.addTrainerForm.disable();
    if (this.mode === 'create') {
      this.modalService
        .confirm('Are you sure you want to create this trainer?')
        .subscribe((confirmed) => {
          if (confirmed) {
            this.inProgress = true;
            this.createTrainer();
          } else {
            this.addTrainerForm.enable();
          }
        });
    } else {
      this.modalService
        .confirm('Are you sure you want to update this trainer?')
        .subscribe((confirmed) => {
          if (confirmed) {
            this.inProgress = true;
            this.updateTrainer();
          } else {
            this.addTrainerForm.enable();
          }
        });
    }
  }

  createTrainer() {
    this.trainerData.id =
      this.trainerApiService.getAllTrainers().forEach.length + 1;
    this.trainerApiService.createTrainer(this.trainerData).subscribe({
      next: (response) => {
        this.modalService.alert({
          title: 'Success',
          content: `Trainer ${response.email} created successfully.`,
          type: 'success',
        });
        this.inProgress = false;
        this.addTrainerForm.get('addExistingUser')?.setValue(false);
        this.addTrainerForm.reset();
        this.addTrainerForm.enable();
      },
      error: (error) => {
        this.modalService.alert({
          title: 'Error',
          content: error.error.message,
          type: 'danger',
        });
        this.inProgress = false;
        this.addTrainerForm.enable();
      },
      complete: () => {
        this.inProgress = false;
        this.addTrainerForm.enable();
      },
    });
  }

  updateTrainer() {
    this.trainerData.id = this.trainer?.id || undefined;
    this.trainerApiService
      .updateTrainer(this.trainerData, this.trainerData.id)
      .subscribe({
        next: (response) => {
          this.modalService.alert({
            title: 'Success',
            content: `Trainer ${response.email} updated successfully.`,
            type: 'success',
          });
          this.inProgress = false;
          this.addTrainerForm.reset();
          this.addTrainerForm.enable();
        },
        error: (error) => {
          this.modalService.alert({
            title: 'Error',
            content: error.error.message,
            type: 'danger',
          });
          this.inProgress = false;
          this.addTrainerForm.enable();
        },
        complete: () => {
          this.inProgress = false;
          this.addTrainerForm.enable();
        },
      });
  }
  goBack() {
    this.router.navigate(['/training'], { relativeTo: this.activatedRoute });
  }
}
