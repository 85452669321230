import { Component } from '@angular/core';

@Component({
  selector: 'app-file-manager-page',
  templateUrl: './file-manager-page.component.html',
  styleUrls: ['./file-manager-page.component.sass']
})
export class FileManagerPageComponent {

}
