import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Page, PageParams } from '@app/api/models/page.model';
import { Observable } from 'rxjs';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';
import { ApiService } from '@core/services/api.service';
import { LearningMaterialData } from '@app/api/models/learning-material-data.model';

export type ContentQueryParams = PageParams & {
  types: ActivityMaterialType[];
  technologies?: string[];
  published?: boolean,
  deprecated?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class ContentLibraryService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getContent(pageParams: ContentQueryParams): Observable<Page<LearningMaterialData>> {
    return this.http.get<Page<LearningMaterialData>>(this.api.endpoint('/content-library'), {
      params: pageParams
    });
  }

}
