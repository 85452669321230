<div class="container">

  <div class="card card-body my-4" *ngIf="student; else spinner">
    <div class="my-2 d-flex justify-content-between align-items-start gap-3">

      <div class="d-flex align-items-center gap-3">
          <div style="width: 120px; height: 120px" class="d-flex justify-content-center align-items-center">
          <img *ngIf="profilePictureUrl; else spinner" [src]="profilePictureUrl" class="img-fluid img-thumbnail rounded-circle" alt=""/>
          </div>

          <div class="d-flex flex-column">
          <div class="h2">{{student.firstName}} {{student.lastName}}</div>
          <div>
              <span class="bi bi-envelope-fill text-info"></span>
              {{student.email}}
          </div>
          </div>
      </div>
      <div>
          <button class="btn btn-primary" type="button"
              [routerLink]="['/training', 'students', 'edit', student.id]">
              Edit<span class="bi bi-pencil-square"></span>
          </button>
      </div>
    </div>
  </div>

  <div class="list-group list-group-flush">
    <div class="list-group-item py-3">
      <h4>Phases</h4>
      <p>Select/deselect a single or multiple phases to grant/revoke privileges.</p>
      <div class="my-2">
        <span *ngFor="let phase of currentStudentPhases" class="bg-info text-light small border rounded p-1 px-2 me-1">
          {{phase}}
        </span>
      </div>
      <button type="button"
              (click)="openUpdatePhasesModal()"
              class="btn btn-primary">
      Update Phases <span class="bi bi-person-badge"></span>
      </button>
    </div>

    <div class="list-group-item py-3">
      <h4>Learning Activities</h4>
      <p>view learning activities of a student</p>
      <button type="button"
              (click)="openLearningActivitiesModal()"
              class="btn btn-primary">
      View Activities <span class="bi bi-person-badge"></span>
      </button>
    </div>

    <div class="list-group-item py-3">
      <h4>Cohorts</h4>
      <p>Select/deselect a single or multiple Cohorts.</p>
      <div class="my-2">
        <span *ngFor="let cohortId of currentStudentCohorts" class="bg-info text-light small border rounded p-1 px-2 me-1">
        {{getCohortName(cohortId)}}
        </span>
      </div>
      <button type="button"
              (click)="openUpdateCohortsModal()"
              class="btn btn-primary">
      Update Cohorts <span class="bi bi-person-badge"></span>
      </button>
    </div>

    <div class="list-group-item py-3">
      <h4>Delete Student</h4>
      <p>Delete the student and remove access to all their resources.</p>
      <button class="btn btn-danger" type="button" (click)="openDeleteStudentConfirm()">
       <span class="bi bi-trash"></span> Delete
      </button>
    </div>
  </div>

  <ng-template *ngIf="student" #updatePhasesModal>
    <p>Select/deselect a single or multiple Phases to grant/revoke privileges.</p>
    <app-multi-select-field [options]="allPhases" [minCount]="0" [(ngModel)]="selectedStudentPhases"></app-multi-select-field>
  </ng-template>

  <ng-template *ngIf="student" #updateCohortsModal>
    <p>Select/deselect a single or multiple Phases to grant/revoke privileges.</p>
    <app-multi-select-field [options]="allCohorts" [minCount]="0" [(ngModel)]="selectedStudentCohorts"></app-multi-select-field>
  </ng-template>

  <ng-template #learningActivitiesModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">View learningActivities</h5>
      <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
    </div>
    <div class="modal-body" style="max-height: 400px; overflow-y: auto;">
      <table class="table table-striped">
        <thead>
          <tr class="table-light">
            <th>Learning Activity</th>
            <th>Type</th>
            <th>Due Date</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let activity of learningActivities">
            <td>{{ activity.title }}</td>
            <td>{{ activity.type }}</td>
            <td>{{ activity.dueDate | date: 'MM/dd/yyyy' }}</td>
            <td>{{ activity.progress | percent }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close()">Close</button>
    </div>
  </ng-template>

  <ng-template #spinner>
      <div class="spinner-border text-primary"></div>
  </ng-template>

  <ng-template #inlineSpinner>
    <span class="spinner-border spinner-border-sm"></span>
  </ng-template>
</div>
