import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { Jwt } from '@auth/interfaces/jwt';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  decodeJwt(token: string): Jwt {
    return jwtDecode<Jwt>(token);
  }

  getTokenExpirationDate(token: string): Date | null {
    const claims = this.decodeJwt(token);
    if (claims.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(claims.exp);
    return date;
  }

  isTokenExpired(token: string, offsetSeconds = 0): boolean {
    if (token === null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === null) {
      return true;
    }
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  extractEmail(token: string): string {
    const claims = this.decodeJwt(token);
    return claims.sub;
  }
}
