<div *ngIf="rubric.criteria && rubric.criteria.length">

  <h3 class="mb-2">Grading Form</h3>

  <p class="text-muted">Grade the assignment using the rubric criteria provided.</p>

  <div class="my-2 table-responsive">
    <table class="table table-bordered">
      <thead class="table-light">
      <tr>
        <th><span class="visually-hidden">Criteria</span></th>
        <th>Excellent <span class="text-muted small">(5 points)</span></th>
        <th>Great <span class="text-muted small">(4 points)</span></th>
        <th>Good <span class="text-muted small">(3 points)</span></th>
        <th>Fair <span class="text-muted small">(2 points)</span></th>
        <th>Poor <span class="text-muted small">(1 points)</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let criterion of rubric.criteria; let criteriaIndex = index">
        <td>
          <div class="fw-bold mb-1">{{criterion.title}}</div>
          <div class="text-muted">{{criterion.description}}</div>
        </td>
        <td *ngFor="let rating of criterion.ratings.reverse(); let index = index">
          <div *ngIf="useRubricScoring && !graded">
            <div class="form-check form-check-reverse">
              <label for="rating{{criterion.id}}_{{rating.id}}" class="form-check-label text-info w-100">
                +{{getPointsFromRatingLevel(rating.ratingLevel)}}
              </label>
              <input id="rating{{criterion.id}}_{{rating.id}}" type="radio" class="form-check-input" name="rating{{criterion.id}}" [value]="getPointsFromRatingLevel(rating.ratingLevel)" [(ngModel)]="criteriaScores[criteriaIndex]" (ngModelChange)="calculatePoints()" [disabled]="!useRubricScoring">
            </div>
          </div>
          <div class="w-100">
            {{rating.description}}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="my-2" *ngIf="!graded; else viewGrade">

  <table class="table">
    <tbody>
    <tr>
      <th scope="row">
        Total Points:
        <br/>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="useRubricCriteria" [(ngModel)]="useRubricCriteria" [disabled]="noRubric">
          <label class="form-check-label text-muted fw-normal" for="useRubricCriteria">Use rubric criteria total points</label>
        </div>
      </th>
      <td>
        <input [disabled]="useRubricCriteria || noRubric" type="number" class="form-control" placeholder="Total points" [(ngModel)]="totalPoints" required>
      </td>
    </tr>
    <tr>
      <th scope="row">
        Scored Points:
        <br/>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="useRubricScoring" [(ngModel)]="useRubricScoring" [disabled]="noRubric">
          <label class="form-check-label text-muted fw-normal" for="useRubricScoring">Use rubric for score</label>
        </div>
      </th>
      <td>
        <input [disabled]="useRubricScoring || noRubric" type="number" class="form-control" placeholder="Scored points" [(ngModel)]="scoredPoints" required>
      </td>
    </tr>
    <tr>
      <th scope="row">
        Grade:
        <br/>
        <div class="form-check form-switch">
          <input [disabled]="noRubric" class="form-check-input" type="checkbox" id="overrideGrade" [(ngModel)]="overrideGrade" (ngModelChange)="calculateGrade()">
          <label class="form-check-label text-muted fw-normal" for="overrideGrade">Override</label>
        </div>
      </th>
      <td>
        <div *ngIf="overrideGrade || noRubric; else readOnlyGrade">
          <input type="number" min="0" max="1" class="form-control" placeholder="Grade" [(ngModel)]="grade" required>
          <div *ngIf="overrideGrade || noRubric" class="text-muted x-small">Enter a value from 0-1. (e.g 0.96)</div>
        </div>
        <ng-template #readOnlyGrade>
          {{ grade | percent: '1.0-2' }}
        </ng-template>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="text-end">
    <button *ngIf="learningActivityGraded" type="button" class="btn btn-outline-secondary me-1" (click)="cancelGrade()">
      Cancel
    </button>
    <button [disabled]="saving" type="button" class="btn btn-primary" (click)="saveGrade()">
      Save Grade <span *ngIf="saving" class="spinner-border spinner-border-sm"></span>
    </button>
  </div>

</div>

<ng-template #viewGrade>
  <div class="card">
    <div class="card-body">
      <p class="card-text">
        <span class="fw-bold">Grade:</span> <span class="text-info ms-1">{{ grade | percent: '1.0-2' }}</span>
      </p>
      <button type="button" class="btn btn-primary btn-sm" (click)="editGrade()">
        Edit Grade <span class="bi bi-pencil-square"></span>
      </button>
    </div>
  </div>
</ng-template>
