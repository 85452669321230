import { Routes } from '@angular/router';
import {
  UserManagementHomePageComponent
} from '@pages/user-management-page/user-management-home-page/user-management-home-page.component';
import { AddUserPageComponent } from '@pages/user-management-page/add-user-page/add-user-page.component';
import { ViewUsersPageComponent } from '@pages/user-management-page/view-users-page/view-users-page.component';
import { EditUserComponent } from '@app/api/user/components/edit-user/edit-user.component';
import { ImportUsersPageComponent } from '@app/pages/user-management-page/import-users-page/import-users-page.component';
import { UserTemplateDownloadPageComponent } from '@app/pages/user-management-page/user-template-download-page/user-template-download-page.component';

export const UserManagementRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: UserManagementHomePageComponent
  },
  {
    path: 'add-user',
    data: { title: 'Add User' },
    component: AddUserPageComponent
  },
  {
    path: 'view-users',
    data: { title: 'View Users' },
    component: ViewUsersPageComponent
  },
  {
    path: 'view-users/:id',
    data: { title: 'View User' },
    component: EditUserComponent
  },
  {
    path: 'view-users/:id/edit',
    data: { title: 'Edit User' },
    component: EditUserComponent
  },
  {
    path: 'import-users',
    data: { title: 'Import Users' },
    component: ImportUsersPageComponent
  },
  {
    path: 'user-template-download',
    data: { title: 'User Template Download' },
    component: UserTemplateDownloadPageComponent
  }
];
