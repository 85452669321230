export interface BuildInfo {
  buildTime: string;
  buildNumber: string;
  commit: string;
  branch: string;
  environment: string;
}

const buildInfo: BuildInfo = {
  environment: 'prod',
  buildTime: '',
  buildNumber: '5',
  commit: '30a8d20f009ba059822d3d1ffc95ed0954c34d3a',
  branch: 'origin/main'
};

export const environment = {
  production: true,
  clientUrl: 'https://app.learn.smoothstack.com',
  apiUrl: 'https://api.learn.smoothstack.com/api/v1',
  azureClientId: 'e9a55099-2a1f-49ac-834c-d57877cab455',
  azureTenantId: '7824f42c-45bd-47d8-8d15-275c536fa0a2',
  redirectUri: 'https://app.learn.smoothstack.com/login',
  postLogoutRedirectUri: 'https://app.learn.smoothstack.com/login',
  buildInfo: buildInfo
};
