import { Component } from '@angular/core';

@Component({
  selector: 'app-view-edit-lessons-page',
  templateUrl: './view-edit-lessons-page.component.html',
  styleUrls: ['./view-edit-lessons-page.component.sass']
})
export class ViewEditLessonsPageComponent {

}
