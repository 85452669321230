import { Component } from '@angular/core';

@Component({
  selector: 'app-question-creator-page',
  templateUrl: './question-creator-page.component.html',
  styleUrls: ['./question-creator-page.component.sass']
})
export class QuestionCreatorPageComponent {

}
