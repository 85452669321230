<div class="container-fluid">

  <div class="mt-3 mb-3 d-flex justify-content-between">
    <h2 class="h4">View Trainers</h2>
    <div class="flex-grow text-end">
      <button type="button" class="btn btn-primary w-100" routerLink="/training/trainers/add">
        <span class="bi bi-plus-circle"></span>
        Add Trainer
      </button>
    </div>
  </div>

  <div class="my-2">
    <app-view-trainers></app-view-trainers>
  </div>

</div>