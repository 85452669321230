import { Injectable } from '@angular/core';
import { Setting, Settings } from '@core/interfaces/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: Settings = [
    {
      name: 'General Settings',
      settings: [
        {
          name: 'defaultToMarkdownEditor',
          label: 'Default to Markdown Editor',
          value: true,
          type: 'boolean',
          description: 'When enabled, the Markdown editor will be used by default instead of a textarea for certain fields.'
        },
      ]
    }
  ];

  getSettings(): Settings {
    return this.settings;
  }

  getSetting(name: string): Setting | null {
    // Settings is an array of SettingsGroup or Setting objects
    // Find the setting with the matching name
    for (const settingGroup of this.settings) {
      if ('settings' in settingGroup) {
        for (const setting of settingGroup.settings) {
          if (setting.name === name) {
            return setting;
          }
        }
      } else {
        if (settingGroup.name === name) {
          return settingGroup;
        }
      }
    }
    return null;
  }

  getSettingValue(name: string): any {
    const setting = this.getSetting(name);
    return setting?.value;
  }
}
