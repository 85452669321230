import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-question-true-false-choice-group',
  templateUrl: './question-true-false-choice-group.component.html',
  styleUrls: ['./question-true-false-choice-group.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuestionTrueFalseChoiceGroupComponent,
      multi: true,
    }
  ]
})
export class QuestionTrueFalseChoiceGroupComponent implements ControlValueAccessor {

  value: boolean | null = null;
  isDisabled = false;

  onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

}
