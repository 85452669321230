import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authenticated() ? true :
    router.navigate(['/login'],
      {
        queryParams: state.url == '/' ? {} : { returnUrl: state.url }
      });
};
