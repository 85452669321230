import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { map, Observable, switchMap } from 'rxjs';
import { FileUploadResponse, TemporaryFileAccessTokenResponse } from '@core/interfaces/file-upload-response.interface';



@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient, private api: ApiService) { }

  buildFormData(file: File, path: string, overwrite: boolean, publiclyAccessible: boolean, fileName?: string) {
    const formData = new FormData();
    const fileBlob = new Blob([file], { type: file.type });
    formData.append('file', fileBlob, file.name);
    formData.append('path', path);
    formData.append('fileName', fileName ? fileName : '');
    formData.append('overwrite', overwrite.toString());
    formData.append('publicAccess', publiclyAccessible.toString());
    return formData;
  }

  uploadFile(
    file: File,
    path: string,
    overwrite = false,
    publiclyAccessible = false,
    fileName?: string): Observable<FileUploadResponse> {
    const formData = this.buildFormData(file, path, overwrite, publiclyAccessible, fileName);
    return this.http.post<FileUploadResponse>(this.api.endpoint('uploads'), formData);
  }

  retrieveFileLink(url: string, preview = false): Observable<string> {
    return this.http.get<TemporaryFileAccessTokenResponse>(url, {
      params: {
        getToken: 'true'
      }
    }).pipe(map(response => preview ? response.previewUrl : response.downloadUrl));
  }

}
