import { Component, TemplateRef, ViewChild, Input, ContentChild, Output, EventEmitter } from '@angular/core';
import { GlobalModalService } from '@core/services/global-modal.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-delete-user-button',
  templateUrl: './delete-user-button.component.html',
  styleUrls: ['./delete-user-button.component.sass']
})
export class DeleteUserButtonComponent {
   
  deleteUserEmail = '';
  @Output() userDeleted = new EventEmitter<void>();
  @Input() user?: User ;
  @ContentChild(TemplateRef) deleteUserTemplate?: TemplateRef<any>;
  @ViewChild('deleteUserModal') deleteUserModal!: TemplateRef<any>;

  context: any = {
    openDeleteUserModal : () => this.openDeleteUserModal(),
  };
  
  constructor(
    private userService: UserService,
    private modalService: GlobalModalService,
    private router: Router,){
  }
 
  openDeleteUserModal() {
    this.modalService.alert({
      title: 'Delete user',
      content: this.deleteUserModal,
      type: 'danger',
      showCancelButton: true,
      okButtonText: 'Delete'
    }).closed.subscribe({
      next: () => {
        if (this.deleteUserEmail === this.user?.email) {
          this.deleteUser();
        } else {
          this.modalService.alert({
            title: 'Invalid email',
            content: 'Please enter the user\'s email address to confirm deletion or cancel deletion.',
            type: 'danger'
          }).result.then(() => {
            this.openDeleteUserModal();
          });
        }
        this.deleteUserEmail = '';
      }
    });
  }

  deleteUser() {
    if (this.user?.id) {
      this.userService.deleteUser(this.user.id).subscribe({
        next: () => {
          this.modalService.alert({
            title: 'User Deleted',
            content: `User ${this.user!.email} has been deleted.`,
            type: 'danger'
          });
          this.userDeleted.emit();
          this.router.navigate(['user-management/view-users']); 
        },
        error: (error) => {
          console.error('Error deleting user:', error);
          this.modalService.alert({
            title: 'Error',
            content: 'Failed to delete user',
            type: 'danger'
          });
        }
      });
    } else {
      console.error('User ID is undefined. Cannot delete user.');
    }
  }

}
