import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-create-quiz-home-page',
  templateUrl: './create-quiz-home-page.component.html',
  styleUrls: ['./create-quiz-home-page.component.sass']
})
export class CreateQuizHomePageComponent {

  items: RouteCardItem[] = [
    {
      title: 'Create a Quiz',
      description: 'Create a new quiz. Use the question bank or create new questions.',
      icon: 'bi-plus-circle',
      routerLink: '/course-composer/quiz-creator/create',
    },
    {
      title: 'View Existing Quizzes',
      description: 'View and edit existing quizzes.',
      icon: 'bi-pencil-square',
      routerLink: '/course-composer/quiz-creator/edit',
    },
    // {
    //   title: 'Import a Quiz',
    //   description: 'Import a quiz from a CSV file.',
    //   icon: 'bi-file-earmark-arrow-up',
    //   routerLink: '/course-composer/quiz-creator/import',
    // },
  ];

}
