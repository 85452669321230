import { Component } from '@angular/core';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { Cohort } from '@app/api/models/cohort.model';
import { catchError, of } from 'rxjs';
import { GlobalToastService } from '@core/services/global-toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cohorts-add-page',
  templateUrl: './cohorts-add-page.component.html',
  styleUrls: ['./cohorts-add-page.component.sass']
})
export class CohortsAddPageComponent {

  saving = false;

  cohort: Cohort = {
    description: '', name: '', students: [], technologies: [], year: 0, day: 0, month: 0,
    createGitlabGroups: false
  };

  constructor(private cohortApi: CohortApiService, private toastService: GlobalToastService, private router: Router) { }

  saveCohort(cohort: Cohort) {
    this.saving = true;
    this.cohortApi.createCohort(cohort)
      .pipe(catchError((_err) => {
        this.toastService.showDangerToast('Error creating cohort.');
        this.saving = false;
        return of(null);
      }))
      .subscribe({
        next: (cohort) => {
          if (!cohort) return;
          this.cohort = cohort;
          this.saving = false;
          // Navigate to the edit page for the newly created cohort
          this.router.navigate(['/training/cohorts/edit', this.cohort.id])
            .then(() => {
              this.toastService.showSuccessToast('Cohort successfully created!');
            });
        }
      });
  }

}
