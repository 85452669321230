<div class="list-group list-group-flush p-0 m-0 w-100">
  <button type="button" class="list-group-item bg-light list-group-item-action py-4 d-flex align-items-center" (click)="courseCollapsed = !courseCollapsed" [style]="courseIndent">
    <span class="bi bi-journal-bookmark-fill me-2 text-primary fs-4"></span>
    <div class="flex-grow-1 mx-1">
      <div class="text-muted x-small">
        Course
      </div>
      {{courseTree.title}}
    </div>
    <span class="bi bi-chevron-up d-inline-block transitioned text-primary" [class.transform-rotate-180]="courseCollapsed"></span>
  </button>
  <div [(ngbCollapse)]="courseCollapsed" class="list-group-item p-0 m-0">
    <div class="list-group list-group-flush p-0 m-0">
      <div class="list-group-item p-0 m-0" *ngFor="let moduleTree of courseTree.modules">
        <app-module-tree-view [moduleTree]="moduleTree" [root]="false"></app-module-tree-view>
      </div>
    </div>
  </div>
</div>
