import { Component } from '@angular/core';
import { RouteCardItem } from '@core/components/route-card/route-card-item.interface';

@Component({
  selector: 'app-course-home-page',
  templateUrl: './course-home-page.component.html',
  styleUrls: ['./course-home-page.component.sass']
})
export class CourseHomePageComponent {

  routeCards: RouteCardItem[] = [
    {
      title: 'Create a Course',
      description: 'Create a new course.',
      icon: 'bi-journal-plus',
      routerLink: '/course-composer/courses/create'
    },
    {
      title: 'Edit Existing Courses',
      description: 'Edit an existing course.',
      icon: 'bi-pencil-square',
      routerLink: '/course-composer/courses/edit'
    }
  ];

}
