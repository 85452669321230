import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth/services/auth.service';
import { catchError, of } from 'rxjs';
import { RedirectService } from '@core/services/redirect.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass']
})
export class LoginFormComponent implements OnInit {

  loginFormGroup: FormGroup;
  loginError?: string;

  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private redirect: RedirectService) {
    this.loginFormGroup = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required
      ]]
    });
  }

  ngOnInit(): void {

    if (this.auth.authenticated()) {
      this.redirect.redirect();
    }

    this.auth.inProgress.subscribe({
      next: (inProgress) => {
        if (inProgress) {
          this.loginFormGroup.disable();
        } else {
          this.loginFormGroup.enable();
        }
      }
    });
  }

  submitLogin() {
    this.loginError = undefined;
    const { email, password } = this.loginFormGroup.value;
    this.auth.login(email, password)
      .pipe(catchError((error) => {
        if (error.status === 401) {
          this.loginError = error.error.message;
        } else {
          this.loginError = 'Something went wrong. Please try again later.';
        }
        return of(null);
      }))
      .subscribe({
        next: (response) => {
          if (response) {
            this.auth.saveToken(response);
            this.redirect.redirect();
          }
        }
      });
  }



}
