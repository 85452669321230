<div [formGroup]="settingsForm" class="container">
  <div class="card card-body mb-4">
    <h5 class="card-title mb-3">Editor Settings</h5>
    <div class="card-text">
      <div class="form-check form-switch">
        <input class="form-check-input"
               formControlName="markdownEditorDefault"
               type="checkbox"
               id="markdown-editor-default">
        <label class="form-check-label" for="markdown-editor-default">Markdown by Default</label>
      </div>
      <p class="text-muted">If checked, all editors will default to markdown mode.</p>
    </div>
  </div>

  <div class="card card-body mb-4">
    <h5 class="card-title mb-3">Notifications</h5>
    <div class="card-text">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="allow-email-notifications" formControlName="allowEmailNotifications">
        <label class="form-check-label" for="allow-email-notifications">Allow Email Notifications</label>
      </div>
      <div class="ps-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="notify-email-new-assignment" formControlName="notifyEmailNewAssignment">
          <label class="form-check-label" for="notify-email-new-assignment">New Assignments</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="notify-email-new-feedback" formControlName="notifyEmailNewFeedback">
          <label class="form-check-label" for="notify-email-new-feedback">Feedback</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="notify-email-grading-activity" formControlName="notifyEmailGradingActivity">
          <label class="form-check-label" for="notify-email-grading-activity">Grading Activity</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="notify-email-student-submission" formControlName="notifyEmailStudentSubmission">
          <label class="form-check-label" for="notify-email-student-submission">Student Submission</label>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-body mb-4">
    <h5 class="card-text mb-3">Reset Settings</h5>
    <div class="card-text">
      <p class="text-muted">Reset all settings to their default values.</p>
      <button type="button" (click)="resetSettings()" class="btn btn-outline-danger">Reset to Default Settings</button>
    </div>
  </div>
</div>
