import { Injectable, TemplateRef } from '@angular/core';

export interface ToastData {
  title?: string;
  content: string | TemplateRef<any>;
  type?: 'success' | 'danger' | 'warning' | 'info' | 'spinner';
  length?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalToastService {

  toasts: ToastData[] = [];

  constructor() { }

  show(toast: ToastData) {
    this.toasts.push(toast);
    return toast;
  }

  remove(toast: ToastData) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  showSuccessToast(message: string) {
    this.show({
      content: message,
      type: 'success'
    });
  }

  showDangerToast(message: string) {
    this.show({
      content: message,
      type: 'danger'
    });
  }

}
