import { Component, DestroyRef, EventEmitter, Inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { switchMap } from 'rxjs';

import { VideoUploadFormService } from '@app/api/video/services/video-upload-form.service';
import { VideoUploadService } from '@app/api/video/services/video-upload.service';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-video-upload-modal',
  templateUrl: './video-upload-modal.component.html',
  styleUrls: ['./video-upload-modal.component.sass']
})
export class VideoUploadModalComponent {

  @Output() closeModal = new EventEmitter<void>();
  @Output() uploadComplete = new EventEmitter<VideoMetaData>();
  @ViewChild('uploadingVideo') uploadingVideoModal?: TemplateRef<any>;

  error = '';
  uploadProgress = 0;
  hasUploaded = false;
  loadingModalRef!: NgbModalRef;

  constructor(
    private videoFormService: VideoUploadFormService,
    private videoUploadService: VideoUploadService,
    private modalService: NgbModal,
    @Inject(DestroyRef)
    private destroy$: DestroyRef
  ) {
  }

  get videoUploadForm() {
    return this.videoFormService.videoUploadForm;
  }

  uploadVideo() {

    this.loadingModalRef = this.modalService.open(this.uploadingVideoModal, { centered: true, backdrop: 'static', keyboard: false });
    if (!this.videoFormService.videoUploadFormData) return;
    this.error = '';
    this.videoUploadService
      .initiateUpload(this.videoFormService.videoUploadFormData)
      .pipe(switchMap(videoId => {
        return this.videoUploadService.uploadVideo(this.videoFormService.videoUploadFormData!, videoId);
      })).subscribe({
        next: (videoMetaData) => {
          if (videoMetaData.type === HttpEventType.UploadProgress) {
            const total = videoMetaData.total || 1;
            this.uploadProgress = Math.round(100 * videoMetaData.loaded / total);
          }
          if (videoMetaData.type === HttpEventType.Response && videoMetaData.body) {
            this.uploadComplete.emit(videoMetaData.body);
            this.hasUploaded = true;
            this.loadingModalRef.close();
          }
        },
        error: (error) => {
          this.error = error['error']['message'] ?? error.message;
          this.loadingModalRef.close();
        }
      });
  }

}
